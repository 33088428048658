import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {Tag} from '../../../components/chip/Chip';
import moment from 'moment';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {useTheme} from '@unthinkable/react-theme';

const RenderDesignationAndTeam = ({row, ...rest}) => {
  const {fonts, colors} = useTheme();
  return (
    <View>
      <Text
        title={row?.position_title?.name}
        style={{
          ...rest.styles.text,
          numberOfLines: 1,
        }}>
        {row?.position_title?.name}
      </Text>
      <Text
        title={row?.department?.name}
        style={{
          ...fonts.BODY2,
          color: colors.NEUTRAL_MEDIUM,
          numberOfLines: 1,
        }}>
        {row?.department?.name}
      </Text>
    </View>
  );
};

const RenderPriority = ({row}) => {
  const {priority} = row;
  const priorityWiseColors = {
    Urgent: 'ERROR',
    High: 'Warning',
    Medium: 'ACCENT5',
    Low: 'SUCCESS',
  };
  return (
    <Tag
      value={priority}
      color={priorityWiseColors[priority]}
      textColor={priorityWiseColors[priority]}
      displayTextColor
    />
  );
};

const RenderStatus = ({row, ...rest}) => {
  const {status, approved_on, rejected_on} = row;
  const statusWiseColors = {
    Approved: 'SUCCESS',
    Rejected: 'ERROR',
  };
  const concernedDate = approved_on || rejected_on;
  const {LOW, HIGH} = useAccentColor(statusWiseColors[status]);
  return (
    <View
      style={{
        backgroundColor: LOW,
        padding: 12,
        margin: -12,
        width: 100,
      }}>
      <Text style={{...rest.styles.text, color: HIGH}}>{row?.status}</Text>
      <GroupContentItem
        {...rest}
        value={concernedDate ? moment(concernedDate).format('DD MMM YY') : '-'}
      />
    </View>
  );
};

const ManPowerRequisitionColumns = [
  {
    header: 'Request',
    field: 'requested_on',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
  },
  {
    header: 'Position',
    render: RenderDesignationAndTeam,
  },
  {
    header: 'Exp.',
    field: 'experience_required.experience',
    type: 'text',
  },
  {
    header: 'Skill',
    field: 'must_have_skills',
    render: MultiColorTag,
    labelField: 'name',
    colorField: 'color',
  },
  {
    header: 'Priority',
    field: 'priority',
    render: RenderPriority,
  },
  {
    field: 'no_of_positions',
    header: 'Positions',
    type: 'number',
    width: 150,
  },
];

export const ManPowerRequisitionPendingTable = props => {
  const {navigation} = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  return (
    <Table
      eventSourceId="ManPowerRequisitionPending"
      api={`/manpowerrequisitions`}
      fields={{
        _id: 1,
        position_title: {
          name: 1,
        },
        no_of_positions: 1,
        position_type: 1,
        location: 1,
        priority: 1,
        salary_offered: 1,
        department: {_id: 1, name: 1},
        hiring_manager: {_id: 1, name: 1, color: 1},
        requested_on: 1,
        experience_required: {
          experience: 1,
        },
        must_have_skills: {
          name: 1,
          color: 1,
        },
        approved_on: 1,
      }}
      filter={{
        status: 'Pending',
        requested_by: employee._id,
      }}
      sort={{
        requested_on: -1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`editable-mrf`, {
          mrf: row?._id,
        });
      }}
      columns={ManPowerRequisitionColumns}
    />
  );
};

export const ManPowerRequisitionApprovedTable = props => {
  const {navigation} = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  return (
    <Table
      api={`/manpowerrequisitions`}
      filter={{
        status: {
          $in: ['Approved', 'Rejected', 'Processed'],
        },
        requested_by: employee?._id,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-mrf`, {
          mrf: row?._id,
        });
      }}
      sort={{
        requested_on: -1,
      }}
      fields={{
        _id: 1,
        position_title: 1,
        no_of_positions: 1,
        position_type: 1,
        location: 1,
        priority: 1,
        salary_offered: 1,
        department: {_id: 1, name: 1},
        hiring_manager: {_id: 1, name: 1, color: 1},
        requested_on: 1,
        experience_required: {
          experience: 1,
        },
        status: 1,
        must_have_skills: {
          name: 1,
          color: 1,
        },
        approved_on: 1,
        rejected_on: 1,
      }}
      columns={[
        ...ManPowerRequisitionColumns,
        {
          header: 'Status',
          render: RenderStatus,
          align: 'center',
        },
      ]}
    />
  );
};
