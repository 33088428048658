import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const DepartmentInputForm = props => {
  const {departmentInputId} = props.route.params;

  let {onSubmit} = useFormSubmit({
    uri: '/departmentinputs',
    eventSourceId: 'DepartmentInput',
  });
  const {params} = props.route || {};
  return (
    <Form
      api={`/departmentInputs/${departmentInputId}`}
      fields={{
        filter: 1,
        action_view: 1,
        kpi: 1,
        label: 1,
        sequence: 1,
        department: {name: 1},
        output: {label: 1},
        model: {name: 1},
        on_press_url: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Label',
          field: 'label',
          type: 'text',
        },
        {
          type: 'text',
          field: 'on_press_url',
          label: 'On Press URL',
        },
        {
          label: 'KPI',
          field: 'kpi',
          type: 'text',
        },
        {
          label: 'Sequence',
          field: 'sequence',
          type: 'number',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Output',
          field: 'output',
          type: 'autoComplete',
          api: `/DepartmentOutputs`,
          suggestionField: 'label',
          valueField: 'label',
        },
        {
          label: 'Model',
          field: 'model',
          type: 'autoComplete',
          api: '/types',
          filter: {
            departments: params?.department?._id,
            is_show: true,
          },
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddDepartmentInputForm = props => {
  return <DepartmentInputForm header={'Add Department Input'} {...props} />;
};

export const UpdateDepartmentInputForm = props => {
  return (
    <DepartmentInputForm
      mode="edit"
      header={'Update Department Input'}
      {...props}
    />
  );
};
