import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {BorderedGroupTable} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {leadControllers} from '../controllers/leadControllers';
import {Tag} from '../../../components/chip/Chip';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {useState} from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form-editors/Editors';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';
import {GroupBy} from '../../../components/table/GroupBy';
import {useGroupBy} from '../../../controllers/useGroupBy';

// const modelInfo = Object.freeze({
//   '64884b6bd40198f62b283007': 'WebCampaign',
//   '64d0fd55ab78dfead48c144a': 'EmailCampaign',
//   '64d0fd55ab78dfead48c144b': 'InmailCampaign',
//   '64be7476e4ba09d3df7b3a6d': 'PaidAdCampaign',
//   '64db3dc4334a9d2f6766ded2': 'OtherCampaign',
// });

export const RenderName = ({row}) => {
  return <Tag value={row?.name} color={row?.color} />;
};

export const LeadToCustomerTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {applyFilter, onChangeFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {forKpi = false, period: kpiPeriod = {}} = params;
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  if (forKpi) {
    params = {...params, period: kpiPeriod, addOnFilter: filter};
  } else {
    params = {...params, period, addOnFilter: filter};
  }
  const groupBy = useGroupBy({
    options: [
      {
        label: 'Owner',
        field: 'owner',
      },
      {
        label: 'Organization category',
        field: 'organization_category',
      },
      {
        label: 'Geography location',
        field: 'geography_location',
      },
      {
        label: 'Employee Team',
        field: 'employee_team',
      },
      {
        label: 'Organization',
        field: 'organization',
      },
    ],
  });
  const {
    channelLead,
    channelPreLead,
    channelQl,
    channelComparativeQl,
    channelPreQl,
    channelPreComparativeQl,
    potential_customers,
    previous_potential_customers,
    channelNurturableLead,
    channelPreNurturableLead,
    opportunity_lead,
    previous_opportunity_lead,
  } = leadControllers({...props, params});
  const [isComparative, setIsComparative] = useState(false);
  return (
    <BorderedGroupTable
      api={'/types/channelTypes/monthWise'}
      eventSourceId={'leadToCustomer'}
      addOnFilter={{...filter}}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Channel"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Owner',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'owner',
                  secondarySuggestionField: 'official_email_id',
                },
                {
                  type: 'autoComplete',
                  label: 'Geographic location',
                  api: '/geographylocations',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'geography_location',
                },
                {
                  type: 'autoComplete',
                  label: 'Organization Category',
                  api: '/organizationcategories',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'organization_category',
                },
                {
                  type: 'autoComplete',
                  label: 'Channel',
                  api: '/channels',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'channel',
                },
                {
                  type: 'autoComplete',
                  label: 'Organization',
                  api: '/organizations',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'organization',
                },
                {
                  type: 'autoComplete',
                  label: 'Employee Team',
                  api: '/employeeteams',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'employee_team',
                  filter: {
                    _id: {
                      $in: [
                        '654c6ebd64126a8c199e9537',
                        '655b1c352722272f13e98953',
                      ],
                    },
                  },
                },
                {
                  type: 'autoComplete',
                  label: 'Country',
                  api: '/countries',
                  filter: {
                    target_country: {$exists: false},
                  },
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'country',
                },
              ]}
            />,
            <GroupBy {...groupBy} />,
            <Row style={{gap: 4}}>
              <TextRenderer value={'Static'} />
              <Switch onChangeValue={setIsComparative} value={isComparative} />
              <TextRenderer value={'Comparative'} />
            </Row>,
            !forKpi && (
              <PeriodRangeMonthWiseBreakupFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />
            ),
          ]}
        />
      )}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={{
        data: '_children.data',
        header: true,
        defaultExpanded: true,
        aggregate: '_children.aggregates',
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              {row?.ownerName || row?.entityName ? (
                <Text style={styles}>{row?.ownerName || row?.entityName}</Text>
              ) : (
                <Text
                  style={{
                    ...styles,
                  }}>
                  {getDateRangeDisplay({
                    from: row?.startDate,
                    to: row?.endDate,
                    filterOf: period?.monthWiseBreakup
                      ? 'Month'
                      : period?.filterOf,
                  })}
                </Text>
              )}
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.leadCount} Leads`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.qlCount} QLs`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.totalScoreOfQls?.toFixed(2)} Tot. Score`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.customerCount} Customers`}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Channels',
          render: RenderName,
          onPress: ({row}) => {
            navigation.navigate('channel-wise-campaign', {
              ...params,
              custom_filter: {
                // model: modelInfo[row._id],
                channel: row._id,
              },
              header: row?.name,
            });
          },
        },
        {
          type: 'number',
          field: 'potential_customer_count',
          header: 'Potential Customer',
          width: 150,
          compareField: 'previous_potential_customer_count',
          align: 'center',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: potential_customers,
          onComparePress: previous_potential_customers,
        },
        {
          type: 'number',
          field: 'lead_count',
          header: 'Leads',
          width: 120,
          compareField: 'previous_lead_count',
          align: 'center',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: channelLead,
          onComparePress: channelPreLead,
        },
        {
          type: 'number',
          field: 'nurturable_lead_count',
          header: 'Nurturable Leads',
          width: 150,
          compareField: 'previous_nurturable_lead_count',
          align: 'center',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: channelNurturableLead,
          onComparePress: channelPreNurturableLead,
        },
        {
          header: 'QL',
          children: [
            {
              type: 'number',
              field: 'ql_count',
              width: 120,
              header: 'Quantity',
              compareField: 'previous_ql_count',
              render: ComparisonRender,
              aggregate: {
                render: AggregateComparisonRender,
              },
              onPress: channelQl,
              onComparePress: channelPreQl,
              visible: !isComparative,
            },
            {
              type: 'number',
              field: 'comparative_ql_count',
              width: 150,
              header: 'Quantity',
              compareField: 'previous_comparative_ql_count',
              render: ComparisonRender,
              aggregate: {
                render: AggregateComparisonRender,
              },
              onPress: channelComparativeQl,
              onComparePress: channelPreComparativeQl,
              visible: !!isComparative,
            },
            {
              type: 'number',
              header: 'Tot. Score',
              field: 'qlTotalScore',
              width: 100,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
            {
              type: 'number',
              header: 'Avg. Score',
              width: 100,
              field: 'qlAverageScore',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
          ],
        },
        // {
        //   header: 'RFR',
        //   field: 'opportunity_rfr_count',
        //   width: 150,
        //   type: 'number',
        //   align: 'center',
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('channel-wise-rfr', {
        //       row,
        //       filters: {
        //         channel: row?._id,
        //         opportunity_type: 'StaffAugmentationOpportunity',
        //         rfr_type: 'PotentailCustomer',
        //         creation_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //       },
        //       addOnFilter: filter,
        //     });
        //   },
        //   visible: !isComparative,
        // },
        {
          header: 'Opportunity Lead',
          children: [
            {
              type: 'number',
              field: 'opportunity_lead_count',
              header: 'Quantity',
              width: 120,
              compareField: 'previous_opportunity_lead_count',
              align: 'center',
              render: ComparisonRender,
              aggregate: {
                render: AggregateComparisonRender,
              },
              onPress: opportunity_lead,
              onComparePress: previous_opportunity_lead,
            },
            {
              type: 'number',
              header: 'Tot. Score',
              field: 'olTotalScore',
              width: 100,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
            {
              type: 'number',
              header: 'Avg. Score',
              field: 'olAverageScore',
              width: 100,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
          ],
        },
        // {
        //   header: 'RFR',
        //   field: 'comparative_opportunity_rfr_count',
        //   width: 80,
        //   type: 'number',
        //   align: 'center',
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('channel-wise-rfr', {
        //       row,
        //       filters: {
        //         channel: row?._id,
        //         opportunity_type: 'StaffAugmentationOpportunity',
        //         rfr_type: 'PotentailCustomer',
        //         creation_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //         sql_stage_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //         uql_stage_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //       },
        //       addOnFilter: filter,
        //     });
        //   },
        //   visible: !!isComparative,
        // },
        // {
        //   header: 'RFQ',
        //   field: 'opportunity_rfq_count',
        //   width: 80,
        //   type: 'number',
        //   align: 'center',
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('channel-wise-rfq', {
        //       row,
        //       filters: {
        //         channel: row?._id,
        //         opportunity_type: 'CustomPlatformOpportunity',
        //         rfr_type: 'PotentailCustomer',
        //         creation_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //       },
        //       addOnFilter: filter,
        //     });
        //   },
        //   visible: !isComparative,
        // },
        // {
        //   header: 'RFQ',
        //   field: 'comparative_opportunity_rfq_count',
        //   width: 80,
        //   type: 'number',
        //   align: 'center',
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('channel-wise-rfq', {
        //       row,
        //       filters: {
        //         channel: row?._id,
        //         opportunity_type: 'CustomPlatformOpportunity',
        //         rfr_type: 'PotentailCustomer',
        //         creation_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //         sql_stage_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //         uql_stage_date: {
        //           $lte: period?.to,
        //           $gte: period?.from,
        //         },
        //       },
        //       addOnFilter: filter,
        //     });
        //   },
        //   visible: !!isComparative,
        // },
        {
          header: 'Customer',
          children: [
            {
              header: 'Quantity',
              field: 'customer_count',
              width: 100,
              type: 'number',
              align: 'center',
              aggregate: true,
              onPress: ({row}) => {
                navigation.navigate('channel-wise-customer', {
                  row,
                  filters: {
                    channel: row?._id,
                    first_order_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                  },
                  addOnFilter: filter,
                });
              },
              visible: !isComparative,
            },
            {
              header: 'Quantity',
              field: 'comparative_customer_count',
              width: 150,
              aggregate: true,
              align: 'center',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-customer', {
                  row,
                  filters: {
                    channel: row?._id,
                    first_order_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    sql_stage_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    uql_stage_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                  },
                });
              },
              visible: !!isComparative,
            },
            {
              type: 'number',
              header: 'Tot. Score',
              field: 'customerTotalScore',
              width: 100,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
            {
              type: 'number',
              header: 'Avg. Score',
              field: 'customerAverageScore',
              width: 100,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
          ],
        },
      ]}
      params={params}
    />
  );
};
