import React from 'react';
import {plural} from 'pluralize';
import {GroupTable, Table} from '../../../components/table/Table';
import {
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_PULL_REQUEST_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {useInvoke} from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {
  EstHrsChipRender,
  EstHrsColumnRender,
} from '../cellrenders/EstHrsChipRender';
import {RenderMarkActive} from '../../task/views/TasksTable';
import DurationChip from '../../../components/chip/DurationChip';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import StatusRender from '../cellrenders/StatusRender';
import {useToast} from '@unthinkable/react-toast';
import Images from '../../../images';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getTime} from '../utility';
import {FeatureDesignScreensRender} from '../cellrenders/DesignUploadCellRender';
import {
  Col,
  Image,
  Row,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Badge} from '../../../components/badge/Badge';
import {Rating} from '@unthinkable/react-star-rating';
import {ActionText} from '../../../components/action/ActionText';
import {FeatureColumnCard} from '../../../components/card/FeatureColumnCard';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';
import {useFeatureActions} from '../actions/FeatureActions';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {Labels} from '../constants/Label';
import {FeatureStatus, QAStages} from '../constants/FeatureStages';
import {AddFilledAction} from '../../../components/action/AddAction';
import {DateRenderer} from '../../../components/form-editors/Editors';
import {CopyTextComponent} from './IssuesTable';
import {ExportExcelAction} from './assets/AssetUtility';
import {useFetchData} from '../../../controllers/useFetchData';
import {Tag} from '../../../components/chip/Chip';

const {StageCircle, PullRequest} = Images;

const releasedFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {releasedOn, feature, code} = row;
    if (releasedOn) {
      releasedOn = `Released on ${getTime(releasedOn)}`;
    }

    const {colors} = useTheme();

    return (
      <FeatureColumnCard
        row={row}
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            renderComponent: <CopyTextComponent code={code} />,
          },
          {
            value: releasedOn,
          },
        ]}
      />
    );
  },
};

const activeFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {
      createdAt,
      created_by,
      feature,
      code,
      sub_status,
      last_status_updated_at,
    } = row;

    const {colors} = useTheme();
    let title;

    createdAt = `${getTime(createdAt)}`;

    if (last_status_updated_at) {
      last_status_updated_at = `${getTime(last_status_updated_at)}`;
    }

    if (created_by?.name) {
      created_by = `By : ${created_by?.name}`;
    }

    if (sub_status === 'Architecture') {
      title = 'In architecture since ' + last_status_updated_at;
    } else if (createdAt === last_status_updated_at) {
      title = 'Created on ' + createdAt;
    } else {
      title = 'Moved to backlog on ' + last_status_updated_at;
    }

    return (
      <FeatureColumnCard
        row={row}
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            renderComponent: <CopyTextComponent code={code} />,
          },
          {
            value: last_status_updated_at,
            title,
          },
        ]}
      />
    );
  },
};

const roadmapFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {
      feature,
      code,
      status,
      last_status_updated_at,
      sub_status,
      open_bugs_count,
      re_qa_count,
    } = row;
    if (last_status_updated_at) {
      last_status_updated_at = `${getTime(last_status_updated_at)}`;
    }

    const statusString = last_status_updated_at;

    let title;
    switch (status) {
      case 'active':
        status = 'Development';
        title = 'In development since ' + last_status_updated_at;
        break;
      case 'qa':
        status = 'QA';
        title = 'In QA since ' + last_status_updated_at;
        break;
    }

    const {colors} = useTheme();

    const itemsList = [
      {
        renderComponent: <CopyTextComponent code={code} />,
      },
      {
        value: statusString,
        title,
      },
    ];

    if (status === 'QA') {
      let itemColor = {
        QA: {subStatus: 'QA', color: colors.WARNING_HIGH},
        'Re-QA': {subStatus: 'Re-QA', color: colors.WARNING_HIGH},
        Passed: {subStatus: 'Pass', color: colors.SUCCESS_HIGH},
        Failed: {subStatus: 'Failed', color: colors.ERROR_HIGH},
        'Re-QA Failed': {subStatus: 'Re-QA Failed', color: colors.ERROR_HIGH},
        UAT: {subStatus: 'UAT', color: colors.ACCENT6_HIGH},
      };

      if (sub_status === 'Failed' && re_qa_count) {
        sub_status = 'Re-QA Failed';
      }

      if (
        open_bugs_count &&
        itemColor?.[sub_status]?.subStatus &&
        sub_status !== 'Passed'
      ) {
        if (sub_status === 'Failed' && re_qa_count) {
          itemColor[sub_status].subStatus = 'Re-QA Failed';
          itemColor[sub_status].color = colors.ERROR_HIGH;
        }

        itemColor[sub_status].subStatus =
          itemColor[sub_status]?.subStatus +
          ` (${open_bugs_count}` +
          ` ${open_bugs_count > 1 ? 'bugs' : 'bug'})`;
      }

      itemsList.push({
        value: itemColor[sub_status]?.subStatus,
        color: itemColor[sub_status]?.color,
      });
    }

    return (
      <FeatureColumnCard
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={itemsList}
        row={row}
      />
    );
  },
};

const featureTasks = ({params, navigation}) => ({
  render: CountCellRender,
  width: 80,
  header: 'Tasks',
  align: 'right',
  released_count_field: 'feature_completed_task_count',
  count_field: 'feature_task_count',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`feature-tasks`, {
      ...params,
      feature: {_id: row?._id, status: row?.status},
      module: row?.module_id,
      title: row?.feature,
      source: PROJECT_FEATURE_TYPE_ID,
      displaySource: true,
    });
  },
});

const totalHours = ({showCompleteHrs}) => ({
  render: EstHrsChipRender,
  width: 100,
  header: 'Est.',
  align: 'right',
  released_count_field: 'completedHours',
  count_field: 'totalHours',
  showCompleteHrs,
});

const totalUpgradedHours = ({showCompleteHrs}) => ({
  render: EstHrsChipRender,
  width: 120,
  header: 'Upgraded Hrs',
  align: 'right',
  count_field: 'upgradedHrs',
  showCompleteHrs,
});

const estHrsColumn = ({width}) => ({
  render: EstHrsColumnRender,
  width: width || 130,
  header: 'Estimate',
  align: 'right',
  estHrsField: 'totalHours',
});

const featureCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_FEATURE_TYPE_ID, label: 'Feature'},
      comment_source_id: {_id: row._id, name: row.feature},
    }),
  });
};

const edit = ({navigation, params, readOnly, extraParams = {}}) => ({
  title: readOnly ? 'Detail' : 'Edit',
  onPress: props => {
    const {row} = props;
    navigation.navigate('feature-detail', {
      ...params,
      feature: {_id: row?._id},
      readOnly,
      ...extraParams,
    });
  },
});

const updateFeatureStage = ({navigation, params, stage}) => ({
  title: 'Update Stage',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-feature-stage', {
      ...params,
      row,
      stage,
    });
  },
});

const DueDateRender = ({row}) => {
  const {due_date, update_due_date_reason} = row;
  const {colors, fonts} = useTheme();
  let {alertCircleGray} = useComponentTheme('PlusColumnRenderStyles');
  return (
    <Row gap={4}>
      {due_date ? (
        <>
          {update_due_date_reason ? (
            <View
              title={`Reason: ${update_due_date_reason}`}
              style={{justifyContent: 'center'}}>
              <Image source={alertCircleGray} style={{height: 12, width: 12}} />
            </View>
          ) : null}
          <DateRenderer
            format="DD MMM"
            value={due_date}
            color={colors.NEUTRAL_LOW}
            styles={{text: {...fonts.BODY2}}}
          />
        </>
      ) : (
        <AddFilledAction />
      )}
    </Row>
  );
};

const dueDate = ({navigation}) => ({
  render: DueDateRender,
  header: 'Due on',
  onPress: ({row}) => {
    navigation.navigate('replan-form', {
      row,
      api: '/projectfeatures',
    });
  },
  width: 80,
  fallbackRender: () => {
    return <AddFilledAction />;
  },
  align: 'center',
});

const changeModule = ({navigation, params}) => ({
  title: 'Update Module',
  onPress: props => {
    const {row} = props;
    navigation.navigate('change-module', {
      ...params,
      row,
      api: '/projectfeatures',
      secondaryTitle: `${row?.feature}`,
    });
  },
  visible: ({row}) => !row.library_id,
});

const updateStatus = ({navigation, params}) => ({
  title: 'Update Status',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-feature-status', {
      ...params,
      row,
      secondaryTitle: `${row?.feature}`,
    });
  },
});

const updateReleasedOn = ({navigation, params}) => ({
  title: 'Update Released On',
  onPress: ({row}) => {
    navigation.navigate('releasedon-form', {
      row,
    });
  },
});

const markReleasedForFeature = ({invoke, toast = () => {}}) => ({
  title: 'Mark Released',
  confirm: {
    title: 'Release Mark',
    message: 'Are you sure you want to Mark Release?',
  },
  onPress: async props => {
    const {row} = props;
    await invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'released',
      },
    });
  },
});

const markActive = ({invoke}) => ({
  width: 180,
  type: 'text',
  render: RenderMarkActive,
  displayText: ({row}) => {
    return row?.type === 'feature'
      ? 'Define Architecture'
      : 'Move to Development';
  },
  align: 'center',
  onPress: ({row}) => {
    const updateValues =
      row?.type === 'feature'
        ? {sub_status: 'Architecture'}
        : {status: 'active'};

    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: updateValues,
    });
  },
});

const assignees = {
  header: 'Assignees',
  field: 'assignee',
  type: 'userAvatarGroup',
  width: 90,
  align: 'right',
  maxAvatars: 3,
};

const RatingRenderer = ({row}) => {
  if (row?.performance_review) {
    return <Rating value={row['performance_review']} readOnly />;
  }
  return <ActionText text={'Review'} />;
};

const review = ({navigation}) => {
  return {
    type: 'rating',
    width: 100,
    align: 'center',
    render: RatingRenderer,
    onPress: props => {
      const {row} = props;
      navigation.navigate('feature-review', {row});
    },
  };
};

const design = ({navigation, params}) => ({
  render: FeatureDesignScreensRender,
  width: 70,
  header: 'Design',
  align: 'right',
  field: 'design_count',
  onPress: ({row}) => {
    if (row?.design_count) {
      navigation.navigate('task-image-previewer', {...params, row});
    }
  },
});

const markFeatureDelete = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message:
      'Are you sure you want to delete this item? This action cannot be undone.',
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectfeatures/${row?._id}/delete`,
      props: {},
    });
  },
});

const moveToBacklog = ({invoke}) => ({
  title: 'Move to Backlog',
  variant: 'subtle',
  confirm: {
    title: 'Move to Backlog',
    message: 'Are you sure you want to move this to Backlog?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'backlog',
        due_date: null,
        sub_status: null,
      },
    });
  },
});

const moveToDevelopment = ({invoke}) => ({
  title: 'Move to Development',
  confirm: {
    title: 'Move to Development',
    message: 'Are you sure you want to move this to Development?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'active',
        sub_status: null,
      },
    });
  },
});

const defineArchitecture = ({invoke}) => ({
  title: 'Define Architecture',
  confirm: {
    title: 'Define Architecture',
    message: 'Are you sure you want to move this to Architecture?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'backlog',
        sub_status: 'Architecture',
      },
    });
  },
  visible: ({row}) => row?.type === 'feature',
});

const moveToActive = ({invoke}) => ({
  title: 'Mark Active',
  variant: 'subtle',
  confirm: {
    title: 'Mark Active',
    message: 'Are you sure you want to mark this as active?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'active',
        releasedOn: null,
        released_by: null,
      },
    });
  },
});

const featureModule = {
  type: 'colorTag',
  field: 'module_id.module',
  colorField: 'module_id.color',
  header: 'Module',
  width: 170,
};

const markQA = ({invoke}) => ({
  title: 'Mark QA',
  confirm: {
    title: 'Mark QA',
    message: 'Are you sure you want to move this to QA?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectfeatures/${row?._id}/markQa`,
      message: 'Marked QA',
    });
  },
  visible: ({row}) => row.status === 'active' || row.sub_status === 'Failed',
});

const featureStatus = {
  render: StatusRender,
  status_field: 'sub_status',
  header: {icon: StageCircle, label: 'Stage'},
  width: 120,
  align: 'center',
};

const featureDetailScreen = ({
  row,
  project,
  featureType,
  clickedSource,
  navigation,
  params,
  taskQualification,
  navigateTo,
}) => {
  const navigationParams = {
    ...params,
    project: project || row?.project_id,
    project_id: project?._id || row?.project_id?._id,
    featureType: featureType || row.type || 'feature',
    feature: row,
    feature_id: row._id,
    module: row?.module_id,
    module_id: row?.module_id?._id,
    sub_status: row?.sub_status,
    clickedSource,
    taskQualification,
  };
  if (navigateTo) {
    navigationParams.breadcrumb = {
      title: row?.feature,
    };
    navigateTo(`feature-detail-screen-new`, navigationParams);
  } else {
    navigation.navigate('feature-detail-screen', navigationParams);
  }
};

const prCount = ({params, navigation, navigateTo}) => ({
  width: 45,
  align: 'right',
  render: ({row}) => {
    if (!row?.open_pr_count) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          featureDetailScreen({
            row,
            clickedSource: PROJECT_PULL_REQUEST_TYPE_ID,
            navigation,
            params,
            project: params.project,
            navigateTo,
          });
        }}>
        <Badge value={row?.open_pr_count}>
          <Image source={PullRequest} />
        </Badge>
      </TouchableOpacity>
    );
  },
});

const markArchived = ({invoke}) => ({
  title: 'Mark Archived',
  variant: 'subtle',
  confirm: {
    title: 'Mark Archived',
    message: 'Are you sure you want to mark this as archive?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'archived',
        due_date: null,
      },
    });
  },
});

const featureExportParams = ({
  search,
  searchFields,
  showReportColumns,
  ...params
}) => {
  const {
    params: {module},
  } = params || {};

  const moduleColumn = !module
    ? [{header: 'Module', key: 'module_id.module', width: 20}]
    : [];

  const reportColumns = showReportColumns
    ? [
        {header: 'Eff. Hrs', key: 'totalHours', width: 30},
        {header: 'Upgraded. Hrs', key: 'upgradedHrs', width: 30},
      ]
    : [];

  return {
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: `${plural(Labels.Feature)}`,
      columns: [
        ...moduleColumn,
        {header: `${Labels.Feature}`, key: 'feature', width: 30},
        {header: 'Description', key: 'desc', width: 30},
        ...reportColumns,
        {
          header: 'Desktop Attachments',
          isImage: true,
          key: 'desktop_attachments',
          width: 30,
        },
        {header: 'Desktop Link', key: 'desktop_link', width: 30, link: true},
        {
          header: 'Mobile Attachments',
          isImage: true,
          key: 'mobile_attachments',
          width: 30,
        },
        {header: 'Mobile Link', key: 'mobile_link', width: 30, link: true},
        {
          header: 'Tab Attachments',
          isImage: true,
          key: 'tab_attachments',
          width: 30,
        },
        {header: 'Tab Link', key: 'tab_link', width: 30, link: true},
      ],
    },
  };
};

export const FeatureTableHeader = ({
  tableProps,
  isReleased,
  restActions = [],
  ...props
}) => {
  const {
    navigation,
    route: {params},
    selectedIds,
  } = props;

  const restProps = {};

  if (isReleased) {
    restProps.onPress = (e, {params}) => {
      navigation.navigate('export-features', {...params});
    };
  }

  return (
    <>
      <TableHeader
        actions={[
          ...restActions,
          <ExportExcelAction
            params={featureExportParams({
              params,
              ...tableProps,
            })}
            selectedIds={selectedIds}
            {...restProps}
          />,
        ]}
      />
    </>
  );
};

export const BacklogFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    fromModule,
    groupBy,
    filter,
    filterParams,
    searchValue,
    selectedTab,
  } = props;

  const {project, milestone, projectlibrary_id} = params;
  const {sub_status} = filter;

  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Feature'});

  const {navigateTo} = useLeftMenuContext() || {};

  const finalParams = {
    project_id: project._id,
    module_id: params?.module?._id,
    ...filterParams,
  };

  const tableProps = {
    api: `/features/backlog/export`,
    search: searchValue,
    filter: {
      milestone_id: milestone?._id,
      ...filter,
    },
    tableParams: finalParams,
    searchFields: ['feature', 'code'],
    eventSourceId: ['Feature', 'Task', 'Comment'],
  };

  return (
    <Table
      {...tableProps}
      api={`/features/backlog`}
      allowDynamicGrouping={true}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      renderHeader={_props => {
        return (
          <FeatureTableHeader {...props} {..._props} tableProps={tableProps} />
        );
      }}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      params={finalParams}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          ...featureModule,
          visible: !fromModule && !projectlibrary_id,
        },
        activeFeatureColumn,
        selectedTab === 'architecture' &&
          design({
            params,
            navigation,
          }),
        featureTasks({params, navigation}),
        {
          ...estHrsColumn({
            width: 100,
          }),
        },
        assignees,
        !sub_status && markActive({invoke}),
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params}),
        changeModule({navigation, params}),
        sub_status && moveToBacklog({invoke}),
        sub_status && moveToDevelopment({invoke}),
        markArchived({invoke}),
        markFeatureDelete({deleteInvoke}),
      ]}
    />
  );
};

export const ReleasedFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    extraParams = {},
    searchValue: searchFilter,
    addOnFilter,
  } = props;

  const {colors} = useTheme();
  const {project, module} = params;
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );

  const {featureActivityLog} = useFeatureActions({navigation, params});
  let {params: filterParams = {}, filter} = filterValues || {};

  params = {
    ...params,
    ...filterParams,
  };

  const filters = {
    ...filter,
    ...addOnFilter,
  };
  const {navigateTo} = useLeftMenuContext() || {};

  const paramsFilter = {
    project_id: project._id,
    module_id: module?._id,
    ...extraParams,
    ...filterParams,
  };

  const tableProps = {
    api: `/projects/${project?._id}/features/released-report`,
    search: searchValue || searchFilter,
    filter: filters,
    tableParams: {...filterParams, ...paramsFilter},
    searchFields: ['feature', 'code'],
    eventSourceId: [
      'Feature',
      'Task',
      'View',
      'Milestone',
      'Design',
      'Bug',
      'Comment',
    ],
    showReportColumns: true,
  };

  const {data, projectEfficiency, totalUpgradedHrs} = useFetchData({
    ...tableProps,
    params: paramsFilter,
  });

  return (
    <Table
      data={data}
      renderHeader={_props => {
        return (
          <FeatureTableHeader
            {...props}
            {..._props}
            restActions={[
              <Tag
                value={`Efficiency: ${projectEfficiency || 0} %`}
                color={colors.BACKGROUND}
                textColor={colors.BRAND_HIGH}
              />,
              <Tag
                value={`Total Upgraded Hours: ${totalUpgradedHrs || 0} h`}
                color={colors.BACKGROUND}
                textColor={colors.BRAND_HIGH}
              />,
            ]}
            tableProps={tableProps}
          />
        );
      }}
      params={paramsFilter}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        featureModule,
        releasedFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        totalHours({params, navigation, showCompleteHrs: false}),
        totalUpgradedHours({showCompleteHrs: false}),
        assignees,
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params, readOnly: true}),
        updateReleasedOn({navigation, params}),
        changeModule({navigation, params}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
      ]}
    />
  );
};

export const WipFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    extraParams = {},
    searchValue: searchFilter,
    addOnFilter,
  } = props;

  const {colors} = useTheme();
  const {project, module} = params;
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );
  const {featureActivityLog} = useFeatureActions({navigation, params});
  let {params: filterParams = {}, filter} = filterValues || {};

  params = {
    ...params,
    ...filterParams,
  };

  const filters = {
    ...filter,
    ...addOnFilter,
  };

  const {navigateTo} = useLeftMenuContext() || {};

  const paramsFilter = {
    project_id: project._id,
    module_id: module?._id,
    ...extraParams,
    ...filterParams,
  };

  const tableProps = {
    api: `/projects/${project?._id}/features/wip`,
    search: searchValue || searchFilter,
    filter: filters,
    tableParams: {...filterParams, ...paramsFilter},
    searchFields: ['feature', 'code'],
    eventSourceId: [
      'Feature',
      'Task',
      'View',
      'Milestone',
      'Design',
      'Bug',
      'Comment',
    ],
    showReportColumns: true,
  };

  const {data, projectEfficiency, totalUpgradedHrs} = useFetchData({
    ...tableProps,
    params: paramsFilter,
  });

  return (
    <Table
      data={data}
      renderHeader={_props => {
        return (
          <FeatureTableHeader
            {...props}
            {..._props}
            restActions={[
              <Tag
                value={`Efficiency: ${projectEfficiency || 0} %`}
                color={colors.BACKGROUND}
                textColor={colors.BRAND_HIGH}
              />,
              <Tag
                value={`Total Upgraded Hours: ${totalUpgradedHrs || 0} h`}
                color={colors.BACKGROUND}
                textColor={colors.BRAND_HIGH}
              />,
            ]}
            tableProps={tableProps}
          />
        );
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        featureModule,
        releasedFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        totalHours({params, navigation, showCompleteHrs: false}),
        totalUpgradedHours({showCompleteHrs: false}),
        assignees,
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params, readOnly: true}),
        updateReleasedOn({navigation, params}),
        changeModule({navigation, params}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
      ]}
      limit={-1}
      skip={-1}
    />
  );
};

export const ProjectReleasedFeaturesTable = props => {
  const {
    navigation,
    route: {params},
    filter,
    showHeader,
    searchValue: searchFilter,
    filterParams,
    fromModule,
    fromProject,
    limit,
  } = props;

  const {project, milestone, module, projectlibrary_id} = params;
  const {ERROR_HIGH, WARNING_HIGH, SUCCESS_HIGH, UPPER_OUTLINE} =
    useTheme('colors');
  const toast = useToast();
  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );

  const {featureActivityLog} = useFeatureActions({navigation, params});
  const {navigateTo} = useLeftMenuContext() || {};

  const finalFilter = {
    module_id: module?._id,
    milestone_id: milestone?._id,
    ...filterValues?.filter,
    ...filter,
  };

  const finalParams = {
    ...filterValues?.params,
    ...filterParams,
    project_id: project._id,
    skipNoDataWeek: !fromProject,
  };

  const tableProps = {
    api: `/features/released/export`,
    search: searchValue || searchFilter,
    filter: finalFilter,
    tableParams: finalParams,
    searchFields: ['feature', 'code'],
    eventSourceId: ['Feature', 'Task', 'Comment'],
  };

  return (
    <GroupTable
      {...tableProps}
      api={`/projects/${project._id}/releasedFeatures`}
      limit={limit || (fromProject ? 10 : 100)}
      renderHeader={_props =>
        showHeader ? (
          <TableHeader
            title="Released"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Assignee',
                    field: 'assigned_to',
                    api: `/projects/${project._id}/members`,
                    placeholder: 'Select Assignee',
                    suggestionField: 'name',
                    secondarySuggestionField: 'email',
                    valueField: 'name',
                    avatar: true,
                    asParam: true,
                  },
                  {
                    type: 'autoComplete',
                    label: 'Module',
                    api: '/projectmodules',
                    placeholder: 'Select Module',
                    suggestionField: 'module',
                    valueField: 'module',
                    field: 'module_id',
                    filter: {project_id: project?._id},
                  },
                  !params?.milestone?._id && {
                    type: 'autoComplete',
                    label: 'Milestone',
                    api: '/projectmilestones',
                    placeholder: 'Select Milestone',
                    suggestionField: 'milestone',
                    valueField: 'milestone',
                    field: 'milestone_id',
                    filter: {project_id: project?._id},
                  },
                ]}
              />,
              <ExportExcelAction
                params={featureExportParams({
                  params,
                  ...tableProps,
                })}
                onPress={(e, {params}) => {
                  navigation.navigate('export-features', {...params});
                }}
              />,
            ]}
          />
        ) : (
          <FeatureTableHeader
            {...props}
            {..._props}
            tableProps={tableProps}
            isReleased
          />
        )
      }
      params={finalParams}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          taskQualification: true,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 200,
          visible: !fromModule && !projectlibrary_id,
        },
        releasedFeatureColumn,
        design({params, navigation}),
        {
          ...estHrsColumn({
            width: 100,
          }),
          completedHrsField: 'completedHours',
        },
        assignees,
        review({navigation}),
        featureCommentColumn({params, navigation}),
      ]}
      groupRow={{
        data: 'data',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {
            count = 0,
            bugs_count = 0,
            disqualified_tasks_count = 0,
          } = row || {};
          let featureCount = `${count} ${Labels.Feature}${
            count > 1 ? 's' : ''
          }`;
          return (
            <RowWithSeparator
              style={{gap: 8, alignItems: 'center'}}
              separator={<LineSeparator color={UPPER_OUTLINE} />}>
              <GroupContentItem value={row?.week_number} />
              <GroupContentItem value={featureCount} />
              {disqualified_tasks_count ? (
                <GroupContentItem
                  title="Disqualified"
                  value={disqualified_tasks_count + ' Disqualified'}
                />
              ) : (
                void 0
              )}
              {bugs_count ? (
                <GroupContentItem
                  title="Bugs"
                  value={bugs_count + ' hrs Bug'}
                />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const {disable_release_efficiency} = row;
          if (disable_release_efficiency) {
            return <View style={{height: 28, width: 100}} />;
          }
          const {
            totalAllocatedHrs = 0,
            efficiencyHours = 0,
            team_size = 0,
            completedHours = 0,
            efficiencyPercentage: efficiency = 0,
          } = row || {};

          let color = '';
          if (efficiency >= 80) {
            color = SUCCESS_HIGH;
          } else if (efficiency >= 65) {
            color = WARNING_HIGH;
          } else {
            color = ERROR_HIGH;
          }

          let variant = fromProject ? 'secondary' : 'primary';

          let render = (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              {fromProject ? (
                <>
                  <GroupContentItem
                    value={`${efficiencyHours.toFixed()} h / ${totalAllocatedHrs.toFixed()} h`}
                    variant={variant}
                  />
                  <GroupContentItem
                    value={team_size ? `${team_size} Members` : '0 Members'}
                    variant={variant}
                  />
                </>
              ) : (
                <GroupContentItem
                  value={`Est Hrs : ${completedHours?.toFixed(1)} h`}
                  variant={variant}
                />
              )}
            </RowWithSeparator>
          );
          if (fromProject) {
            return (
              <Col
                style={{
                  alignItems: 'flex-end',
                }}>
                <GroupContentItem
                  value={efficiency?.toFixed() + '% Efficiency'}
                  color={color}
                />
                {render}
              </Col>
            );
          }
          return render;
        },
      }}
      moreActions={() => [
        edit({navigation, params, readOnly: true}),
        moveToActive({invoke}),
        updateReleasedOn({navigation, params}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
      ]}
    />
  );
};

export const RoadMapTable = props => {
  let {
    navigation,
    route: {params},
    stage,
    searchValue,
    filter,
    filterParams,
    groupBy,
  } = props;

  const {project, milestone, module, projectlibrary_id} = params;

  const toast = useToast();
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Feature'});

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'Feature',
      'Model',
      'ThirdPartyIntegration',
      'Controller',
      'Component',
      'StyledComponent',
      'View',
      'DataService',
      'TestCase',
      'projectDataServicesLibrary',
    ],
  });

  const {navigateTo} = useLeftMenuContext() || {};

  const {featureActivityLog} = useFeatureActions({navigation, params});

  const tableProps = {
    api: `/features/active/export`,
    search: searchValue,
    filter: {
      module_id: module?._id,
      milestone_id: milestone?._id,
      ...filter,
      project_id: project?._id,
    },
    tableParams: filterParams,
    searchFields: ['feature', 'code'],
    eventSourceId: [
      'Feature',
      'Task',
      'View',
      'Milestone',
      'Design',
      'Bug',
      'Comment',
    ],
  };

  return (
    <Table
      {...tableProps}
      api={`/features/active`}
      allowDynamicGrouping={true}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      renderHeader={_props => {
        return (
          <FeatureTableHeader {...props} {..._props} tableProps={tableProps} />
        );
      }}
      params={filterParams}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 150,
          visible: !module && !projectlibrary_id,
        },
        roadmapFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        {
          ...estHrsColumn({
            width: stage === 'qa' ? 100 : 130,
          }),
          completedHrsField: 'completedHours',
        },
        dueDate({navigation}),
        assignees,
        (stage === FeatureStatus.qa.value ||
          stage === FeatureStatus.active.value) &&
          prCount({params, navigation, navigateTo}),
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params}),
        changeModule({navigation, params}),
        // updateFeatureStage({navigation, params, stage}),
        updateStatus({navigation, params}),
        markQA({invoke}),
        markReleasedForFeature({invoke, toast}),
        stage === FeatureStatus.active.value && defineArchitecture({invoke}),
        stage !== FeatureStatus.qa.value && moveToBacklog({invoke}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
        markFeatureDelete({deleteInvoke}),
      ]}
    />
  );
};

export const ArchiveFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    fromModule,
    filter,
    searchValue,
    filterParams,
  } = props;
  const {project, module, projectlibrary_id} = params;
  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      renderHeader={() => {
        return <TableHeader title={`Archived ${plural(Labels.Feature)}`} />;
      }}
      search={searchValue}
      searchFields={['feature', 'code']}
      filter={{
        module_id: module?._id,
        ...filter,
        project_id: project?._id,
        library_id: projectlibrary_id,
      }}
      params={{
        ...filterParams,
      }}
      eventSourceId={['Feature', 'Task', 'Comment']}
      api={`/features/archive`}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          ...featureModule,
          visible: !fromModule,
        },
        activeFeatureColumn,
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [edit({navigation, params}), moveToBacklog({invoke})]}
    />
  );
};

export const QAFeaturesTable = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const {project, milestone} = params;

  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      eventSourceId={[
        'Feature',
        'Task',
        'View',
        'Milestone',
        'Design',
        'Bug',
        'Comment',
      ]}
      api={`/features/active`}
      filter={{
        sub_status: {
          $in: QAStages.map(option => option.value),
        },
        project_id: project?._id,
        milestone_id: milestone?._id,
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 150,
        },
        roadmapFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        estHrsColumn({width: 100}),
        assignees,
        prCount({params, navigation, navigateTo}),
        featureCommentColumn({params, navigation}),
      ]}
    />
  );
};
