import React from 'react';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';
import {GroupFilter} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {Text} from '@unthinkable/react-core-components';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const TDSReport = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter({
    filter: {
      account: {
        $in: [{_id: '53b125b179df44bac9b0edb4', name: 'TDS Salary'}],
      },
    },
  });
  const {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    tds_report: {
      label: 'TDS Report',
      view: (
        <TDSReportTable
          filterParams={filter}
          globalParams={globalParams}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              field: 'account',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props:{
                props:{
                  fetchProps:{
                    api:'/gettdsreport',
                    addOnFilter:{...filterValues.filter},
                    globalParams:{...globalParams}
                  },
                  column:{
                    Name:"name",
                    Current:"currentAmount",
                    Previous:"previousAmount",
                    Variance:"variance"
                  }
                }
              }
            });
          }}
        />,
      ]}
    />
  );
};

const TDSReportTable = props => {
  const {
    route: {params},
    navigation,
    filterParams,
    globalParams,
  } = props;
  return (
    <Table
      {...props}
      api={'/gettdsreport'}
      addOnFilter={{...filterParams}}
      limit={1000}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Account',
          minWidth: 200,
        },
        {
          type: 'currency',
          field: 'currentAmount',
          header: 'Current',
          width: 200,
        },
        {
          type: 'currency',
          field: 'previousAmount',
          header: 'Previous',
          width: 200,
        },
        {
          type: 'currency',
          field: 'variance',
          header: 'Variance',
          width: 200,
        },
      ]}
    />
  );
};
