import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

export const voucherlineitemTables = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;

  const currentdate = new Date(row?.date);
  const monthFirstDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    1,
  );
  const monthLastDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth() + 1,
    0,
  );

  return (
    <Table
      {...props}
      api={'/voucherlineitemdetail'}
      addOnFilter={{
        date: {$gte: monthFirstDate, $lte: monthLastDate},
        category: row._id,
        account: {$in: row?.accountIds},
      }}
      onRowPress={({row}) => {
        navigation.navigate(`open-voucher`, {
          extraFilter: {_id: row?.voucher_id?._id},
        });
      }}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Voucher No',
          field: 'voucher_id.voucher_no',
          type: 'text',
          minWidth: 150,
        },
        {
          header: 'Entity',
          field: 'entity.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Account',
          field: 'account.name',
          type: 'text',
          width: 350,
        },
        {
          header: 'Category',
          field: 'category.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Cr Amount',
          field: 'cr_amount',
          type: 'number',
          aggregate: true,
          width: 100,
        },
        {
          header: 'Dr Amount',
          field: 'dr_amount',
          type: 'number',
          aggregate: true,
          width: 100,
        },
      ]}
    />
  );
};

const CashFlowTable = props => {
  const {navigation, filterParams, searchValue, params} = props;
  return (
    <GroupTable
      {...props}
      api={'/cashFlowReport'}
      addOnFilter={filterParams}
      params={params}
      eventSourceId="cashflow"
      search={searchValue}
      limit={50}
      groupRow={{
        data: '_children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.name} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 16, alignItems: 'center'}}>
              {row?.opening_balance && (
                <GroupContentItem
                  value={`${row?.opening_balance?.toFixed(0)}`}
                />
              )}
              <GroupContentItem value={`${row?.amount?.toFixed(0)}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Summary Of Funds Flow Statement',
          field: 'name',
          type: 'text',
          minWidth: 400,
        },
        {
          header: 'Opening Balance',
          field: 'opening_balance',
          type: 'number',
          width: 150,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('voucher-line-items', {row});
          },
        },
      ]}
    />
  );
};

export const CashflowReportTab = props => {
  let {route: {params} = {}} = props;
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    cashFlow: {
      label: 'Cash Flow',
      view: (
        <CashFlowTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'cashflow',
      addOnFilter: filter,
      search: searchValue,
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account',
            },
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/categories',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/downloadCashFlowReport',
              props: {
                addOnFilter: {...filter},
                params: {...params},
                globalParams: globalParams,
              },
            });
          }}
        />,
      ]}
    />
  );
};
