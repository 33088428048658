import React from 'react';
import {WithModal} from '@unthinkable/react-popper';
import moment from 'moment';
import {
  CancelButton,
  CancelText,
  Container,
  DateRangeIcon,
  SelectedLabel,
  HeaderText,
  HeaderTitleContainer,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  MonthContainer,
  MonthItemContainer,
  MonthLabel,
  MonthWiseContainer,
  OtherContainer,
  PrimaryTitle,
  QuarterContainer,
  QuarterItemContainer,
  QuarterLabel,
  QuarterRow,
  SecondaryTitle,
  Separator,
  SwitchLabel,
  YearContainer,
  SelectedValue,
  PrevNextButton,
  PreviousIcon,
  NextIcon,
  YearRow,
  YearPrevNextButton,
  YearNextIcon,
  YearText,
  YearPreviousIcon,
  CustomPickerContainer,
  StartDateContainer,
  EndDateContainer,
  SelectedLabelContainer,
} from './styles/DateRangePickerStyles';
import {SwitchInput} from '@unthinkable/react-switch';
import {Button} from '@unthinkable/react-button';
import {Grid, GridItem} from '@unthinkable/react-layout';
import {RadioGroup} from '@unthinkable/react-radio-input';
import {
  getDateRangeDisplay,
  getFinancialYear,
  getFinancialYearDisplay,
  getZeroTimeDate,
  getZeroTimeDateRange,
} from './DateUtility';
import {DatePicker} from '.';

const QuarterOptions = [
  {
    label: 'Q1',
    value: 2,
  },
  {
    label: 'Q2',
    value: 3,
  },
  {
    label: 'Q3',
    value: 4,
  },
  {
    label: 'Q4',
    value: 1,
  },
];

const MonthOptions = [
  {
    label: 'Apr',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'Jun',
    value: 5,
  },
  {
    label: 'Jul',
    value: 6,
  },
  {
    label: 'Aug',
    value: 7,
  },
  {
    label: 'Sep',
    value: 8,
  },
  {
    label: 'Oct',
    value: 9,
  },
  {
    label: 'Nov',
    value: 10,
  },
  {
    label: 'Dec',
    value: 11,
  },
  {
    label: 'Jan',
    value: 0,
  },
  {
    label: 'Feb',
    value: 1,
  },
  {
    label: 'Mar',
    value: 2,
  },
];

const PickerModal = ({
  styles,
  hide,
  value = {},
  onChangeValue: onChangeValueProp,
  options = [],
  showMonthWiseBreakup,
  skipQuarter,
}) => {
  const [state, setState] = React.useState(value);

  const currentFyYear = getFinancialYear(state?.from);

  const onChangeValue = value => {
    setState({...state, ...value});
  };

  const onApply = () => {
    let {from, to, ...restState} = state;
    onChangeValueProp({...restState, from, to});
    hide();
  };

  const onPressFinancialYear = () => {
    onChangeValue({
      filterOf: 'FY',
      ...getZeroTimeDateRange('currentFinancialYear', {
        date: state?.from,
      }),
    });
  };

  const onChangeYear = ({source = 'next'}) => {
    let from, to;
    if (source === 'next') {
      from = moment(state.from).add(1, 'year').toDate();
    } else {
      from = moment(state.from).subtract(1, 'year').toDate();
    }
    onChangeValue({
      filterOf: 'FY',
      ...getZeroTimeDateRange('currentFinancialYear', {
        date: from,
      }),
    });
  };

  const onChangeQuarter = index => {
    let from = moment(state.from).quarter(index).startOf('quarter').toDate();
    let to = moment(state.to).quarter(index).endOf('quarter').toDate();
    if (index === 1) {
      from = moment(from)
        .set('year', currentFyYear + 1)
        .toDate();
      to = moment(to)
        .set('year', currentFyYear + 1)
        .toDate();
    } else {
      from = moment(from).set('year', currentFyYear).toDate();
      to = moment(to).set('year', currentFyYear).toDate();
    }
    from = getZeroTimeDate(from);
    to = getZeroTimeDate(to, true);
    onChangeValue({
      from,
      to,
      filterOf: 'Quarter',
    });
  };

  const onChangeMonth = index => {
    let from = moment(state.from).month(index).startOf('month').toDate();
    let to = moment(state.to).month(index).endOf('month').toDate();
    if (index > 2) {
      from = moment(from).set('year', currentFyYear).toDate();
      to = moment(to).set('year', currentFyYear).toDate();
    } else {
      from = moment(from)
        .set('year', currentFyYear + 1)
        .toDate();
      to = moment(to)
        .set('year', currentFyYear + 1)
        .toDate();
    }
    from = getZeroTimeDate(from);
    to = getZeroTimeDate(to, true);
    onChangeValue({
      from,
      to,
      monthWiseBreakup: false,
      filterOf: 'Month',
    });
  };

  const isQuarterSelected = quarterIndex => {
    if (state?.filterOf !== 'Quarter') {
      return false;
    }
    let quarter = moment(state?.from).quarter();
    if (quarter === quarterIndex) {
      return true;
    }
    return false;
  };

  const isMonthSelected = monthIndex => {
    if (state?.filterOf !== 'Month') {
      return false;
    }
    let month = moment(state?.from).month();
    if (month === monthIndex) {
      return true;
    }
    return false;
  };

  const displayHeaderText = state?.from
    ? getDateRangeDisplay(state)
    : 'Select Range';

  return (
    <ModalContainer styles={styles}>
      <ModalHeader styles={styles}>
        <HeaderTitleContainer styles={styles}>
          <DateRangeIcon styles={styles} />
          <HeaderText styles={styles}>{displayHeaderText}</HeaderText>
        </HeaderTitleContainer>
        <SwitchLabel styles={styles}>Custom</SwitchLabel>
        <SwitchInput
          styles={styles?.switchInput}
          value={state?.filterOf === 'Custom'}
          onChangeValue={() => {
            let newFilterOf = state?.filterOf === 'Custom' ? '' : 'Custom';
            onChangeValue({
              filterOf: newFilterOf,
              monthWiseBreakup: false,
            });
          }}
        />
      </ModalHeader>
      <ModalBody styles={styles}>
        {state?.filterOf === 'Custom' ? (
          <CustomPickerContainer styles={styles}>
            <StartDateContainer styles={styles}>
              <DatePicker
                styles={styles.datePicker}
                label="Start Date"
                value={state?.from}
                onChangeValue={value => {
                  onChangeValue({from: !!value ? value : undefined});
                }}
              />
            </StartDateContainer>
            <EndDateContainer styles={styles}>
              <DatePicker
                styles={styles.datePicker}
                eod
                label="End Date"
                value={state?.to}
                onChangeValue={value => {
                  onChangeValue({to: !!value ? value : undefined});
                }}
              />
            </EndDateContainer>
          </CustomPickerContainer>
        ) : (
          <>
            <YearContainer
              styles={styles}
              isSelected={['FY', 'Quarter', 'Month'].includes(state?.filterOf)}
              onPress={onPressFinancialYear}>
              <PrimaryTitle styles={styles}>Financial year</PrimaryTitle>
              <YearRow styles={styles}>
                <YearPrevNextButton
                  styles={styles}
                  onPress={() => onChangeYear({source: 'prev'})}>
                  <YearPreviousIcon styles={styles} />
                </YearPrevNextButton>
                <YearText styles={styles}>
                  {getFinancialYearDisplay(state?.from)}
                </YearText>
                <YearPrevNextButton
                  disabled={currentFyYear === getFinancialYear()}
                  styles={styles}
                  onPress={() => onChangeYear({source: 'next'})}>
                  <YearNextIcon styles={styles} />
                </YearPrevNextButton>
              </YearRow>
            </YearContainer>
            {skipQuarter ? (
              void 0
            ) : (
              <QuarterContainer styles={styles}>
                {/* <SecondaryTitle styles={styles}>Quarter</SecondaryTitle> */}
                <QuarterRow styles={styles}>
                  {QuarterOptions.map((option, index) => (
                    <QuarterItemContainer
                      isSelected={isQuarterSelected(option.value)}
                      key={index}
                      styles={styles}
                      onPress={() => {
                        onChangeQuarter(option.value);
                      }}>
                      <QuarterLabel key={index} styles={styles}>
                        {option.label}
                      </QuarterLabel>
                    </QuarterItemContainer>
                  ))}
                </QuarterRow>
              </QuarterContainer>
            )}
            {showMonthWiseBreakup ? (
              <MonthWiseContainer styles={styles}>
                <PrimaryTitle styles={styles}>Month-wise breakup</PrimaryTitle>
                <SwitchInput
                  disabled={
                    !(state?.filterOf === 'FY' || state?.filterOf === 'Quarter')
                  }
                  styles={styles?.switchInput}
                  value={state?.monthWiseBreakup}
                  onChangeValue={() => {
                    onChangeValue({monthWiseBreakup: !state?.monthWiseBreakup});
                  }}
                />
              </MonthWiseContainer>
            ) : (
              void 0
            )}
            <Separator styles={styles} />
            <MonthContainer styles={styles}>
              {/* <SecondaryTitle styles={styles}>Month</SecondaryTitle> */}
              <Grid columns={3} colGap={8} rowGap={8}>
                {MonthOptions.map((option, index) => (
                  <GridItem key={index} size={1}>
                    <MonthItemContainer
                      isSelected={isMonthSelected(option.value)}
                      styles={styles}
                      onPress={() => onChangeMonth(option.value)}>
                      <MonthLabel styles={styles}>{option.label}</MonthLabel>
                    </MonthItemContainer>
                  </GridItem>
                ))}
              </Grid>
            </MonthContainer>
            {options?.length ? (
              <OtherContainer styles={styles}>
                <SecondaryTitle styles={styles}>Other options</SecondaryTitle>
                <RadioGroup
                  value={state?.filterOf}
                  onChangeValue={value => {
                    onChangeValue({
                      filterOf: value,
                      ...getZeroTimeDateRange(value),
                      monthWiseBreakup: false,
                    });
                  }}
                  options={options}
                  styles={styles?.radioGroup}
                />
              </OtherContainer>
            ) : (
              void 0
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter styles={styles}>
        <CancelButton styles={styles} onPress={hide}>
          <CancelText styles={styles}>Cancel</CancelText>
        </CancelButton>
        <Button onPress={onApply}>Apply</Button>
      </ModalFooter>
    </ModalContainer>
  );
};

export const PeriodRangeSelector = ({
  styles,
  value,
  onChangeValue,
  options = [],
  skipQuarter,
  monthWiseBreakup: showMonthWiseBreakup,
  resetAllowed,
}) => {
  const {filterOf, monthWiseBreakup} = value || {};
  let label = '';
  let canMoveNextPrev = true;
  if (filterOf === 'FY') {
    label = 'Year';
    if (monthWiseBreakup) {
      label = label + '(month-wise)';
    }
  } else if (filterOf === 'Quarter') {
    label = 'Quarter';
    if (monthWiseBreakup) {
      label = label + '(month-wise)';
    }
  } else if (filterOf === 'Month') {
    label = 'Month';
  } else if (filterOf === 'week') {
    if (
      moment(value.from).format('YYYY-MM-DD') ===
      moment(new Date()).startOf('week').format('YYYY-MM-DD')
    ) {
      label = 'This Week';
    } else if (
      moment(value.from).format('YYYY-MM-DD') ===
      moment(new Date())
        .subtract(1, 'Week')
        .startOf('week')
        .format('YYYY-MM-DD')
    ) {
      label = 'Last Week';
    } else {
      label = options.find(option => option.value === filterOf)?.label;
    }
    if (!label) label = 'Week';
  } else if (filterOf === 'day') {
    if (
      moment(value.from).format('YYYY-MM-DD') ===
      moment(new Date()).format('YYYY-MM-DD')
    ) {
      label = 'Today';
    } else if (
      moment(value.from).format('YYYY-MM-DD') ===
      moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
    ) {
      label = 'Yesterday';
    } else {
      label = options.find(option => option.value === filterOf)?.label;
    }
    if (!label) label = 'Day';
  } else if (filterOf === 'biMonthly') {
    label = options.find(option => option.value === filterOf)?.label;
  } else if (filterOf === 'Custom') {
    label = 'Custom';
    canMoveNextPrev = false;
  } else if (
    filterOf &&
    options?.length &&
    options.find(option => option.value === filterOf)
  ) {
    label = options.find(option => option.value === filterOf)?.label;
    canMoveNextPrev = false;
  } else if (value?.from && value?.to) {
    label = 'Custom';
    canMoveNextPrev = false;
    value = {...value, filterOf: 'Custom'};
  }

  const onPressNextPrev = ({source = 'next'}) => {
    let from, to;
    if (source === 'next') {
      if (filterOf === 'FY') {
        from = moment(value.from).add(1, 'year').toDate();
        to = moment(value.to).add(1, 'year').toDate();
      } else if (filterOf === 'Quarter') {
        from = moment(value.from).add(3, 'month').toDate();
        to = moment(value.to).add(3, 'month').toDate();
      } else if (filterOf === 'Month') {
        from = moment(value.from).add(1, 'month').toDate();
        to = moment(value.to).add(1, 'month').toDate();
      } else if (filterOf === 'week') {
        from = moment(value.from).add(7, 'day').toDate();
        to = moment(value.to).add(7, 'day').toDate();
      } else if (filterOf === 'day') {
        from = moment(value.from).add(1, 'day').toDate();
        to = moment(value.to).add(1, 'day').toDate();
      } else if (filterOf === 'biMonthly') {
        const currentStart = moment(value.from);
        if (currentStart.date() == 1) {
          from = moment(value.from).set('date', 16).toDate();
          to = getZeroTimeDate(
            moment(value.from).endOf('month').toDate(),
            true,
          );
        } else {
          from = moment(value.from).add(1, 'month').set('date', 1).toDate();
          to = getZeroTimeDate(
            moment(value.from)
              .add(1, 'month')
              .set('date', 15)
              .endOf('day')
              .toDate(),
            true,
          );
        }
      }
    } else {
      if (filterOf === 'FY') {
        from = moment(value.from).subtract(1, 'year').toDate();
        to = moment(value.to).subtract(1, 'year').toDate();
      } else if (filterOf === 'Quarter') {
        from = moment(value.from).subtract(3, 'month').toDate();
        to = moment(value.to).subtract(3, 'month').toDate();
      } else if (filterOf === 'Month') {
        from = moment(value.from).subtract(1, 'month').toDate();
        to = moment(value.to).subtract(1, 'month').toDate();
      } else if (filterOf === 'week') {
        from = moment(value.from).subtract(7, 'day').toDate();
        to = moment(value.to).subtract(7, 'day').toDate();
      } else if (filterOf === 'day') {
        from = moment(value.from).subtract(1, 'day').toDate();
        to = moment(value.to).subtract(1, 'day').toDate();
      } else if (filterOf === 'biMonthly') {
        const currentStart = moment(value.from);
        if (currentStart.date() == 1) {
          from = moment(value.from)
            .subtract(1, 'month')
            .set('date', 16)
            .toDate();
          to = getZeroTimeDate(
            moment(value.from).subtract(1, 'month').endOf('month').toDate(),
            true,
          );
        } else {
          from = moment(value.from).set('date', 1).toDate();
          to = getZeroTimeDate(
            moment(value.from).set('date', 15).endOf('day').toDate(),
            true,
          );
        }
      }
    }
    onChangeValue({...value, from, to});
  };

  let isDisabledNext = false;
  let currentFyYear = getFinancialYear(value?.from);
  if (filterOf === 'FY') {
    isDisabledNext = currentFyYear === getFinancialYear();
  } else if (filterOf === 'Quarter') {
    let quarter = moment(value?.from).quarter();
    if (quarter === 1) isDisabledNext = currentFyYear === getFinancialYear();
  } else if (filterOf === 'Month') {
    let month = moment(value?.from).month();
    if (month === 2) isDisabledNext = currentFyYear === getFinancialYear();
  }

  return (
    <WithModal
      styles={styles?.modal}
      renderModal={({hide}) => {
        return (
          <PickerModal
            styles={styles}
            hide={hide}
            value={value}
            onChangeValue={onChangeValue}
            options={options}
            showMonthWiseBreakup={showMonthWiseBreakup}
            skipQuarter={skipQuarter}
          />
        );
      }}>
      <Container styles={styles}>
        <DateRangeIcon styles={styles} />
        {value?.from && value?.to && label ? (
          <SelectedLabelContainer styles={styles}>
            <SelectedLabel styles={styles}>{label}</SelectedLabel>
          </SelectedLabelContainer>
        ) : (
          void 0
        )}
        {canMoveNextPrev ? (
          <PrevNextButton
            styles={styles}
            onPress={() => {
              onPressNextPrev({source: 'prev'});
            }}>
            <PreviousIcon styles={styles} />
          </PrevNextButton>
        ) : (
          void 0
        )}
        <SelectedValue styles={styles}>
          {getDateRangeDisplay(value)}
        </SelectedValue>
        {canMoveNextPrev ? (
          <PrevNextButton
            disabled={isDisabledNext}
            styles={styles}
            onPress={() => {
              onPressNextPrev({source: 'next'});
            }}>
            <NextIcon styles={styles} />
          </PrevNextButton>
        ) : (
          void 0
        )}
      </Container>
    </WithModal>
  );
};
