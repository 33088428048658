import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DepartmentForm = props => {
  const {route: {params} = {}} = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/vendorinvoice/changeDepartment',
    eventSourceId: 'VendorInvoiceView',
  });
  return (
    <Form
      defaultValues={{selectedIds}}
      onSubmit={onSubmit}
      submitAction="Save"
      fields={{
        department: {name: 1},
      }}
      {...props}
    />
  );
};

export const AddDepartment = props => {
  return (
    <DepartmentForm
      header="Add Department"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'department',
          label: 'Department',
          api: '/activeDepartmentSuggestions',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
