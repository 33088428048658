import React from 'react';
import {
  CommonActions,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native';
import {AutoCompleteLink} from '../form-editors/autocomplete/AutoComplete';
import {Breadcrumbs} from './Breadcrumbs';
import {useAppModuleContext} from '../../providers/AppModuleProvider';
import {IconButton} from '../button/IconButton';
import {useAppStateContext} from '../../providers/AppState';
import {AppModuleDropdown} from './AppModuleDropdown';

const dropdownItems = ({moduleData, params, user}) => {
  const filteredModuleData = moduleData.reduce((acc, module) => {
    let {visible = true} = module;

    if (typeof visible === 'function') {
      visible = visible({params, user, module, fromModule: true});
    }

    if (visible) {
      acc.push(module);
    }
    return acc;
  }, []);

  const projectFilter = user?.org ? {user_organization: user?.org} : {};
  const projectApi = user?.org
    ? `/projects`
    : `/projects/${params?.parentTabApiKey}`;

  return {
    appModule: {
      suggestionField: 'name',
      secondarySuggestionField: 'desc',
      valueField: 'name',
      options: filteredModuleData,
    },
    project: {
      suggestionField: 'project',
      valueField: 'project',
      api: projectApi,
      filter: projectFilter,
      searchPlaceholder: 'Search Project',
    },
    module: {
      suggestionField: 'module',
      valueField: 'module',
      filter: {project_id: params?.project?._id},
      api: '/projectmodules',
      searchPlaceholder: 'Search Module',
    },
    departments: {
      suggestionField: 'name',
      valueField: 'name',
      api: '/activeDepartmentSuggestions',
      searchPlaceholder: 'Search Department',
    },
    milestone: {
      suggestionField: 'milestone',
      valueField: 'milestone',
      filter: {project_id: params?.project?._id},
      api: '/projectmilestones',
      searchPlaceholder: 'Search Milestone',
    },
    projectlibrary: {
      suggestionField: 'name',
      valueField: 'name',
      filter: {
        project_id: params?.project_id,
        type: params?.projectlibrary?.type,
      },
      api: '/projectlibraries',
      searchPlaceholder: 'Search Library',
    },
  };
};

export const useBreadcrumb = props => {
  const {route: currentRoute} = props;
  const navigation = useNavigation();
  const {routes} = useNavigationState(state => state);

  const currentIndex = routes.findIndex(
    route => route.key === currentRoute.key,
  );

  const currentRoutes = routes.slice(0, currentIndex + 1);

  const onChangeValue = ({index, newParams}) => {
    let newRoutes = routes.slice(0, index + 1).map((route, i) => {
      if (i === index) {
        const {itemId, screenId, tabId, ...rest} = route.params || {};
        return {
          ...route,
          params:
            route?.params?.breadcrumb?.dropdown?.field === 'appModule' &&
            route?.params?.appModule?.id !== newParams?.appModule?.id
              ? {...rest, ...newParams}
              : {...route?.params, ...newParams},
        };
      }
      return route;
    });

    navigation.dispatch(
      CommonActions.reset({
        index: newRoutes.length - 1,
        routes: newRoutes,
      }),
    );
  };

  const onClearValue =
    ({index}) =>
    () => {
      const newRoutes = currentRoutes.slice(0, index);
      navigation.dispatch(
        CommonActions.reset({
          index: newRoutes.length - 1,
          routes: newRoutes,
        }),
      );
    };

  const onItemClick =
    ({index}) =>
    () => {
      let newRoutes = routes.slice(0, index + 1);
      navigation.dispatch(
        CommonActions.reset({
          index: newRoutes.length - 1,
          routes: newRoutes,
        }),
      );
    };

  const onHomeClick = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'app-module',
            params: {
              appModule_id: currentRoute?.params?.appModule_id,
              appModule: currentRoute?.params?.appModule,
            },
          },
        ],
      }),
    );
  };

  return {
    onChangeValue,
    onClearValue,
    onItemClick,
    onHomeClick,
    routes,
  };
};

export const Breadcrumb = props => {
  const {routes, onChangeValue, onClearValue, onItemClick, onHomeClick} =
    useBreadcrumb(props);

  const {moduleData} = useAppModuleContext() || {};
  const {user} = useAppStateContext();

  let items = [
    <IconButton
      onPress={onHomeClick}
      icon={props?.styles?.unthinkableFlagIcon}
    />,
  ];
  let itemsKey = [];
  routes.forEach((route, index) => {
    const {params} = route;
    const {breadcrumb} = params || {};
    if (!breadcrumb) {
      return null;
    }

    let {title, dropdown} = breadcrumb;
    if (dropdown && !itemsKey.includes(dropdown.field)) {
      const {field, onChangeParams, ...rest} = dropdown;

      let BreadCrumbComponent =
        field === 'appModule' ? AppModuleDropdown : AutoCompleteLink;

      items.push({
        Component: (
          <BreadCrumbComponent
            {...dropdownItems({moduleData, params, user})[field]}
            {...rest}
            modalWidth={300}
            value={params[field]}
            variant={'link'}
            searchingInModal
            onClearValue={onClearValue({index})}
            onLinkPress={onItemClick({index})}
            onChangeValue={value => {
              let newParams = onChangeParams
                ? onChangeParams(value)
                : {
                    [field]: value,
                    [`${field}_id`]: value?._id || value?.id,
                  };
              onChangeValue({index, newParams});
            }}
          />
        ),
      });
      itemsKey.push(field);
    } else if (title && !itemsKey.includes(title)) {
      items.push({
        title,
        onPress: onItemClick({index}),
      });
      itemsKey.push(title);
    }
  });
  return <Breadcrumbs items={items} />;
};
