import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {useAppStateContext} from '../../../providers/AppState';
import {
  SubscriptionApproverTable,
  SubscriptionFinanceApproverTable,
  SubscriptionOrgApproverTable,
  SubscriptionRequesterTable,
} from './SubscriptionPlannedTable';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import { Button } from '../../../components/button/Button';
export const SubscriptionRequesterTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <SubscriptionRequesterTable
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{
            // status: 'Requested',
            requested_by: employee._id,
            $or: [
              {status: 'Requested'},
              {status: 'Approved', approver_status: 'Finance'},
            ],
          }}
          //   searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: '/subscriptionplanned/details',
      //   search: searchValue,
      //   searchFields: 'invoice_number',
      addOnFilter: {
        requested_by: employee._id,
        // status: 'Requested',
        $or: [
          {status: 'Requested'},
          {status: 'Approved', approver_status: 'Finance'},
        ],
        ...filterValues.filter,
      },
      limit: 500,
    },
    Active: {
      label: 'Active Subscription',
      view: (
        <SubscriptionRequesterTable
          tab={'Active'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Active',
            requested_by: employee._id,
            cancel_subscription_request: {$in: [null, false]},
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'subscriptionplanned',
      api: '/subscriptionplanned/details',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Active',
        cancel_subscription_request: {$in: [null, false]},
        ...filterValues.filter,
      },
    },
    stop_txn: {
      label: 'Cancel Subscription Requests',
      view: (
        <SubscriptionRequesterTable
          tab={'StopTxn'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Active',
            requested_by: employee._id,
            cancel_subscription_request: true,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'subscriptionplanned',
      api: '/subscriptionplanned/details',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Active',
        cancel_subscription_request: true,
        ...filterValues.filter,
      },
    },
    Inactive: {
      label: 'Cancel Subscription',
      view: (
        <SubscriptionRequesterTable
          tab={'Inactive'}
          filterValues={filterValues}
          addOnFilter={{status: 'Inactive', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'subscriptionplanned',
      api: '/subscriptionplanned/details',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Inactive',
        ...filterValues.filter,
      },
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <SubscriptionRequesterTable
          tab={'Rejected'}
          filterValues={filterValues}
          addOnFilter={{status: 'Rejected', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      api: '/subscriptionplanned/details',
      eventSourceId: 'subscriptionplanned',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/subscriptioncategories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
              filter: {
                employee_id: {$exists: false},
              },
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'Renewal Date',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'Next Renwal Date',
              field: 'request_date',
            },
          ]}
        />,
        <AddButton title="Subscription" view={'add-subscription-request'} />,
      ]}
    />
  );
};

export const SubscriptionApproverTab = props => {
  let {route: {params} = {}, orgView} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  let addOnFilter = {};
  if (!orgView) {
    addOnFilter = {approver: employee._id};
  }
  const tabs = {
    Active: {
      label: 'Requested',
      view: (
        <SubscriptionApproverTable
          tab={'ApproverActive'}
          addOnFilter={{status: 'Requested'}}
          orgView={orgView}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        ...filterValues.filter,
        status: 'Requested',
        ...addOnFilter,
      },
    },
    close: {
      label: 'Closed',
      view: (
        <SubscriptionApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            status: {$in: ['Approved', 'Rejected', 'Active', 'Inactive']},
            // approver_status: 'Team Head',
            ...filterValues.filter,
          }}
          orgView={orgView}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        status: {$in: ['Approved', 'Rejected', 'Active', 'Inactive']},
        // approver_status: 'Team Head',
        ...filterValues.filter,
        ...addOnFilter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
              filter: {
                employee_id: {$exists: false},
              },
            },
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/subscriptioncategories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Status',
            //   options: ['Approved', 'Rejected'],
            //   field: 'status',
            // },
          ]}
        />,
      ]}
    />
  );
};

export const FinanceSubscriptionTab = props => {
  let {routes: {params} = {},navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let currentdate = new Date();
  let monthFirstDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    1,
  );
  const currentDate = getZeroTimeDate(new Date());
  const threeMonthsDate = getZeroTimeDate(currentDate);
  threeMonthsDate.setMonth(threeMonthsDate.getMonth() + 3);
  const tabs = {
    requested: {
      label: 'Requested',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'FinanceRequested'}
          addOnFilter={{
            status: 'Approved',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: false},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/subscriptionplanned/details',
      addOnFilter: {
        status: 'Approved',
        approver_status: 'Finance',
        approved_by_finance_date: {$exists: false},
        ...filterValues.filter,
      },
      limit: 500,
    },
    active_transaction: {
      label: 'Active Subscription',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: true},
            cancel_subscription_request: {$in: [null, false]},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        approver_status: 'Finance',
        approved_by_finance_date: {$exists: true},
        cancel_subscription_request: {$in: [null, false]},
        ...filterValues.filter,
      },
    },
    upcoming_transaction: {
      label: 'Upcoming Subscription',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: true},
            cancel_subscription_request: {$in: [null, false]},
            next_renewal_date: {$gte: currentDate, $lte: threeMonthsDate},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        approver_status: 'Finance',
        approved_by_finance_date: {$exists: true},
        cancel_subscription_request: {$in: [null, false]},
        next_renewal_date: {$gte: currentDate, $lte: threeMonthsDate},
        ...filterValues.filter,
      },
    },
    overdue_transaction: {
      label: 'Overdue Subscription',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: true},
            cancel_subscription_request: {$in: [null, false]},
            next_renewal_date: {$lt: getZeroTimeDate(new Date())},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        approver_status: 'Finance',
        approved_by_finance_date: {$exists: true},
        cancel_subscription_request: {$in: [null, false]},
        next_renewal_date: {$lt: getZeroTimeDate(new Date())},
        ...filterValues.filter,
      },
    },
    stop_txn: {
      label: 'Cancel Subscription Requests',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'StopTxn'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: true},
            cancel_subscription_request: true,
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        approver_status: 'Finance',
        approved_by_finance_date: {$exists: true},
        cancel_subscription_request: true,
        ...filterValues.filter,
      },
    },
    inactive_transaction: {
      label: 'Cancel Subscription',
      view: (
        <SubscriptionFinanceApproverTable
          tab={'InactiveTransactions'}
          addOnFilter={{
            status: 'Inactive',
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        status: 'Inactive',
        ...filterValues.filter,
      },
    },
    rejected: {
      label: 'Rejected',
      view: (
        <SubscriptionFinanceApproverTable
          addOnFilter={{
            status: 'Rejected',
            rejected_by: 'Finance',
            // rejected_by_finance_date: {$exists: true},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/subscriptionplanned/details',
      addOnFilter: {
        status: 'Rejected',
        rejected_by: 'Finance',
        ...filterValues.filter,
      },
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/subscriptioncategories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
              filter: {
                employee_id: {$exists: false},
              },
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'Renewal Date',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'Next Renwal Date',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'Next Renwal Date',
              field: 'request_date',
            },
            {
              type: 'autoComplete',
              label: 'Subscription Request Type',
              options: [
                {label: 'Close', value: 'Stop'},
                {label: 'Renew', value: 'Renew'},
              ],
              placeholder: 'Select',
              field: 'subscription_request_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Button
          text="Import Subscription"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'subscriptionDetails',
              model: 'SubscriptionPlanned',
            });
          }}
        />,
      ]}
    />
  );
};

export const OrgSubscriptionTab = props => {
  let {routes: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let currentdate = new Date();
  let monthFirstDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    1,
  );

  const tabs = {
    requested: {
      label: 'Requested',
      view: (
        <SubscriptionOrgApproverTable
          tab={'FinanceRequested'}
          addOnFilter={{
            $or: [
              {status: 'Requested'},
              {status: 'Approved', approver_status: 'Finance'},
            ],
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/subscriptionplanned/details',
      addOnFilter: {
        $or: [
          {status: 'Requested'},
          {status: 'Approved', approver_status: 'Finance'},
        ],
        ...filterValues.filter,
      },
      limit: 500,
    },
    active_transaction: {
      label: 'Active Subscription',
      view: (
        <SubscriptionOrgApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            $or: [
              {
                subscription_request_type: {$exists: false},
              },
              {
                subscription_request_type: null,
              },
            ],
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        $or: [
          {
            subscription_request_type: {$exists: false},
          },
          {
            subscription_request_type: null,
          },
        ],
        ...filterValues.filter,
      },
    },
    stop_txn: {
      label: 'Upgrade/Stop Subscription Requests',
      view: (
        <SubscriptionOrgApproverTable
          tab={'StopTxn'}
          addOnFilter={{
            // $or: [
            //   {
            //     status: 'Approved',
            //     approver_status: 'Finance',
            //     approved_by_finance_date: {$exists: true},
            //   },
            //   {
            //     status: {$in: ['Rejected', 'Active', 'Inactive']},
            //     approver_status: 'Finance',
            //   },
            // ],
            status: 'Active',
            subscription_request_type: {$in: ['Stop', 'Renew']},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        // $or: [
        //   {
        //     status: 'Approved',
        //     approver_status: 'Finance',
        //     approved_by_finance_date: {$exists: true},
        //   },
        //   {
        //     status: {$in: ['Rejected', 'Active', 'Inactive']},
        //     approver_status: 'Finance',
        //   },
        // ],
        status: 'Active',
        subscription_request_type: {$in: ['Stop', 'Renew']},
        ...filterValues.filter,
      },
    },
    inactive_transaction: {
      label: 'Inactive Subscription',
      view: (
        <SubscriptionOrgApproverTable
          tab={'InactiveTransactions'}
          addOnFilter={{
            status: 'Inactive',
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'subscriptionplanned',
      api: `/subscriptionplanned/details`,
      addOnFilter: {
        status: 'Inactive',
        ...filterValues.filter,
      },
    },
    rejected: {
      label: 'Rejected',
      view: (
        <SubscriptionOrgApproverTable
          addOnFilter={{
            status: 'Rejected',
            // rejected_by_finance_date: {$exists: true},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/subscriptionplanned/details',
      addOnFilter: {
        status: 'Rejected',
        ...filterValues.filter,
      },
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/subscriptioncategories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
              filter: {
                employee_id: {$exists: false},
              },
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
          ]}
        />,
      ]}
    />
  );
};
