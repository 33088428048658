import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';
import React, {useEffect, useState} from 'react';
import {Tag} from '../../../components/chip/Chip';
import {Form, MultiStepForm} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

//Billing Details
//Account
//Account Management
//Contact Detail
//Contact Detail Pm
//Contact Detail Billing

const customerComputations = fetch => ({
  'set booleans for contact details': {
    compute: async value => {
      if (value?.contact_detail_pm && value.contact_detail_pm.length > 0) {
        value.contact_detail_pm.forEach(data => {
          if (!data.is_pm_contact) {
            data.is_pm_contact = true;
          }
        });
      }
      if (
        value?.contact_detail_billing &&
        value.contact_detail_billing.length > 0
      ) {
        value.contact_detail_billing.forEach(data => {
          if (!data.is_billing_contact) {
            data.is_billing_contact = true;
          }
        });
      }
      if (value?.contact_detail && value.contact_detail.length > 0) {
        value.contact_detail.forEach(data => {
          data.is_billing_contact = false;
          data.is_pm_contact = false;
        });
      }
    },
    multi: true,
    dependencies: ['contact_detail_pm', 'contact_detail_billing'],
  },

  // 'set booleans for contact details': {
  //   compute: async value => {
  //     if (value?.contact_detail && value.contact_detail.length > 0) {
  //       value.contact_detail.forEach(data => {
  //         if (data.relation) {
  //           if (data.relation == 'PM') data.is_pm_contact = true;
  //           if (data.relation == 'Finance') data.is_billing_contact = true;
  //           if (data.relation == 'Decision Maker') data.is_internal_poc = true;
  //         }
  //       });
  //     }
  //   },
  //   multi: true,
  //   dependencies: ['contact_detail'],
  // },

  lead_score: {
    compute: async value => {
      if (
        value?.employee_count &&
        value?.geography_location &&
        value?.organization_category
      ) {
        const {data: employeeCountData} = await fetch({
          uri: `/employeesizes/${value?.employee_count?._id}`,
          props: {
            fields: {
              _id: 1,
              score: 1,
            },
            only: true,
          },
        });
        const {data: locationData} = await fetch({
          uri: `/geographylocations/${value?.geography_location?._id}`,
          props: {
            fields: {
              _id: 1,
              score: 1,
            },
            only: true,
          },
        });
        const {data: orgData} = await fetch({
          uri: `/organizationcategories/${value?.organization_category?._id}`,
          props: {
            fields: {
              _id: 1,
              score: 1,
            },
            only: true,
          },
        });
        const lead_value =
          (employeeCountData?.score || 1) *
          (locationData?.score || 1) *
          (orgData?.score || 1);
        return lead_value;
      }
    },
    // multi: true,
    dependencies: [
      'employee_count',
      'geography_location',
      'organization_category',
    ],
  },
});

const losCustomerComputations = fetch => ({
  lost_type: {
    compute: async value => {
      if (
        value?.lost_reason == 'Project(s) Completed' ||
        value?.lost_reason == 'Poor delivery quality'
      ) {
        return 'Lost';
      } else if (value?.lost_reason == 'Payment Issue') {
        return 'Dormant';
      }
    },
    dependencies: ['lost_reason'],
  },
});

export const CustomerForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {customerId} = params;
  const {fetch} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Customer',
    uri: '/customers',
    ...props,
  });

  return (
    <MultiStepForm
      api={`/customer/editcustomer/${customerId}`}
      onSubmit={onSubmit}
      submitAction={'Save'}
      saveOnNext={false}
      computations={{...customerComputations(fetch)}}
      steps={[
        {
          title: 'About Customer',
          fields: [
            {
              type: 'text',
              field: 'name',
              label: 'Customer Name',
              size: 4,
              required: true,
            },
            {
              label: 'Account Owner',
              field: 'owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
              required: true,
            },

            {
              label: 'Sales Owner',
              field: 'sales_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },

            {
              type: 'text',
              field: 'client_rating',
              type: 'autoComplete',
              options: ['A+', 'A', 'B', 'C', 'D', 'E'],
              label: 'Client Rating',
              size: 4,
              required: true,
            },
            {
              type: 'text',
              field: 'website',
              label: 'Website Link',
              size: 4,
            },
            {
              field: 'employee_count',
              placeholder: 'Employee Count',
              label: 'Employee Count',
              type: 'autoComplete',
              api: `/employeeSize/active`,
              suggestionField: 'number',
              valueField: 'number',
              required: true,

              size: 4,
            },
            {
              field: 'organization_category',
              placeholder: 'Organization Category',
              label: 'Organization Category',
              type: 'autoComplete',
              api: `/organizationcategories`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              size: 4,
            },
            {
              field: 'geography_location',
              placeholder: 'Location',
              label: 'Geography Location',
              type: 'autoComplete',
              api: `/geographylocations`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              size: 4,
            },
            {
              field: 'country',
              placeholder: 'Country',
              label: 'Country',
              type: 'autoComplete',
              api: `/countries`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            {
              type: 'text',
              field: 'correspondence_country_code',
              label: 'Country Code',
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: `/industries`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Industry',
              label: 'Industry',
              label: 'Industry',
              required: true,
              size: 4,
            },

            {
              type: 'text',
              field: 'source',
              type: 'autoComplete',
              options: ['Marketing', 'Inside Sales', 'Bidding', 'Tender'],
              label: 'Source',
              size: 4,
              required: true,
            },
            {
              type: 'text',
              field: 'place_of_supply',
              label: 'Place to Supply',
              required: true,
              size: 4,
            },
            {
              type: 'number',
              field: 'credit_days',
              label: 'Credit Days',
              required: true,
              size: 4,
            },
            {
              type: 'text',
              field: 'sale_type',
              type: 'autoComplete',
              options: [
                'Standard',
                'Export with payment of IGST',
                'Export without payment of IGST',
                'SEZ supply with payment of IGST',
                'SEZ supply without payment of IGST',
                'Deemed Export',
                'Intra-State supplies attracting IGST',
              ],
              label: 'Sale Type',
              size: 4,
              required: true,
            },
            {
              type: 'text',
              field: 'customer_category',
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Upwork'],
              label: 'Customer Category',
              size: 4,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'product',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              label: 'Product',
              size: 4,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'organization',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              label: 'Organization',
              size: 4,
              required: true,
            },
            {
              label: 'Campaign Details',
              fields: [
                {
                  placeholder: 'Channel & Campaign',
                  label: 'Channel & Campaign',
                  field: 'campaign_type',
                  type: 'cascader',
                  required: true,
                  cascades: [
                    {
                      label: 'Select Source',
                      field: 'campaign_type',
                      api: '/types/suggestionsForLead',
                      suggestionField: 'label',
                      valueField: 'label',
                      required: true,
                    },
                    {
                      label: 'Campaign Value',
                      placeholder: 'Campaign Id',
                      field: 'campaign_id',
                      type: 'autoComplete',
                      api: '/sourceSuggestions',
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      params: ({values: {campaign_type} = {}}) => {
                        return {
                          source: campaign_type,
                        };
                      },
                      onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                        navigation,
                        params,
                      }),
                    },
                  ],
                },
              ],
            },
            {
              fields: [
                {
                  field: 'sez_customer',
                  type: 'checkbox',
                  label: 'SEZ Customer',
                },
                {
                  type: 'text',
                  placeholder: 'LOA NO.',
                  visible: ({values}) => values.sez_customer,
                  field: 'loa_number',
                  label: 'LOA NO.',
                  size: 6,
                },
                {
                  type: 'text',
                  placeholder: 'LUT NO.',
                  visible: ({values}) => values.sez_customer,
                  field: 'lut_number',
                  label: 'LUT NO.',
                  size: 6,
                },
              ],
            },
            {
              type: 'richText',
              field: 'description',
              label: 'Customer Description',
              required: true,
            },
            {
              field: 'attachment',
              placeholder: 'CIN File',
              type: 'file',
              label: 'CIN File',
            },
          ],
        },
        {
          title: 'Taxation Details',
          fields: [
            {
              label: 'Gst Info',
              fields: [
                {
                  label: 'GSTIN',
                  field: 'gstin',
                  type: 'text',
                  placeholder: 'GSTIN',
                  size: 4,
                },
                {
                  label: 'GSTIN State',
                  field: 'gst_state',
                  type: 'text',
                  placeholder: 'GSTIN State',
                  size: 4,
                },
                {
                  label: 'GSTIN State Code',
                  field: 'gst_state_code',
                  type: 'text',
                  placeholder: 'GSTIN State Code',
                  size: 4,
                },

                {
                  label: 'PAN',
                  field: 'pan_no',
                  type: 'text',
                  placeholder: 'PAN',
                  size: 4,
                },
                {
                  label: 'TAN',
                  field: 'tan_no',
                  type: 'text',
                  placeholder: 'TAN',
                  size: 4,
                },
                {
                  label: 'VAT',
                  field: 'vat_number',
                  type: 'text',
                  placeholder: 'VAT',
                  size: 4,
                },
                {
                  type: 'file',
                  field: 'gst_certificate',
                  placeholder: 'GST Certificate',
                  size: 4,
                },
                {
                  type: 'file',
                  field: 'pan_certificate',
                  placeholder: 'PAN Certificate',
                  size: 4,
                },
                {
                  type: 'file',
                  field: 'tan_certificate',
                  placeholder: 'TAN Certificate',
                  size: 4,
                },

                {
                  label: 'Invoice Category',
                  placeholder: 'Invoice Category',
                  type: 'autoComplete',
                  field: 'invoice_category',
                  options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
                  size: 4,
                },
                {
                  label: 'Type Of Supply',
                  field: 'type_of_supply',
                  placeholder: 'Type Of Supply',
                  options: ['B2B', 'B2C', 'SEZWOP'],
                  type: 'autoComplete',
                  required: true,
                  size: 4,
                },

                {
                  label: 'HSN',
                  field: 'hsn_no',
                  type: 'text',
                  placeholder: 'HSN',
                  size: 4,
                },
                {
                  label: 'HSN Description',
                  field: 'hsn_description',
                  type: 'text',
                  placeholder: 'HSN Description',
                  size: 6,
                },
                {
                  type: 'file',
                  field: 'tax_identification_certificate',
                  placeholder: 'Tax Identification Certificate',
                  size: 6,
                },
                {
                  label: 'GST Details',
                  fields: [
                    {
                      field: 'customer_gst_rate',
                      nested: true,
                      addInBottom: true,

                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'IGST',
                              field: 'igst.name',
                              type: 'text',
                            },
                            {
                              header: 'IGST Percent',
                              field: 'igst_percent',
                              placeholder: 'Igst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'CGST',
                              field: 'cgst.name',
                              type: 'text',
                              align: 'center',
                            },
                            {
                              header: 'CGST Percent',
                              field: 'cgst_percent',
                              placeholder: 'Cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'SGST',
                              field: 'sgst.name',
                              type: 'text',
                              align: 'center',
                            },
                            {
                              header: 'SGST Percent',
                              field: 'sgst_percent',
                              placeholder: 'cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select IGST',
                              field: 'igst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                            },
                            {
                              label: 'IGST Percent',
                              field: 'igst_percent',
                              placeholder: 'IGST Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Select CGST',
                              field: 'cgst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                              align: 'center',
                            },
                            {
                              label: 'CGST Percent',
                              field: 'cgst_percent',
                              placeholder: 'CGST Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Select SGST',
                              field: 'sgst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                              align: 'center',
                            },
                            {
                              label: 'SGST Percent',
                              field: 'sgst_percent',
                              placeholder: 'cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
                {
                  label: 'VAT Details',
                  fields: [
                    {
                      field: 'customer_vat_rate',
                      nested: true,
                      addInBottom: true,
                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'Select Account',
                              field: 'account.name',
                              type: 'text',
                            },
                            {
                              header: 'Rate Percentage',
                              field: 'rate_percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select Account',
                              type: 'autoComplete',
                              field: 'account',
                              api: '/accounts',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                            },
                            {
                              label: 'Rate Percentage',
                              field: 'rate_percent',
                              placeholder: 'Rate Percentage',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
                {
                  label: 'TDS Details',
                  fields: [
                    {
                      field: 'customer_tds_rate',
                      nested: true,
                      addInBottom: true,
                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'Select Account',
                              field: 'account.name',
                              type: 'text',
                            },
                            {
                              header: 'Rate Percentage',
                              field: 'rate_percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select Account',
                              type: 'autoComplete',
                              field: 'account',
                              api: '/accounts',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                            },
                            {
                              label: 'Rate Percentage',
                              field: 'rate_percent',
                              placeholder: 'Rate Percentage',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'MSA & SOW',
          fields: [
            {
              label: 'MSA Details',
              fields: [
                {
                  field: 'msa',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          header: 'Attachment',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },
                        {
                          field: 'attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          label: 'Attachment',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'SOW Details',
              fields: [
                {
                  field: 'sow',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'sow_name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'sow_attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          header: 'Attachment',
                        },
                        {
                          field: 'from_date',
                          placeholder: 'From',
                          type: 'date',
                          header: 'From',
                          required: true,
                        },
                        {
                          field: 'to_date',
                          placeholder: 'To',
                          type: 'date',
                          header: 'To',
                          required: true,
                        },
                        {
                          field: 'rate',
                          placeholder: 'Rate',
                          type: 'number',
                          header: 'Rate',
                          required: true,
                        },
                      ]}
                      fields={[
                        {
                          field: 'sow_name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },

                        {
                          field: 'from_date',
                          placeholder: 'From',
                          type: 'date',
                          label: 'From',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'to_date',
                          placeholder: 'To',
                          type: 'date',
                          label: 'To',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'rate',
                          placeholder: 'Rate',
                          type: 'number',
                          label: 'Rate',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'sow_attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          label: 'Attachment',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Address Details',
          fields: [
            {
              label: 'Address Detail',
              fields: [
                {
                  label: 'Customer address',
                  field: 'correspondence_address',
                  type: 'text',
                  placeholder: 'Customer address',
                  size: 6,
                },
                {
                  label: 'Resgistration address',
                  field: 'registration_address',
                  type: 'text',
                  placeholder: 'Resgistration address',
                  size: 6,
                },
                {
                  label: 'Place Of Supply',
                  field: 'place_of_supply',
                  type: 'text',
                  placeholder: 'Place Of Supply',
                  size: 6,
                },
                {
                  label: 'Invoice Location',
                  field: 'location_id',
                  api: `/offices`,
                  type: 'autoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            {
              label: 'Invoice/Delivery Address',
              fields: [
                {
                  field: 'invoice_address',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'correspondence_address',
                          placeholder: 'Enter Place',
                          type: 'text',
                          header: 'Place',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN',
                          field: 'gstin',
                          header: 'GSTIN',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State',
                          field: 'gst_state',
                          header: 'GSTIN State',
                        },
                        {
                          type: 'number',
                          placeholder: 'Pincode',
                          field: 'pin_code',
                          header: 'Pincode',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State Code',
                          field: 'gst_state_code',
                          header: 'GSTIN State Code',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },
                        {
                          field: 'correspondence_address',
                          placeholder: 'Enter Place',
                          type: 'text',
                          label: 'Place',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN',
                          field: 'gstin',
                          label: 'GSTIN',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State',
                          field: 'gst_state',
                          label: 'GSTIN State',
                        },
                        {
                          type: 'number',
                          placeholder: 'Pincode',
                          field: 'pin_code',
                          label: 'Pincode',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State Code',
                          field: 'gst_state_code',
                          label: 'GSTIN State Code',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Interest Rate & Accounting Details',
          fields: [
            {
              label: 'Interest Rate Info',
              fields: [
                {
                  label: 'Interest Rate For Delayed Payment',
                  placeholder: 'Interest Rate For Delayed Payment',
                  field: 'interest_rate',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Days After Due Date for Interest Addition',
                  field: 'days_for_interest_rate',
                  placeholder: 'Days After Due Date for Interest Addition',
                  type: 'number',
                  size: 6,
                },
              ],
            },
            {
              label: 'Account',
              fields: [
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: '/organization',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: '/product',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            {
              label: 'Account Management',
              fields: [
                {
                  field: 'sales_owner',
                  label: 'Sales Owner',
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  field: 'owner',
                  label: 'Account Owner',
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  field: 'csm',
                  label: 'Csm',
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  field: 'com',
                  label: 'Com',
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
              ],
            },
            {
              label: 'Accounting Information',
              fields: [
                {
                  label: 'Currency',
                  field: 'base_currency',
                  api: '/currencies',
                  type: 'autoComplete',
                  suggestionField: 'currency',
                  valueField: 'currency',
                  size: 6,
                },
                {
                  label: 'Receivable Account',
                  field: 'receivable_account',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  label: 'Accounting Account',
                  field: 'advanced_account',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  label: 'Discount',
                  type: 'number',
                  placeholder: 'Discount',
                  field: 'discount',
                  size: 6,
                },
                {
                  label: 'is Foreigner',
                  type: 'checkbox',
                  field: 'foreigner',
                  size: 3,
                },
              ],
            },
          ],
        },
        {
          title: 'Contact Details',
          fields: [
            {
              label: 'Contact Detail',
              fields: [
                {
                  field: 'contact_detail',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          header: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          header: 'Last Name',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          header: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          header: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          header: 'Designation',
                        },
                      ]}
                      fields={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          label: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          label: 'Last Name',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          label: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          label: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          label: 'Designation',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Contact Detail Pm',
              fields: [
                {
                  field: 'contact_detail_pm',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          header: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          header: 'Last Name',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          header: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          header: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          header: 'Designation',
                        },
                      ]}
                      fields={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          label: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          label: 'Last Name',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          label: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          label: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          label: 'Designation',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Contact Detail Billing',
              fields: [
                {
                  field: 'contact_detail_billing',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          required: true,
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          header: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          header: 'Last Name',
                        },
                        {
                          required: true,
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          header: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          header: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          header: 'Designation',
                        },
                        {
                          field: 'is_primary',
                          placeholder: 'Primary',
                          type: 'checkBox',
                          header: 'Primary',
                        },
                        {
                          field: 'ignore',
                          placeholder: 'Ignore',
                          type: 'checkBox',
                          header: 'Ignore',
                        },
                      ]}
                      fields={[
                        {
                          required: true,
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          label: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          label: 'Last Name',
                        },
                        {
                          required: true,
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          label: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          label: 'Mobile No',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          label: 'Designation',
                        },
                        {
                          field: 'is_primary',
                          placeholder: 'Primary',
                          type: 'checkbox',
                          label: 'Primary',
                          size: 6,
                        },
                        {
                          field: 'ignore',
                          placeholder: 'Ignore',
                          type: 'checkbox',
                          label: 'Ignore',
                          size: 6,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Billing Details',
          fields: [
            {
              label: 'Billing Details',
              fields: [
                {
                  field: 'billing_type',
                  options: ['Hourly', 'Daily', 'Monthly', 'Manual'],
                  placeholder: 'Billing Model',
                  label: 'Billing Model',
                  type: 'autoComplete',
                  size: 4,
                },
                {
                  field: 'ewd_allowed',
                  options: ['Yes', 'No'],
                  placeholder: 'EWD',
                  label: 'EWD',
                  type: 'autoComplete',
                  size: 4,
                },
                {
                  field: 'leave_model',
                  options: ['Yearly', 'Monthly', 'Not Allowed'],
                  placeholder: 'Leave Model',
                  label: 'Leave Model',
                  type: 'autoComplete',
                  size: 4,
                },
                {
                  field: 'no_of_leave',
                  visible: ({values}) =>
                    values &&
                    values.leave_model &&
                    values.leave_model !== 'Not Allowed',
                  placeholder: 'Enter Number of Leaves Allowed',
                  type: 'number',
                  label: 'Number of Leaves Allowed',
                },
                {
                  field: 'stop_sending',
                  placeholder: 'Stop Sending',
                  type: 'checkbox',
                  label: 'Stop Sending',
                },
              ],
            },
          ],
        },
      ]}
      // layoutFields={[
      //   {
      //     field: 'country',
      //     label: 'Country',
      //     type: 'autoComplete',
      //     api: `/countries`,
      //     suggestionField: 'name',
      //     valueField: 'name',
      //     size: 6,
      //     required: true,
      //   },
      //   {
      //     type: 'autoComplete',
      //     field: 'industry',
      //     api: `/industries`,
      //     suggestionField: 'name',
      //     valueField: 'name',
      //     label: 'Industry',
      //     size: 6,
      //     required: true,
      //   },
      //   // {
      //   //   type: 'text',
      //   //   field: 'billing_contact_person',
      //   //   label: 'Billing Contact Person',
      //   //   size: 6,
      //   // },
      //   // {
      //   //   type: 'text',
      //   //   field: 'billing_email_id',
      //   //   label: 'Billing Email Id',
      //   //   size: 6,
      //   // },

      //   {
      //     type: 'text',
      //     field: 'customer_category',
      //     type: 'autoComplete',
      //     options: ['Domestic', 'International', 'Upwork'],
      //     label: 'Customer Category',
      //     size: 6,
      //     required: true,
      //   },
      //   {
      //     type: 'text',
      //     field: 'gstin',
      //     label: 'GST',
      //     size: 4,
      //   },
      //   {
      //     type: 'text',
      //     field: 'pan_no',
      //     label: 'PAN',
      //     size: 4,
      //   },
      //   {
      //     type: 'text',
      //     field: 'tan_no',
      //     label: 'TAN',
      //     size: 4,
      //   },

      //   // {
      //   //   type: 'text',
      //   //   field: 'type_of_supply',
      //   //   label: 'Type',
      //   //   type: 'autoComplete',
      //   //   options: ['B2B', 'B2C', 'SEZWOP'],
      //   //   size: 4,
      //   //   // required: true,
      //   // },
      //   // {
      //   //   label: 'Department',
      //   //   field: 'department',
      //   //   type: 'autoComplete',
      //   //   api: `/departments`,
      //   //   suggestionField: 'name',
      //   //   valueField: 'name',
      //   //   size: 6,
      //   // },

      //   // {
      //   //   label: 'Base Currency',
      //   //   field: 'base_currency',
      //   //   type: 'autoComplete',
      //   //   api: `/currencies`,
      //   //   suggestionField: 'currency',
      //   //   valueField: 'currency',
      //   //   size: 6,
      //   // },

      //   // Gst Info form
      //   // Accounting Information Form Starting
      //   // msa form starting
      //   // sow form starting
      //   // invoice address form starting
      //   // Billing details form starting
      //   // Account form starting
      //   // Address Detail from Starting
      //   // Vat Details form starting

      //   // campaign details form starting
      // ]}
      {...props}
    />
  );
};

export const CustomerFormStepwiseNew = props => {
  const {
    route: {params},
    navigation,
    isDualMode,
  } = props;
  const {customerId} = params;
  const {fetch} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Customer',
    uri: '/customers',
    ...props,
  });

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  return (
    <MultiStepForm
      api={`/customer/editcustomer/${customerId}`}
      onSubmit={onSubmit}
      submitAction={'Save'}
      saveOnNext={false}
      isDualMode={isDualMode}
      computations={{...customerComputations(fetch)}}
      steps={[
        {
          title: 'Basic Information',
          fields: [
            {
              label: 'Customer Details',
              fields: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Customer Name',
                  size: 3,
                  required: true,
                },
                {
                  type: 'text',
                  field: 'website',
                  label: 'Website Link',
                  size: 3,
                },
                {
                  type: 'autoComplete',
                  field: 'industry',
                  api: `/industries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  placeholder: 'Industry',
                  label: 'Industry', //port color
                  label: 'Industry',
                  required: true,
                  chip: true,
                  colorField: 'color',
                  size: 3,
                },
                {
                  field: 'country',
                  placeholder: 'Country',
                  label: 'Country',
                  type: 'autoComplete',
                  api: `/countries`, //port color
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 3,
                  chip: true,
                  colorField: 'color',
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  required: true,
                },
              ],
            },

            {
              label: 'Business Assessment',
              fields: [
                {
                  field: 'employee_count',
                  placeholder: 'Employee Count',
                  label: 'Employee Count',
                  type: 'autoComplete',
                  api: `/employeeSize/active`,
                  suggestionField: 'number', //port color
                  valueField: 'number',
                  required: true,

                  size: 3,
                },
                {
                  field: 'organization_category',
                  placeholder: 'Industry Category', //port color
                  label: 'Industry Category',
                  type: 'autoComplete',
                  api: `/organizationcategories`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 3,
                },
                {
                  field: 'geography_location', //port color
                  placeholder: 'Location',
                  label: 'Region',
                  type: 'autoComplete',
                  api: `/geographylocations`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 3,
                },
                {
                  field: 'lead_score',
                  placeholder: 'Score',
                  label: 'Score',
                  editable: false,
                  type: 'number',
                  size: 3,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'Marketing source',
                  fields: [
                    {
                      placeholder: 'Channel & Campaign',
                      label: 'Channel & Campaign',
                      field: 'campaign_type',
                      type: 'cascader',
                      required: true,
                      cascades: [
                        {
                          label: 'Select Source',
                          field: 'campaign_type',
                          api: '/types/suggestionsForLead',
                          suggestionField: 'label',
                          valueField: 'label',
                          required: true,
                        },
                        {
                          label: 'Campaign Value',
                          placeholder: 'Campaign Id',
                          field: 'campaign_id',
                          type: 'autoComplete',
                          api: '/sourceSuggestions',
                          suggestionField: 'name',
                          valueField: 'name',
                          required: true,
                          params: ({values: {campaign_type} = {}}) => {
                            return {
                              source: campaign_type,
                            };
                          },
                          onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                            navigation,
                            params,
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Stakeholders',
          fields: [
            {
              label: 'Client POCs',
              fields: [
                {
                  field: 'contact_detail',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm
                      fields={[
                        {
                          field: 'relation',
                          options: ['Finance', 'PM', 'Decision Maker'],
                          // placeholder: 'Relation',
                          label: 'Relation',
                          required: true,
                          type: 'autoComplete',
                          // size: 4,
                        },
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          header: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          header: 'Last Name',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          header: 'Designation',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          type: 'text',
                          header: 'Email',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          type: 'text',
                          header: 'Mobile No',
                        },
                      ]}
                      columns={[
                        {
                          field: 'relation',
                          placeholder: 'Relation', //port colors/tags for the relations, icon for email and phone and copy on clipboard
                          type: 'text',
                          header: 'Relation',
                          label: 'Relation',
                          render: ({row}) => {
                            if (row?.relation == 'Finance') {
                              return (
                                <Tag
                                  value={row?.relation}
                                  color={colors.ACCENT6_MEDIUM}
                                />
                              );
                            } else if (row?.relation == 'Decision Maker') {
                              return (
                                <Tag
                                  value={row?.relation}
                                  color={colors.ACCENT3_MEDIUM}
                                />
                              );
                            } else if (row?.relation == 'PM') {
                              return (
                                <Tag
                                  value={row?.relation}
                                  color={colors.ACCENT4_MEDIUM}
                                />
                              );
                            }
                          },
                        },
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          type: 'text',
                          header: 'First Name',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          type: 'text',
                          header: 'Last Name',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          type: 'text',
                          label: 'Designation',
                        },
                        {
                          field: 'email',
                          align: 'center',
                          placeholder: 'Email',
                          type: 'text',
                          label: 'Emaill',
                          render: ({row}) => {
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  copyText({isEmail: true, email: row?.email});
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Image source={icon.atTheRate} />
                              </TouchableOpacity>
                            );
                          },
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Mobile No',
                          align: 'center',
                          type: 'text',
                          label: 'Mobile No',
                          render: ({row}) => {
                            return (
                              <TouchableOpacity
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                onPress={() => {
                                  copyText({
                                    isEmail: false,
                                    mobile: row?.phone_no,
                                  });
                                }}>
                                <Image source={icon.phone} />
                              </TouchableOpacity>
                            );
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Internal Stakeholders',
              fields: [
                {
                  field: 'sales_owner',
                  label: 'Sales owner',
                  required: true,
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  secondarySuggestionField: 'official_email_id',
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 4,
                },
                {
                  field: 'owner',
                  label: 'Account manager',
                  required: true,
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  type: 'autoComplete',
                  size: 4,
                },
                {
                  field: 'csm',
                  label: 'CSM',
                  api: `/employeeSuggestions`,
                  filter: {employee_status: 'Active'},
                  suggestionField: 'name',
                  valueField: 'name',
                  type: 'autoComplete',
                  secondarySuggestionField: 'official_email_id',
                  size: 4,
                },
              ],
            },
          ],
        },
        {
          title: 'Accounting',
          fields: [
            {
              label: 'Accounting Information',
              fields: [
                {
                  label: 'Currency',
                  field: 'base_currency', //port colors
                  api: '/currencies',
                  type: 'autoComplete',
                  suggestionField: 'currency',
                  valueField: 'currency',
                  chip: true,
                  colorField: 'color',
                  size: 6,
                },
                {
                  label: 'Recievable Account',
                  field: 'receivable_account', //port colors
                  api: '/accounts',
                  type: 'autoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  chip: true,
                  colorField: 'color',
                  size: 6,
                },
                {
                  label: 'Advanced Account',
                  field: 'advanced_account', //port colors
                  api: '/accounts',
                  type: 'autoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  chip: true,
                  colorField: 'color',
                  size: 6,
                },
                {
                  label: 'Place Of Supply',
                  field: 'place_of_supply',
                  type: 'text',
                  // placeholder: 'Place Of Supply',
                  required: true,
                  size: 6,
                },
                {
                  label: 'Type Of Supply',
                  field: 'type_of_supply',
                  // placeholder: 'Type Of Supply',
                  options: ['B2B', 'B2C', 'SEZWOP'],
                  type: 'autoComplete',
                  required: true,
                  size: 6,
                },
                {
                  label: 'Supplying Organization',
                  // field: ''
                  field: 'type_of_supply',
                  // required: true,
                  type: 'text',
                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'product',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Product',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  field: 'organization',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Organization',
                  size: 6,
                  required: true,
                },
                {
                  field: 'is_internal',
                  placeholder: 'Is Internal',
                  type: 'checkbox',
                  label: 'Is Internal',
                },
              ],
            },
            {
              label: 'Payment terms',
              fields: [
                {
                  field: 'stop_sending',
                  placeholder: 'Stop Sending',
                  type: 'checkbox',
                  label: 'Stop Sending',
                },
                {
                  type: 'number',
                  field: 'credit_days',
                  label: 'Credit Days',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Interest rate for delayed payment',
                  field: 'interest_rate',
                  type: 'number',
                  size: 4,
                },
                {
                  label: 'Days post due date for interest addition',
                  field: 'days_for_interest_rate',
                  type: 'number',
                  size: 4,
                },
              ],
            },
          ],
        },
        {
          title: 'Address Details',
          fields: [
            {
              label: 'Address Detail',
              fields: [
                {
                  label: 'Customer address',
                  field: 'correspondence_address',
                  type: 'text',
                  placeholder: 'Customer address',
                  size: 6,
                },
                {
                  label: 'Resgistration address',
                  field: 'registration_address',
                  type: 'text',
                  placeholder: 'Resgistration address',
                  size: 6,
                },
                {
                  label: 'Place Of Supply',
                  field: 'place_of_supply',
                  type: 'text',
                  placeholder: 'Place Of Supply',
                  size: 6,
                },
                {
                  label: 'Invoice Location',
                  field: 'location_id',
                  api: `/offices`,
                  type: 'autoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Correspondance Country',
                  field: 'correspondence_country',
                  api: `/countries`,
                  type: 'autoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  // size: 6,
                },
              ],
            },
            {
              label: 'Invoice/Delivery Address',
              fields: [
                {
                  field: 'invoice_address',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'correspondence_address',
                          placeholder: 'Enter Place',
                          type: 'text',
                          header: 'Place',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN',
                          field: 'gstin',
                          header: 'GSTIN',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State',
                          field: 'gst_state',
                          header: 'GSTIN State',
                        },
                        {
                          type: 'number',
                          placeholder: 'Pincode',
                          field: 'pin_code',
                          header: 'Pincode',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State Code',
                          field: 'gst_state_code',
                          header: 'GSTIN State Code',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },
                        {
                          field: 'correspondence_address',
                          placeholder: 'Enter Place',
                          type: 'text',
                          label: 'Place',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN',
                          field: 'gstin',
                          label: 'GSTIN',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State',
                          field: 'gst_state',
                          label: 'GSTIN State',
                        },
                        {
                          type: 'number',
                          placeholder: 'Pincode',
                          field: 'pin_code',
                          label: 'Pincode',
                        },
                        {
                          type: 'text',
                          placeholder: 'GSTIN State Code',
                          field: 'gst_state_code',
                          label: 'GSTIN State Code',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Taxation & Compliance',
          fields: [
            {
              fields: [
                {
                  field: 'sez_customer',
                  type: 'checkbox',
                  label: 'SEZ Customer',
                },
                {
                  type: 'text',
                  placeholder: 'LOA NO.',
                  visible: ({values}) => values.sez_customer,
                  field: 'loa_number',
                  label: 'LOA No.',
                  size: 6,
                },
                {
                  type: 'text',
                  placeholder: 'LUT NO.',
                  visible: ({values}) => values.sez_customer,
                  field: 'lut_number',
                  label: 'LUT No.',
                  size: 6,
                },
              ],
            },
            {
              // label: 'Demo',
              fields: [
                {
                  field: 'is_domestic',
                  type: 'checkbox',
                  label: 'Domestic',
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'Tax Information',
                  fields: [
                    {
                      type: 'file',
                      field: 'tax_identification_certificate',
                      placeholder: 'Tax identification certificate',
                      size: 6,
                    },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'GST Information',
                  fields: [
                    {
                      label: 'Invoice Category',
                      type: 'autoComplete',
                      field: 'invoice_category',
                      options: [
                        'Export Invoice',
                        'Tax Invoice',
                        'Bill of Supply',
                      ],
                      size: 4,
                    },
                    {
                      label: 'HSN Code',
                      field: 'hsn_no',
                      type: 'text',
                      size: 4,
                    },
                    {
                      label: 'HSN Description',
                      field: 'hsn_description',
                      type: 'text',
                      size: 4,
                    },
                    {
                      label: 'GSTIN Code',
                      field: 'gstin',
                      type: 'text',
                      size: 6,
                    },
                    {
                      type: 'file',
                      field: 'gst_certificate',
                      placeholder: 'GST Certificate',
                      size: 6,
                    },

                    {
                      label: 'GSTIN State',
                      field: 'gst_state',
                      type: 'text',
                      placeholder: 'GSTIN State',
                      size: 6,
                    },
                    {
                      label: 'GSTIN State Code',
                      field: 'gst_state_code',
                      type: 'text',
                      placeholder: 'GSTIN State Code',
                      size: 6,
                    },

                    // {
                    //   label: 'VAT',
                    //   field: 'vat_number',
                    //   type: 'text',
                    //   placeholder: 'VAT',
                    //   size: 4,
                    // },

                    // {
                    //   label: 'Type Of Supply',
                    //   field: 'type_of_supply',
                    //   placeholder: 'Type Of Supply',
                    //   options: ['B2B', 'B2C', 'SEZWOP'],
                    //   type: 'autoComplete',
                    //   required: true,
                    //   size: 4,
                    // },

                    // {
                    //   type: 'file',
                    //   field: 'tax_identification_certificate',
                    //   placeholder: 'Tax Identification Certificate',
                    //   size: 6,
                    // },
                    // {
                    //   label: 'GST Details',
                    //   fields: [
                    //     {
                    //       field: 'customer_gst_rate',
                    //       nested: true,
                    //       addInBottom: true,

                    //       render: props => (
                    //         <NestedTable
                    //           {...props}
                    //           columns={[
                    //             {
                    //               header: 'IGST',
                    //               field: 'igst.name',
                    //               type: 'text',
                    //             },
                    //             {
                    //               header: 'IGST Percent',
                    //               field: 'igst_percent',
                    //               placeholder: 'Igst Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'CGST',
                    //               field: 'cgst.name',
                    //               type: 'text',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'CGST Percent',
                    //               field: 'cgst_percent',
                    //               placeholder: 'Cgst Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'SGST',
                    //               field: 'sgst.name',
                    //               type: 'text',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'SGST Percent',
                    //               field: 'sgst_percent',
                    //               placeholder: 'cgst Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid From',
                    //               field: 'valid_from',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //           fields={[
                    //             {
                    //               label: 'Select IGST',
                    //               field: 'igst',
                    //               api: '/accounts',
                    //               type: 'autoComplete',
                    //               suggestionField: 'name',
                    //               valueField: 'name',
                    //               required: true,
                    //             },
                    //             {
                    //               label: 'IGST Percent',
                    //               field: 'igst_percent',
                    //               placeholder: 'IGST Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Select CGST',
                    //               field: 'cgst',
                    //               api: '/accounts',
                    //               type: 'autoComplete',
                    //               suggestionField: 'name',
                    //               valueField: 'name',
                    //               required: true,
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'CGST Percent',
                    //               field: 'cgst_percent',
                    //               placeholder: 'CGST Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Select SGST',
                    //               field: 'sgst',
                    //               api: '/accounts',
                    //               type: 'autoComplete',
                    //               suggestionField: 'name',
                    //               valueField: 'name',
                    //               required: true,
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'SGST Percent',
                    //               field: 'sgst_percent',
                    //               placeholder: 'cgst Percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid From',
                    //               field: 'valid_from',
                    //               placeholder: 'Valid From',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               placeholder: 'Valid Upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //         />
                    //       ),
                    //     },
                    //   ],
                    // },
                    // {
                    //   label: 'VAT Details',
                    //   fields: [
                    //     {
                    //       field: 'customer_vat_rate',
                    //       nested: true,
                    //       addInBottom: true,
                    //       render: props => (
                    //         <NestedTable
                    //           {...props}
                    //           columns={[
                    //             {
                    //               header: 'Select Account',
                    //               field: 'account.name',
                    //               type: 'text',
                    //             },
                    //             {
                    //               header: 'Rate Percentage',
                    //               field: 'rate_percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid From',
                    //               field: 'valid_from',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //           fields={[
                    //             {
                    //               label: 'Select Account',
                    //               type: 'autoComplete',
                    //               field: 'account',
                    //               api: '/accounts',
                    //               suggestionField: 'name',
                    //               valueField: 'name',
                    //               required: true,
                    //             },
                    //             {
                    //               label: 'Rate Percentage',
                    //               field: 'rate_percent',
                    //               placeholder: 'Rate Percentage',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid From',
                    //               field: 'valid_from',
                    //               placeholder: 'Valid From',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               placeholder: 'Valid Upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //         />
                    //       ),
                    //     },
                    //   ],
                    // },
                    // {
                    //   label: 'TDS Details',
                    //   fields: [
                    //     {
                    //       field: 'customer_tds_rate',
                    //       nested: true,
                    //       addInBottom: true,
                    //       render: props => (
                    //         <NestedTable
                    //           {...props}
                    //           columns={[
                    //             {
                    //               header: 'Select Account',
                    //               field: 'account.name',
                    //               type: 'text',
                    //             },
                    //             {
                    //               header: 'Rate Percentage',
                    //               field: 'rate_percent',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid From',
                    //               field: 'valid_from',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               header: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //           fields={[
                    //             {
                    //               label: 'Select Account',
                    //               type: 'autoComplete',
                    //               field: 'account',
                    //               api: '/accounts',
                    //               suggestionField: 'name',
                    //               valueField: 'name',
                    //               required: true,
                    //             },
                    //             {
                    //               label: 'Rate Percentage',
                    //               field: 'rate_percent',
                    //               placeholder: 'Rate Percentage',
                    //               type: 'number',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid From',
                    //               field: 'valid_from',
                    //               placeholder: 'Valid From',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //             {
                    //               label: 'Valid Upto',
                    //               field: 'valid_upto',
                    //               placeholder: 'Valid Upto',
                    //               type: 'date',
                    //               align: 'center',
                    //             },
                    //           ]}
                    //         />
                    //       ),
                    //     },
                    //   ],
                    // },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'PAN',
                  fields: [
                    {
                      label: 'PAN',
                      field: 'pan_no',
                      type: 'text',
                      placeholder: 'PAN',
                      size: 4,
                    },
                    {
                      type: 'file',
                      field: 'pan_certificate',
                      placeholder: 'PAN Certificate',
                      size: 4,
                    },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'TAN',
                  fields: [
                    {
                      label: 'TAN',
                      field: 'tan_no',
                      type: 'text',
                      placeholder: 'TAN',
                      size: 4,
                    },
                    {
                      type: 'file',
                      field: 'tan_certificate',
                      placeholder: 'TAN Certificate',
                      size: 4,
                    },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'GST Details',
                  fields: [
                    {
                      field: 'customer_gst_rate',
                      nested: true,
                      addInBottom: true,

                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'IGST',
                              field: 'igst.name',
                              type: 'text',
                            },
                            {
                              header: 'IGST Percent',
                              field: 'igst_percent',
                              placeholder: 'Igst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'CGST',
                              field: 'cgst.name',
                              type: 'text',
                              align: 'center',
                            },
                            {
                              header: 'CGST Percent',
                              field: 'cgst_percent',
                              placeholder: 'Cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'SGST',
                              field: 'sgst.name',
                              type: 'text',
                              align: 'center',
                            },
                            {
                              header: 'SGST Percent',
                              field: 'sgst_percent',
                              placeholder: 'cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select IGST',
                              field: 'igst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              // required: true,
                            },
                            {
                              label: 'IGST Percent',
                              field: 'igst_percent',
                              placeholder: 'IGST Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Select CGST',
                              field: 'cgst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              // required: true,
                              align: 'center',
                            },
                            {
                              label: 'CGST Percent',
                              field: 'cgst_percent',
                              placeholder: 'CGST Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Select SGST',
                              field: 'sgst',
                              api: '/accounts',
                              type: 'autoComplete',
                              suggestionField: 'name',
                              valueField: 'name',
                              // required: true,
                              align: 'center',
                            },
                            {
                              label: 'SGST Percent',
                              field: 'sgst_percent',
                              placeholder: 'cgst Percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'TDS Information', //not a nested table anymore, so now how will we port the older data.
                  fields: [
                    {
                      field: 'customer_tds_rate',
                      nested: true,
                      addInBottom: true,
                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'Select Account',
                              field: 'account.name',
                              type: 'text',
                            },
                            {
                              header: 'Rate Percentage',
                              field: 'rate_percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select Account',
                              type: 'autoComplete',
                              field: 'account',
                              api: '/accounts',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                            },
                            {
                              label: 'Rate Percentage',
                              field: 'rate_percent',
                              placeholder: 'Rate Percentage',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
                {
                  visible: ({values}) => values?.is_domestic,
                  label: 'VAT Information', //not a nested table anymore, so now how will we port the older data.
                  fields: [
                    {
                      field: 'customer_vat_rate',
                      nested: true,
                      addInBottom: true,
                      render: props => (
                        <NestedTable
                          {...props}
                          columns={[
                            {
                              header: 'Select Account',
                              field: 'account.name',
                              type: 'text',
                            },
                            {
                              header: 'Rate Percentage',
                              field: 'rate_percent',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              header: 'Valid From',
                              field: 'valid_from',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              header: 'Valid Upto',
                              field: 'valid_upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                          fields={[
                            {
                              label: 'Select Account',
                              type: 'autoComplete',
                              field: 'account',
                              api: '/accounts',
                              suggestionField: 'name',
                              valueField: 'name',
                              required: true,
                            },
                            {
                              label: 'Rate Percentage',
                              field: 'rate_percent',
                              placeholder: 'Rate Percentage',
                              type: 'number',
                              align: 'center',
                            },
                            {
                              label: 'Valid From',
                              field: 'valid_from',
                              placeholder: 'Valid From',
                              type: 'date',
                              align: 'center',
                            },
                            {
                              label: 'Valid Upto',
                              field: 'valid_upto',
                              placeholder: 'Valid Upto',
                              type: 'date',
                              align: 'center',
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'MSA & SOW',
          fields: [
            {
              label: 'MSA Details',
              fields: [
                {
                  field: 'msa',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          header: 'Attachment',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },
                        {
                          field: 'attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          label: 'Attachment',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'SOW Details',
              fields: [
                {
                  field: 'sow',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          field: 'sow_name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                        {
                          field: 'sow_attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          header: 'Attachment',
                        },
                        {
                          field: 'from_date',
                          placeholder: 'From',
                          type: 'date',
                          header: 'From',
                          required: true,
                        },
                        {
                          field: 'to_date',
                          placeholder: 'To',
                          type: 'date',
                          header: 'To',
                          required: true,
                        },
                        {
                          field: 'rate',
                          placeholder: 'Rate',
                          type: 'number',
                          header: 'Rate',
                          required: true,
                        },
                      ]}
                      fields={[
                        {
                          field: 'sow_name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                        },

                        {
                          field: 'from_date',
                          placeholder: 'From',
                          type: 'date',
                          label: 'From',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'to_date',
                          placeholder: 'To',
                          type: 'date',
                          label: 'To',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'rate',
                          placeholder: 'Rate',
                          type: 'number',
                          label: 'Rate',
                          size: 4,
                          required: true,
                        },
                        {
                          field: 'sow_attachment',
                          placeholder: 'Attachment',
                          type: 'file',
                          label: 'Attachment',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        // {
        //   title: 'Billing Details',
        //   fields: [
        //     {
        //       lable: 'Billing Detials',
        //       fields: [
        //         {
        //           field: 'billing_type',
        //           options: ['Hourly', 'Daily', 'Monthly', 'Manual'],
        //           placeholder: 'Billing Model',
        //           label: 'Billing Model',
        //           type: 'autoComplete',
        //           size: 4,
        //         },
        //         {
        //           field: 'ewd_allowed',
        //           options: ['Yes', 'No'],
        //           placeholder: 'EWD',
        //           label: 'EWD',
        //           type: 'autoComplete',
        //           size: 4,
        //         },
        //         {
        //           field: 'leave_model',
        //           options: ['Yearly', 'Monthly', 'Not Allowed'],
        //           placeholder: 'Leave Model',
        //           label: 'Leave Model',
        //           type: 'autoComplete',
        //           size: 4,
        //         },
        //         {
        //           field: 'no_of_leave',
        //           visible: ({values}) =>
        //             values &&
        //             values.leave_model &&
        //             values.leave_model !== 'Not Allowed',
        //           placeholder: 'Enter Number of Leaves Allowed',
        //           type: 'number',
        //           label: 'Number of Leaves Allowed',
        //         },
        //         {
        //           field: 'stop_sending',
        //           placeholder: 'Stop Sending',
        //           type: 'checkbox',
        //           label: 'Stop Sending',
        //         },
        //       ],
        //     },
        //   ],
        // },

        ///no msa or sow
      ]}
      {...props}
    />
  );
};

export const CreateCustomerFormNew = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {customerId} = params;

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Customer',
    uri: '/customers',
    ...props,
  });
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Form
      api={`/customer/editcustomer/${customerId}`}
      onSubmit={onSubmit}
      submitAction={'Save'}
      saveOnNext={false}
      computations={{...customerComputations(fetch)}}
      layoutFields={[
        {
          label: 'Customer Details',
          fields: [
            {
              type: 'text',
              field: 'name',
              label: 'Customer Name',
              size: 3,
              required: true,
            },
            {
              type: 'text',
              field: 'website',
              label: 'Website Link',
              size: 3,
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: `/industries`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Industry',
              label: 'Industry', //port color
              label: 'Industry',
              required: true,
              chip: true,
              colorField: 'color',
              size: 3,
            },
            {
              field: 'country',
              placeholder: 'Country',
              label: 'Country',
              type: 'autoComplete',
              api: `/countries`, //port color
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 3,
              chip: true,
              colorField: 'color',
            },
            {
              type: 'textArea',
              field: 'description',
              label: 'Description',
              // required: true,
            },
          ],
        },

        {
          label: 'Business Assessment',
          fields: [
            {
              field: 'employee_count',
              placeholder: 'Employee Count',
              label: 'Employee Count',
              type: 'autoComplete',
              api: `/employeeSize/active`,
              suggestionField: 'number', //port color
              valueField: 'number',
              required: true,
              chip: true,
              colorField: 'color',

              size: 3,
            },
            {
              field: 'organization_category',
              placeholder: 'Industry Category', //port color
              label: 'Industry Category',
              type: 'autoComplete',
              api: `/organizationcategories`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              chip: true,
              colorField: 'color',
              size: 3,
            },
            {
              field: 'geography_location', //port color
              placeholder: 'Location',
              label: 'Region',
              type: 'autoComplete',
              api: `/geographylocations`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              chip: true,
              colorField: 'color',
              size: 3,
            },
            {
              field: 'lead_score',
              placeholder: 'Score',
              label: 'Score',
              editable: false,
              type: 'number',
              size: 3,
            },
          ],
        },
        {
          label: 'Client POCs',
          fields: [
            {
              field: 'contact_detail',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm
                  fields={[
                    {
                      field: 'relation',
                      options: ['Finance', 'PM', 'Decision Maker'],
                      // placeholder: 'Relation',
                      label: 'Relation',
                      type: 'autoComplete',
                      // size: 4,
                    },
                    {
                      field: 'name',
                      placeholder: 'Name', //will save the entire name now
                      type: 'text',
                      header: 'Name',
                    },
                    {
                      field: 'designation',
                      placeholder: 'Designation',
                      type: 'text',
                      header: 'Designation',
                    },
                    {
                      field: 'email',
                      placeholder: 'Email',
                      type: 'text',
                      header: 'Email',
                    },
                    {
                      field: 'phone_no',
                      placeholder: 'Mobile No',
                      type: 'text',
                      header: 'Mobile No',
                    },
                  ]}
                  columns={[
                    {
                      field: 'relation',
                      placeholder: 'Relation', //port colors/tags for the relations, icon for email and phone and copy on clipboard
                      type: 'text',
                      header: 'Relation',
                      label: 'Relation',
                      render: ({row}) => {
                        if (row?.relation == 'Finance') {
                          return (
                            <Tag
                              value={row?.relation}
                              color={colors.ACCENT6_MEDIUM}
                            />
                          );
                        } else if (row?.relation == 'Decision Maker') {
                          return (
                            <Tag
                              value={row?.relation}
                              color={colors.ACCENT3_MEDIUM}
                            />
                          );
                        } else if (row?.relation == 'PM') {
                          return (
                            <Tag
                              value={row?.relation}
                              color={colors.ACCENT4_MEDIUM}
                            />
                          );
                        }
                      },
                    },
                    {
                      field: 'name',
                      placeholder: 'Name',
                      type: 'text',
                      label: 'Name',
                    },
                    {
                      field: 'designation',
                      placeholder: 'Designation',
                      type: 'text',
                      label: 'Designation',
                    },
                    {
                      align: 'center',
                      field: 'email',
                      placeholder: 'Email',
                      type: 'text',
                      label: 'Emaill',
                      render: ({row}) => {
                        return (
                          <TouchableOpacity
                            onPress={() => {
                              copyText({isEmail: true, email: row?.email});
                            }}
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Image source={icon.atTheRate} />
                          </TouchableOpacity>
                        );
                      },
                    },
                    {
                      align: 'center',
                      field: 'phone_no',
                      placeholder: 'Mobile No',
                      type: 'text',
                      label: 'Mobile No',
                      render: ({row}) => {
                        return (
                          <TouchableOpacity
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onPress={() => {
                              copyText({isEmail: false, mobile: row?.phone_no});
                            }}>
                            <Image source={icon.phone} />
                          </TouchableOpacity>
                        );
                      },
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Accounting Information',
          fields: [
            {
              label: 'Currency',
              field: 'base_currency', //port colors
              api: '/currencies',
              type: 'autoComplete',
              suggestionField: 'currency',
              valueField: 'currency',
              chip: true,
              colorField: 'color',
              size: 6,
            },
            {
              label: 'Place Of Supply',
              field: 'place_of_supply',
              type: 'text',
              // placeholder: 'Place Of Supply',
              required: true,
              size: 6,
            },
            {
              label: 'Type Of Supply',
              field: 'type_of_supply',
              // placeholder: 'Type Of Supply',
              options: ['B2B', 'B2C', 'SEZWOP'],
              type: 'autoComplete',
              required: true,
              size: 3,
            },
            {
              label: 'Supplying Organization',
              // field: '' //????
              field: 'type_of_supply',
              // required: true,
              type: 'text',
              size: 3,
            },
            {
              type: 'autoComplete',
              field: 'product',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              label: 'Product',
              size: 3,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'organization',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              label: 'Organization',
              size: 3,
              required: true,
            },
          ],
        },
        {
          label: 'Payment Terms',
          fields: [
            {
              type: 'number',
              field: 'credit_days',
              label: 'Credit Days',
              required: true,
              // size: 4,
            },
          ],
        },
        {
          label: 'Internal Stakeholders',
          fields: [
            {
              field: 'sales_owner',
              label: 'Sales owner',
              required: true,
              api: `/employeeSuggestions`,
              filter: {employee_status: 'Active'},
              suggestionField: 'name',
              valueField: 'name',
              type: 'autoComplete',
              secondarySuggestionField: 'official_email_id',
              size: 4,
            },
            {
              field: 'owner',
              label: 'Account manager',
              required: true,
              api: `/employeeSuggestions`,
              filter: {employee_status: 'Active'},
              suggestionField: 'name',
              valueField: 'name',
              type: 'autoComplete',
              secondarySuggestionField: 'official_email_id',
              size: 4,
            },
            {
              field: 'csm',
              label: 'CSM',
              api: `/employeeSuggestions`,
              filter: {employee_status: 'Active'},
              suggestionField: 'name',
              valueField: 'name',
              type: 'autoComplete',
              secondarySuggestionField: 'official_email_id',
              size: 4,
            },
          ],
        },
        {
          groups: [
            {
              label: 'Marketing source',
              fields: [
                {
                  placeholder: 'Channel & Campaign',
                  label: 'Channel & Campaign',
                  field: 'campaign_type',
                  type: 'cascader',
                  required: true,
                  cascades: [
                    {
                      label: 'Select Source',
                      field: 'campaign_type',
                      api: '/types/suggestionsForLead',
                      suggestionField: 'label',
                      valueField: 'label',
                      required: true,
                    },
                    {
                      label: 'Campaign Value',
                      placeholder: 'Campaign Id',
                      field: 'campaign_id',
                      type: 'autoComplete',
                      api: '/sourceSuggestions',
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      params: ({values: {campaign_type} = {}}) => {
                        return {
                          source: campaign_type,
                        };
                      },
                      onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                        navigation,
                        params,
                      }),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddCustomerFormNew = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  const {data: potentialCustomerData} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: row?.potential_customer?._id,
    },
    fields: {
      campaign_type: {_id: 1, name: 1},
      campaign_id: {_id: 1, name: 1},
      employee_count: {_id: 1, number: 1},
      organization_category: {_id: 1, name: 1},
      geography_location: {_id: 1, name: 1},
      lead_source: {_id: 1, name: 1},
      industry: {_id: 1, name: 1},
      country: {_id: 1, name: 1},
      lead_score: 1,
      website: 1,
      email: 1,
    },
  });

  const {data: opportunityData} = useFetchFormData({
    api: '/opportunities',
    filter: {
      _id: row?._id,
    },
    fields: {
      type: 1,
    },
  });

  if (!potentialCustomerData) {
    return null;
  }

  if (!opportunityData) {
    return null;
  }
  return (
    <CustomerForm
      {...props}
      header={'Add Customerss'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
        source: opportunityData?.type,
        owner: row?.owner,
        name: row?.potential_customer?.name,
        employee_count: potentialCustomerData?.employee_count,
        organization_category: potentialCustomerData?.organization_category,
        geography_location: potentialCustomerData?.geography_location,
        lead_source: potentialCustomerData?.lead_source,
        industry: potentialCustomerData?.industry,
        country: potentialCustomerData?.country,
        lead_score: potentialCustomerData?.lead_score,
        website: potentialCustomerData?.website || potentialCustomerData?.email,

        campaign_type: potentialCustomerData?.campaign_type,
        campaign_id: potentialCustomerData?.campaign_id,
        organization: row?.organization,
        channel: potentialCustomerData?.campaign_type?._id,
        last_communication_date: row?.last_communication_date,
        last_communication: row?.last_communication,
        potential_customer: row?.potential_customer,
        next_task_date: row?.next_task_date,
        next_task: row?.next_task,
        opportunity: row?._id,
      }}
    />
  );
};

export const AddCustomerFormForCustomerNew = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  return (
    <CustomerFormStepwiseNew
      {...props}
      header={'Add Customers'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
        isDualMode: false,
      }}
    />
  );
};
export const EditCustomerFormNew = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <CustomerFormStepwiseNew
      {...props}
      mode="edit"
      isDualMode={true}
      header={{
        title: 'Edit Customer',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
    />
  );
};

export const MarkCustomerLost = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/customers`,
    eventSourceId: 'Customer',
    ...props,
  });

  const {fetch} = useAppStateContext();

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        dormant_date: new Date(),
      }}
      computations={{...losCustomerComputations(fetch)}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'lost_reason',
          options: [
            {
              label: 'Project(s) Completed',
              value: 'Project(s) Completed',
              size: 4,
            },
            {
              label: 'Poor delivery quality',
              value: 'Poor delivery quality',
              size: 4,
            },
            {
              label: 'Payment Issue',
              value: 'Payment Issue',
              size: 4,
            },
          ],
        },
        {
          // label: 'Is Nurturable',
          fields: [
            // {
            //   type: 'radioGroup',
            //   field: 'is_customer_nurturable',
            //   options: [
            //     {
            //       label: 'Nurturable',
            //       value: true,
            //     },
            //   ],
            // },
            {
              label: 'Nurturable',
              field: 'is_customer_nurturable',
              type: 'switch',
              width: 100,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const LostCustomer = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkCustomerLost
      header={{
        title: 'Mark Inactive',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const SetParentOnCustomer = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/customers`,
    eventSourceId: 'Customer',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        _id: row?._id,
        is_duplicate: true,
        duplicate_marked_on: new Date(),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Customer',
          field: 'parent_customer',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const SetParentOnCustomerOnDuplicate = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/customers`,
    eventSourceId: 'Customer',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        _id: row?._id,
        parent_updated_date: new Date(),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Customer',
          field: 'parent_customer',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const SetParentCustomerOnDuplicateCustomer = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <SetParentOnCustomerOnDuplicate
      header={{
        title: 'Select Parent Customer',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const SetParentCustomer = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <SetParentOnCustomer
      header={{
        title: 'Select Parent Customer',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const DuplicateCustomer = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  let {data} = useFetchFormData({
    api: `/customer/editcustomer/${row?._id}`,
  });
  if (!data) {
    return null;
  }
  const {_id, ...rest} = data;
  data = {...rest};

  const removeIdFromObjects = array => array.map(({_id, ...rest}) => rest);

  const fieldsToUpdate = [
    'customer_gst_rate',
    'customer_tds_rate',
    'customer_vat_rate',
    'contact_detail',
    'contact_detail_pm',
    'contact_detail_billing',
  ];
  fieldsToUpdate.forEach(field => {
    if (data[field] && data[field].length > 0) {
      data[field] = removeIdFromObjects(data[field]);
    }
  });

  data = {
    ...data,
    is_duplicate: true,
    duplicate_marked_on: new Date(),
    active_status: 'Active',
    created_on: getZeroTimeDate(new Date()),
    parent_customer: _id,
  };

  return (
    <CustomerFormStepwiseNew
      defaultValues={data}
      header={{
        title: 'Customer Details',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const icons = useTheme('icons');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <View
              style={{
                backgroundColor: colors.SURFACE2,
                borderRadius: 6,
                paddingTop: 5,
                paddingBottom: 5,
              }}>
              <Row style={{alignItems: 'center'}} gap={12}>
                <Image source={icons.EstChangeGrey} />
                <Row>
                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_MEDIUM,
                    }}>
                    Duplicating customer for
                  </Text>
                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_HIGH,
                    }}>
                    {`: ${row?.name}`}
                  </Text>
                </Row>
              </Row>
            </View>
          );
        },
      }}
      {...props}
    />
  );
};

export const DuplicateCustomerPopUp = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {CAPTION_LARGE} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <>
      <Row style={{alignItems: 'center'}} gap={12}>
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 5,
          }}>
          Parent Customer:
        </Text>
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_HIGH,
            fontSize: 13,
          }}>
          {row?.parent_customer?.name}
        </Text>
      </Row>
    </>
  );
};

export const CustomerDescriptionPopUp = props => {
  const {
    route: {params},
  } = props;
  const {data} = params;
  const {CAPTION_LARGE} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_HIGH,
          fontSize: 13,
        }}>
        {data?.description}
      </Text>
    </>
  );
};

export const UpdateCustomerCSM = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/customers`,
    eventSourceId: 'Customer',
    ...props,
  });

  return (
    <Form
      header="Update CSM"
      api={`/customers/${row?._id}`}
      mode="edit"
      fields={{csm: {name: 1}}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'CSM',
          field: 'csm',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateCustomerPotentialCustomer = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/customers`,
    eventSourceId: 'Customer',
    ...props,
  });

  return (
    <Form
      header="Update"
      api={`/customers/${row?._id}`}
      mode="edit"
      fields={{potential_customer: {name: 1}}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Potential Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            stage: {$in: ['5530f027a814a8f057123166']},
            lead_status: 'Active',
          },
          size: 4,
        },
      ]}
      {...props}
    />
  );
};

export const CreateCustomerForm = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  return (
    <CreateCustomerFormNew
      {...props}
      header={'Add Customers'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
      }}
    />
  );
};
