import React from 'react';
import {TouchableOpacity, Image} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';

export const UploadButton = ({props, navigation}) => {
  let {uploadIcon, buttonContainer} = useComponentTheme('UploadButtonStyles');

  return (
    <TouchableOpacity
      style={buttonContainer}
      onPress={() => {
        navigation.navigate('import-list', {
          ...props?.route?.params,
          source: props?.source || 'linkedin seed contacts',
          model: props?.model || 'PotentialCustomer',
          isLeadImport: props?.isLeadImport,
        });
      }}>
      <Image style={{height: 20, width: 20}} source={uploadIcon} />
    </TouchableOpacity>
  );
};
