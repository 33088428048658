import {useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import DepartmentTable from '../../department/views/DepartmentTable';
import DesignationTable from '../../department/views/DesignationTable';
import {
  EmployeeAttendanceRequestTable,
  EmployeeEWDRequestTable,
  EmployeeLeaveRequestTable,
  EmployeeTimesheetRequests,
  EmployeeTimesheetTable,
  EmployeeTourRequestTable,
  EmployeeWFHRequestTable,
} from './EmployeeSetupTable';

export const LeaveRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeLeaveRequestTable
          addOnFilter={{
            ...filterValues.filter,
            status: {$exists: false},
          }}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeLeaveRequestTable
          addOnFilter={{
            ...filterValues.filter,
            status: {$in: ['approve', 'reject']},
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'requested_on',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const WFHRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeWFHRequestTable
          addOnFilter={{...filterValues.filter, status: 'unapproved'}}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeWFHRequestTable
          addOnFilter={{
            ...filterValues.filter,
            status: {$in: ['approve', 'reject']},
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'createdAt',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const AttendanceChangeRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeAttendanceRequestTable
          addOnFilter={{
            ...filterValues.filter,
            is_approved: false,
            status: 'unapproved',
          }}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeAttendanceRequestTable
          addOnFilter={{
            ...filterValues.filter,
            is_approved: true,
            status: {$in: ['approve', 'reject']},
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'attendance_date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};


export const EWDRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeEWDRequestTable
          addOnFilter={{
            ...filterValues.filter,
            is_approved: false,
            status: 'unapproved',
            $or : [
              { first_attendance_type_id : "5382ea3470f5d40200321611"},
              {second_attendance_type_id : "5382ea3470f5d40200321611"}
             ],
          }}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeEWDRequestTable
          addOnFilter={{
            ...filterValues.filter,
            is_approved: true,
            status: {$in: ['approve', 'reject']},
            $or : [
              { first_attendance_type_id : "5382ea3470f5d40200321611"},
              {second_attendance_type_id : "5382ea3470f5d40200321611"}
             ],
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'attendance_date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const TimesheetRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeTimesheetRequests
          addOnFilter={{...filterValues.filter, status: 'Log Requested'}}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeTimesheetRequests
          addOnFilter={{
            ...filterValues.filter,
            status: {$in: ['Log Approved', 'Log Rejected']},
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
    all: {
      label: 'All',
      view: <EmployeeTimesheetTable addOnFilter={{...filterValues.filter}} />,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const EmployeeSetupTab = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const tab = {
    Department: {
      label: 'Department',
      view: <DepartmentTable {...props} />,
      // actions: [
      //   <AddButton
      //     title="Add Department"
      //     view="add-department"
      //     params={params}
      //   />,
      // ],
    },
    Designation: {
      label: 'Designation',
      view: <DesignationTable {...props} />,
      // actions: [
      //   <AddButton
      //     title="Add Designation"
      //     view="add-designation"
      //     params={params}
      //   />,
      // ],
    },
  };
  return <TabView tabs={tab} params={params} {...props} />;
};



export const TourRequestHrTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter();
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <EmployeeTourRequestTable
          addOnFilter={{...filterValues.filter, status: 'unapproved'}}
          tab={'pending'}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Closed',
      view: (
        <EmployeeTourRequestTable
          addOnFilter={{
            ...filterValues.filter,
            status: {$in: ['approve', 'reject']},
          }}
          tab={'processed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              field: 'employee',
              api: '/employeeSuggestions',
              type: 'autoComplete',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Date',
              field: 'createdAt',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};