import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';
import { Tag } from '../../../components/chip/Chip';

const RenderPurchaseCategory = props => {
    const {row} = props;
    const category = row?.purchase_category
      ?.map(element => {
        return element?.name;
      })
      .join(', ');
    if (!category) return null;
    return <Tag value={category} />;
  };

const VendorGSTTable = props => {
    const { filterParams, searchValue, params } = props;
    return (
      <Table
        {...props}
        api={'/vendorGstinvoices'}
        addOnFilter={filterParams}
        params={params}
        eventSourceId="GST"
        searchFields={['invoice_no']}
        search={searchValue}
        limit={50}
        columns={[
          {
            type: 'userAvatarChip',
            field: 'payee_id',
            header: 'Vendor',
            minWidth: 200,
          },
          {
            field: 'invoice_no',
            header: 'Invoice No.',
            type: 'colorTag',
            colorField: randomColor,
            width: 160,
          },
          {
            field: 'bill_number',
            header: 'Bill No.',
            width: 160,
          },
          {
            field: 'invoice_date',
            header: 'Invoice Date',
            type: 'date',
            formatOptions: {
              format: 'DD MMM YY',
            },
            width: 140,
          },
          {
            header: 'Expense Heads',
            render: RenderPurchaseCategory,
            width: 280
          },
          {
            type: 'text',
            header: 'GST Number',
            field: 'payee_id.gstin',
            width: 140,
          },
          {
            aggregate: true,
            field: 'payable_amount',
            type: 'currency',
            header: 'Payable Amount',
            width: 140,
          },
          {
            aggregate: true,
            field: 'base_exp_amount',
            type: 'currency',
            header: 'Taxable Amount',
            width: 180,
          },
          {
            aggregate: true,
            field: 'igst_amount',
            type: 'currency',
            header: 'IGST Amount',
            width: 140,
          },
          {
            field: 'cgst_amount',
            type: 'currency',
            header: 'CGST Amount',
            width: 140,
          },
          {
            aggregate: true,
            field: 'sgst_amount',
            type: 'currency',
            header: 'SGST Amount',
            width: 140,
          },
        
          {
            field: 'payee_id.gst_state_code',
            header: 'Place of Supply',
            width: 180,
            value: ({row}) => {
              return `${row?.payee_id?.gst_state_code}-${row?.payee_id?.gst_state}`;
            },
          },
        ]}
      />
    );
};

export const VendorGSTTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'GST Input',
            view: (
                <VendorGSTTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'TDS',
            api: '/vendorGstinvoices',
            addOnFilter: filter,
            search: searchValue,
            searchFields: ['invoice_no'],
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportvendorgst',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <SearchFilter
                    value={searchValue}
                    onChangeFilter={onChangeFilter}
                    placeholder="Search Invoice Number"
                />,
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'invoice_date',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Vendor',
                            api: '/vendors',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'payee_id',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};


const GSTTable = props => {
    const { filterParams, searchValue, params } = props;
    return (
        <Table
            {...props}
            api={'/tdsreportdata'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="GST"
            searchFields={['invoice_no']}
            search={searchValue}
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'Vendor.name',
                    header: 'Vendor',
                    minWidth: 200,
                },
                {
                    field: 'Number',
                    header: 'Number.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: 'Date',
                    header: 'Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 140,
                },
                {
                    type: 'text',
                    header: "Pan Number",
                    field: "pan_no",
                    width: 140,
                },
                {
                    field: 'amount',
                    type: 'currency',
                    header: 'Amount',
                    width: 140
                },
                {
                    field: 'tds_amount',
                    type: 'currency',
                    header: 'TDS Amount',
                    width: 140,
                },
                {
                    field: 'Percentage',
                    header: 'Percentage',
                    width: 140,
                },
                {
                    field: 'tds_account',
                    header: 'TDS Account',
                    width: 180,
                },
            ]}
        />
    );
};

export const GSTTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'TDS',
            view: (
                <GSTTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'TDS',
            api: '/tdsreportdata',
            addOnFilter: filter,
            search: searchValue,
            searchFields: ['invoice_no'],
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exporttdsreport',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <SearchFilter
                    value={searchValue}
                    onChangeFilter={onChangeFilter}
                    placeholder="Search Invoice Number"
                />,
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Vendor',
                            api: '/vendors',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'payee_id',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'TDS Account',
                            api: '/accounts',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'tds_account',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};
