import React from 'react';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {GroupTable, Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {percentageToHour} from '@unthinkable/react-duration-input';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Row, Text} from '@unthinkable/react-core-components';
import {Avatar} from '../../../components/avatar/Avatar';
import {EfficiencyColumnRender} from '../components/EfficiencyColumn';
import {toFixedDecimalValue} from '../../../utils/util';
import {BugIssueHeaderStyles} from '../../../components/card/theme';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';

export const rating = {
  header: 'Average rating',
  type: 'rating',
  width: 130,
  align: 'center',
  field: 'performance',
};

const employeeColumn = {
  header: 'Employee',
  render: ({row, styles}) => {
    const {assigned_to} = row || {};
    return (
      <Row style={{gap: 10, alignItems: 'center'}}>
        <Avatar value={assigned_to?.name} color={assigned_to?.color} />
        <Text style={styles?.rowText}>{assigned_to?.name}</Text>
      </Row>
    );
  },
};

const overdueColumn = ({navigation, params, fromProject}) => ({
  header: 'Overdue',
  align: 'center',
  visible: !params?.employee_team && !fromProject,
  render: ({row}) => {
    const overdueTaskCount = row?.overdueTaskCount || 0;
    const reviewOverdueTaskCount = row?.reviewOverdueTaskCount || 0;
    if (overdueTaskCount === 0 && reviewOverdueTaskCount === 0) {
      return null;
    }
    return (
      <RowWithSeparator style={{gap: 6, alignItems: 'center'}}>
        <GroupContentItem value={`${overdueTaskCount} tasks`} />
        <GroupContentItem value={`${reviewOverdueTaskCount} reviews`} />
      </RowWithSeparator>
    );
  },
  onPress: ({row}) => {
    if (fromProject) {
      navigation.navigate('project-employee-overdue-detail', {
        userId: row?.assigned_to?._id,
        assigned_to: row?.assigned_to,
        project_id: params?.project_id,
        row,
      });
    } else
      navigation.navigate('employee-overdue-detail', {
        userId: row?.assigned_to?._id,
        assigned_to: row?.assigned_to,
        row,
      });
  },
  width: 200,
});

const issueAndBugColumn = ({navigation, params, fromProject}) => ({
  header: {
    render: () => {
      const {textStyle} = useStyles(BugIssueHeaderStyles);
      return (
        <RowWithSeparator style={{gap: 8}} separator={<LineSeparator />}>
          <Text style={[textStyle, {width: 50}]}>Bugs</Text>
          <Text style={[textStyle, {width: 50}]}>Issues</Text>
          <Text style={[textStyle, {width: 70}]}>Disqualify</Text>
        </RowWithSeparator>
      );
    },
  },
  align: 'center',
  visible: !params?.fromEmployeeTeam,
  render: ({row}) => {
    const {icons} = useTheme();
    let {issueTaskHrs = 0, bugTaskHrs = 0, disqualifiedTaskHrs = 0} = row || {};
    let totalHrs = issueTaskHrs + bugTaskHrs + disqualifiedTaskHrs;
    if (totalHrs === 0) {
      return null;
    }
    return (
      <RowWithSeparator
        style={{gap: 6, alignItems: 'center'}}
        separator={<LineSeparator />}>
        <Row style={{width: 52, justifyContent: 'center'}}>
          <GroupContentItem
            icon={icons.Bug}
            value={toFixedDecimalValue(bugTaskHrs) + 'h'}
          />
        </Row>
        <Row style={{width: 54, justifyContent: 'center'}}>
          <GroupContentItem
            value={toFixedDecimalValue(issueTaskHrs) + 'h'}
            icon={icons.IssueWarning}
          />
        </Row>
        <Row style={{width: 70, justifyContent: 'center'}}>
          <GroupContentItem
            value={toFixedDecimalValue(disqualifiedTaskHrs) + 'h'}
            icon={icons.DisqualifiedBlack}
          />
        </Row>
      </RowWithSeparator>
    );
  },
  onPress: ({row}) => {
    const {datePeriod} = row || {};
    const {start_date, end_date} = datePeriod || {};
    navigation.navigate('employee-efficiency-issue-detail', {
      ...params,
      row,
      userId: row?.assigned_to?._id,
      start_date,
      end_date,
      est_hours: row?.est_hours,
      span: 'efficiencyPeriod',
      isCompleted: true,
      filter: {
        status: 'completed',
      },
      fromProject,
    });
  },
  width: 250,
});

const disqualifyTaskColumn = ({navigation, params}) => ({
  header: 'Disqualified',
  align: 'center',
  visible: params?.fromEmployeeTeam,
  render: ({row}) => {
    let {disqualifiedTaskHrs = 0} = row || {};
    if (disqualifiedTaskHrs === 0) {
      return null;
    }
    const {fonts, colors} = useTheme();
    return (
      <Text style={{...fonts.BODY2, color: colors.NEUTRAL_MEDIUM}}>
        {toFixedDecimalValue(disqualifiedTaskHrs) + 'h'}
      </Text>
    );
  },
  onPress: ({row}) => {
    const {datePeriod} = row || {};
    const {start_date, end_date} = datePeriod || {};
    navigation.navigate('org-disqualified-tasks', {
      ...params,
      row,
      userId: row?.assigned_to?._id,
      start_date,
      end_date,
      span: 'efficiencyPeriod',
    });
  },
  width: 120,
});

const employeeEfficiencyDetail =
  ({navigation, params, fromProject, fromThreadTask, navigateTo, fromMyTeam}) =>
  ({row}) => {
    const {datePeriod} = row || {};
    const {start_date, end_date} = datePeriod || {};

    if (navigateTo && fromProject) {
      navigateTo('employee-efficiency-detail-new', {
        ...params,
        row,
        assigned_to: row?.assigned_to?._id,
        userId: row?.assigned_to?._id,
        start_date,
        end_date,
        est_hours: row?.est_hours,
        span: 'efficiencyPeriod',
        isCompleted: true,
        filter: {
          status: 'completed',
        },
        fromThreadTask,
        fromProject,
        breadcrumb: {
          title: row?.assigned_to?.name,
        },
      });
    } else {
      navigation.navigate('employee-efficiency-detail', {
        ...params,
        row,
        assigned_to: row?.assigned_to?._id,
        userId: row?.assigned_to?._id,
        start_date,
        end_date,
        est_hours: row?.est_hours,
        span: 'efficiencyPeriod',
        isCompleted: true,
        filter: {
          status: 'completed',
        },
        fromProject,
        fromThreadTask,
      });
    }
  };

const efficiencyColumn = props => ({
  header: 'Efficiency',
  render: EfficiencyColumnRender,
  onPress: employeeEfficiencyDetail(props),
  width: 180,
});

export const MyEfficiencyTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      {...props}
      renderHeader={() => <TableHeader title="Output" />}
      eventSourceId={'Task'}
      api={`/myOutput`}
      params={{}}
      limit={20}
      columns={[
        {
          field: 'span',
          header: 'Week',
        },
        overdueColumn({navigation, params}),
        issueAndBugColumn({navigation, params}),
        efficiencyColumn({navigation, params}),
        rating,
      ]}
    />
  );
};

// Akshat Garg - 8/11/24 - my tasks output
export const MyTasksEfficiencyTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      {...props}
      renderHeader={() => <TableHeader title="Output" />}
      eventSourceId={'Task'}
      api={`/myOutput`}
      params={{}}
      limit={20}
      columns={[
        {
          field: 'span',
          header: 'Week',
        },
        {
          field: 'reworkTasks',
          header: 'Rework Task',
          align: 'center',
          type: 'number',
          width: 150,
        },
        efficiencyColumn({navigation, params}),
        rating,
      ]}
    />
  );
};

const EfficiencyTable = ({
  paramsObj,
  params,
  showOnlyDisqualified = true,
  navigation,
  fromProject,
  fromMyTeam,
  onRowPress,
  fromThreadTask,
  ...props
}) => {
  const {navigateTo} = useLeftMenuContext() || {};
  const colors = useTheme('colors');

  return (
    <GroupTable
      {...props}
      params={paramsObj}
      onRowPress={onRowPress}
      columns={[
        {
          ...employeeColumn,
          onPress: fromMyTeam
            ? props => {
                const {row} = props;
                navigation.navigate('employee-team-efficiency', {
                  ...params,
                  row,
                  title: `${row?.assigned_to?.name}'s Team Output`,
                });
              }
            : void 0,
        },
        // overdueColumn({navigation, params, fromProject}),
        showOnlyDisqualified
          ? disqualifyTaskColumn({navigation, params})
          : issueAndBugColumn({navigation, params, fromProject}),

        efficiencyColumn({
          navigation,
          navigateTo,
          params,
          fromProject,
          fromThreadTask,
        }),
        rating,
      ]}
      groupRow={{
        data: 'data',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {
            span,
            totalEfficiency,
            efficiencyPercentage,
            totalReviewHrs,
            issue_hrs,
          } = row || {};
          let color = '';
          if (efficiencyPercentage >= 80) {
            color = colors.SUCCESS_HIGH;
          } else if (efficiencyPercentage >= 65) {
            color = colors.WARNING_HIGH;
          } else {
            color = colors.ERROR_HIGH;
          }
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={span} />
              <GroupContentItem
                value={`Tasks : ${
                  totalEfficiency ? totalEfficiency.toFixed(1) : 0
                } hrs`}
              />
              {totalReviewHrs ? (
                <GroupContentItem
                  value={`Reviews: ${percentageToHour(totalReviewHrs)}`}
                />
              ) : (
                void 0
              )}
              {issue_hrs ? (
                <GroupContentItem
                  value={`Issues: ${percentageToHour(issue_hrs)}`}
                />
              ) : (
                void 0
              )}
              <GroupContentItem
                value={
                  (efficiencyPercentage ? efficiencyPercentage.toFixed() : 0) +
                  '% Efficiency'
                }
                color={color}
              />
            </RowWithSeparator>
          );
        },
      }}
    />
  );
};

export const TeamEfficiencyTable = props => {
  const {
    navigation,
    route: {params},
    user,
    showOnlyDisqualified,
    fromMyTeam,
  } = props;
  const {row, employee_team} = params;
  const paramsObj = {
    userId: user?._id || row?.assigned_to?._id,
    employeeTeamId: employee_team?._id,
    scope: 'team',
  };
  let onRowPress = void 0;
  if (employee_team) {
    onRowPress = employeeEfficiencyDetail({navigation, params});
  }

  return (
    <EfficiencyTable
      eventSourceId={'Task'}
      api={`/teamOutput`}
      paramsObj={paramsObj}
      limit={8}
      navigation={navigation}
      params={params}
      onRowPress={onRowPress}
      showOnlyDisqualified={showOnlyDisqualified}
      fromMyTeam={fromMyTeam}
    />
  );
};

export const MyTeamEfficiencyTable = props => {
  return (
    <TeamEfficiencyTable showOnlyDisqualified={false} fromMyTeam {...props} />
  );
};

export const ProjectEfficiencyTable = props => {
  const {
    navigation,
    route: {params},
    fromThreadTask = false,
  } = props;
  const {project} = params;

  const paramsObj = {scope: 'project', span: 'Weekly'};

  return (
    <EfficiencyTable
      eventSourceId={'Task'}
      api={`/projects/${project?._id}/teamEfficiency`}
      limit={8}
      paramsObj={paramsObj}
      navigation={navigation}
      params={params}
      showOnlyDisqualified={false}
      fromProject={true}
      fromThreadTask={fromThreadTask}
    />
  );
};
