import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {
  Image,
  Platform,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form/Renderers';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useInvoke} from '../../../controllers/useInvoke';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';
import {multipleFileRenderer} from '../controllers/RecruitmentColumnRenderer';
import {RowWithSeparator} from '@unthinkable/react-layout';

const RenderMobile = ({value}) => {
  const icons = useTheme('icons');
  const toast = useToast();
  return (
    <TouchableOpacity
      onPress={() => {
        if (Platform.OS === 'web') {
          navigator.clipboard.writeText(value);
          toast({message: 'Mobile number copied.'});
        }
      }}>
      <Image
        title={value}
        source={icons.phone}
        style={{width: 24, height: 24}}
      />
    </TouchableOpacity>
  );
};

const RenderPositionTitle = ({row}) => {
  return (
    <View>
      <TextRenderer value={row?.job_opening?.position_title?.name} />
      <GroupContentItem value={row?.job_opening?.department?.name} />
    </View>
  );
};

const RenderStatus = ({row}) => {
  const {colors} = useTheme();
  const referralStatus = row?.candidate?.status || row.status;
  let goodStatus = true;
  const badStatuses = ['Offer Rejected', 'Rejected', 'Dropped'];
  badStatuses.forEach(status => {
    if (referralStatus == status) goodStatus = false;
  });
  return (
    <View
      style={{
        margin: -16,
        padding: 16,
        backgroundColor: goodStatus
          ? colors['SUCCESS_LOW']
          : colors['ERROR_LOW'],
        width: 120,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <TextRenderer
        style={{
          color: goodStatus ? colors['SUCCESS_HIGH'] : colors['ERROR_HIGH'],
        }}
        value={row?.candidate?.status || row.status}
      />
    </View>
  );
};

const RenderStatusReferral = ({row}) => {
  const {colors} = useTheme();
  let referralStatus = row.status;
  let goodStatus = true;
  const badStatuses = ['Offer Rejected', 'Rejected', 'Dropped'];
  badStatuses.forEach(status => {
    if (referralStatus == status) goodStatus = false;
  });
  if (referralStatus === 'Closed') {
    referralStatus = 'Added';
  }

  return (
    <View
      style={{
        margin: -16,
        padding: 16,
        backgroundColor: goodStatus
          ? colors['SUCCESS_LOW']
          : colors['ERROR_LOW'],
        width: 120,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <TextRenderer
        style={{
          color: goodStatus ? colors['SUCCESS_HIGH'] : colors['ERROR_HIGH'],
        }}
        value={referralStatus}
      />
    </View>
  );
};

export const ReferralTable = props => {
  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'ReferralCandidate',
  });
  const {navigation} = props;
  return (
    <Table
      {...props}
      api="/referralCandidates"
      eventSourceId="ReferralCandidate"
      fields={{
        name: 1,
        email: 1,
        mobile: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        status: 1,
        comment: 1,
        referred_on: 1,
        referred_by: {
          name: 1,
          official_email_id: 1,
          color: 1,
        },
        added_to_job_opening: 1,
        candidate: {
          status: 1,
        },
        attachment: 1,
      }}
      columns={[
        {
          header: 'Referred On',
          field: 'referred_on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              color={row?.color}
              official_email_id={row?.email}
            />
          ),
        },
        {
          header: 'Mobile',
          field: 'mobile',
          render: RenderMobile,
          width: 80,
          align: 'center',
        },
        {
          header: 'CV',
          field: 'attachment',
          render: multipleFileRenderer,
          align: 'center',
          width: 80,
          align: 'center',
        },
        {
          header: 'Position',
          render: RenderPositionTitle,
        },
        {
          header: 'Referred By',
          field: 'referred_by',
          render: ({value}) => (
            <UserAvatarWithNameAndEmail
              name={value?.name}
              official_email_id={value?.official_email_id}
              color={value?.color}
            />
          ),
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-referral-candidate', {
              referral_candidate: row,
            });
          },
        },
        {
          title: 'Add To This Opening',
          confirm: {
            title: 'Add To This Opening',
            message: 'Are you sure you want to add this candidate?',
          },
          onPress: ({row}) => {
            postInvoke({
              uri: '/addReferralToOpening',
              props: {
                ...row,
              },
            });
          },
          visible: ({row}) => !row?.added_to_job_opening,
        },
        {
          title: 'Add To Another Opening',
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate-to-job-opening', {
              referral_candidate: row,
            });
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate-comment', {
              referral_candidate: row,
            });
          },
          variant: 'error',
        },
      ]}
    />
  );
};

export const ReferralClosedGroupTable = props => {
  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'ReferralCandidate',
  });
  const {navigation} = props;
  return (
    <GroupTable
      {...props}
      limit={500000}
      api={'/refeeral-candidates-grouped-list'}
      eventSourceId="ReferralCandidate"
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} candidates`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Referred On',
          field: 'referred_on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              color={row?.color}
              official_email_id={row?.email}
            />
          ),
        },
        {
          header: 'Mobile',
          field: 'mobile',
          render: RenderMobile,
          width: 80,
          align: 'center',
        },
        {
          header: 'CV',
          field: 'attachment',
          render: multipleFileRenderer,
          width: 80,
          align: 'center',
        },
        {
          header: 'Position',
          render: RenderPositionTitle,
        },
        {
          header: 'Referred By',
          field: 'referred_by',
          render: ({value}) => (
            <UserAvatarWithNameAndEmail
              name={value?.name}
              official_email_id={value?.official_email_id}
              color={value?.color}
            />
          ),
        },
        {
          header: 'Referral Status',
          render: RenderStatusReferral,
          align: 'center',
        },
        {
          header: 'Current Stage',
          render: RenderStatus,
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Add To This Opening',
          confirm: {
            title: 'Add To This Opening',
            message: 'Are you sure you want to add this candidate?',
          },
          onPress: ({row}) => {
            postInvoke({
              uri: '/addReferralToOpening',
              props: {
                ...row,
              },
            });
          },
          visible: ({row}) => !row?.added_to_job_opening,
        },
        {
          title: 'Add To Another Opening',
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate-to-job-opening', {
              referral_candidate: row,
            });
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate-comment', {
              referral_candidate: row,
            });
          },
        },
      ]}
    />
  );
};

export const MyRefferedCandidates = props => {
  const filter = {};
  return (
    <Table
      {...props}
      filter={filter}
      api="/referralCandidates"
      eventSourceId="ReferralCandidate"
      fields={{
        name: 1,
        email: 1,
        mobile: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        status: 1,
        comment: 1,
        referred_on: 1,
        referred_by: {
          name: 1,
        },
        added_to_job_opening: 1,
        candidate: {
          status: 1,
        },
        attachment: 1,
      }}
      columns={[
        {
          header: 'Referred On',
          field: 'referred_on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          minWidth: 120,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              color={row?.color}
              official_email_id={row?.email}
            />
          ),
          minWidth: 250,
        },
        {
          header: 'Mobile',
          field: 'mobile',
          render: RenderMobile,
          width: 80,
        },
        {
          header: 'CV',
          field: 'attachment',
          render: multipleFileRenderer,
          width: 200,
          align: 'center',
        },
        {
          header: 'Position title & team',
          render: RenderPositionTitle,
          width: 200,
        },
        {
          header: 'Current Stage',
          render: RenderStatus,
          align: 'center',
          width: 120,
        },
      ]}
    />
  );
};
