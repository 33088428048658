import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeScreenTypeForm = props => {
  const {
    route: {params},
  } = props;

  const {row, api, project, secondaryTitle} = params;
  const {view_type} = row || {};

  const {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: 'View',
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id, view_type}}
      header={{
        title: `Update Screen Type`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Screen Type',
          field: 'view_type',
          type: 'autoComplete',
          options: ['Table', 'Form'],
        },
      ]}
      {...props}
    />
  );
};
