import React from 'react';
import { LeftMenuView } from '../../../components/menu/LeftMenuView';
import { BlogsTable } from './BlogsTable';
import { CaseStudyTable } from './CaseStudyTable';
import { PodcastsTable, PodcastTable } from './PodcastTable';
import { UnthinkablePages } from './UnthinkablePages';
import { DomainTable } from './DomainTable';

export const WebsiteMenu = props => {
    const { route: { params } = {} } = props;

    const menus = [
        {
            label: 'Pages',
            view: <UnthinkablePages {...props} />,
        },
        {
            label: 'Domains',
            view: <DomainTable {...props} />,
        },
        {
            label: 'Blogs',
            view: <BlogsTable {...props} />,
        },
        {
            label: 'Case Studies',
            view: <CaseStudyTable {...props} />,
        },
        {
            label: 'Podcasts',
            view: <PodcastsTable {...props} />,
        }
    ];

    return <LeftMenuView {...props} params={params} menus={menus} />;
};