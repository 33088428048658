import {
  ActivityIndicator,
  Text,
  View,
} from '@unthinkable/react-core-components';
import moment from 'moment';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useAppStateContext} from '../../../providers/AppState';

export const AdditionalEmailRequesterTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'budget',
  });
  return (
    <Table
      eventSourceId={['budget']}
      api={`/additionalemailrequests/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-automatic-payment-request', {
            row,
            readOnly: true,
          });
        } else {
          navigation.navigate('edit-automatic-payment-request', {row});
        }
      }}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Client',
          field: 'customer.project',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },

        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
        },
        tab == 'Draft' && {
          header: 'Requested On',
          field: 'request_date',
          type: 'date',
        },

        tab != 'StopTxn' &&
          tab != 'Inactive' && {
            header: 'Requested',
            field: 'request_date',
            type: 'date',
            width: 100,
          },
        tab === 'Requested' && {
          header: 'Pending by',
          field: 'approver_status',
          type: 'colorTag',
          minWidth: 200,
        },
        tab === 'StopTxn' && {
          header: 'Stop requested on',
          field: 'stop_txn_request_date',
          type: 'date',
          width: 150,
        },
        tab === 'Inactive' && {
          header: 'Stopped on',
          field: 'inactive_by_finance_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
          align: 'center',
        },
        {
          header: 'Head Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_head_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Finance Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_finance_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Rejected',
          type: 'date',
          visible: ({values}) => {
            if (tab === 'Rejected') return true;
          },
          render: ({row, styles}) => {
            return (
              <View>
                <Text style={{...styles.rowText, color: '#FF3B30'}}>
                  {moment(row.rejected_date).format('DD MMM YY')}
                </Text>
                <Text style={{...styles.rowText, color: '#737373'}}>
                  {row.reject_reason}
                </Text>
              </View>
            );
          },
          width: 200,
        },
      ]}
      moreActions={[
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to Delete?',
        //     confirmText: 'Delete',
        //   },
        //   onPress: ({row}) => {
        //     deleteInvoke({
        //       uri: `/automaticpayments/${row._id}`,
        //       props: {
        //         ...row,
        //       },
        //     });
        //   },
        // },
        tab === 'Draft' && {
          title: 'Request Email',
          confirm: {
            title: 'Request',
            message:
              'Are you sure you want to make an additional request for this email?',
            confirmText: 'Request',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/additionalemailrequests/${row._id}`,
              props: {
                status: 'Requested',
                request_date: new Date(),
              },
            });
          },
        },
        tab === 'Active' && {
          title: 'Stop Transaction',
          confirm: {
            title: 'Stop',
            message: 'Are you sure you want to stop payment?',
            confirmText: 'Stop',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/automaticpayments/${row._id}`,
              props: {
                status: 'Active',
                stop_txn_request: true,
                stop_txn_request_date: new Date(),
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/automaticpayments/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
