import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {getCurrentDay} from '../../employee/views/EmployeeTeamTabs';
import {
  AllAllocationResourceList,
  AllocationList,
} from './DetailResourceAllocatedList';

export const AllocatedResourceTab = props => {
  let {route: {params} = {}} = props;
  const {department, employeeFilter = {}} = params;
  const defaultFilter = {};
  if (department) {
    // defaultFilter['employee.department'] = department;
    // defaultFilter['employee.employee_status'] = 'Active';
  }
  const {applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentDay(),
    },
    filter: defaultFilter,
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllocationList
          filterParams={{
            ...filter,
            team_id: {$exists: false},
            ...employeeFilter,
          }}
          tab="all"
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/allocatedList',
      addOnFilter: {
        ...filter,
        is_wrong_input: {$in: [false, null]},
        team_id: {$exists: false},
        ...employeeFilter,
      },
      params: params,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/exportAllocatedList`,
              props: {
                uri: '/allocatedList',
                ...params,
                period,
                addOnFilter: {
                  ...filter,
                  is_wrong_input: {$in: [false, null]},
                  team_id: {$exists: false},
                  ...employeeFilter,
                },
              },
            });
          }}
        />,
      ],
    },

    't&m': {
      label: 'T & M',
      view: (
        <AllocationList
          filterParams={{
            ...filter,
            project_type: 'tm',
            team_id: {$exists: false},
            ...employeeFilter,
          }}
          tab="tm"
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/allocatedList',
      addOnFilter: {
        ...filter,
        is_wrong_input: {$in: [false, null]},
        project_type: 'tm',
        team_id: {$exists: false},
        ...employeeFilter,
      },
      params: params,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/exportAllocatedList`,
              props: {
                ...params,
                period,
                uri: '/allocatedList',
                addOnFilter: {
                  ...filter,
                  is_wrong_input: {$in: [false, null]},
                  project_type: 'tm',
                  team_id: {$exists: false},
                  ...employeeFilter,
                },
              },
            });
          }}
        />,
      ],
    },

    Fixed: {
      label: 'Fixed',
      view: (
        <AllocationList
          filterParams={{
            ...filter,
            project_type: 'f',
            team_id: {$exists: false},
            ...employeeFilter,
          }}
          tab="fixed"
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/allocatedList',
      addOnFilter: {
        ...filter,
        is_wrong_input: {$in: [false, null]},
        project_type: 'f',
        team_id: {$exists: false},
        ...employeeFilter,
      },
      params: params,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/exportAllocatedList`,
              props: {
                ...params,
                period,
                uri: '/allocatedList',
                addOnFilter: {
                  ...filter,
                  is_wrong_input: {$in: [false, null]},
                  project_type: 'f',
                  team_id: {$exists: false},
                  ...employeeFilter,
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee.primary_practice',
              // asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Milestone No.',
              api: '/ordermilestones',
              placeholder: 'Select',
              suggestionField: 'order_milestone_no',
              valueField: 'order_milestone_no',
              field: 'milestone_id',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project_id',
            },
            {
              label: 'From date',
              type: 'date',
              field: 'period',
              asParam: true,
            },
            {
              label: 'Type',
              field: 'project_type',
              placeholder: 'Type',
              type: 'autoComplete',
              options: [
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
                {value: 'tm', label: 'T & M'},
                {value: 'tr', label: 'Training'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Project SubCategory',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skillmetrics',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill_metrics',
            },
            {
              type: 'autoComplete',
              label: 'Tools',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'tool',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Locations',
            //   api: '/offices',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'employee.sitting_location',
            // },
          ]}
        />,
      ]}
    />
  );
};

export const AllAllocatedResourceTab = props => {
  let {route: {params} = {}} = props;
  const {projectFilter = {}} = params;
  const {applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllAllocationResourceList
          filterParams={{
            ...filter,
            team_id: {$exists: false},
            ...projectFilter,
          }}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/allocatedList',
      addOnFilter: {
        ...filter,
        is_wrong_input: {$in: [false, null]},
        team_id: {$exists: false},
        ...projectFilter,
      },
      params: params,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              valueField: 'name',
              field: 'employee',
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              label: 'From date',
              type: 'date',
              field: 'period',
              asParam: true,
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Project SubCategory',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skillmetrics',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill_metrics',
            },
            {
              type: 'autoComplete',
              label: 'Tools',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'tool',
            },
          ]}
        />,
      ]}
    />
  );
};
