import {Row} from '@unthinkable/react-core-components';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {RowWithSeparator} from '@unthinkable/react-layout';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {TabView} from '../../../components/tab/TabView';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
import {InmailCampaignTable} from '../views/InmailCampaignTable';

export const ManualCampaignTabView = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams} = filterValues || {};

  const api = `/inmail-campaign`;

  let currentObjectId = '64d0fd55ab78dfead48c144b';
  if (filter?.campaign_type?._id === '64d0fd55ab78dfead48c144a') {
    currentObjectId = '64d0fd55ab78dfead48c144a';
  }

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Type',
        field: 'campaign_type',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.label} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Owner',
        field: 'assigned_to',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const {period} = filterParams;
  const tabs = {
    active: {
      label: 'Active',
      view: (
        <InmailCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          api={api}
          groupBy={groupBy}
          tab={'Active'}
          tabFilter={{
            $or: [
              {
                status: 'active',
              },
              {
                status: 'Confirmed',
                $and: [
                  {
                    confirmed_on: {$lte: period?.to},
                  },
                  {
                    $or: [{retired_on: {$gte: period?.to}}, {retired_on: null}],
                  },
                ],
              },
              {
                status: 'In Experiment',
                $and: [
                  {
                    experiment_on: {$lte: period?.to},
                  },
                  {
                    $or: [
                      {confirmed_on: {$gte: period?.to}},
                      {confirmed_on: null},
                    ],
                  },
                  {
                    $or: [{failed_on: {$gte: period?.to}}, {failed_on: null}],
                  },
                ],
              },
            ],
          }}
        />
      ),
    },
    completed: {
      label: 'Completed',
      view: (
        <InmailCampaignTable
          filterParams={filterParams}
          filter={filter}
          api={api}
          searchValue={searchValue}
          groupBy={groupBy}
          tabFilter={{
            $or: [
              {status: 'Retired', retired_on: {$lte: period.to}},
              {status: 'Failed', failed_on: {$lte: period.to}},
            ],
          }}
        />
      ),
    },
  };
  const actions = [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search"
    />,
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'assigned_to',
        },
        {
          type: 'autoComplete',
          label: 'Target Audience',
          api: '/buyerPersonas',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'target_audience',
        },
        {
          type: 'autoComplete',
          label: 'Is MR',
          options: [
            {label: 'True', value: 'true'},
            {label: 'False', value: 'false'},
          ],
          placeholder: 'Select',
          field: 'is_mr',
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          type: 'autoComplete',
          label: 'Campaign Type',
          api: '/types',
          placeholder: 'Select',
          suggestionField: 'label',
          valueField: 'label',
          field: 'campaign_type',
          filter: {
            _id: {
              $in: [
                '64d0fd55ab78dfead48c144b',
                '64d0fd55ab78dfead48c144a',
                '64db3dc4334a9d2f6766ded2',
                '64884b6bd40198f62b283007',
                '64be7476e4ba09d3df7b3a6d',
              ],
            },
          },
        },
      ]}
    />,
    <GroupBy {...groupBy} />,
    <Row
      gap={8}
      style={{alignItems: 'center', justifyContent: 'space-between'}}>
      <AddButton
        title="Campaign"
        view={'add-universal-campaigns-form'}
        params={props?.route?.params}
      />
      <MoreActions
        actions={[
          // {
          //   title: 'Create Email Campaign',
          //   onPress: () => {
          //     navigation.navigate('add-emailcampaign', {
          //       ...props?.route?.params,
          //     });
          //   },
          // },
          // {
          //   title: 'Create Inreach Campaign',
          //   onPress: () => {
          //     navigation.navigate('add-othercampaign', {
          //       ...props?.route?.params,
          //     });
          //   },
          // },
          // {
          //   title: 'Create Web Campaign',
          //   onPress: () => {
          //     navigation.navigate('add-webcampaign', {
          //       ...props?.route?.params,
          //     });
          //   },
          // },
          {
            title: 'Import Inmail Prospects',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'linkedin prospects',
                model: 'PotentialCustomer',
                inmailProspect: true,
              });
            },
          },
          {
            title: 'Import Helper Prospects',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'helper prospects',
                model: 'PotentialCustomer',
                inmailProspect: true,
              });
            },
          },
          {
            title: 'View woodpecker logs',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'woodpecker prospects',
                model: 'PotentialCustomer',
                woodpeckerEnabled: true,
              });
            },
          },
          {
            title: 'Import Email Prospects',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'email prospects',
                model: 'PotentialCustomer',
                emailProspect: true,
              });
            },
          },
          {
            title: 'Import Prospects',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'inreach prospects',
                model: 'PotentialCustomer',
                inreachProspects: true,
              });
            },
          },
          {
            title: 'Import Apollo Prospects',
            onPress: () => {
              navigation.navigate('import-list', {
                ...props?.route?.params,
                source: 'apollo prospects',
                model: 'PotentialCustomer',
              });
            },
          },
        ]}
      />
    </Row>,
  ];
  return <TabView {...props} tabs={tabs} actions={actions} />;
};
