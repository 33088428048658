import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddUniversalCampaignForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/campaigns',
    eventSourceId: 'inmailCampaignAdded',
    ...props,
  });

  const {
    route: {params},
  } = props;
  const {product, row, afterSubmit, searchValue} = params;
  return (
    <Form
      api={`/campaigns/${row?._id}`}
      fields={{
        name: 1,
        date: 1,
        inmail: {
          inmail_subject: 1,
        },
        profile_owner: {
          name: 1,
        },
        assigned_to: {
          name: 1,
        },
        communication_type: 1,
        target_audience: {
          name: 1,
          industry: 1,
        },
        linkedin_profile: {email: 1},
        email_profile: {email: 1},
        user_segment_id: {name: 1},
        drip_limit_day: 1,
        auto_send: 1,
        campaign_method: 1,
        is_mr: 1,
        criteria: 1,
        auto_schedule: 1,
        auto_withdrawal: 1,
        auto_withdrawal_time: 1,
        observer: {name: 1},
        other_campaign_type: 1,
        campaign_type: {label: 1},
        campaign_account: 1,
        is_nurtuable: 1,
        channel: {name: 1},
        organization: {name: 1},
        employee_team: {name: 1},
        url: 1,
        campaign_id: 1,
      }}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      header={'Create campaign'}
      submitAction={'Save Setup'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        experiment_on: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
        name: searchValue,
        status: 'In Experiment',
      }}
      layoutFields={[
        {
          fields: [
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
              required: true,
              size: 2,
            },
            {
              label: 'Name',
              field: 'name',
              type: 'text',
              size: 2,
              required: true,
              visible: ({values}) =>
                values?.campaign_type?._id !== '64884b6bd40198f62b283007',
            },
            {
              label: 'Web Page',
              field: 'name',
              type: 'text',
              size: 2,
              visible: ({values}) =>
                values?.campaign_type?._id === '64884b6bd40198f62b283007',
            },
            {
              label: 'URL',
              field: 'url',
              type: 'text',
              size: 2,
              visible: ({values}) =>
                values?.campaign_type?._id === '64884b6bd40198f62b283007',
            },
          ],
        },
        {
          fields: [
            {
              type: 'radioGroup',
              field: 'communication_type',
              label: 'Communication Type',
              options: ['Automatic', 'Manual'],
              dependentFields: ['loaded_prospects', 'loaded_messages'],
              onChangeValue: (value, e, {setFieldValue}) => {
                value === 'Manual'
                  ? setFieldValue('campaign_method', 'Manual')
                  : null;
              },
            },
          ],
          visible: ({values}) =>
            values?.campaign_type?._id !== '64884b6bd40198f62b283007',
        },
        {
          label: 'Funnel limit/ day',
          field: 'drip_limit_day',
          type: 'text',
          size: 2,
          required: true,
          visible: ({values}) =>
            values?.communication_type === 'Automatic' &&
            values?.campaign_type?._id === '64d0fd55ab78dfead48c144b',
        },
        {
          label: 'Criteria',
          field: 'criteria',
          required: true,
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Country',
                  field: 'country.name',
                  type: 'text',
                },
                {
                  header: 'Industry',
                  field: 'industry.name',
                  type: 'text',
                },
                {
                  header: 'Stage',
                  field: 'stage.name',
                  type: 'text',
                },
                {
                  header: 'Batch',
                  field: 'batch_id.name',
                  type: 'text',
                },
              ]}
              fields={[
                {
                  field: 'country',
                  placeholder: 'Country',
                  label: 'Country',
                  type: 'autoComplete',
                  api: `/countries`,
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  field: 'industry',
                  placeholder: 'Industry',
                  label: 'Industry',
                  type: 'autoComplete',
                  api: `/industries`,
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  field: 'stage',
                  placeholder: 'Stage',
                  label: 'Stage',
                  type: 'autoComplete',
                  api: `/stages`,
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  field: 'batch_id',
                  placeholder: 'Batch',
                  label: 'Batch',
                  type: 'autoComplete',
                  api: `/usersegments`,
                  suggestionField: 'name',
                  valueField: 'name',
                },
              ]}
            />
          ),
          visible: ({values}) =>
            values?.communication_type === 'Automatic' &&
            values?.campaign_type?._id === '64d0fd55ab78dfead48c144b',
        },
        {
          label: 'Ownership',
          fields: [
            {
              label: 'Owner',
              field: 'assigned_to',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 'medium',
              required: true,
            },
            {
              label: 'Linkedin Profile',
              field: 'linkedin_profile',
              type: 'autoComplete',
              api: '/linkedinprofiles',
              suggestionField: 'email',
              valueField: 'email',
              size: 'medium',
              // required: true,
              visible: ({values}) =>
                values?.campaign_type?._id !== '64884b6bd40198f62b283007',
            },
            {
              label: 'Email Profile',
              field: 'email_profile',
              type: 'autoComplete',
              api: '/emailprofiles',
              suggestionField: 'email',
              valueField: 'email',
              size: 'medium',
              // required: true,
              visible: ({values}) =>
                values?.campaign_type?._id !== '64884b6bd40198f62b283007',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Channel',
              field: 'channel',
              type: 'autoComplete',
              api: `/channels`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              filter: {
                _id: {
                  $nin: ['66d2ec16f897151a53daf08b'],
                },
              },
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Employee Team',
              field: 'employee_team',
              type: 'autoComplete',
              api: `/employeeteams`,
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Templatization',
              type: 'radioGroup',
              options: ['AI', 'Manual'],
              field: 'campaign_method',
              onChangeValue: (value, e, {setFieldValue}) => {
                value === 'AI' ? setFieldValue('is_mr', null) : null;
                value === 'AI'
                  ? setFieldValue('system_controlled', true)
                  : setFieldValue('system_controlled', null);
              },
            },
          ],
          visible: ({values}) => {
            return (
              values?.communication_type === 'Automatic' &&
              values?.campaign_type?._id === '64d0fd55ab78dfead48c144b'
            );
          },
        },
        {
          label: 'Configuration',
          fields: [
            {
              label: 'Auto Withdrawal',
              field: 'auto_withdrawal',
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 2,
            },
            {
              label: 'Auto withdrawal In Days',
              field: 'auto_withdrawal_time',
              type: 'number',
              visible: ({values}) => {
                return values.auto_withdrawal;
              },
              size: 2,
            },

            {
              label: 'Auto Schedule',
              field: 'auto_schedule',
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 2,
            },
            {
              label: 'Observers',
              field: 'observer',
              placeholder: 'Assign observer',
              type: 'multiAutoComplete',
              api: `/users`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              valueField: 'name',
            },
          ],
          visible: ({values}) => values?.campaign_method === 'AI',
        },
        {
          fields: [
            {
              label: 'Auto Send',
              type: 'radioGroup',
              options: [
                {label: 'On', value: true},
                {
                  label: 'Off (Needs verification before sending)',
                  value: false,
                },
              ],
              field: 'auto_send',
            },
          ],
          visible: ({values}) =>
            values?.communication_type === 'Automatic' &&
            values?.campaign_type?._id === '64d0fd55ab78dfead48c144b',
        },
        {
          fields: [
            {
              type: 'checkbox',
              field: 'is_mr',
              label: 'Is MR',
              size: 6,
            },
          ],
          visible: ({values}) =>
            values?.communication_type === 'Manual' &&
            values?.campaign_type?._id === '64d0fd55ab78dfead48c144b',
        },
        {
          label: 'Woodpecker',
          fields: [
            {
              label: 'Campaign Account',
              field: 'campaign_account',
              type: 'radioGroup',
              suggestionField: 'label',
              keyField: 'value',
              options: [
                {label: 'Unthinkable', value: 'A1'},
                {label: 'Daffodil', value: 'L1'},
              ],
            },
            {
              label: 'Campaign Ids',
              field: 'campaign_id',
              type: 'text',
            },
            {
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_nurtuable',
                  label: 'Is Nurtuable',
                  size: 6,
                },
              ],
            },
          ],
          visible: ({values}) =>
            values?.communication_type === 'Manual' &&
            values?.campaign_type?._id === '64d0fd55ab78dfead48c144a',
        },
        {
          fields: [
            {
              label: 'Campaign Type Str',
              field: 'other_campaign_type',
              type: 'autoComplete',
              options: ['Email', 'Inmail'],
              required: true,
            },
          ],
          visible: ({values}) =>
            values?.communication_type === 'Manual' &&
            values?.campaign_type?._id === '64db3dc4334a9d2f6766ded2',
        },
      ]}
      {...props}
    />
  );
};

export const EditUniversalCampaignForm = props => {
  return (
    <AddUniversalCampaignForm
      mode="edit"
      header={'Campaign Detail'}
      {...props}
    />
  );
};
