import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeAssetStatusForm = props => {
  const {
    route: {params},
  } = props;

  const {row, api, secondaryTitle} = params;

  const {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: [
      'View',
      'DataService',
      'Controller',
      'Model',
      'ThirdPartyIntegration',
    ],
    ...props,
  });

  return (
    <Form
      defaultValues={{
        _id: row?.versionId,
        artifact_status: row?.artifact_status,
        complexity_id: row?.complexity_id,
      }}
      header={{
        title: `Update Asset Status`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Status',
          field: 'artifact_status',
          type: 'autoComplete',
          options: ['New', 'Update', 'Existing'],
          required: true,
        },
        {
          label: 'Complexity',
          type: 'autoComplete',
          field: 'complexity_id',
          api: '/projectcomplexities',
          suggestionField: 'complexity_type',
          secondarySuggestionField: 'point',
          valueField: 'complexity_type',
        },
      ]}
      {...props}
    />
  );
};
