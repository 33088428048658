import React from 'react';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {BankTransactionTable} from './TransactionTables';
import {getCurrentMonth} from '@unthinkable/react-filters';

export const BankTransactionTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <BankTransactionTable
          addOnFilter={{
            transaction_status: 'Pending authoriser Appoval',
            ...filterValues.filter,
          }}
          tab="Requested"
          searchValue={searchValue}
          {...props}
        />
      ),
    },
    Successful: {
      label: 'Successful',
      view: (
        <BankTransactionTable
          addOnFilter={{
            transaction_status: {$in:['Success',"Manually updated"]},
            ...filterValues.filter,
          }}
          tab="Successful"
          searchValue={searchValue}
          {...props}
        />
      ),
    },
    UnSuccessful: {
      label: 'UnSuccessful',
      view: (
        <BankTransactionTable
          addOnFilter={{
            transaction_status: 'Fail',
            ...filterValues.filter,
          }}
          tab="UnSuccessful"
          searchValue={searchValue}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Transaction No"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              label: 'Payment Date',
              type: 'date',
              field: 'payment_date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
