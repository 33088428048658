import {Text} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {Form, MultiStepForm} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const UpdateLeavePolicy = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/setEmployeeLeavePolicy',
    eventSourceId: 'EmployeeAssignment',
    ids: selectedIds,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Leave Policy',
          header: 'Leave Policy',
          field: 'leave_policy_id',
          type: 'autoComplete',
          api: '/leavepolicies',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          placeholder: 'From',
          field: 'from_date',
          header: 'From',
        },
        {
          type: 'date',
          placeholder: 'To',
          field: 'to_date',
          header: 'To',
        },
        {
          label: 'On maternity leave',
          field: 'on_maternity_leave',
          type: 'checkbox',
        },
      ]}
    />
  );
};

export const UpdateHolidayCalendar = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/setEmployeeHolidayCalendar',
    eventSourceId: 'EmployeeAssignment',
    ids: selectedIds,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Holiday calendar',
          header: 'Holiday calendar',
          field: 'holiday_calendar',
          type: 'autoComplete',
          api: '/holidaycalendars',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          placeholder: 'From',
          field: 'from_date',
          header: 'From',
          required: true,
        },
        {
          type: 'date',
          placeholder: 'To',
          field: 'to_date',
          header: 'To',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateRosterPolicy = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/setEmployeeRosterPolicy',
    eventSourceId: 'EmployeeAssignment',
    ids: selectedIds,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Roster policy',
          header: 'Roster policy',
          field: 'roster_policy_id',
          type: 'autoComplete',
          api: '/rosterpolicies',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          placeholder: 'From',
          field: 'from_date',
          header: 'From',
          required: true,
        },
        // {
        //   type: 'date',
        //   placeholder: 'To',
        //   field: 'to_date',
        //   header: 'To',
        //   // required: true,
        // },
      ]}
    />
  );
};

export const UpdateScheduleInformation = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/setEmployeeScheduleInformation',
    eventSourceId: 'EmployeeAssignment',
    ids: selectedIds,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Schedule information',
          header: 'Schedule information',
          field: 'schedule_information',
          type: 'autoComplete',
          api: '/schedules',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          placeholder: 'From',
          field: 'from_date',
          header: 'From',
          required: true,
        },
        {
          type: 'date',
          placeholder: 'To',
          field: 'to_date',
          header: 'To',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateShiftInformation = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/setEmployeeShiftInformation',
    eventSourceId: 'EmployeeAssignment',
    ids: selectedIds,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Shift information',
          header: 'Shift information',
          field: 'shift_information',
          type: 'autoComplete',
          api: '/shifts',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          placeholder: 'From',
          field: 'from_date',
          header: 'From',
          required: true,
        },
        {
          type: 'date',
          placeholder: 'To',
          field: 'to_date',
          header: 'To',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateEmployeeLeavePolicy = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateLeavePolicy
      header={{
        title: 'Update leave policy',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeHolidayCalendar = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateHolidayCalendar
      header={{
        title: 'Update holiday calendar',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeRosterPolicy = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateRosterPolicy
      header={{
        title: 'Update Roster Policy',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeScheduleInformation = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateScheduleInformation
      header={{
        title: 'Update schedule information',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeShiftInformation = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateShiftInformation
      header={{
        title: 'Update shift information',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};
