import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  ActiveTraineesTable,
  AllCompletedTrainees,
  AllTraineesTable,
} from './AllTraineesTable';

export const AllTraineesTabs = props => {
  const filterProps = useFilter();
  const {
    filterValues: {filter},
  } = filterProps;

  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();

  const tabs = {
    active: {
      label: 'Active',
      view: <ActiveTraineesTable />,
      api: '/allTrainees',
      eventSourceId: ['EmployeeTrainings'],
      filter: {
        status: {$in: ['active', 'extended']},
        ...filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportAllTrainees',
              props: {
                filter: {status: {$in: ['active', 'extended']}, ...filter},
              },
            });
          }}
        />,
      ],
    },
    // extended: {
    //   label: 'Extended',
    //   view: <AllTraineesTable status="extended" />,
    //   api: '/allTrainees',
    //   eventSourceId: ['EmployeeTrainings'],
    //   filter: {
    //     status: 'extended',
    //     ...filter,
    //   },
    //   actions: [
    //     <IconButton
    //       icon={Download}
    //       onPress={() => {
    //         download({
    //           uri: '/exportAllTrainees',
    //           props: {
    //             filter: {status: 'extended', ...filter},
    //           },
    //         });
    //       }}
    //     />,
    //   ],
    // },
    completed: {
      label: 'Completed',
      view: <AllCompletedTrainees status="completed" />,
      api: '/allcompletedtrainees',
      eventSourceId: ['EmployeeTrainings'],
      filter: {
        status: 'completed',
        ...filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportAllTrainees',
              props: {
                filter: {status: 'completed', ...filter},
              },
            });
          }}
        />,
      ],
    },
    dropped: {
      label: 'Dropped',
      view: <AllTraineesTable status="dropped" />,
      api: '/allTrainees',
      eventSourceId: ['EmployeeTrainings'],
      filter: {
        status: 'dropped',
        ...filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportAllTrainees',
              props: {
                filter: {status: 'dropped', ...filter},
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      headerComponent={
        <TableHeader
          title="All Trainees"
          actions={[
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  label: 'Trainee',
                  type: 'autoComplete',
                  field: 'employee',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  placeholder: 'Select Trainee',
                },
                {
                  label: 'Trainer',
                  type: 'autoComplete',
                  field: 'mentor',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  placeholder: 'Select Trainer',
                },
                {
                  label: 'Course Batch',
                  type: 'autoComplete',
                  field: 'training_batch',
                  api: '/trainingBatches',
                  suggestionField: 'name',
                  placeholder: 'Select Course Batch',
                  secondarySuggestionField: 'batch_number',
                },
                {
                  label: 'From Date',
                  type: 'date',
                  field: 'from_date',
                  placeholder: 'From Date',
                },
                {
                  label: 'Extended',
                  type: 'autoComplete',
                  options: [{label: 'Yes', value: 'extended'}],
                  placeholder: 'Select',
                  field: 'status',
                  suggestionField: 'label',
                  keyField: 'value',
                  valueField: 'label',
                },
              ]}
            />,
          ]}
        />
      }
      tabs={tabs}
      extraFilter={filter}
      {...props}
    />
  );
};
