import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  EmployeeWorkLoadForBilledAndUnbilled,
  EmployeeWorkLoadForProjectTeam,
} from './EmployeeWorkLoadForProjectTeam';

const groupFilter = [
  {
    type: 'autoComplete',
    label: 'Reporting To',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    secondarySuggestionField: 'official_email_id',
    valueField: 'name',
    field: 'reporting_to',
  },
  {
    type: 'autoComplete',
    label: 'Practice',
    api: '/practices',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'primary_practice',
  },
  {
    type: 'autoComplete',
    api: '/offices',
    suggestionField: 'name',
    valueField: 'name',
    field: 'sitting_location',
    label: 'Location',
    size: 6,
  },
  {
    type: 'autoComplete',
    placeholder: 'Select',
    options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
    label: 'Experience Range',
    field: 'experience_range',
  },
  {
    type: 'autoComplete',
    placeholder: 'Select',
    options: [
      {label: 'CE', value: 'A+'},
      {label: 'OE', value: 'A'},
      {label: 'ME', value: 'B+'},
      {label: 'PE', value: 'B'},
      {label: 'DE', value: 'C'},
    ],
    field: 'project_feedback_rating',
    label: 'Last Rating',
    suggestionField: 'label',
    keyField: 'value',
    valueField: 'label',
  },
];

export const EnggAllResourceTabsForProjectTeam = props => {
  const {route: {params} = {}, fromICMenu, fromManagerMenu} = props;

  const defaultFilter = {};
  if (fromICMenu) {
    defaultFilter.is_manager = false;
  }
  if (fromManagerMenu) {
    defaultFilter.is_manager = true;
  }
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {params: filterParams} = filterValues;
  const filter = {...params, ...filterParams};

  if (!fromICMenu) {
    groupFilter.push({
      type: 'autoComplete',
      options: [
        {label: 'True', value: true},
        {label: 'False', value: false},
      ],
      field: 'is_manager',
      label: 'Is Manager',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    });
  }

  const tabs = {
    employee: {
      label: 'All Engineers',
      view: (
        <EmployeeWorkLoadForProjectTeam
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          benchComparision={params?.benchComparision || false}
        />
      ),
      api: '/employees-team/workload',
      addOnFilter: filterValues?.filter || {},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilter}
        />,
      ],
      params: {...params, ...filter},
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const EnggAllResourceBilledAndUnBilledTabs = props => {
  const {route: {params} = {}, fromICMenu, fromManagerMenu} = props;

  const defaultFilter = {};
  if (fromICMenu) {
    defaultFilter.is_manager = false;
  }
  if (fromManagerMenu) {
    defaultFilter.is_manager = true;
  }
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {params: filterParams} = filterValues;
  const filterParameters = {...params, ...filterParams};

  if (!fromICMenu) {
    groupFilter.push({
      type: 'autoComplete',
      options: [
        {label: 'True', value: true},
        {label: 'False', value: false},
      ],
      field: 'is_manager',
      label: 'Is Manager',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    });
  }

  const tabs = {
    billed_employee: {
      label: 'Billable',
      view: (
        <EmployeeWorkLoadForBilledAndUnbilled
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          benchComparision={params?.benchComparision || false}
          fromBilled={true}
          tab="Billable"
        />
      ),
      api: '/employees-workload/billed-and-unbilled',
      addOnFilter: filterValues?.filter,
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilter}
        />,
      ],
      params: {...params, ...filterParameters, fromBilled: true, tab: 'Billable'},
    },

    unbilled_employee: {
      label: 'Non-Billable',
      view: (
        <EmployeeWorkLoadForBilledAndUnbilled
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          benchComparision={params?.benchComparision || false}
          fromBilled={false}
          tab="Non-Billable"
        />
      ),
      api: '/employees-workload/billed-and-unbilled',
      addOnFilter: filterValues?.filter,
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilter}
        />,
      ],
      params: {...params, ...filterParameters, fromBilled: false, tab: 'Non-Billable'},
    },
  };

  return <TabView tabs={tabs} {...props} />;
};
