import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const SalaryComponent = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/salarycomponents',
    eventSourceId: 'salaryComponent',
  });
  return (
    <Form
      api={`/salaryComponent/detail/${row?._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {component_type, ...rest} = data;
        let componentType = {};
        if (component_type === 'earning_type') {
          componentType['type'] = 'e';
          componentType['status'] = 'a';
        } else if (component_type === 'deduction_type') {
          componentType['type'] = 'd';
          componentType['status'] = 'a';
        }
        return {data: {...rest, ...componentType}};
      }}
      submitAction="Save"
      defaultValues={{
        component_type: params?.type || 'earning_type',
        component_calculation_base_id: params?.component_calculation_base_id,
      }}
      layoutFields={[
        {
          label: 'Component Type',
          field: 'component_type',
          type: 'autoComplete',
          options: [
            {label: 'Earning', value: 'earning_type'},
            {label: 'Deductions', value: 'deduction_type'},
            {label: 'Statutory', value: 'statutory_type'},
          ],
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'value',
          size: 6,
          visible: () => !props?.mode,
        },
        {
          label: 'Component Type',
          field: 'component_type_id',
          api: '/salarycomponents',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
        },
        {
          label: 'Payment type',
          field: 'payment_type_id',
          type: 'autoComplete',
          api: '/paymenttypes',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
        },
        {
          label: 'Calculation Base',
          field: 'component_calculation_base_id',
          api: '/componentcalculationbasetypes',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
        },
        {
          label: 'Payment Cycle',
          field: 'payment_cycle_id',
          api: '/paymentcycles',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Pay To',
          field: 'pay_to_id',
          api: '/paytos',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          type: 'autoComplete',
          size: 6,
        },
        {
          label: 'Payment After Month',
          field: 'pay_after_month',
          type: 'number',
          size: 6,
          visible: ({values}) => {
            if (
              values &&
              values.component_calculation_base_id &&
              values.component_calculation_base_id.name == 'Performance Based'
            )
              return true;
          },
        },
        {
          field: 'rate',
          label: 'Rate',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Percentage Of Components',
          placeholder: 'Percentage Of Components',
          field: 'percentage_of_components',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          field: 'target',
          label: 'Target',
          placeholder: 'Target',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Ignore In Exception Report',
          placeholder: 'Ignore In Exception Report',
          field: 'ignore_in_exception_report',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Base Amount',
          placeholder: 'Base Amount',
          field: 'base_amount',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (
              (values && values.component_type === 'statutory_type') ||
              values.component_type === 'deduction_type'
            )
              return true;
          },
        },
        {
          label: 'Product',
          placeholder: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: '/products',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Not Show For Gross',
          placeholder: 'Not Show For Gross',
          field: 'not_show_for_gross',
          type: 'text',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: ' Organization',
          placeholder: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          label: 'Max Limit',
          placeholder: 'Max Limit',
          field: 'max_limit',
          type: 'number',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'statutory_type')
              return true;
          },
        },
        {
          type: 'text',
          label: 'Name In Payslip',
          placeholder: 'Name',
          field: 'name',
          size: 6,
          visible: ({values}) => {
            if (values && values.component_type === 'earning_type') return true;
          },
        },
        {
          label: 'Include In Gross',
          field: 'include_in_gross_id',
          type: 'multiAutoComplete',
          api: '/salarycomponents',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          multi:true,
          size: 6,
          visible: ({values}) => {
            if (
              values &&
              values.component_calculation_base_id &&
              values.component_calculation_base_id.name === 'Net Payable Based'
            )
              return true;
          },
        },
        {
          label: 'Include In Id',
          field: 'include_in_id',
          api: '/salaryComponents',
          type: 'multiAutoComplete',
          suggestionField: 'name',
          valueField: 'name',
          keyField: 'name',
          size: 6,
          visible: ({values}) => {
            if (
              values &&
              values.component_calculation_base_id &&
              values.component_calculation_base_id.name === 'Net Payable Based'
            )
              return true;
          },
        },
        {
          label: 'calculation Type',
          fields: [
            {
              field: 'ctc_calculation_type',
              type: 'radioGroup',
              options: [
                {value: 'fix_amount', label: 'Fix Amount'},
                {value: 'percentage', label: 'Percentage'},
              ],
              valuefield: 'value',
              suggestionField: 'label',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Extend when component extends',
              field: 'is_extendable',
              type: 'checkbox',
            },
          ],
        },
        {
          fields: [
            {
              field: 'dependency_field',
              api: '/salaryComponents',
              suggestionField: 'name',
              valueField: 'name',
              keyField: 'name',
              size: 6,
              type: 'autoComplete',
              label: 'Dependency Field',
              visible: ({values}) => {
                if (values && values.ctc_calculation_type == 'percentage')
                  return true;
              },
            },
            {
              type: 'number',
              label: 'Percentage',
              field: 'ctc_percentage',
              size: 6,
              visible: ({values}) => {
                if (values && values.ctc_calculation_type == 'percentage')
                  return true;
              },
            },
          ],
          visible: ({values}) => {
            if (values && values.ctc_calculation_type == 'percentage')
              return true;
          },
        },
        {
          groups: [
            {
              label: 'Account',
              defaultHidden: true,
              fields: [
                {
                  label: 'Payable Account',
                  field: 'payable_account_id',
                  type: 'autoComplete',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  keyField: 'name',
                  size: 6,
                },
                {
                  field: 'deductable_account_id',
                  label: 'Deductable Account',
                  type: 'autoComplete',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  keyField: 'name',
                  size: 6,
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              label: 'Other Details',
              defaultHidden: true,
              fields: [
                {
                  label: "Don't Show In Salary Slip",
                  field: 'not_show_in_salary_slip',
                  type: 'checkbox',
                  size: 6,
                },
                {
                  label: "Don't Show In Salary Sheet",
                  field: 'not_show_in_salary_sheet',
                  type: 'checkbox',
                  size: 6,
                },
                {
                  label: 'Not Show for Payable',
                  field: 'not_show_for_payable',
                  type: 'checkbox',
                  size: 6,
                },
                {
                  label: 'Is Upper Round',
                  field: 'is_upper_round',
                  type: 'checkbox',
                  size: 6,
                },
                {
                  label: 'Is Taxable',
                  field: 'is_taxable',
                  placeholder: 'Is Taxable',
                  type: 'checkbox',
                  size: 6,
                },
                {
                  label: 'Is Fix Amount',
                  field: 'is_fix_amount',
                  type: 'checkbox',
                  size: 6,
                  visible: ({values}) =>
                    values &&
                    values.component_calculation_base_id &&
                    values.component_calculation_base_id.name &&
                    values.component_calculation_base_id.name == 'Fixed',
                },
                {
                  label: 'Sequence For Salary Sheet',
                  field: 'sequence_for_salary_sheet',
                  type: 'number',
                  size: 6,
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddSalaryComponent = props => {
  return <SalaryComponent header="Add salary Component" {...props} />;
};

export const EditSalaryComponent = props => {
  return (
    <SalaryComponent
      mode="edit"
      header={'Update salary Component'}
      {...props}
    />
  );
};

const SalaryComponentSetup = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/salarycomponents',
    eventSourceId: 'SalaryComponent',
  });
  return (
    <Form
      api={`/salarycomponents/${row?._id}`}
      fields={{
        name: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          required: true,
          field: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddSalaryComponentSetup = props => {
  return <SalaryComponentSetup header={'Add Salary Component'} {...props} />;
};

export const EditSalaryComponentSetup = props => {
  return (
    <SalaryComponentSetup
      mode="edit"
      header={'Update Salary Component'}
      {...props}
    />
  );
};
