import React from 'react';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { Form } from '../../../components/form/Form';
import { useTheme } from '@unthinkable/react-theme';
import { Text } from '@unthinkable/react-core-components';
import { useAppStateContext } from '../../../providers/AppState';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

const rmfeedbackComputation = fetch => ({
    mentorship: {
        compute: async value => {
            const { employee, mentorship } = value;
            const { employee_level } = employee;
            const { level } = employee_level;
            if (level >= 4 && mentorship && (mentorship > 35 || mentorship < 0)) {
                throw new Error('Rating should be out of 35');
            }
            else if (level < 4 && mentorship && (mentorship > 5 || mentorship < 0)) {
                throw new Error('Rating should be out of 5');
            }
            else {
                return { mentorship: mentorship }
            }
        },
        multi: true,
        dependencies: ['mentorship'],
    },
    certification_score: {
        compute: async value => {
            const { certification_score } = value;
            if (certification_score && (certification_score > 3 || certification_score < 0)) {
                throw new Error('Rating should be out of 3');
            }
            else {
                return { certification_score: certification_score }
            }
        },
        multi: true,
        dependencies: ['certification_score'],
    },
    rm_training_given_score: {
        compute: async value => {
            const { rm_training_given_score } = value;
            if (rm_training_given_score && (rm_training_given_score > 3 || rm_training_given_score < 0)) {
                throw new Error('Rating should be out of 3');
            }
            else {
                return { rm_training_given_score: rm_training_given_score }
            }
        },
        multi: true,
        dependencies: ['rm_training_given_score'],
    },
})

export const EmployeeRMFeedbackForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    let { onSubmit } = useFormSubmit({
        uri: '/employeeprojectfeedbacks',
        eventSourceId: ['PendingProjectFeedback', 'ProcessedProjectFeedback'],
        ...props,
    });
    const { fetch } = useAppStateContext();

    const defaultValues = {
        _id: row?._id,
        is_rm_feedback_given: true,
        rm_feedback_submitted_on: getZeroTimeDate(new Date())
    }

    return (
        <Form
            api={`/employeeprojectfeedback/${row?._id}`}
            defaultValues={{ ...defaultValues }}
            beforeSubmit={({ data }) => {
                return {
                    data: {
                        ...data,
                        is_rm_feedback_given: true,
                        rm_feedback_submitted_on: getZeroTimeDate(new Date())
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction={'Save'}
            mode="edit"
            computations={{ ...rmfeedbackComputation(fetch) }}
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Experience',
                            label: 'Experience',
                            field: 'employee.experience',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'People Manager',
                            label: 'People Manager',
                            field: 'employee.functional_manager.name',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Due Date',
                            label: 'Appraisal Due Date',
                            field: 'assesment_id.effective_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Closure Date',
                            label: 'Appraisal Closure Date',
                            field: 'assesment_id.closed_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project Start Date',
                            label: 'Project Start Date',
                            field: 'assesment_id.effective_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project End Date',
                            label: 'Project End Date',
                            field: 'assesment_id.closed_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values) {
                            const { employee: { employee_level = {} } = {} } = values;
                            const { level } = employee_level;
                            if (level < 4) {
                                return true
                            }
                        }
                    },
                    label: 'Trainings Given(Based on number and quality of training)',
                    fields: [
                        {
                            field: 'rm_training_given_score',
                            placeholder: "Score",
                            label: 'Score',
                            required: true,
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'rm_training_given_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 3`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'Relevant Certifications as per Business Need',
                    fields: [
                        {
                            field: 'certification_score',
                            placeholder: "Score",
                            required: true,
                            label: 'Score',
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            size: 6,
                            field: 'certication_score_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 3`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'No.Of Interview Rejections',
                    fields: [
                        {
                            field: 'number_of_interview_rejection',
                            placeholder: "Score",
                            required: true,
                            size: 6,
                            label: 'Score',
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'number_of_interview_rejection_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`3 points for Each Rejections`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'No.of Rejection from employees end for RFR/Technology change/project change/location change',
                    fields: [
                        {
                            field: 'number_of_rejection',
                            placeholder: "Score",
                            required: true,
                            size: 6,
                            label: 'Score',
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'number_of_rejection_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`3 points for Each Rejections`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'Mentorship',
                    fields: [
                        {
                            field: 'mentorship',
                            placeholder: "Score",
                            required: true,
                            label: 'Score',
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'mentorship_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                const { employee: { experience = 0} = {} } = row;
                                let text = 5
                                if (experience >= 4.6) {
                                    text = 35
                                }
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of ${text}`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    fields: [
                        {
                            field: 'name',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                        {
                            field: "comments",
                            type: "textArea",
                            label: "Comments",
                            size: 6,
                            required: true
                        }
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const EmployeeRMFeedback = props => {
    let {
        route: { params },
    } = props;
    const { row , tab, fromClosedRmFeedbackTab} = params;
    let readOnly = false
    if(tab === "HR" || tab === "Processed"){
        readOnly = true
    }
    if(fromClosedRmFeedbackTab){
        readOnly = true
    }
    return (
        <EmployeeRMFeedbackForm
            header={{
                title: 'Edit Resource Feedback'
            }}
            readOnly = {readOnly}
            {...props}
        />
    );
};

export const EmployeeSelfRMFeedbackForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    return (
        <Form
            api={`/employeeprojectfeedback/${row?._id}`}
            mode="edit"
            layoutFields={[
                {
                    label: 'Resource Management Feedback',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "reporting_to.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'text',
                            label: 'Technology',
                            field: "technology.name",
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values) {
                            const { employee: { employee_level = {} } = {} } = values;
                            const { level } = employee_level;
                            if (level < 4) {
                                return true
                            }
                        }
                    },
                    label: 'Trainings Given(Based on number and quality of training)',
                    fields: [
                        {
                            field: 'rm_training_given_score',
                            placeholder: "Score",
                            label: 'Score',
                            required: true,
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'rm_training_given_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 3`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'Relevant Certifications as per Business Need',
                    fields: [
                        {
                            field: 'certification_score',
                            placeholder: "Score",
                            required: true,
                            label: 'Score',
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            size: 6,
                            field: 'certication_score_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 3`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'No.Of Interview Rejections',
                    fields: [
                        {
                            field: 'number_of_interview_rejection',
                            placeholder: "Score",
                            required: true,
                            size: 6,
                            label: 'Score',
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'number_of_interview_rejection_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`3 points for Each Rejections`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'No.of Rejection from employees end for RFR/Technology change/project change/location change',
                    fields: [
                        {
                            field: 'number_of_rejection',
                            placeholder: "Score",
                            required: true,
                            size: 6,
                            label: 'Score',
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'number_of_rejection_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`3 points for Each Rejections`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    label: 'Mentorship',
                    fields: [
                        {
                            field: 'mentorship',
                            placeholder: "Score",
                            required: true,
                            label: 'Score',
                            size: 6,
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'mentorship_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                const { employee: { employee_level = {} } = {} } = row;
                                const { level } = employee_level;
                                let text = 5
                                if (level >= 4) {
                                    text = 35
                                }
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of ${text}`}
                                    </Text>
                                );
                            },
                        }
                    ],
                },
                {
                    fields: [
                        {
                            field: 'name',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                        {
                            field: "comments",
                            type: "textArea",
                            label: "Comments",
                            size: 6,
                            required: true
                        }
                    ],
                },
            ]}
            {...props}
        />
    );
};

export const EmployeeSelfRMFeedback = props => {
    let {
        route: { params },
    } = props;
    let readOnly = true
    return (
        <EmployeeSelfRMFeedbackForm
            header={{
                title: 'Edit Resource Feedback'
            }}
            readOnly = {readOnly}
            {...props}
        />
    );
};

