import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

export const ResourceSkill = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      mode="edit"
      submitAction="Save"
      fields={{
        resource_language: {_id: 1, name: 1},
        seconday_resource_language: {_id: 1, name: 1},
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Resource Language',
          api: '/tools',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'resource_language',
        },
        // {
        //   type: 'autoComplete',
        //   label: 'Secondary Resource Language',
        //   api: '/tools',
        //   placeholder: 'Select',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   field: 'seconday_resource_language',
        // },
      ]}
      {...props}
    />
  );
};

export const ResourcePractice = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      mode="edit"
      submitAction="Save"
      fields={{
        primary_practice: {_id: 1, name: 1},
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Practice',
          api: '/practices',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'primary_practice',
        },
      ]}
      {...props}
    />
  );
};

export const ResourceSecondarySkill = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeesecondaryskills',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employeesecondaryskills/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        employee: row?._id,
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Secondary Resource Language',
          api: '/tools',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          field: 'secondary_resource_language',
        },
        {
          label: 'Start Date',
          type: 'date',
          field: 'from_date',
          required: true,
          size: 6,
        },
        {
          label: 'End Date',
          type: 'date',
          field: 'to_date',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditResourceSkill = props => {
  return <ResourceSkill header={'Update Primary Skill'} {...props} />;
};

export const EditResourceSecondarySkill = props => {
  return (
    <ResourceSecondarySkill header={'Update Secondary Skill'} {...props} />
  );
};

export const EditResourcePractice = props => {
  return <ResourcePractice header={'Update Resource Practice'} {...props} />;
};

export const EmployeeSecondarySkillTable = props => {
  const {
    route: {params},
  } = props;
  const {row, fromKpi = false} = params;
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'employeereportees',
  });
  let filter = {employee: row?._id};
  if (fromKpi) {
    filter = {
      employee: row?.employee?._id,
    };
  }
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      api={`/employeesecondaryskills`}
      filter={filter}
      fields={{
        secondary_resource_language: {name: 1},
        from_date: 1,
        to_date: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Secondary Skill"
            secondaryTitle={`${row?.name}`}
            actions={[
              <AddButton
                title="Secondary Skill"
                view="edit-resource-secondary-skill"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Sec. Skill',
          field: 'secondary_resource_language.name',
          type: 'text',
        },
        {
          header: 'Start Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'End Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
      ]}
      // moreActions={[deletePrimaryReportee({invoke})]}
    />
  );
};
