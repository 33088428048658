import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const ChangeAllocationForm = props => {
  const params = props?.route?.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    ...props,
  });
  const {
    user: {
      employee: {_id: employeeId},
    },
  } = useAppStateContext();
  return (
    <Form
      onSubmit={onSubmit}
      header="Change allocation"
      submitAction="Save"
      defaultValues={{
        employee: row,
      }}
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Allocate to Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          filter: {
            managers: employeeId,
          },
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Object',
          field: 'object',
          type: 'autoComplete',
          api: `/types`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          type: 'number',
          field: 'percentage',
          label: 'Allocation',
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'Start Date',
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'End Date',
        },
      ]}
    />
  );
};

export default ChangeAllocationForm;
