import React, {useState} from 'react';
import {Row} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form-editors/Editors';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';

import {useAppStateContext} from '../../../providers/AppState';
import {Button} from '../../../components/button/Button';
import {RecruiterWisePositionsAndCandidatesTable} from './JobOpeningsTable';

export const RecruiterwiseCandidateReport = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const [isComparative, setIsComparative] = useState(false);

  const {download} = useAppStateContext();
  const {params: filterParams = {}} = filterValues || {};
  const tabs = {
    candidates: {
      label: 'Candidates',
      view: (
        <RecruiterWisePositionsAndCandidatesTable
          searchValue={searchValue}
          params={params}
          filterParams={filterParams}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
      eventSourceId: 'practiceWiseOpportunity',
      search: searchValue,
      searchFields: 'name',
      params: {...params, ...filterParams},
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
