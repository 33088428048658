import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { UserAvatarWithNameCodeJoiningDate } from '../../reimbursement/components/ReimbursementColumnRenders';
import { Text } from '@unthinkable/react-core-components';
import { AmountRender } from '../../order/views/components/AmountRender';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';
import { useGroupBy } from '../../../controllers/useGroupBy';
import { GroupBy } from '../../../components/table/GroupBy';
import { useTheme } from '@unthinkable/react-theme';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import { TabView } from '../../../components/tab';
export const ProvisionalSalaryTable = props => {
    const {
        navigation,
        route: { params } = {},
        addOnFilter = {},
        groupBy
    } = props;

    const colors = useTheme('colors');
    const { download } = useAppStateContext();
    const fetchProps = {
        addOnFilter,
        api: `/provisionalSalaryData`,
        addonGroupBy: groupBy?.addonGroupBy,
        groupRow: groupBy?.groupRow,
    };
    return (
        <Table
            eventSourceId={['Appraisal']}
            api={'/provisionalSalaryData'}
            limit={100}
            addOnFilter={{ ...addOnFilter }}
            addonGroupBy={groupBy?.addonGroupBy}
            groupRow={groupBy?.groupRow}
            // onRowPress={({row}) =>
            //   navigation.navigate('hr-appraisal-detail', {
            //     row,
            //   })
            // }
            selection={{
                actions: [
                    {
                        title: 'Download',
                        onPress: props =>
                            download({
                                uri: '/exportprovisionalsalarydata',
                                props: {
                                    selectedIds: props.selectedIds,
                                    fetchProps,
                                },
                            }),
                    },
                ],
            }}
            columns={[
                {
                    header: 'Employee',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameCodeJoiningDate
                                name={row?.employee?.name}
                                color={row?.employee?.color}
                                employee_code={row?.employee?.employee_code}
                                joining_date={row?.employee?.joining_date}
                                primarySubTitle={row?.employee?.experience}
                            />
                        );
                    },
                    minWidth: 260,
                },
                {
                    header: 'Appraisal Due',
                    type: 'date',
                    field: 'effective_date',
                    width: 120,
                    formatOptions: { format: 'DD MMM YY' },
                },
                {
                    header: 'Designation',
                    type: 'text',
                    field: 'employee.designation.name',
                    width: 250,
                },
                {
                    header: 'Current Ctc',
                    type: 'number',
                    render: ({ row, styles }) => {
                        return (
                            <AmountRender
                                row={row}
                                styles={styles}
                                amount={row?.employee_salary_component?.ctc_amount || 0}
                            />
                        );
                    },
                },
                {
                    header: 'Proposed%',
                    type: 'text',
                    render: ({ row, styles: { rowText = {} } }) => {
                        return <Text style={{
                            ...rowText,
                            color: colors.INFORMATION_HIGH,
                        }}> {'15%'} </Text>;
                    },
                },
                {
                    header: 'Due For Days',
                    type: 'text',
                    field : 'ovedue_days'
                },
                {
                    header: 'Provision For Arrears',
                    type: 'number',
                    width: 200,
                    aggregate : true,
                    field : "proposed_amount",
                    render: ({ row, styles }) => {
                        return (
                            <AmountRender
                                row={row}
                                styles={styles}
                                amount={row?.proposed_amount || 0}
                            />
                        );
                    },
                }

            ]}
        />
    );
};


export const ProvisionalSalaryTab = props => {
    let { route: { params } = {}, navigation } = props;
    const addOnFilter = {
        status: 'Active'
    }
    const filterProps = useFilter();
    const { filterValues } = filterProps;
   
    const groupBy = useGroupBy({
        options: [
            {
                label: 'Department',
                field: 'department',
                groupRow: {
                    data: '_children',
                    defaultExpanded: true,
                    leftContent: ({ row }) => {
                        return (
                            <RowWithSeparator style={{ gap: 8, alignItems: 'center' }}>
                                <GroupContentItem value={row.name} />
                                <GroupContentItem value={row?.proposed_amount?.toFixed(2)} />
                            </RowWithSeparator>
                        );
                    },
                },
            },

        ],
    });
    const tabs = {
      Active: {
        label: 'Active',
        view: (
          <ProvisionalSalaryTable
            addOnFilter={{...filterValues.filter, ...addOnFilter}}
            groupBy={groupBy}
            {...props} />
        ),
        eventSourceId: 'provisionalSalary',
        api: '/provisionalSalaryData',
        addOnFilter: { status: "Active" }
      },
    };
  
    return (
      <TabView
        {...props}
        tabs={tabs}
        params={params}
        actions={[
            <GroupFilter
                {...filterProps}
                filters={[
                    {
                        type: 'multiAutoComplete',
                        label: 'Employee',
                        api: '/employeeSuggestions',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        secondarySuggestionField: 'official_email_id',
                        valueField: 'name',
                        field: 'employee',
                        searchFields: ['name', 'official_email_id', 'employee_code'],
                    },
                    {
                        type: 'date',
                        label: 'Appraisal Due Date',
                        field: 'date',
                    },
                ]}
            />,
            <GroupBy {...groupBy} />,
        ]}
      />
    );
  };