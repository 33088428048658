import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DialogueBoxForm = props => {
  const {
    route: {params = {}},
  } = props;

  let {
    header = 'Date',
    field = 'confirmed_on',
    label = 'Date',
    uri = '',
    eventSourceId = '',
    status = 'In Experiment',
  } = params;
  if (uri == '') {
    return (
      <div>
        <h3>Please Contact the developer for the issue.</h3>
      </div>
    );
  }

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: eventSourceId,
  });

  const updateStatus = data => {
    invoke({
      uri: uri,
      props: {
        ...data,
        status,
      },
    });
  };

  return (
    <Form
      onSubmit={updateStatus}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        [field]: new Date(),
      }}
      layoutFields={[
        {
          type: 'date',
          placeholder: 'Date',
          field: field,
          label: label,
        },
      ]}
    />
  );
};

export const MarkCampaignRetired = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/campaigns',
    eventSourceId: 'inmailCampaignAdded',
    ids: selectedIds,
  });

  return (
    <Form
      {...props}
      onSubmit={props => {
        onSubmit(props);
      }}
      defaultValues={{
        status: 'Retired',
        retired_on: new Date(),
      }}
      header={'Retire Date'}
      submitAction={'Retire'}
      layoutFields={[
        {
          type: 'date',
          placeholder: 'Date',
          field: 'retired_on',
          label: 'Retire Date',
        },
      ]}
    />
  );
};
