import {Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useAppStateContext} from '../../../providers/AppState';

const SubStageRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  let newDate = new Date(row?.sub_stage_date).toLocaleTimeString('en-US');
  newDate = newDate.replace(/:\d{2}\s/, ' ');
  if (row?.case_status == 'Settled') {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.INFORMATION_LOW}
          textColor={colors.INFORMATION_HIGH}
          value={row?.case_status}
          borderRadius={6}
        />
        <Row gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
            }}>
            {`${moment(row.closed_date).format('DD MMM, YY')}`}
          </Text>
        </Row>
      </>
    );
  }
  if (row?.case_status == 'Write-Off') {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.ACCENT2_LOW}
          textColor={colors.ACCENT2_HIGH}
          value={'Write-Off'}
          borderRadius={6}
        />
        <Row gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${moment(row.closed_date).format('DD MMM, YY')}`}
          </Text>
        </Row>
      </>
    );
  }
  if (row?.case_status == 'Decided') {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.ACCENT2_LOW}
          textColor={colors.ACCENT2_HIGH}
          value={row?.case_status}
          borderRadius={6}
        />
        <Row gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
            }}>
            {`${moment(row.closed_date).format('DD MMM, YY')}`}
          </Text>
        </Row>
      </>
    );
  }
  if (row?.case_status == 'Withdrawn') {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.WARNING_LOW}
          textColor={colors.WARNING_HIGH}
          value={row?.case_status}
          borderRadius={6}
        />
        <Row gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
            }}>
            {`${moment(row.closed_date).format('DD MMM, YY')}`}
          </Text>
        </Row>
      </>
    );
  }
  if (row?.sub_stage == 'Submitted') {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.SUCCESS_LOW}
          textColor={colors.SUCCESS_HIGH}
          value={row?.sub_stage}
          borderRadius={6}
        />
        <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${newDate}`}
          </Text>
        </RowWithSeparator>
      </>
    );
  }
  if (
    row?.sub_stage == 'Requirement gathering' ||
    row?.sub_stage == 'Business Requirement Document & Technical Assistance'
  ) {
    return (
      <>
        <Chip
          // {...rest}
          color={colors.ACCENT6_LOW}
          textColor={colors.ACCENT6_HIGH}
          value={'Req. Gathering'}
          borderRadius={6}
        />
        <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${newDate}`}
          </Text>
        </RowWithSeparator>
      </>
    );
  }
};

export const OngoingLegalCases = props => {
  let {
    navigation,
    addOnFilter,
    tab,
    filterValues,
    searchValue,
    route: {params},
  } = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'legal',
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    api: `/legalcases`,
    sort: {created_on: 1},
    searchFields: 'case_number',
  };
  const {download} = useAppStateContext();
  return (
    <Table
      eventSourceId={['legal']}
      api={`/legalcases`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="case_number"
      limit={500}
      fields={{
        case_number: 1,
        plaintiff_complainant: 1,
        defendant_accused: 1,
        year_of_filing: 1,
        next_date_of_hearing: 1,
        amount_involved: 1,
        case_stage: {stage: 1},
        jurisdiction: {jurisdiction: 1},
        court_room: 1,
        client: {client: 1},
        last_date_of_hearing: 1,
        current_status: 1,
        plan_of_action: 1,
        case_type: {case_type: 1},
        last_order: 1,
        case_brief_link: 1,
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Case No.': 'case_number',
                      'Year of filing': 'year_of_filing',
                      Plaintiff: 'plaintiff_complainant',
                      Defendant: 'defendant_accused',
                      'Court Room': 'court_room',
                      Jurisdiction: 'jurisdiction.jurisdiction',
                      Client: 'client.client',
                      Status: 'current_status',
                      'Last Hearing': 'last_date_of_hearing',
                      'Plan of action': 'plan_of_action',
                      'Next Date of hearing': 'next_date_of_hearing',
                      'Case type': 'case_type.case_type',
                      Amount: 'amount_involved',
                      'Last Order': 'last_order',
                      'Case brief link': 'case_brief_link',
                      Stage: 'case_stage.stage',
                    },
                    filename: 'Ongoing Legal Cases',
                  },
                },
              }),
          },
        ],
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-legal-case', {row});
      }}
      columns={[
        {
          header: 'Case No.',
          // align: 'center',
          width: 150,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.case_number}
                items={[{value: row?.year_of_filing}]}
              />
            );
          },
        },
        // {
        //   header: 'Case No.',
        //   field: 'case_number',
        //   type: 'colorTag',
        //   // width: 150,
        // },
        {
          header: 'Plaintiff',
          field: 'plaintiff_complainant',
          type: 'text',
          width: 165,
        },
        {
          header: 'Defendant',
          field: 'defendant_accused',
          type: 'text',
          width: 150,
          // minWidth: 200,
        },

        {
          header: 'CR & Jurisdiction',
          // align: 'center',
          width: 150,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.court_room}
                items={[{value: row?.jurisdiction?.jurisdiction}]}
              />
            );
          },
        },
        {
          header: 'Client',
          field: 'client.client',
          type: 'text',
          width: 120,
        },
        {
          header: 'Status & Last hearing',
          // align: 'center',
          width: 230,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.current_status}
                items={[
                  {
                    value: moment(row?.last_date_of_hearing).format(
                      'DD MMM, YY',
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Action plan & Next hearing',
          // align: 'center',
          width: 210,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.plan_of_action}
                items={[
                  {
                    value: moment(row?.next_date_of_hearing).format(
                      'DD MMM, YY',
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Type',
          field: 'case_type.case_type',
          type: 'colorTag',
          width: 165,
        },
        {
          header: 'Amount',
          field: 'amount_involved',
          type: 'currency',
        },
        {
          header: 'Last Order',
          field: 'last_order',
          type: 'text',
          width: 300,
        },
        {
          header: 'Case brief link',
          field: 'case_brief_link',
          type: 'text',
          width: 200,
        },
        {
          header: 'Stage',
          field: 'case_stage.stage',
          type: 'colorTag',
          width: 200,
        },
      ]}
      moreActions={[
        {
          title: 'Update Case Status',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-case-status`, {
              ...params,
              legalcase: row,
            });
          },
        },
        {
          title: 'Update',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`edit-legal-case-proceedings`, {
              ...params,
              row,
            });
          },
        },
      ]}
    />
  );
};

export const ClosedLegalCases = props => {
  let {
    navigation,
    addOnFilter,
    tab,
    filterValues,
    searchValue,
    route: {params},
  } = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'legal',
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    api: `/legalcases`,
    sort: {created_on: 1},
    searchFields: 'case_number',
  };
  const {download} = useAppStateContext();

  return (
    <Table
      eventSourceId={['legal']}
      api={`/legalcases`}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{created_on: -1}}
      searchFields="case_number"
      limit={500}
      fields={{
        case_number: 1,
        plaintiff_complainant: 1,
        defendant_accused: 1,
        year_of_filing: 1,
        next_date_of_hearing: 1,
        amount_involved: 1,
        case_stage: {stage: 1},
        jurisdiction: {jurisdiction: 1},
        court_room: 1,
        client: {client: 1},
        last_date_of_hearing: 1,
        current_status: 1,
        plan_of_action: 1,
        case_type: {case_type: 1},
        last_order: 1,
        case_brief_link: 1,
        case_status: 1,
        closed_date: 1,
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Case No.': 'case_number',
                      'Year of filing': 'year_of_filing',
                      Plaintiff: 'plaintiff_complainant',
                      Defendant: 'defendant_accused',
                      'Court Room': 'court_room',
                      Jurisdiction: 'jurisdiction.jurisdiction',
                      Client: 'client.client',
                      Status: 'current_status',
                      'Last Hearing': 'last_date_of_hearing',
                      'Plan of action': 'plan_of_action',
                      'Next Date of hearing': 'next_date_of_hearing',
                      'Case type': 'case_type.case_type',
                      Amount: 'amount_involved',
                      'Last Order': 'last_order',
                      'Case brief link': 'case_brief_link',
                      Stage: 'case_stage.stage',
                      'Case Status': 'case_status',
                    },
                    filename: 'Closed Legal Cases',
                  },
                },
              }),
          },
        ],
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-legal-case', {row, readOnly: true});
      }}
      columns={[
        {
          header: 'Case No.',
          // align: 'center',
          width: 150,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.case_number}
                items={[{value: row?.year_of_filing}]}
              />
            );
          },
        },
        // {
        //   header: 'Case No.',
        //   field: 'case_number',
        //   type: 'colorTag',
        //   // width: 150,
        // },
        {
          header: 'Plaintiff',
          field: 'plaintiff_complainant',
          type: 'text',
          width: 165,
        },
        {
          header: 'Defendant',
          field: 'defendant_accused',
          type: 'text',
          width: 150,
          // minWidth: 200,
        },

        {
          header: 'CR & Jurisdiction',
          // align: 'center',
          width: 150,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.court_room}
                items={[{value: row?.jurisdiction?.jurisdiction}]}
              />
            );
          },
        },
        {
          header: 'Client',
          field: 'client.client',
          type: 'text',
          width: 120,
        },
        {
          header: 'Status & Last hearing',
          // align: 'center',
          width: 230,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.current_status}
                items={[
                  {
                    value: moment(row?.last_date_of_hearing).format(
                      'DD MMM, YY',
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Action plan & Next hearing',
          // align: 'center',
          width: 210,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.plan_of_action}
                items={[
                  {
                    value: moment(row?.next_date_of_hearing).format(
                      'DD MMM, YY',
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Type',
          field: 'case_type.case_type',
          type: 'colorTag',
          width: 165,
        },
        {
          header: 'Amount',
          field: 'amount_involved',
          type: 'currency',
        },
        {
          header: 'Last Order',
          field: 'last_order',
          type: 'text',
          width: 300,
        },
        {
          header: 'Case brief link',
          field: 'case_brief_link',
          type: 'text',
          width: 200,
        },
        {
          header: 'Stage',
          field: 'case_stage.stage',
          type: 'colorTag',
          width: 200,
        },
        {
          header: 'Status',
          render: SubStageRender,
          width: 160,
          // width: 180,
        },
        // {
        //   header: 'Next Hearing',
        //   field: 'next_date_of_hearing',
        //   type: 'date',
        //   width: 150,
        // },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequest/delete/${row._id}`,
              props: {
                ...row,
              },
            });
          },
        },
        {
          title: 'Update Case Status',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-case-status`, {
              ...params,
              legalcase: row,
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const JurisdictionTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="legal"
      api={`/jurisdictions`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-jurisdiction`, {
          juristiction: row?._id,
        });
      }}
      fields={{
        jurisdiction: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Jurisdiction"
            actions={[
              <AddButton title="Jurisdiction" view="add-jurisdiction" />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'jurisdiction',
          header: 'Jurisdiction',
        },
      ]}
    />
  );
};

export const LegalClientTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="legal"
      api={`/legalclients`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-legal-client`, {
          client: row?._id,
        });
      }}
      fields={{
        client: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Client"
            actions={[<AddButton title="Client" view="add-legal-client" />]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'client',
          header: 'Client',
        },
      ]}
    />
  );
};

export const CaseTypeTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="legal"
      api={`/casetypes`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-case-type`, {
          case_type: row?._id,
        });
      }}
      fields={{
        case_type: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Case Type"
            actions={[<AddButton title="Client" view="add-case-type" />]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'case_type',
          header: 'Case Type',
        },
      ]}
    />
  );
};

export const CaseStageTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="legal"
      api={`/legalcasestages`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-case-stage`, {
          stage: row?._id,
        });
      }}
      fields={{
        stage: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Case Stage"
            actions={[<AddButton title="Client" view="add-case-stage" />]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'stage',
          header: 'Case Stage',
        },
      ]}
    />
  );
};
