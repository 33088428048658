import React, {useContext, useMemo} from 'react';
import {AppModules, appModuleMapping} from '../modules/AppModules';

export const AppModuleContext = React.createContext();

const backendModules = {
  crm: 1,
  user: 1,
  organization: 1,
  attendancemanagement: 1,
  recruitment: 1,
  hrm: 1,
  finance: 1,
  skill: 1,
  asset: 1,
  payrollmanagement: 1,
  revenuemanagement: 1,
  expensemanagement: 1,
  task: 1,
  pmt: 1,
  helpmanagement: 1,
  legalmanagement: 1,
};
export const useAppModuleContext = () => {
  return useContext(AppModuleContext);
};

export const AppModuleProvider = ({children, user}) => {
  let {module_navigation = {}} = user || {};

  let filteredModuleData = useMemo(() => {
    return AppModules.reduce((acc, module) => {
      let {id, visible = true} = module;

      if (backendModules[id]) {
        if (module_navigation[id]) {
          let {id: moduleId, name} = module_navigation[id] || {};
          let mapping = appModuleMapping[id] || {};

          acc.push({id: moduleId, name, ...mapping});
        }
        return acc;
      }

      if (typeof visible === 'function') {
        visible = visible({user, module, fromModule: true});
      }

      if (visible) {
        acc.push(module);
      }
      return acc;
    }, []);
  }, [user, AppModules]);

  return (
    <AppModuleContext.Provider
      value={{
        moduleData: filteredModuleData,
      }}>
      {children}
    </AppModuleContext.Provider>
  );
};
