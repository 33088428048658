import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const DomainForm = props => {
  const {
    route: { params },
  } = props;
  const { row, website } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/domains',
    eventSourceId: 'domain',
  });
  return (
    <Form
      api={`/domains/${row?._id}`}
      fields={{
        domain: 1,
        icon: 1,
        active_icon: 1,
        is_domain: 1,
        parent: {
          domain: 1,
        },
        index: 1,
        is_published: 1,
        color: 1,
      }}
      defaultValues={{
        website,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Domain',
          type: 'text',
          field: 'domain',
          required: true,
        },
        {
          label: 'Icon',
          type: 'file',
          field: 'icon',
          size: 6,
        },
        {
          label: 'Active Icon',
          type: 'file',
          field: 'active_icon',
          size: 6,
        },
        {
          label: 'Domain',
          type: 'checkbox',
          field: 'is_domain',
          size: 6,
        },
        {
          label: 'Publish',
          type: 'checkbox',
          field: 'is_published',
          size: 6,
        },
        {
          label: 'Domain Module',
          type: 'autoComplete',
          api: '/domains',
          filter: {
            is_domain: true,
          },
          suggestionField: 'domain',
          valueField: 'domain',
          field: 'parent',
        },
        {
          label: 'Color',
          type: 'autoComplete',
          options: ['#9DF7FF', '#FBCDB1', '#FAD2FF', '#A3FFD6', '#DAD2FF', '#FFFF99', '#BFDBFF', '#FFBBCA'],
          field: 'color',
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        }
      ]}
      {...props}
    />
  );
};

export const AddDomainForm = props => {
  return <DomainForm header="Add Domain" {...props} />;
};

export const UpdateDomainForm = props => {
  return (
    <DomainForm mode="edit" header="Update Domain" {...props} />
  );
};
