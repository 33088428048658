import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateTrainingPerDayAllocation = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/employeeTrainings',
    eventSourceId: 'EmployeeTrainingsFromBatch',
    ...props,
  });
  const {
    route: {params},
  } = props;
  const {employee_training} = params;
  return (
    <Form
      header="Update Allocation"
      onSubmit={onSubmit}
      mode="edit"
      api={'/employeeTrainings/' + employee_training?._id}
      fields={{
        per_day_allocation: 1,
      }}
      layoutFields={[
        {
          field: 'per_day_allocation',
          type: 'number',
          label: 'Per Day Allocation',
          placeholder: 'Per Day Allocation',
        },
      ]}
      {...props}
    />
  );
};
