import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {TasksTable} from './TasksTable';
import {taskFilters, useTaskActions} from './TaskActions';

export const ArchivedOrganizationTaskTable = props => {
  const {
    route: {params},
    navigation,
    user,
    favorite,
    delegatedFilter = {},
  } = props;
  let {employee_team, fromMyView, userId, thread = {}, threadGoalId} = params;
  userId = userId || user?._id;

  let threadFilter = thread?._id  ? {thread: thread?._id, thread_goal: threadGoalId} : {};
  if(threadGoalId){
    threadFilter = {thread_goal: threadGoalId};
  }
  
  const favoriteFilter = favorite ? { favorite_by: {$in: [userId]} } : {};

  const {activityLogs, unarchive, deleteTask} = useTaskActions({
    navigation,
    params,
    fromMyView,
  });

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
      readOnly: fromMyView,
    });
  };

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: extraFilter} = filterValues;

  const addOnFilter = {
    type: {
      $in: ['Task', 'Continuous'],
    },
    ...extraFilter,
    ...threadFilter,
    ...delegatedFilter,
    ...favoriteFilter,
  };

  if (filterParams.is_open == 'Yes') {
    addOnFilter.assigned_to = null;
  } else if (filterParams.is_open == 'No') {
    addOnFilter.assigned_to = {$exists: true};
  }
  if (fromMyView && userId) {
    addOnFilter.assigned_to = userId;
  }

  return (
    <>
      <TableHeader
        title="Archived Tasks"
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <PeriodRangeFilter
            field={'archived_on'}
            asParam={false}
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
          />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              taskFilters.assigned_to,
              taskFilters.custom,
              taskFilters.strategic,
              taskFilters.rework,
              taskFilters.source,
            ]}
          />,
        ]}
      />
      <TasksTable
        {...props}
        api={`/organizationtasks`}
        params={{teamId: employee_team?._id, userId}}
        archived
        fromMyView={fromMyView}
        onRowPress={taskDetail}
        searchValue={searchValue}
        addOnFilter={{
          status: 'archived',
          ...addOnFilter,
        }}
        moreActions={[unarchive, activityLogs, deleteTask]}
      />
    </>
  );
};
