import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const CandidateFeedback = props => {
  const {route} = props;
  const {params} = route;
  const {candidate, field, feedback, editable} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: ['candidate'],
  });

  return (
    <Form
      header={{title: 'Candidate Feedback'}}
      readOnly={!editable}
      defaultValues={{
        ...feedback,
        feedback_date: new Date(),
      }}
      beforeSubmit={({data}) => ({
        data: {
          _id: candidate._id,
          [field]: [
            {
              ...feedback,
              ...data,
              status: 'Completed',
            },
          ],
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'overall_feedback',
          label: 'Overall feedback',
          type: 'rating',
          required: true,
        },
        {
          field: 'round_verdict',
          type: 'radioGroup',
          options: ['Pass', 'Fail'],
        },
        {
          label: 'Pass',
          fields: [
            {
              field: 'deployablity',
              type: 'radioGroup',
              options: ['Deployable', 'Trainable'],
            },
          ],
          visible: ({values}) => values.round_verdict == 'Pass',
        },
        {
          label: 'Comments',
          field: 'remarks',
          type: 'textArea',
          required: true,
        },
      ]}
    />
  );
};

export const CandidateFeedbackDetail = props => {
  const {route} = props;
  const {params} = route;
  const {candidate, field, feedback, editable} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: ['candidate'],
  });

  return (
    <Form
      header={{title: 'Candidate Feedback'}}
      readOnly={!editable}
      defaultValues={{
        ...feedback,
        feedback_date: new Date(),
      }}
      beforeSubmit={({data}) => ({
        data: {
          _id: candidate._id,
          [field]: [
            {
              ...feedback,
              ...data,
              status: 'Completed',
            },
          ],
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'overall_feedback',
          label: 'Overall feedback',
          type: 'rating',
          required: true,
        },
        {
          field: 'round_verdict',
          type: 'radioGroup',
          options: ['Pass', 'Fail'],
        },
        {
          label: 'Pass',
          fields: [
            {
              field: 'deployablity',
              type: 'radioGroup',
              options: ['Deployable', 'Trainable'],
            },
          ],
          visible: ({values}) => values.round_verdict == 'Pass',
        },
        {
          label: 'Comments',
          field: 'remarks',
          type: 'textArea',
        },
      ]}
    />
  );
};
