import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AddStaffAugmentationOpportunityForm = props => {
  const {route} = props;
  const {route: {params} = {}, header = 'Add Staff Augmentation'} = props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  }); 
  const {row} = params;
  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  let defaultValues = {
    rfr_type: 'Customer',
    opportunity_type: 'StaffAugmentationOpportunity',
    owner: employee,
    organization: params?.organization || employee?.organization,
    product: params?.product || employee?.product,
    creation_date: new Date(),
  };

  if (route?.name == 'add-staffaugmentationopportunity-proposal') {
    const {leadId} = params;
    const {data} = useFetchFormData({
      api: '/potentialCustomers',
      filter: {
        _id: leadId,
      },
      fields: {
        name: 1,
        owner: {_id: 1, name: 1},
        industry: {_id: 1, name: 1},
        department: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        organization: {_id: 1, name: 1},
      },
    });
    if (!data) {
      return null;
    }
    const {_id, owner, department, product, organization, name, ...restdata} =
      data;

    defaultValues = {
      ...restdata,
      account_owner: owner,
      potential_customer: {_id: leadId, name: name},
      rfr_type: 'PotentailCustomer',
      opportunity_type: 'StaffAugmentationOpportunity',
      owner: employeeId,
      organization,
      product,
      creation_date: new Date(),
    };
  }

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      api={`/opportunities/${row?._id}`}
      fields={{
        rfr_type: 1,
        customer_id: {name: 1},
        potential_customer: {name: 1},
        industry: {name: 1},
        client_rating: 1,
        probability: 1,
        priority: 1,
        work_location: 1,
        requirement: 1,
        description: 1,
        due_date: 1,
        process: 1,
        no_of_resources: 1,
        skill: {name: 1},
        practice: {name: 1},
        experience: {experience: 1},
        account_owner: {name: 1},
        owner: {name: 1},
        rfr_engagement_type: 1,
        tech_person: {name: 1},
        requirement_document: 1,
        product: {name: 1},
        organization: {name: 1},
        creation_date: 1,
        creation_time: 1,
      }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        // {
        //   type: 'radioGroup',
        //   field: 'rfr_type',
        //   // options: ['Customer', 'PotentailCustomer'],
        //   options: ['Customer'],
        // },
        {
          type: 'radioGroup',
          field: 'rfr_type',
          // options: ['Customer'],
          // options: ['Customer', 'PotentailCustomer'],
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            // {
            //   label: 'Potential Customer',
            //   value: 'PotentailCustomer',
            // },
          ],
        },
        {
          fields: [
            {
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/customers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           channel: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.channel);
              //   }
              // },
              visible: ({values}) =>
                values && values.rfr_type && values.rfr_type === 'Customer',
              required: ({values}) =>
                values && values.rfr_type && values.rfr_type === 'Customer',
            },
            {
              label: 'Potential Customer',
              field: 'potential_customer',
              type: 'autoComplete',
              api: '/potentialcustomers',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/potentialCustomers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           campaign_type: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.campaign_type);
              //   }
              // },
              visible: ({values}) =>
                values &&
                values.rfr_type &&
                values.rfr_type === 'PotentailCustomer',
              required: ({values}) =>
                values &&
                values.rfr_type &&
                values.rfr_type === 'PotentailCustomer',
            },
            {
              label: 'Industry',
              field: 'industry',
              type: 'autoComplete',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            // {
            //   field: 'client_rating',
            //   label: 'Client Rating',
            //   type: 'autoComplete',
            //   options: ['A+', 'A', 'B', 'C', 'D', 'E'],
            //   size: 6,
            // },
            {
              type: 'number',
              label: 'Probability',
              field: 'probability',
              size: 6,
            },
            // {
            //   field: 'priority',
            //   type: 'autoComplete',
            //   label: 'Priority',
            //   options: ['High', 'Medium', 'Low'],
            //   size: 6,
            // },
            {
              field: 'work_location',
              type: 'autoComplete',
              label: 'Work Location',
              options: ['OffSite', 'OnSite'],
              size: 6,
              required: true,
            },
            {
              type: 'date',
              field: 'creation_date',
              label: 'Created On',
              required: true,
              size: 6,
            },
            {
              type: 'duration',
              field: 'creation_time',
              label: 'Created On time(24 hr format)',
              required: true,
              editorAsRenderer: true,
              size: 6,
            },
            {
              type: 'textArea',
              field: 'requirement',
              label: 'Requirement',
              required: true,
            },
            {
              // type: 'richText',
              type: 'textArea',
              field: 'description',
              label: 'Description',
              required: true,
            },
            {
              type: 'date',
              field: 'due_date',
              label: 'Due Date',
              size: 6,
            },
            {
              field: 'process',
              type: 'autoComplete',
              label: 'Mode Of Screening',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              size: 6,
              required: true,
            },
            {
              type: 'number',
              field: 'no_of_resources',
              label: 'No. of Resources',
              required: true,
              size: 6,
            },
            {
              label: 'Skill',
              field: 'skill',
              type: 'multiAutoComplete',
              api: '/tools',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Practice',
              field: 'practice',
              type: 'multiAutoComplete',
              api: '/practices',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              field: 'experience',
              label: 'Experience',
              type: 'autoComplete',
              api: '/experiences',
              suggestionField: 'experience',
              valueField: 'experience',
              size: 6,
              required: true,
            },
          ],
        },
        // {
        //   label: 'Account Owner',
        //   field: 'account_owner',
        //   type: 'autoComplete',
        //   api: '/employeeSuggestions',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          label: 'Sales Owner',
          field: 'owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Engagement Type',
          field: 'rfr_engagement_type',
          type: 'autoComplete',
          options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
          required: true,
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        // {
        //   field: 'tech_person',
        //   label: 'Tech Person Name',
        //   type: 'multiAutoComplete',
        //   api: '/employeeSuggestions',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   size: 6,
        // },
        {
          placeholder: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
          required: true,
        },
        //no to or from date, client rating, mode of screening
      ]}
      {...props}
    />
  );
};

export const MakeCopyStaffAugmentationOpportunity = props => {
  const {route} = props;
  const {route: {params} = {}, header = 'Add Staff Augmentation'} = props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {row} = params;
  const {
    _id,
    organization,
    product,
    assigned_count,
    active_count,
    screening_count,
    interview_count,
    result_due_count,
    selected_count,
    lost_count,
    drop_count,
    ...restData
  } = row;
  const {user} = useAppStateContext();
  const {employee} = user;
  // const {_id: employeeId, organization, product} = employee;

  let defaultValues = {
    ...restData,
    owner: employee,
    organization: params?.organization || employee?.organization,
    product: params?.product || employee?.product,
    creation_date: new Date(),
    opportunity_type: 'StaffAugmentationOpportunity',
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        // {
        //   type: 'radioGroup',
        //   field: 'rfr_type',
        //   // options: ['Customer', 'PotentailCustomer'],
        //   options: ['Customer'],
        // },
        {
          type: 'radioGroup',
          field: 'rfr_type',
          // options: ['Customer'],
          // options: ['Customer', 'PotentailCustomer'],
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            // {
            //   label: 'Potential Customer',
            //   value: 'PotentailCustomer',
            // },
          ],
        },
        {
          fields: [
            {
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/customers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           channel: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.channel);
              //   }
              // },
              visible: ({values}) =>
                values && values.rfr_type && values.rfr_type === 'Customer',
              required: ({values}) =>
                values && values.rfr_type && values.rfr_type === 'Customer',
            },
            {
              label: 'Potential Customer',
              field: 'potential_customer',
              type: 'autoComplete',
              api: '/potentialcustomers',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/potentialCustomers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           campaign_type: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.campaign_type);
              //   }
              // },
              visible: ({values}) =>
                values &&
                values.rfr_type &&
                values.rfr_type === 'PotentailCustomer',
              required: ({values}) =>
                values &&
                values.rfr_type &&
                values.rfr_type === 'PotentailCustomer',
            },
            {
              label: 'Industry',
              field: 'industry',
              type: 'autoComplete',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            // {
            //   field: 'client_rating',
            //   label: 'Client Rating',
            //   type: 'autoComplete',
            //   options: ['A+', 'A', 'B', 'C', 'D', 'E'],
            //   size: 6,
            // },
            {
              type: 'number',
              label: 'Probability',
              field: 'probability',
              size: 6,
            },
            // {
            //   field: 'priority',
            //   type: 'autoComplete',
            //   label: 'Priority',
            //   options: ['High', 'Medium', 'Low'],
            //   size: 6,
            // },
            {
              field: 'work_location',
              type: 'autoComplete',
              label: 'Work Location',
              options: ['OffSite', 'OnSite'],
              size: 6,
              required: true,
            },
            {
              type: 'date',
              field: 'creation_date',
              label: 'Created On',
              required: true,
              size: 6,
            },
            {
              type: 'duration',
              field: 'creation_time',
              label: 'Created On time(24 hr format)',
              required: true,
              editorAsRenderer: true,
              size: 6,
            },
            {
              type: 'textArea',
              field: 'requirement',
              label: 'Requirement',
              required: true,
            },
            {
              // type: 'richText',
              type: 'textArea',
              field: 'description',
              label: 'Description',
              required: true,
            },
            {
              type: 'date',
              field: 'due_date',
              label: 'Due Date',
              size: 6,
            },
            {
              field: 'process',
              type: 'autoComplete',
              label: 'Mode Of Screening',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              size: 6,
              required: true,
            },
            {
              type: 'number',
              field: 'no_of_resources',
              label: 'No. of Resources',
              required: true,
              size: 6,
            },
            {
              label: 'Skill',
              field: 'skill',
              type: 'multiAutoComplete',
              api: '/tools',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Practice',
              field: 'practice',
              type: 'multiAutoComplete',
              api: '/practices',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              field: 'experience',
              label: 'Experience',
              type: 'autoComplete',
              api: '/experiences',
              suggestionField: 'experience',
              valueField: 'experience',
              size: 6,
              required: true,
            },
          ],
        },
        // {
        //   label: 'Account Owner',
        //   field: 'account_owner',
        //   type: 'autoComplete',
        //   api: '/employeeSuggestions',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          label: 'Sales Owner',
          field: 'owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Engagement Type',
          field: 'rfr_engagement_type',
          type: 'autoComplete',
          options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
          required: true,
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        // {
        //   field: 'tech_person',
        //   label: 'Tech Person Name',
        //   type: 'multiAutoComplete',
        //   api: '/employeeSuggestions',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   size: 6,
        // },
        {
          placeholder: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
        },
        //no to or from date, client rating, mode of screening
      ]}
      {...props}
    />
  );
};

export const EditStaffAugmentationOpportunityForm = props => {
  return (
    <AddStaffAugmentationOpportunityForm
      header={'Edit Staff Augmentation'}
      mode="edit"
      isDualMode
      {...props}
    />
  );
};

export const DetailStaffAugmentationOpportunityForm = props => {
  return (
    <AddStaffAugmentationOpportunityForm
      header={'Edit Staff Augmentation'}
      mode="edit"
      // isDualMode
      readOnly={true}
      {...props}
    />
  );
};

export const MakeCopyStaffAugmentationOpportunityForm = props => {
  return (
    <MakeCopyStaffAugmentationOpportunity
      header={'Add Staff Augmentation'}
      {...props}
    />
  );
};

export const MarkContractNegotiation = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Contract Negotiation'}
      submitAction={'Save'}
      defaultValues={{
        _id: row?._id,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'contract_negotiation_date',
          label: 'Contract Negotiation Date',
        },
      ]}
    />
  );
};

export const UpdateProposalHours = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Proposal Hours'}
      submitAction={'Update'}
      defaultValues={{
        _id: row?._id,
        proposal_hours: row?.proposal_hours,
      }}
      layoutFields={[
        {
          type: 'number',
          field: 'proposal_hours',
          label: 'Proposal Hours',
        },
      ]}
    />
  );
};
