import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const UpdateDeliveryOwnerForm = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds = []} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/updateDeliveryOwner',
    eventSourceId: ['revenue-invoice'],
  });

  return (
    <Form
      header={{
        title: 'Update Delivery Owner',
      }}
      beforeSubmit={({data}) => {
        return {data: {...data, selectedIds}};
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Delivery Owner',
          field: 'delivery_owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
export const UpdateAccountOwnerForm = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds = []} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/updateAccountOwner',
    eventSourceId: ['revenue-invoice'],
  });

  return (
    <Form
      header={{
        title: 'Update Account Owner',
      }}
      beforeSubmit={({data}) => {
        return {data: {...data, selectedIds}};
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Account Owner',
          field: 'account_owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
