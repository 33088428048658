import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {useInvoke} from '../../../controllers/useInvoke';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import images from '../../../images';
import {ThreadGoalForm} from './AddThreadGoalForm';
import {OverudueTasks} from './MyThreadTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useComponentTheme, useStyles, useTheme} from '@unthinkable/react-theme';
import {Col, Text, View, Image, Row} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {ReviewedTaskRenderStyle} from '../../pmt/cellrenders/theme';
import {fonts} from '../../../theme/theme';
import moment from 'moment';
import {THREAD_GOAL_ID} from '../../common/constants/SourceConstants';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {DateRenderer} from '../../../components/form-editors/Editors';
import {AddFilledAction} from '../../../components/action/AddAction';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {ActionText} from '../../../components/action/ActionText';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {NAME, PERFORMANCEMANAGER} from '../../employee/views/EmployeeTeamsList';
import {useFetchData} from '../../../controllers/useFetchData';

export const threadGoalCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      comment_source: {_id: THREAD_GOAL_ID, label: 'ThreadGoal'},
      comment_source_id: {_id: row._id, name: row.goal},
    }),
  });
};

const {Owner} = images;

const ThreadGoalNextTaskRender = props => {
  const {row = {}} = props;
  const {colors} = useTheme();
  const {textStyle} = useStyles(ReviewedTaskRenderStyle);

  let {next_task = {}} = row;
  let {name: nextTaskName, due_date: nextTaskDate} = next_task || {};

  if (!nextTaskName) {
    return <></>;
  }

  nextTaskDate = moment(nextTaskDate);

  return (
    <Col gap={4}>
      <View title={nextTaskName} numberOfLines={2} style={textStyle}>
        {nextTaskName}
      </View>
      <RowWithSeparator
        gap={6}
        style={{alignItems: 'center', flexWrap: 'wrap'}}>
        <View style={{...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM}}>
          {nextTaskDate.format('DD MMM')}
        </View>
      </RowWithSeparator>
    </Col>
  );
};

const DueDateRender = ({row}) => {
  const {due_date, update_due_date_reason} = row;
  const {colors, fonts} = useTheme();
  let {alertCircleGray} = useComponentTheme('PlusColumnRenderStyles');
  return (
    <Row gap={4}>
      {due_date ? (
        <>
          {update_due_date_reason ? (
            <View
              title={`Reason: ${update_due_date_reason}`}
              style={{justifyContent: 'center'}}>
              <Image source={alertCircleGray} style={{height: 12, width: 12}} />
            </View>
          ) : null}
          <DateRenderer
            format="DD MMM"
            value={due_date}
            color={colors.NEUTRAL_LOW}
            styles={{text: {...fonts.BODY2}}}
          />
        </>
      ) : (
        <AddFilledAction />
      )}
    </Row>
  );
};

export const dueDateColumn = ({navigation}) => ({
  render: DueDateRender,
  header: 'Due on',
  onPress: ({row}) => {
    navigation.navigate('due-date-form', {
      row,
      api: '/threadgoals',
    });
  },
  width: 80,
  fallbackRender: () => {
    <AddFilledAction />;
  },
  align: 'center',
});

const ThreadGoalAndIssuesTable = props => {
  const {
    navigation,
    route: {breadcrumb, ...params},
    searchValue,
    addOnFilter,
    isCompleted,
    isPlan,
    issues,
    myThreadGoals,
    threadNameNotVisible,
  } = props;

  console.log('>>>>>addOnFilter', addOnFilter);

  const updateInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'markCompleted',
  });

  let fields = {
    owner: {
      name: 1,
      color: 1,
    },
    goal: 1,
    issue: 1,
    due_date: 1,
    update_due_date_reason: 1,
    thread: {
      name: 1,
    },
    kpi: {
      kpi: 1,
    },
    keyperformanceindicator: {
      name: 1,
    },
    project: {project: 1},
  };
  if (!issues) {
    fields = {
      ...fields,
      next_task: {
        due_date: 1,
        name: 1,
      },
      tasks: {
        est_hours: 1,
        count: 1,
      },
      completed_tasks: {
        est_hours: 1,
        count: 1,
      },
      completed_on: 1,
      last_comment: {
        createdAt: 1,
      },
      comments_count: {_id: 1},
      members: {
        name: 1,
        color: 1,
      },
    };
  }

  const extraColumns = [];

  if (isCompleted) {
    extraColumns.push({
      header: 'Completed On',
      field: 'completed_on',
      width: 150,
      align: 'center',
      type: 'date',
      formatOptions: {
        format: 'DD MMM YY',
      },
    });
  }

  return (
    <>
      {!isCompleted && !myThreadGoals && isPlan ? (
        <ThreadGoalForm props={{...props, issues}} />
      ) : (
        void 0
      )}
      <Table
        eventSourceId={['threadGoal', 'markCompleted', 'Task', 'Comment']}
        api={issues ? '/threadissues' : '/threadgoals'}
        search={searchValue}
        searchFields={issues ? 'issue' : 'goal'}
        fields={{...fields}}
        filter={{...addOnFilter}}
        onRowPress={({row}) => {
          row = {...row, issues};
          if (issues) {
            navigation.navigate('edit-thread-goal-issue', {row});
          } else {
            row = {
              ...row,
              threadGoalId: row?._id,
              threadNameNotVisible,
              threadGoalNameNotVisible: true,
            };
            navigation.navigate('thread-goal-tasks', {...row});
          }
        }}
        columns={[
          {
            header: issues ? 'Issue' : 'Goal',
            field: issues ? 'issue' : 'goal',
            width: !issues && !isCompleted ? 300 : void 0,
            type: 'text',
          },
          !issues && !!myThreadGoals
            ? {
                header: 'Project',
                field: 'thread.name',
                type: 'text',
              }
            : void 0,
          {
            header: {icon: Owner},
            field: !issues ? 'members' : 'owner.name',
            type: !issues ? 'userAvatarGroup' : 'colorChip',
            width: !issues ? 150 : void 0,
            align: 'center',
          },
          {
            header: 'KPI',
            field: 'keyperformanceindicator.name',
            type: 'text',
          },
          !issues ? OverudueTasks({all_tasks: true, isCompleted}) : void 0,
          !issues && !isCompleted
            ? {
                header: 'Next Task',
                align: 'left',
                render: _props => {
                  return ThreadGoalNextTaskRender({...props, ..._props});
                },
              }
            : void 0,
          !issues ? dueDateColumn({navigation}) : void 0,
          ...extraColumns,
          !issues && isCompleted
            ? threadGoalCommentColumn({navigation, params})
            : void 0,
        ]}
        moreActions={() =>
          !isCompleted
            ? [
                {
                  title: 'Mark Completed',
                  confirm: {
                    title: 'Mark Completed',
                    message: `Are you sure you want to mark this ${
                      issues ? 'issue' : 'goal'
                    } as completed ?`,
                    confirmText: 'Confirm',
                  },
                  onPress: ({row}) => {
                    updateInvoke({
                      uri: issues
                        ? `/threadissues/${row?._id}`
                        : `/threadgoals/${row?._id}`,
                      props: {
                        status: 'Completed',
                      },
                    });
                  },
                  visible: !isPlan || !!issues,
                },
                {
                  title: 'Mark Active',
                  confirm: {
                    title: 'Mark Active',
                    message: `Are you sure you want to mark this ${
                      issues ? 'issue' : 'goal'
                    } as Active ?`,
                    confirmText: 'Confirm',
                  },
                  onPress: ({row}) => {
                    updateInvoke({
                      uri: issues
                        ? `/threadissues/${row?._id}`
                        : `/threadgoals/${row?._id}`,
                      props: {
                        status: 'Active',
                      },
                    });
                  },
                  visible: !!isPlan && !issues,
                },
                {
                  title: 'Mark Archived',
                  onPress: ({row}) => {
                    navigation.navigate('archive-thread-goal', {
                      row,
                    });
                  },
                  visible: !issues,
                },
                {
                  title: 'Edit',
                  onPress: ({row}) => {
                    navigation.navigate('edit-goal', {row, myThreadGoals});
                  },
                  visible: !issues,
                },
              ]
            : []
        }
      />
    </>
  );
};

export const ThreadGoalTabs = props => {
  const {
    route: {params},
    myThreadGoals,
    allThreadGoals,
  } = props;

  const {user} = useAppStateContext();
  const {employee = {}} = user || {};
  const {
    thread,
    row = {},
    threadNameNotVisible,
    departmentGoalFilter = {},
  } = params;
  const {employee_user, _id: employee_id} = row || {};
  const myThreadGoalFilter =
    myThreadGoals && !departmentGoalFilter.thread
      ? {
          $or: [
            {owner: employee_user?._id || user?._id},
            {members: employee_id || employee?._id || user?._id},
          ],
        }
      : {};

  const threadFilter = thread?._id ? {thread: thread._id} : {};

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });

  const {filter: extraFilter} = filterValues;

  let addOnFilter = {
    ...threadFilter,
    ...myThreadGoalFilter,
    ...departmentGoalFilter,
  };

  addOnFilter = !allThreadGoals ? {...addOnFilter} : {};

  return (
    <TabView
      tabs={{
        plan: {
          label: 'Plan',
          api: '/threadgoals',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Plan',
            ...addOnFilter,
            ...extraFilter,
          },
          search: searchValue,
          searchFields: 'goal',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Plan',
                ...addOnFilter,
                ...extraFilter,
              }}
              isCompleted={false}
              isPlan={true}
              issues={false}
              myThreadGoals={myThreadGoals}
              threadNameNotVisible={threadNameNotVisible}
            />
          ),
        },
        active: {
          label: 'Active',
          api: '/threadgoals',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Active',
            ...addOnFilter,
            ...extraFilter,
          },
          search: searchValue,
          searchFields: 'goal',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Active',
                ...addOnFilter,
                ...extraFilter,
              }}
              isCompleted={false}
              issues={false}
              myThreadGoals={myThreadGoals}
              threadNameNotVisible={threadNameNotVisible}
            />
          ),
        },
        completed: {
          label: 'Completed ',
          api: '/threadgoals',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Completed',
            ...addOnFilter,
            ...extraFilter,
          },
          search: searchValue,
          searchFields: 'goal',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Completed',
                ...addOnFilter,
                ...extraFilter,
              }}
              isCompleted={true}
              issues={false}
              myThreadGoals={myThreadGoals}
              threadNameNotVisible={threadNameNotVisible}
            />
          ),
        },
        archived: {
          label: 'Archived ',
          api: '/threadgoals',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Archive',
            ...addOnFilter,
            ...extraFilter,
          },
          search: searchValue,
          searchFields: 'goal',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Archive',
                ...addOnFilter,
                ...extraFilter,
              }}
              isCompleted={true}
              issues={false}
              myThreadGoals={myThreadGoals}
              threadNameNotVisible={threadNameNotVisible}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <AddButton
          title="Goal"
          view="add-goals"
          params={{...params, myThreadGoals}}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Members',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              field: 'members',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              filter: {employee_status: 'Active'},
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const ThreadIssuesTabs = props => {
  const {
    route: {params},
  } = props;

  const {thread} = params;
  const threadFilter = thread?._id ? {thread: thread._id} : {};

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {filter: extraFilter} = filterValues;

  let addOnFilter = {
    ...extraFilter,
    ...threadFilter,
  };

  return (
    <TabView
      tabs={{
        active: {
          label: 'Active',
          api: '/threadissues',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Active',
            ...addOnFilter,
          },
          search: searchValue,
          searchFields: 'issue',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Active',
                ...addOnFilter,
              }}
              isCompleted={false}
              issues={true}
              isPlan={true}
            />
          ),
        },
        completed: {
          label: 'Completed ',
          api: '/threadissues',
          eventSourceId: ['threadGoal', 'markCompleted'],
          filter: {
            status: 'Completed',
            ...addOnFilter,
          },
          search: searchValue,
          searchFields: 'issue',
          view: (
            <ThreadGoalAndIssuesTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Completed',
                ...addOnFilter,
              }}
              isCompleted={true}
              issues={true}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
      ]}
      {...props}
    />
  );
};

export const TeamGoalTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {user} = useAppStateContext();

  const {employee} = user || {};
  let employeeId = employee?._id;

  return (
    <Table
      eventSourceId={'Task'}
      api={`/employees/${employeeId}/teamTasks`}
      params={{teamAllTask: true}}
      columns={[
        {
          render: ({row}) => <UserAvatarChip value={row} />,
          header: 'Members',
        },
        {
          header: 'Team',
          field: 'teamCount',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            row?.teamCount &&
              navigation.navigate(`employee-team-tasks`, {
                ...params,
                employeeId: row._id,
                row,
              });
          },
        },
        {
          align: 'center',
          width: 200,
          render: () => {
            return <ActionText text="View Goals" />;
          },
          onPress: ({row}) => {
            navigation.navigate('my-thread-goal', {row});
          },
        },
      ]}
    />
  );
};

export const DepartmentThreadGoalTable = props => {
  const {params = {}, navigation} = props;

  const {fetch, user} = useAppStateContext();

  const filter = {
    performance_manager: user?.employee?._id,
  };

  return (
    <Table
      params={{
        ...params,
      }}
      addOnFilter={{...filter}}
      eventSourceId={['EmployeeTeam', 'markInactive']}
      searchFields="name"
      api={`/employees/nonprojectteams`}
      columns={[
        NAME,
        {
          render: CountCellRender,
          count_field: 'resource_count',
          header: 'Team',
          field: 'resource_count',
          aggregate: true,
          align: 'right',
          width: 100,
        },
        {
          field: 'avg_experience',
          header: 'Avg Exp',
          align: 'right',
          render: ({row, styles: {rowText = {}}}) => {
            return <Text style={{...rowText}}>{row?.avg_experience} yrs</Text>;
          },
          width: 100,
        },
        PERFORMANCEMANAGER,
        {
          align: 'center',
          width: 200,
          render: () => {
            return <ActionText text="View Goals" />;
          },
          onPress: async ({row}) => {
            const {data = []} = await fetch({
              uri: '/threads',
              props: {
                filter: {
                  employee_team: row?._id,
                },
                fields: {
                  _id: 1,
                },
              },
            });
            const threadIds = data.map(d => d._id);
            navigation.navigate('my-thread-goal', {
              departmentGoalFilter: {thread: {$in: threadIds}},
              row,
            });
          },
        },
      ]}
    />
  );
};
