import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupTable} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {
  SubtasksCountColumnRender,
  TaskAssignUserAvatar,
  TaskDynamicRender,
  taskCommentColumn,
  estHrs,
  CompletedTasksTable,
  dueDateColumn,
} from './TasksTable';
import {taskFilters, useTaskActions} from './TaskActions';
import Images from '../../../images';
import {AddFilledAction} from '../../../components/action/AddAction';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {MarkCompletedRender} from '../../../components/table/Renders';
import {useAppStateContext} from '../../../providers/AppState';

const {Owner, EstHours} = Images;

export const OrganizationActiveTasksGroupByPlan = props => {
  const {
    filter: propsFilter,
    navigation,
    route: {params},
    delegatedFilter = {},
    delegatedTask,
    favorite,
  } = props;
  const {fromMyView, thread = {}, threadGoalId, threadMeetingNotes, departmentTasks} = params;
  const {
    updatePlan,
    plan,
    updateDueDate,
    archive,
    moveToBacklog,
    activityLogs,
    markComplete,
    markStrategic,
    markFavorite,
    unsetEstHours,
    addThread,
  } = useTaskActions({
    navigation,
    params,
  });

  const {user} = useAppStateContext();

  let threadFilter = thread?._id
    ? {
        thread: thread?._id,
        thread_goal: threadGoalId,
        thread_meeting_notes: threadMeetingNotes,
      }
    : {};

  if(threadGoalId){
    threadFilter = {thread_goal: threadGoalId};
  }

  const favoriteFilter = favorite ? {favorite_by: {$in: [user?._id]}} : {};
  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
      readOnly: fromMyView,
    });
  };
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  let {filter} = filterValues;
  if (!filter?.show_parent_task) {
    filter = {...filter, is_parent: {$exists: false}};
  }
  filter = {...filter, ...threadFilter, ...favoriteFilter, ...delegatedFilter};
  return (
    <GroupTable
      selection={departmentTasks ? {
        actions: [addThread],
      }: null}
      renderHeader={() => {
        return (
          <TableHeader
            title="Active Tasks"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  !fromMyView && taskFilters.assigned_to,
                  taskFilters.favorite_by,
                  taskFilters.custom,
                  taskFilters.strategic,
                  taskFilters.rework,
                  taskFilters.due_date,
                  !fromMyView && taskFilters.is_open,
                  taskFilters.source,
                  taskFilters.show_parent_task,
                ]}
              />,
            ]}
          />
        );
      }}
      search={searchValue}
      searchFields={['name', 'code']}
      filter={filter}
      params={{
        teamId: params?.employee_team?._id,
        userId: params?.userId || params?.assigned_to,
        delegatedTask,
      }}
      eventSourceId={['Task', 'Comment']}
      api={'/activeTasksGroupByPlan'}
      limit={-1}
      fields={{
        name: 1,
        assigned_to: {name: 1},
        est_hours: 1,
        plan_date: 1,
        source: {name: 1, list_view: 1, label: 1},
        source_id: {name: 1},
        status: 1,
        completed_on: 1,
        recurring_task_id: {
          frequency_mode: 1,
        },
        output_link: 1,
        output_attachment: 1,
        is_continuous: 1,
        type: 1,
        open_for: 1,
        is_open: 1,
        due_date: 1,
        output_remark: 1,
        recent_reviewed_status: 1,
        recent_review: {
          review_avg: 1,
          reviewed_by: {name: 1, color: 1},
        },
        review_pending: 1,
        next_action_date: 1,
        next_action: 1,
        feature_id: {feature: 1, sub_status: 1},
        module_id: {module: 1},
        project_id: {project: 1},
        reviewer: {
          name: 1,
          color: 1,
        },
        master_task_assignment: {
          archive_disabled: 1,
          output_model_view: {
            label: 1,
            view: 1,
            filter_api: 1,
          },
        },
        archive_reason: 1,
        archived_on: 1,
        is_ad_hoc: 1,
        is_strategic: 1,
        disqualified: 1,
        sub_task_count: {_id: 1},
        completed_sub_task_count: {_id: 1},
        active_and_backlog_sub_task_count: {_id: 1},
        comments_count: {_id: 1},
        review_not_required: 1,
        parent_task: {name: 1},
        last_comment: {
          createdAt: 1,
        },
        favorite_by: 1,
        thread: {name: 1},
        thread_goal: {goal: 1},
        update_due_date_reason: 1,
        is_rework: 1,
      }}
      groupRow={{
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.label} />
              <GroupContentItem value={`Tasks: ${row.children?.length}`} />
              {row.est_hrs ? (
                <GroupContentItem value={`${row.est_hrs?.toFixed(2)} hrs`} />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        data: 'children',
        defaultExpandedIndex: 0,
      }}
      onRowPress={taskDetail}
      columns={[
        {
          width: 40,
          onPress: markComplete.onPress,
          render: MarkCompletedRender,
          disabled: ({row}) => {
            const {completed_sub_task_count, sub_task_count} = row || {};
            return !(
              (sub_task_count > 0 &&
                completed_sub_task_count === sub_task_count) ||
              (row?.est_hours && row?.assigned_to)
            );
          },
        },
        {
          header: 'Task',
          sourceField: 'source',
          sourceIdField: 'source_id',
          target_view_field: 'master_task_assignment.output_model_view.view',
          render: _props => {
            return TaskDynamicRender({
              ...props,
              ..._props,
              params,
            });
          },
        },
        {
          width: 70,
          params: params,
          navigation,
          header: {icon: Owner},
          render: TaskAssignUserAvatar,
          onLock: ({row}) => {
            navigation.navigate('assign-task', {
              ...params,
              row,
            });
          },
          responsive: 'sm',
          align: 'center',
          visible: !propsFilter?.assigned_to,
        },
        params?.fromMyView
          ? {
              header: {icon: EstHours},
              width: 80,
              type: 'duration',
              field: 'est_hours',
              align: 'right',
            }
          : estHrs({
              params,
              navigation,
            }),
        {
          type: 'date',
          header: 'Plan',
          field: 'plan_date',
          width: 80,
          align: 'center',
          onPress: ({row}) => {
            updatePlan.onPress({row});
          },
          fallbackRender: ({row}) => {
            return (
              <AddFilledAction
                onPress={() => {
                  plan.onPress({row});
                }}
              />
            );
          },
        },
        params?.fromMyView
          ? {
              type: 'date',
              header: 'Due On',
              field: 'due_date',
              width: 80,
              align: 'center',
            }
          : dueDateColumn({navigation}),
        {
          visible: !params?.parent_task,
          width: 85,
          render: SubtasksCountColumnRender,
          onPress: ({row}) => {
            navigation.navigate('sub-task-table', {
              parent_task: row,
              ...params,
            });
          },
        },
        taskCommentColumn({params, navigation}),
      ]}
      moreActions={[
        updateDueDate,
        archive,
        moveToBacklog,
        updatePlan,
        markStrategic,
        markFavorite,
        departmentTasks && addThread,
        activityLogs,
        unsetEstHours,
      ]}
    />
  );
};

// Akshat Garg - 26/08/24 - completed in custom tasks
export const OrganizationCompletedTasks = props => {
  return (
    <>
      <CompletedTasksTable
        parentSourceView="MyTeam"
        customCompletedTask={true}
        title={'Completed Tasks'}
        {...props}
      />
    </>
  );
};
