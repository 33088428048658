import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateCategoryForm = props => {
  const {route: {params} = {}} = props;
  const {selectedIds, fromReceipt, fromFundTransfer} = params;
  let {onSubmit} = useFormSubmit({
    uri: fromReceipt
      ? '/updateReceiptCategory'
      : fromFundTransfer
      ? '/updateFundTransferCategory'
      : '/updatePaymentCategory',
    eventSourceId: 'receipt',
  });
  return (
    <Form
      defaultValues={{selectedIds}}
      onSubmit={onSubmit}
      submitAction="Save"
      fields={{
        category: {name: 1},
      }}
      {...props}
    />
  );
};

export const UpdateReceiptCategory = props => {
  const {
    route: {params},
  } = props;
  const {fromReceipt} = params;
  return (
    <UpdateCategoryForm
      header="Update Category"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'category',
          label: 'Category',
          api: '/categories',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
          filter: {
            type: fromReceipt ? 'Receipt' : 'Payment',
          },
        },
      ]}
      {...props}
    />
  );
};
