import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { EmployeeAppraisalTable } from './EmployeeAppraisalTable';

const EmployeeAppraisalTab = props => {
    let { user = {}, route: { params = {} } = {} , ...rest } = props || {};
    const { applyFilter, filterValues, searchValue } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    let tabs = {};
    tabs['PendingAppraisal'] = {
        label: 'Pending',
        eventSourceId: 'Appraisal',
        view: <EmployeeAppraisalTable
            searchValue={searchValue}
            tab="TeamPending"
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'Active',
                offer_accepted_by_employee: { $in: [null, false] },
                ifIncrement_asPer_aggrement: { $in: [null, false] },
                people_manager_appraisal_status: { $in: [null, false] },
                unit_appraisal_status: { $in: [null, false] },
                processed_manually_by_hr: { $in: [null, false]},
            }}
            user={user}
            {...rest}
        />,
        addOnFilter: {
            ...filter,
            status: 'Active',
            offer_accepted_by_employee: { $in: [null, false] },
            ifIncrement_asPer_aggrement: { $in: [null, false] },
            people_manager_appraisal_status: { $in: [null, false] },
            unit_appraisal_status: { $in: [null, false] },
            processed_manually_by_hr: { $in: [null, false] },
        },
        api: '/employeeteamappraisal',
    }

    tabs['AwaitingResponse'] = {
        label: 'Unit Head',
        eventSourceId: 'Appraisal',
        view: <EmployeeAppraisalTable
            searchValue={searchValue}
            filterValues={filterValues}
            tab="Awaiting Response"
            addOnFilter={{
                ...filterValues.filter,
                offer_accepted_by_employee: { $ne: true },
                ifIncrement_asPer_aggrement: { $in: [null, false] },
                status: "Active",
                people_manager_appraisal_status: true,
                unit_appraisal_status: { $in: [null, false] },
                processed_manually_by_hr: { $in: [null, false] },
            }}
            user={user}
            {...rest} />,
        addOnFilter: {
            ...filter,
            offer_accepted_by_employee: { $ne: true },
            ifIncrement_asPer_aggrement: { $in: [null, false] },
            status: "Active",
            people_manager_appraisal_status: true,
            unit_appraisal_status: { $in: [null, false] },
            processed_manually_by_hr: { $in: [null, false] },
        },
        api: '/employeeteamappraisal',
    }

    // tabs['InDiscussion'] = {
    //     label: 'In Discussion',
    //     eventSourceId: 'Appraisal',
    //     view: <EmployeeAppraisalTable
    //         searchValue={searchValue}
    //         filterValues={filterValues}
    //         tab="In Discussion"
    //         addOnFilter={{
    //             ...filterValues.filter,
    //             status: 'Active',
    //             offer_accepted_by_employee: { $ne: true },
    //             ifIncrement_asPer_aggrement: { $in: [null, false] },
    //             people_manager_appraisal_status: true,
    //             unit_appraisal_status: true,
    //             processed_manually_by_hr: { $in: [null, false] },
    //         }}
    //         user={user}
    //         {...rest} />,
    //     addOnFilter: {
    //         ...filter,
    //         status: 'Active',
    //         offer_accepted_by_employee: { $ne: true },
    //         ifIncrement_asPer_aggrement: { $in: [null, false] },
    //         people_manager_appraisal_status: true,
    //         unit_appraisal_status: true,
    //         processed_manually_by_hr: { $in: [null, false] },
    //     },
    //     api: '/employeeteamappraisal',
    // }


    tabs['OnHold'] = {
        label: 'OnHold',
        eventSourceId: 'Appraisal',
        view: <EmployeeAppraisalTable
            searchValue={searchValue}
            filterValues={filterValues}
            tab="On Hold"
            addOnFilter={{
                ...filterValues.filter,
                offer_accepted_by_employee: { $ne: true },
                ifIncrement_asPer_aggrement: "OnHold",
                manually_processed_onhold_extend: { $in: [null, false] },
                status: "Active"
            }}
            user={user}
            {...rest} />,
        addOnFilter: {
            ...filter,
            offer_accepted_by_employee: { $ne: true },
            ifIncrement_asPer_aggrement: "OnHold",
            manually_processed_onhold_extend: { $in: [null, false] },
            status: "Active"
        },
        api: '/employeeteamappraisal',
    }

    tabs['Processed'] = {
      label: 'Processed',
      eventSourceId: 'Appraisal',
      view: (
        <EmployeeAppraisalTable
          searchValue={searchValue}
          filterValues={filterValues}
          tab="Processed"
          addOnFilter={{
            ...filterValues.filter,
            $or: [
              {
                employee_people_manager: {_id: user?.employee?._id},
                offer_accepted_by_employee: true,
                $or: [
                  {ifIncrement_asPer_aggrement: {$in: ['AsPerAggrement']}},
                  {
                    ifIncrement_asPer_aggrement: {$in: [null, false]},
                    processed_manually_by_hr: {$in: [null, false]},
                  },
                ],
                status: 'Active',
              },
              {
                new_employee_people_manager: {_id: user?.employee?._id},
                offer_accepted_by_employee: true,
                $or: [
                  {ifIncrement_asPer_aggrement: {$in: ['AsPerAggrement']}},
                  {
                    ifIncrement_asPer_aggrement: {$in: [null, false]},
                    processed_manually_by_hr: {$in: [null, false]},
                  },
                ],
                status: 'Active',
              },
              {
                new_employee_people_manager: {_id: user?.employee?._id},
                hrPayroll_closed_status: true,
                status: 'Closed',
              },
              {
                employee_people_manager: {_id: user?.employee?._id},
                hrPayroll_closed_status: true,
                status: 'Closed',
              },
            ],
          }}
          user={user}
          {...rest}
        />
      ),
      addOnFilter: {
        ...filter,
        $or: [
          {
            employee_people_manager: {_id: user?.employee?._id},
            offer_accepted_by_employee: true,
            $or: [
              {ifIncrement_asPer_aggrement: {$in: ['AsPerAggrement']}},
              {
                ifIncrement_asPer_aggrement: {$in: [null, false]},
                processed_manually_by_hr: {$in: [null, false]},
              },
            ],
            status: 'Active',
          },
          {
            new_employee_people_manager: {_id: user?.employee?._id},
            offer_accepted_by_employee: true,
            $or: [
              {ifIncrement_asPer_aggrement: {$in: ['AsPerAggrement']}},
              {
                ifIncrement_asPer_aggrement: {$in: [null, false]},
                processed_manually_by_hr: {$in: [null, false]},
              },
            ],
            status: 'Active',
          },
          {
            new_employee_people_manager: {_id: user?.employee?._id},
            hrPayroll_closed_status: true,
            status: 'Closed',
          },
          {
            employee_people_manager: {_id: user?.employee?._id},
            hrPayroll_closed_status: true,
            status: 'Closed',
          },
        ],
      },
      api: '/employeeteamappraisal',
    };

    tabs['Extended'] = {
      label: 'Extended',
      eventSourceId: 'Appraisal',
      view: (
        <EmployeeAppraisalTable
          searchValue={searchValue}
          filterValues={filterValues}
          tab="Extended"
          addOnFilter={{
            ...filterValues.filter,
            $or: [
              {
                ifIncrement_asPer_aggrement: 'Extend',
                manually_processed_onhold_extend: {$in: [false, null]},
                status: 'Active',
                new_employee_people_manager: {
                    _id: user && user.employee && user.employee._id,
                  },
              },
              {
                ifIncrement_asPer_aggrement: 'Extend',
                manually_processed_onhold_extend: {$in: [false, null]},
                status: 'Active',
                employee_people_manager: {
                    _id: user && user.employee && user.employee._id,
                  },
              },
            ],
          }}
          user={user}
          {...rest}
        />
      ),
      addOnFilter: {
        ...filter,
        ifIncrement_asPer_aggrement: 'Extend',
        manually_processed_onhold_extend: {$in: [false, null]},
        status: 'Active',
      },
      api: '/employeeteamappraisal',
    };


    return (
      <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'multiAutoComplete',
                label: 'Employee',
                api: '/employeeSuggestions',
                placeholder: 'Select',
                suggestionField: 'name',
                secondarySuggestionField: 'official_email_id',
                valueField: 'name',
                field: 'employee',
                searchFields: ['name', 'official_email_id', 'employee_code'],
              },
            ]}
          />,
        ]}
      />
    );
};

export default EmployeeAppraisalTab;
