import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const SubscriptionPlannedRequest = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false , upgrade} = params;
  const {_id , ...rest} = row || {}
  let {onSubmit} = useFormSubmit({
    uri: '/subscriptionplanneds',
    eventSourceId: 'subscriptionplanned',
  });
  const {user, fetch, post} = useAppStateContext();
  let defaultValues = {};
  if(upgrade){
    defaultValues = {
      ...rest,
      old_subscription : _id
    }
  }
  return (
    <Form
      api={`/subscriptionplanneds/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        ...defaultValues,
        status: 'Requested',
        request_date: getZeroTimeDate(new Date()),
        requested_by: user.employee?._id,
        approver_status: 'Team Head',
      }}
      readOnly={readOnly}
      fields={{
        vendor: {name: 1},
        team: {name: 1},
        approver: {name: 1},
        department: {name: 1},
        next_renewal_date: {name: 1},
        is_user_based: 1,
        account_id: {name: 1},
        currency: {currency: 1},
        // term: 1,
        description: 1,
        amount: 1,
        renewal_date: 1,
        organization: {name: 1},
        product: {name: 1},
        category : {name : 1},
        per_user_amount : 1,
        quantity : 1
      }}
      computations={{
        'set organization,product and currency': {
          compute: async value => {
            const {data: vendorData} = await fetch({
              uri: '/vendors',
              props: {
                filter: {
                  _id: value.vendor._id,
                },
                fields: {
                  organization: {_id: 1, name: 1},
                  product: {_id: 1, name: 1},
                  department: {_id: 1, name: 1},
                  currency_id: {_id: 1, currency: 1},
                },
                only: true,
              },
            });
            return {
              organization: vendorData?.organization,
              product: vendorData?.product,
              currency: vendorData?.currency_id,
              department: vendorData?.department,
            };
          },
          multi: true,
          dependencies: ['vendor'],
        },
      }}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Subcription details',
          fields: [
            {
              label: 'Category',
              type: 'autoComplete',
              field: 'category',
              api: `/subscriptioncategories`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              readOnly : upgrade
            },
            {
              label: 'Account',
              type: 'autoComplete',
              field: 'account_id',
              api: `/accounts`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              filter: {
                _id: {
                  $in: ['53b1255779df44bac9b0ed49', '5b23b5fb7aaad4769951b856'],
                },
              },
            },
            {
              label: 'Vendor',
              type: 'autoComplete',
              field: 'vendor',
              api: `/vendors`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              filter: {
                employee_id: {$exists: false},
              },
            },
            {
              label: 'Is User Based',
              type: 'checkbox',
              field: 'is_user_based',
              size: 2,
            },
            {
              label: 'Per User Amount',
              type: 'number',
              field: 'per_user_amount',
              size: 5,
              visible: ({values}) => values?.is_user_based
            },
            {
              label: 'Quantity',
              type: 'number',
              field: 'quantity',
              visible: ({values}) => values?.is_user_based,
              size: 5,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'amount',
              required: true,
              size: 6,
            },
            {
              api: '/currencies',
              field: 'currency',
              label: 'Currency',
              valueField: 'currency',
              suggestionField: 'currency',
              required: true,
              type: 'autoComplete',
              size: 6,
            },
            {
              label: 'Renewal Date',
              type: 'date',
              field: 'renewal_date',
              size: 4,
            },
            {
              label: 'Next Renewal Date',
              type: 'date',
              field: 'next_renewal_date',
              size: 4,
            },
            {
              label: 'Description',
              type: 'text',
              field: 'description',
              required: true,
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user.employee._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
            {
              label: 'Department',
              field: 'department',
              type: 'autoComplete',
              api: '/activeDepartmentSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddSubscriptionPlanned = props => {
  return (
    <SubscriptionPlannedRequest header="Add Subscription Request" {...props} />
  );
};

export const EditSubscriptionPlanned = props => {
  return (
    <SubscriptionPlannedRequest
      mode="edit"
      header={'Update Subscription Request'}
      {...props}
    />
  );
};

export const RejectSubscriptionRequest = props => {
  const {route: {params} = {}} = props;

  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/subscriptionplanneds`,
    eventSourceId: 'subscriptionplanned',
  });

  return (
    <Form
      api={`/subscriptionplanneds/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_date: getZeroTimeDate(new Date()),
            rejected_by: rejected_by,
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="subscriptionplanned"
      layoutFields={[
        {
          label: 'Reject reason',
          field: 'reject_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpgradeSubscriptionRequest = props => {
  return (
    <SubscriptionPlannedRequest header="Upgrade Subscription Request" {...props} />
  );
};

export const ApproveUpgradeSubscriptionRequest = props => {
  const {route: {params} = {}} = props;
  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/subscriptionplanneds`,
    eventSourceId: 'subscriptionplanned',
  });

  return (
    <Form
      api={`/subscriptionplanneds/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Active',
            renewed_by_finance_on: getZeroTimeDate(new Date()),
            subscription_request_type: null,
          },
        };
      }}
      mode="edit"
      header="Update Subscription"
      submitAction="Save"
      eventSourceId="subscriptionplanned"
      layoutFields={[
        {
          label: 'Requested Renewal Date',
          field: 'new_renewal_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Rquested Next Renewal Date',
          field: 'new_next_renewal_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Previous Renewal Date',
          field: 'renewal_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Previous Next Renewal Date',
          field: 'next_renewal_date',
          type: 'date',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

const SubscriptionCategoryFrom = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/subscriptioncategories',
    eventSourceId: 'SubscriptionCategory',
  });

  return (
    <Form
      api={`/subscriptioncategories/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditSubscriptionCategory = props => {
  return (
    <SubscriptionCategoryFrom
      mode="edit"
      header="Update Subscription Category"
      {...props}
    />
  );
};
export const AddSubscriptionCategory = props => {
  return (
    <SubscriptionCategoryFrom header="Add Subscription Category" {...props} />
  );
};
