import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const UpdateCV = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'workload-billable',
    ...props,
  });
  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            is_cv_added: true,
            cv_updated_on: getZeroTimeDate(new Date()),
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Update CV"
      fields={{
        resume: 1,
      }}
      layoutFields={[
        {
          type: 'file',
          field: 'resume',
          placeholder: 'Resume',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateComment = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/benchcomments',
    eventSourceId: ['EmployeeAssignment', 'allocatedResource'],
    ...props,
  });
  let fetchProps = {};
  if(params?.row?.comment?._id){
    fetchProps = {
      api : `/benchcomments/${params?.row?.comment?._id}`,
      mode : 'edit'
    }
  }
  return (
    <Form
      {...fetchProps}
      // api={`/benchcomments/${params?.row?.comment?._id}`}
      // mode="edit"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            employee : params?.row?.assigned_to?._id
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Update Comment"
      fields={{
        comment: 1,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'comment',
          placeholder: 'Comment',
          header: 'Comment',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateResourceSkill = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: ['workload-billable', 'EmployeeAssignment'],
    ...props,
  });
  const {row} = params;
  return (
    <Form
      api={`/employees/${row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      header="Select Language"
      fields={{
        resource_language: {name: 1},
      }}
      layoutFields={[
        {
          placeholder: 'Language',
          label: 'Language',
          field: 'resource_language',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
        },
      ]}
      {...props}
    />
  );
};
