import React from 'react';
import {TabView} from '../../../components/tab';
import {
  JobOpeningActiveTable,
  JobOpeningClosedTable,
  JobOpeningDraftTable,
  JobOpeningHoldTable,
} from './JobOpeningsTable';

export const JobOpeningTab = props => {
  const {
    route: {params},
  } = props;

  const tabs = {
    active: {
      label: 'Active',
      view: <JobOpeningActiveTable {...props} />,
      // api: `/jobopening/Active`,
      api: `/jobopenings`,
      addOnFilter: {
        status: 'Active',
      },
      eventSourceId: 'JobOpeningActive',
    },
    hold: {
      label: 'On Hold',
      view: <JobOpeningHoldTable {...props} />,
      api: `/jobopening/Hold`,
      eventSourceId: 'JobOpeningHold',
    },
    close: {
      label: 'Closed',
      view: <JobOpeningClosedTable {...props} />,
      api: `/jobopening/Closed`,
      eventSourceId: 'JobOpeningClosed',
    },
    draft: {
      label: 'Draft',
      view: <JobOpeningDraftTable {...props} />,
      api: `/jobopening/Draft`,
      eventSourceId: 'JobOpeningActive',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
