import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {CommentMoreAction} from '../../../app-components/renders/CommentRender';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Chip, Tag} from '../../../components/chip/Chip';
import {Efficiency} from '../../../components/efficiency/Efficiency';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {PROJECT_TYPE_ID} from '../../common/constants/SourceConstants';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useProjectActions} from '../actions/ProjectActions';
import {AvatarGroup} from '../../../components/avatar/Avatar';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import _ from 'lodash';
import {PROJECT_TYPES} from '../constants/ProjectConstant';

export const AllActiveProjects = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    filterValues = {},
    // groupBy,
    user,
  } = props;
  const icons = useTheme('icons');
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    editClientProject,
    activityLogDetail,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };
  const {download} = useAppStateContext();

  const fetchProps = {
    params: {...filterParams},
    // search,
    addOnFilter: {...addOnFilter, ...filterValues},
    searchFields: ['project'],
    api: '/project/performance-report',
  };
  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams}}
      search={searchValue}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('client-project-detail', {project: row});
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Project: 'project',
                      PM: 'owner_id.name',
                      Allocation: 'team',
                      Label: 'new_project_sub_type.project_sub_type',
                    },
                  },
                },
              }),
          },
          {
            title: 'Mark Processed',
            onPress: ({selectedIds}) => {
              navigation.navigate(`nodues-mark-processed`, {
                ...params,
                selectedIds,
              });
            },
          },
        ],
      }}
      columns={[
        showCustomer && {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
        },
        {
          header: 'Project',
          field: 'project',
          type: 'text',
          onPress: projectDetail,
        },

        {
          header: 'PM',
          field: 'owner_id',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Allocation',
          field: 'team',
          type: 'text',
          aggregate: true,
          width: 100,
        },
        // {
        //   header: 'Avg Exp',
        //   field: 'avg_experience',
        //   type: 'number',
        //   aggregate: true,
        // },
        {
          header: 'Module Leads',
          render: ({row, ...rest}) => {
            const value = row['modules'] || [];
            const moduleOwners = value
              .map(_value => {
                return _value.owner_id;
              })
              .filter(_value => _value);
            const uniqModuleOwners = _.uniqBy(
              moduleOwners,
              _value => _value._id,
            );
            const avatars = uniqModuleOwners.map((_value, index) => {
              return <UserAvatar value={_value} key={index} size={24} />;
            });
            if (avatars.length)
              return (
                <AvatarGroup avatarSize={24} avatars={avatars} {...rest} />
              );
            else {
              return (
                <Image source={icons.Owner} style={{height: 24, width: 24}} />
              );
            }
          },
          minWidth: 200,
          onPress: ({row}) => {
            navigation.navigate('project-modules', {
              project: row,
            });
          },
        },
        showLabel && {
          header: 'Label',
          field: 'new_project_sub_type.project_sub_type',
          type: 'colorTag',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editClientProject,
        },
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        // {
        //   title: 'View All Allocations',
        //   onPress: ({row}) => {
        //     navigation.navigate('all-allocated-resource-list', {
        //       projectFilter: {
        //         project_id: row?._id,
        //       },
        //     });
        //   },
        // },

        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },

        {
          title: 'Mark Hold',
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status !== 'h',
        },
        {
          title: 'Update Account Owner',
          onPress: ({row}) => {
            navigation.navigate('update-account-owner', {
              row,
            });
          },
          visible: () => {
            if (
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'sushil.nagvan@daffodilsw.com'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        // {
        //   title: 'Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-employee-request-for-fixed', {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'Plan Projects',
        //   onPress: projectOrderMappings,
        //   visible: ({row}) => row?.projecttype == 'f',
        // },

        // {
        //   title: 'Client Feedbacks',
        //   onPress: ({row}) => {
        //     navigation.navigate('client-feedback-tabs', {
        //       project: row,
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const AllClosedProjects = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    filterValues = {},
    user,
  } = props;
  const icons = useTheme('icons');
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    activityLogDetail,
  } = useProjectActions({
    navigation,
    params,
  });
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams}}
      search={searchValue}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('client-project-detail', {project: row});
      }}
      columns={[
        showCustomer && {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
        },
        {
          header: 'Project',
          field: 'project',
          type: 'text',
          onPress: projectDetail,
        },

        {
          header: 'PM',
          field: 'owner_id',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Allocation',
          field: 'team',
          type: 'number',
          aggregate: true,
        },
        // {
        //   header: 'Avg Exp',
        //   field: 'avg_experience',
        //   type: 'number',
        //   aggregate: true,
        // },
        {
          header: 'Module Leads',
          render: ({row, ...rest}) => {
            const value = row['modules'] || [];
            const moduleOwners = value
              .map(_value => {
                return _value.owner_id;
              })
              .filter(_value => _value);
            const uniqModuleOwners = _.uniqBy(
              moduleOwners,
              _value => _value._id,
            );
            const avatars = uniqModuleOwners.map((_value, index) => {
              return <UserAvatar value={_value} key={index} size={24} />;
            });
            if (avatars.length)
              return (
                <AvatarGroup avatarSize={24} avatars={avatars} {...rest} />
              );
            else {
              return (
                <Image source={icons.Owner} style={{height: 24, width: 24}} />
              );
            }
          },
          minWidth: 200,
          onPress: ({row}) => {
            navigation.navigate('project-modules', {
              project: row,
            });
          },
        },
        showLabel && {
          header: 'Label',
          field: 'new_project_sub_type.project_sub_type',
          type: 'colorTag',
        },
      ]}
      moreActions={() => [
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) =>
            row.project_status == 'h' || row.project_status == 'c',
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },

        // {
        //   title: 'Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-employee-request-for-fixed', {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'Plan Projects',
        //   onPress: projectOrderMappings,
        //   visible: ({row}) => row?.projecttype == 'f',
        // },

        // {
        //   title: 'Client Feedbacks',
        //   onPress: ({row}) => {
        //     navigation.navigate('client-feedback-tabs', {
        //       project: row,
        //     });
        //   },
        // },
      ]}
    />
  );
};
