import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const PromotionComputations = fetch => ({
  'update promotion details': {
    compute: async value => {
      if (value?.employee?._id) {
        const {data: employeeData} = await fetch({
          uri: '/employees',
          props: {
            filter: {
              _id: value?.employee?._id,
            },
            fields: {
              _id: 1,
              designation: {_id: 1, name: 1},
              primary_practice: {_id: 1},
            },
            only: true,
          },
        });
        let practiceOwner = {};
        if (
          employeeData &&
          employeeData._id &&
          employeeData.primary_practice &&
          employeeData?.primary_practice?._id
        ) {
          const {data: practiceData} = await fetch({
            uri: '/practices',
            props: {
              filter: {
                _id: employeeData?.primary_practice?._id,
              },
              fields: {
                _id: 1,
                owner: {_id: 1, name: 1},
              },
              only: true,
            },
          });
          practiceOwner = practiceData?.owner?._id;
        }
        const response = {
          oldDesignation: employeeData?.designation,
          practice_head: practiceOwner,
          promotion_status: 'pending_by_project_reporting_to',
        };
        return response;
      }
    },
    dependencies: ['employee'],
  },
});

export const AddEmployeePromotion = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Promotion',
    uri: '/promotiondetails',
    ...props,
  });
  let defaultValues = {};
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  return (
    <Form
      {...props}
      defaultValues={{promotion_due_date: new Date()}}
      onSubmit={onSubmit}
      computations={{...PromotionComputations(fetch)}}
      header={'Add Promotion Recommendation'}
      submitAction={'Add'}
      layoutFields={[
        {
          placeholder: 'Employee',
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          filter: {
            employee_status: 'Active',
          },
          // size: 6,
        },
        {
          placeholder: 'Eligible Designation',
          label: 'Eligible Designation',
          field: 'designation',
          type: 'autoComplete',
          api: '/designations',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          // size: 6,
        },
        {
          type: 'date',
          field: 'promotion_due_date',
          label: 'Promotion date',
          required: true,
        },
      ]}
    />
  );
};
