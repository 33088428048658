import {Chip} from '@unthinkable/react-chip';
import {Image, Text} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {objectTasksMoreAction} from '../../common/actions/ObjectWiseTasks';
import {
  OPPORTUNITY_TYPE_ID,
  STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {organizationProjectNavigator} from '../../employee/views/ProjectsNavigator';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {customDigitalPlatformOppControllers} from '../controllers/customDigitalPlatformControllers';
import {leadControllers} from '../controllers/leadControllers';
import {staffAugmentationControllers} from '../controllers/staffAugmentationControllers';

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'next_task', type: 'text'},
        {
          field: 'next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const ModeRender = props => {
  const {row} = props;
  return (
    <PrimaryColumnCard
      primaryTitle={row?.process}
      items={[
        {
          value: row?.work_location,
        },
        {
          value: moment(row?.creation_date)?.format('DD MMM, YY'),
        },
      ]}
    />
  );
};

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.employee_count.number', type: 'text'},
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.country.name', type: 'text'},
        {
          field: 'customer_id.country.name',
          type: 'text',
        },
      ]}
    />
  );
};

const LostRender = ({row}) => {
  return (
    <PrimaryColumnCard
      primaryTitle={row?.outcome_description}
      items={[
        {
          value: row?.outcome_rejection_reason,
        },
      ]}
    />
  );
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (
    row?.outcome &&
    (row?.outcome == 'Rejected' ||
      row?.outcome == 'Drop' ||
      row?.outcome == 'Denied by Resource' ||
      row?.outcome == 'Lost')
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Selected') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Shift To Another Project') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 13,
          color: '#30839f',
          overflow: 'hidden',
        }}>
        {row?.outcome}
      </Text>
    );
  } else if (row?.interview_date && row?.interview_time && !row.outcome) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          Interview Scheduled
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

const CUSTOMER = {
  header: 'Customer/Entity',
  render: CustomerRender,
};

const SKILL = {
  width: 250,
  header: 'Skill',
  field: 'skill.name',
  // type: 'chips',
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
};

const EMPLOYEE_COUNT = {
  header: 'Size',
  render: EmployeeCountRender,
  responsive: 'sm',
};
const COUNTRY = {
  header: 'Country',
  render: CountryRender,
  responsive: 'sm',
};

const MODE = {
  header: 'Mode',
  render: ModeRender,
  width: 150,
};

const POSITIONOPEN = {
  header: 'Position Open',
  field: 'no_of_resources',
};

const PENDINGPOSITION = {
  header: 'Pending',
  field: 'pending_count',
  type: 'number',
  width: 100,
};

const OUTCOMEDATE = {
  header: 'Outcome Date',
  field: 'outcome_date',
  type: 'date',
};

const LOSTREASON = {
  header: 'Lost Reason',
  field: 'outcome_description',
};
const DROPEASON = {
  header: 'Drop Reason',
  field: 'outcome_description',
};

const REJECTION_RESON = {
  header: 'Reason',
  render: LostRender,
};

const STATUS = {
  header: 'Status',
  render: RenderStatus,
  width: 180,
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.label',
  colorField: 'campaign_type.color',
  type: 'colorTag',
};

export const StaffAugmentationOpportunityAllTable = props => {
  const params = props.route.params;
  const {markOpportunityClose, assignPreSales, markContract} =
    leadControllers(props);

  const {assignedCount, activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/all"
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },

        {
          field: 'owner',
          header: 'BD Person',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },

        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },

        {
          header: 'Customer',
          field: 'customer_id.name',
        },

        {
          header: 'Skill',
          field: 'skill.name',
        },

        {
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          header: 'Work Location',
          field: 'work_location',
        },
        {
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          header: 'Priority',
          field: 'priority',
        },
        {
          header: 'Status',
          field: 'status',
        },
        {
          header: 'Resource',
          field: 'no_of_resources',
        },
        {
          header: 'Assigned',
          field: 'assigned_count',
          onPress: ({row}) => {
            assignedCount({row});
          },
        },
        {
          header: 'Active',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Pending',
          field: 'pending_count',
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
      moreActions={() => [
        // {title: 'Assign Resource', onPress: assignInterviewResource},
        // {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        // {
        //   title: 'Add Communication',
        //   onPress: opportunityCommunications,
        // },
      ]}
    />
  );
};

export const ActiveStaffAugmentationOpportunityTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {assignedCount, activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  const {addProjectionOnOpportunity} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/active"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        // POSITIONOPEN,
        {
          header: 'Position',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          width: 100,
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
          width: 100,
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
          width: 100,
        },
        PENDINGPOSITION,
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Mark Contract Negotiations', onPress: markContract},
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
      ]}
    />
  );
};

export const ActiveStaffAugmentationOpportunityTableForEmployeeMenu = props => {
  let {
    route: {params},
  } = props;
  navigation;
  const {
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  const {filterValues, applyFilter} = useFilter();
  const {params: filterParams, filter} = filterValues;
  const {period = {}, ...rest} = params;
  params = {...rest, ...filterParams};
  return (
    <Table
      api="/staffAugmentationOpportunities/active"
      renderHeader={() => (
        <TableHeader
          title="Opportunities"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Owner',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  field: 'owner',
                },
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer_id',
                },
                {
                  type: 'autoComplete',
                  label: 'Skill',
                  api: '/skills',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'skill',
                },
                {
                  type: 'autoComplete',
                  label: 'Potential Customer',
                  api: '/potentialcustomers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'potential_customer',
                },
                {
                  type: 'autoComplete',
                  label: 'Mode',
                  options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
                  placeholder: 'Select',
                  field: 'process',
                },
                {
                  type: 'autoComplete',
                  label: 'Engagement Type',
                  options: [
                    'Trial Engagement',
                    'Billable Engagement',
                    'Replacement',
                  ],
                  placeholder: 'Select',
                  field: 'rfr_engagement_type',
                },
                {
                  type: 'date',
                  field: 'next_task_date',
                  asParam: true,
                  label: 'Next Task date',
                },
                {
                  type: 'date',
                  field: 'outcome_date',
                  asParam: true,
                  label: 'Outcome date',
                },
                {
                  type: 'date',
                  field: 'creation_date',
                  asParam: true,
                  label: 'Creation date',
                },
              ]}
            />,
          ]}
        />
      )}
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      addOnFilter={filter}
      sort={{_id: -1}}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        {
          header: 'Opportunity',
          render: CustomerRender,
          width: 250,
        },
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        {
          header: 'Open Position',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row: {...row, formEmployeeMenu: true}});
          },
          type: 'number',
          width: 120,
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
          type: 'number',
          width: 100,
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
          type: 'number',
          width: 100,
        },
        PENDINGPOSITION,
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Mark Contract Negotiations', onPress: markContract},
      ]}
    />
  );
};

export const StaffAugmentationOpportunityTableOverview = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {activeCount, selectedCount} = staffAugmentationControllers(props);

  return (
    <Table
      api="/staffAugmentationOpportunities/all"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        {
          header: 'Skill',
          render: RenderSkills,
        },
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        MODE,
        {
          header: 'Position',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          width: 100,
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
          width: 100,
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
          width: 100,
        },
        PENDINGPOSITION,
        {
          header: 'Status',
          field: 'status',
          width: 80,
          type: 'colorTag',
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Mark Contract Negotiations', onPress: markContract},
      ]}
    />
  );
};

export const WonStaffAugmentationOpportunityTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    assignInterviewResource,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {assignedCount, activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/won"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        {
          header: 'Position Open',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        PENDINGPOSITION,
        OUTCOMEDATE,
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Mark Contract Negotiations', onPress: markContract},
        ...(row.potential_customer?._id
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
      ]}
    />
  );
};

export const LostStaffAugmentationOpportunityTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {activeCount} = staffAugmentationControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/lost"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        {
          header: 'Position Open',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        OUTCOMEDATE,
        LOSTREASON,
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        // {title: 'Assign Resource', onPress: assignInterviewResource},
        // {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        // {
        //   title: 'Add Communication',
        //   onPress: opportunityCommunications,
        // },
      ]}
    />
  );
};

export const DropStaffAugmentationOpportunityTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {activeCount, selectedCount} = staffAugmentationControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/drop"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        {
          header: 'Position Open',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        PENDINGPOSITION,
        OUTCOMEDATE,
        DROPEASON,
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        // {title: 'Assign Resource', onPress: assignInterviewResource},
        // {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        // {
        //   title: 'Add Communication',
        //   onPress: opportunityCommunications,
        // },
      ]}
    />
  );
};

export const PartiallyWonStaffAugmentationOpportunityTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markOpportunityClose,
    assignPreSales,
    assignInterviewResource,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {assignedCount, activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/partiallyWon"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
        },
        COUNTRY,
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        MODE,
        {
          header: 'Position Open',
          field: 'no_of_resources',
          onPress: ({row}) => {
            showInterviewResources({row});
          },
        },
        {
          header: 'Resume',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        PENDINGPOSITION,
        OUTCOMEDATE,
        LOSTREASON,
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Assign Resource', onPress: assignInterviewResource},
        {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        {
          title: 'Add Communication',
          onPress: opportunityCommunications,
        },
      ]}
    />
  );
};

export const OverdueStaffAugmentationOpportunityTable = props => {
  const params = props.route.params;
  const {navigation} = props;
  const {
    markOpportunityClose,
    assignPreSales,
    assignInterviewResource,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {assignedCount, activeCount, selectedCount, opportunityCommunications} =
    staffAugmentationControllers(props);
  return (
    <Table
      api="/staffAugmentationOpportunities/overdue"
      eventSourceId={'CustomPlatformOpportunity'}
      {...props}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },

        {
          field: 'owner',
          header: 'BD Person',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },

        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },

        {
          header: 'Customer',
          field: 'customer_id.name',
        },
        {
          header: 'Skill',
          field: 'skill.name',
        },

        {
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          header: 'Work Location',
          field: 'work_location',
        },
        {
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          header: 'Priority',
          field: 'priority',
        },
        {
          header: 'Status',
          field: 'status',
        },
        {
          header: 'Resource',
          field: 'no_of_resources',
        },
        {
          header: 'Assigned',
          field: 'assigned_count',
          onPress: ({row}) => {
            assignedCount({row});
          },
        },
        {
          header: 'Active',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Pending',
          field: 'pending_count',
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Assign Resource', onPress: assignInterviewResource},
        {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        {
          title: 'Add Communication',
          onPress: opportunityCommunications,
        },
      ]}
    />
  );
};

export const InterviewOpportunityAllTable = props => {
  const params = props.route.params;
  const {product, period} = params;
  console.log(product);
  const {
    markOpportunityClose,
    assignPreSales,
    assignInterviewResource,
    showInterviewResources,
    markContract,
  } = leadControllers(props);
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api={`/allRfrOpportunities`}
      addOnFilter={{
        interview_date: {
          $gte: period.from,
          $lte: period.to,
        },
        product: product?._id,
      }}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      sort={{creation_date: -1}}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },

        {
          type: 'text',
          field: 'owner',
          header: 'BD Person',
        },
        {
          type: 'text',
          field: 'account_owner',
          header: 'Account Owner',
        },

        {
          type: 'userAvatarGroup',
          field: 'rfq_owner',
          header: 'Pre Sales',
        },

        {
          type: 'text',
          header: 'Customer',
          field: 'customer_id.name',
        },

        {
          type: 'text',
          header: 'Skill',
          field: 'skill.name',
        },

        {
          type: 'text',
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          type: 'text',
          header: 'Work Location',
          field: 'work_location',
        },
        {
          type: 'text',
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          type: 'text',
          header: 'Priority',
          field: 'priority',
        },
        {
          type: 'text',
          header: 'Status',
          field: 'status',
        },

        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
      moreActions={() => [
        {title: 'Assign Resource', onPress: assignInterviewResource},
        {title: 'Show Resources', onPress: showInterviewResources},
        {title: 'Mark Contract Negotiations', onPress: markContract},
        {
          title: 'Add Communication',
          onPress: opportunityCommunications,
        },
      ]}
    />
  );
};

export const PotentialCustomerWonStaffAugmentationOpportunityTableApi = {
  api: '/staffAugmentationOpportunities/won',
  addOnFilter: {
    rfr_type: 'PotentailCustomer',
  },
};

export const PotentialCustomerWonStaffAugmentationOpportunityTable = props => {
  const params = props.route.params;
  const {
    markOpportunityClose,
    assignPreSales,
    assignInterviewResource,
    showInterviewResources,
  } = leadControllers(props);
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      {...props}
      {...PotentialCustomerWonStaffAugmentationOpportunityTableApi}
      params={params}
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markOpportunityClose},
          {title: 'Assign Pre Sales', onPress: assignPreSales},
        ],
      }}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          type: 'userAvatarChip',
          field: 'owner',
          header: 'BD Person',
        },
        {
          type: 'userAvatarChip',
          field: 'account_owner',
          header: 'Account Owner',
        },
        {
          type: 'userAvatarGroup',
          field: 'rfq_owner',
          header: 'Pre Sales',
        },

        {
          type: 'text',
          header: 'Customer',
          field: 'customer_id.name',
        },
        {
          type: 'text',
          header: 'Potential Customer',
          field: 'potential_customer.name',
        },

        {
          type: 'text',
          header: 'Skill',
          field: 'skill.name',
        },

        {
          type: 'text',
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          type: 'text',
          header: 'Work Location',
          field: 'work_location',
        },
        {
          type: 'text',
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          type: 'text',
          header: 'Priority',
          field: 'priority',
        },
        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            opportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
      moreActions={() => [
        {title: 'Assign Resource', onPress: assignInterviewResource},
        {title: 'Show Resources', onPress: showInterviewResources},
        {
          title: 'Add Communication',
          onPress: opportunityCommunications,
        },
      ]}
    />
  );
};

export const PracticeWiseOpportunityTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {addOnFilter = {}, fromPractice} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/practiceWiseOpportunities"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 100,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          // width: 200,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          // widht: 250,
        },

        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },

        // {...CUSTOMER, width: 180},
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 200,
        },
        {
          header: 'Model',
          field: 'mode',
          type: 'text',
          // widht: 250,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          // widht: 250,
        },
        // fromPractice && {
        //   header: 'PH feedback',
        //   field: 'ph_feedback',
        //   type: 'text',
        //   widht: 250,
        // },
        // STATUS,
        // REJECTION_RESON,
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
      moreActions={() => [
        {
          title: 'PH Feedback',
          onPress: ({row}) => {
            navigation.navigate('ph-feedback-form', {row: row});
          },
          visible: fromPractice ? true : false,
        },
      ]}
    />
  );
};

export const PracticeWiseClosedOpportunityTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {addOnFilter = {}, fromPractice} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/practiceWiseOpportunities"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={['StaffAugmentation']}
      columns={[
        // {
        //   header: 'RFR Date',
        //   field: 'rfr_assigned_date',
        //   type: 'date',
        //   width: 100,
        // },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },

        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        {...CUSTOMER, width: 180},
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        // fromPractice && {
        //   header: 'PH feedback',
        //   field: 'ph_feedback',
        //   type: 'text',
        //   widht: 250,
        // },
        STATUS,
        REJECTION_RESON,
        {
          header: 'Vid Link',
          field: 'video_link',
          type: 'text',
          width: 200,
        },
        {
          header: 'TAT Days',
          field: 'tat_days',
          type: 'number',
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'PH Feedback',
          onPress: ({row}) => {
            navigation.navigate('ph-feedback-form', {row: row});
          },
          visible: ({row}) => (!row.ph_feedback && fromPractice ? true : false),
        },
        {
          title: 'PH Feedback',
          onPress: ({row}) => {
            navigation.navigate('ph-feedback-form-dual', {row: row});
          },
          visible: ({row}) => (row.ph_feedback && fromPractice ? true : false),
        },
        {
          title: 'AM Feedback',
          onPress: ({row}) => {
            navigation.navigate('am-feedback-non-editable', {row: row});
          },
          visible: ({row}) => (fromPractice ? true : false),
        },
      ]}
    />
  );
};

export const ResourceWiseSuccessOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/resource-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 120,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          // width: 150,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mode',
          field: 'mode',
          type: 'text',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 200,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};

export const ResourceWiseSuccessOpportunityTableClosed = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/resource-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 120,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mode',
          field: 'mode',
          type: 'text',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};

export const MentorWiseSuccessOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/mentor-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 120,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          // width: 150,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mode',
          field: 'mode',
          type: 'text',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 200,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};

export const MentorWiseSuccessClosedOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/mentor-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 120,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mode',
          field: 'mode',
          type: 'text',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        {
          header: 'Vid Link',
          field: 'video_link',
          type: 'text',
          width: 200,
        },
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};

export const DeliveryOwnerWiseSuccessOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/delivery-owner-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 100,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          // width: 200,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mode',
          field: 'mode',
          type: 'text',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 200,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        {
          header: 'Vid Link',
          field: 'video_link',
          type: 'text',
          width: 200,
        },
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};

export const DeliveryOwnerWiseSuccessClosedOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter = {}} = params;
  const {opportunityCommunications} = staffAugmentationControllers(props);
  return (
    <Table
      api="/delivery-owner-wise-success"
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Assigned On',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 120,
        },
        // {
        //   header: 'Created On',
        //   field: 'creation_date',
        //   type: 'date',
        //   width: 120,
        // },
        {
          header: 'Candidate',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          // widht: 250,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            console.log('row', row.resource);
            return (
              <Text style={{...rowText}}>
                {row?.resource?.experience && `${row?.resource?.experience} yr`}
              </Text>
            );
          },
        },
        // {...CUSTOMER, width: 180},
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          widht: 250,
        },
        STATUS,
        REJECTION_RESON,
        // {
        //   header: 'TAT Days',
        //   field: 'tat_days',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
    />
  );
};
