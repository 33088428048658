import React from 'react';
import {Table} from '../../../components/table/Table';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TabView} from '../../../components/tab';
import {Image, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {useTheme} from '@unthinkable/react-theme';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {useAssetActions} from '../actions/AssetActions';
import {AllDeletedITAssetTable, DeletedITAssetTab} from './NewITAssetTable';
const JsBarcode = require('jsbarcode');
const {createCanvas} = require('canvas');

const INACTIVESINCE = {
  header: 'Inactive Since',
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={
          (row?.depreciation_date || row?.scrap_date) &&
          moment(row?.depreciation_date || row?.scrap_date).format('DD MMM YY')
        }
        // items={[
        //   {
        //     value: `Purchased: ${
        //       row?.date && moment(row?.date).format('DD MMM YY')
        //     }`,
        //   },
        // ]}
      />
    );
  },
};

const LOCATION = {
  header: 'Location',
  field: 'location.name',
  type: 'colorTag',
  colorField: randomColor,
};

const ASSETANDCODE = {
  header: 'Asset code',
  align: 'center',
  width: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.code}
        // items={[{value: row?.asset_type}]}
      />
    );
  },
};

const BRAND = {
  header: 'Brand',
  field: 'brand',
  type: 'text',
};

const MODEL = {
  header: 'Model',
  field: 'model',
};

const SPECIFICATION = {
  header: 'Specification',
  field: 'specification',
};

const renderStatus = ({backgroundColor, textcolor, value, rowText}) => {
  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        padding: (2, 4, 2, 4),
        alignItems: 'center',
        borderRadius: 4,
      }}>
      <Text
        style={{
          ...rowText,
          color: textcolor,
        }}>
        {value}
      </Text>
    </View>
  );
};

const STATUS = {
  header: 'Status',
  field: 'status',
  render: ({row, styles: {rowText = {}}}) => {
    const color = useTheme('colors');
    if (row?.status === 'Scrap') {
      return renderStatus({
        backgroundColor: color.ACCENT3_LOW,
        textcolor: color.ACCENT3_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Donated') {
      return renderStatus({
        backgroundColor: color.ACCENT2_LOW,
        textcolor: color.ACCENT2_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Lost') {
      return renderStatus({
        backgroundColor: color.ERROR_LOW,
        textcolor: color.ERROR_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Returned') {
      return renderStatus({
        backgroundColor: color.ACCENT4_LOW,
        textcolor: color.ACCENT4_HIGH,
        value: row?.status,
        rowText,
      });
    }
  },
};

const InActiveAllITAsset = props => {
  const {route: {params = {}} = {}, searchValue, addOnFilter = {}} = props;
  return (
    <Table
      eventSourceId={['InactiveITAsset']}
      addOnFilter={{...addOnFilter}}
      search={searchValue}
      searchFields="code"
      api={`/inactive-it-assets`}
      columns={[
        INACTIVESINCE,
        LOCATION,
        ASSETANDCODE,
        BRAND,
        MODEL,
        SPECIFICATION,
        STATUS,
      ]}
    />
  );
};

const InActiveScrapITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const {download} = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/inactive-it-assets`,
    sort: {scrap_date: -1},
  };
  return (
    <Table
      eventSourceId={['InactiveITAsset']}
      {...fetchProps}
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        INACTIVESINCE,
        ASSETANDCODE,
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          // align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Bar Code',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        LOCATION,

        // STATUS,
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const InActiveDonatedITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const {download} = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/inactive-it-assets`,
    sort: {donated_date: -1},
  };
  return (
    <Table
      eventSourceId={['InactiveITAsset']}
      {...fetchProps}
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // INACTIVESINCE,
        {
          header: 'Inactive since',
          field: 'donated_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        ASSETANDCODE,
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          // align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Bar Code',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        LOCATION,
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const InActiveLostITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const {download} = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/inactive-it-assets`,
    sort: {depreciation_date: -1},
  };
  return (
    <Table
      eventSourceId={['InactiveITAsset']}
      {...fetchProps}
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        INACTIVESINCE,
        ASSETANDCODE,
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          // align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Bar Code',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        LOCATION,
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const InActiveReturnedITAsset = props => {
  const {route: {params = {}} = {}, searchValue, addOnFilter = {}} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['InactiveITAsset'],
  });
  const {user} = useAppStateContext();
  const {employee = {}} = user;
  return (
    <Table
      eventSourceId={['InactiveITAsset']}
      addOnFilter={{...addOnFilter}}
      search={searchValue}
      searchFields="code"
      api={`/inactive-it-assets`}
      columns={[
        INACTIVESINCE,
        LOCATION,
        ASSETANDCODE,
        BRAND,
        MODEL,
        SPECIFICATION,
        STATUS,
      ]}
      moreActions={[
        {
          title: 'Mark Received',
          confirm: {
            title: 'Mark Received',
            message: 'Are you sure you want to mark received this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/received/${row._id}`,
              props: {
                status: 'Unassigned',
                asset_status: 'Active',
                assigned_from_date: row?.assigned_from_date,
                assigned_to_date: row?.assigned_to_date,
                assigned_to: null,
                asset_aggrement_document: row?.asset_aggrement_document,
                assigned_by: employee?.id,
                recieved_by: row?.assigned_to?._id,
                asset: row?._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const InActiveITAssetTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter = {}} = filterValues;
  const tabs = {
    scrap: {
      label: 'Scrap',
      view: (
        <InActiveScrapITAsset
          tab={'scrap'}
          addOnFilter={{...filter, status: 'Scrap'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'InactiveITAsset',
      api: '/inactive-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Scrap',
      },
      limit: 1000,
    },

    donated: {
      label: 'Donated',
      view: (
        <InActiveDonatedITAsset
          tab={'donated'}
          addOnFilter={{...filter, status: 'Donated'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'InactiveITAsset',
      api: '/inactive-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Donated',
      },
      limit: 1000,
    },

    lost: {
      label: 'Lost',
      view: (
        <InActiveLostITAsset
          tab={'lost'}
          addOnFilter={{...filter, status: 'Lost'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'InactiveITAsset',
      api: '/inactive-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Lost',
      },
      limit: 1000,
    },
    all: {
      label: 'All',
      view: (
        <InActiveLostITAsset
          tab={'all'}
          addOnFilter={{...filter, status: {$in: ['Lost', 'Scrap', 'Donated']}}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'InactiveITAsset',
      api: '/inactive-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: {$in: ['Lost', 'Scrap', 'Donated']},
        // status
        // status: 'Lost',
      },
      limit: 1000,
    },
    // archieved: {
    //   label: 'Archived',
    //   view: (
    //     <AllDeletedITAssetTable
    //       tab={'deleted'}
    //       addOnFilter={{
    //         ...filter,
    //         // asset_type: 'Ready To Use',
    //         status: 'Deleted',
    //       }}
    //       searchValue={searchValue}
    //       {...props}
    //     />
    //   ),
    //   eventSourceId: 'ActiveITAsset',
    //   api: '/all-it-assets',
    //   search: searchValue,
    //   searchFields: ['code', 'internal_code', 'serial_no'],
    //   addOnFilter: {
    //     ...filter,
    //     // asset_type: 'Ready To Use',
    //     status: 'Deleted',
    //   },
    //   limit: 1000,
    // },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Asset Type',
              options: ['Ready To Use', 'Asset Inventory'],
              field: 'asset_type',
            },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Asset Aggrement',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'asset_aggrement_signed',
            },
            {
              type: 'autoComplete',
              label: 'Asset Category',
              api: '/assetMasterCategories',
              searchField: 'name',
              suggestionField: 'name',
              field: 'type',
            },
          ]}
        />,
      ]}
    />
  );
};

export const EndofLifeTable = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const {
    download,
    user: {employee = {}},
  } = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {scrap_date: -1},
  };
  return (
    <Table
      eventSourceId={['ActiveITAsset']}
      {...fetchProps}
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Inactive',
            onPress: ({selectedIds}) =>
              navigation.navigate('mark-end-of-life-asset-inactive', {
                ...params,
                selectedIds,
              }),
            visible: ({}) => {
              if (
                employee?._id == '5db673b0fdfc8569be32aa76' ||
                employee?._id == '53a438926dc89c02007cd37d' ||
                employee?._id == '5a24d508c5eded3207e2873c' ||
                employee?._id == '540458a0dd329b0c9672efc1'
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      'End of life category': 'end_of_life_category',
                      'End of life date': 'end_of_life_date',
                      'Fault type': 'type_of_fault',
                      'Fault Desc': 'fault_type_detail',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // INACTIVESINCE,
        {
          type: 'date',
          field: 'end_of_life_date',
          header: 'End of life since',
          width: 150,
          formatOptions: {
            format: 'DD MMM,YY',
          },
        },
        ASSETANDCODE,
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          // align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Bar Code',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        LOCATION,

        // STATUS,
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};
