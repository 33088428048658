import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const WebsiteForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/websites',
        eventSourceId: 'website',
    });

    return (
        <Form
            api={`/websites/${row?._id}`}
            fields={{
                domain: 1,
                sandbox_domain: 1,
                alternate_domains: 1,
                no_index: 1,
            }}
            onSubmit={onSubmit}
            layoutFields={[
                {
                    label: 'Domain',
                    type: 'text',
                    field: 'domain',
                    required: true,
                },
                {
                    label: 'Sandbox Domain',
                    type: 'text',
                    field: 'sandbox_domain',
                    required: true,
                },
                {
                    label: 'Alternate Domains',
                    type: 'multiText',
                    field: 'alternate_domains',
                },
                {
                    label: 'No Index',
                    type: 'checkbox',
                    field: 'no_index',
                },
            ]}
            {...props}
        />
    );
};

export const AddWebsiteForm = props => {
    return <WebsiteForm header="Add Website" {...props} />;
};

export const UpdateWebsiteForm = props => {
    return <WebsiteForm mode="edit" header="Update Website" {...props} />;
}; 