import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import moment from 'moment';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

export const TrainingGapReportTable = props => {
  return (
    <Table
      eventSourceId={['EmployeeTrainings']}
      searchFields={['name', 'official_email_id', 'employee_code']}
      api="/trainingGapReport"
      limit={50000}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => <UserAvatarWithNameAndEmail {...row} />,
          minWidth: 300,
        },
        {
          header: 'Department',
          field: 'department.name',
          type: 'text',
        },
        {
          header: 'Experience',
          field: 'experience',
          suffix: 'yrs',
          type: 'text',
        },
        {
          header: 'Reporting To',
          field: 'reporting_to',
          type: 'userAvatarGroup',
        },
        {
          header: 'Last Training',
          field: 'last_training',
          render: ({row}) => {
            const {last_training} = row;
            return (
              <PrimaryColumnCard
                primaryTitle={
                  last_training?.training_batch?.name ||
                  last_training?.training_batch?.training_program?.name +
                    ' Batch ' +
                    last_training?.training_batch?.batch_number
                }
                items={[
                  {
                    value: moment(last_training?.from_date)?.format(
                      'DD MMM YY',
                    ),
                  },
                  {
                    value: moment(last_training?.to_date)?.format('DD MMM YY'),
                  },
                  {
                    value: last_training.deployability,
                  },
                ]}
              />
            );
          },
        },
      ]}
      {...props}
    />
  );
};

export const TrainingGapReport = props => {
  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;
  const tabs = {
    employees: {
      label: 'Employees',
      view: (
        <TrainingGapReportTable
          {...props}
          search={searchValue}
          addOnFilter={filterValues.filter}
        />
      ),
      api: '/trainingGapReport',
      addOnFilter: filterValues.filter,
      search: searchValue,
      searchFields: ['name', 'official_email_id', 'employee_code'],
      actions: [
        <SearchFilter {...filterProps} />,
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Department',
              api: '/activeDepartmentSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'department',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
          ]}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} {...props} />;
};
