import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Chip} from '@unthinkable/react-chip';
import {useTheme} from '@unthinkable/react-theme';
import {Image, Text} from '@unthinkable/react-core-components';
import moment from 'moment';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
        {
          field: 'creation_date',
          type: 'date',
        },
      ]}
    />
  );
};

const RenderSkills = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const {INFORMATION_MEDIUM, INFORMATION_HIGH} = useTheme('colors');
  const skillName = row?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return (
    <Chip
      color={INFORMATION_MEDIUM}
      textColor={INFORMATION_HIGH}
      value={skillName}
      borderRadius={6}
    />
  );
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (
    row?.outcome &&
    (row?.outcome == 'Rejected' ||
      row?.outcome == 'Drop' ||
      row?.outcome == 'Denied by Resource' ||
      row?.outcome == 'Lost')
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Selected') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Shift To Another Project') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 13,
          color: '#30839f',
          overflow: 'hidden',
        }}>
        {row?.outcome}
      </Text>
    );
  } else if (row?.interview_date && row?.interview_time && !row.outcome) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          Interview Scheduled
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderTAT = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const {tat_minutes = 0, tat_days = 0} = row;
  let days = 0;
  let hours = 0;
  let totaldays = tat_days + 'd';
  if (tat_minutes > 0) {
    const hoursInADay = 9;
    const totalMinutesInADay = hoursInADay * 60;
    days = Math.floor(tat_minutes / totalMinutesInADay);
    hours = Math.floor((tat_minutes % totalMinutesInADay) / 60);
  }
  if (days >= 0) {
    totaldays = days + 'd ';
  }
  if (hours > 0) {
    totaldays = totaldays + hours + 'h';
  }
  return (
    <>
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          overflow: 'hidden',
        }}>
        {totaldays}
      </Text>
    </>
  );
};

const LostRender = ({row}) => {
  return (
    <PrimaryColumnCard
      primaryTitle={row?.outcome_description}
      items={[
        {
          value: row?.outcome_rejection_reason,
        },
      ]}
    />
  );
};

const STATUS = {
  header: 'Status',
  render: RenderStatus,
  width: 120,
};

const CUSTOMER = {
  header: 'Opportunity',
  render: CustomerRender,
  width: 200,
  // responsive: 'sm',
};

const REJECTION_RESON = {
  header: 'Rejection Reason',
  render: LostRender,
  width: 180,
};

export const RfrTatList = props => {
  let {params = {}, navigation, filterParams, searchValue, filter} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/tat-fullfilment'}
      eventSourceId={'TATReport'}
      search={searchValue}
      addOnFilter={filter}
      onRowPress={({row}) => {
        navigation.navigate(`rfr-tat-details`, {
          rfrId: row._id,
          row,
        });
      }}
      columns={[
        {...CUSTOMER},

        {
          header: 'Resume added',
          field: 'rfr_assigned_date',
          type: 'date',
          width: 180,
        },

        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.resource?.name}
                color={row?.resource?.color}
                official_email_id={row?.resource?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Mentor',
          type: 'userAvatar',
          field: 'mentor',
          width: 80,
        },
        {
          header: 'New/rejection',
          type: 'colorTag',
          field: 'profile_category',
          width: 180,
        },

        {
          header: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          width: 180,
        },
        {
          header: 'Assigned against',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.assigned_against?.name}
                color={row?.assigned_against?.color}
                official_email_id={row?.assigned_against?.official_email_id}
              />
            );
          },
          width: 250,
        },
        STATUS,
        REJECTION_RESON,
        {
          header: 'TAT Days',
          render: RenderTAT,
          width: 80,
          align: 'right',
        },
      ]}
    />
  );
};
