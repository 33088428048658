import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {InlineForm} from '../../../components/form/Form';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {PROJECT_FEATURE_TYPE_ID} from '../../common/constants/SourceConstants';
import {EstRender} from '../../pmt/cellrenders/EstRender';
import StatusIconRender from '../../pmt/cellrenders/StatusIconRender';
import TaskSourceRender from '../../pmt/cellrenders/TaskSourceRender';
import Images from '../../../images';
import {AddAction, AddFilledAction} from '../../../components/action/AddAction';
import {TaskWithActionRenderer} from '../../pmt/cellrenders/TaskWithActionRenderer';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {ReviewComponent} from './TasksTable';
import {taskFilters, useTaskActions} from './TaskActions';
import {Button} from '../../../components/button/Button';

const {Owner} = Images;

const FeatureTaskInlineForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['Task'],
    uri: '/projecttasks',
    ...props,
  });

  const {project, module, feature} = params || {};

  const defaultValues = {
    project_id: project?._id,
    module_id: module?._id,
  };

  let parent_model;

  if (feature?._id) {
    defaultValues.feature_id = feature?._id;
    defaultValues.source_id = feature?._id;
    defaultValues.source = PROJECT_FEATURE_TYPE_ID;
    parent_model = PROJECT_FEATURE_TYPE_ID;
  }

  const paramFilter = {};

  if (project?._id) {
    paramFilter.project_id = project?._id;
  }

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          width: 250,
          label: 'Task Type',
          field: 'master_task_assignment',
          type: 'autoComplete',
          api: `/projects/${project?._id}/mastertaskassignments`,
          params: {
            module_id: module?._id,
            parent_model,
          },
          suggestionField: 'task',
          valueField: 'task',
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('reviewer', value?.reviewer);
            setFieldValue('review_not_required', value?.review_not_required);
            setFieldValue('team', value?.team);
            setFieldValue(
              'assigned_to',
              value?.team?.length == 1 ? value?.team[0] : void 0,
            );
          },
        },
        {
          label: 'Task',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          label: 'Assignee',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          params: {project_id: project?._id},
          width: 200,
        },
      ]}
    />
  );
};

export const taskWithSource = ({params}) => {
  const {displaySource = true} = params;
  return {
    header: 'Task',
    render: props => {
      return (
        <View style={{gap: 4}}>
          <TaskWithActionRenderer
            {...props}
            numberOfLines={2}
            actionField="master_task_assignment.task"
            taskField="name"
            colorField="master_task_assignment.color"
          />
          {displaySource ? (
            <TaskSourceRender
              {...props}
              sourceField="parent_model"
              sourceIdField="parent_model_id"
            />
          ) : null}
        </View>
      );
    },
  };
};

const assignTo = ({navigation}) => ({
  width: 50,
  header: {icon: Owner},
  field: 'assigned_to',
  type: 'userAvatar',
  onPress: ({row}) => {
    navigation.navigate('update-assignee', {
      row,
      task_id: row?._id,
      project: row?.project_id,
    });
  },
  fallbackRender: ({row}) => {
    const {LockGrey} = useTheme('icons');
    return (
      <AddAction
        icon={LockGrey}
        onPress={() => {
          navigation.navigate('update-assignee', {
            row,
            task_id: row?._id,
            project: row?.project_id,
          });
        }}
      />
    );
  },
});

const estHrs = ({navigation}) => ({
  render: EstRender,
  align: 'center',
  width: 70,
  header: 'Est.',
  columnField: 'est_hours',
  onPress: ({row}) => {
    navigation.navigate('update-est-hours', {
      row,
    });
  },
  onAdd: props => {
    const {row} = props;
    navigation.navigate('update-est-hours', {
      row,
    });
  },
});

const dueDate = ({navigation}) => ({
  align: 'center',
  width: 80,
  header: 'Due Date',
  field: 'due_date',
  type: 'date',
  onPress: ({row}) => {
    row.status === 'completed'
      ? void 0
      : navigation.navigate('due-date-form', {
          row,
          api: '/projecttasks',
        });
  },
  fallbackRender: ({row}) => {
    if (row.status === 'completed') {
      return null;
    }
    return <AddFilledAction />;
  },
});

const status = ({invoke}) => ({
  render: StatusIconRender,
  iconField: 'status',
  onIconPress: ({row}) => {
    invoke({
      uri: `/projecttasks/${row?._id}`,
      props: {
        status: 'completed',
      },
    });
  },
  disabled: ({row}) => !row?.assigned_to,
  width: 50,
});

export const performance = ({params}) => ({
  type: 'rating',
  header: 'Rating',
  width: 120,
  align: 'center',
  render: ReviewComponent,
  readOnly: true,
  hideReview: ({row}) =>
    !(row.review_pending === false || row.review_pending === true),
  visible:
    params?.feature?.status === 'released' ||
    params?.feature?.status === 'active',
});

export const FeatureTasksTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project, feature, taskQualification} = params;
  const {
    activityLogs,
    updateCompletedOnDate,
    markIncomplete,
    markQualified,
    markDisqualified,
    deleteTask,
    comments,
    updateTaskType,
    changeTaskComplexity,
    markFavorite,
  } = useTaskActions({navigation, params});
  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});

  const {filterValues, applyFilter} = useFilter();

  return (
    <Table
      eventSourceId={['Feature', 'Task']}
      api={`/projecttasks/all`}
      renderHeader={
        !(feature?.status === 'archived' || feature?.status === 'released')
          ? () => {
              return FeatureTaskInlineForm(props);
            }
          : taskQualification
          ? () => (
              <TableHeader
                title={'Tasks'}
                actions={[
                  <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[taskFilters.disqualified]}
                  />,
                ]}
              />
            )
          : void 0
      }
      params={{
        feature_id: feature?._id,
        ...filterValues.filter,
      }}
      columns={[
        status({invoke}),
        taskWithSource({params, project, navigation}),
        estHrs({navigation}),
        assignTo({params, navigation, project}),
        dueDate({navigation}),
        performance({params, navigation}),
      ]}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          row: {_id: row._id, status: row?.status},
          readOnly: row?.status === 'completed',
        });
      }}
      moreActions={[
        updateCompletedOnDate,
        feature?.status !== 'released' && markIncomplete,
        updateTaskType,
        changeTaskComplexity, // change-task-complexity
        taskQualification && markDisqualified,
        taskQualification && markQualified,
        comments,
        markFavorite,
        activityLogs,
        deleteTask,
      ]}
    />
  );
};

const FeatureAsssetTaskInlineForm = props => {
  const {
    route: {params},
  } = props;
  const {project, module, source, source_id, feature_id, feature, afterSubmit} =
    params || {};

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['Task'],
    uri: '/projecttasks',
    ...props,
  });

  const featureId = feature?._id || feature_id;

  const defaultValues = {
    status: 'backlog',
    project_id: project?._id,
    source,
    source_id,
    module_id: module?._id,
    feature_id: featureId,
  };

  if (params?.row?.bug) {
    defaultValues.bug_id = params?.row?._id;
  }

  return (
    <InlineForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      afterSubmit={afterSubmit}
      submitAction="Save"
      layoutFields={[
        {
          width: 250,
          label: 'Task Type',
          field: 'master_task_assignment',
          type: 'autoComplete',
          api: `/projects/${project?._id}/mastertaskassignments`,
          params: {
            module_id: module?._id,
            parent_model: source,
          },
          suggestionField: 'task',
          valueField: 'task',
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('reviewer', value?.reviewer);
            setFieldValue('review_not_required', value?.review_not_required);
            setFieldValue('team', value?.team);
            setFieldValue(
              'assigned_to',
              value?.team?.length == 1 ? value?.team[0] : void 0,
            );
          },
        },
        {
          label: 'Task',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          width: 250,
          label: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          params: {project_id: project?._id},
        },
      ]}
    />
  );
};

export const FeatureAssetTasksTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {
    project,
    source,
    source_id,
    feature_id,
    feature,
    isReleased,
    title,
    uploadDesign,
    fromFeatureDetail,
    bug,
  } = params || {};

  const invoke = useInvoke({method: 'put', eventSourceId: 'Task'});

  const {markIncomplete, updateTaskType, updateCompletedOnDate, deleteTask} =
    useTaskActions({
      navigation,
      params,
    });

  const featureId = feature?._id || feature_id;

  const extraParams = {};
  if (featureId) {
    extraParams.feature_id = featureId;
  }
  if (fromFeatureDetail) {
    extraParams.parent_model_id = source_id;
  } else if (bug) {
    extraParams.bug_id = bug?._id;
  }

  return (
    <>
      <TableHeader
        title={'Tasks'}
        secondaryTitle={title || feature?.feature}
        actions={[
          uploadDesign ? (
            <Button
              text={'Upload Design'}
              onPress={() => {
                navigation.navigate(`addProjectDesign`, {...params});
              }}
            />
          ) : null,
        ]}
      />
      {isReleased ? null : <FeatureAsssetTaskInlineForm {...props} />}
      <Table
        eventSourceId={'Task'}
        onRowPress={({row}) => {
          navigation.navigate('task-edit', {
            ...params,
            parent_model: source,
            row: {_id: row._id, status: row?.status},
            readOnly: row?.status === 'completed',
          });
        }}
        api={`/projecttasks/all`}
        params={{
          project_id: project?._id,
          ...extraParams,
        }}
        columns={[
          status({invoke}),
          {
            header: 'Task',
            render: TaskWithActionRenderer,
            taskField: 'name',
            actionField: 'master_task_assignment.task',
            colorField: 'master_task_assignment.color',
          },
          estHrs({navigation}),
          assignTo({params, navigation, project}),
          dueDate({navigation}),
        ]}
        moreActions={[
          updateTaskType,
          updateCompletedOnDate,
          deleteTask,
          feature?.status !== 'released' && markIncomplete,
        ]}
      />
    </>
  );
};
