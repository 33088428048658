import React from 'react';
import {TabView} from '../../../components/tab';
import {useAppStateContext} from '../../../providers/AppState';
import {MyReferralJobOpeningTable} from './JobOpeningsTable';
import {MyRefferedCandidates} from './ReferralTable';

export const MyReferralTabs = props => {
  const {
    user: {employee: {_id: employeeId} = {}},
  } = useAppStateContext();
  const tabs = {
    jobOpenings: {
      label: 'Open Positions',
      view: (
        <MyReferralJobOpeningTable
          {...props}
          addOnFilter={{
            not_visible_in_referrals: {
              $in: [null, false],
            },
          }}
        />
      ),
      api: '/jobOpenings',
      filter: {
        status: 'Active',
        not_visible_in_referrals: {
          $in: [null, false],
        },
      },
      eventSourceId: 'JobOpeningActive',
    },
    myReferral: {
      label: 'My Referrals',
      view: (
        <MyRefferedCandidates
          {...props}
          addOnFilter={{
            referred_by: {$in: [employeeId]},
          }}
          showStage
        />
      ),
      api: '/referralCandidates',
      filter: {
        referred_by: {$in: [employeeId]},
      },
      eventSourceId: 'ReferralCandidate',
    },
  };
  return <TabView tabs={tabs} isLinkTab={false} />;
};
