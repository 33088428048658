import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddGenericInitiativesForm = props => {
  const {route = {}} = props;
  const {params} = route;
  const {defaultValues, row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/genericInitiatives',
    eventSourceId: 'reloadGenericInitiative',
    ...props,
  });
  return (
    <Form
      api={`/genericInitiatives/${row?._id}`}
      fields={{
        name: 1,
        department: {name: 1},
        leader: {
          name: 1,
        },
        resources: {
          name: 1,
        },
        date: 1,
        status: 1,
        open_for: 1,
        is_open: 1,
        open_for_departments: {
          name: 1,
        },
        resource_interests: {
          resource: {
            name: 1,
          },
          interest: 1,
          date: 1,
        },
        kpi: {
          kpi: 1,
        },
        task_list: {name: 1},
      }}
      onSubmit={onSubmit}
      header={'Add Generic Initiative'}
      defaultValues={{
        department: params.department?._id,
        product: params.product?._id,
        status: 'Active',
        ...defaultValues,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'KPI',
          field: 'kpi',
          type: 'autoComplete',
          api: '/kpis',
          searchField: 'kpi',
          suggestionField: 'kpi',
        },
        {
          placeholder: 'Creator',
          field: 'leader',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 6,
        },
        {
          placeholder: 'Target',
          field: 'date',
          type: 'date',
          width: 220,
        },
        {
          label: 'Invite Volunteers',
          type: 'radioGroup',
          options: ['My Department', 'Particular Departments', 'Organization'],
          field: 'open_for',
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue(
              'is_open',
              value == 'Particular Departments' || value == 'Organization',
            );
          },
        },
        {
          type: 'multiAutoComplete',
          field: 'open_for_departments',
          label: 'Open For Departments',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          searchField: 'name',
          visible: ({values}) => values?.open_for == 'Particular Departments',
        },
        {
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
        },
        {
          field: 'task_list',
          label: 'Task List',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  type: 'text',
                  field: 'name',
                  label: 'Task',
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const EditGenericInitiativeForm = props => {
  return (
    <AddGenericInitiativesForm
      {...props}
      header={'Edit Generic Initiative'}
      mode="edit"
    />
  );
};
