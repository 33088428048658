import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  AttendanceChangeRequestTable,
  EmployeeDailyAttendanceForShiftAllowanceTable,
  // BulkForm16Table,
  EmployeeDailyAttendanceTable,
  EmployeeLeaveRegisterTable,
  EmployeeLOPTable,
  EmployeeMonthlyAttendanceTable,
  EWDRequestTable,
  MonthWiseEmployeeLOPTable,
  MonthWiseEmployeeTeamLOPTable,
  TeamEmployeeDailyAttendanceTable,
  TeamEmployeeLeaveRegisterTable,
} from './EmployeeAttendanceTable';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {LeaveRequestTable} from './RequestTable';
import {TourRequestTable, WFHRequestTable} from './WfhRequestTable';
import {
  SelfApprovedTeamTimesheetRequests,
  SelfPendingTimesheetRequests,
  TimesheetsRequestTable,
} from './TimesheetTable';
import {SelfOfficeTimeTrackingReport} from '../../leadGeneration/views/EmployeeOfficeTrackingReport';
import {getCurrentMonth} from '@unthinkable/react-filters';

export const DailyAttendanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    myAttendance: {
      label: 'Attendance',
      view: (
        <EmployeeDailyAttendanceTable
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'myAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
          ]}
        />,
      ],
    },
    // lop: {
    //   label: 'LOP',
    //   view: (
    //     <MonthWiseEmployeeLOPTable filter={filter} params={params} {...props} />
    //   ),
    //   eventSourceId: 'myAttendance',
    //   actions: [
    //     <GroupFilter
    //       filterValues={filterValues}
    //       applyFilter={applyFilter}
    //       filters={[
    //         {
    //           type: 'autoComplete',
    //           label: 'First Half',
    //           api: '/DailyAttendanceTypes',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'first_attendance_type_id',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Second Half',
    //           api: '/DailyAttendanceTypes',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'second_attendance_type_id',
    //         },
    //       ]}
    //     />,
    //   ],
    // },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
export const TeamEmployeeAttendanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    myAttendance: {
      label: 'Attendance',
      view: (
        <TeamEmployeeDailyAttendanceTable
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'myAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
          ]}
        />,
      ],
    },
    lop: {
      label: 'LOP',
      view: (
        <MonthWiseEmployeeTeamLOPTable
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'myAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
          ]}
        />,
      ],
    },
    leaveRegister: {
      label: 'Leave register',
      view: <TeamEmployeeLeaveRegisterTable {...props} />,
      eventSourceId: 'leaveRegister',
      // actions: [
      //   <AddButton
      //     title="Leave request"
      //     view="add-leave-request"
      //     params={props?.route?.params}
      //   />,
      // ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const DailyAttendanceForShiftAllowanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, extraAddOnFilter = {}, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    myAttendance: {
      label: 'Attendance',
      view: (
        <EmployeeDailyAttendanceForShiftAllowanceTable
          filter={{...filter, ...extraAddOnFilter}}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'myAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
          ]}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const MyAttendanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    timesheet: {
      label: 'Timesheet',
      view: (
        <TimesheetsRequestTable filter={filter} params={params} {...props} />
      ),
      eventSourceId: 'timesheet',
      actions: [
        <AddButton
          title="Request"
          view="add-timesheet-request"
          params={props?.route?.params}
        />,
      ],
    },
    monthlyAttendance: {
      label: 'Monthly Attendance',
      // view: <EmployeeMonthlyAttendanceTable {...props} />,
      view: <EmployeeLOPTable filter={filter} params={params} {...props} />,
      eventSourceId: 'monthlyAttendance',
    },
    leaveRegister: {
      label: 'Leave Register',
      view: <EmployeeLeaveRegisterTable {...props} />,
      eventSourceId: 'leaveRegister',
      actions: [
        <AddButton
          title="Leave request"
          view="add-leave-request"
          params={props?.route?.params}
        />,
      ],
    },
    // LOP: {
    //   label: 'LOP',
    //   view: <EmployeeLOPTable filter={filter} params={params} {...props} />,
    //   eventSourceId: 'LOP',
    // },
    // OfficeTrackingReport: {
    //   label: 'Office Tracking Report',
    //   view: <SelfOfficeTimeTrackingReport {...props} />,
    // },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="My attendance" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const OfficeTrackingReportTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const tabs = {
    officetime_tracking_report: {
      label: 'Office Tracking Report',
      view: (
        <SelfOfficeTimeTrackingReport filterValues={filterValues} {...props} />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      parmas={params}
      actions={[
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

export const MyLeaveRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <LeaveRequestTable
          filter={{...filter, status: {$exists: false}}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'leaveManagement',
      actions: [
        <AddButton
          title="New Leave Request"
          view="add-leave-request"
          params={props?.route?.params}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: (
        <LeaveRequestTable
          filter={{...filter, status: {$exists: true}}}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'leaveManagement',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Leave Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyAttendanceRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <AttendanceChangeRequestTable
          filter={{...filter, is_approved: false, status: 'unapproved'}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'leaveManagement',
      // actions: [
      //   <AddButton
      //     title="New Leave Request"
      //     view="add-leave-request"
      //     params={props?.route?.params}
      //   />,
      // ],
    },
    closed: {
      label: 'Closed',
      view: (
        <AttendanceChangeRequestTable
          filter={{
            ...filter,
            is_approved: true,
            status: {$in: ['approve', 'reject']},
          }}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'leaveManagement',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Attendance Change Requests" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyEWDRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <EWDRequestTable
          filter={{...filter, is_approved: false, status: 'unapproved'}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'EWD',
    },
    closed: {
      label: 'Closed',
      view: (
        <EWDRequestTable
          filter={{
            ...filter,
            is_approved: true,
            status: {$in: ['approve', 'reject']},
          }}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'EWD',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="EWD Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyWFHRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <WFHRequestTable
          filter={{...filter, status: 'unapproved'}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'wfhRequest',
      actions: [
        <AddButton
          title="WFH Request"
          view="add-wfh-request"
          params={props?.route?.params}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: (
        <WFHRequestTable
          filter={{...filter, status: {$in: ['approve', 'reject']}}}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'wfhRequest',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="WFH Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyRoasterRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <AttendanceChangeRequestTable
          filter={{...filter, status: 'unapproved'}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'attendanceChangeRequest',
    },
    closed: {
      label: 'Closed',
      view: (
        <AttendanceChangeRequestTable
          filter={{...filter, status: {$in: ['approved', 'reject']}}}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'attendanceChangeRequest',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Roaster Change Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyTimesheetRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: <SelfPendingTimesheetRequests params={params} {...props} />,
      eventSourceId: 'timesheet',
    },
    closed: {
      label: 'Closed',
      view: <SelfApprovedTeamTimesheetRequests params={params} {...props} />,
      eventSourceId: 'timesheet',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Timesheet Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};

export const MyTourRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <TourRequestTable
          filter={{...filter, status: 'unapproved'}}
          params={params}
          tab="open"
          {...props}
        />
      ),
      eventSourceId: 'wfhRequest',
      actions: [
        <AddButton
          title="Tour Request"
          view="add-tour-request"
          params={props?.route?.params}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: (
        <TourRequestTable
          filter={{...filter, status: {$in: ['approve', 'reject']}}}
          params={params}
          tab="closed"
          {...props}
        />
      ),
      eventSourceId: 'wfhRequest',
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Tour Request" />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};
