import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';

const FundTransferForn = props => {
  const {
    route: {params},
    api,
    addOnFilter,
    mode,
    readOnly,
  } = props;
  const {onSubmit} = useFormSubmit({
    uri: '/fundtransfers',
    eventSourceId: ['fundTransfer'],
  });
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();
  const defaultValues = {
    transaction_status: 'New',
  };
  return (
    <Form
      api={api}
      onSubmit={onSubmit}
      addOnFilter={addOnFilter}
      eventSourceId="voucher"
      submitAction="Save"
      defaultValues={defaultValues}
      fields={{
        from_fund: {name: 1},
        to_fund: {name: 1},
        date: 1,
        transfer_amount: 1,
        transfer_mode: 1,
        from_location: {name: 1},
        to_location: {name: 1},
        organization : {name : 1},
        category : {name : 1},
        product : {name : 1},
        description : 1,
        attachment : 1
      }}
      layoutFields={[
        {
          label: 'From fund',
          field: 'from_fund',
          type: 'autoComplete',
          api: `/banks`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'To fund',
          field: 'to_fund',
          type: 'autoComplete',
          api: `/banks`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          placeholder: 'Fund Transfer Date',
          field: 'date',
          required: true,
          label: 'Fund Transfer Date',
          size: 6,
        },
        {
          field: 'transfer_mode',
          label: 'Select Mode of Payment',
          type: 'autoComplete',
          options: ['RTGS', 'NEFT', 'INTRABANK'],
          required: true,
          size: 6,
        },
        {
          type: 'number',
          placeholder: 'Transfer Amount',
          label: 'Transfer Amount',
          field: 'transfer_amount',
          required: true,
          size: 6,
        },
        {
          label: 'From Location',
          field: 'from_location',
          type: 'autoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'To Location',
          field: 'to_location',
          type: 'autoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          type: 'textArea',
          placeholder: 'Description',
          field: 'description',
          required: true,
          label: 'Description',
          size: 6,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Category',
          field: 'category',
          type: 'autoComplete',
          api: '/categories',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          field: 'attachment',
          type: 'file',
          options: {bucketName: 'manaze'},
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const CreateFundTransferForm = props => {
  return <FundTransferForn header="Create Fund Transfer" {...props} />;
};

export const EditFundTransferForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <FundTransferForn
      mode="edit"
      readOnly={params.readOnly}
      header={{
        title: 'Update Fund TRansfer',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {params?.row?.fund_transfer_no}
            </Text>
          );
        },
      }}
      api={`/fundtransfer/${params?.row?._id}`}
      {...props}
    />
  );
};
