import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text} from '@unthinkable/react-core-components';
import {GroupTable, Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import {UserAvatarGroupChip} from '../../../components/avatar/UserAvatar';

export const TagWiseEmployeeTable = props => {
  let {route: {params} = {}, searchValue} = props;
  return (
    <Table
      {...props}
      api={'/tagWiseAllocatedEmployee'}
      search={searchValue}
      searchFields={['name']}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Email',
          field: 'official_email_id',
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Hours',
          field: 'Hours',
          type: 'number',
        },
      ]}
    />
  );
};

export const TagWiseUnallocatedList = props => {
  const {navigation, route: {params} = {}, searchValue} = props;
  return (
    <GroupTable
      {...props}
      api={'/tagWiseUnAllocatedEmployee'}
      params={{unallocatedList: true}}
      search={searchValue}
      searchFields={['name']}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.department} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Email',
          field: 'official_email_id',
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Reporting To',
          render: ({row}) => {
            return <UserAvatarGroupChip value={row?.reporting_to} />;
          },
        },
        {
          header: 'Hours',
          field: 'hours',
          type: 'number',
        },
      ]}
    />
  );
};

export const TagWiseAllocatedEmployeeTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  const tabs = {
    allocated: {
      label: 'Allocated',
      view: <TagWiseEmployeeTable searchValue={searchValue} {...props} />,
      eventSourceId: 'tagWise',
      api: '/tagWiseAllocatedEmployee',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TagWiseUnallocatedEmployeeTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  const tabs = {
    allocated: {
      label: 'Unallocated',
      view: <TagWiseUnallocatedList searchValue={searchValue} {...props} />,
      eventSourceId: 'tagWise',
      api: '/tagWiseUnAllocatedEmployee',
      params: {unallocatedList: true},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
