import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const ResginationReasonsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['resignreasons']}
      api={`/resignreasons`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Resign Reasons"
            actions={[
              <AddButton
                title="Resign Reason"
                view="add-resgin-reason"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-resgin-reason`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          header: 'Reason',
          field: 'reason',
        },
      ]}
    />
  );
};
