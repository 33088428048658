import {
  CaseStageTable,
  CaseTypeTable,
  JurisdictionTable,
  LegalClientTable,
} from '../LegalCases/views/LegalCasesTables';
import {LegalCasesTabs} from '../LegalCases/views/LegalCasesTabs';

export const ScreenMapping = [
  {
    id: 'legalcases',
    view: <LegalCasesTabs />,
  },
  {
    id: 'jurisdiction',
    view: <JurisdictionTable />,
  },
  {
    id: 'legalclient',
    view: <LegalClientTable />,
  },
  {
    id: 'casetype',
    view: <CaseTypeTable />,
  },
  {
    id: 'casestage',
    view: <CaseStageTable />,
  },
];
