import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddFreezeRangeForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/freezes',
    eventSourceId: 'freeze',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/freezes/${row?._id}`}
      fields={{
        from_date: 1,
        to_date: 1,
        type: 1,
        employee: {
          name: 1,
        },
        organization: {
          name: 1,
        },
      }}
      header="Add Freeze Range"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'employee',
          label: 'Employee',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          type: 'date',
          field: 'from_date',
          required: true,
          label: 'From Date',
        },
        {
          type: 'date',
          field: 'to_date',
          required: true,
          label: 'To Date',
        },
        {
          type: 'autoComplete',
          field: 'type',
          required: true,
          label: 'Type',
          options: ['Attendance', 'Order', 'Voucher', 'Salary', 'Incentive'],
        },
        {
          label: 'Organization',
          type: 'autoComplete',
          field: 'organization',
          api: '/organizations',
          suggestionField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const EditFreezeRangeForm = props => {
  return (
    <AddFreezeRangeForm {...props} mode="edit" header="Edit Freeze Range" />
  );
};
