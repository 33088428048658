import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { useFilter } from '../../../controllers/useFilter';
import { TableHeader } from '../../../components/table/Headers';
import { TasksTable } from './TasksTable';
import { taskFilters, useTaskActions } from './TaskActions';

export const ReworkTasksTable = props => {
    const {
        route: { params },
        navigation,
        user,
        favorite,
        delegatedFilter = {},
    } = props;
    let { employee_team, fromMyView, userId, thread = {}, threadGoalId } = params;
    userId = userId || user?._id;

    let threadFilter = thread?._id ? { thread: thread?._id, thread_goal: threadGoalId } : {};
    if(threadGoalId){
        threadFilter = {thread_goal: threadGoalId};
    }
    const favoriteFilter = favorite ? { favorite_by: { $in: [userId] } } : {};

    const { activityLogs, archive, markFavorite } = useTaskActions({
        navigation,
        params,
        fromMyView,
    });

    const taskDetail = ({ row }) => {
        navigation.navigate('task-edit', {
            ...params,
            row,
            readOnly: fromMyView,
        });
    };

    const { searchValue, onChangeFilter, filterValues, applyFilter } = useFilter({
        params: {},
    });
    const { params: filterParams, filter: extraFilter } = filterValues;

    const addOnFilter = {
        type: {
            $in: ['Task', 'Continuous'],
        },
        ...extraFilter,
        ...threadFilter,
        ...delegatedFilter,
        ...favoriteFilter,
    };

    if (filterParams.is_open == 'Yes') {
        addOnFilter.assigned_to = null;
    } else if (filterParams.is_open == 'No') {
        addOnFilter.assigned_to = { $exists: true };
    }
    if (fromMyView && userId) {
        addOnFilter.assigned_to = userId;
    }

    return (
        <>
            <TableHeader
                title="Rework Tasks"
                actions={[
                    <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
                    <GroupFilter
                        filterValues={filterValues}
                        applyFilter={applyFilter}
                        filters={[
                            !fromMyView && taskFilters.assigned_to,
                            taskFilters.reviewer,
                            taskFilters.favorite_by,
                            taskFilters.custom,
                            taskFilters.strategic,
                            taskFilters.due_date,
                            !fromMyView && taskFilters.is_open,
                            taskFilters.source,
                            taskFilters.show_child_task,
                        ]}
                    />,
                ]}
            />
            <TasksTable
                {...props}
                api={`/organizationtasks`}
                params={{ teamId: employee_team?._id, userId }}
                fromMyView={fromMyView}
                onRowPress={taskDetail}
                searchValue={searchValue}
                addOnFilter={{
                    is_rework: true,
                    status: {$in: ['backlog', 'active', 'archived']},
                    ...addOnFilter,
                }}
                moreActions={[archive, markFavorite, activityLogs]}
            />
        </>
    );
};
