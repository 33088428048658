import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
  UserAvatarWithNameAndEmail,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useAppStateContext} from '../../../providers/AppState';

const RenderCreateEmployee = ({row = {}, styles = {}}) => {
  const [loading] = useState(false);
  const colors = useTheme('colors');

  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}>
            <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
              Clear
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

export const EmployeeNoDuesList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {clearEmployeeNoDues, clearHRNoDues} = employeeController(props);

  return (
    <Table
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesEmployee']}
      searchFields={['name']}
      api={'/no-dues/employee'} //query on backend
      filter={
        {
          // employee_feedback: {$ne: true},
        }
      }
      columns={[
        {
          header: 'Employee',
          onPress: clearEmployeeNoDues,
          render: ({row, styles}) => {
            console.log('row?.employee_feedback', row?.employee_feedback);
            if (row?.employee_feedback == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.employee_feedback_date).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else {
              return <RenderCreateEmployee row={row} styles={styles} />;
            }
          },
        },
        {
          header: 'Admin',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.admin_DuesCleaared_Date).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accounts',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.accounts_DuesCleaared_Date).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'IT & net.',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.itandnetworking_DuesCleaared_Date).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Manager',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.reportingTo_DuesCleaared_Date).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'HR',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.hr_DuesCleaared_Date).format('DD MMM, YY')}`}
                  </Text>
                </>
              );
            } else {
              return (
                <TouchableOpacity
                  onPress={() => {
                    clearHRNoDues(true);
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'Pending'}
                    borderRadius={6}
                  />
                </TouchableOpacity>
              );
            }
          },
        },
        {
          header: 'F&F date',
          type: 'date',
          field: 'fnf_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.status == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                </>
              );
            } else if (row?.is_cancelled == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.OUTLINE_LOW}
                    textColor={colors.OUTLINE_HIGH}
                    value={'Cancelled'}
                    borderRadius={6}
                  />
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.INFORMATION_LOW}
                  textColor={colors.INFORMATION_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesHrPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {CancelNodues, HoldNodues, StartFnF} = employeeController(props);
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      $and: [
        {
          status: {
            $ne: true,
          },
        },
        {is_cancelled: {$ne: true}},
        {on_hold: {$ne: true}},
      ],
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/pending',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   $and: [
      //     {
      //       status: {
      //         $ne: true,
      //       },
      //     },
      //     {is_cancelled: {$ne: true}},
      //     {on_hold: {$ne: true}},
      //   ],
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      'Joining date': 'joining_date_download',
                      'Resign date': 'resign_date_new_download',
                      'Exp Relieving date': 'expected_relieving_date_download',
                      'Relieving date': 'relieving_date_download',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'resign_accepted_on_download',
                      'Resign rejected on': 'resign_rejected_on_download',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                      'Employee no dues cleared': 'employee_feedback',
                      'Employee no dues cleared date': 'employee_feedback_date',
                      'Admin no dues cleared': 'admin_DuesCleaared',
                      'Admin no dues cleared date': 'admin_DuesCleaared_Date',
                      'Accounts no dues cleared': 'Accounts_DuesCleaared',
                      'Accounts no dues cleared date':
                        'accounts_DuesCleaared_Date',
                      'IT no dues cleared': 'ITNetworking_DuesCleaared',
                      'IT no dues cleared date':
                        'itandnetworking_DuesCleaared_Date',
                      'Manager no dues cleared': 'reportingTo_DuesCleaared',
                      'Manager no dues cleared date':
                        'reportingTo_DuesCleaared_Date',
                      'Clearance date': 'no_dues_closing_date',
                      'FNF status': 'fnf_status',
                      'Relieving Letter status':
                        'employee.is_relieving_letter_added',
                      'Relieving letter date': 'employee.relieving_letter_date',
                      'Employee status': 'employee.employee_status',
                    },
                  },
                },
              }),
          },
          {
            title: 'Mark Processed',
            onPress: ({selectedIds}) => {
              navigation.navigate(`nodues-mark-processed`, {
                ...params,
                selectedIds,
              });
            },
          },
        ],
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {
        //     format: 'DD MMM,YY',
        //   },
        // }, //is fnf and pending since same?
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 120,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            if (row?.employee_feedback == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`employee-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.employee_feedback_date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Admin',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`admin-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.admin_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accounts',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`accounts-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.accounts_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'IT & net.',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`it-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.itandnetworking_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Manager',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`team-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.reportingTo_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'HR',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`hr-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.hr_DuesCleaared_Date).format('DD MMM YY')}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'F&F date',
          type: 'date',
          field: 'fnf_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={() => [
        CancelNodues({invoke}),
        HoldNodues({invoke}),
        StartFnF({invoke}),
      ]}
    />
  );
};

export const NoDuesHrOverdueList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {CancelNodues, HoldNodues, StartFnF} = employeeController(props);
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      $and: [
        {
          status: {
            $ne: true,
          },
        },
        {is_cancelled: {$ne: true}},
        {on_hold: {$ne: true}},
      ],
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/overude',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   $and: [
      //     {
      //       status: {
      //         $ne: true,
      //       },
      //     },
      //     {is_cancelled: {$ne: true}},
      //     {on_hold: {$ne: true}},
      //   ],
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      'Joining date': 'joining_date_download',
                      'Resign date': 'resign_date_new_download',
                      'Exp Relieving date': 'expected_relieving_date_download',
                      'Relieving date': 'relieving_date_download',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'resign_accepted_on_download',
                      'Resign rejected on': 'resign_rejected_on_download',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                      'Employee no dues cleared': 'employee_feedback',
                      'Employee no dues cleared date': 'employee_feedback_date',
                      'Admin no dues cleared': 'admin_DuesCleaared',
                      'Admin no dues cleared date': 'admin_DuesCleaared_Date',
                      'Accounts no dues cleared': 'Accounts_DuesCleaared',
                      'Accounts no dues cleared date':
                        'accounts_DuesCleaared_Date',
                      'IT no dues cleared': 'ITNetworking_DuesCleaared',
                      'IT no dues cleared date':
                        'itandnetworking_DuesCleaared_Date',
                      'Manager no dues cleared': 'reportingTo_DuesCleaared',
                      'Manager no dues cleared date':
                        'reportingTo_DuesCleaared_Date',
                      'Clearance date': 'no_dues_closing_date',
                      'FNF status': 'fnf_status',
                      'Relieving Letter status':
                        'employee.is_relieving_letter_added',
                      'Relieving letter date': 'employee.relieving_letter_date',
                      'Employee status': 'employee.employee_status',
                    },
                  },
                },
              }),
          },
          {
            title: 'Mark Processed',
            onPress: ({selectedIds}) => {
              navigation.navigate(`nodues-mark-processed`, {
                ...params,
                selectedIds,
              });
            },
          },
        ],
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {
        //     format: 'DD MMM,YY',
        //   },
        // }, //is fnf and pending since same?
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 120,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            if (row?.employee_feedback == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`employee-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.employee_feedback_date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Admin',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`admin-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.admin_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accounts',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`accounts-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.accounts_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'IT & net.',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`it-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.itandnetworking_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Manager',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(`team-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.reportingTo_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'HR',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`hr-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.hr_DuesCleaared_Date).format('DD MMM YY')}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'F&F date',
          type: 'date',
          field: 'fnf_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      // moreActions={() => [
      //   CancelNodues({invoke}),
      //   HoldNodues({invoke}),
      //   StartFnF({invoke}),
      // ]}
    />
  );
};

const markProcessed = ({selectedIds, invoke}) => {
  invoke({
    uri: `/mark-delivery-adjusted`,
    method: 'put',
    props: {
      selectedIds: selectedIds,
    },
  });
};

export const NoDuesHrClosedList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {CancelNodues, StartFnF, AbscondingNodues} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      $or: [{status: true}, {is_cancelled: true}],
      $nor: [{on_hold: true}, {status: true, fnf_status: 'absoconding'}],
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/pending-closed',
  };

  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/no-dues/pending-closed'} //query on backend
      // filter={{
      //   $or: [{status: true}, {is_cancelled: true}],
      //   $nor: [{on_hold: true}, {status: true, fnf_status: {$exists: true}}],
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      'Joining date': 'joining_date_download',
                      'Resign date': 'resign_date_new_download',
                      'Exp Relieving date': 'expected_relieving_date_download',
                      'Relieving date': 'relieving_date_download',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'resign_accepted_on_download',
                      'Resign rejected on': 'resign_rejected_on_download',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                      'Employee no dues cleared': 'employee_feedback',
                      'Employee no dues cleared date': 'employee_feedback_date',
                      'Admin no dues cleared': 'admin_DuesCleaared',
                      'Admin no dues cleared date': 'admin_DuesCleaared_Date',
                      'Accounts no dues cleared': 'Accounts_DuesCleaared',
                      'Accounts no dues cleared date':
                        'accounts_DuesCleaared_Date',
                      'IT no dues cleared': 'ITNetworking_DuesCleaared',
                      'IT no dues cleared date':
                        'itandnetworking_DuesCleaared_Date',
                      'Manager no dues cleared': 'reportingTo_DuesCleaared',
                      'Manager no dues cleared date':
                        'reportingTo_DuesCleaared_Date',
                      'Clearance date': 'no_dues_closing_date',
                      'FNF status': 'fnf_status',
                      'Relieving Letter status':
                        'employee.is_relieving_letter_added',
                      'Relieving letter date': 'employee.relieving_letter_date',
                      'Employee status': 'employee.employee_status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 120,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 120,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            if (row?.employee_feedback == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`employee-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.employee_feedback_date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Admin',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`admin-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.admin_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accounts',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`accounts-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.accounts_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'IT & net.',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`it-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.itandnetworking_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Manager',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`team-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.reportingTo_DuesCleaared_Date).format(
                      'DD MMM YY',
                    )}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'HR',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`hr-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.hr_DuesCleaared_Date).format('DD MMM YY')}`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'F&F date',
          type: 'date',
          field: 'fnf_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.status == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.nodue_closing_date).format('DD MMM YY')}`}
                  </Text>
                </>
              );
            } else if (row?.is_cancelled == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.OUTLINE_LOW}
                    textColor={colors.OUTLINE_HIGH}
                    value={'Cancelled'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.cancel_date).format('DD MMM YY')}`}
                  </Text>
                </>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.INFORMATION_LOW}
                  textColor={colors.INFORMATION_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
      moreActions={() => [StartFnF({invoke}), AbscondingNodues({invoke})]}
    />
  );
};

export const NoDuesHrHoldList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {CancelNodues, RestartFnF, AbscondingNodues} =
    employeeController(props);
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      $or: [{fnf_status: 'absconding'}, {on_hold: true}],
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/pending-closed',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/no-dues/pending-closed'} //query on backend
      // filter={{
      //   $or: [{fnf_status: 'absconding'}, {on_hold: true}],
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      'Joining date': 'joining_date_download',
                      'Resign date': 'resign_date_new_download',
                      'Exp Relieving date': 'expected_relieving_date_download',
                      'Relieving date': 'relieving_date_download',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'resign_accepted_on_download',
                      'Resign rejected on': 'resign_rejected_on_download',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                      'Employee no dues cleared': 'employee_feedback',
                      'Employee no dues cleared date': 'employee_feedback_date',
                      'Admin no dues cleared': 'admin_DuesCleaared',
                      'Admin no dues cleared date': 'admin_DuesCleaared_Date',
                      'Accounts no dues cleared': 'Accounts_DuesCleaared',
                      'Accounts no dues cleared date':
                        'accounts_DuesCleaared_Date',
                      'IT no dues cleared': 'ITNetworking_DuesCleaared',
                      'IT no dues cleared date':
                        'itandnetworking_DuesCleaared_Date',
                      'Manager no dues cleared': 'reportingTo_DuesCleaared',
                      'Manager no dues cleared date':
                        'reportingTo_DuesCleaared_Date',
                      'Clearance date': 'no_dues_closing_date',
                      'FNF status': 'fnf_status',
                      'Relieving Letter status':
                        'employee.is_relieving_letter_added',
                      'Relieving letter date': 'employee.relieving_letter_date',
                      'Employee status': 'employee.employee_status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 120,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            if (row?.employee_feedback == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`employee-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.employee_feedback_date &&
                      moment(row.employee_feedback_date).format('DD MMM YY')
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Admin',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`admin-no-dues-form`, {
                      ...params,

                      fromHr: true,

                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.admin_DuesCleaared_Date &&
                      moment(row.admin_DuesCleaared_Date).format('DD MMM YY')
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accounts',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`accounts-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.accounts_DuesCleaared_Date &&
                      moment(row.accounts_DuesCleaared_Date).format('DD MMM YY')
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'IT & net.',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`it-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.itandnetworking_DuesCleaared_Date &&
                      moment(row.itandnetworking_DuesCleaared_Date).format(
                        'DD MMM YY',
                      )
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Manager',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`team-no-dues-form`, {
                      ...params,
                      fromHr: true,
                      row,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.reportingTo_DuesCleaared_Date &&
                      moment(row.reportingTo_DuesCleaared_Date).format(
                        'DD MMM YY',
                      )
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'HR',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('row-', row);
                    navigation.navigate(`hr-no-dues-form`, {
                      ...params,
                      row,
                      fromHr: true,
                    });
                  }}>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Cleared'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.hr_DuesCleaared_Date &&
                      moment(row.hr_DuesCleaared_Date).format('DD MMM YY')
                    }`}
                  </Text>
                </TouchableOpacity>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'F&F date',
          type: 'date',
          field: 'fnf_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.fnf_status == 'absconding') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Absconding'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.absconding_date &&
                      moment(row.absconding_date).format('DD MMM YY')
                    }`}
                  </Text>
                </>
              );
            } else if (row?.on_hold == true) {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'On hold'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${
                      row.on_hold_date &&
                      moment(row.on_hold_date).format('DD MMM YY')
                    }`}
                  </Text>
                </>
              );
            }
          },
        },
      ]}
      // moreActions={() => [RestartFnF({invoke})]}
    />
  );
};

export const NoDuesTeamPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      reportingTo_DuesCleaared: {$ne: true},
      is_transfer_case: {$in: [null, false]},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
    },
    searchFields: ['name'],
    api: '/no-dues/team',
  };

  return (
    <Table
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesTeam']}
      searchFields={['name']}
      api={'/no-dues/team'} //query on backend
      filter={{
        reportingTo_DuesCleaared: {$ne: true},
        is_transfer_case: {$in: [null, false]},
        is_cancelled: {$ne: true},
        on_hold: {$ne: true},
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Resign date',
          field: 'employee.resign_date_new',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Exp. Relieving date',
          field: 'employee.expected_relieving_date',
          width: 180,
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: clearTeamNoDues,
          // width: 80,
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesTeamProcessedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesTeam']}
      searchFields={['name']}
      api={'/no-dues/team/processed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} processed`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Exit on',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.reportingTo_DuesCleaared == true) {
              if (row?.is_cancelled == true) {
                return (
                  <>
                    <Chip
                      // {...rest}
                      color={colors.OUTLINE_LOW}
                      textColor={colors.OUTLINE_HIGH}
                      value={'Cancelled'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.nodue_closing_date).format('DD MMM, YY')}`}
                    </Text>
                  </>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      console.log('row-', row);
                      navigation.navigate(`team-no-dues-form`, {
                        ...params,
                        fromHr: true,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={colors.SUCCESS_LOW}
                      textColor={colors.SUCCESS_HIGH}
                      value={'Cleared'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.reportingTo_DuesCleaared_Date).format(
                        'DD MMM, YY',
                      )}`}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }
          },
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesAccountsPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      Accounts_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/pending',
  };

  const {clearAccountsNoDues} = employeeController(props);
  const {user, download} = useAppStateContext();

  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      // //   addOnFilter={{...addOnFilter, ...filterValues}}
      // eventSourceId={['NoduesAccounts']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   Accounts_DuesCleaared: {$ne: true},
      //   is_cancelled: {$ne: true},
      //   on_hold: {$ne: true},
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'employee.name',
                      'Employee code': 'employee.employee_code',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Exp Relieving date': 'employee.expected_relieving_date',
                      'Relieving date': 'employee.relieving_date',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'employee.resign_accepted_on',
                      'Resign rejected on': 'employee.resign_rejected_on',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Pending since',
          // width: 120,
          field: 'nodue_start_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Resign date',
          field: 'employee.resign_date_new',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Exp. Relieving date',
          field: 'employee.expected_relieving_date',
          width: 180,
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: clearAccountsNoDues,
          // width: 80,
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesAccountsProcessedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesTeam']}
      searchFields={['name']}
      api={'/no-dues/accounts/processed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} new`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Pending since',
          // width: 120,
          field: 'nodue_start_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Exit on',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.Accounts_DuesCleaared == true) {
              if (row?.is_cancelled == true) {
                return (
                  <>
                    <Chip
                      // {...rest}
                      color={colors.OUTLINE_LOW}
                      textColor={colors.OUTLINE_HIGH}
                      value={'Cancelled'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.nodue_closing_date).format('DD MMM, YY')}`}
                    </Text>
                  </>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(`accounts-no-dues-form`, {
                        ...params,
                        fromHr: true,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={colors.SUCCESS_LOW}
                      textColor={colors.SUCCESS_HIGH}
                      value={'Cleared'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.accounts_DuesCleaared_Date).format(
                        'DD MMM, YY',
                      )}`}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }
          },
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesHRPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearHRNoDues} = employeeController(props);
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      hr_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/no-dues/pending',
  };

  return (
    <Table
      {...props}
      // params={params}
      // //   addOnFilter={{...addOnFilter, ...filterValues}}
      // eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   hr_DuesCleaared: {$ne: true},
      //   is_cancelled: {$ne: true},
      //   on_hold: {$ne: true},
      // }}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'employee.name',
                      'Employee code': 'employee.employee_code',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Exp Relieving date': 'employee.expected_relieving_date',
                      'Relieving date': 'employee.relieving_date',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'employee.resign_accepted_on',
                      'Resign rejected on': 'employee.resign_rejected_on',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Resign date',
          field: 'employee.resign_date_new',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Exp. Relieving date',
          field: 'employee.expected_relieving_date',
          width: 180,
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: clearHRNoDues,
          // width: 80,
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesHRProcessedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesHr']}
      searchFields={['name']}
      api={'/no-dues/hr/processed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} new`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Exit on',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.hr_DuesCleaared == true) {
              if (row?.is_cancelled == true) {
                return (
                  <>
                    <Chip
                      // {...rest}
                      color={colors.OUTLINE_LOW}
                      textColor={colors.OUTLINE_HIGH}
                      value={'Cancelled'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.nodue_closing_date).format('DD MMM, YY')}`}
                    </Text>
                  </>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(`hr-no-dues-form`, {
                        ...params,
                        fromHr: true,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={colors.SUCCESS_LOW}
                      textColor={colors.SUCCESS_HIGH}
                      value={'Cleared'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.hr_DuesCleaared_Date).format(
                        'DD MMM, YY',
                      )}`}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }
          },
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesAdminPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearAdminNoDues} = employeeController(props);
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      admin_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
      ...filterValues
    },
    searchFields: ['name'],
    api: '/no-dues/pending',
  };

  return (
    <Table
      {...props}
      // params={params}
      // //   addOnFilter={{...addOnFilter, ...filterValues}}
      // eventSourceId={['NoduesAdmin']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   admin_DuesCleaared: {$ne: true},
      //   is_cancelled: {$ne: true},
      //   on_hold: {$ne: true},
      // }}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'employee.name',
                      'Employee code': 'employee.employee_code',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Exp Relieving date': 'employee.expected_relieving_date',
                      'Relieving date': 'employee.relieving_date',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'employee.resign_accepted_on',
                      'Resign rejected on': 'employee.resign_rejected_on',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Resign date',
          field: 'employee.resign_date_new',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Exp. Relieving date',
          field: 'employee.expected_relieving_date',
          width: 180,
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: clearAdminNoDues,
          // width: 80,
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesAdminProcessedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesAdmin']}
      searchFields={['name']}
      api={'/no-dues/admin/processed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} new`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'fnf_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Exit on',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.admin_DuesCleaared == true) {
              if (row?.is_cancelled == true) {
                return (
                  <>
                    <Chip
                      // {...rest}
                      color={colors.OUTLINE_LOW}
                      textColor={colors.OUTLINE_HIGH}
                      value={'Cancelled'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.nodue_closing_date).format('DD MMM, YY')}`}
                    </Text>
                  </>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(`admin-no-dues-form`, {
                        ...params,
                        fromHr: true,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={colors.SUCCESS_LOW}
                      textColor={colors.SUCCESS_HIGH}
                      value={'Cleared'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.admin_DuesCleaared_Date).format(
                        'DD MMM, YY',
                      )}`}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }
          },
        },
      ]}
      //   moreActions={() => [
      //     {
      //       title: 'Parichay Details',
      //       onPress: ({row}) => {
      //         navigation.navigate('edit-employee-parichay-form', {row: row});
      //       },
      //     },
      //   ]}
    />
  );
};

export const NoDuesAdminITList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearITNoDues} = employeeController(props);
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      ITNetworking_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
      ...filterValues
    },
    searchFields: ['name'],
    api: '/no-dues/pending',
  };

  return (
    <Table
      {...props}
      // params={params}
      // //   addOnFilter={{...addOnFilter, ...filterValues}}
      // eventSourceId={['NoduesIt']}
      // searchFields={['name']}
      // api={'/no-dues/pending'} //query on backend
      // filter={{
      //   ITNetworking_DuesCleaared: {$ne: true},
      //   is_cancelled: {$ne: true},
      //   on_hold: {$ne: true},
      // }}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'employee.name',
                      'Employee code': 'employee.employee_code',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Exp Relieving date': 'employee.expected_relieving_date',
                      'Relieving date': 'employee.relieving_date',
                      'Resign status': 'employee.resign_status',
                      'Resign accepted on': 'employee.resign_accepted_on',
                      'Resign rejected on': 'employee.resign_rejected_on',
                      Experience: 'employee.experience',
                      'Reporting to': 'employee_reporting_to',
                      'IT Accessories': 'it_assets',
                      'IT Accessories Comment': 'it_assets_comment',
                      'IT Accessories Not Applicable':
                        'it_assets_not_applicable',

                      Laptop: 'laptop',
                      'Laptop Comment': 'laptop_comment',
                      'Laptop Not Applicable': 'laptop_not_applicable',

                      'Sim card': 'sim_card',
                      'Sim card Comment': 'sim_card_comment',
                      'Sim card Not Applicable': 'sim_card_not_applicable',

                      'Mobile bill': 'mobile_bill',
                      'Mobile bill Comment': 'mobile_bill_comment',
                      'Mobile bill Not Applicable':
                        'mobile_bill_not_applicable',

                      'Recovery if any in case of lost or damaged device':
                        'other_assets',
                      'Recovery if any in case of lost or damaged device Comment':
                        'other_assets_comment',
                      'Recovery if any in case of lost or damaged device Not Applicable':
                        'other_assets_not_applicable',

                      'Biometric disabled': 'biometric_disable',
                      'Biometric disabled Comment': 'biometric_disable_comment',
                      'Biometric disabled Not Applicable':
                        'biometric_disable_not_applicable',

                      'Reset the password of mail, skype and others after LWD formalities':
                        'password_asset',
                      'Reset the password of mail, skype and others after LWD formalities Comment':
                        'password_asset_comment',
                      'Reset the password of mail, skype and others after LWD formalities Not Applicable':
                        'password_asset_not_applicable',

                      'Approval, if employee wants to retain the sim':
                        'sim_retain',
                      'Approval, if employee wants to retain the sim Comment':
                        'sim_retain_comment',
                      'Approval, if employee wants to retain the sim Not Applicable':
                        'sim_retain_not_applicable',

                      'Email backUp required': 'email_backup_required',
                      'Format laptop': 'format_laptop',
                      'Reporting manager no dues cleard':
                        'reportingTo_DuesCleaared',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // {
        //   header: 'Nodues start date',
        //   // width: 120,
        //   field: 'nodue_start_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.reporting_to?.[0]?.name}
                color={row?.employee?.reporting_to?.[0]?.color}
                values={[
                  {
                    value: row?.employee?.reporting_to?.[0]?.official_email_id,
                  },
                ]}
              />
            );
          },
          width: 280,
        },
        // {
        //   header: 'Reporting',
        //   field: 'employee.reporting_to',
        //   type: 'userAvatarGroup',
        //   align: 'center',
        //   width: 100,
        // },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Resign date',
          field: 'employee.resign_date_new',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Exp. Relieving date',
          field: 'employee.expected_relieving_date',
          width: 180,
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Relieving date',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: clearITNoDues,
          // width: 80,
        },
      ]}
      moreActions={() => [
        {
          title: 'Activity history',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee', select: {name: 1}},
                {
                  path: 'fnf_start_by',
                  select: {name: 1},
                },
              ],
              api: `/nodues/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const NoDuesITProcessedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['NoduesIt']}
      searchFields={['name']}
      api={'/no-dues/it/processed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} new`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        // {
        //   header: 'Pending since',
        //   // width: 120,
        //   field: 'fnf_date',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM, YY'},
        // },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Exit on',
          field: 'employee.relieving_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.ITNetworking_DuesCleaared == true) {
              if (row?.is_cancelled == true) {
                return (
                  <>
                    <Chip
                      // {...rest}
                      color={colors.OUTLINE_LOW}
                      textColor={colors.OUTLINE_HIGH}
                      value={'Cancelled'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.nodue_closing_date).format('DD MMM, YY')}`}
                    </Text>
                  </>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(`it-no-dues-form`, {
                        ...params,
                        fromHr: true,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={colors.SUCCESS_LOW}
                      textColor={colors.SUCCESS_HIGH}
                      value={'Cleared'}
                      borderRadius={6}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        // overflow: 'hidden',
                      }}>
                      {`${moment(row.itandnetworking_DuesCleaared_Date).format(
                        'DD MMM, YY',
                      )}`}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Activity history',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee', select: {name: 1}},
                {
                  path: 'fnf_start_by',
                  select: {name: 1},
                },
              ],
              api: `/nodues/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
