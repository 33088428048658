import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useStyles} from '@unthinkable/react-theme';
import {
  PeriodRangeFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import {Tag} from '../../../components/chip/Chip';
import {useFetchData} from '../../../controllers/useFetchData';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';

export const WonClosedRender = ({row, ...rest}) => {
  const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      {...rest}
      color={SUCCESS_LOW}
      textColor={SUCCESS_HIGH}
      value={row?.won_count || 0}
      borderRadius={6}
    />
  );
};

export const LostRender = ({row, ...rest}) => {
  const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={ERROR_LOW}
      textColor={ERROR_HIGH}
      value={row?.lost_count || 0}
      borderRadius={6}
    />
  );
};

export const DropRender = ({row, ...rest}) => {
  const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={WARNING_LOW}
      textColor={WARNING_HIGH}
      value={row?.drop_count || 0}
      borderRadius={6}
    />
  );
};

export const OpportunityAnalyticsTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  let {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter = {}, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const colors = useTheme('colors');

  return (
    <>
      <Table
        api={`/opportunity/opportunity-analytics`}
        renderHeader={() => (
          <TableHeader
            title="Opportunity Analaytics"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
            ]}
          />
        )}
        params={params}
        variant={'bordered'}
        columns={[
          {
            header: 'Opportunity Type',
            field: 'opportunity_type',
            minWidth: 180,
            render: ({row}) => {
              if (row?.opportunity_type == 'Engineer') {
                return (
                  <Tag
                    value={'Staff Augmentation'}
                    color={colors.ACCENT6_MEDIUM}
                  />
                );
              } else if (row?.opportunity_type == 'Feature') {
                return (
                  <Tag
                    value={'Custom Platform'}
                    color={colors.ACCENT5_MEDIUM}
                  />
                );
              }
            },
          },
          {
            header: 'Opportunities',
            children: [
              {
                type: 'number',
                field: 'month_start_count',
                header: 'Opening',
                width: 70,
                align: 'center',
                onPress: ({row}) => {
                  if (row?.opportunity_type == 'Feature') {
                    const arrayOfIds = row?.monthStartRfq.map(item => item._id);
                    navigation.navigate(
                      'opportunity-analytics-active-feature',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                  if (row?.opportunity_type == 'Engineer') {
                    const arrayOfIds = row?.monthStartRfr.map(item => item._id);

                    navigation.navigate(
                      'show-interview-resource-analytics',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                },
              },
              {
                type: 'number',
                field: 'new_count',
                onPress: ({row}) => {
                  if (row?.opportunity_type == 'Feature') {
                    const arrayOfIds = row?.newRfq.map(item => item._id);
                    navigation.navigate(
                      'opportunity-analytics-active-feature',
                      {
                        row: arrayOfIds,
                      },
                    );
                  }
                  if (row?.opportunity_type == 'Engineer') {
                    const arrayOfIds = row?.newRfr.map(item => item._id);

                    navigation.navigate(
                      'show-interview-resource-analytics',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                },
                header: 'New',
                width: 60,
              },
              {
                type: 'number',
                field: 'won_count',
                align: 'right',
                render: ({row}) => {
                  const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
                  const {BODY2, CAPTION_LARGE} = useTheme('fonts');

                  if (row?.opportunity_type == 'Engineer') {
                    return (
                      <Chip
                        color={SUCCESS_LOW}
                        textColor={SUCCESS_HIGH}
                        value={row?.won_count || 0}
                        borderRadius={6}
                      />
                    );
                  }
                  if (row?.opportunity_type == 'Feature') {
                    return (
                      <Col style={{alignItems: 'flex-end'}}>
                        <Chip
                          color={SUCCESS_LOW}
                          textColor={SUCCESS_HIGH}
                          value={row?.won_count || 0}
                          borderRadius={6}
                        />
                        <Text
                          style={{
                            ...CAPTION_LARGE,
                            color: colors.NEUTRAL_LOW,
                          }}>
                          {row.proposal_man_months}MM
                        </Text>
                      </Col>
                    );
                  }
                },
                header: 'Won',
                onPress: ({row}) => {
                  if (row?.opportunity_type == 'Feature') {
                    const arrayOfIds = row?.wonRfq.map(item => item._id);
                    navigation.navigate('opportunity-analytics-close-feature', {
                      ...params,
                      skipGroupping: true,
                      row: arrayOfIds,
                    });
                  }
                  if (row?.opportunity_type == 'Engineer') {
                    const arrayOfIds = row?.wonRfr.map(item => item._id);
                    navigation.navigate(
                      'show-interview-resource-analytics',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                },
                width: 70,
              },
              {
                type: 'number',
                field: 'lost_count',
                header: 'Lost',
                render: ({row}) => {
                  const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
                  const {BODY2, CAPTION_LARGE} = useTheme('fonts');

                  if (row?.opportunity_type == 'Engineer') {
                    return (
                      <Chip
                        color={ERROR_LOW}
                        textColor={ERROR_HIGH}
                        value={row?.lost_count || 0}
                        borderRadius={6}
                      />
                    );
                  }
                  if (row?.opportunity_type == 'Feature') {
                    return (
                      <Col style={{alignItems: 'flex-end'}}>
                        <Chip
                          color={ERROR_LOW}
                          textColor={ERROR_HIGH}
                          value={row?.lost_count || 0}
                          borderRadius={6}
                        />
                        <Text
                          style={{
                            ...CAPTION_LARGE,
                            color: colors.NEUTRAL_LOW,
                          }}>
                          {row.quote_man_months}MM
                        </Text>
                      </Col>
                    );
                  }
                },
                onPress: ({row}) => {
                  if (row?.opportunity_type == 'Feature') {
                    const arrayOfIds = row?.lostRfq.map(item => item._id);

                    navigation.navigate('opportunity-analytics-close-feature', {
                      ...params,
                      skipGroupping: true,
                      row: arrayOfIds,
                    });
                  }
                  if (row?.opportunity_type == 'Engineer') {
                    const arrayOfIds = row?.lostRfr.map(item => item._id);

                    navigation.navigate(
                      'show-interview-resource-analytics',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                },
                width: 70,
              },
              {
                type: 'number',
                field: 'drop_count',
                header: 'Drop',
                render: DropRender,
                onPress: ({row}) => {
                  if (row?.opportunity_type == 'Feature') {
                    const arrayOfIds = row?.dropRfq.map(item => item._id);
                    navigation.navigate('opportunity-analytics-close-feature', {
                      ...params,
                      skipGroupping: true,
                      row: arrayOfIds,
                    });
                  }
                  if (row?.opportunity_type == 'Engineer') {
                    const arrayOfIds = row?.dropRfr.map(item => item._id);
                    navigation.navigate(
                      'show-interview-resource-analytics',
                      {
                        ...params,
                        row: arrayOfIds,
                      },
                    );
                  }
                },
                width: 60,
              },
              {
                type: 'number',
                field: 'end_count',
                header: 'Closing',
                align: 'center',
                width: 70,
              },
            ],
          },
          {
            header: 'Win %',
            align: 'right',
            width: 100,
            render: ({row}) => {
              const colors = useTheme('colors');
              const {BODY2, CAPTION_LARGE} = useTheme('fonts');
              if (row && row.won_percent) {
                console.log('row');
                if (row.won_percent < 65) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.ERROR_HIGH,
                      }}>
                      {row.won_percent.toFixed(2) || 0}%
                    </Text>
                  );
                } else if (row.won_percent >= 65 && row.won_percent < 85) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.WARNING_HIGH,
                      }}>
                      {row.won_percent.toFixed(2) || 0}%
                    </Text>
                  );
                } else if (row.won_percent > 85) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.SUCCESS_HIGH,
                      }}>
                      {row.won_percent.toFixed(2) || 0}%
                    </Text>
                  );
                }
              }
            },
          },
          {
            header: 'Average Convertion Time',
            align: 'right',
            render: ({row}) => {
              const {BODY2, CAPTION_LARGE} = useTheme('fonts');
              if (row && row?.avg_convertion_time) {
                return (
                  <Text
                    style={{
                      ...BODY2,
                    }}>
                    {row.avg_convertion_time}d
                  </Text>
                );
              }
            },
          },
          {
            header: 'Selection Rate',
            align: 'right',
            render: ({row}) => {
              const colors = useTheme('colors');
              const {BODY2, CAPTION_LARGE} = useTheme('fonts');
              if (row && row.selection_percent) {
                console.log('row');
                if (row.selection_percent < 65) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.ERROR_HIGH,
                      }}>
                      {row.selection_percent.toFixed(2)}%
                    </Text>
                  );
                } else if (
                  row.selection_percent >= 65 &&
                  row.selection_percent < 85
                ) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.WARNING_HIGH,
                      }}>
                      {row.selection_percent.toFixed(2)}%
                    </Text>
                  );
                } else if (row.selection_percent > 85) {
                  return (
                    <Text
                      style={{
                        ...BODY2,
                        color: colors.SUCCESS_HIGH,
                      }}>
                      {row.selection_percent.toFixed(2)}%
                    </Text>
                  );
                }
              }
            },
          },
        ]}
      />
    </>
  );
};
