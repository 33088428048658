import React from 'react';

import {InlineForm} from '../../../../components/form/Form';
import {Table} from '../../../../components/table/Table';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_DATA_SERVICE_TYPE_ID} from '../../../common/constants/SourceConstants';
import {DataServiceDetailForm} from './DataServiceForm';
import {SplitScreen} from '../../../../components/SplitScreen';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {DataServiceTable, dataServiceExportParams} from './DataServiceScreen';
import {TabView} from '../../../../components/tab';
import {TableHeader} from '../../../../components/table/Headers';
import {useFilter} from '../../../../controllers/useFilter';
import {SearchFilter} from '../../../../components/filter/Filters';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {GroupBy} from '../../../../components/table/GroupBy';

const DataServiceInlineForm = props => {
  const {
    route: {params},
    navigation,
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'DataService',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params || {};

  const defaultValues = {
    source: PROJECT_DATA_SERVICE_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Name',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_DATA_SERVICE_TYPE_ID}/suggestions`,
          suggestionField: 'name',
          valueField: 'name',
          searchFields: ['name'],
          required: true,
          filter: {_id: {$nin: sourceIds}},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-data-service`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

export const FeaturesDataServiceTable = props => {
  const {
    navigation,
    route: {params},
    selectedId,
  } = props;

  const {feature} = params;

  const EVENT_SOURCE_ID = ['Feature', 'Task', 'DataService', 'MapUserstory'];

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_DATA_SERVICE_TYPE_ID}/asset-data`,
  };

  const {
    assetDetail,
    tasks,
    versions,
    changeModule,
    updateArtifactStatus,

    removeFromFeature,
    changeDataEntity,
    changeAPI,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_DATA_SERVICE_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/projectDataServices',
    source: PROJECT_DATA_SERVICE_TYPE_ID,
  });

  return (
    <>
      <Table
        renderHeader={headerProps => (
          <FeatureAssetHeaderRow
            feature={feature}
            assetId={PROJECT_DATA_SERVICE_TYPE_ID}
            assetInlineForm={<DataServiceInlineForm {...props} />}
            eventSourceId={EVENT_SOURCE_ID}
            exportParams={dataServiceExportParams({
              ...tableProps,
              fromFeature: true,
            })}
            {...headerProps}
          />
        )}
        selection={{
          actions: [updateModuleMultipleAssets, updateDataEntityMultipleAssets],
        }}
        eventSourceId={EVENT_SOURCE_ID}
        columns={[
          {
            type: 'text',
            field: 'name',
            header: 'Name',
          },
          {
            type: 'colorTag',
            field: 'model_id.model',
            colorField: 'model_id.color',
            header: 'Model',
            width: 200,
          },
          !selectedId && assetColumns.userstory_count,
          !selectedId && assetColumns.multipleApis,
          !selectedId && assetColumns.tasks({onPress: tasks}),
          !selectedId && assetColumns.est_hrs,
          !selectedId && assetColumns.assignees,
          !selectedId && assetColumns.complexity,
          !selectedId && assetColumns.artifact_status,
        ]}
        onRowPress={assetDetail}
        moreActions={[
          versions,
          changeModule,
          updateArtifactStatus,
          changeDataEntity,
          changeAPI,
          removeFromFeature,
        ]}
        {...tableProps}
      />
    </>
  );
};

const FeatureDataServiceScreen = props => {
  return (
    <SplitScreen splitScreen={<DataServiceDetailForm {...props} />}>
      <FeaturesDataServiceTable {...props} />
    </SplitScreen>
  );
};

export const AllDataServicesScreen = props => {
  const {
    route: {params},
    api,
    groupBy,
  } = props;

  const {
    project,
    feature,
    module,
    paramsFilter,
    referenceAsset,
    api: apiParams,
  } = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_DATA_SERVICE_TYPE_ID].sourceField;

  const filter = {...paramsFilter};

  if (!referenceAsset) {
    filter.module_id = module?._id;
  }

  const tableProps = {
    api:
      apiParams ||
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_DATA_SERVICE_TYPE_ID}/all-data`,
    addOnFilter: filterValues.filter,
    search: searchValue,
    searchFields: sourceField,
    filter,
    params: {
      show_in_development: !referenceAsset && !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
          !referenceAsset && (
            <ExportExcelAction
              selectedIds={selectedIds}
              params={dataServiceExportParams({
                ...tableProps,
                fromFeature: true,
              })}
            />
          ),
          <AssetAddButton
            title={'Service'}
            view={'add-data-service'}
            params={params}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<DataServiceDetailForm {...props} />}>
      <DataServiceTable
        {...props}
        groupBy={groupBy}
        renderHeader={renderHeader}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureDataServiceTab = props => {
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureDataServiceScreen {...props} />,
        },
        all: {
          label: 'All',
          view: <AllDataServicesScreen {...props} />,
        },
      }}
    />
  );
};
