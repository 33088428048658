import React from 'react';
import { Table } from '../../../components/table/Table';

export const UnthinkableSectionTable = props => {

  const {route: {params}, navigation} = props;
  const {filter, url, website} = params;

  return (
    <Table
      eventSourceId={["section"]}
      api={`/sections`}
      fields={{
        type: 1,
        heading: 1,
        sub_heading: 1,
        description: 1,
        index: 1,
      }}
      sort={{index: 1}}
      filter={filter}
      onRowPress={({ row }) => {
        navigation.navigate('edit-section', { row, page: filter?.page, url, website, readOnly: true});
      }}
      columns={[
        {
          type: 'text',
          field: 'type',
          header: 'Type',
          width: 200,
        },
        {
          type: 'textArea',
          field: 'heading',
          header: 'Heading',
          width: 280,
        },
        {
          type: 'textArea',
          field: 'sub_heading',
          header: 'Sub Heading',
          width: 280,
        },
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
        {
          type: 'number',
          field: 'index',
          header: 'Index',
          width: 120,
        },
      ]}
    />
  );
};
