import React from 'react';
import {Table} from '../../../components/table/Table';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useInvoke} from '../../../controllers/useInvoke';
import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {randomColor} from '../../../utils/util';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import moment from 'moment';
import {Chip} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';

const EMPLOYEE = {
  header: 'Employee',
  render: ({row}) => {
    return (
      <UserAvatarWithNameAndEmail
        name={row?.employee?.name}
        color={row?.employee?.color}
        values={[
          {value: row?.employee?.employee_code},
          {value: row?.employee?.official_email_id},
        ]}
      />
    );
  },
  width: 240,
};

const EXPERIENCE = {
  header: 'Exp.',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.employee?.experience} yrs`}</Text>
    );
  },
  width: 80,
};

const PROJECT = {
  header: 'Project',
  field: 'project.project',
  type: 'colorTag',
  colorField: randomColor,
};

const SKILL = {
  header: 'Skill',
  field: 'employee.resource_language.name',
  type: 'colorTag',
  colorField: randomColor,
};

const PERIOD = {
  header: 'Period',
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={`${row?.quater} ${row?.financial_year?.financial_year}`}
      />
    );
  },
};

const LEADER = {
  header: 'Leader',
  field: 'reporting_to.name',
  type: 'colorTag',
  colorField: 'reporting_to.color',
};

const PRACTICEHEAD = {
  header: 'Practice Head',
  field: 'practice_head.name',
  type: 'colorTag',
  colorField: 'practice_head.color',
};

const OverallRating = ({row = {}}) => {
  const colors = useTheme('colors');
  if (row?.overall_rating) {
    const {overall_rating} = row;
    if (overall_rating == 'A+') {
      return (
        <Chip
          color={colors.SUCCESS_LOW}
          textColor={colors.SUCCESS_HIGH}
          value={'CE'}
          borderRadius={6}
        />
      );
    }
    if (overall_rating == 'A') {
      return (
        <Chip
          color={colors.ACCENT6_LOW}
          textColor={colors.ACCENT6_HIGH}
          value={'OE'}
          borderRadius={6}
        />
      );
    }
    if (overall_rating == 'B+') {
      return (
        <Chip
          color={colors.ACCENT1_LOW}
          textColor={colors.ACCENT1_HIGH}
          value={'ME'}
          borderRadius={6}
        />
      );
    }
    if (overall_rating == 'B') {
      return (
        <Chip
          color={colors.WARNING_LOW}
          textColor={colors.WARNING_HIGH}
          value={'PE'}
          borderRadius={6}
        />
      );
    }
    if (overall_rating == 'C') {
      return (
        <Chip
          color={colors.ERROR_LOW}
          textColor={colors.ERROR_HIGH}
          value={'DE'}
          borderRadius={6}
        />
      );
    }
  }
};

const RATINGWITHSUBMITTED = {
  header: 'Rating',
  render: OverallRating,
  // render: ({row}) => {
  //   return (
  //     <PrimaryColumnCard
  //       primaryTitle={row?.overall_rating}
  //       items={[
  //         {
  //           value:
  //             row?.submitted_on &&
  //             moment(row?.submitted_on).format('DD MMM YY'),
  //         },
  //       ]}
  //     />
  //   );
  // },
};

export const PendingProjectFeedbackTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  const fetchProps = {
    addOnFilter : {
      ...filter, ...addOnFilter
    },
    api: '/employeeprojectfeedback',
  };
  const {user, download} = useAppStateContext();
  return (
    <Table
      eventSourceId={['PendingProjectFeedback']}
      api={'/employeeprojectfeedback'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('project-feedback-detail', {
          row,
          tab,
        })
      }
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      Experience: 'employee.experience',
                      Project: 'project.project',
                      Skill: 'employee.resource_language.name',
                      Quater: 'quater',
                      'Financial yr': 'financial_year.financial_year',
                      Leader: 'reporting_to.name',
                    },
                    filename: 'Pending project feedback(HR)',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        EMPLOYEE, EXPERIENCE, PROJECT, SKILL, PERIOD, LEADER,
        tab && tab === 'HR' && PRACTICEHEAD
      ]}
      moreActions={[
        {
          title: 'Change ReportingTo',
          onPress: ({row}) => {
            navigation.navigate(`update-reporting-manager`, {
              ...params,
              row,
            });
          },
          visible: () => tab && tab === 'HR',
        },
        {
          title: 'Change Practice Head',
          onPress: ({row}) => {
            navigation.navigate(`update-feedback-practice-head`, {
              ...params,
              row,
            });
          },
          visible: () => tab && tab === 'HR',
        },
        {
          title: 'Mark Not Relevant',
          onPress: ({row}) => {
            navigation.navigate(`mark-not-relevant`, {
              ...params,
              row,
            });
          },
          visible: () => tab && tab === 'team',
        },
        {
          title: 'Activity History',
          visible: () => tab && tab === 'HR',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'reporting_to', select: {name: 1}},
              ],
              api: `/employeeprojectfeedbacks/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const NotRelevantProjectFeedbackTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  const fetchProps = {
    addOnFilter : {...filter, ...addOnFilter},
    api: '/employeeprojectfeedback',
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      eventSourceId={['NotRelevantProjectFeedback']}
      api={'/employeeprojectfeedback'}
      addOnFilter={{...filter, ...addOnFilter}}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      Experience: 'employee.experience',
                      Project: 'project.project',
                      Skill: 'employee.resource_language.name',
                      Quater: 'quater',
                      'Financial yr': 'financial_year.financial_year',
                      Leader: 'reporting_to.name',
                      Comment: 'comments',
                    },
                    filename: 'Not relevant approval project feedback(HR)',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        EMPLOYEE,
        EXPERIENCE,
        PROJECT,
        SKILL,
        PERIOD,
        LEADER,
        tab && tab === 'HR' && PRACTICEHEAD,
        {header: 'Comment', field: 'comments'},
      ]}
    />
  );
};

export const InProcessProjectFeedbackTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  const updateInvoke = useInvoke({
    method: 'put',
    eventSourceId: ['InProcessProjectFeedback', 'ProcessedProjectFeedback'],
  });
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['InProcessProjectFeedback', 'ProcessedProjectFeedback'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    api: '/employeeprojectfeedback',
    addOnFilter : {...filter, ...addOnFilter},
  };
  return (
    <Table
      eventSourceId={['InProcessProjectFeedback']}
      api={'/employeeprojectfeedback'}
      addOnFilter={{...filter, ...addOnFilter}}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      Experience: 'employee.experience',
                      Project: 'project.project',
                      Skill: 'employee.resource_language.name',
                      Quater: 'quater',
                      'Financial yr': 'financial_year.financial_year',
                      Leader: 'reporting_to.name',
                      Rating: 'rating_export',
                    },
                    filename: 'Pending approval project feedback(HR)',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        EMPLOYEE,
        EXPERIENCE,
        PROJECT,
        SKILL,
        PERIOD,
        LEADER,
        RATINGWITHSUBMITTED,
        tab && tab === 'HR' && PRACTICEHEAD
      ]}
      onRowPress={({row}) =>
        navigation.navigate('project-feedback-detail', {
          row,
        })
      }
      moreActions={[
        {
          title: 'Approve',
          confirm: {
            title: 'Approve Feedback',
            message: 'Do you want to Approve this Feedback?',
          },
          visible: () => tab && tab === 'Practice',
          onPress: props => {
            const {row} = props;
            updateInvoke({
              uri: `/employeeprojectfeedbacks/${row?._id}`,
              message: 'Feedback Approved Successfully',
              props: {
                is_processed: true,
                feedback_approved_date: new Date(),
                is_project_manager_feedback_given: true,
                project_manager_feedback_submitted_on: getZeroTimeDate(
                  new Date(),
                ),
              },
            });
          },
        },
        {
          title: 'Change Practice Head',
          onPress: ({row}) => {
            navigation.navigate(`update-feedback-practice-head`, {
              ...params,
              row,
            });
          },
          visible: () => tab && tab === 'HR',
        },
        {
          title: 'Reject',
          confirm: {
            title: 'Reject Feedback',
            message: 'Do you want to Reject this Feedback?',
          },
          visible: () => tab && tab === 'Practice',
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/sendmailonfeedbackrejection`,
              message: 'Feedback Rejected Successfully',
              props: {
                data: row,
              },
            });
          },
        },
        {
          title: 'Activity History',
          visible: () => tab && tab === 'HR',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'reporting_to', select: {name: 1}},
              ],
              api: `/employeeprojectfeedbacks/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const ProcessedProjectFeedbackTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter,tab} = props;
  const fetchProps = {
    api: '/employeeprojectfeedback',
    addOnFilter : {...filter, ...addOnFilter},
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      eventSourceId={['ProcessedProjectFeedback']}
      api={'/employeeprojectfeedback'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('project-feedback-detail', {
          row,
          tab: 'Processed',
        })
      }
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Email: 'employee.official_email_id',
                      Experience: 'employee.experience',
                      Project: 'project.project',
                      Skill: 'employee.resource_language.name',
                      Quater: 'quater',
                      'Financial yr': 'financial_year.financial_year',
                      Leader: 'reporting_to.name',
                      Rating: 'rating_export',
                    },
                    filename: 'Closed approval project feedback(HR)',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        EMPLOYEE,
        EXPERIENCE,
        PROJECT,
        SKILL,
        PERIOD,
        LEADER,
        RATINGWITHSUBMITTED,
        tab && tab === 'HR' && PRACTICEHEAD
      ]}
    />
  );
};
