import React from 'react';
import {Table} from '../../../components/table/Table';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useTheme} from '@react-navigation/native';
import {TabView} from '../../../components/tab';
import {randomColor} from '../../../utils/util';
import {AllITAssetTable} from './NewITAssetTable';
import {View, Image} from '@unthinkable/react-core-components';
const JsBarcode = require('jsbarcode');
const {createCanvas} = require('canvas');

const RegisterITAssetTable = props => {
  const {route: {params = {}} = {}, searchValue} = props;
  const color = useTheme('colors');
  return (
    <Table
      eventSourceId={['AssetRegister']}
      search={searchValue}
      searchFields="code"
      api={`/register-it-assets`}
      columns={[
        {
          header: 'Purchase',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Location',
          field: 'location.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Asset type & Code',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.asset_type}
                items={[{value: row?.code}]}
              />
            );
          },
        },
        {
          header: 'Brand',
          field: 'brand',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Model',
          field: 'model',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Specification',
          field: 'specification',
          type: 'colorTag',
          colorField: randomColor,
        },
      ]}
    />
  );
};

const RegisterITAssetInventoryTable = props => {
  const {route: {params = {}} = {}, searchValue} = props;
  const color = useTheme('colors');
  return (
    <Table
      eventSourceId={['AssetRegister']}
      search={searchValue}
      searchFields="code"
      api={`/register-it-assets-inventory`}
      columns={[
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Asset code',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.code}
                // items={[{value: row?.asset_type}]}
              />
            );
          },
        },
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {
          header: 'Specification',
          field: 'specification',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Location',
          field: 'location.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        // {
        //   header: 'Status',
        //   align: 'center',
        //   width: 180,
        //   render: ({row}) => {
        //     return (
        //       <PrimaryColumnCard
        //         primaryTitle={row?.asset_status}
        //         items={[{value: row?.status}]}
        //       />
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const RegisterITAssetsTab = props => {
  const {route: {params = {}} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const tabs = {
    assets: {
      label: 'Assets',
      view: (
        <RegisterITAssetTable
          addOnFilter={{
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          tab="Assets"
          {...props}
        />
      ),
      api: '/register-it-assets',
      addOnFilter: {
        ...filterValues.filter,
      },
      search: searchValue,
      searchFields: 'code',
    },

    assets_inventory: {
      label: 'Assets Inventory',
      view: (
        <RegisterITAssetInventoryTable
          addOnFilter={{...filterValues.filter}}
          tab="Assets Inventory"
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/register-it-assets-inventory',
      addOnFilter: {
        ...filterValues.filter,
      },
      search: searchValue,
      searchFields: 'code',
    },
    deleted: {
      label: 'Deleted',
      view: (
        <AllITAssetTable
          tab={'deleted'}
          addOnFilter={{
            ...filterValues.filter,
            // asset_type: 'Ready To Use',
            status: 'Deleted',
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'ActiveITAsset',
      api: '/all-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filterValues.filter,
        // asset_type: 'Ready To Use',
        status: 'Deleted',
      },
      limit: 1000,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Code"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Payee',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'autoComplete',
              field: 'department',
              label: 'Department',
              placeholder: 'Select',
              api: '/activeDepartmentSuggestions',
              valueField: 'name',
              suggestionField: 'name',
            },
            {
              type: 'autoComplete',
              field: 'location',
              label: 'Location',
              placeholder: 'Select',
              api: '/offices',
              valueField: 'name',
              suggestionField: 'name',
            },
            {
              type: 'autoComplete',
              field: 'account',
              label: 'Account',
              placeholder: 'Select',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
            },
            {
              type: 'autoComplete',
              field: 'vendor_invoice',
              label: 'Invoice Number',
              placeholder: 'Select',
              api: '/vendorInvoices/all',
              valueField: 'invoice_no',
              suggestionField: 'invoice_no',
            },
            {
              type: 'date',
              field: 'capitalization_date',
              label: 'Capitalization Date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
