import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { useInvoke } from '../../../controllers/useInvoke';

const pageCountRender = ({ navigation }) => ({
    render: CountCellRender,
    header: 'Pages',
    align: 'center',
    count_field: 'pagesCount',
    responsive: 'sm',
    onPress: props => {
        const { row } = props;
        navigation.navigate(`page-menu`, {
            row
        });
    },
    width: 150,
});


export const WebsiteTable = () => {
    const navigation = useNavigation();

    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'website',
    });

    return (
        <Table
            eventSourceId={["website"]}
            api={`/websites`}
            fields={{
                domain: 1,
                alternate_domains: 1,
                sandbox_domain: 1,
                no_index: 1,
                pagesCount: {
                    _id: 1,
                },
            }}
            sort={{ domain: 1 }}
            renderHeader={() => (
                <TableHeader
                    title="Websites"
                    actions={[
                        <AddButton
                            title="Add Website"
                            view="add-website"
                        />,
                    ]}
                />
            )}
            onRowPress={({ row }) => {
                navigation.navigate('edit-website', { row });
            }}
            columns={[
                {
                    type: 'text',
                    field: 'domain',
                    header: 'Domain',
                },
                {
                    type: 'text',
                    field: 'sandbox_domain',
                    header: 'Sandbox Domain',
                },
                {
                    type: 'textArea',
                    field: 'alternate_domains',
                    header: 'Alternate Domains',
                },
                {
                    type: 'boolean',
                    field: 'no_index',
                    header: 'No Index',
                    width: 100,
                },
                pageCountRender({ navigation }),
            ]}
            moreActions={() => [
                {
                    title: 'Edit',
                    onPress: ({ row }) => {
                        navigation.navigate('edit-website', { row });
                    },
                },
                {
                    title: 'Move Sandbox to live',
                    confirm: {
                        title: 'Move Sandbox to live',
                        message: 'Are you sure you want to move sandbox to live?',
                    },
                    onPress: ({ row }) => {
                        invoke({
                            uri: `/copyWebsiteData`,
                            props: {
                                row,
                            },
                        });
                    },
                }
            ]}
        />
    );
}; 