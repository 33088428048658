import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import moment from 'moment';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {Confirm} from '../../../components/confirm/Confirm';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
const RenderDesignationAndTeam = ({row, ...rest}) => {
  const {fonts, colors} = useTheme();
  return (
    <View>
      <Text
        title={row?.position_title?.name}
        style={{
          ...rest.styles.text,
          numberOfLines: 1,
        }}>
        {row?.position_title?.name}
      </Text>
      <Text
        title={row?.department?.name}
        style={{
          ...fonts.BODY2,
          color: colors.NEUTRAL_MEDIUM,
          numberOfLines: 1,
        }}>
        {row?.department?.name}
      </Text>
    </View>
  );
};

const RenderPriority = ({row}) => {
  const {priority} = row;
  const priorityWiseColors = {
    Urgent: 'ERROR',
    High: 'Warning',
    Medium: 'ACCENT5',
    Low: 'SUCCESS',
  };
  return (
    <Tag
      value={priority}
      color={priorityWiseColors[priority]}
      textColor={priorityWiseColors[priority]}
      displayTextColor
    />
  );
};

const RenderStatus = ({row, ...rest}) => {
  const {status, approved_on, rejected_on} = row;
  const statusWiseColors = {
    Approved: 'SUCCESS',
    Rejected: 'ERROR',
  };
  const concernedDate = approved_on || rejected_on;
  const {LOW, HIGH} = useAccentColor(statusWiseColors[status]);
  return (
    <View
      style={{
        backgroundColor: LOW,
        padding: 12,
        margin: -12,
        width: 100,
      }}>
      <Text style={{...rest.styles.text, color: HIGH}}>{row?.status}</Text>
      <GroupContentItem
        {...rest}
        value={concernedDate ? moment(concernedDate).format('DD MMM YY') : '-'}
      />
    </View>
  );
};

const ManPowerRequisitionColumns = [
  {
    header: 'Request',
    field: 'requested_on',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
  },
  {
    header: 'Requested By',
    field: 'requested_by',
    render: UserAvatarChip,
  },
  {
    header: 'Position',
    render: RenderDesignationAndTeam,
  },
  {
    header: 'Exp.',
    field: 'experience_required.experience',
    type: 'text',
  },
  {
    header: 'Skill',
    field: 'must_have_skills',
    render: MultiColorTag,
    labelField: 'name',
    colorField: 'color',
  },
  {
    header: 'Priority',
    field: 'priority',
    render: RenderPriority,
  },
  {
    field: 'no_of_positions',
    header: 'Positions',
    type: 'number',
    width: 150,
  },
];

const RenderApproval = ({row, styles, navigation, invoke}) => {
  const {ERROR_HIGH, SUCCESS_HIGH, OUTLINE} = useTheme('colors');
  return (
    <Row gap={8}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(`approve-with-salary-man-power-requisition`, {
            mrf: row?._id,
          });
        }}>
        <Text
          style={{
            ...styles.text,
            color: SUCCESS_HIGH,
          }}>
          Approve
        </Text>
      </TouchableOpacity>
      <View
        style={{
          width: 1,
          backgroundColor: OUTLINE,
        }}
      />
      <Confirm
        title={'Reject Talent Requisition Request'}
        message={'Do you want to reject this Talent Requisition Request?'}
        variant={'error'}
        confirmText="Reject"
        onConfirm={() => {
          invoke({
            uri: `/manpowerrequisitions/${row?._id}`,
            props: {
              status: 'Rejected',
            },
          });
        }}>
        <Text
          style={{
            ...styles.text,
            color: ERROR_HIGH,
          }}>
          Reject
        </Text>
      </Confirm>
    </Row>
  );
};

export const MrfApproverPendingTable = props => {
  const {navigation} = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'MrfApproverPending',
      'MrfApproverApproved',
      'MrfApproverRejected',
    ],
  });

  return (
    <Table
      eventSourceId="MrfApproverPending"
      api={`/manpowerrequisitions`}
      filter={{
        status: 'Pending',
      }}
      fields={{
        _id: 1,
        position_title: {
          name: 1,
        },
        no_of_positions: 1,
        position_type: 1,
        location: 1,
        priority: 1,
        salary_offered: 1,
        department: {_id: 1, name: 1},
        hiring_manager: {_id: 1, name: 1, color: 1},
        requested_on: 1,
        experience_required: {
          experience: 1,
        },
        must_have_skills: {
          name: 1,
          color: 1,
        },
        approved_on: 1,
        requested_by: {
          name: 1,
          color: 1,
        },
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-mrf`, {
          mrf: row?._id,
        });
      }}
      columns={[
        ...ManPowerRequisitionColumns,
        {
          render: RenderApproval,
          navigation,
          invoke,
          width: 160,
          align: 'center',
          header: 'Action',
        },
      ]}
    />
  );
};

export const MrfApproverApprovedTable = props => {
  return (
    <Table
      api={`/manpowerrequisitions`}
      filter={{
        status: {
          $in: ['Approved', 'Rejected', 'Processed'],
        },
      }}
      fields={{
        _id: 1,
        position_title: {
          name: 1,
        },
        no_of_positions: 1,
        position_type: 1,
        location: 1,
        priority: 1,
        salary_offered: 1,
        department: {_id: 1, name: 1},
        hiring_manager: {_id: 1, name: 1, color: 1},
        requested_on: 1,
        experience_required: {
          experience: 1,
        },
        must_have_skills: {
          name: 1,
          color: 1,
        },
        approved_on: 1,
        status: 1,
        requested_by: {
          name: 1,
          color: 1,
        },
        rejected_on: 1,
      }}
      columns={[
        ...ManPowerRequisitionColumns,
        {
          render: RenderStatus,
          header: 'Status',
          align: 'center',
        },
      ]}
    />
  );
};
