import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeTaskComplexityForm = props => {
  const {
    route: {params},
  } = props;

  const {row, secondaryTitle} = params;

  const {onSubmit} = useFormSubmit({
    uri: `/projecttasks`,
    eventSourceId: ['Task'],
    ...props,
  });

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        complexity_id: row?.complexity_id,
      }}
      header={{
        title: `Update Complexity`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Complexity',
          type: 'autoComplete',
          field: 'complexity_id',
          api: '/projectcomplexities',
          suggestionField: 'complexity_type',
          secondarySuggestionField: 'point',
          valueField: 'complexity_type',
        },
      ]}
      {...props}
    />
  );
};
