import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const LegalCase = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false, orgView} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/legalcases',
    eventSourceId: 'legal',
  });
  let defaultValues = {};
  const {user, fetch} = useAppStateContext();

  return (
    <Form
      api={`/legalcases/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        case_status: 'OnGoing',
        created_on: new Date(),
        created_by: user.employee?._id,
        case_stage: row?.case_stage,
        // case_stage: {
        //   _id: row?.case_stage?._id,
        //   stage: row?.case_stage?.stage,
        // },
      }}
      fields={{
        case_number: 1,
        plaintiff_complainant: 1,
        defendant_accused: 1,
        year_of_filing: 1,
        next_date_of_hearing: 1,
        amount_involved: 1,
        case_stage: {stage: 1},
        closed_date: 1,
        case_status: 1,
        case_brief_link: 1,
        plan_of_action: 1,
        last_order: 1,
        current_status: 1,
        court_room: 1,
        next_date_of_hearing: 1,
        last_date_of_hearing: 1,
        case_type: {case_type: 1},
        client: {client: 1},
        jurisdiction: {jurisdiction: 1},
      }}
      readOnly={readOnly}
      submitAction="Save"
      eventSourceId="legal"
      layoutFields={[
        {
          label: 'Case details',
          fields: [
            {
              label: 'Case Number',
              type: 'text',
              field: 'case_number',
              size: 6,
            },
            {
              label: 'Year of Filing',
              type: 'text',
              field: 'year_of_filing',
              size: 6,
              required: true,
            },
            {
              label: 'Jurisdiction',
              type: 'autoComplete',
              field: 'jurisdiction',
              api: `/jurisdictions`,
              required: true,
              suggestionField: 'jurisdiction',
              valueField: 'jurisdiction',
              size: 4,
            },
            {
              label: 'Court Room',
              type: 'text',
              field: 'court_room',
              size: 4,
            },

            {
              label: 'Employee/ Client/ Vendor',
              field: 'client',
              type: 'autoComplete',
              api: '/legalclients',
              suggestionField: 'client',
              valueField: 'client',
              required: true,
              size: 4,
            },
            {
              label: 'Plaintiff/ Complainant',
              type: 'text',
              field: 'plaintiff_complainant',
              size: 6,
              required: true,
            },
            {
              label: 'Defendant/ Accused',
              type: 'text',
              field: 'defendant_accused',
              size: 6,
              required: true,
            },
            {
              label: 'Last Hearing',
              field: 'last_date_of_hearing',
              type: 'date',
              size: 6,
            },
            {
              label: 'Current Status',
              field: 'current_status',
              type: 'text',
              size: 6,
            },
            {
              label: 'Next Hearing',
              field: 'next_date_of_hearing',
              type: 'date',
              size: 6,
            },

            {
              label: 'Plan of Action',
              field: 'plan_of_action',
              type: 'textArea',
              size: 6,
            },

            {
              label: 'Case Type',
              field: 'case_type',
              type: 'autoComplete',
              api: '/casetypes',
              suggestionField: 'case_type',
              valueField: 'case_type',
              required: true,
              size: 6,
            },
            {
              label: 'Amount Involved',
              type: 'number',
              field: 'amount_involved',
              size: 6,
            },

            {
              label: 'Last Order',
              field: 'last_order',
              type: 'textArea',
            },

            {
              label: 'Case Brief Link',
              field: 'case_brief_link',
              type: 'textArea',
              size: 6,
            },
            {
              label: 'Stage',
              field: 'case_stage',
              type: 'autoComplete',
              api: '/legalcasestages',
              suggestionField: 'stage',
              valueField: 'stage',
              required: true,
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const LegalCaseProceedings = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false, orgView} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/legalcases',
    eventSourceId: 'legal',
  });
  const {user} = useAppStateContext();

  return (
    <Form
      api={`/legalcases/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        case_status: 'OnGoing',
        created_on: new Date(),
        created_by: user.employee?._id,
        case_stage: row?.case_stage,
      }}
      fields={{
        current_status: 1,
        next_date_of_hearing: 1,
        last_date_of_hearing: 1,
        plan_of_action: 1,
        case_type: {case_type: 1},
        last_order: 1,
      }}
      readOnly={readOnly}
      submitAction="Save"
      eventSourceId="legal"
      layoutFields={[
        {
          label: 'Case details',
          fields: [
            {
              label: 'Last Hearing',
              field: 'last_date_of_hearing',
              type: 'date',
              size: 6,
            },
            {
              label: 'Current Status',
              field: 'current_status',
              type: 'text',
              size: 6,
            },
            {
              label: 'Next Hearing',
              field: 'next_date_of_hearing',
              type: 'date',
              size: 6,
            },

            {
              label: 'Plan of Action',
              field: 'plan_of_action',
              type: 'textArea',
              size: 6,
            },

            {
              label: 'Last Order',
              field: 'last_order',
              type: 'textArea',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddLegalCase = props => {
  return <LegalCase header="Add Legal Case" {...props} />;
};

export const EditLegalCase = props => {
  return <LegalCase mode="edit" header={'Legal Case'} {...props} />;
};

export const EditLegalCaseProceedings = props => {
  return <LegalCaseProceedings mode="edit" header={'Legal Case'} {...props} />;
};

const Jurisdiction = props => {
  const {
    route: {params},
  } = props;
  const {jurisdiction} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/jurisdictions',
    eventSourceId: 'legal',
  });

  return (
    <Form
      api={`/jurisdictions/`}
      defaultValues={{
        _id: jurisdiction,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'jurisdiction',
          label: 'Jurisdiction',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditJurisdiction = props => {
  return <Jurisdiction mode="edit" header="Update Jurisdiction" {...props} />;
};
export const AddJurisdiction = props => {
  return <Jurisdiction header="Add Jurisdiction" {...props} />;
};

const LegalClient = props => {
  const {
    route: {params},
  } = props;
  const {client} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/legalclients',
    eventSourceId: 'legal',
  });

  return (
    <Form
      api={`/legalclients`}
      defaultValues={{
        _id: client,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'client',
          label: 'Client',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditLegalClient = props => {
  return <LegalClient mode="edit" header="Update Client" {...props} />;
};
export const AddLegalClient = props => {
  return <LegalClient header="Add Client" {...props} />;
};

const CaseType = props => {
  const {
    route: {params},
  } = props;
  const {case_type} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/casetypes',
    eventSourceId: 'legal',
  });

  return (
    <Form
      api={`/casetypes`}
      defaultValues={{
        _id: case_type,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'case_type',
          label: 'Case Type',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditCaseType = props => {
  return <CaseType mode="edit" header="Update Case Type" {...props} />;
};
export const AddCaseType = props => {
  return <CaseType header="Add Case Type" {...props} />;
};

const CaseStage = props => {
  const {
    route: {params},
  } = props;
  const {stage} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/legalcasestages',
    eventSourceId: 'legal',
  });

  return (
    <Form
      api={`/legalcasestages`}
      defaultValues={{
        _id: stage,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'stage',
          label: 'Case Stage',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditCaseStage = props => {
  return <CaseStage mode="edit" header="Update Case Stage" {...props} />;
};
export const AddCaseStage = props => {
  return <CaseStage header="Add Case Stage" {...props} />;
};

export const UpdateCaseStatus = props => {
  const params = props?.route?.params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'legal',
    uri: '/legalcases',
    ...props,
  });
  const {legalcase} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Status'}
      submitAction={'Save'}
      defaultValues={{
        ...legalcase,
        closed_date: new Date(),
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          label: 'Status',
          field: 'case_status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['Settled', 'Write-Off', 'Decided', 'Withdrawn'],
        },
      ]}
    />
  );
};
