import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab';
import {VendorPaymentTable} from './VendorPaymentTables';
import {AddButton} from '../../../components/button/AddButton';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const PaymentTabs = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    All: {
      label: 'All',
      view: (
        <VendorPaymentTable
          filterValues={filterValues}
          searchValue={searchValue}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpayments',
              props: {
                addOnFilter: {...filterValues.filter},
              },
            });
          }}
        />,
      ],
      search: searchValue,
      searchFields: 'payment_no',
      api: `/payments`,
      addOnFilter: {...filterValues.filter},
    },
    Bill: {
      label: 'Bill',
      view: (
        <VendorPaymentTable
          searchValue={searchValue}
          filterValues={filterValues}
          addOnFilter={{payment_type: 'Bill'}}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpayments',
              props: {
                addOnFilter: {...filterValues.filter, payment_type: 'Bill'},
              },
            });
          }}
        />,
      ],
      search: searchValue,
      searchFields: 'payment_no',
      api: `/payments`,
      addOnFilter: {
        ...filterValues.filter,
        payment_type: 'Bill',
      },
    },
    Advance: {
      label: 'Advance',
      view: (
        <VendorPaymentTable
          searchValue={searchValue}
          filterValues={filterValues}
          addOnFilter={{
            payment_type: 'Prepayment',
          }}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpayments',
              props: {
                addOnFilter: {
                  ...filterValues.filter,
                  payment_type: 'Prepayment',
                },
              },
            });
          }}
        />,
      ],
      search: searchValue,
      searchFields: 'payment_no',
      api: `/payments`,
      addOnFilter: {
        ...filterValues.filter,
        payment_type: 'Prepayment',
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Payee',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
              titleField: 'name',
            },
            {
              type: 'date',
              field: 'payment_date',
              label: 'Payment Date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Payment type',
              options: [
                {label: 'Pre-payment', value: 'Prepayment'},
                {label: 'Post-payment', value: 'Bill'},
                {label: 'Loan', value: 'Loan'},
                {label: 'Direct', value: 'Direct'},
                {label: 'Other', value: 'Other'},
              ],
              placeholder: 'Select',
              field: 'payment_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/categories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            {
              type: 'multiAutoComplete',
              label: 'Bank',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'fund_center',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Tax Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
        <AddButton title="Add Payment" view={'add-payment-form'} />,
      ]}
    />
  );
};
