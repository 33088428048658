import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  BonusSalaryTable,
  CompensationStructureSalaryTable,
  FixedSalaryTable,
  IcIncentiveFixedBillingTable,
  IcIncentiveTMBillingTable,
  IncentiveSalaryTable,
  KPIIncentiveDetailAuditTable,
  KPIIncentiveDetailFixedTable,
  KPIIncentiveDetailRFQTable,
  KPIIncentiveDetailRFRTable,
  KPIIncentiveDetailTMTable,
  SelfFixedBillingTable,
  SelfTMBillingTable,
} from './EmployeeSalaryTable';
import {TaxDeclarationTabs} from './EmployeeTaxDeclarationTabs';

export const EmployeeSalaryTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});

  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    Fixed: {
      label: 'Fixed',
      view: <FixedSalaryTable filter={filter} {...props} />,
      eventSourceId: 'fixedSalary',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    Incentive: {
      label: 'Incentive',
      view: <IncentiveSalaryTable filter={filter} {...props} />,
      eventSourceId: 'incentiveSalary',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    Bonus: {
      label: 'Bonus',
      view: <BonusSalaryTable filter={filter} {...props} />,
      eventSourceId: 'bonusSalary',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    shiftAllowance: {
      label: 'Shift Allowance',
      // view:,
      eventSourceId: 'shiftallowance',
    },
    compensationStructure: {
      label: 'Compensation Structure',
      view: <CompensationStructureSalaryTable filter={filter} {...props} />,
      eventSourceId: 'compensationstructure',
    },
    TaxDeclaration: {
      label: 'Tax Declaration',
      view: <TaxDeclarationTabs filter={filter} {...props} />,
      eventSourceId: 'taxDeclaration',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const EmployeeSelfLevelTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const tabs = {
    TM: {
      label: 'T & M Billing',
      view: (
        <SelfTMBillingTable filterParams={filter} period={period} {...props} />
      ),
      eventSourceId: 't&m',
      addOnFilter: {...filter},
      params: params,

      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ],
    },
    fixed: {
      label: 'Fixed',
      view: (
        <SelfFixedBillingTable
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ],
      eventSourceId: 'fixed-selfl1l2l3',
      params: params,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const EmployeeIncentiveSalaryTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const tabs = {
    TM: {
      label: 'T & M Billing',
      view: (
        <IcIncentiveTMBillingTable
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 't&m',
      // api: '/allocatedList',
      addOnFilter: {...filter},
      params: params,
    },
    fixed: {
      label: 'Fixed',
      view: (
        <IcIncentiveFixedBillingTable
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      params: params,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const EmployeeRfrVariableTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {
    ...params,
    row: {period: params?.period, user: {employee: {_id: params?.skillLeader}}},
  };

  const tabs = {
    RFR: {
      label: 'RFR',
      view: (
        <KPIIncentiveDetailRFRTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 't&m',
      addOnFilter: {...filter},
      params: params,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const EmployeeRfqVariableTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {
    ...params,
    row: {period: params?.period, user: {employee: {_id: params?.skillLeader}}},
  };

  const tabs = {
    RFQ: {
      label: 'RFQ New',
      view: (
        <KPIIncentiveDetailRFQTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      addOnFilter: {...filter},
      params: params,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const EmployeeKPIIncentiveSalaryDetailTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const tabs = {
    RFR: {
      label: 'RFR',
      view: (
        <KPIIncentiveDetailRFRTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 't&m',
      addOnFilter: {...filter},
      params: params,
    },
    RFQ: {
      label: 'RFQ New',
      view: (
        <KPIIncentiveDetailRFQTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      addOnFilter: {...filter},
      params: params,
    },
    TM: {
      label: 'T&M',
      view: (
        <KPIIncentiveDetailTMTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      params: params,
    },
    FixedNew: {
      label: 'Fixed New',
      view: (
        <KPIIncentiveDetailFixedTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      params: params,
    },
    Audit: {
      label: 'Audit',
      view: (
        <KPIIncentiveDetailAuditTable
          params={params}
          filterParams={filter}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'fixed-selfl1l2l3',
      params: params,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
