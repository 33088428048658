import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const ratingParametersNew = [
    {
        field: "delivery_quality",
        value: 25
    },
    {
        field: "communucations_rating",
        value: 25
    },
    {
        field: "attitude",
        value: 25
    },
    {
        field: "punctuality_rating",
        value: 25
    },
]

const ratingCalculationDataNew = [
    {
        rating: "A+",
        rating_percentage: 100
    },
    {
        rating: "A",
        rating_percentage: 80
    },
    {
        rating: "B+",
        rating_percentage: 60
    },
    {
        rating: "B",
        rating_percentage: 40
    },
    {
        rating: "C",
        rating_percentage: 20
    },
    {
        rating: "NA",
        rating_percentage: 0
    },

]

const projectfeedbackComputation = fetch => ({
    "set overall rating": {
        compute: async (value) => {
            let overallRating = 0
            for (const ratingData of ratingParametersNew) {
                const { field, value: score } = ratingData
                if (value && value.hasOwnProperty(field)) {
                    for (const ratingCal of ratingCalculationDataNew) {
                        const { rating, rating_percentage } = ratingCal;
                        if (rating === value[field]) {
                            overallRating = overallRating + (score * rating_percentage) / 100
                        }
                    }
                }
            }
            let avgOverallRating = (overallRating && overallRating / 100) * 5 || 0;
            if (avgOverallRating > 5) {
                avgOverallRating = 5
            }
            let grade = ""
            if (4.5 < avgOverallRating && avgOverallRating <= 5) {
                grade = "A+"
            }
            else if (3.5 < avgOverallRating && avgOverallRating <= 4.5) {
                grade = "A"
            }
            else if (2.5 < avgOverallRating && avgOverallRating <= 3.5) {
                grade = "B+"
            }
            else if (1.5 < avgOverallRating && avgOverallRating <= 2.5) {
                grade = "B"
            }
            else if (avgOverallRating <= 1.5) {
                grade = "C"
            }
            return { overall_rating: grade };
        },
        multi: true,
        dependencies: [
            "delivery_quality",
            "communucations_rating",
            "attitude",
            "punctuality_rating",
        ]
    }
})


export const EmployeeProjectFeedbackForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/employeeprojectfeedbacks',
        eventSourceId: ['PendingProjectFeedback', 'InProcessProjectFeedback'],
        ...props,
    });
    const defaultValues = {
        _id: row?._id,
        to_be_approved: true,
    }
    const { fetch } = useAppStateContext();

    return (
        <Form
            api={`/employeeprojectfeedback/${row?._id}`}
            defaultValues={{ ...defaultValues }}
            beforeSubmit={({ data }) => {
                return {
                    data: {
                        ...data,
                        to_be_approved: true
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction={'Save'}
            mode="edit"
            computations={{ ...projectfeedbackComputation(fetch) }}
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            readOnly: true,
                            size: 6
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Experience',
                            label: 'Experience',
                            field: 'employee.experience',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'People Manager',
                            label: 'People Manager',
                            field: 'employee.functional_manager.name',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Due Date',
                            label: 'Appraisal Due Date',
                            field: 'assesment_id.effective_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Closure Date',
                            label: 'Appraisal Closure Date',
                            field: 'assesment_id.closed_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'Project Manager Feedback',
                    fields: [
                        {
                            placeholder: 'Manager',
                            label: 'Manager',
                            field: "reporting_to.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project',
                            label: 'Project',
                            field: "project.project",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project Start Date',
                            label: 'Project Start Date',
                            field: 'project_start_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project End Date',
                            label: 'Project End Date',
                            field: 'project_end_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'Overall Rating',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'overall_rating',
                            required: true,
                            editable: false,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                    ],
                },
                {
                    label: 'Delivery Quality',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'delivery_quality',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "delivery_quality_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Communication',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'communucations_rating',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "communication_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Cultural & Behavioural',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'punctuality_rating',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "behavioural_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Learning & Sharing',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'attitude',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "learning_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    fields: [
                        {
                            field: 'technology',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                        {
                            field: "comments",
                            type: "textArea",
                            label: "Comments",
                            required: true,
                            size: 6,
                        }
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const EmployeeProjectFeedback = props => {
    let {
        route: { params },
    } = props;
    const { row, tab } = params;
    console.log("tab>>>>>>>>>",tab)
    let readOnly = false;
    if (tab === "HR" || tab === "RM" || tab === "Processed") {
        readOnly = true
    }
    return (
        <EmployeeProjectFeedbackForm
            header={{
                title: 'Edit Employee Feedback'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};


export const EmployeeSelfProjectFeedbackForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;

    return (
        <Form
            api={`/employeeprojectfeedback/${row?._id}`}
            mode="edit"
            layoutFields={[
                {
                    label: 'Project Manager Feedback',
                    fields: [
                        {
                            placeholder: 'Manager',
                            label: 'Manager',
                            field: "reporting_to.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project',
                            label: 'Project',
                            field: "project.project",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project Start Date',
                            label: 'Project Start Date',
                            field: 'project_start_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Project End Date',
                            label: 'Project End Date',
                            field: 'project_end_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'Overall Rating',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'overall_rating',
                            required: true,
                            editable: false,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                    ],
                },
                {
                    label: 'Delivery Quality',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'delivery_quality',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "delivery_quality_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Communication',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'communucations_rating',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "communication_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Cultural & Behavioural',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'punctuality_rating',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "behavioural_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    label: 'Learning & Sharing',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'attitude',
                            required: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'Consistently Exceeds Expectation',
                                },
                                {
                                    value: "A",
                                    label: 'Occasionally Exceeds Expectation',
                                },
                                {
                                    value: "B+",
                                    label: 'Meet Expectation',
                                },
                                {
                                    value: "B",
                                    label: 'Partially Meets Expectation',
                                },
                                {
                                    value: "C",
                                    label: 'Does Not Meet Expectation',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: "learning_reason",
                            type: "textArea",
                            label: "Reason",
                            required: true
                        }
                    ],
                },
                {
                    fields: [
                        {
                            field: 'technology',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                        {
                            field: "comments",
                            type: "textArea",
                            label: "Comments",
                            required: true,
                            size: 6,
                        }
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const EmployeeSelfProjectFeedback = props => {
    let {
        route: { params },
    } = props;
    let readOnly = true
    return (
        <EmployeeProjectFeedbackForm
            header={{
                title: 'Edit Employee Feedback'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};


export const ChangeReportingToForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/employeeprojectfeedbacks`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/employeeprojectfeedbacks/${row?._id}`}
            fields={
                { reporting_to: { name: 1 } }
            }
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    field: 'reporting_to',
                    api: '/employeeSuggestions',
                    type: 'autoComplete',
                    label: 'Select Reporting To',
                    searchField: 'name',
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    required: true,
                    size: 4,
                },
            ]}
            {...props}
        />
    );
};

export const ChangeReportingTo = props => {
    return <ChangeReportingToForm header="Update Reporting To" {...props} />;
};


export const ChangeFeedbackPracticeHeadForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/employeeprojectfeedbacks`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/employeeprojectfeedbacks/${row?._id}`}
            fields={
                { practice_head: { name: 1 } }
            }
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    field: 'practice_head',
                    api: '/employeeSuggestions',
                    type: 'autoComplete',
                    label: 'Select Practice Head',
                    searchField: 'name',
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    required: true,
                    size: 4,
                },
            ]}
            {...props}
        />
    );
};

export const ChangeFeedbackPracticeHead = props => {
    return <ChangeFeedbackPracticeHeadForm header="Update Practice Head" {...props} />;
};

export const MarkFeedbackNotRelevantForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/employeeprojectfeedbacks`,
        eventSourceId: ['PendingProjectFeedback','NotRelevantProjectFeedback'],
    });
    return (
        <Form
            api={`/employeeprojectfeedbacks/${row?._id}`}
            fields={
                { reporting_to: { name: 1 } }
            }
            beforeSubmit = {({data}) => {
                return {
                    data: {
                        is_not_relevent: true,
                        ...data,
                    }
                };
            }}
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'textArea',
                    field: 'not_relevent_reason',
                    label: 'Reason',
                    header: "Reason",
                    required: true
                },
            ]}
            {...props}
        />
    );
};

export const MarkFeedbackNotRelevant = props => {
    return <MarkFeedbackNotRelevantForm header="Mark Not Relevant" {...props} />;
};