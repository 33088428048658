import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const manualVariableComputations = fetch => ({
  'set self hrs from percentage': {
    compute: async value => {
        const hours = value?.self_percent && (value?.self_percent * 190) / 100 || 0
        return {
          self_achievement: hours
        };
    },
    multi: true,
    dependencies: ['self_percent'],
  },
});

export const ManualVariable = props => {
  const {route: {params} = {}} = props;
  const {user} = useAppStateContext();
  const {employee = {}} = user;
  const {department_access = []} = employee;
  let department = [];
  if(department_access?.length){
    department = department_access.map((dep) => dep._id)
  }
  const {row , fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/variablesalaries',
    eventSourceId: 'manualVariable',
  });
  return (
    <Form
      api={`/manualVariable/setup/${row?._id}`}
      onSubmit={onSubmit}
      computations={{...manualVariableComputations()}}
      submitAction="Save"
      defaultValues={
        {
          status : "pending"
        }
      }
      layoutFields={[
        {
          label: 'Employee',
          type: 'autoComplete',
          field: 'employee_id',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          size: 6,
          filter : ({}) => {
            const filter = {
              employee_status : ['Active' , 'Inactive']
            };
            if(!fromHr){
              filter.department = department,
              filter.employee_status = 'Active'
            }
            return {
              ...filter
           }
          }
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Self Percent',
          field: 'self_percent',
          type: 'number',
          size: 6,
        },
        {
          label: 'Self Hours',
          field: 'self_achievement',
          type: 'number',
          size: 6,
          readOnly : !fromHr
        },
        {
          label: 'Level 1',
          field: 'level1_achievement',
          type: 'number',
          size: 6,
          visible : fromHr
        },
        {
          label: 'Level 2',
          field: 'level2_achievement',
          type: 'number',
          size: 6,
          visible : fromHr
        },
        {
          label: 'Level 3',
          field: 'level3_achievement',
          type: 'number',
          size: 6,
          visible : fromHr
        },
      ]}
      {...props}
    />
  );
};

export const AddManualVariable = props => {
  return <ManualVariable header="Add Manual Variable" {...props} />;
};

export const EditManualVariable = props => {
  const {route: {params} = {}} = props;
  const {tab,fromHr} = params;
  let readOnly = true;
  if(tab === "Pending" || fromHr){
    readOnly = false
  }
  return (
    <ManualVariable mode="edit" header={'Update Manual Variable'} readOnly={readOnly} {...props} />
  );
};
