import React from 'react';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {PotentialCustomerFormForLead} from './PotentialCustomerForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AddLeadForm = props => {
  const {
    route: {params},
  } = props;

  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;
  let product = params?.product;
  if (
    (!product || !product._id) &&
    employee &&
    employee.product &&
    employee.product._id
  ) {
    product = employee.product;
  }
  let owner = {_id: employeeId, name: employee.name};

  return (
    <PotentialCustomerFormForLead
      {...props}
      add
      header={'Add Lead'}
      defaultValues={{
        lead_status: 'Active',
        pipeline_type: 'Prospect',
        created_on: getZeroTimeDate(new Date()),
        // sql_stage_date: getZeroTimeDate(new Date()),
        type: 'Organization',
        // stage: {_id: '5530f027a814a8f057123166', name: 'QL'},
        product: product,
        organization: params?.organization,
        department: params?.department,
        // sql_stage_date: getZeroTimeDate(new Date()),
        owner: owner,
        activity_date: new Date(),
        added_on: getZeroTimeDate(new Date()),
      }}
    />
  );
};

export const AddLeadFormUQLActive = props => {
  const {
    route: {params},
  } = props;
  console.log('props>>', props);

  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  return (
    <PotentialCustomerFormForLead
      {...props}
      add
      header={'Add Lead'}
      defaultValues={{
        lead_status: 'Active',
        pipeline_type: 'Prospect',
        created_on: getZeroTimeDate(new Date()),
        // sql_stage_date: getZeroTimeDate(new Date()),
        type: 'Organization',
        // stage: {_id: '5530f027a814a8f057123166', name: 'QL'},
        product: params?.product,
        organization: params?.organization,
        department: params?.department,
        // sql_stage_date: getZeroTimeDate(new Date()),
        owner: employeeId,
        activity_date: new Date(),
        added_on: getZeroTimeDate(new Date()),
        stage: {_id: '5b56cf2421799051029c4522', name: 'Lead'},
      }}
    />
  );
};
export const AddLeadFormQLActive = props => {
  const {
    route: {params},
  } = props;
  console.log('props>>', props);

  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  return (
    <PotentialCustomerFormForLead
      {...props}
      add
      header={'Add Lead'}
      defaultValues={{
        lead_status: 'Active',
        pipeline_type: 'Prospect',
        created_on: getZeroTimeDate(new Date()),
        // sql_stage_date: getZeroTimeDate(new Date()),
        type: 'Organization',
        stage: {_id: '5530f027a814a8f057123166', name: 'QL'},
        product: params?.product,
        organization: params?.organization,
        department: params?.department,
        // sql_stage_date: getZeroTimeDate(new Date()),
        owner: employeeId,
        activity_date: new Date(),
        added_on: getZeroTimeDate(new Date()),
      }}
    />
  );
};

export const CreateDuplicateLeadForm = props => {
  const {
    route: {params},
  } = props;

  const {row, product, department} = params;

  let {data} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: row?._id,
    },
    fields: {
      type: 1,
      email: 1,
      website: 1,
      name: 1,
      country: {name: 1},
      industry: {name: 1},
      owner: {name: 1},
      phone: 1,
      employee_count: 1,
      exact_employee_count: 1,
      campaign_type: 1,
      campaign_id: {name: 1},
      description: 1,
      manual_description: 1,
      product: {name: 1},
      department: {name: 1},
      organization: {name: 1},
      inside_sales: {name: 1},
    },
  });
  if (!data) {
    return null;
  }

  let {_id, email, website, ...newData} = data;

  if (email) {
    newData = {...newData, email};
  }
  if (website) {
    newData = {...newData, website};
  }
  return (
    <PotentialCustomerFormForLead
      {...props}
      header={'Add Lead'}
      defaultValues={{
        ...newData,
        lead_status: 'Active',
        is_duplicate: true,
        pipeline_type: 'Prospect',
        // sql_stage_date: getZeroTimeDate(new Date()),
        uql_stage_date: getZeroTimeDate(new Date()),
        created_on: getZeroTimeDate(new Date()),
        // stage: {_id: '5530f027a814a8f057123166', name: 'QL'},
        stage: {_id: '5b56cf2421799051029c4522', name: 'Lead'},
        // product,
        department,
        activity_date: new Date(),
        added_on: getZeroTimeDate(new Date()),
        parent_potential_customer: _id,
      }}
    />
  );
};

export const EditLeadForm = props => {
  const {route: {params} = {}} = props;
  const {leadId, row, fromAllLead} = params;
  let {data} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: leadId,
    },

    fields: {
      type: 1,
      email: 1,
      website: 1,
      keyword: 1,
      uql_stage_date: 1,
      name: 1,
      country: {name: 1},
      industry: {name: 1},
      owner: {name: 1},
      phone: 1,
      organization_category: {name: 1},
      employee_count: {number: 1},
      geography_location: {name: 1},
      exact_employee_count: 1,
      campaign_type: {name: 1},
      campaign_id: {name: 1},
      description: 1,
      manual_description: 1,
      product: {name: 1},
      department: {name: 1},
      organization: {name: 1},
      buyer_persona: {name: 1},
      inside_sales: {name: 1},
      stage: {name: 1},
      tech_consultant: {name: 1},
      solution_consultant: {name: 1},
      ux_consultant: {name: 1},
      business_analyst: {name: 1},
      business_consultant: {name: 1},
      contact_detail: {
        first_name: 1,
        last_name: 1,
        designation: 1,
        email: 1,
        linkedin_url: 1,
        phone_no: 1,
      },
      channel: {name: 1},
      employee_team: {name: 1},
      exact_employee_count: 1,
    },
  });
  if (!data) {
    return null;
  }
  if (fromAllLead) {
    data = {...data, activity_date: new Date()};
    if (row?.stage?.name === 'Lead') {
      return (
        <PotentialCustomerFormForLead
          beforeSubmit={({data}) => ({
            data: {
              ...data,
              activity_date: getZeroTimeDate(new Date()),
            },
          })}
          {...props}
          isDualMode
          header={{title: 'Edit Lead', secondaryTitle: row?.name}}
          defaultValues={data}
        />
      );
    } else {
      return (
        <PotentialCustomerFormForLead
          beforeSubmit={({data}) => ({
            data: {
              ...data,
              activity_date: getZeroTimeDate(new Date()),
            },
          })}
          {...props}
          readOnly={true}
          header={{title: 'Lead Details', secondaryTitle: row?.name}}
          defaultValues={data}
        />
      );
    }
  } else {
    return (
      <PotentialCustomerFormForLead
        beforeSubmit={({data}) => ({
          data: {
            ...data,
            activity_date: getZeroTimeDate(new Date()),
          },
        })}
        {...props}
        isDualMode
        header={{title: 'Edit Lead', secondaryTitle: row?.name}}
        defaultValues={data}
      />
    );
  }

  // if (fromAllLead && !row.uql_stage_date && row?.stage?.name != 'UQL') {
  //   return (
  //     <PotentialCustomerFormForLead
  //       {...props}
  //       // isDualMode
  //       readOnly={true}
  //       header={{title: 'Lead Details', secondaryTitle: row?.name}}
  //       defaultValues={data}
  //     />
  //   );
  // } else {
  //   console.log('here');
  //   return (
  //     <PotentialCustomerFormForLead
  //       {...props}
  //       isDualMode
  //       header={{title: 'Edit Lead', secondaryTitle: row?.name}}
  //       defaultValues={data}
  //     />
  //   );
  // }
};
