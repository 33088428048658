import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AutomaticPaymentRequest = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/automaticpayments',
    eventSourceId: 'budget',
  });
  const {user, fetch, post} = useAppStateContext();

  // if (row && row?._id) {
  //   const {data} = useFetchFormData({
  //     api: '/potentialCustomers',
  //     filter: {
  //       _id: leadId,
  //     },
  //     fields: {
  //       name: 1,
  //       owner: {_id: 1, name: 1},
  //       industry: {_id: 1, name: 1},
  //       department: {_id: 1, name: 1},
  //       product: {_id: 1, name: 1},
  //       organization: {_id: 1, name: 1},
  //       campaign_type: {_id: 1, label: 1, color: 1},
  //     },
  //   });
  // }
  return (
    <Form
      api={`/automaticpayments/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Requested',
        request_date: new Date(),
        requested_by: user.employee?._id,
        approver_status: 'Team Head',
      }}
      readOnly={readOnly}
      fields={{
        vendor: {name: 1},
        team: {name: 1},
        approver: {name: 1},
        department: {name: 1},
        currency: {currency: 1},
        term: 1,
        description: 1,
        amount: 1,
        from_date: 1,
        to_date: 1,
        amount: 1,
        address: 1,
        organization: {name: 1},
        product: {name: 1},
        address: 1,
      }}
      computations={{
        'set organization,product and currency': {
          compute: async value => {
            const {data: vendorData} = await fetch({
              uri: '/vendors',
              props: {
                filter: {
                  _id: value.vendor._id,
                },
                fields: {
                  organization: {_id: 1, name: 1},
                  product: {_id: 1, name: 1},
                  department: {_id: 1, name: 1},
                  currency_id: {_id: 1, currency: 1},
                },
                only: true,
              },
            });
            return {
              organization: vendorData?.organization,
              product: vendorData?.product,
              currency: vendorData?.currency_id,
              department: vendorData?.department,
            };
          },
          multi: true,
          dependencies: ['vendor'],
        },
      }}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Payment details',
          fields: [
            {
              label: 'Vendor',
              type: 'autoComplete',
              field: 'vendor',
              api: `/vendors`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Term',
              type: 'autoComplete',
              options: ['Monthly', 'Quarterly', 'Half yearly', 'Yearly'],
              field: 'term',
              size: 6,
              required: true,
            },

            {
              label: 'Description',
              type: 'text',
              field: 'description',
              required: true,
            },
            {
              label: 'Address',
              type: 'text',
              field: 'address',
              required: true,
            },
            {
              label: 'Approx Amount',
              type: 'number',
              field: 'amount',
              required: true,
              size: 6,
            },
            {
              label: 'Currency',
              type: 'autoComplete',
              field: 'currency',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 6,
              required: true,
            },
            {
              label: 'Start date',
              field: 'from_date',
              type: 'date',
              size: 6,
              required: true,
              // validate: async (fieldValue, {values}) => {
              //   const {data} = await post({
              //     uri: `/automaticpayments/datesValidation`,
              //     method: 'post',
              //     props: {
              //       from_date: fieldValue,
              //     },
              //   });
              //   console.log('data>>>>..', data);
              //   if (data) {
              //     return data;
              //   }
              // },
              // onChangeValue: (value, e, {setFieldValue}) => {
              //   if (value) {
              //     setFieldValue('expense_bill_date', value);
              //   }
              // },
            },
            {
              label: 'End date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 6,
            },
            {
              label: 'Gst No.',
              type: 'text',
              field: 'vendor_gst',
              size: 6,
            },
            // {
            //   label: 'Tds No.',
            //   type: 'text',
            //   field: 'vendor_tds',
            //   size: 6,
            // },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user.employee._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
            {
              label: 'Department',
              field: 'department',
              type: 'autoComplete',
              api: '/activeDepartmentSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Gst No.',
              type: 'text',
              field: 'organization_gst',
              size: 6,
            },
            // {
            //   label: 'Tds No.',
            //   type: 'text',
            //   field: 'organization_tds',
            //   size: 6,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddAutomaticPayment = props => {
  return <AutomaticPaymentRequest header="Add Card Schedule" {...props} />;
};

export const EditAutomaticPayment = props => {
  return (
    <AutomaticPaymentRequest
      mode="edit"
      header={'Update Budget Request'}
      {...props}
    />
  );
};

export const RejectAutomaticPayment = props => {
  const {route: {params} = {}} = props;

  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/automaticpayments`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/automaticpayments/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_date: new Date(),
            rejected_by: rejected_by,
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Reject reason',
          field: 'reject_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const RejectAdditionalEmailRequest = props => {
  const {route: {params} = {}} = props;

  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/additionalemailrequests`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/additionalemailrequests/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_date: new Date(),
            rejected_by: rejected_by,
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Remark',
          field: 'reject_remark',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const IsseAdditionalEmailRequestIT = props => {
  const {route: {params} = {}} = props;

  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/additionalemailrequests`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/additionalemailrequests/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Issued',
            approved_by_finance_date: new Date(),
          },
        };
      }}
      mode="edit"
      header="Issue Email"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
        },
        {
          placeholder: 'Attachment',
          label: 'Attachment',
          field: 'requirement_document',
          type: 'file',
          options: {bucketName: 'manaze'},
        },
      ]}
      {...props}
    />
  );
};

export const AdditionalEmailRemarks = props => {
  const {route: {params} = {}} = props;

  const {row, rejected_by = ''} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/additionalemailrequests`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/additionalemailrequests/${row._id}`}
      onSubmit={onSubmit}
      mode="edit"
      header="reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Remark',
          field: 'remarks',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ExtendAdditionalEmailRequest = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/additionalemailrequests`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/additionalemailrequests/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            is_extend: true,
          },
        };
      }}
      mode="edit"
      header="Extend"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Extended Till',
          field: 'extended_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Reason',
          field: 'extended_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EmployeeEmailRemarks = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/employees/${row._id}`}
      onSubmit={onSubmit}
      mode="edit"
      header="reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Remark',
          field: 'remarks',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ExtendEmployeeEmailRequest = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'EmployeeAssignment',
  });

  return (
    <Form
      api={`/employees/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            is_email_extended: true,
          },
        };
      }}
      mode="edit"
      header="Extend"
      submitAction="Save"
      eventSourceId="EmployeeAssignment"
      layoutFields={[
        {
          label: 'Extended Till',
          field: 'email_extended_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Reason',
          field: 'remarks',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const MarkEmployeeEmailActive = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'EmployeeAssignment',
  });

  return (
    <Form
      api={`/employees/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            email_activation_status: 'Active',
          },
        };
      }}
      mode="edit"
      header="Mark Active"
      submitAction="Save"
      eventSourceId="EmployeeAssignment"
      layoutFields={[
        {
          label: 'Approved Upto',
          field: 'email_extended_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Approver',
          field: 'approved_by',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddPaymentFromFinanceForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/automaticpayments`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/automaticpayments/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Active',
            approved_by_finance_date: new Date(),
          },
        };
      }}
      mode="edit"
      header="Add Card"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          api: '/banks',
          field: 'fund_center',
          label: 'Card',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
          titleField: 'name',
          type: 'autoComplete',
        },
      ]}
      {...props}
    />
  );
};

export const AdditionalEmailRequestsForms = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/additionalemailrequests',
    eventSourceId: 'budget',
  });
  const {user, fetch, post} = useAppStateContext();

  // if (row && row?._id) {
  //   const {data} = useFetchFormData({
  //     api: '/potentialCustomers',
  //     filter: {
  //       _id: leadId,
  //     },
  //     fields: {
  //       name: 1,
  //       owner: {_id: 1, name: 1},
  //       industry: {_id: 1, name: 1},
  //       department: {_id: 1, name: 1},
  //       product: {_id: 1, name: 1},
  //       organization: {_id: 1, name: 1},
  //       campaign_type: {_id: 1, label: 1, color: 1},
  //     },
  //   });
  // }
  return (
    <Form
      api={`/additionalemailrequests/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Draft',
        request_date: new Date(),
        requester: user.employee?._id,
        approver_status: 'Team Head',
      }}
      readOnly={readOnly}
      fields={{
        project: {project: 1},
        team: {name: 1},
        approver: {name: 1},
        customer: {name: 1},
        organization: {name: 1},
        product: {name: 1},
        term: 1,
        from_date: 1,
        to_date: 1,
        email: 1,
        approved_date: 1,
        request_date: 1,
        rejected_date: 1,
        description: 1,
        status: 1,
        reject_remark: 1,
      }}
      computations={{
        'set organization,product': {
          compute: async value => {
            const {data: customerData} = await fetch({
              uri: '/customers',
              props: {
                filter: {
                  _id: value.customer._id,
                },
                fields: {
                  organization: {_id: 1, name: 1},
                  product: {_id: 1, name: 1},
                },
                only: true,
              },
            });
            return {
              organization: customerData?.organization,
              product: customerData?.product,
            };
          },
          multi: true,
          dependencies: ['customer'],
        },
      }}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Details',
          fields: [
            {
              label: 'Customer',
              type: 'autoComplete',
              field: 'customer',
              api: `/customers`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Project',
              type: 'autoComplete',
              field: 'project',
              api: `/customer/projects`,
              // required: true,
              suggestionField: 'project',
              valueField: 'project',
              size: 6,
              filter: ({values}) => {
                if (values?.customer && values?.customer?._id) {
                  return {
                    customer: values?.customer?._id,
                    project_status: 'a',
                  };
                }
              },
            },
            {
              label: 'Email',
              type: 'text',
              field: 'email',
              required: true,
            },

            {
              label: 'Description',
              type: 'text',
              field: 'description',
            },

            {
              label: 'Start date',
              field: 'from_date',
              type: 'date',
              size: 6,
              required: true,
            },
            {
              label: 'End date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 6,
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user.employee._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
            // {
            //   label: 'Product',
            //   field: 'product',
            //   type: 'autoComplete',
            //   api: `/products`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   size: 6,
            // },
            // {
            //   label: 'Organization',
            //   field: 'organization',
            //   type: 'autoComplete',
            //   api: `/organizations`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   size: 6,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AdditionalEmailRequestsFormIT = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/additionalemailrequests',
    eventSourceId: 'budget',
  });
  const {user, fetch, post} = useAppStateContext();

  // if (row && row?._id) {
  //   const {data} = useFetchFormData({
  //     api: '/potentialCustomers',
  //     filter: {
  //       _id: leadId,
  //     },
  //     fields: {
  //       name: 1,
  //       owner: {_id: 1, name: 1},
  //       industry: {_id: 1, name: 1},
  //       department: {_id: 1, name: 1},
  //       product: {_id: 1, name: 1},
  //       organization: {_id: 1, name: 1},
  //       campaign_type: {_id: 1, label: 1, color: 1},
  //     },
  //   });
  // }
  return (
    <Form
      api={`/additionalemailrequests/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Draft',
        request_date: new Date(),
        requester: user.employee?._id,
        status: 'Issued',
        approved_date: new Date(),
        approved_by_finance_date: new Date(),
        created_by_it: true,
      }}
      readOnly={readOnly}
      fields={{
        project: {project: 1},
        team: {name: 1},
        approver: {name: 1},
        customer: {name: 1},
        organization: {name: 1},
        product: {name: 1},
        term: 1,
        from_date: 1,
        to_date: 1,
        email: 1,
        approved_date: 1,
        request_date: 1,
        rejected_date: 1,
        description: 1,
        status: 1,
        reject_remark: 1,
      }}
      computations={{
        'set organization,product': {
          compute: async value => {
            const {data: customerData} = await fetch({
              uri: '/customers',
              props: {
                filter: {
                  _id: value.customer._id,
                },
                fields: {
                  organization: {_id: 1, name: 1},
                  product: {_id: 1, name: 1},
                },
                only: true,
              },
            });
            return {
              organization: customerData?.organization,
              product: customerData?.product,
            };
          },
          multi: true,
          dependencies: ['customer'],
        },
      }}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Details',
          fields: [
            {
              label: 'Customer',
              type: 'autoComplete',
              field: 'customer',
              api: `/customers`,
              required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Project',
              type: 'autoComplete',
              field: 'project',
              api: `/customer/projects`,
              // required: true,
              suggestionField: 'project',
              valueField: 'project',
              size: 6,
              filter: ({values}) => {
                if (values?.customer && values?.customer?._id) {
                  return {
                    customer: values?.customer?._id,
                    project_status: 'a',
                  };
                }
              },
            },
            {
              label: 'Email',
              type: 'text',
              field: 'email',
              required: true,
            },

            {
              label: 'Description',
              type: 'text',
              field: 'description',
            },

            {
              label: 'Start date',
              field: 'from_date',
              type: 'date',
              size: 6,
              required: true,
            },
            {
              label: 'End date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 6,
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                // requester: {$in: [user.employee._id]},
                project: {$exists: false},
                team_type: 'Department',
                status: 'Active',
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              size: 6,
              required: true,
              // params: ({values}) => {
              //   return {
              //     _id: values?.team?._id,
              //   };
              // },
            },
            // {
            //   label: 'Product',
            //   field: 'product',
            //   type: 'autoComplete',
            //   api: `/products`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   size: 6,
            // },
            // {
            //   label: 'Organization',
            //   field: 'organization',
            //   type: 'autoComplete',
            //   api: `/organizations`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   size: 6,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddAdditionalEmailRequests = props => {
  return (
    <AdditionalEmailRequestsForms
      header="Request Additional Email"
      {...props}
    />
  );
};

export const EditAdditionalEmailRequests = props => {
  return (
    <AdditionalEmailRequestsForms
      mode="edit"
      header={'Update Additional Email Request'}
      {...props}
    />
  );
};

export const AddAdditionalEmailRequestsIT = props => {
  return (
    <AdditionalEmailRequestsFormIT header="Add Additional Email" {...props} />
  );
};

export const EditAdditionalEmailRequestsIT = props => {
  return (
    <AdditionalEmailRequestsFormIT
      mode="edit"
      header={'Update Additional Email Request'}
      {...props}
    />
  );
};
