import React from 'react';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';

export const NewAppraisalChipsOptions = [
    {
        value: "A+",
        label: "CE",
    },
    {
        value: "A",
        label: "OE",
    },
    {
        value: "B+",
        label: "ME",
    },
    {
        value: "B",
        label: "PE",
    },
    {
        value: "C",
        label: "DE",
    },
];

export const SelfProjectFeedbackTable = props => {
    let {
        route: { params } = {},
        filter,
        navigation
    } = props;
    const { user } = useAppStateContext();
    const addOnFilter = {
      ...filter,
      $and: [
        {rm_feedback_generated: {$in: [null, false]}},
        {rm_feedback_generated_new: {$in: [null, false]}},
      ],
      $or: [
        {is_project_manager_feedback_given: true},
        {is_processed: true},
      ],
      employee: user && user.employee && user.employee._id,
    };
    return (
        <Table
            eventSourceId={['SelfProjectFeedback']}
            api={'/employeeprojectfeedback'}
            addOnFilter={addOnFilter}
            onRowPress={({ row }) =>
                navigation.navigate('self-project-feedback-detail', {
                    row,
                })
            }
            columns={[
                { header: 'Manager Name', field: 'reporting_to', type : "userAvatarChip" },
                { header: 'Quater', field: 'quater' },
                { header: 'Project', field: 'project.project' },
                {
                    header: 'Overall Rating',
                    field: 'overall_rating',
                    value: ({ row }) => {
                        for (const rating of NewAppraisalChipsOptions) {
                            const { value, label } = rating;
                            if (row && row.overall_rating && row.overall_rating === value) {
                                return label
                            }
                        }
                    }
                },
            ]}
            // params={params}
        />
    );
};


export const SelfRMFeedbackTable = props => {
    let {
        route: { params } = {},
        filter,
        navigation
    } = props;
    const { user } = useAppStateContext();
    const addOnFilter = {
      ...filter,
      is_rm_feedback_given: true,
      $or: [{rm_feedback_generated: true}, {rm_feedback_generated_new: true}],
      employee: user && user.employee && user.employee._id,
    };
    return (
        <Table
            eventSourceId={['SelfRMFeedback']}
            api={'/employeeprojectfeedback'}
            addOnFilter={addOnFilter}
            onRowPress={({ row }) =>
                navigation.navigate('self-rm-feedback-detail', {
                    row,
                })
            }
            columns={[
                { header: 'Quater', field: 'quater' },
                { header: 'Overall Score', field: 'rm_feedback_score' },
            ]}
            // params={params}
        />
    );
};