import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupTable} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {GroupFilter} from '../../../components/filter/Filters';
import {useAppStateContext} from '../../../providers/AppState';

const employeeTypeFilters = [
  {
    label: 'Employee Type',
    field: 'employee_level',
    placeholder: 'Select Employee Type',
    type: 'autoComplete',
    options: [
      {value: 'include_manager', label: 'All'},
      {value: 'exclude_manager', label: 'Exclude Managers'},
      {value: 'only_manager', label: 'Only Managers'},
    ],
    suggestionField: 'label',
    keyField: 'value',
    valueField: 'label',
  },
  {
    label: 'KPI',
    field: 'kpi_id',
    placeholder: 'Select KPI',
    type: 'autoComplete',
    api: '/keyperformanceindicators',
    suggestionField: 'name',
    keyField: 'name',
    valueField: 'name',
    filter: {
      type: 'performance',
    },
  },
  {
    label: 'Department',
    field: 'department',
    placeholder: 'Select Department',
    type: 'autoComplete',
    api: '/activeDepartmentSuggestions',
    suggestionField: 'name',
    keyField: 'name',
    valueField: 'name',
    asParam: true,
  },
];

const VariableKpisTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const filtersInfo = useFilter();
  let {filterValues: {filter = {}, params: departmentParam = {}} = {}} =
    filtersInfo || {};
  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {period: params.period || getPreviousMonth()},
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  const {user} = useAppStateContext();
  return (
    <GroupTable
      limit={2000}
      api={`/kpis-by-user`}
      params={{
        period: period,
        department:
          params?.departments?._id ||
          departmentParam?._id ||
          '5848ffe17d89cd3f17c7335c',
        employee_level: filter?.employee_level,
        employee: user?.employee?._id,
      }}
      addOnFilter={{...filter}}
      renderHeader={props => (
        <TableHeader
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter {...filtersInfo} filters={employeeTypeFilters} />,
          ]}
          {...props}
        />
      )}
      defaultExpanded={true}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.userName} />
              <GroupContentItem value={row?.email} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.amount} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'KPI',
          type: 'text',
          field: 'kpi.name',
          responsive: 'sm',
        },
        {
          header: 'Outcome',
          type: 'text',
          field: 'output',
          responsive: 'sm',
          onPress: ({row}) => {
            row?.api?.view ? navigation.navigate(row.api.view, row) : null;
          },
          align: 'right',
        },
      ]}
    />
  );
};

export default VariableKpisTable;
