import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {useAppStateContext} from '../../../providers/AppState';
import {ClosedLegalCases, OngoingLegalCases} from './LegalCasesTables';

export const LegalCasesTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Ongoing',
      view: (
        <OngoingLegalCases
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{case_status: 'OnGoing'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'legal',
      api: '/legalcases',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        case_status: 'OnGoing',
        ...filterValues.filter,
      },
      limit: 500,
    },
    Closed: {
      label: 'Closed',
      view: (
        <ClosedLegalCases
          tab={'Closed'}
          filterValues={filterValues}
          addOnFilter={{
            case_status: {$ne: 'OnGoing'},
            requested_by: employee._id,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'legal',
      api: '/legalcases',
      addOnFilter: {
        case_status: {$ne: 'OnGoing'},
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Stage',
              api: '/legalcasestages',
              placeholder: 'Select',
              suggestionField: 'stage',
              valueField: 'stage',
              field: 'case_stage',
            },
            {
              label: 'Employee/ Client/ Vendor',
              field: 'client',
              type: 'autoComplete',
              api: '/legalclients',
              suggestionField: 'client',
              valueField: 'client',
              required: true,
              size: 6,
            },
            {
              label: 'Case Type',
              field: 'case_type',
              type: 'autoComplete',
              api: '/casetypes',
              suggestionField: 'case_type',
              valueField: 'case_type',
              required: true,
              size: 6,
            },
            {
              label: 'Jurisdiction',
              field: 'jurisdiction',
              type: 'autoComplete',
              api: '/jurisdictions',
              suggestionField: 'jurisdiction',
              valueField: 'jurisdiction',
              required: true,
              size: 6,
            },
            {
              label: 'Last Hearing',
              field: 'last_date_of_hearing',
              type: 'date',
            },
            {
              label: 'Next Hearing',
              field: 'next_date_of_hearing',
              type: 'date',
            },
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Settled', 'Write-Off', 'Decided', 'Withdrawn'],
              placeholder: 'Select',
              field: 'case_status',
            },
          ]}
        />,
        <AddButton title="Add Case" view={'add-legal-case'} />,
      ]}
    />
  );
};
