import {Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {
  UserAvatar,
  UserAvatarChip,
} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {BorderedGroupTable, Table} from '../../../components/table/Table';
import {renderType} from './TeamAttendanceRequestTable';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const renderStatus = ({row = {}, styles = {}, field}) => {
  let {status, is_approved} = row;
  let updateColor = {color: '#34C759'};
  let updateValue = '';
  let background = '';
  let {rowText = {}} = styles;
  if (is_approved && status === 'reject') {
    updateColor['color'] = '#FF3B30';
    updateValue = 'Rejected';
    background = '#FFF1F0';
  } else if (is_approved || status === "approve") {
    updateColor['color'] = '#34C759';
    updateValue = 'Approved';
    background = '#F0FFF4';
  } else {
    updateColor['color'] = '#007AFF';
    updateValue = 'Unapproved';
    background = '#E0F5FF';
  }
  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{updateValue || '-'}</Text>
    </View>
  );
};
const renderAttendance = ({name, styles}) => {
  let updateColor = {color: '#34C759'};
  let background = '';
  let {rowText = {}} = styles;
  if (name === 'Present') {
    updateColor['color'] = '#34C759';
    background = '#F0FFF4';
  } else if (name === 'Absent') {
    updateColor['color'] = '#FF3B30';
    background = '#FFF1F0';
  } else if (name === 'Leave') {
    updateColor['color'] = '#F9C700';
    background = '#FFFCF0';
  } else {
    updateColor['color'] = '#737373';
    background = '#F7F7F7';
  }
  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{name || '-'}</Text>
    </View>
  );
};
export const EmployeeDailyAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/attendance/self'}
      params={params}
      eventSourceId={['myAttendance', 'allattendance']}
      addOnFilter={filter}
      search={searchValue}
      selection={{
        actions: [
          {
            title: 'update Attendance',
            onPress: ({selectedIds, row}) => {
              navigation.navigate('update-attendance', {
                ...params,
                selectedIds,
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Date',
          width: 220,
          render: ({row, styles}) => {
            const date = new Date(row?.attendance_date);
            const dayOfWeek = daysOfWeek[date.getUTCDay()];
            return (
              <PrimaryColumnCard
                primaryTitle={
                  row.attendance_date &&
                  moment(row.attendance_date).format('DD MMM YY')
                }
                items={[{value: dayOfWeek}]}
              />
              // <Text style={{...styles.rowText}}>
              //   {moment(row.attendance_date).format('DD MMM YY')}
              // </Text>
            );
          },
        },
        {
          header: 'In Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>{row?.in_time}</Text>
            );
          },
        },
        {
          header: 'Out Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>
                {row?.out_time}
              </Text>
            );
          },
        },
        {
          header: 'Duration',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            let value = (row && row['time_in_office']) || null;
            if (!value) return void 0;
            let duration = '';
            let defaultDuration = 60;
            let hours = value && value / defaultDuration;
            let minute = value && value % defaultDuration;
            if (hours && hours > 0) {
              hours = Math.floor(hours);
              duration += hours + 'h ';
            }
            if (minute) {
              minute = Math.round(minute);
              duration += minute + 'm';
            }
            return (
              <Text style={{...styles.rowText, color: '#737373'}}>
                {duration}
              </Text>
            );
          },
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.first_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.second_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Status',
          type: 'text',
          // field: 'status',
          align: 'center',
          render: renderStatus,
        },
      ]}
      moreActions={() => [
        {
          title: 'Update Attendance',
          onPress: ({row}) => {
            navigation.navigate('update-attendance', {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Punch Data',
          onPress: ({row}) => {
            navigation.navigate('punch-data-screen', {row});
          },
        },
        {
          title: 'Apply Leave',
          onPress: ({row}) => {
            navigation.navigate('add-self-leave-request', {
              ...params,
              row,
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeDailyAttendanceForShiftAllowanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/attendance/all'}
      params={params}
      eventSourceId={['myAttendance', 'allattendance']}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Date',
          width: 220,
          render: ({row, styles}) => {
            const date = new Date(row?.attendance_date);
            const dayOfWeek = daysOfWeek[date.getUTCDay()];
            return (
              <PrimaryColumnCard
                primaryTitle={
                  row.attendance_date &&
                  moment(row.attendance_date).format('DD MMM YY')
                }
                items={[{value: dayOfWeek}]}
              />
            );
          },
        },
        {
          header: 'In Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>{row?.in_time}</Text>
            );
          },
        },
        {
          header: 'Out Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>
                {row?.out_time}
              </Text>
            );
          },
        },
        {
          header: 'Duration',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            let value = (row && row['time_in_office']) || null;
            if (!value) return void 0;
            let duration = '';
            let defaultDuration = 60;
            let hours = value && value / defaultDuration;
            let minute = value && value % defaultDuration;
            if (hours && hours > 0) {
              hours = Math.floor(hours);
              duration += hours + 'h ';
            }
            if (minute) {
              minute = Math.round(minute);
              duration += minute + 'm';
            }
            return (
              <Text style={{...styles.rowText, color: '#737373'}}>
                {duration}
              </Text>
            );
          },
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.first_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.second_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Status',
          type: 'text',
          field: 'status',
          align: 'center',
          render: renderStatus,
        },
      ]}
    />
  );
};

export const EmployeeLeaveRegisterTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  return (
    <Table
      api={'/monthlyattendance/leaveregister/self'}
      params={params}
      eventSourceId={'leaveRegister'}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Available',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (
              row &&
              row.leave_details &&
              row.leave_details.opening_leave_balance
            ) {
              return (
                <Text style={{...rowText}}>
                  {row?.leave_details?.opening_leave_balance?.toFixed(1)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Accured',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.accrued_leaves) {
              return (
                <Text style={{...rowText}}>
                  {row.leave_details.accrued_leaves.toFixed(2)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Consumed',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.consumed_leaves) {
              let value = parseFloat(
                row.leave_details.consumed_leaves.toFixed(1),
              );
              return (
                <Text style={{...rowText, color: '#007AFF'}}>{value}</Text>
              );
            } else {
              return <Text style={{...rowText, color: '#007AFF'}}>0</Text>;
            }
          },
        },
        {
          header: 'Balance',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.leave_balance) {
              return (
                <Text style={{...rowText, color: '#34C759'}}>
                  {parseFloat(row.leave_details.leave_balance.toFixed(3))}
                </Text>
              );
            } else {
              return <Text style={{...rowText, color: '#34C759'}}>0</Text>;
            }
          },
        },
      ]}
    />
  );
};

export const TeamLeaveRegisterTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
    tab,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
    tab,
  };
  let {reporting_to = '', functional_manager = '', ...restfilters} = filter;
  if (tab === 'project') {
    filter = {
      ...restfilters,
      'employee.reporting_to': reporting_to,
    };
  }
  if (tab === 'primary') {
    filter = {
      ...restfilters,
      'employee.functional_manager': functional_manager,
    };
  }
  params = {...params, ...filterParams, periods};
  return (
    <Table
      api={'/monthlyattendance/leaveregister/team'}
      params={params}
      eventSourceId={'leaveRegister'}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Name',
          field: 'employee',
          type: 'userAvatarChip',
          minWidth: 200,
        },
        {
          header: 'Available',
          type: 'number',
          width: 120,
          render: ({row, styles: {rowText = {}}}) => {
            if (
              row &&
              row.leave_details &&
              row.leave_details.opening_leave_balance
            ) {
              return (
                <Text style={{...rowText}}>
                  {row?.leave_details?.opening_leave_balance?.toFixed(1)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Accured',
          type: 'number',
          width: 120,
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.accrued_leaves) {
              return (
                <Text style={{...rowText}}>
                  {row.leave_details.accrued_leaves.toFixed(2)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Consumed',
          type: 'number',
          width: 120,
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.consumed_leaves) {
              let value = parseFloat(
                row.leave_details.consumed_leaves.toFixed(1),
              );
              return (
                <Text style={{...rowText, color: '#007AFF'}}>{value}</Text>
              );
            } else {
              return <Text style={{...rowText, color: '#007AFF'}}>0</Text>;
            }
          },
        },
        {
          header: 'Balance',
          type: 'number',
          width: 120,
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.leave_balance) {
              return (
                <Text style={{...rowText, color: '#34C759'}}>
                  {parseFloat(row.leave_details.leave_balance.toFixed(3))}
                </Text>
              );
            } else {
              return <Text style={{...rowText, color: '#34C759'}}>0</Text>;
            }
          },
        },
      ]}
    />
  );
};

export const TeamEmployeeLeaveRegisterTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  return (
    <Table
      api={'/monthlyattendance/leaveregister/team-employee'}
      params={params}
      eventSourceId={'leaveRegister'}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Available',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (
              row &&
              row.leave_details &&
              row.leave_details.opening_leave_balance
            ) {
              return (
                <Text style={{...rowText}}>
                  {row?.leave_details?.opening_leave_balance?.toFixed(1)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Accured',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.accrued_leaves) {
              return (
                <Text style={{...rowText}}>
                  {row.leave_details.accrued_leaves.toFixed(2)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Consumed',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.consumed_leaves) {
              let value = parseFloat(
                row.leave_details.consumed_leaves.toFixed(1),
              );
              return (
                <Text style={{...rowText, color: '#007AFF'}}>{value}</Text>
              );
            } else {
              return <Text style={{...rowText, color: '#007AFF'}}>0</Text>;
            }
          },
        },
        {
          header: 'Balance',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.leave_balance) {
              return (
                <Text style={{...rowText, color: '#34C759'}}>
                  {parseFloat(row.leave_details.leave_balance.toFixed(3))}
                </Text>
              );
            } else {
              return <Text style={{...rowText, color: '#34C759'}}>0</Text>;
            }
          },
        },
      ]}
    />
  );
};
export const EmployeeMonthlyAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  return (
    <Table
      api={'/monthlyattendance/self'}
      params={params}
      eventSourceId={'monthlyAttendance'}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`daily-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      columns={[
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Total',
          field: 'total_days_id_month',
          type: 'number',
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
        {
          header: 'LOP',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText, color: '#FF3B30'}}>
                {row?.details.un_paid_leaves + row?.details?.absent_days}
              </Text>
            );
          },
        },
      ]}
    />
  );
};

export const EmployeeLOPTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <BorderedGroupTable
      api={'/monthlyattendance/lop/self'}
      params={params}
      variant={'bordered'}
      eventSourceId={'myAttendance'}
      addOnFilter={filter}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`daily-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      limit={20}
      search={searchValue}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: '#737373',
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
          minWidth: 150,
        },
        {
          header: 'Total',
          type: 'number',
          field: 'total_days_id_month',
          // value: ({row}) => {
          //   if (row && row?.details) {
          //     return (
          //       row?.details?.present_days +
          //       row?.details?.compensatory_off +
          //       row?.details?.ewd_payable +
          //       row?.details?.paid_leaves +
          //       row?.details?.holidays -
          //       row?.details?.absent_days -
          //       row?.details?.un_paid_leaves
          //     );
          //   }
          // },
          width: 80,
        },
        {
          header: 'Payable',
          children: [
            {
              header: 'Present',
              field: 'details.present_days',
              type: 'number',
              width: 80,
            },
            {
              header: 'Off',
              field: 'details.compensatory_off',
              type: 'number',
              width: 80,
            },
            {
              header: 'EWD',
              field: 'details.ewd_payable',
              type: 'number',
              width: 80,
            },
            {
              header: 'Paid Leaves',
              field: 'details.paid_leaves',
              type: 'number',
              width: 100,
            },
            {
              header: 'Holiday',
              field: 'details.holidays',
              type: 'number',
              width: 80,
            },
            {
              header: 'Total',
              width: 80,
              type: 'number',
              value: ({row}) => {
                if (row && row?.details) {
                  return (
                    Number(row?.details?.present_days || 0) +
                    Number(row?.details?.compensatory_off || 0) +
                    Number(row?.details?.ewd_payable || 0) +
                    Number(row?.details?.paid_leaves || 0) +
                    Number(row?.details?.holidays || 0)
                  );
                }
              },
            },
          ],
        },
        {
          header: 'LOP',
          children: [
            {
              header: 'Absent',
              field: 'details.absent_days',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate(`daily-attendance-tab`, {
                  row: {
                    month_id: row.month_id,
                    year_id: row.year_id,
                    first_attendance_type_id: '5382e9c424b06a02005c09f1',
                    second_attendance_type_id: '5382e9c424b06a02005c09f1',
                    employee: row.employee,
                  },
                });
              },
              width: 80,
            },
            {
              header: 'Unpaid Leaves',
              field: 'details.un_paid_leaves',
              type: 'number',
              width: 120,
            },
            {
              header: 'Total',
              width: 80,
              type: 'number',
              value: ({row}) => {
                if (row && row?.details) {
                  return (
                    Number(row?.details?.absent_days || 0) +
                    Number(row?.details?.un_paid_leaves || 0)
                  );
                }
              },
            },
          ],
        },
      ]}
    />
  );
};

export const AttendanceChangeRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    tab,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {
    ...filter,
    // is_approved: false,
    status: 'unapproved',
  };
  return (
    <Table
      api={'/attendance/self'}
      params={params}
      eventSourceId={'attendanceChangeRequest'}
      addOnFilter={addOnFilter}
      search={searchValue}
      columns={[
        {
          header: 'Requested On',
          minWidth: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Row>
                <UserAvatar value={row?.employee?.reporting_to?.[0]} />
                <Text
                  style={{
                    ...rowText,
                    padding: 3,
                    marginLeft: 2,
                    color: '#A3A3A3',
                  }}>
                  {row?.createdAt && moment(row?.createdAt).format('DD MMM')}
                </Text>
              </Row>
            );
          },
        },
        {
          header: 'Date',
          field: 'attendance_date',
          type: 'date',
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Status',
          field: 'status',
          align: 'center',
          render: renderStatus,
          visible: () => tab && tab === 'closed',
        },
      ]}
    />
  );
};

export const EWDRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    tab,
  } = props;
  let sort = {};
  if (tab == 'open') {
    sort = {createdAt: -1};
  } else if (tab == 'closed') {
    sort = {updatedAt: -1};
  }
  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {
    ...filter,
    $or: [
      {first_attendance_type_id: '5382ea3470f5d40200321611'},
      {second_attendance_type_id: '5382ea3470f5d40200321611'},
    ],
  };
  return (
    <Table
      api={'/attendance/self'}
      params={params}
      eventSourceId={'attendanceChangeRequest'}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{...sort}}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Row>
                <UserAvatar value={row?.employee?.reporting_to?.[0]} />
                <Text
                  style={{
                    ...rowText,
                    padding: 3,
                    marginLeft: 2,
                    color: '#A3A3A3',
                  }}>
                  {row?.createdAt && moment(row?.createdAt).format('DD MMM')}
                </Text>
              </Row>
            );
          },
        },
        {
          header: 'Duration',
          field: 'attendance_date',
          type: 'date',
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Status',
          field: 'status',
          align: 'center',
          render: renderStatus,
          visible: () => tab && tab === 'closed',
        },
      ]}
    />
  );
};

export const MonthWiseEmployeeLOPTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams};
  const {row = {}} = params;
  return (
    <Table
      api={'/monthlyattendance/lop/self'}
      params={params}
      eventSourceId={'myAttendance'}
      addOnFilter={{
        ...filter,
        month_id: row?.month_id?._id,
        year_id: row?.year_id?._id,
      }}
      search={searchValue}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: '#737373',
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
          minWidth: 150,
        },
        {
          header: 'Present',
          field: 'details.present_days',
          type: 'text',
          width: 100,
        },
        {
          header: 'Off',
          field: 'details.compensatory_off',
          type: 'text',
          width: 100,
        },
        {
          header: 'EWD',
          field: 'details.ewd_payable',
          type: 'text',
          width: 100,
        },
        {
          header: 'Leaves',
          field: 'details.leave_days',
          type: 'text',
          width: 100,
        },
        {
          header: 'Holiday',
          field: 'details.holidays',
          type: 'text',
          width: 100,
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'text',
          width: 100,
        },
        {
          header: 'Absent',
          field: 'details.absent_days',
          type: 'text',
          width: 100,
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'text',
          width: 100,
          value: ({row}) => {
            if (row && row?.details) {
              return row?.details?.absent_days + row?.details?.un_paid_leaves;
            }
          },
        },
      ]}
    />
  );
};
export const MonthWiseEmployeeTeamLOPTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams};
  const {row = {}} = params;
  return (
    <Table
      api={'/monthlyattendance/lop/team-employee'}
      params={params}
      eventSourceId={'myAttendance'}
      addOnFilter={{
        ...filter,
        month_id: row?.month_id?._id,
        year_id: row?.year_id?._id,
      }}
      search={searchValue}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: '#737373',
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
          width: 150,
        },
        {
          header: 'Present',
          field: 'details.present_days',
          type: 'number',
        },
        {
          header: 'Off',
          field: 'details.compensatory_off',
          type: 'number',
        },
        {
          header: 'EWD',
          field: 'details.ewd_payable',
          type: 'number',
        },
        {
          header: 'Leaves',
          field: 'details.leave_days',
          type: 'number',
        },
        {
          header: 'Holiday',
          field: 'details.holidays',
          type: 'number',
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
        {
          header: 'Absent',
          field: 'details.absent_days',
          type: 'number',
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'number',
          value: ({row}) => {
            if (row && row?.details) {
              return row?.details?.absent_days + row?.details?.un_paid_leaves;
            }
          },
        },
      ]}
    />
  );
};

export const TeamEmployeeDailyAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    params: {period: attendancePeriod = {}} = {},
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/attendance/self'}
      params={params}
      eventSourceId={['myAttendance', 'allattendance']}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Date',
          width: 220,
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {row.attendance_date &&
                  moment(row.attendance_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'In Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>{row?.in_time}</Text>
            );
          },
        },
        {
          header: 'Out Time',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>
                {row?.out_time}
              </Text>
            );
          },
        },
        {
          header: 'Duration',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            let value = (row && row['time_in_office']) || null;
            if (!value) return void 0;
            let duration = '';
            let defaultDuration = 60;
            let hours = value && value / defaultDuration;
            let minute = value && value % defaultDuration;
            if (hours && hours > 0) {
              hours = Math.floor(hours);
              duration += hours + 'h ';
            }
            if (minute) {
              minute = Math.round(minute);
              duration += minute + 'm';
            }
            return (
              <Text style={{...rowText, color: '#737373'}}>{duration}</Text>
            );
          },
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.first_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderAttendance({
              name: row?.second_attendance_type_id?.name,
              styles: styles,
            }),
        },
        {
          header: 'Timesheet Hours',
          field: 'progress_hours',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText, color: '#A3A3A3'}}>
                {`${row?.progress_hours}h`}
              </Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Update Attendance',
          onPress: ({row}) => {
            navigation.navigate('update-attendance-HR', {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Punch Data',
          onPress: ({row}) => {
            navigation.navigate('punch-data-screen', {row});
          },
        },
        {
          title: 'Allocation',
          onPress: ({row}) => {
            navigation.navigate('allocation-list', {
              filterParams: {
                employee: row?.employee._id,
              },
              period: attendancePeriod,
              fromAttendence: true,
            });
          },
          visible: () => {
            if (
              params?.row?.tab === 'project' ||
              params?.row?.tab === 'primary'
            )
              return true;
          },
        },
      ]}
    />
  );
};
