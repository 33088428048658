import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const MarkUnusableForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetUnusable',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      fields={{
        reason: 1,
      }}
      header={{
        title: 'Mark unusable',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Unusable',
            unusable_date: getZeroTimeDate(new Date()),
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Reason',
          field: 'reason',
          type: 'autoComplete',
          options: ['To be repaired', 'Broken & unrepairable', 'To be donated'],
          required: true,
        },
        {
          label: 'Fault type',
          field: 'type_of_fault',
          type: 'autoComplete',
          options: ['Damage', 'Specific part not working', 'Dead'],
        },
        {
          label: 'Damaged Part',
          field: 'fault_type_detail',
          type: 'multiAutoComplete',
          options: [
            'A Cover',
            'B Cover',
            'C Cover',
            'D Cover',
            'A,B Cover',
            'Display',
            'Keyboard',
            'Touchpad',
            'Charging Port',
            'HDMI Port',
            'USB Port',
            'Thunderbolt Port',
            'Type C Port',
            'LAN Port',
            'AUX Port',
          ],
          visible: ({values}) => values?.type_of_fault === 'Damage',
        },
        {
          label: 'Non-fuctional Part',
          field: 'fault_type_detail',
          type: 'multiAutoComplete',
          options: [
            'A Cover',
            'B Cover',
            'C Cover',
            'D Cover',
            'A,B Cover',
            'Display',
            'Keyboard',
            'Touchpad',
            'Charging Port',
            'HDMI Port',
            'USB Port',
            'Thunderbolt Port',
            'Type C Port',
            'LAN Port',
            'AUX Port',
          ],
          visible: ({values}) =>
            values?.type_of_fault === 'Specific part not working',
        },
        {
          label: 'Fault classification',
          field: 'fault_type_detail',
          type: 'autoComplete',
          options: [
            'Liquid Damage',
            'Physical Damage',
            ' Technical Issues in the Board',
          ],
          visible: ({values}) => values?.type_of_fault === 'Dead',
        },
      ]}
    />
  );
};

export const MarkEndOfLifeForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetUnusable',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      header={{
        title: 'Mark End of life',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'End of life',
            end_of_life_date: getZeroTimeDate(new Date()),
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Category',
          field: 'end_of_life_category',
          type: 'autoComplete',
          options: ['Ready to Scrap', 'Ready to Donate', 'Ready for E-Waste'],
          required: true,
        },
      ]}
    />
  );
};
