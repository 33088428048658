import React from 'react';
import {AddButton} from '../../../../components/button/AddButton';
import {GroupFilter, PeriodRangeFilter} from '../../../../components/filter/Filters';
import {TabView} from '../../../../components/tab/TabView';
import {useFilter} from '../../../../controllers/useFilter';
import {
  AllAttendanceTable,
  AllLeaveRegisterTable,
  AllLOPTable,
} from './AllAttendanceTable';
import { getCurrentMonth } from '@unthinkable/react-filters';
import { AttendanceAllGroupTable } from '../../../employee/views/DepartmentwiseAttendance';

export const AttendanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: {filterParams, period: periods} = {}} = filterValues || {};
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    departmetnwise: {
      label: 'All',
      view: (
        <AttendanceAllGroupTable
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'alllop',
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: ({values}) => {
                  return {
                    employee_status : {$in : ['Active','Inactive']}
                  }
              },
            },
            {
              type: 'autoComplete',
              label: 'Department',
              api: '/activeDepartmentSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'department',
              searchFields: ['name'],
            },
            {
              type: 'autoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              searchFields: ['name'],
            },
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthtypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yeartypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    AllLOP: {
      label: 'Monthly Attendance',
      view: (
        <AllLOPTable
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'alllop',
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: ({values}) => {
                return {
                  employee_status : {$in : ['Active','Inactive']}
                }
            },
            },
            {
              type: 'autoComplete',
              label: 'Department',
              api: '/activeDepartmentSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'department',
              searchFields: ['name'],
            },
            {
              type: 'autoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              searchFields: ['name'],
            },
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthtypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yeartypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    AllLEaveRegister: {
      label: 'Leave Register',
      view: (
        <AllLeaveRegisterTable
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'allleaveregister',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: ({values}) => {
                return {
                  employee_status : {$in : ['Active','Inactive']}
                }
            },
            },
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthtypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yeartypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    all: {
      label: 'Daywise Attendance',
      view: (
        <AllAttendanceTable
          filter={filter}
          params={params}
          periods={periods}
          tab="daywiseAttendance"
          {...props}
        />
      ),
      eventSourceId: 'allattendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: ({values}) => {
                return {
                  employee_status : {$in : ['Active','Inactive']}
                }
            },
            },
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
            {
              type: 'autoComplete',
              field: 'is_roster_day',
              label: 'Is Roster',
              options: ['true', 'false'],
            },
          ]}
        />,
        <AddButton title={'Attendance'} view={'add-attendance'} />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const AttendanceTabForVerifiedOrder = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: {filterParams, period: periods} = {}} = filterValues;
  let {
    organization,
    product,
    period,
    row = {},
    attendanceFilter = {},
    ...rest
  } = params;
  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const employeeIds = row?.project_resource_employee?.map(emp => emp?._id);
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllAttendanceTable
          filter={{
            ...filter,
            ...attendanceFilter,
            employee: {
              $in: employeeIds,
            },
          }}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      api: '/attendance/all',
      addOnFilter: {
        ...filter,
        ...attendanceFilter,
        employee: {
          $in: employeeIds,
        },
      },
      params: {
        ...params,
        periods,
      },
      eventSourceId: 'allattendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
