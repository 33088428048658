import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useInvoke} from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';

export const MyOrdersTargetTable = props => {
  let {
    route: {params},
  } = props;
  const {filter = {}} = params;
  const {user} = useAppStateContext();
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'OrderTarget',
  });

  return (
    <Table
      eventSourceId={['OrderTarget']}
      api={`/ordertargets`}
      filter={{...filter}}
      renderHeader={() => {
        return <TableHeader title="Target Hours" />;
      }}
      fields={{
        customer: {_id: 1, name: 1},
        order: {_id: 1, order_number: 1},
        employee: {_id: 1, name: 1, official_email_id: 1, color: 1},
        from_date: 1,
        to_date: 1,
        target_hours: 1,
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.customer?.name}
                items={[
                  {
                    value: row?.order?.order_number,
                  },
                ]}
              />
            );
          },
        },
        {field: 'from_date', header: 'From Date', type: 'date'},
        {field: 'to_date', header: 'To Date', type: 'date'},
        {
          field: 'target_hours',
          header: 'Target hours',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            props.navigation.navigate('update-order-target', {
              targetOrder: {...row, project: params?.project},
            });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/ordertargets/${row?._id}`,
            });     
          },
          visible: user?.email === 'amit.singh@daffodildb.com',
        },
      ]}
    />
  );
};
