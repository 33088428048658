import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Durations} from '../../order/views/components/Durations';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {GroupBy} from '../../../components/table/GroupBy';
import {TabView} from '../../../components/tab';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useFilter} from '@unthinkable/react-filters';

const PROJECTANDCUSTOMER = {
  header: 'Project',
  //   align: 'center',
  //   width: 150,
  // width: 300,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.project?.project}
        items={[
          {
            value: row?.customer?.name,
          },
        ]}
      />
    );
  },
};

export const ProjectAuditTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/projectaudits`}
      filter={{project: row?._id}}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return (
          <TableHeader title="Project Audits" secondaryTitle={row?.project} />
        );
      }}
      fields={{
        auditer: {
          name: 1,
          color: 1,
        },
        audit_comments: 1,
        audit_date: 1,
        next_audit_date: 1,
        total_score: 1,
      }}
      sort={{plan_project_audit: -1}}
      onRowPress={({row}) => {
        navigation.navigate('update-project-audit-plan', {
          row,
          fromHistory: true,
        });
      }}
      columns={[
        {
          field: 'auditer',
          type: 'userAvatarChip',
          header: 'Auditer',
        },
        {
          field: 'audit_date',
          type: 'date',
          header: 'Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'next_audit_date',
          type: 'date',
          header: 'Next Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'total_score',
          type: 'number',
          header: 'Score',
        },
        {
          field: 'audit_comments',
          type: 'text',
          header: 'Comments',
        },
      ]}
    />
  );
};

export const ProjectAuditTableForKpi = props => {
  const {
    route: {params = {}},
    navigation,
    groupBy,
    ...rest
  } = props;
  const {period = {}} = params;
  const addOnFilter = {
    audit_date: {
      $gte: period.from,
      $lte: period.to,
    },
  };

  return (
    <Table
      {...rest}
      params={{...params, period}}
      api={`/monthwise-project-audit-data`}
      eventSourceId={'performance-report'}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        {
          field: 'project.project',
          type: 'colortag',
          header: 'Project',
        },
        {
          field: 'auditer',
          type: 'userAvatarChip',
          header: 'Auditer',
        },
        {
          field: 'project.owner_id',
          type: 'userAvatarChip',
          header: 'PM',
        },
        {
          header: 'Type',
          type: 'text',
          field: 'project.new_project_sub_type.project_sub_type',
        },
        {
          field: 'audit_date',
          type: 'date',
          header: 'Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'next_audit_date',
          type: 'date',
          header: 'Next Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'total_score',
          type: 'number',
          header: 'Score',
        },
        {
          field: 'audit_comments',
          type: 'text',
          header: 'Comments',
        },
      ]}
    />
  );
};

export const ProjectCSATTableForKpi = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {period = {}} = params;
  const addOnFilter = {
    client_feedback_from_date: {$gte: period.from},
    client_feedback_to_date: {$lt: period.to},
    feedback_requested: {$in: [null, false]},
  };
  return (
    <GroupTable
      {...props}
      params={{...params, period}}
      api={`/monthwise-project-csat-data-grouped/kpi`}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return <TableHeader title="Project CSAT" />;
      }}
      fields={{
        project: {project: 1},
        customer: {name: 1},
        client_score: 1,
        client_feedback_from_date: 1,
        client_feedback_to_date: 1,
        rating: 1,
        message: 1,
        description: 1,
      }}
      // addOnFilter={addOnFilter}
      // sort={{client_score: -1}}
      // onRowPress={({row}) => {
      //   navigation.navigate('update-project-audit-plan', {
      //     row,
      //     fromHistory: true,
      //   });
      // }}
      groupRow={{
        data: 'children',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} CSAT`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        PROJECTANDCUSTOMER,
        {
          header: 'PM',
          type: 'userAvatar',
          field: 'project.owner_id',
          width: 100,
        },
        {
          header: 'Type',
          type: 'text',
          field: 'project.new_project_sub_type.project_sub_type',
          width: 180,
        },
        {
          header: 'Submitted On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.client_feedback_to_date,
              fromDate: row?.client_feedback_from_date,
            });
          },
          width: 250,
        },
        {
          field: 'client_score',
          type: 'number',
          header: 'Score',
        },
        // {
        //   field: 'rating',
        //   type: 'text',
        //   header: 'Rating',
        // },
        {
          field: 'description',
          type: 'text',
          header: 'Messagge',
        },
      ]}
    />
  );
};

export const ProjectAuditTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Owner',
        field: 'project.practice_owner',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem
                  value={row?.project?.primary_practice?.owner?.name || ''}
                />
                <GroupContentItem value={`Avg Score ${row?.avg_score}`} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const tabs = {
    project_audit: {
      label: 'Project Audits',
      view: (
        <ProjectAuditTableForKpi
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          period={period}
          groupBy={groupBy}
        />
      ),

      actions: [<GroupBy {...groupBy} />],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
