import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddLOIForm = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
  });

  return (
    <Form
      header={{
        title: 'Add LOI Details',
        secondaryTitle: candidate?.name,
      }}
      onSubmit={onSubmit}
      mode="edit"
      api={'/candidates/' + candidate?._id}
      layoutFields={[
        {
          label: 'Organisation Details',
          fields: [
            {
              field: 'organization',
              label: 'Organization',
              type: 'autoComplete',
              api: '/organizations',
              suggestionField: 'name',
              size: 'medium',
              required: true,
            },
            {
              field: 'department',
              label: 'Department',
              type: 'autoComplete',
              api: '/activeDepartmentSuggestions',
              suggestionField: 'name',
              size: 'medium',
              required: true,
            },
            {
              field: 'designation',
              label: 'Designation',
              type: 'autoComplete',
              api: '/designations',
              suggestionField: 'name',
              size: 'medium',
              required: true,
            },
            {
              type: 'date',
              field: 'tentative_joining_date',
              label: 'Joining Date',
              size: 'medium',
              required: true,
            }
          ],
        },
        {
          label: 'Personal Details',
          fields: [
            {
              type: 'autoComplete',
              field: 'gender',
              label: 'Gender',
              options: ['Male', 'Female'],
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'father_name',
              label: "Father's Name",
              size: 'medium',
              required: true,
            },
            {
              type: 'textArea',
              field: 'address_line_1',
              label: 'Address Line 1',
              size: 'medium',
              required: true,
            },
            {
              field: 'address_line_2',
              label: 'Address Line 2',
              size: 'medium',
              type: 'textArea',
            },
            {
              field: 'pin',
              label: 'Pincode',
              type: 'number',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'city',
              label: 'City',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'state',
              label: 'State',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'country',
              label: 'Country',
              size: 'medium',
              required: true,
            },
          ],
        },
      ]}
      fields={{
        department: {
          name: 1,
        },
        gender: 1,
        father_name: 1,
        address_line_1: 1,
        address_line_2: 1,
        pin: 1,
        city: 1,
        state: 1,
        country: 1,
        organization: {
          name: 1,
        },
        designation: {
          name: 1,
        },
        tentative_joining_date: 1,
      }}
    />
  );
};
