import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {
  ActiveResignationEnggTable,
  AllEnggResignationGroupTable,
  ClosedClosedGroupTable,
} from './ResignationTables';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate, useFilter} from '@unthinkable/react-filters';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const EnggResginationTabs = props => {
  let {route} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {params = {}, filter} = filterValues;
  let addOnFilter = {};
  addOnFilter = {...addOnFilter, ...filter};
  const tabs = {
    All: {
      label: 'All',
      view: (
        <AllEnggResignationGroupTable
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),

      params: {
        ...params,
      },
      api: '/employees/all-engineering-resigned',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
          ]}
        />,
      ],
    },
    Active: {
      label: 'Active',
      view: (
        <ActiveResignationEnggTable
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),
      params: filterValues?.filter,
      api: '/employees/engineering-active-resignations',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'resign_date_new',
              label: 'Resign Date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
            {
              type: 'autoComplete',
              label: 'Replacement Required',
              field: 'replacement_required',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
          ]}
        />,
      ],
    },
    Closed: {
      label: 'Closed',
      view: (
        <ClosedClosedGroupTable
          filterValues={filterValues}
          searchValue={searchValue}
          {...props}
        />
      ),
      params: {
        ...params,
      },
      api: '/employees/closed-engineering-resigned',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
          ]}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
