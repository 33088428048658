import {
  Col,
  Image,
  Linking,
  Platform,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {TextRenderer} from '../../../components/form-editors/Editors';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {GroupTable, Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {
  addLOIDetails,
  checkPackageBreakdown,
  downloadLoiWithLetterHead,
  downloadLoiWithoutLetterHead,
  markJoined,
  moveToAnotherJobOpening,
  moveToInterview,
  offerJob,
  putOnHoldCandidate,
  rejectCandidate,
} from '../controllers/CandidateController';
import {multipleFileRenderer} from '../controllers/RecruitmentColumnRenderer';
import {RenderRejection} from './RenderRejection';
import {useToast} from '@unthinkable/react-toast';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useNavigation} from '@react-navigation/native';

const RenderMobile = ({row, navigation, skipEdit}) => {
  const icons = useTheme('icons');
  const toast = useToast();
  if (!row.mobile && !skipEdit) {
    return (
      <TouchableOpacity onPress={() => editCandidate({navigation, row})}>
        <Image
          source={icons.AddPlus}
          style={{
            width: 16,
            height: 16,
          }}
        />
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity
      onPress={() => {
        if (Platform.OS === 'web') {
          navigator.clipboard.writeText(row.mobile);
          toast({message: 'Mobile number copied.'});
        }
      }}>
      <Image
        title={row.mobile}
        source={icons.phone}
        style={{width: 24, height: 24}}
      />
    </TouchableOpacity>
  );
};

const RenderAddedOn = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  if (row?.candidate_created_on) {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
        }}>
        {moment(row.candidate_created_on).format('DD MMM, YY')}
      </Text>
    );
  } else {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
        }}>
        {moment(row.createdAt).format('DD MMM, YY')}
      </Text>
    );
  }
};

const RenderLink = ({row, navigation, skipEdit}) => {
  const {portfolio_link} = row;
  const {icons} = useTheme();
  if (!portfolio_link && !skipEdit) {
    return (
      <TouchableOpacity onPress={() => editCandidate({navigation, row})}>
        <Image
          source={icons.AddPlus}
          style={{
            width: 16,
            height: 16,
          }}
        />
      </TouchableOpacity>
    );
  }
  const formattedLink = portfolio_link?.includes('http')
    ? portfolio_link
    : `https://${portfolio_link}`;
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(formattedLink, '_blank');
      }}>
      <Image
        source={icons.Link}
        style={{
          width: 24,
          height: 24,
        }}
      />
    </TouchableOpacity>
  );
};

const RenderInterviewRound = ({round, row, styles, navigation, fromClosed}) => {
  const roundWiseFeedbacks = {
    1: 'first_round_interview_feedback',
    2: 'second_round_interview_feedback',
    3: 'third_round_interview_feedback',
    4: 'fourth_round_interview_feedback',
    5: 'hr_round_interview_feedback',
  };
  const value = row?.[roundWiseFeedbacks?.[round]]?.[0];
  const {current_round} = row;
  const colors = useTheme('colors');
  const fonts = useTheme('fonts');
  if (!value && (current_round === round || round === 5) && !fromClosed)
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate('schedule-interview', {
            candidate: row,
            field: roundWiseFeedbacks[round],
          })
        }>
        <Text
          style={{
            ...styles.text,
            color: colors['INFORMATION_HIGH'],
          }}>
          Schedule
        </Text>
      </TouchableOpacity>
    );
  if (!value) return <GroupContentItem value={'-'} />;
  const {overall_feedback = 0, interviewer} = value;
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback', {
          candidate: row,
          field: roundWiseFeedbacks[round],
          feedback: value,
          round,
          editable: false,
        });
      }}>
      <Col
        style={{
          justifyContent: 'center',
        }}>
        {value.status == 'Active' ? (
          <Text
            style={{
              ...fonts.BODY2,
              color: colors['NEUTRAL_LOW'],
              textAlign: 'center',
            }}>
            Pending
          </Text>
        ) : (
          <RatingRenderer value={overall_feedback} />
        )}
        <Text
          title={interviewer?.name}
          style={{
            ...fonts.CAPTION_SMALL,
            color: colors['NEUTRAL_LOW'],
            numberOfLines: 1,
          }}>
          {interviewer?.name}
        </Text>
      </Col>
    </TouchableOpacity>
  );
};

const RenderGiveFeedback = ({row, navigation}) => {
  const {user} = useAppStateContext();
  const roundWiseFeedbacks = [
    'first_round_interview_feedback',
    'second_round_interview_feedback',
    'third_round_interview_feedback',
    'fourth_round_interview_feedback',
    'hr_round_interview_feedback',
  ];

  let myRound;

  roundWiseFeedbacks.forEach(round => {
    if (row?.[round]?.[0]?.interviewer === user?.employee?._id) {
      myRound = round;
    }
  });

  const value = row?.[myRound]?.[0];

  const {icons} = useTheme();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback', {
          candidate: row,
          field: myRound,
          feedback: value,
          editable: true,
        });
      }}>
      <Image source={icons.AddPlus} />
    </TouchableOpacity>
  );
};

const RenderDepartment = ({row, ...rest}) => {
  const {colors, fonts} = useTheme();
  return (
    <Col>
      <Text
        title={row?.job_opening?.position_title?.name}
        style={{...rest.styles.text, numberOfLines: 1}}>
        {row?.job_opening?.position_title?.name}
      </Text>
      <Text
        title={row?.job_opening?.department?.name}
        style={{
          ...fonts.BODY2,
          color: colors.NEUTRAL_MEDIUM,
          numberOfLines: 1,
        }}>
        {row?.job_opening?.department?.name}
      </Text>
    </Col>
  );
};

const RenderFirstInterviewFeedback = ({row, ...rest}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback-detail', {
          feedback: row?.first_round_interview_feedback[0],
        });
      }}>
      <Col>
        <TextRenderer
          {...rest}
          value={row?.first_round_interview_feedback[0]?.round_verdict}
        />
        <GroupContentItem
          {...rest}
          value={
            row?.first_round_interview_feedback[0]?.feedback_date
              ? moment(
                  row?.first_round_interview_feedback[0]?.feedback_date,
                ).format('DD MMM YY')
              : ''
          }
        />
      </Col>
    </TouchableOpacity>
  );
};

const RenderSecondInterviewFeedback = ({row, ...rest}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback-detail', {
          feedback: row?.second_round_interview_feedback[0],
        });
      }}>
      <Col>
        <TextRenderer
          {...rest}
          value={row?.second_round_interview_feedback[0]?.round_verdict}
        />
        <GroupContentItem
          {...rest}
          value={
            row?.second_round_interview_feedback[0]?.feedback_date
              ? moment(
                  row?.second_round_interview_feedback[0]?.feedback_date,
                ).format('DD MMM YY')
              : ''
          }
        />
      </Col>
    </TouchableOpacity>
  );
};

const RenderThirdInterviewFeedback = ({row, ...rest}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback-detail', {
          feedback: row?.third_round_interview_feedback[0],
        });
      }}>
      <Col>
        <TextRenderer
          {...rest}
          value={row?.third_round_interview_feedback[0]?.round_verdict}
        />
        <GroupContentItem
          {...rest}
          value={
            row?.third_round_interview_feedback[0]?.feedback_date
              ? moment(
                  row?.third_round_interview_feedback[0]?.feedback_date,
                ).format('DD MMM YY')
              : ''
          }
        />
      </Col>
    </TouchableOpacity>
  );
};

const RenderFourthInterviewFeedback = ({row, ...rest}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback-detail', {
          feedback: row?.fourth_round_interview_feedback[0],
        });
      }}>
      <Col>
        <TextRenderer
          {...rest}
          value={row?.fourth_round_interview_feedback[0]?.round_verdict}
        />
        <GroupContentItem
          {...rest}
          value={
            row?.fourth_round_interview_feedback[0]?.feedback_date
              ? moment(
                  row?.fourth_round_interview_feedback[0]?.feedback_date,
                ).format('DD MMM YY')
              : ''
          }
        />
      </Col>
    </TouchableOpacity>
  );
};

const RenderHRInterviewFeedback = ({row, ...rest}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('candidate-feedback-detail', {
          feedback: row?.hr_round_interview_feedback[0],
        });
      }}>
      <Col>
        <TextRenderer
          {...rest}
          value={row?.hr_round_interview_feedback[0]?.round_verdict}
        />
        <GroupContentItem
          {...rest}
          value={
            row?.hr_round_interview_feedback[0]?.feedback_date
              ? moment(
                  row?.hr_round_interview_feedback[0]?.feedback_date,
                ).format('DD MMM YY')
              : ''
          }
        />
      </Col>
    </TouchableOpacity>
  );
};

const editCandidate = ({navigation, row}) => {
  navigation.navigate('edit-candidate', {
    candidateId: row?._id,
  });
};

const RenderCV = ({row, navigation, skipEdit}) => {
  const {icons} = useTheme();
  if (row?.candidate_resume?.length) {
    return multipleFileRenderer({row, field: 'candidate_resume'});
  } else if (!skipEdit) {
    return (
      <TouchableOpacity onPress={() => editCandidate({navigation, row})}>
        <Image
          source={icons.AddPlus}
          style={{
            width: 16,
            height: 16,
          }}
        />
      </TouchableOpacity>
    );
  }
};

const RenderStatus = ({row, styles}) => {
  const {
    status,
    joining_date,
    rejected_date,
    offer_rejected_date,
    dropping_date,
  } = row;
  const {colors} = useTheme();
  const concernedDate =
    joining_date || rejected_date || offer_rejected_date || dropping_date;

  return (
    <View
      style={{
        margin: -16,
        padding: 16,
        backgroundColor:
          status == 'Joined' ? colors['SUCCESS_LOW'] : colors['ERROR_LOW'],
        width: 150,
      }}>
      <Text
        style={{
          ...styles.text,
          color:
            status == 'Joined' ? colors['SUCCESS_HIGH'] : colors['ERROR_HIGH'],
          numberOfLines: 1,
        }}>
        {status}
      </Text>
      <GroupContentItem
        value={concernedDate ? moment(concernedDate).format('DD MMM YY') : '-'}
      />
    </View>
  );
};

const RenderDraftCandidateStatus = ({row, styles}) => {
  const {status, rejected_date, draft_candidate_active_date} = row;
  const {colors} = useTheme();
  const concernedDate = draft_candidate_active_date || rejected_date;

  return (
    <View
      style={{
        margin: -16,
        padding: 16,
        backgroundColor:
          status == 'Sortlisted' ? colors['SUCCESS_LOW'] : colors['ERROR_LOW'],
        width: 150,
        alignItems: 'center',
      }}>
      <Text
        style={{
          ...styles.text,
          color:
            status == 'Sortlisted'
              ? colors['SUCCESS_HIGH']
              : colors['ERROR_HIGH'],
          numberOfLines: 1,
        }}>
        {status}
      </Text>
      <GroupContentItem
        value={concernedDate ? moment(concernedDate).format('DD MMM YY') : '-'}
      />
    </View>
  );
};

const RenderOfferStatus = ({row, styles, navigation}) => {
  const {status} = row;
  const {colors} = useTheme();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });
  if (status == 'Offer Rejected')
    return (
      <Text style={{...styles.text, color: colors['ERROR_HIGH']}}>
        Rejected
      </Text>
    );
  else
    return (
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('mark-candidate-accepted', {
              candidate: row,
            });
          }}>
          <Text style={{...styles.text, color: colors['SUCCESS_HIGH']}}>
            Accepted
          </Text>
        </TouchableOpacity>
        <View
          style={{
            width: 1,
            backgroundColor: colors['INFORMATION_MEDIUM'],
          }}
        />
        <TouchableOpacity
          onPress={() =>
            invoke({
              uri: `/candidates/${row._id}`,
              props: {
                status: 'Offer Rejected',
              },
            })
          }>
          <Text style={{...styles.text, color: colors['ERROR_HIGH']}}>
            Rejected
          </Text>
        </TouchableOpacity>
      </Row>
    );
};

const RenderDrop = ({row, styles}) => {
  const {status, dropping_date, offer_rejected_date} = row;
  const colors = useTheme('colors');
  const concernedDate =
    status == 'Offer Rejected' ? offer_rejected_date : dropping_date;
  return (
    <View
      style={{
        margin: -12,
        padding: 12,
        backgroundColor: colors['ERROR_LOW'],
        width: 140,
      }}>
      <Text
        style={{
          ...styles.text,
          color: colors['ERROR_HIGH'],
        }}>
        {status}
      </Text>
      <GroupContentItem
        value={concernedDate ? moment(concernedDate).format('DD MMM YY') : '-'}
      />
    </View>
  );
};

const RenderSelectedAction = ({styles, navigation, params, row}) => {
  const {is_parichay_form_shared, is_employee_created} = row;
  const {colors} = useTheme();
  return (
    <Row gap={4}>
      {!is_parichay_form_shared && !is_employee_created ? (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('add-employee-user-form', {
              defaultValues: {
                name: row.name,
                email: row.email,
                candidate: row?._id,
              },
              updateCandidate: true,
              candidate: row?._id,
            });
          }}>
          <Text
            style={{
              ...styles.text,
              color: colors['INFORMATION_HIGH'],
              numberOfLines: 1,
            }}>
            Share parichay form
          </Text>
        </TouchableOpacity>
      ) : (
        void 0
      )}
      {!is_parichay_form_shared && !is_employee_created ? (
        <View style={{width: 1, backgroundColor: colors['INFORMATION_HIGH']}} />
      ) : (
        void 0
      )}
      {!is_parichay_form_shared && !is_employee_created ? (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('add-employee-form', {
              defaultValues: {
                candidate: row?._id,
                organization: row.organization,
                name: row.name,
                joining_date: row.joining_date,
                product: row.product,
                designation: row?.job_opening?.position_title,
                employee_team: row?.job_opening?.department,
              },
            });
          }}>
          <Text
            style={{
              ...styles.text,
              color: colors['INFORMATION_HIGH'],
              numberOfLines: 1,
            }}>
            Create employee
          </Text>
        </TouchableOpacity>
      ) : (
        void 0
      )}
    </Row>
  );
};

//Candidate Overview from job position

export const ActiveCandidate = props => {
  const {navigation, fromAll} = props || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });

  const {download} = useAppStateContext();

  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        createdAt: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        mobile: 1,
      }}
      filter={{
        status: {$in: ['Sortlisted', 'Interview', 'Offered', 'Offer Accepted']},
      }}
      columns={[
        {
          header: 'Added on',
          render: RenderAddedOn,
          // field: 'candidate_created_on',
          // type: 'date',
          // formatOptions: {
          //   format: 'DD MMM YY',
          // },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',

          render: RenderMobile,
          navigation,
          width: 80,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          visible: !!fromAll,
        },
        {
          header: 'Round 1',
          round: 1,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 2',
          round: 2,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 3',
          round: 3,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 4',
          round: 4,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'HR Round',
          round: 5,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={() => [
        {
          title: 'Move Candidate To Next Round',
          onPress: ({row}) => {
            invoke({
              uri: '/candidates/' + row._id,
              props: {
                current_round: row.current_round + 1,
              },
            });
          },
          confirm: {
            title: 'Are you sure?',
            message: 'This will move candidate to next round.',
          },
          visible: ({row}) =>
            row.current_round < 4 && row.status == 'Interview',
        },
        moveToInterview({invoke}),
        offerJob({navigation}),
        addLOIDetails({navigation}),
        downloadLoiWithLetterHead({download}),
        downloadLoiWithoutLetterHead({download}),
        {
          title: 'Mark Offer Accepted',
          onPress: ({row}) =>
            navigation.navigate('mark-candidate-accepted', {
              candidate: row,
            }),
          visible: ({row}) => row?.status == 'Offered',
        },
        {
          title: 'Mark Offer Rejected',
          onPress: ({row}) =>
            invoke({
              uri: `/candidates/${row._id}`,
              props: {
                status: 'Offer Rejected',
              },
            }),
          visible: ({row}) => row?.status == 'Offered',
        },
        markJoined({navigation}),
        rejectCandidate({navigation}),
        putOnHoldCandidate({navigation}),
        moveToAnotherJobOpening({navigation}),
      ]}
      {...props}
    />
  );
};

export const ClosedCandidate = props => {
  const {addOnFilter = {}, fromAll, navigation} = props || {};

  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        createdAt: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        joining_date: 1,
        rejected_date: 1,
        offer_rejected_date: 1,
        dropping_date: 1,
        mobile: 1,
      }}
      filter={{
        status: {$in: ['Offer Rejected', 'Joined', 'Rejected', 'Dropped']},
      }}
      eventSourceId="candidate"
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Added on',
          render: RenderAddedOn,
          // field: 'candidate_created_on',
          // type: 'date',
          // formatOptions: {
          //   format: 'DD MMM YY',
          // },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',

          render: RenderMobile,
          navigation,
          width: 80,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        // {
        //   header: 'PF',
        //   render: RenderLink,
        //   navigation,
        // },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          visible: !!fromAll,
        },
        {
          header: 'Round 1',
          round: 1,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
          fromClosed: true,
        },
        {
          header: 'Round 2',
          round: 2,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
          fromClosed: true,
        },
        {
          header: 'Round 3',
          round: 3,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
          fromClosed: true,
        },
        {
          header: 'Round 4',
          round: 4,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
          fromClosed: true,
        },
        {
          header: 'HR Round',
          round: 5,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
          fromClosed: true,
        },
        {
          header: 'Status',
          render: RenderStatus,
          align: 'center',
          width: 150,
        },
      ]}
    />
  );
};

export const OnHoldCandidate = props => {
  let {addOnFilter = {}, fromAll, navigation} = props || {};
  addOnFilter = {...addOnFilter};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });
  return (
    <Table
      api={`/candidates`}
      filter={{
        status: 'On Hold',
      }}
      fields={{
        candidate_created_on: 1,
        createdAt: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        onhold_date: 1,
        mobile: 1,
      }}
      eventSourceId="candidate"
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Added on',
          render: RenderAddedOn,
          // field: 'candidate_created_on',
          // type: 'date',
          // formatOptions: {
          //   format: 'DD MMM YY',
          // },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',

          render: RenderMobile,
          navigation,
          width: 80,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          field: 'job_opening.position_titleinterviewer',
          header: 'Job opening',
          visible: !!fromAll,
        },
        {
          header: 'On hold',
          type: 'date',
          field: 'onhold_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
    />
  );
};

export const DraftCandidate = props => {
  const {navigation} = props || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });

  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        createdAt: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        mobile: 1,
        comment: 1,
        draft_candidate_active_date: 1,
        website_type: 1,
      }}
      filter={{
        status: {$in: ['Draft']},
        candidate_source: 'Website',
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          field: 'createdAt',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,
          width: 80,
          align: 'center',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
          width: 80,
          align: 'center',
        },
        {
          header: 'PF',
          render: RenderLink,
          navigation,
          width: 80,
          align: 'center',
        },
        {
          header: 'Website',
          type: 'text',
          field: 'website_type',
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={() => [
        {
          title: 'Add To This Opening',
          onPress: ({row}) => {
            invoke({
              uri: '/candidates/' + row._id,
              props: {
                status: 'Sortlisted',
                draft_candidate_active_date: new Date(),
              },
            });
          },
          confirm: {
            title: 'Are you sure?',
            message: 'This will make this person an active candidate.',
          },
          visible: ({row}) =>
            row?.job_opening && row.job_opening.position_title ? true : false,
        },
        {
          title: 'Add To Another Job Opening',
          onPress: ({row}) => {
            navigation.navigate('add-job-opening-to-candidate', {
              candidate: row,
            });
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('add-draft-candidate-comment', {
              candidate: row,
            });
          },
          variant: 'error',
        },
      ]}
      {...props}
    />
  );
};

export const DraftCandidateClosedTable = props => {
  const {navigation} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });

  return (
    <GroupTable
      api={`/draft-candidates-grouped-list`}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} candidates`} />
            </RowWithSeparator>
          );
        },
      }}
      limit={500000}
      columns={[
        {
          field: 'createdAt',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
        },
        {
          header: 'Mobile',
          field: 'mobile',
          render: RenderMobile,
          navigation,
          skipEdit: true,
          width: 80,
          align: 'center',
        },
        {
          header: 'CV',
          align: 'center',
          field: 'candidate_resume',
          render: RenderCV,
          navigation,
          skipEdit: true,
          width: 80,
          align: 'center',
        },
        {
          header: 'PF',
          field: 'portfolio_link',
          render: RenderLink,
          navigation,
          skipEdit: true,
          width: 80,
          align: 'center',
        },
        {
          header: 'Website',
          type: 'text',
          field: 'website_type',
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          type: 'text',
        },
        {
          header: 'Current Stage',
          render: RenderDraftCandidateStatus,
          align: 'center',
          width: 120,
          align: 'center',
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={() => [
        {
          title: 'Add To This Opening',
          onPress: ({row}) => {
            invoke({
              uri: '/candidates/' + row._id,
              props: {
                status: 'Sortlisted',
                draft_candidate_active_date: new Date(),
              },
            });
          },
          confirm: {
            title: 'Are you sure?',
            message: 'This will make this person an active candidate.',
          },
        },
        {
          title: 'Add To Another Job Opening',
          onPress: ({row}) => {
            navigation.navigate('add-job-opening-to-candidate', {
              candidate: row,
            });
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('add-draft-candidate-comment', {
              candidate: row,
            });
          },
          variant: 'error',
        },
      ]}
      {...props}
    />
  );
};

// My Candidate feedback

export const MyCandidateFeedbacks = props => {
  const {
    navigation,
    addOnFilter = {},
    fromAll = false,
    filter = {},
    fromOrgAll = false,
  } = props || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: 1,
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: 1,
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: 1,
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: 1,
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: 1,
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        mobile: 1,
      }}
      filter={filter}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
          width: 200,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          visible: !!fromAll,
        },
        // {
        //   header: 'Interview',
        //   render: RenderInterview,
        // },
        !fromOrgAll && {
          header: 'Feedback',
          render: RenderGiveFeedback,
          navigation,
          align: 'center',
        },
      ]}
      eventSourceId={['candidate']}
    />
  );
};

export const MyCandidateClosedFeedbacks = props => {
  const {navigation, addOnFilter = {}, fromAll, filter = {}} = props || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        mobile: 1,
      }}
      filter={filter}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
          width: 200,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          visible: !!fromAll,
        },
        // {
        //   header: 'Interview',
        //   render: RenderInterview,
        // },
        {
          header: 'Round 1',
          render: RenderFirstInterviewFeedback,
        },
        {
          header: 'Round 2',
          render: RenderSecondInterviewFeedback,
        },
        {
          header: 'Round 3',
          render: RenderThirdInterviewFeedback,
        },
        {
          header: 'Round 4',
          render: RenderFourthInterviewFeedback,
        },
        {
          header: 'Hr Round',
          render: RenderHRInterviewFeedback,
        },
        // {
        //   header: 'Feedback',
        //   render: RenderShowFeedback,
        //   navigation,
        //   align: 'center',
        // },
      ]}
      eventSourceId={['candidate']}
    />
  );
};

//Active candidate tabs

export const ShortListedCandidates = props => {
  const {navigation} = props;

  const invoke = useInvoke({
    eventSourceId: ['candidate'],
    method: 'put',
  });

  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        mobile: 1,
      }}
      eventSourceId={['candidate']}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',
          render: RenderLink,
          navigation,
        },
      ]}
      moreActions={[
        {onPress: ({row}) => editCandidate({navigation, row}), title: 'Edit'},
        moveToInterview({invoke}),
        moveToAnotherJobOpening({navigation}),
        putOnHoldCandidate({navigation}),
        rejectCandidate({navigation}),
      ]}
      {...props}
    />
  );
};

export const InterviewCandidates = props => {
  const {navigation, addOn} = props;
  const invoke = useInvoke({
    eventSourceId: ['candidate'],
    method: 'put',
  });
  let filter = {};
  if (addOn) {
    filter = {...filter, ...addOn};
  }
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        mobile: 1,
      }}
      filter={{
        status: 'Active',
        ...filter,
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',
          render: RenderLink,
          navigation,
        },
        {
          header: 'Round 1',
          round: 1,
          render: RenderInterviewRound,
          navigation,
        },
        {
          header: 'Round 2',
          round: 2,
          render: RenderInterviewRound,
          navigation,
        },
        {
          header: 'Round 3',
          round: 3,
          render: RenderInterviewRound,
          navigation,
        },
        {
          header: 'Round 4',
          round: 4,
          render: RenderInterviewRound,
          navigation,
        },
        {
          header: 'HR Round',
          round: 5,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={[
        moveToAnotherJobOpening({navigation}),
        {
          title: 'Move Candidate To Next Round',
          onPress: ({row}) => {
            invoke({
              uri: '/candidates/' + row._id,
              props: {
                current_round: row.current_round + 1,
              },
            });
          },
          confirm: {
            title: 'Are you sure?',
            message: 'This will move candidate to next round.',
          },
        },
        putOnHoldCandidate({navigation}),
        offerJob({navigation}),
        rejectCandidate({navigation}),
      ]}
      {...props}
    />
  );
};

export const OfferedCandidates = props => {
  const {navigation, addOn} = props;
  const {download} = useAppStateContext();
  let filter = {};
  if (addOn) {
    filter = {
      ...filter,
      ...addOn,
    };
  }
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        offer_accepted_date: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        mobile: 1,
      }}
      filter={{
        status: {$in: ['Offered']},
        ...filter,
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 100,
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 300,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          minWidth: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,
          width: 100,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
          width: 150,
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          width: 100,
          navigation,
        },
        {
          header: 'PF',
          width: 100,
          render: RenderLink,
          navigation,
        },
        {
          header: 'Offer Status',
          render: RenderOfferStatus,
          width: 160,
          navigation,
          visible: () => {
            if (!addOn) {
              return true;
            }
          },
        },
        {
          header: 'Offer Accepted Date',
          type: 'date',
          width: 200,
          field: 'offer_accepted_date',
          visible: () => {
            if (addOn) {
              return true;
            }
          },
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={[
        addLOIDetails({navigation}),
        downloadLoiWithLetterHead({download}),
        downloadLoiWithoutLetterHead({download}),
        checkPackageBreakdown({navigation}),
      ]}
      {...props}
    />
  );
};

export const OffereAccetedCandidates = props => {
  const {navigation} = props;
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        mobile: 1,
      }}
      filter={{
        status: 'Offer Accepted',
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          header: 'Accepted Date',
          field: 'offer_accepted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          header: 'Joining',
          field: 'joining_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={[
        markJoined({navigation}),
        checkPackageBreakdown({navigation}),
      ]}
      {...props}
    />
  );
};

export const OnHoldCandidates = props => {
  const {navigation} = props;
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        onhold_date: 1,
        mobile: 1,
      }}
      filter={{
        status: 'On Hold',
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          header: 'On Hold',
          field: 'onhold_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      eventSourceId={['candidate']}
      {...props}
    />
  );
};

//Closed Candidate tabs

export const JoinedCandidates = props => {
  const {navigation} = props;
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        mobile: 1,
      }}
      filter={{
        status: 'Joined',
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          header: 'Accepted Date',
          field: 'offer_accepted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          header: 'Joined',
          field: 'joining_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      eventSourceId={['candidate']}
      {...props}
    />
  );
};

export const RejectedCandidates = props => {
  const {navigation} = props;
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        mobile: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        rejected_date: 1,
        rejected_in: 1,
      }}
      filter={{
        status: 'Rejected',
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          header: 'Accepted Date',
          field: 'offer_accepted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          header: 'Rejection',
          render: RenderRejection,
          align: 'center',
          width: 150,
        },
      ]}
      eventSourceId={['candidate']}
      {...props}
    />
  );
};

export const DroppedCandidates = props => {
  const {navigation} = props;
  return (
    <Table
      api={`/candidates`}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        mobile: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        rejected_date: 1,
        rejected_in: 1,
        dropping_date: 1,
        offer_rejected_date: 1,
      }}
      filter={{
        status: {$in: ['Dropped', 'Offer Rejected']},
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience.experience',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          header: 'Accepted Date',
          field: 'offer_accepted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          header: 'Rejection',
          render: RenderDrop,
          align: 'center',
          width: 150,
        },
      ]}
      eventSourceId={['candidate']}
      {...props}
    />
  );
};

export const SelectedCandidates = props => {
  const {download} = useAppStateContext();
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      api={`/candidates`}
      eventSourceId={['candidate', 'selectedCandidate']}
      fields={{
        candidate_created_on: 1,
        name: 1,
        email: 1,
        mobile: 1,
        experience: {
          experience: 1,
        },
        candidate_resume: 1,
        portfolio_link: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
          department: {
            name: 1,
          },
        },
        current_round: 1,
        status: 1,
        first_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        second_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        third_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        fourth_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        hr_round_interview_feedback: {
          interviewer: {
            name: 1,
          },
          overall_feedback: 1,
          round_verdict: 1,
          deployablity: 1,
          remarks: 1,
          status: 1,
          interview_date: 1,
          feedback_date: 1,
        },
        offer_accepted_date: 1,
        joining_date: 1,
        organization: {
          name: 1,
        },
        product: {
          name: 1,
        },
        is_parichay_form_shared: 1,
        is_employee_created: 1,
      }}
      filter={{
        status: 'Joined',
      }}
      columns={[
        {
          header: 'Accepted Date',
          field: 'offer_accepted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          header: 'Joined',
          field: 'joining_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 80,
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 200,
        },
        {
          header: 'Action',
          render: RenderSelectedAction,
          width: 320,
          align: 'center',
          navigation,
        },
      ]}
      {...props}
      moreActions={[
        downloadLoiWithLetterHead({download}),
        downloadLoiWithoutLetterHead({download}),
      ]}
    />
  );
};

export const RecruiterWiseCandidates = props => {
  const {navigation, fromAll, route} = props || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['candidate'],
  });
  const {params} = route;
  const {download} = useAppStateContext();

  return (
    <Table
      api={`/candidates`}
      data={params.data}
      columns={[
        {
          header: 'Added on',
          render: RenderAddedOn,
          // field: 'candidate_created_on',
          // type: 'date',
          // formatOptions: {
          //   format: 'DD MMM YY',
          // },
        },
        {
          header: 'Name',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          width: 200,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,
          width: 80,
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'string',
          align: 'right',
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
        },
        {
          header: 'PF',

          render: RenderLink,
          navigation,
        },
        {
          field: 'job_opening.position_title.name',
          header: 'Job opening',
          visible: !!fromAll,
        },
        {
          header: 'Round 1',
          round: 1,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 2',
          round: 2,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 3',
          round: 3,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'Round 4',
          round: 4,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        {
          header: 'HR Round',
          round: 5,
          render: RenderInterviewRound,
          navigation,
          align: 'center',
        },
        params?.fromJoining && {
          header: 'Tentative Date',
          type: 'date',
          field: 'tentative_joining_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        params?.fromJoining && {
          header: 'Joining Date',
          type: 'date',
          field: 'tentative_joining_date',
          formatOptions: {format: 'DD MMM YY'},
        },
      ]}
      eventSourceId={['candidate']}
      moreActions={() => [
        {
          title: 'Move Candidate To Next Round',
          onPress: ({row}) => {
            invoke({
              uri: '/candidates/' + row._id,
              props: {
                current_round: row.current_round + 1,
              },
            });
          },
          confirm: {
            title: 'Are you sure?',
            message: 'This will move candidate to next round.',
          },
          visible: ({row}) =>
            row.current_round < 4 && row.status == 'Interview',
        },
        moveToInterview({invoke}),
        offerJob({navigation}),
        addLOIDetails({navigation}),
        downloadLoiWithLetterHead({download}),
        downloadLoiWithoutLetterHead({download}),
        {
          title: 'Mark Offer Accepted',
          onPress: ({row}) =>
            navigation.navigate('mark-candidate-accepted', {
              candidate: row,
            }),
          visible: ({row}) => row?.status == 'Offered',
        },
        {
          title: 'Mark Offer Rejected',
          onPress: ({row}) =>
            invoke({
              uri: `/candidates/${row._id}`,
              props: {
                status: 'Offer Rejected',
              },
            }),
          visible: ({row}) => row?.status == 'Offered',
        },
        markJoined({navigation}),
        rejectCandidate({navigation}),
        putOnHoldCandidate({navigation}),
        moveToAnotherJobOpening({navigation}),
      ]}
      {...props}
    />
  );
};

export const CandidateOfferedKpiTable = props => {
  const {
    navigation,
    route: {params},
    addOnFilter,
  } = props;

  const {period} = params;
  return (
    <GroupTable
      api={'/offeredCandidateData'}
      params={{period, fromdetailView: true}}
      addOnFilter={addOnFilter}
      // renderHeader={props => (
      //   <TableHeader title="Offered Candidates" {...props} />
      // )}
      groupRow={{
        data: '_children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.customer?.position_title?.name} />
              <GroupContentItem value={row?.count} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          field: 'candidate_created_on',
          header: 'Added on',
          type: 'date',
          width: 200,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Candidate',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              official_email_id={row.email}
            />
          ),
          minWidth: 200,
        },
        {
          header: 'Exp.',
          type: 'text',
          field: 'experience',
          width: 150,
        },
        {
          header: 'Position',
          render: RenderDepartment,
          width: 250,
        },
        {
          header: 'Mobile',
          render: RenderMobile,
          navigation,

          width: 100,
        },
        {
          header: 'CV',
          align: 'center',
          render: RenderCV,
          navigation,
          width: 100,
        },
        {
          header: 'Offer Accepted',
          field: 'offer_accepted_date',
          align: 'center',
          type: 'date',
          width: 150,
        },
      ]}
    />
  );
};
