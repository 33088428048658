import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { useInvoke } from '../../../controllers/useInvoke';

const pageSectionCountRender = ({ navigation, website }) => ({
  render: CountCellRender,
  header: 'Sections',
  align: 'center',
  count_field: 'sectionsCount',
  responsive: 'sm',
  // onPlusPress: props => {
  //   const { row } = props;
  //   navigation.navigate(`add-section`, {
  //     page: row?._id,
  //     url: row?.url,
  //     type: row?.type,
  //     website,
  //   });
  // },
  onPress: props => {
    const { row } = props;
    navigation.navigate(`section-table`, {
      name: row?.title,
      filter: {
        page: row?._id,
        website,
      },
      url: row?.url,
      type: row?.type,
      website,
    });
  },
  width: 150,
});

export const UnthinkablePages = props => {
  const { route: { params } = {} } = props;
  const { row = {} } = params || {};
  const website = row?._id;
  const navigation = useNavigation();

  let invoke = useInvoke({ method: 'put', eventSourceId: 'page' });

  const updateStatus = ({ row }) => {
    invoke({
      uri: `/pages/${row?._id}`,
      props: {
        status: row?.status === 'Archive' ? 'Active' : 'Archive',
      },
      success: 'Status updated successfully',
    });
  };


  return (
    <Table
      eventSourceId={["page", "section"]}
      api={`/pages`}
      fields={{
        url: 1,
        title: 1,
        metatitle: 1,
        metadesc: 1,
        type: 1,
        index: 1,
        sections: {
          _id: 1,
        },
        sectionsCount: {
          _id: 1,
        },
        status: 1,
        website: 1,
        header: 1,
      }}
      filter={{
        website,
      }}
      renderHeader={() => (
        <TableHeader
          title="Unthinkable Website Pages"
          actions={[
            <AddButton
              title="Add Page"
              view="add-page" params={{
                website,
              }}
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-page', { row, website });
      }}
      columns={[
        {
          type: 'text',
          field: 'url',
          header: 'URL',
          width: 250,
        },
        {
          type: 'textArea',
          field: 'title',
          header: 'Title',
          width: 150,
        },
        {
          type: 'text',
          field: 'metatitle',
          header: 'Metatitle',
          width: 150,
        },
        {
          type: 'textArea',
          field: 'metadesc',
          header: 'Metadescription',
        },
        {
          type: 'text',
          field: 'header',
          header: 'Header',
          width: 150,
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
          width: 100,
          align: 'center',
        },
        pageSectionCountRender({ navigation, website }),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-page', { row, website });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: `Mark Active`,
            message: 'Are you sure you want to mark active?',
            confirmText: 'Mark Active',
          },
          visible: ({row}) => row?.status === 'Archive',
          onPress: updateStatus,
        },
        {
          title: 'Mark Archive',
          confirm: {
            title: `Mark Archive`,
            message: 'Are you sure you want to mark archive?',
            confirmText: 'Mark Archive',
          },
          visible: ({row}) => !row?.status || row?.status === 'Active',
          onPress: updateStatus,
        },
        {
          title: 'Sandbox Sections',
          onPress: ({ row }) => {
            navigation.navigate(`sandbox-section-table`, {
              name: row?.title,
              filter: {
                page: row?._id,
                website: row?.website,
              },
              url: row?.url,
              type: row?.type,
              website,
            });
          },
        }
      ]}
    />
  );
};
