import {CompositeFieldsRender} from '@unthinkable/react-table';
import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';

import {Chip} from '@unthinkable/react-chip';
import {Col, Image, Linking, Text} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {useFilter} from '@unthinkable/react-filters';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useProjectActions} from '../../pmt/actions/ProjectActions';
import {Button} from '../../../components/button/Button';
import CountCellRender from '../../../app-components/renders/CountCellRender';

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      title={row?.last_communication}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const ContactRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'website',
          type: 'text',
        },
        {field: 'email', type: 'text'},
      ]}
    />
  );
};

const LostReasonRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'lost_description_comment', type: 'text'},
        {
          field: 'lost_date',
          type: 'date',
        },
      ]}
    />
  );
};
const RenderLostStage = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  if (row?.stage && row?.stage?.name == 'Proposal') {
    return (
      <>
        <Tag value={'Opportunity'} color={colors?.ACCENT2_MEDIUM} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.lost_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else if (row?.stage && row?.stage?.name == 'QL') {
    return (
      <>
        <Tag value={'QL'} color={colors?.ACCENT4_MEDIUM} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.lost_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else if (row?.stage && row?.stage?.name == 'Lead') {
    return (
      <>
        <Tag value={'Lead'} color={colors?.ACCENT1_MEDIUM} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.lost_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};
const RenderOpportunity = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (
    row?.opportunity_type &&
    row?.opportunity_type == 'CustomPlatformOpportunity'
  ) {
    return (
      <>
        <Tag value={'Custom Platform'} color={colors?.ACCENT5_MEDIUM} />
        {/* <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.lost_date).format('DD MMM, YY')}
        </Text> */}
      </>
    );
  } else if (
    row?.opportunity_type &&
    row?.opportunity_type == 'StaffAugmentationOpportunity' &&
    row?.skill
  ) {
    const skillName = row?.skill
      ?.map(element => {
        return element?.name;
      })
      .join(', ');
    if (!skillName) return null;
    return (
      <>
        <Tag value={'Staff Augmentation'} color={colors?.ACCENT3_MEDIUM} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {`${skillName} (${row?.no_of_resources} candidates)`}
        </Text>
      </>
    );
  } else if (
    row?.opportunity_type &&
    row?.opportunity_type == 'StaffAugmentationOpportunity' &&
    !row?.skill
  ) {
    return <Tag value={'Staff Augmentation'} color={colors?.ACCENT3_MEDIUM} />;
  } else {
    return null;
  }
};

const RenderLostReason = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  if (
    (row?.lost_description_comment &&
      row?.lost_description_comment == 'Unsubscribed') ||
    row?.lost_description_comment == 'Do Not Call'
  ) {
    return (
      <>
        <Tag
          value={row?.lost_description_comment}
          color={colors?.WARNING_MEDIUM}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {row?.lead_sub_stage}
        </Text>
      </>
    );
  } else if (
    (row?.lost_description_comment &&
      row?.lost_description_comment == 'Not Interested') ||
    row?.lost_description_comment == 'Not Relevant'
  ) {
    return (
      <>
        <Tag
          value={row?.lost_description_comment}
          color={colors?.ERROR_MEDIUM}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {row?.lead_sub_stage}
        </Text>
      </>
    );
  } else if (
    (row?.lost_description_comment &&
      row?.lost_description_comment == 'Rejected') ||
    row?.lost_description_comment == 'Nurturable'
  ) {
    return (
      <>
        <Tag
          value={row?.lost_description_comment}
          color={colors?.ACCENT2_MEDIUM}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {row?.lead_sub_stage}
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Tag value={row?.lost_description_comment} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {row?.lead_sub_stage}
        </Text>
      </>
    );
  }
};

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const underline = row?.website ? {textDecoration: 'underline'} : void 0;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <PrimaryColumnCard
      primaryTitle={row?.name}
      variant="secondary"
      titleOnPress={() => {
        row?.website &&
          Linking.openURL(
            row?.website.startsWith('https')
              ? row?.website
              : `https://${row?.website}`,
            '_blank',
          );
      }}
      items={[
        {
          value: row?.employee_count?.number,
          backgroundColor: row?.employee_count?.color,
          numberOfLines: 1,
          // onPress
        },
        {
          value: row?.country?.name,
          backgroundColor: row?.country?.color,
          numberOfLines: 1,
        },
        {
          value: row?.industry?.name,
          backgroundColor: row?.industry?.name?.color,
          numberOfLines: 1,
        },
      ]}
    />
  );
};

const RenderUqlName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const underline = row?.website ? {textDecoration: 'underline'} : void 0;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  // return (
  //   <TouchableOpacity
  //     onPress={() => {
  //       row?.website &&
  //         Linking.openURL(
  //           row?.website.startsWith('https')
  //             ? row?.website
  //             : `https://${row?.website}`,
  //           '_blank',
  //         );
  //     }}>
  //     <Text
  //       title={row?.name}
  //       style={{
  //         ...rowText,
  //         ...underline,
  //       }}>
  //       {row?.name}
  //     </Text>
  //   </TouchableOpacity>
  // );

  return (
    <PrimaryColumnCard
      primaryTitle={row?.name}
      variant="secondary"
      titleOnPress={() => {
        row?.website &&
          Linking.openURL(
            row?.website.startsWith('https')
              ? row?.website
              : `https://${row?.website}`,
            '_blank',
          );
      }}
      items={[
        {
          value: row?.employee_count?.number,
          backgroundColor: row?.employee_count?.color,
          numberOfLines: 1,
          // onPress
        },
        {
          value: row?.country?.name,
          backgroundColor: row?.country?.color,
          numberOfLines: 1,
        },
        {
          value: row?.industry?.name,
          backgroundColor: row?.industry?.name?.color,
          numberOfLines: 1,
        },
      ]}
    />
  );
};

const RenderUqlSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        title={row?.name}
        style={{
          ...rowText,
        }}>
        {row?.days_difference || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.uql_stage_date).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const RenderLeadCreationSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        title={row?.name}
        style={{
          ...rowText,
        }}>
        {row?.days_difference || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.created_on).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const RenderQlSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        title={row?.name}
        style={{
          ...rowText,
        }}>
        {row?.days_difference || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.sql_stage_date).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const RenderOppSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        title={row?.name}
        style={{
          ...rowText,
        }}>
        {row?.days_difference || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.opportunity_date).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const RenderCustomerSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        title={row?.name}
        style={{
          ...rowText,
        }}>
        {row?.days_difference || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.customer_date).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const NextTaskRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.last_communication) {
    return (
      <>
        <Text
          numberOfLines={1}
          title={row?.next_task}
          style={{
            ...rowText,
          }}>
          {row?.next_task}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {row?.next_task_date
            ? moment(row?.next_task_date).format('DD MMM')
            : null}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
};

const CONTACT = {
  header: 'Contact',
  render: ContactRender,
};

const EMPLOYEE_COUNT = {
  field: 'employee_count.number',
  colorField: 'employee_count.color',
  header: 'Size',
  type: 'colorTag',
};

const LEAD_STATUS = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.lead_status == 'Active') {
    return (
      <Chip
        color={colors.SUCCESS_LOW}
        textColor={colors.SUCCESS_HIGH}
        value={row?.lead_status}
        borderRadius={6}
      />
    );
  } else if (row?.lead_status == 'Lost') {
    if (
      row?.lead_sub_stage == 'Unsubscribed' ||
      row?.lead_sub_stage == 'Nurturable' ||
      row?.lead_sub_stage == 'Rejected'
    ) {
      return (
        <>
          {/* <RowWithSeparator gap={4} style={{alignItems: 'center'}}> */}
          <Chip
            color={colors.ERROR_LOW}
            textColor={colors.ERROR_HIGH}
            value={row?.lead_status}
            borderRadius={6}
          />
          {/* <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            {row?.lost_description_comment}
          </Text> */}
          {/* </RowWithSeparator> */}
          <RowWithSeparator gap={2} style={{alignItems: 'center'}}>
            <Text
              title={`${row?.lead_sub_stage} ${moment(row.lost_date).format(
                'DD MMM, YY',
              )}`}
              // numberOfLines={1}
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              {row?.lead_sub_stage}
            </Text>
            <Text
              numberOfLines={1}
              title={moment(row.lost_date).format('DD MMM, YY')}
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              {moment(row.lost_date).format('DD MMM, YY')}
            </Text>
          </RowWithSeparator>
        </>
      );
    } else {
      return (
        <>
          <Chip
            color={colors.ERROR_LOW}
            textColor={colors.ERROR_HIGH}
            value={row?.lead_status}
            borderRadius={6}
          />
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            {moment(row.lost_date).format('DD MMM, YY')}
          </Text>
        </>
      );
    }
  } else {
    return null;
  }
};

const allLeadStage = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.stage && row?.stage?.name == 'QL') {
    return (
      <>
        <Chip
          color={colors.ACCENT2_LOW}
          textColor={colors.ACCENT2_HIGH}
          value={'QL'}
          borderRadius={6}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.sql_stage_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else if (row?.stage && row?.stage?.name == 'Lead') {
    return (
      <>
        <Chip
          color={colors.ACCENT1_LOW}
          textColor={colors.ACCENT1_HIGH}
          value={'Lead'}
          borderRadius={6}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.uql_stage_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else if (row?.stage && row?.stage?.name == 'Proposal') {
    return (
      <>
        <Chip
          color={colors.ACCENT4_LOW}
          textColor={colors.ACCENT4_HIGH}
          value={'Opportunity'}
          borderRadius={6}
        />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.proposal_stage_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <>
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          color: colors.NEUTRAL_LOW,
        }}>
        {row.lead_score}
      </Text>
    </>
  );
};

const STAGE_DATE = {
  field: 'sql_stage_date',
  header: 'SQL Stage Date',
  type: 'date',
};

const UQL_STAGE_DATE = {
  field: 'uql_stage_date',
  header: 'UQL Date',
  type: 'date',
};

const STAGE = {
  field: 'stage.name',
  colorField: 'stage.color',
  header: 'Stage',
  type: 'colorTag',
};

const CAMPAIGN = {
  field: 'campaign_id.name',
  header: 'Campaign',
  type: 'text',
};

const COUNTRY = {
  field: 'country.name',
  colorField: 'country.color',
  header: 'Country',
  type: 'colorTag',
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  width: 140,
  render: CommunicationRender,
  onPress: communications,
});

const OWNER = {
  header: 'Owner',
  field: 'owner',
  type: 'userAvatar',
  width: 80,
};

const DATE = {
  header: 'Date',
  field: 'sql_stage_date',
  type: 'date',
};

const CHANNEL = {
  header: 'Channel',
  field: 'channel.name',
  colorField: 'channel.color',
  type: 'colorTag',
  width: 160,
};

const BUSINESS_CONSULTANT = {
  header: 'Consultant',
  field: 'business_consultant',
  type: 'userAvatarGroup',
  width: 90,
};

const LEAD_SCORE = {
  header: 'Score',
  field: 'lead_score',
  render: leadScoreRender,
  toFixed: 2,
  width: 80,
  align: 'right',
};

const NEXT_TASK = {
  header: 'Next Task',
  render: NextTaskRender,
  width: 200,
};

const markLeadWon = ({invoke}) => ({
  title: 'Mark Won',
  confirm: {
    title: 'Mark Won',
    message: 'Do you want mark as won?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/customers`,
      props: {
        ...row,
        stage: '53a44d07faba6a0200b67fb5',
        potential_customer: row?._id,
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
      },
    });
  },
});

export const AllLeadTableMenu = props => {
  let {
    route: {params},
    filter,
    navigation,
    searchValue,
    filterParams,
    allLeadApi,
    isNurturable,
    addOnFilter,
    groupBy,
  } = props;
  const {
    communications,
    updateUqlDate,
    updateOwnerRowAction,
    markAsIrrelevant,
    markAsNurturable,
  } = leadControllers(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Lead'],
  });
  const {user, download} = useAppStateContext();
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter: filter,
    searchFields: ['name', 'website', 'email'],
    api: `/potentialcustomers/allLeads`,
  };
  if (addOnFilter) {
    filter = {...filter, ...addOnFilter};
  }
  if (isNurturable) {
    filter = {...filter, is_nurturable: {$exists: true}};
  }
  return (
    <Table
      params={params}
      // {...allLeadApi}
      api={`/potentialcustomers/allLeads`}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      search={searchValue}
      eventSourceId={['Lead', 'communicationAdded']}
      sort={{last_communication_date: -1}}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
          fromAllLead: true,
        });
      }}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Download',
      //       onPress: props =>
      //         download({
      //           uri: '/exportExcelHelper',
      //           props: {
      //             props: {
      //               ...props,
      //               fetchProps,
      //               column: {
      //                 'Lead date': 'created_on',
      //                 Organisation: 'name',
      //                 Website: 'website',
      //                 'Employee count': 'employee_count.number',
      //                 Country: 'country.name',
      //                 Industry: 'industry.name',
      //                 'Lead Score': 'lead_score',
      //                 Channel: 'campaign_type.label',
      //                 Owner: 'owner.name',
      //                 'Last stage': 'stage.name',
      //                 Status: 'lead_status',
      //               },
      //             },
      //           },
      //         }),
      //     },
      //   ],
      // }}
      columns={[
        {
          header: 'Lead date',
          render: RenderLeadCreationSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {...COUNTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {header: 'Last stage', render: allLeadStage, width: 150},
        // {header: 'Status', render: LEAD_STATUS, width: 100},
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Update UQL Date',
          onPress: updateUqlDate,
        },
        {
          title: 'Update Owner',
          onPress: updateOwnerRowAction,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Mark As Irrelevant',
          onPress: markAsIrrelevant,
          visible: ({row}) => {
            return !row?.is_irrelevant;
          },
        },
        {
          title: 'Mark As Nurturable',
          onPress: markAsNurturable,
          visible: ({row}) => {
            return !row?.is_nurturable;
          },
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Update Campaign',
          onPress: ({row}) => {
            row = {
              ...row,
              selectedIds: [row?._id],
            };
            navigation.navigate('update-campaign', {
              ...row,
            });
          },
        },
        user && user.email == 'sushil.nagvan@daffodilsw.com'
          ? {
              title: 'Mark active',
              confirm: {
                title: 'Mark active',
                message: 'Are you sure you want to mark this lead as active?',
              },

              onPress: props => {
                const {row} = props;
                invoke({
                  uri: `/potentialcustomers/${row?._id}`,
                  message: 'Marked active successfully',
                  props: {
                    lead_status: 'Active',
                    lead_sub_stage: null,
                    lost_date: null,
                    lost_description: null,
                    lost_description_comment: null,
                  },
                });
              },
            }
          : null,
      ]}
    />
  );
};

export const UQLTableMenu = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    path,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {
    markLeadLost,
    updateLeadStatus,
    addLeadConsultants,
    updateOwner,
    communications,
    makeLead,
    moveToRPS,
    pageVisits,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  return (
    <Table
      api={`/potentialcustomers/uql`}
      params={params}
      limit={3000}
      eventSourceId={['Lead', 'communicationAdded']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      sort={{
        uql_stage_date: -1,
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead Since',
          render: RenderUqlSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {...COUNTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          field: 'exact_employee_count',
          type: 'text',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Add Consultants',
          onPress: addLeadConsultants,
        },
        {
          title: 'Mark Lost',
          onPress: markLeadLost,
        },
        {
          title: 'Move to QL',
          onPress: makeLead,
        },
        // {
        //   title: 'Move to RPS',
        //   onPress: moveToRPS,
        // },

        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Page Visits',
          onPress: pageVisits,
          visible: ({row}) => {
            return row?.hubspot_contact_id;
          },
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const ActiveQualifiedLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    path,
    addOnFilter: _addOnFilter,
    groupBy,
  } = props;
  const {
    user: {email: employeeEmail, employee: employeeId},
  } = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {
    markLost,
    markLeadLost,
    updateLeadStage,
    addLeadConsultants,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  if (_addOnFilter) {
    addOnFilter = {...addOnFilter, ..._addOnFilter};
  }
  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/activeLeads`}
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      sort={{
        sql_stage_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Qualified Since',
          render: RenderQlSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...COUNTRY},
        // {...INDUSTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          field: 'exact_employee_count',
          type: 'text',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {...BUSINESS_CONSULTANT},
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },

        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Add Consultants',
          onPress: addLeadConsultants,
        },
        {
          title: 'Mark Lost',
          onPress: markLeadLost,
        },
        {
          title: 'Update Stage',
          onPress: updateLeadStage,
        },
        // ...(employeeEmail === 'amit.singh@daffodildb.com' ||
        // employeeEmail === 'sushil.nagvan@daffodilsw.com'
        //   ? [
        //       {
        //         title: 'Mark RQL',
        //         onPress: moveToRql,
        //       },
        //     ]
        //   : []),
        {
          title: 'Create Opportunity',
          onPress: moveToOpportunity,
        },
        // {
        //   title: 'Move to RPS',
        //   onPress: moveToRPS,
        // },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Update Campaign',
          onPress: ({row}) => {
            row = {
              ...row,
              selectedIds: [row?._id],
            };
            navigation.navigate('update-campaign', {
              ...row,
            });
          },
        },
        // {
        //   title: 'Make Customer',
        //   onPress: makeCustomer,
        // },
        // markLeadWon({invoke}),
      ]}
    />
  );
};

export const ActiveQualifiedLeadTableForSolCon = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {
    user: {email: employeeEmail},
  } = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    markLost,
    markLeadLost,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/activeLeads/sol-con`}
      params={params}
      sort={{
        activity_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      filter={{
        $or: [
          {solution_consultant: employeeId?._id},
          {tech_consultant: employeeId?._id},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Qualified Since',
          render: RenderQlSince,
          width: 120,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          width: 200,
        },
        LEAD_SCORE,
        {...EMPLOYEE_COUNT, width: 100},
        {...CHANNEL},
        OWNER,
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Mark Lost',
      //     onPress: markLeadLost,
      //   },
      //   // ...(employeeEmail === 'amit.singh@daffodildb.com' ||
      //   // employeeEmail === 'sushil.nagvan@daffodilsw.com'
      //   //   ? [
      //   //       {
      //   //         title: 'Mark RQL',
      //   //         onPress: moveToRql,
      //   //       },
      //   //     ]
      //   //   : []),
      //   {
      //     title: 'Move to Opportunity',
      //     onPress: moveToOpportunity,
      //   },
      //   {
      //     title: 'Move to RPS',
      //     onPress: moveToRPS,
      //   },
      //   {
      //     title: 'Activity Logs',
      //     onPress: activityLogDetailForLead,
      //   },
      //   // {
      //   //   title: 'Make Customer',
      //   //   onPress: makeCustomer,
      //   // },
      //   // markLeadWon({invoke}),
      // ]}
    />
  );
};

export const ActiveOpportunityLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    path,
    groupBy,
  } = props;
  const {
    user: {email: employeeEmail, employee: employeeId},
  } = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {
    markLost,
    markLeadLost,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToOpportunity,
    addLeadConsultants,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/opportunityLeads`}
      params={params}
      // limit={3000}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      sort={{
        opportunity_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Opp Since',
          render: RenderOppSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...COUNTRY},
        // {...INDUSTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {...BUSINESS_CONSULTANT},
        // {
        //   header: 'Opp. Type',
        //   render: RenderOpportunity,
        //   width: 140,
        // },
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Add Consultants',
          onPress: addLeadConsultants,
        },
        {
          title: 'Mark Lost',
          onPress: markLeadLost,
        },
        // ...(employeeEmail === 'amit.singh@daffodildb.com' ||
        // employeeEmail === 'sushil.nagvan@daffodilsw.com'
        //   ? [
        //       {
        //         title: 'Mark RQL',
        //         onPress: moveToRql,
        //       },
        //     ]
        //   : []),
        // {
        //   title: 'Move to Opportunity',
        //   onPress: moveToOpportunity,
        // },
        // {
        //   title: 'Move to RPS',
        //   onPress: moveToRPS,
        // },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Move to Customer',
          onPress: makeCustomer,
        },
        {
          title: 'Create Opportunity',
          onPress: moveToOpportunity,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        // markLeadWon({invoke}),
      ]}
    />
  );
};

export const ActiveOpportunityToCustomerLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {
    user: {email: employeeEmail},
  } = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    markLost,
    markLeadLost,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToOpportunity,
    addLeadConsultants,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/opportunitytoCustomerLeads`}
      params={params}
      sort={{
        opportunity_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      // selection={{
      //   actions: [
      //     {title: 'Update Status', onPress: updateLeadStatus},
      //     {title: 'Update Owner', onPress: updateOwner},
      //   ],
      // }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Customer since',
          render: RenderCustomerSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...COUNTRY},
        // {...INDUSTRY},
        LEAD_SCORE,
        {...CHANNEL},
        OWNER,
        {
          header: 'Opp. Type',
          render: RenderOpportunity,
          width: 140,
        },

        LAST_COMMUNICATION(communications),
        // {...NEXT_TASK},
        // {
        //   header: 'Won Date',
        //   render: RenderCustomerSince,
        //   width: 150,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        // markLeadWon({invoke}),
      ]}
    />
  );
};
const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const LostLeadsTabls = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {
    user: {email: employeeEmail},
  } = useAppStateContext();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
    createDuplicateLead,
    showContacts,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  const {download} = useAppStateContext();
  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <GroupTable
      api={`/potentialcustomers/lostLeads`}
      params={params}
      limit={1500}
      sort={{
        lost_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      // onRowPress={({row}) => {
      //   navigation.navigate(`lead-detail`, {
      //     leadId: row._id,
      //     row,
      //   });
      // }}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Leads`} />
            </RowWithSeparator>
          );
        },
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Lost Leads"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <PeriodRangeMonthWiseBreakupFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee Size',
                    api: '/employeesizes',
                    placeholder: 'Select',
                    suggestionField: 'number',
                    valueField: 'number',
                    field: 'employee_count',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Country',
                    api: '/countries',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'country',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Industries',
                    api: '/industries',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'industry',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Sentiment',
                    suggestionField: 'label',
                    valueField: 'label',
                    keyField: 'value',
                    options: [
                      {label: 'Positive', value: 'positive'},
                      {label: 'Neutral', value: 'neutral'},
                      {label: 'Negative', value: 'negative'},
                      {label: 'No Response', value: 'no response'},
                    ],
                    placeholder: 'Select',
                    field: 'last_sentiment',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Campaign Type',
                    api: '/types/suggestionsForLead',
                    placeholder: 'Select',
                    suggestionField: 'label',
                    valueField: 'label',
                    field: 'campaign_type',
                  },
                  {
                    label: 'Campaign',
                    placeholder: 'Select',
                    field: 'campaign_id',
                    type: 'autoComplete',
                    api: '/sourceSuggestions',
                    suggestionField: 'name',
                    valueField: 'name',
                    params: ({filter: {campaign_type} = {}}) => {
                      return {
                        source: campaign_type,
                      };
                    },
                  },
                  {
                    type: 'autoComplete',
                    label: 'Lost Stage',
                    api: '/stages/lost-lead-filter',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'stage',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Lost Reason',
                    options: [
                      'Unsubscribed',
                      'Do Not Call',
                      'Not Interested',
                      'Not Relevant',
                      'Nurturable',
                      'Rejected',
                    ],
                    placeholder: 'Select',
                    field: 'lost_description_comment',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Owner',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'owner',
                  },

                  {
                    type: 'autoComplete',
                    label: 'Inside Sales',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'inside_sales',
                  },

                  {
                    type: 'autoComplete',
                    label: 'Communication',
                    placeholder: 'Select',
                    field: 'last_communication_date',
                    options: ['Exists', 'Not Exists'],
                    asParam: true,
                  },
                  {
                    type: 'date',
                    field: 'sql_date',
                    asParam: true,
                    label: 'QL Date',
                  },
                  {
                    type: 'date',
                    field: 'uql_date',
                    asParam: true,
                    label: 'Lead Date',
                  },
                  {
                    type: 'date',
                    field: 'next_task_date',
                    asParam: true,
                    label: 'Next Task Date',
                  },
                  {
                    type: 'date',
                    field: 'nurturable_date',
                    asParam: true,
                    label: 'Nurturable Date',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Channel',
                    api: '/channels',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'channel',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Organization',
                    api: '/organizations',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'organization',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Employee Team',
                    api: '/employeeteams',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'employee_team',
                    filter: {
                      _id: {
                        $in: [
                          '654c6ebd64126a8c199e9537',
                          '655b1c352722272f13e98953',
                        ],
                      },
                    },
                  },
                ]}
              />,
              // <AddButton
              //   title="Lead"
              //   view="add-lead"
              //   params={props?.route?.params}
              // />,
              // <Button
              //   text="Import Leads"
              //   onPress={() => {
              //     navigation.navigate('import-list', {
              //       source: 'leads',
              //       model: 'PotentialCustomer',
              //     });
              //   }}
              // />,
              <Button
                text="Download"
                onPress={() => {
                  download({
                    uri: `/potentialcustomers/lostleads/export`,
                    props: {
                      ...params,
                      ...filterParams,
                      ...filter,
                    },
                  });
                }}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Lead date',
          render: RenderLeadCreationSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {
        //   field: 'country.name',
        //   colorField: 'country.color',
        //   header: 'Country',
        //   type: 'colorTag',
        //   width: 150, // gave this to fit lost stage and date header
        // },
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},

        OWNER,
        {
          header: 'Lost Stage & Date',
          render: RenderLostStage,
          width: 150,
        },
        // {
        //   header: 'Opp. Type & Size',
        //   render: RenderOpportunity,
        //   width: 180,
        // },
        {
          header: 'Lost Reason',
          render: RenderLostReason,
          // width: 120
        },
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Create New',
          onPress: createDuplicateLead,
        },
        {
          title: 'Show Contacts',
          onPress: showContacts,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const NewLeadsTables = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
    createDuplicateLead,
    showContacts,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <GroupTable
      api={`/potentialcustomers/newLeads`}
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      // sort={{
      //   lost_date: -1,
      // }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      // addOnFilter={filter}
      // groupRow={{
      //   data: 'children',
      //   defaultExpanded: true,
      //   leftContent: ({row}) => {
      //     return (
      //       <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
      //         <GroupContentItem value={row.key} />
      //         <GroupContentItem value={`${row.count} Leads`} />
      //       </RowWithSeparator>
      //     );
      //   },
      // }}
      columns={[
        {
          header: 'Lead Since',
          render: RenderUqlSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {...COUNTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },

        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Show Contacts',
          onPress: showContacts,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const NewQualifiedLeadsTables = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
    searchValue,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
    createDuplicateLead,
    showContacts,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <GroupTable
      api={`/potentialcustomers/groupedQLNew`}
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      // limit={1500}
      // sort={{
      //   lost_date: -1,
      // }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      search={searchValue}
      // addOnFilter={filter}
      // groupRow={{
      //   data: 'children',
      //   defaultExpanded: true,
      //   leftContent: ({row}) => {
      //     return (
      //       <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
      //         <GroupContentItem value={row.key} />
      //         <GroupContentItem value={`${row.count} Leads`} />
      //       </RowWithSeparator>
      //     );
      //   },
      // }}
      columns={[
        {
          header: 'Qualified Since',
          render: RenderQlSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {...COUNTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },
        // {...BUSINESS_CONSULTANT},
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Show Contacts',
          onPress: showContacts,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Update Campaign',
          onPress: ({row}) => {
            row = {
              ...row,
              selectedIds: [row?._id],
            };
            navigation.navigate('update-campaign', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const NewOpportunityLeadsTables = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
    createDuplicateLead,
    showContacts,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <GroupTable
      api={`/potentialcustomers/newopportunityLeads`}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      params={params}
      limit={1500}
      // sort={{
      //   lost_date: -1,
      // }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      // addOnFilter={filter}
      // groupRow={{
      //   data: 'children',
      //   defaultExpanded: true,
      //   leftContent: ({row}) => {
      //     return (
      //       <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
      //         <GroupContentItem value={row.key} />
      //         <GroupContentItem value={`${row.count} Leads`} />
      //       </RowWithSeparator>
      //     );
      //   },
      // }}
      columns={[
        {
          header: 'Opp Since',
          render: RenderOppSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...INDUSTRY},
        // {...COUNTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },

        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
      moreActions={() => [
        {
          title: 'Show Contacts',
          onPress: showContacts,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const ActiveOpportunityLeadTableForKpi = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    path,
    groupBy,
    period,
  } = props;
  const {
    user: {email: employeeEmail, employee: employeeId},
  } = useAppStateContext();

  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {
    markLost,
    markLeadLost,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToOpportunity,
    addLeadConsultants,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  params = {period, ...params};
  console.log('params>>...', params);
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/opportunityLeads/kpi`}
      params={params}
      // limit={3000}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      sort={{
        opportunity_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Opp Since',
          render: RenderOppSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...COUNTRY},
        // {...INDUSTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          type: 'text',
          field: 'exact_employee_count',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {...BUSINESS_CONSULTANT},
        // {
        //   header: 'Opp. Type',
        //   render: RenderOpportunity,
        //   width: 140,
        // },
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
    />
  );
};

export const ActiveLeadTableForKpi = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    path,
    groupBy,
    period,
    data = [],
  } = props;
  const {
    user: {email: employeeEmail, employee: employeeId},
  } = useAppStateContext();

  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {communications} = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  params = {period, ...params};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      // api={`/potentialcustomers/activeLeads/kpi`}
      params={params}
      // limit={3000}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      sort={{
        sql_stage_date: -1,
      }}
      data={data}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      searchFields={['name', 'website', 'email']}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Qualified Since',
          render: RenderQlSince,
          width: 100,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        // {...EMPLOYEE_COUNT, width: 100},
        // {...COUNTRY},
        // {...INDUSTRY},
        LEAD_SCORE,
        {
          header: 'Emp Count',
          field: 'exact_employee_count',
          type: 'text',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {...BUSINESS_CONSULTANT},
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },

        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
    />
  );
};

export const LeadWiseCustomerTable = props => {
  const {params = {}, filterParams, fromCustomer = false} = props;

  const {communications} = leadControllers(props);

  return (
    <Table
      // variant={'bordered'}
      params={{...params, ...filterParams, forDetailView: true, fromCustomer}}
      api={`/leadwise-customer/kpi`}
      columns={[
        {
          header: 'Qualified Since',
          render: RenderQlSince,
          width: 150,
        },
        {
          header: 'Organisation',
          render: RenderUqlName,
          // width: 200,
        },
        LEAD_SCORE,
        {
          header: 'Emp Count',
          field: 'exact_employee_count',
          type: 'text',
          width: 100,
        },
        {...CHANNEL},
        OWNER,
        {...BUSINESS_CONSULTANT},
        {
          header: 'Current Stage',
          field: 'stage.name',
          type: 'colorTag',
          width: 120,
        },

        LAST_COMMUNICATION(communications),
        {...NEXT_TASK},
      ]}
    />
  );
};

export const ChannelWiseLeadCountTable = props => {
  const {params = {}, navigation, filterParams, searchValue} = props;

  return (
    <Table
      variant={'bordered'}
      params={{...params, ...filterParams}}
      api={`/potentialcustomers/activeLeads/kpi`}
      columns={[
        {
          header: 'Channel',
          field: 'name',
          width: 250,
          render: ({row}) => {
            return <Tag value={row?.name} color={row?.color} />;
          },
        },
        {
          header: 'Current',
          field: 'currentCount',
          align: 'right',
          render: CountCellRender,
          aggregate: true,
          count_field: 'currentCount',
          onPress: ({row = {}}) => {
            navigation.navigate(`channelwise-lead-tabs`, {
              data: row?.currentData,
            });
          },
        },
        {
          header: 'Previous',
          field: 'previousCount',
          align: 'right',
          render: CountCellRender,
          count_field: 'previousCount',
          aggregate: true,
          onPress: ({row = {}}) => {
            navigation.navigate(`channelwise-lead-tabs`, {
              data: row?.previousData,
            });
          },
        },
        {
          header: 'Variance',
          field: 'variance',
          align: 'right',
          render: CountCellRender,
          count_field: 'variance',
          aggregate: true,
        },
      ]}
    />
  );
};
