import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form/Renderers';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {TabView} from '../../../components/tab';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {
  UserAvatarWithNameAndEmail,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarWithOffcialEmailId,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {GroupFilter} from '../../../components/filter/Filters';
import {AddButton} from '../../../components/button/AddButton';
import {useFilter} from '@unthinkable/react-filters';

const RenderProvideMockInterviewFeedback = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('add-mock-interview-feedback', {
              row,
              forAccept: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            Provide Feedback
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

const RenderViewMockInterviewFeedback = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('view-mock-interview-feedback', {
              row,
              forAccept: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            View Feedback
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

export const MockInterviews = props => {
  const {navigation, addOnFilter, isPending, isCompleted} = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api="/mockinterviews"
      eventSourceId={['MockInterviews']}
      fields={{
        candidate: {
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        interviewer: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        mock_interview_date: 1,
        review_completed: 1,
        introductin_and_domanin_project: 1,
        problem_solving_skills: 1,
        coding_ability: 1,
        api_implementation: 1,
        db_knowledge: 1,
        error_handling: 1,
        architectural_understanding: 1,
        frontend_navigation: 1,
        frontend_state_management: 1,
        frontend_page_speed: 1,
        caching: 1,
        search_architecture: 1,
        clarity_of_expression: 1,
        confidence_in_speech: 1,
        comprehension: 1,
        engagement_with_interviewer: 1,
        questioning_skills: 1,
        overall_impression: 1,
        strenghts: 1,
        area_of_improvements: 1,
        actionable_recommendations: 1,
      }}
      //   filter={{
      //     employee: employeeId,
      //   }}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        {
          header: 'Candidate',
          //   width: 280,
          render: ({row}) => {
            const {candidate = {}} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={candidate?.name}
                color={candidate?.color}
                values={[
                  {
                    value: candidate?.official_email_id,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Interviewer',
          //   width: 280,
          render: ({row}) => {
            const {interviewer = {}} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={interviewer?.name}
                color={interviewer?.color}
                values={[
                  {
                    value: interviewer?.official_email_id,
                  },
                ]}
              />
            );
          },
        },

        {
          header: 'Interview date',
          type: 'date',
          field: 'mock_interview_date',
          //   width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        isCompleted && {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderViewMockInterviewFeedback
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
      moreActions={[
        // {
        //   title: 'My Attendance',
        //   onPress: ({row}) => {
        //     navigation.navigate('my-training-attendance', {
        //       employee_training: row,
        //       training_batch: row.training_batch,
        //     });
        //   },
        // },
        // {
        //   title: 'Give Feedback',
        //   visible: !!isCompleted,
        //   onPress: ({row}) => {
        //     navigation.navigate('trainee-to-trainer-feedbacks', {
        //       employee_training: row,
        //       training_batch: row.training_batch,
        //     });
        //   },
        // },
        isPending && {
          title: 'Update',
          visible: isPending,
          onPress: ({row}) => {
            navigation.navigate('edit-mock-interview', {
              row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const MockInterviewTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, applyFilter} = useFilter();

  return (
    <TabView
      // headerComponent={() => <TableHeader title="My Courses" />}
      tabs={{
        active: {
          label: 'Review Pending',
          view: (
            <MockInterviews
              addOnFilter={{
                ...filterValues.filter,
                review_completed: {$in: [null, false]},
              }}
              isPending
            />
          ),
          api: '/mockinterviews',
          filter: {
            review_completed: {$in: [null, false]},
          },
          eventSourceId: ['MockInterviews'],
          actions: [
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              // filters={[
              //   {
              //     type: 'autoComplete',
              //     label: 'Department',
              //     api: '/departments',
              //     placeholder: 'Select',
              //     suggestionField: 'name',
              //     valueField: 'name',
              //     field: 'department',
              //   },
              //   {
              //     type: 'autoComplete',
              //     label: 'Product',
              //     api: '/products',
              //     placeholder: 'Select',
              //     suggestionField: 'name',
              //     valueField: 'name',
              //     field: 'product',
              //   },
              //   {
              //     type: 'autoComplete',
              //     label: 'Reporting To',
              //     api: '/employeeSuggestions',
              //     placeholder: 'Select',
              //     suggestionField: 'name',
              //     valueField: 'name',
              //     field: 'reporting_to',
              //     filter: {employee_status: 'Active'},
              //   },
              // ]}
            />,
            <Row
              gap={8}
              style={{alignItems: 'center', justifyContent: 'space-between'}}>
              <AddButton
                title="Mock Interview"
                view="create-mock-interview"
                params={props?.route?.params}
              />
            </Row>,
          ],
        },
        completed: {
          label: 'Review Completed',
          view: (
            <MockInterviews
              addOnFilter={{
                ...filterValues.filter,
                review_completed: true,
              }}
              isCompleted
            />
          ),
          api: '/mockinterviews',
          eventSourceId: ['MockInterviews'],
          filter: {
            review_completed: true,
          },
        },
      }}
      {...props}
    />
  );
};

export const MockInterviewsInterviewer = props => {
  const {navigation, addOnFilter, isPending, isCompleted} = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api="/mockinterviews"
      eventSourceId={['MockInterviews']}
      fields={{
        candidate: {
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        interviewer: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        mock_interview_date: 1,
        review_completed: 1,
        introductin_and_domanin_project: 1,
        problem_solving_skills: 1,
        coding_ability: 1,
        api_implementation: 1,
        db_knowledge: 1,
        error_handling: 1,
        architectural_understanding: 1,
        frontend_navigation: 1,
        frontend_state_management: 1,
        frontend_page_speed: 1,
        caching: 1,
        search_architecture: 1,
        clarity_of_expression: 1,
        confidence_in_speech: 1,
        comprehension: 1,
        engagement_with_interviewer: 1,
        questioning_skills: 1,
        overall_impression: 1,
        strenghts: 1,
        area_of_improvements: 1,
        actionable_recommendations: 1,
      }}
      //   filter={{
      //     employee: employeeId,
      //   }}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        {
          header: 'Candidate',
          //   width: 280,
          render: ({row}) => {
            const {candidate = {}} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={candidate?.name}
                color={candidate?.color}
                values={[
                  {
                    value: candidate?.official_email_id,
                  },
                ]}
              />
            );
          },
        },

        {
          header: 'Interview date',
          type: 'date',
          field: 'mock_interview_date',
          //   width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        isPending && {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderProvideMockInterviewFeedback
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
        isCompleted && {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderViewMockInterviewFeedback
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
      {...props}
    />
  );
};

export const MockInterviewTabsInterviewer = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, applyFilter} = useFilter();
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <TabView
      // headerComponent={() => <TableHeader title="My Courses" />}
      tabs={{
        active: {
          label: 'Review Pending',
          view: (
            <MockInterviewsInterviewer
              addOnFilter={{
                ...filterValues.filter,
                review_completed: {$in: [null, false]},
                interviewer: employeeId,
              }}
              isPending
            />
          ),
          api: '/mockinterviews',
          filter: {
            review_completed: {$in: [null, false]},
            interviewer: employeeId,
          },
          eventSourceId: ['MockInterviews'],
          // actions: [
          //   <GroupFilter
          //     filterValues={filterValues}
          //     applyFilter={applyFilter}
          //     filters={[
          //       {
          //         type: 'autoComplete',
          //         label: 'Department',
          //         api: '/departments',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'department',
          //       },
          //       {
          //         type: 'autoComplete',
          //         label: 'Product',
          //         api: '/products',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'product',
          //       },
          //       {
          //         type: 'autoComplete',
          //         label: 'Reporting To',
          //         api: '/employeeSuggestions',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'reporting_to',
          //         filter: {employee_status: 'Active'},
          //       },
          //     ]}
          //   />,
          // ],
        },
        completed: {
          label: 'Review Completed',
          view: (
            <MockInterviewsInterviewer
              addOnFilter={{
                ...filterValues.filter,
                review_completed: true,
                interviewer: employeeId,
              }}
              isCompleted
            />
          ),
          api: '/mockinterviews',
          eventSourceId: ['MockInterviews'],
          filter: {
            review_completed: true,
            interviewer: employeeId,
          },
        },
      }}
      {...props}
    />
  );
};

export const MockInterviewsCandidate = props => {
  const {navigation, addOnFilter, isPending, isCompleted} = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api="/mockinterviews"
      eventSourceId={['MockInterviews']}
      fields={{
        candidate: {
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        interviewer: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        mock_interview_date: 1,
        review_completed: 1,
        introductin_and_domanin_project: 1,
        problem_solving_skills: 1,
        coding_ability: 1,
        api_implementation: 1,
        db_knowledge: 1,
        error_handling: 1,
        architectural_understanding: 1,
        frontend_navigation: 1,
        frontend_state_management: 1,
        frontend_page_speed: 1,
        caching: 1,
        search_architecture: 1,
        clarity_of_expression: 1,
        confidence_in_speech: 1,
        comprehension: 1,
        engagement_with_interviewer: 1,
        questioning_skills: 1,
        overall_impression: 1,
        strenghts: 1,
        area_of_improvements: 1,
        actionable_recommendations: 1,
      }}
      //   filter={{
      //     employee: employeeId,
      //   }}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        {
          header: 'Interviewer',
          //   width: 280,
          render: ({row}) => {
            const {interviewer = {}} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={interviewer?.name}
                color={interviewer?.color}
                values={[
                  {
                    value: interviewer?.official_email_id,
                  },
                ]}
              />
            );
          },
        },

        {
          header: 'Interview date',
          type: 'date',
          field: 'mock_interview_date',
          //   width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        isPending && {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderProvideMockInterviewFeedback
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
        isCompleted && {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderViewMockInterviewFeedback
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
      {...props}
    />
  );
};

export const MockInterviewTabsCandidate = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, applyFilter} = useFilter();
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <TabView
      // headerComponent={() => <TableHeader title="My Courses" />}
      tabs={{
        active: {
          label: 'Review Pending',
          view: (
            <MockInterviewsCandidate
              addOnFilter={{
                ...filterValues.filter,
                review_completed: {$in: [null, false]},
                candidate: employeeId,
              }}
              isPending
            />
          ),
          api: '/mockinterviews',
          filter: {
            review_completed: {$in: [null, false]},
            candidate: employeeId,
          },
          eventSourceId: ['MockInterviews'],
          // actions: [
          //   <GroupFilter
          //     filterValues={filterValues}
          //     applyFilter={applyFilter}
          //     filters={[
          //       {
          //         type: 'autoComplete',
          //         label: 'Department',
          //         api: '/departments',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'department',
          //       },
          //       {
          //         type: 'autoComplete',
          //         label: 'Product',
          //         api: '/products',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'product',
          //       },
          //       {
          //         type: 'autoComplete',
          //         label: 'Reporting To',
          //         api: '/employeeSuggestions',
          //         placeholder: 'Select',
          //         suggestionField: 'name',
          //         valueField: 'name',
          //         field: 'reporting_to',
          //         filter: {employee_status: 'Active'},
          //       },
          //     ]}
          //   />,
          // ],
        },
        completed: {
          label: 'Review Completed',
          view: (
            <MockInterviewsCandidate
              addOnFilter={{
                ...filterValues.filter,
                review_completed: true,
                candidate: employeeId,
              }}
              isCompleted
            />
          ),
          api: '/mockinterviews',
          eventSourceId: ['MockInterviews'],
          filter: {
            review_completed: true,
            candidate: employeeId,
          },
        },
      }}
      {...props}
    />
  );
};
