import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';

import {TeamTasksTab} from './ProjectActiveTasksTab';
import {OrganizationTasksTab} from './OrganizationTasksTab';

const TeamTaskMenu = props => {
  const {
    route: {params},
  } = props;

  const menus = [
    {
      label: 'Software Project Tasks',
      view: <TeamTasksTab {...props} />,
      eventSourceId: 'Task',
    },
    {
      label: 'Project Tasks',
      view: <OrganizationTasksTab {...props} />,
    },
  ];

  return <LeftMenuView {...props} menus={menus} />;
};

export default TeamTaskMenu;
