import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const KPIPermissionInputForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/kpipermissions',
    eventSourceId: 'kpipermissions',
    ...props,
  });

  return (
    <Form
      api={`/kpipermissions/${row?._id}`}
      fields={{
        entity: {
          name: 1,
        },
        entity_id: {
          name: 1,
        },
        role: 1,
        user_id: {name: 1},
      }}
      onSubmit={onSubmit}
      header={{title: 'Add KPI Permission'}}
      layoutFields={[
        // {
        //   label: 'Entity Details',
        //   fields: [
        //     {
        //       placeholder: 'Entity',
        //       label: 'Entity',
        //       field: 'entity',
        //       type: 'cascader',
        //       cascades: [
        //         {
        //           label: 'Select Source',
        //           field: 'entity',
        //           api: '/types',
        //           suggestionField: 'label',
        //           valueField: 'label',
        //         },
        //         {
        //           label: 'Entity Name',
        //           placeholder: 'Entity_id',
        //           field: 'entity_id',
        //           type: 'autoComplete',
        //           api: '/sourceSuggestions',
        //           suggestionField: 'name',
        //           valueField: 'name',
        //           params: ({values: {entity} = {}}) => {
        //             return {
        //               source: entity,
        //             };
        //           },
        //           onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
        //             navigation,
        //             params,
        //           }),
        //         },
        //       ],
        //     },
        //     {
        //       type: 'text',
        //       field: 'entity_id.name',
        //       placeholder: 'Entity Name',
        //       label: 'Entity Name',
        //     },
        //   ],
        // },
        {
          label: 'Role',
          field: 'role',
          type: 'autoComplete',
          api: '/role/modifiedData',
          suggestionField: 'role',
          valueField: 'role',
        },
        {
          label: 'User',
          type: 'autoComplete',
          field: 'user_id',
          api: '/users',
          filter: {
            status: 'a',
          },
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditKPIPermissionInputForm = props => {
  return (
    <KPIPermissionInputForm
      header={{title: 'Edit KPI Permission'}}
      mode="edit"
      {...props}
    />
  );
};
