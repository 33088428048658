import React from 'react';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {AddButton} from '../../../components/button/AddButton';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';

export const ProjectComplexityTable = props => {
  const {navigation} = props;
  const filterProps = useFilter({});
  const {searchValue} = filterProps;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'UserOrganization',
  });

  const deleteUserOrganization = ({row}) => {
    deleteInvoke({
      uri: `/userorganizations/${row?._id}`,
    });
  };

  return (
    <Table
      search={searchValue}
      eventSourceId={['ProjectComplexity']}
      searchFields={['complexity_type']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Project Complexity"
            actions={[
              <SearchFilter {...filterProps} />,
              <AddButton
                title={'Project Complexity'}
                view={'add-project-complexity'}
              />,
            ]}
          />
        );
      }}
      api={`/projectcomplexities`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-project-complexity`, {
          complexity: row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'complexity_type',
          header: 'Type',
        },

        {
          type: 'number',
          header: 'Point',
          field: 'point',
        },
      ]}
      //   moreActions={[
      //     {
      //       title: 'Delete',
      //       onPress: deleteUserOrganization,
      //       variant: 'error',
      //       confirm: {
      //         title: 'Delete',
      //         message: 'Are you sure you want to Delete?',
      //         confirmText: 'Delete',
      //       },
      //     },
      //   ]}
    />
  );
};
