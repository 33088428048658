import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const CaseStudyForm = props => {
  const {
    route: { params },
  } = props;
  const { row, website } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/casestudymodels',
    eventSourceId: 'caseStudy',
  });
  return (
    <Form
      api={`/casestudymodels/${row?._id}`}
      fields={{
        domain: {
            domain: 1,
        },
        image: 1,
        background_image: 1,
        logo: 1,
        tags: 1,
        website_url: 1,
        title: 1,
        description: 1,
        success_story: 1,
        is_published: 1,
        index: 1,
      }}
      defaultValues={{
        website,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Domain',
          type: 'autoComplete',
          api: '/domains',
          suggestionField: 'domain',
          valueField: 'domain',
          field: 'domain',
        },
        {
          label: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
        },
        {
          label: 'Tags',
          field: 'tags',
          type: 'multiText',
        },
        {
          label: 'Website Url',
          field: 'website_url',
          type: 'text',
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
        {
          field: 'image',
          type: 'file',
          label: 'Image',
          required: true,
          size: 4,
        },
        {
          field: 'logo',
          type: 'file',
          label: 'Logo',
          size: 4,
        },
        {
          field: 'background_image',
          type: 'file',
          label: 'Background Image',
          required: true,
          size: 4,
        },
        {
          field: 'success_story',
          type: 'checkbox',
          label: 'Success Story',
          size: 6,
        },
        {
          field: 'is_published',
          type: 'checkbox',
          label: 'Publish',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddCaseStudyForm = props => {
  return <CaseStudyForm header="Add Case Study" {...props} />;
};

export const UpdateCaseStudyForm = props => {
  return (
    <CaseStudyForm mode="edit" header="Update Case Study" {...props} />
  );
};
