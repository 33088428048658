import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddProjectComplexity = props => {
  const {route} = props;
  const {params} = route;
  const {complexity} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/projectcomplexities',
    eventSourceId: ['ProjectComplexity'],
  });

  return (
    <Form
      header={{
        title: 'Project Complexity',
      }}
      api={'/projectcomplexities/' + complexity?._id}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Type',
          type: 'text',
          field: 'complexity_type',
        },
        {
          label: 'Point',
          type: 'number',
          field: 'point',
        },
      ]}
      {...props}
    />
  );
};

export const EditProjectComplexity = props => {
  return <AddProjectComplexity mode="edit" {...props} />;
};
