import React from 'react';
import {Table} from '../../../components/table/Table';
import {Chip, Tag} from '../../../components/chip/Chip';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text, Row} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import { UserAvatarChip } from '../../../components/avatar/UserAvatar';

const employeeStatusColors = {
  Available: 'information',
  'Partial Available': 'accent6',
  'Under Traning': 'accent6',
  Fixed: 'accent6',
  'T&M': 'accent6',
  'KT For Swapping': 'accent4',
  'KT For Resignation': 'accent3',
  Offline: 'accent3',
  Reserved: 'accent3',
  Unavailable: 'accent2',
  Billed: 'success',
  Bench: 'error',
  'Partialy billed': 'accent1',
};

const assignShadow = ({navigation, params}) => ({
  title: 'Assign Shadow',
  onPress: props => {
    const {row} = props;
    navigation.navigate('workload-assign-shadow-form', {
      row,
      type: 'workload',
    });
  },
});
const markBandWidth = ({navigation, params}) => ({
  title: 'Proposed Release',
  onPress: ({row}) => {
    navigation.navigate(`replacement-form`, {
      ...row,
      // defaultValues: {
      //   status: 'due',
      //   type: 'Negative Margin',
      // },
    });
  },
});
const markAnchor = ({navigation, params}) => ({
  title: 'Mark Anchor Resource',
  onPress: ({row}) => {
    navigation.navigate(`replacement-form`, {
      ...row,
      defaultValues: {
        status: 'due',
        type: 'Anchor',
      },
    });
  },
});

const assignAsReplacement = ({navigation, params}) => ({
  title: 'Assign As Replacement',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-replacement', {
      row,
    });
  },
});

const assignTraining = ({navigation, params}) => ({
  title: 'Assign Training',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-training', {
      row,
    });
  },
});

const addOtherResources = ({navigation, params}) => ({
  title: 'Add Other Resource',
  onPress: props => {
    const {row} = props || {};
    const {_id, to_date, skill_requirement = {}, ...rest} = row || {};
    navigation.navigate('workload-add-other-resourceform', {
      row,
      type: 'workload',
    });
  },
  visible: ({row}) => {
    return row?.totalActualWorkingHours > 0;
  },
});

const stopAllocation = ({navigation, params}) => ({
  title: 'Stop Allocation',
  onPress: props => {
    const {row} = props || {};
    const {_id, to_date, skill_requirement = {}, ...rest} = row || {};
    navigation.navigate('workload-stop-allocation', {
      row,
      type: 'workload',
    });
  },
  visible: ({row}) => {
    return row?.totalActualWorkingHours > 0;
  },
});

const updateCV = ({navigation, params}) => ({
  title: 'Update CV',
  onPress: ({row}) => {
    navigation.navigate('update-cv', {
      row,
    });
  },
});

const renderStatus = ({row = {}}) => {
  let {status} = row || {};
  if (!status) return null;
  return <Tag value={status} color={employeeStatusColors[status]} />;
};

const EmployeeWorkLoad = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    benchComparision,
    tab,
    employeeFilter,
    projectFilter,
    period : periodParam
  } = props;
  const {fromBenchStatusReport,fromTeam,from, api} = params;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');

  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {from: start_date, to: end_date} = period || {};
  let datefilter = {};
  if (!benchComparision) {
    datefilter = {
      from_date: {$lte: end_date},
      $or: [{to_date: {$gte: start_date}}, {to_date: {$exists: false}}],
    };
  } else {
    datefilter = {};
  }
  let addOnFilter = { 
    is_shadow: {$in: [null, false]},
    is_internal: {$in: [null, false]},
    ...datefilter
  }
  if(!params.employeeFilter && (employeeFilter || projectFilter)){
    params = {...params,tab,employeeFilter , period : periodParam , projectFilter};
    addOnFilter = {}
  }
  return (
    <Table
      {...props}
      api={api || '/employees-team/workload'}
      eventSourceId={['allocatedResource', 'workload-billable']}
      search={searchValue}
      addOnFilter={filter}
      limit={2000}
      searchFields={['name']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {
             employee: row?._id,
            ...addOnFilter
          },
          period : periodParam
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
                primarySubTitle={row?.types}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 80,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 160,
        },
        {
          header: 'Reporting',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.reporting_to?.[0]?.name,
                  color: row?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        (!from || (from !== "bench")) && {
          header: 'Delivery Owner',
          type: 'userAvatarChip',
          field: 'delivery_owner',
          width: 160,
        },
        (!from || (from !== "bench")) && {
          header: 'Skill Leader',
          type: 'userAvatarChip',
          field: 'skill_leader',
          width: 160,
        },
        (!from || (from !== "bench")) && {
          header: 'Status',
          width: 100,
          render: renderStatus,
        },
        (!from || (from !== "bench")) && {
          header: 'Project',
          render: ({row}) => {
            const projectLength = (row?.project && row?.project.length) || 0;
            const projectSubCategory =
              row?.project &&
              row?.project[0] &&
              row?.project[0].project_sub_category;
            const projectName =
              row?.project && row?.project[0] && row?.project[0].project;
            if (row?.status == 'Billed' || row?.status == 'Partial Available') {
              if (projectLength > 1) {
                return (
                  <>
                    <Row gap={8} style={{alignItems: 'center'}}>
                      <Tag
                        value={projectName}
                        color={
                          row?.project &&
                          row?.project[0] &&
                          row?.project[0].color
                        }
                      />

                      <Text style={{...BODY2}}>+{projectLength - 1}</Text>
                    </Row>

                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              } else {
                return (
                  <>
                    <Tag
                      value={projectName}
                      color={
                        row?.project && row?.project[0] && row?.project[0].color
                      }
                    />
                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              }
            }
          },
        },
       (!from || (from !== "bench")) && {
          header: 'Allc. Hrs.',
          align: 'right',
          width: 80,
          render: ({row, styles: {rowText = {}}}) => {
            const {totalActualWorkingHours,workingHours} = row;
            const convertIntoPercentage = (totalActualWorkingHours / workingHours) * 100;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {convertIntoPercentage.toFixed(0)}%
              </Text>
            );
          },
        },
        from == "bench" && {
          header: 'Free Hrs.',
          align: 'right',
          width: 80,
          render: ({row, styles: {rowText = {}}}) => {
            const {totalActualWorkingHours,workingHours} = row;
            const freeHour = ((workingHours - totalActualWorkingHours)/workingHours) * 100;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {freeHour.toFixed(0)}%
              </Text>
            );
          },
        },
        !fromTeam && {
          header: 'Last Rating',
          align: 'center',
          render: ({row}) => {
            const projectRatingDetail = {
              'A+': {
                color: 'success',
                value: 'CE',
              },
              A: {
                color: 'accent6',
                value: 'OE',
              },
              'B+': {
                color: 'accent1',
                value: 'ME',
              },
              B: {
                color: 'warning',
                value: 'PE',
              },
              C: {
                color: 'error',
                value: 'DE',
              },
            };
            const project_rating = row?.assigned_to?.project_rating;
            if (project_rating) {
              const projectRatingInfo = projectRatingDetail[project_rating];
              return (
                <Chip
                  color={projectRatingInfo?.color}
                  displayTextColor
                  value={projectRatingInfo?.value}
                  borderRadius={6}
                />
              );
            }
          },
          width: 80,
        },
      ]}
      moreActions={
        fromBenchStatusReport
          ? []
          : () => [
              assignShadow({navigation, params}),
              assignAsReplacement({navigation, params}),
              assignTraining({navigation, params}),
              stopAllocation({navigation, params}),
              addOtherResources({navigation, params}),
              updateCV({navigation, params}),
              // markAnchor({navigation, params}),
              markBandWidth({navigation, params}),
            ]
      }
      params={params}
    />
  );
};

export default EmployeeWorkLoad;

export const EmployeeWorkLoadForKPI = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    benchComparision,
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');

  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {from: start_date, to: end_date} = period || {};
  let datefilter = {};
  if (!benchComparision) {
    datefilter = {
      from_date: {$lte: end_date},
      $or: [{to_date: {$gte: start_date}}, {to_date: {$exists: false}}],
    };
  } else {
    datefilter = {};
  }
  return (
    <Table
      {...props}
      api={'/projectwiseEmployeesWorkLoadForKPI'}
      eventSourceId={['allocatedResource', 'workload-billable']}
      search={searchValue}
      addOnFilter={filter}
      limit={2000}
      searchFields={['name']}
      groupRow={{
        data: 'employee',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.project.project} />
              <GroupContentItem value={`${(row?.employeeCount || 0)}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
                primarySubTitle={row?.types}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 80,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 160,
        },
        {
          header: 'Skill Leader',
          type: 'userAvatarChip',
          field: 'skill_leader',
          width: 160,
        },
        {
          header: 'Status',
          width: 100,
          render: renderStatus,
        },
        {
          header: 'Project',
          render: ({row}) => {
            const projectLength = (row?.project && row?.project.length) || 0;
            const projectSubCategory =
              row?.project &&
              row?.project[0] &&
              row?.project[0].project_sub_category;
            const projectName =
              row?.project && row?.project[0] && row?.project[0].project;
            if (row?.status == 'Billed' || row?.status == 'Partial Available') {
              if (projectLength > 1) {
                return (
                  <>
                    <Row gap={8} style={{alignItems: 'center'}}>
                      <Tag
                        value={projectName}
                        color={
                          row?.project &&
                          row?.project[0] &&
                          row?.project[0].color
                        }
                      />
                      <Text style={{...BODY2}}>+{projectLength - 1}</Text>
                    </Row>
                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              } else {
                return (
                  <>
                    <Tag
                      value={projectName}
                      color={
                        row?.project && row?.project[0] && row?.project[0].color
                      }
                    />
                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              }
            }
          },
        },
        {
          header: 'Allc. Hrs.',
          align: 'right',
          width: 80,
          render: ({row, styles: {rowText = {}}}) => {
            const {totalActualWorkingHours} = row;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {totalActualWorkingHours.toFixed(0)}
              </Text>
            );
          },
        },
      ]}
      params={params}
    />
  );
};
