import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const AddRequestTalentForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/requesttalents',
    ...props,
  });
  const {user} = useAppStateContext();
  return (
    <Form
      onSubmit={onSubmit}
      header="Request talent"
      submitAction="Request"
      defaultValues={{
        requested_by: user.employee?._id,
        status: 'Pending',
      }}
      layoutFields={[
        {
          label: 'Skillset',
          field: 'skill_set',
          type: 'multiAutoComplete',
          api: `/skills`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Experience',
          field: 'experience',
          type: 'autoComplete',
          api: `/employeeexperiences`,
          suggestionField: 'experience',
          valueField: 'experience',
        },
        {
          type: 'number',
          field: 'number_of_resource_required',
          label: 'Number of resource required',
        },
        {
          type: 'number',
          field: 'allocation',
          label: 'Allocation',
        },
        {
          type: 'date',
          field: 'start_date',
          label: 'Start Date',
        },
        {
          type: 'date',
          field: 'end_date',
          label: 'End Date',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Object',
          field: 'object',
          type: 'autoComplete',
          api: `/types`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const AddRequestTalentForEmployee = props => {
  const params = props?.route?.params;
  const {row} = params;
  const {user} = useAppStateContext();
  let {onSubmit} = useFormSubmit({
    uri: '/requesttalents',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header="Request talent"
      submitAction="Request"
      defaultValues={{
        requested_by: user.employee?._id,
        employee: {_id: row?._id, name: row?.name},
        skill_set: row.skills,
        status: 'Pending',
      }}
      layoutFields={[
        {
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          label: 'Employee',
        },
        {
          label: 'Skillset',
          field: 'skill_set',
          type: 'multiAutoComplete',
          api: `/skills`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          type: 'number',
          field: 'allocation',
          label: 'Allocation',
        },
        {
          type: 'date',
          field: 'start_date',
          label: 'Start Date',
        },
        {
          type: 'date',
          field: 'end_date',
          label: 'End Date',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Object',
          field: 'object',
          type: 'autoComplete',
          api: `/types`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const RequestTalent = props => {
  const params = props?.route?.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/addEmployeeAssigmentToRequestTalent/${row?._id}`,
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header="Request talent"
      submitAction="Allocate"
      defaultValues={{
        employee: {
          _id: row?.employee?._id ? row?.employee?._id : params?._id,
          name: row?.employee?.name ? row?.employee?.name : params?.name,
        },
        skill_set: row?.skill_set ? row?.skill_set : row?.skills,
        experience: row?.experience ? row?.experience : params?.experience,
        percentage: row?.allocation
          ? row?.allocation
          : row?.allocated?.[0]?.percentage,
        from_date: row?.start_date,
        to_date: row?.end_date,
        department: {_id: row?.department?._id, name: row?.department?.name},
        object: {_id: row?.object?._id, name: row?.object?.name},
        product: {_id: row?.product?._id, name: row?.product?.name},
        number_of_resource_required: row?.number_of_resource_required,
      }}
      layoutFields={[
        {
          placeholder: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          label: 'Employee',
        },
        {
          label: 'Skillset',
          field: 'skill_set',
          type: 'multiAutoComplete',
          api: `/skills`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Experience',
          field: 'experience',
          type: 'autoComplete',
          api: `/employeeexperiences`,
          suggestionField: 'experience',
          valueField: 'experience',
        },
        {
          type: 'number',
          field: 'percentage',
          placeholder: 'Allocation',
          label: 'Allocation',
        },
        {
          type: 'date',
          field: 'from_date',
          placeholder: 'Start Date',
          label: 'Start Date',
        },
        {
          type: 'date',
          field: 'to_date',
          placeholder: 'End Date',
          label: 'End Date',
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          placeholder: 'Product',
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          placeholder: 'Object',
          label: 'Object',
          field: 'object',
          type: 'autoComplete',
          api: `/types`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
    />
  );
};
