import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';

export const CaseStudyTable = (props) => {
    const {route: {params}} = props;
    const {row} = params || {};
    const website = row?._id;

    const navigation = useNavigation();

    const deleteInvoke = useInvoke({
        method: 'delete',
        eventSourceId: 'caseStudy',
    });

    return (
        <Table
            eventSourceId={["caseStudy"]}
            api={`/casestudymodels`}
            fields={{
                domain: {
                    domain: 1,
                },
                title: 1,
                description: 1,
                createdOn: 1,
                index: 1,
                success_story: 1,
                is_published: 1,
            }}
            filter={{
                website,
            }}
            sort={{ index: 1 }}
            renderHeader={() => (
                <TableHeader
                    title="Case Study"
                    actions={[
                        <AddButton
                            title="Add Case Study"
                            view="add-case-study"
                            params={{
                                website,
                            }}
                        />,
                    ]}
                />
            )}
            onRowPress={({ row }) => {
                navigation.navigate('edit-case-study-unthinkable', { row, website });
            }}
            columns={[
                {
                    type: 'text',
                    field: 'domain.domain',
                    header: 'Domain',
                    width: 120,
                },
                {
                    type: 'textArea',
                    field: 'title',
                    header: 'Title',
                },
                {
                    type: 'textArea',
                    field: 'description',
                    header: 'Description',
                },
                {
                    type: 'Boolean',
                    field: 'success_story',
                    header: 'Success Story',
                    width: 120,
                    align: 'center',
                },
                {
                    type: 'boolean ',
                    field: 'is_published',
                    header: 'Publish',
                    align: 'center',
                    width: 100,
                },
                {
                    header: 'Created On',
                    field: 'createdOn',
                    align: 'center',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 120,
                }
            ]}
            moreActions={() => [
                {
                    title: 'Edit',
                    onPress: ({ row }) => {
                        navigation.navigate('edit-case-study-unthinkable', { row, website });
                    },
                },
                {
                    title: 'Delete',
                    confirm: {
                        title: 'Delete',
                        message: 'Are you sure you want to Delete?',
                        confirmText: 'Delete',
                    },
                    onPress: ({ row }) => {
                        deleteInvoke({
                            uri: `/casestudymodels/${row?._id}`,
                            props: {},
                        });
                    },
                },
            ]}
        />
    );
};
