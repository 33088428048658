import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { PHAppraisalTable, PHFinalAppraisalTable } from './EmployeeAppraisalTable';

const appraisalCommonFilters = [
    {
        type: 'multiAutoComplete',
        label: 'Employee',
        api: '/employeeSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        secondarySuggestionField: 'official_email_id',
        valueField: 'name',
        field: 'employee',
        searchFields: ['name', 'official_email_id', 'employee_code'],

    },
    {
        type: 'multiAutoComplete',
        label: 'Employee',
        api: '/employeeSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        secondarySuggestionField: 'official_email_id',
        valueField: 'name',
        field: 'employee.functional_manager',
        searchFields: ['name', 'official_email_id', 'employee_code'],

    },
    {
        type: 'date',
        label: "Effective Date",
        field: 'effective_date'
    },
    {
        type: 'date',
        label: "Closed Date",
        field: 'closed_date'
    },
    {
        type: 'date',
        label: "Joining Date",
        field: 'employee.joining_date'
    },
    {
        type: 'autoComplete',
        label: 'Department',
        api: '/activeDepartmentSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'employee.department',
    },
    {
        type: 'autoComplete',
        label: 'Designation',
        api: '/designations',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'employee.designation',
    },
    {
        type: 'autoComplete',
        label: 'Approver',
        api: '/employeeSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'approved_by_unit_ph',
        searchFields: ['name', 'official_email_id', 'employee_code'],
        secondarySuggestionField: 'official_email_id',
    },
]

const PHAppraisalTab = props => {
    let { user = {}, route: { params = {} } = {} ,tab, forUH, ...rest } = props || {};
    const { applyFilter, filterValues, searchValue, onChangeFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    let tabs = {};
    tabs['PendingAppraisal'] = {
        label: 'Pending Approval',
        eventSourceId: 'Appraisal',
        view: <PHAppraisalTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: "Active",
                people_manager_appraisal_status: true,
                unit_appraisal_status: { $in: [null, false] },
                offer_accepted_by_employee: { $in: [null, false] },
                ifIncrement_asPer_aggrement: { $in: [null, false] },
                processed_manually_by_hr: { $in: [null, false] },
            }}
            api = '/employeephappraisal'
            user={user}
            {...rest}
            tab = "Pending Approval"
        />,
        addOnFilter: {
            ...filter,
            status: "Active",
            people_manager_appraisal_status: true,
            unit_appraisal_status: { $in: [null, false] },
            offer_accepted_by_employee: { $in: [null, false] },
            ifIncrement_asPer_aggrement: { $in: [null, false] },
            processed_manually_by_hr: { $in: [null, false] },
        },
        api: '/employeephappraisal',
    }

    // tabs['InDiscussion'] = {
    //     label: 'In Discussion',
    //     eventSourceId: 'Appraisal',
    //     view: <PHAppraisalTable
    //         searchValue={searchValue}
    //         filterValues={filterValues}
    //         addOnFilter={{
    //             ...filterValues.filter,
    //             people_manager_appraisal_status: true,
    //             unit_appraisal_status: true,
    //             offer_accepted_by_employee: { $in: [null, false] },
    //             status: "Active",
    //             ifIncrement_asPer_aggrement: { $in: [null, false] },
    //             processed_manually_by_hr: { $in: [null, false] },
    //         }}
    //         api = '/employeephappraisal'
    //         user={user}
    //         {...rest} 
    //         tab = "In Discussion"
    //         />,
    //     addOnFilter: {
    //         ...filter,
    //         people_manager_appraisal_status: true,
    //         unit_appraisal_status: true,
    //         offer_accepted_by_employee: { $in: [null, false] },
    //         status: "Active",
    //         ifIncrement_asPer_aggrement: { $in: [null, false] },
    //         processed_manually_by_hr: { $in: [null, false] },
    //     },
    //     api: '/employeephappraisal',
    // }

    // tabs['AppraisalInProcess'] = {
    //     label: 'Appraisal In Process',
    //     eventSourceId: 'Appraisal',
    //     view: <PHFinalAppraisalTable
    //         searchValue={searchValue}
    //         filterValues={filterValues}
    //         addOnFilter={{
    //             ...filterValues.filter,
    //             hrPayroll_appraisal_status: true,
    //             hrPayroll_closed_status: { $ne: true },
    //             status: "Active"
    //         }}
    //         api = '/employeephappraisal'
    //         user={user}
    //         {...rest} 
    //         tab = "Appraisal In Process"
    //         />,
    //     addOnFilter: {
    //         ...filter,
    //         hrPayroll_appraisal_status: true,
    //         hrPayroll_closed_status: { $ne: true },
    //         status: "Active"
    //     },
    //     api: '/employeephappraisal',
    // }

    tabs['Accepted Appraisal'] = {
        label: 'Letter Sharing Due',
        eventSourceId: 'Appraisal',
        view: <PHFinalAppraisalTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                $or: [
                    {
                        processed_manually_by_hr: true,
                        offer_accepted_by_employee: true
                    },
                    {
                        ifIncrement_asPer_aggrement: "Extend",
                        manually_processed_onhold_extend: true,
                    },
                    {
                        ifIncrement_asPer_aggrement: "OnHold",
                        manually_processed_onhold_extend: true,
                        people_manager_appraisal_status: true,
                        offer_accepted_by_employee: true,
                    },
                    {
                        ifIncrement_asPer_aggrement: 'AsPerAggrement',
                        people_manager_appraisal_status: true,
                        offer_accepted_by_employee: true,
                    },
                    {
                        ifIncrement_asPer_aggrement: { $in: [null, false] },
                        people_manager_appraisal_status: true,
                        offer_accepted_by_employee: true,
                    },
                ],
                status: 'Active',
                hrPayroll_appraisal_status: { $in: [null, false] }
            }}
            api = '/employeephappraisal'
            user={user}
            {...rest}
            tab = "Accepted Appraisal"
             />,
        addOnFilter: {
            ...filter,
            $or: [
                {
                    processed_manually_by_hr: true,
                    offer_accepted_by_employee: true
                },
                {
                    ifIncrement_asPer_aggrement: "Extend",
                    manually_processed_onhold_extend: true,
                },
                {
                    ifIncrement_asPer_aggrement: "OnHold",
                    manually_processed_onhold_extend: true,
                    people_manager_appraisal_status: true,
                    offer_accepted_by_employee: true,
                },
                {
                    ifIncrement_asPer_aggrement: 'AsPerAggrement',
                    people_manager_appraisal_status: true,
                    offer_accepted_by_employee: true,
                },
                {
                    ifIncrement_asPer_aggrement: { $in: [null, false] },
                    people_manager_appraisal_status: true,
                    offer_accepted_by_employee: true,
                },
            ],
            status: 'Active',
            hrPayroll_appraisal_status: { $in: [null, false] }
        },
        api: '/employeephappraisal',
    }

    // tabs['PromotionRecomendation'] = {
    //     label: 'Promotion Recomendation',
    //     eventSourceId: 'Appraisal',
    //     view: <PHFinalAppraisalTable
    //         searchValue={searchValue}
    //         filterValues={filterValues}
    //         addOnFilter={{
    //             ...filterValues.filter,
    //             $forPromotion_unit_manager: true,
    //             unit_appraisal_status: true,
    //             hrPayroll_closed_status: { $ne: true },
    //             status: "Active"
    //         }}
    //         api = '/employeephappraisal'
    //         user={user}
    //         {...rest}
    //         tab = "Promotion Recomendation"
    //          />,
    //     addOnFilter: {
    //         ...filter,
    //         forPromotion_unit_manager: true,
    //         unit_appraisal_status: true,
    //         hrPayroll_closed_status: { $ne: true },
    //         status: "Active"
    //     },
    //     api: '/employeephappraisal',
    // }

    tabs['CLosed'] = {
        label: 'Closed Appraisals',
        eventSourceId: 'Appraisal',
        view: <PHFinalAppraisalTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                hrPayroll_appraisal_status: true,
                hrPayroll_closed_status: true,
                status: "Closed"
            }}
            api = '/employeephappraisal'
            user={user}
            {...rest}
            tab = "Closed Appraisals"
             />,
        addOnFilter: {
            ...filter,
            hrPayroll_appraisal_status: true,
            hrPayroll_closed_status: true,
            status: "Closed"
        },
        api: '/employeephappraisal',
    }


    return <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
            <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                    ...appraisalCommonFilters
                ]}
            />,
        ]}
    />;
};

export default PHAppraisalTab;
