import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchData} from '../../../controllers/useFetchData';

export const ThreadTaskForm = props => {
  const {
    route: {params},
  } = props;

  let {
    row = {},
    masterTaskAssignment,
    selectedIds = [],
    departmentTasks,
    departmentRecurringTasks,
  } = params || {};

  if (departmentTasks) {
    const {data: tasks = []} = useFetchData({
      api: '/organizationtasks',
      fields: {
        _id: 1,
      },
      params: {teamId: row?._id},
      filter: {
        status: {$in: ['active', 'backlog']},
      },
      limit: 1000000,
    });

    selectedIds = tasks.map(item => item._id);
  }

  if (departmentRecurringTasks) {
    const {data: tasks = []} = useFetchData({
      api: '/mastertaskassignments',
      fields: {
        _id: 1,
      },
      filter: {
        status: 'Active',
        parent_model_relation: 'Lifetime',
        employee_team: row?._id,
        parent_task_assignment: null,
      },
      limit: 1000000,
    });

    selectedIds = tasks.map(item => item._id);
  }

  let selectedProps = {};
  if (selectedIds && selectedIds.length > 0) {
    selectedProps = {ids: selectedIds};
  }

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Task',
    uri: masterTaskAssignment ? '/mastertaskassignments' : '/projecttasks',
    ...props,
    ...selectedProps,
  });
  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={
        !(selectedIds && selectedIds.length)
          ? masterTaskAssignment
            ? `/mastertaskassignments/${row?._id}`
            : `/projecttasks/${row?._id}`
          : void 0
      }
      fields={{thread: {name: 1}}}
      layoutFields={[
        {
          field: 'thread',
          label: 'Thread',
          type: 'autoComplete',
          api: '/threads',
          filter: {status: {$in: ['Active', 'Plan']}},
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddThreadTaskForm = props => {
  return (
    <ThreadTaskForm
      header={{
        title: 'Add Thread',
        secondaryTitle: props?.route?.params?.row?.name || {},
      }}
      {...props}
    />
  );
};

export const UpdateThreadTaskForm = props => {
  return (
    <ThreadTaskForm
      mode={'edit'}
      header={{
        title: 'Update Thread',
        secondaryTitle: props?.route?.params?.row?.name || '',
      }}
      {...props}
    />
  );
};
