import React, {forwardRef, useMemo, useRef, memo, useEffect} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'quill-mention';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import './richTextInput.css';
import {RichTextWrapper} from './Styles';
import {View} from '@unthinkable/react-core-components';
import {useMergeRefs} from '@unthinkable/react-utils';
import {InputLabel, InputWrapper} from '@unthinkable/react-input';

// Move font registration inside the component to access props
const configureFonts = (additionalFonts = []) => {
  const FontClass = ReactQuill.Quill.import('attributors/class/font');
  const FontStyle = ReactQuill.Quill.import('attributors/style/font');

  const defaultFonts = [
    'sans-serif',
    'serif',
    'monospace',
  ];

  const fontWhitelist = [...defaultFonts, ...additionalFonts];

  FontClass.whitelist = fontWhitelist;
  FontStyle.whitelist = fontWhitelist;

  ReactQuill.Quill.register(FontClass, true);
  ReactQuill.Quill.register(FontStyle, true);

  return FontClass.whitelist;
};

const useQuillHandlers = ({quillRef, maxHeight, minHeight}) => {
  useEffect(() => {
    if (maxHeight) {
      const element = document.getElementsByClassName('ql-editor')[0];
      element.setAttribute('style', `max-height: ${maxHeight}px !important`);
    }
    if (minHeight) {
      const element = document.getElementsByClassName('ql-editor')[0];
      element.setAttribute('style', `min-height: ${minHeight}px !important`);
    }
  }, [maxHeight, minHeight]);

  useEffect(() => {
    const quill = quillRef.current.getEditor();

    const handleClick = event => {
      if (event.target && event.target.tagName === 'IMG') {
        const imageURL = event.target.getAttribute('src');
        window.open(imageURL, '_blank');
      }
    };

    const editorRoot = quill.root;
    editorRoot.addEventListener('click', handleClick);

    return () => {
      editorRoot.removeEventListener('click', handleClick);
    };
  }, []);
};

const RichTextInput = (
  {
    placeholder = 'Write something...',
    onChangeValue,
    value,
    mention,
    resourceUrl,
    upload,
    maxHeight,
    minHeight,
    readOnly,
    className,
    editorRef,
    additionalFonts = [],
    ...props
  },
  refProp,
) => {
  const fontWhitelist = configureFonts(additionalFonts);
  const {styles} = props;
  const internalRef = useRef(null);
  const quillRef = useMergeRefs(internalRef, refProp, editorRef);

  useQuillHandlers({quillRef, minHeight, maxHeight});

  const imageHandler = async () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      var file = input && input.files ? input.files[0] : null;
      let quillObj = quillRef.current.getEditor();
      const result = await upload(file);
      const url = resourceUrl(result);
      const range = quillObj.getSelection();
      quillObj.editor.insertEmbed(range.index, 'image', url);

      // Insert a space after the image
      quillObj.insertText(range.index + 1, ' ');

      // Move cursor to after the space
      quillObj.setSelection(range.index + 2);

      // Trigger editor change event (optional, depending on your implementation)
      if (quillRef.current.onEditorChange) {
        quillRef.current.onEditorChange('text-change', range.index);
      }
    };
  };
  const modules = useMemo(() => {
    const {options = [], ...restMention} = mention || {};
    return {
      toolbar: {
        container: [
          [{header: '1'}, {header: '2'}, {font: fontWhitelist}],
          [{size: []}],
          [{color: []}, {background: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        positioningStrategy: 'fixed',
        source: (searchTerm, renderList, mentionChar) => {
          let values;
          if (mentionChar === '@') {
            values = options;
          }
          // else {
          //   values = options; //hashValues;
          // }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        ...restMention,
      },
    };
  }, []);

  const onChangeEditor = value => {
    if (value === '<p><br></p>') {
      value = '';
    }
    onChangeValue && onChangeValue(value);
  };

  return (
    <InputWrapper {...props}>
      <RichTextWrapper
        className={`editor-wrapper ${className}`}
        styles={styles}>
        <ReactQuill
          style={styles?.editorStyle}
          className="editor"
          onChange={onChangeEditor}
          value={value}
          ref={quillRef}
          modules={modules}
          defaultValue={''}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'code-block',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'video',
            'mention',
            'color',
            'background',
            'clean',
          ]}
          bounds=".editor-wrapper"
          placeholder={placeholder}
          readOnly={readOnly}
        />
      </RichTextWrapper>
    </InputWrapper>
  );
};

export default forwardRef(RichTextInput);

export const RichText = memo(({value, maxHeight, minHeight}) => {
  const quillRef = useRef(null);
  useQuillHandlers({quillRef, maxHeight, minHeight});
  return (
    <ReactQuill
      ref={quillRef}
      readOnly
      value={value}
      modules={{toolbar: null}}
    />
  );
});

export const RichTextRenderer = ({className, ...props}) => {
  return (
    <InputLabel {...props}>
      <View className={`non-editable ${className}`}>
        <RichText {...props} />
      </View>
    </InputLabel>
  );
};

export const extractEditorContent = value => {
  const container = document.createElement('div');

  const quillEditor = new Quill(container);
  quillEditor.clipboard.dangerouslyPasteHTML(value);

  // Get the editor content in Delta format
  const delta = quillEditor.getContents();

  // Initialize containers for different content types
  const mentions = [];
  const images = [];
  const videos = [];
  const links = [];
  // Iterate over the Delta ops to find and extract different content types
  delta.ops.forEach(op => {
    if (op.insert) {
      if (op.insert.link) {
        // Extract link data
        links.push(op.insert.link);
      } else if (op.insert.mention) {
        // Extract mention data
        mentions.push(op.insert.mention);
      } else if (op.insert.image) {
        // Extract image source URL
        images.push(op.insert.image);
      } else if (op.insert.video) {
        // Extract video source URL
        videos.push(op.insert.video);
      }
    }
  });

  // Return the extracted content
  return {
    links,
    mentions,
    images,
    videos,
  };
};
