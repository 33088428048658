import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const BlogsForm = props => {
  const {
    route: { params },
  } = props;
  const { row, website } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/blogmodels',
    eventSourceId: 'blog',
  });
  return (
    <Form
      api={`/blogmodels/${row?._id}`}
      fields={{
        domain: {
            domain: 1,
        },
        image: 1,
        title: 1,
        description: 1,
        index: 1,
        background_image: 1,
        tags: 1,
        is_published: 1,
      }}
      defaultValues={{
        website,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Domain',
          type: 'autoComplete',
          api: '/domains',
          suggestionField: 'domain',
          valueField: 'domain',
          field: 'domain',
        },
        {
          label: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
        },
        {
          label: 'Tags',
          type: 'multiText',
          field: 'tags',
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
        {
          field: 'image',
          type: 'file',
          label: 'Image',
          required: true,
          size: 6,
        },
        {
          field: 'background_image',
          type: 'file',
          label: 'Background Image',
          size: 6,
        },
        {
          field: 'is_published',
          type: 'checkbox',
          label: 'Publish',
        },
      ]}
      {...props}
    />
  );
};

export const AddBlogsForm = props => {
  return <BlogsForm header="Add Blog" {...props} />;
};

export const UpdateBlogsForm = props => {
  return (
    <BlogsForm mode="edit" header="Update Blog" {...props} />
  );
};
