import React from 'react';
import {TabView} from '../../../components/tab';
import {OverdueReviewTasksTable} from './TasksTable';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import moment from 'moment';
import {taskFilters} from './TaskActions';

export const ReviewTasksTabs = props => {
  const {
    route: {params},
    showAllReviews,
  } = props;

  const {filterValues, applyFilter, searchValue, onChangeFilter} = useFilter({
    params: {},
  });
  const {employee_team, userId, project} = params;

  let {filter} = filterValues;

  const tabFilter = {
    status: 'completed',
    review_pending: true,
    project_id: project?._id,
    ...filter,
  };

  const tabs = {
    all: {
      label: 'Pending',
      api: '/reviewTasks',
      params: {employeeTeam: employee_team?._id, userId, showAllReviews},
      filter: tabFilter,
      search: searchValue,
      searchFields: ['name', 'code'],
      view: (
        <OverdueReviewTasksTable
          filter={{...filter, 
            project_id: project?._id
          }}
          showAllReviews={showAllReviews}
          searchValue={searchValue}
          pending={true}
        />
      ),
      eventSourceId: ['Task'],
    },
    overdue: {
      label: 'Overdue',
      api: '/reviewTasks',
      params: {employeeTeam: employee_team?._id, userId, showAllReviews},
      filter: {
        completed_on: {
          $lt: moment.utc().startOf('day').subtract(2, 'days').toDate(),
        },
        ...tabFilter,
      },
      search: searchValue,
      searchFields: ['name', 'code'],
      view: (
        <OverdueReviewTasksTable
          filter={{
            completed_on: {
              $lt: moment.utc().startOf('day').subtract(2, 'days').toDate(),
            },
            project_id: project?._id,
            ...filter,
          }}
          showAllReviews={showAllReviews}
          searchValue={searchValue}
          pending={true}
        />
      ),
      eventSourceId: ['Task'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            taskFilters.reviewer,
            taskFilters.completed_on,
            taskFilters.custom,
          ]}
        />,
      ]}
      {...props}
    />
  );
};
