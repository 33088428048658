import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {useAppStateContext} from '../../../providers/AppState';
import {
  CompletedTasksTable,
  EmployeeProjectTasks,
} from '../../task/views/TasksTable';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {GroupFilter} from '../../../components/filter/Filters';
import {taskFilters} from '../../task/views/TaskActions';

export const EmployeeCompletedTasksTab = props => {
  const {route, defaultParams} = props;
  const {params = {}} = route;
  const {filterValues, ...filterProps} = useFilter({
    params: defaultParams,
  });
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        projectTasks: {
          label: 'Project Tasks',
          apiKey: 'active',
          view: (
            <EmployeeProjectTasks
              {...props}
              filterValues={filterValues}
              fromOutputView={true}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.project({user})]}
            />,
          ],
        },
        organizationTasks: {
          label: 'Custom Tasks',
          apiKey: 'active',
          view: (
            <CompletedTasksTable
              {...props}
              skipAddTask
              groupFilter={filterValues}
              fromOutputView={true}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.custom]}
            />,
          ],
        },
      }}
    />
  );
};

export const EmployeeEfficiencyDetail = props => {
  const {route: {params} = {}} = props;
  const {
    fromProject,
    row = {},
    fromOrganisation,
    fromThreadTask = false,
  } = params || {};

  const from = row?.datePeriod?.start_date;
  const to = row?.datePeriod?.end_date;
  const daysDifference = Math.floor(
    (new Date(to) - new Date(from)) / (1000 * 60 * 60 * 24),
  );
  let filterOf = daysDifference > 7 ? 'Month' : 'Week';
  let period = {from, to, filterOf};
  const {user} = useAppStateContext();

  const parentSourceView = 'EmployeeEfficiency';

  const menus = [
    {
      label: 'Tasks',
      view: (
        <EmployeeCompletedTasksTab
          key="Task"
          defaultParams={{period}}
          parentSourceView={parentSourceView}
        />
      ),
    },
    {
      label: 'Reviews',
      view: (
        <EmployeeCompletedTasksTab
          key="Review"
          defaultParams={{
            fromReview: true,
            period,
            review_pending: false,
          }}
          parentSourceView={parentSourceView}
        />
      ),
    },
  ];

  const projectMenus = [
    {
      label: 'Tasks',
      view: (
        <EmployeeProjectTasks
          key="Task"
          addOnParams={{period}}
          parentSourceView={parentSourceView}
          fromThreadTask={fromThreadTask}
        />
      ),
      eventSourceId: 'Task',
    },
    {
      label: 'Reviews',
      view: (
        <EmployeeProjectTasks
          key="Review"
          addOnParams={{
            fromReview: true,
            period,
            review_pending: false,
          }}
          parentSourceView={parentSourceView}
          fromThreadTask={fromThreadTask}
        />
      ),
    },
  ];

  const organisationMenus = [
    {
      label: 'Tasks',
      view: (
        <CompletedTasksTable
          {...props}
          key="Task"
          skipAddTask
          defaultParams={{period}}
          title="Tasks"
          parentSourceView={parentSourceView}
        />
      ),
      eventSourceId: 'Task',
    },
    {
      label: 'Reviews',
      view: (
        <CompletedTasksTable
          {...props}
          key="Review"
          skipAddTask
          defaultParams={{
            fromReview: true,
            period,
            review_pending: false,
          }}
          parentSourceView={parentSourceView}
          title="Reviews"
        />
      ),
    },
  ];

  return (
    <LeftMenuView
      {...props}
      menus={
        fromProject || user?.userType != 'employee'
          ? projectMenus
          : fromOrganisation
          ? organisationMenus
          : menus
      }
    />
  );
};
