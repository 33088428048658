import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {randomColor} from '../../../utils/util';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const BankTxnUpdateForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  console.log('row', row);
  let {onSubmit} = useFormSubmit({
    uri: `/banktransacations`,
    eventSourceId: 'banktransanction',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['banktransanction'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        txn_type: row?.txn_type,
        unique_id: row?.unique_id,
        ifsc: row?.ifsc,
        remarks: row?.remarks,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Transaction type',
          field: 'txn_type',
          options: ['RGS', 'RTG', 'TPA'],
          // type: 'textArea',
          // required: true,
        },
        {
          label: 'Unique Id',
          field: 'unique_id',
          type: 'textArea',
          // required: true,
        },
        {
          label: 'IFSC',
          field: 'ifsc',
          type: 'text',
        },
        {
          label: 'Remark',
          field: 'remarks',
          type: 'text',
        },
      ]}
    />
  );
};

export const BankTxnForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <BankTxnUpdateForm
      header={{
        title: 'Update transaction',
      }}
      {...props}
    />
  );
};

export const BankTransactionFundTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
  } = props;
  const {row} = params;
  if (row) {
    addOnFilter = {
      ...addOnFilter,
      fundTransfer_id: row._id,
    };
  }
  return (
    <Table
      api={`/banktransanction`}
      addOnFilter={addOnFilter}
      eventsourceId="banktransanction"
      columns={[
        {
          header: 'From Bank',
          type: 'colorTag',
          minWidth: 150,
          colorField: randomColor,
          render: ({row, styles}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.fund_id?.name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.debit_acc || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'To Bank',
          type: 'colorTag',
          minWidth: 150,
          colorField: randomColor,
          render: ({row, styles}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.payee_name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.ifsc || '',
                    },
                    {
                      value: row?.credit_acc || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Category & Type',
          width: 200,
          render: ({row, styles}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.category?.name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.txn_type || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          width: 200,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('bank-transaction-update-form', {row});
          },
        },
      ]}
    />
  );
};

export const BankTransactionTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
    searchValue,
  } = props;
  const {row, fromFund = false} = params || {};
  if (row && !fromFund) {
    addOnFilter = {
      ...addOnFilter,
      payment_id: row._id,
    };
  } else if (row && fromFund) {
    addOnFilter = {
      ...addOnFilter,
      fundTransfer_id: row._id,
    };
  }
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'banktransanction',
  });
  const updateinvoke = useInvoke({
    method: 'put',
    eventSourceId: 'banktransanction',
  });
  return (
    <Table
      api={`/banktransanction`}
      search={searchValue}
      searchFields="payee_name"
      addOnFilter={addOnFilter}
      eventSourceId="banktransanction"
      columns={[
        {
          header: 'Date',
          field: 'payment_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'payee_name',
          type: 'colorTag',
          minWidth: 250,
          colorField: randomColor,
          render: ({row, styles}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.payee_name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.ifsc || '',
                    },
                    {
                      value: row?.credit_acc || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Category & Type',
          width: 200,
          render: ({row, styles}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.category?.name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.payment_id?.payment_type || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Payment no & bank',
          width: 250,
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.payment_id?.payment_no || ''}
                items={[
                  {
                    value: row?.fund_id?.name || '',
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Amount & MOP',
          width: 200,
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.amount}
                items={[
                  {
                    value: row?.payment_id?.payment_type || '',
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'UTR Number',
          field: 'utr_number',
          type: 'text',
          width: 200,
          visible: () => {
            if (tab === 'Successful') {
              return true;
            }
          },
        },
        {
          header: 'Remark',
          field: 'remarks',
          type: 'text',
          width: 200,
        },
        {
          header: 'Subsidiary & unit',
          width: 250,
          render: ({row}) => {
            return (
              <>
                <Tag
                  color={randomColor()}
                  value={row?.payment_id?.organization?.name || ''}
                />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.payment_id?.product?.name || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Status',
          width: 250,
          // field: 'status',
          type: 'text',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
              primaryTitle={row?.transaction_status}
                items={[
                  {
                    value: row?.status || '',
                  },
                ]}
              />
            );
          },
        },
        // {
        //   header: 'Approver',
        //   width: 200,
        // },
      ]}
      moreActions={[
        {
          title: 'check Transaction',
          confirm: {
            title: 'Check Transaction',
            message: 'Are you sure you want to Check Transaction?',
          },
          visible: () => {
            if (tab === 'Requested') return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/checkTransaction`,
              props: {
                checkTransaction: row?._id,
              },
            });
          },
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('bank-transaction-update-form', {row});
          },
        },
        {
          title: 'Update Status',
          onPress: ({row}) => {
            updateinvoke({
              uri: `/banktransacations/${row?._id}`,
              props: {
                transaction_status: 'Manually updated',
              },
            });
          },
          visible: () => {
            if (tab === 'Requested') return true;
          },
        },
      ]}
    />
  );
};
