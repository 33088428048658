import React from 'react';
import { Form } from '../../../../components/form/Form';
import { useFormSubmit } from '../../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../../providers/AppState';

const addAttendanceLayoutFields = [
  {
    label: 'Attendance Details',
    fields: [
      {
        placeholder: 'Employee',
        field: 'employee',
        type: 'autoComplete',
        api: `/employeeSuggestions`,
        label: 'Employee',
        suggestionField: 'name',
        valueField: 'name',
        secondarySuggestionField: 'official_email_id',
        required: true,
        filter: {employee_status: {$in: ['Active', 'Inactive']}},
        size: 6,
      },
      {
        type: 'date',
        field: 'attendance_date',
        label: 'Attendance Date',
        placeholder: 'Date',
        size: 6,
      },
      {
        field: 'first_attendance_type_id',
        type: 'autoComplete',
        api: `/DailyAttendanceTypes`,
        label: 'First Half',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
      },
      {
        field: 'second_attendance_type_id',
        type: 'autoComplete',
        api: `/DailyAttendanceTypes`,
        label: 'Second Half',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
      },
      {
        field: 'leave_type_id',
        type: 'autoComplete',
        api: `/currentLeaveType`,
        label: 'Leave Type Id',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
        filter: ({values}) => {
          let filter = {};
          if (values?.attendance_date) {
            filter['from_date'] = new Date(values?.attendance_date);
            filter['to_date'] = new Date(values?.attendance_date);
          }
          if (values?.employee) {
            filter['employee'] = values?.employee;
          }
          return filter;
        },
        visible: ({values}) => {
          if (
            values &&
            (values?.first_attendance_type_id?.name ||
              values?.second_attendance_type_id?.name) === 'Leave'
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        field: 'in_time',
        placeholder: 'In Time(hh:mm)',
        label: 'In Time',
        type: 'text',
        size: 6,
      },
      {
        field: 'out_time',
        placeholder: 'Out Time(hh:mm)',
        label: 'Out Time',
        type: 'text',
        size: 6,
      },
      {
        field: 'time_in_office',
        placeholder: ' Time in Office(min)',
        label: 'Time in Office',
        type: 'text',
        size: 6,
      },
      {
        field: 'break_time',
        placeholder: 'Break Time(min)',
        label: 'Break Time',
        type: 'text',
        size: 6,
      },
      {
        type: 'checkbox',
        field: 'is_approved',
        placeholder: 'Internal Customer',
        label: 'Approved',
        size: 'small',
      },
    ],
  },
];

const updateHRAttendanceLayoutFields = [
  {
    placeholder: 'First Half Day',
    field: 'first_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'First Half Day',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
  },
  {
    placeholder: 'Second Half Day',
    field: 'second_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'Second Half Day',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
  },
  {
    type: 'autoComplete',
    field: 'leave_type_id',
    suggestionField: 'name',
    valueField: 'name',
    api: '/leavetypes',
    label: 'Leave Type',
    size: 6,
    visible: ({values}) => {
      if (
        values?.first_attendance_type_id?.name === 'Leave' ||
        values?.second_attendance_type_id?.name === 'Leave'
      ) {
        return true;
      }
    },
  },
  {
    type: 'text',
    field: 'purpose',
    label: 'Purpose',
    visible: ({values}) => {
      if (
        values.first_attendance_type_id?.name === 'EWD' ||
        values?.second_attendance_type_id?.name === 'EWD'
      ) {
        return true;
      }
    },
    size: 6,
  },
  {
    type: 'textArea',
    field: 'remark',
    placeholder: 'Remark',
    required: true,
  },
];

const updateAttendanceLayoutFields = [
  {
    placeholder: 'First Half Day',
    field: 'first_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'First Half Day',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
    filter: {
      _id: {
        $in: [
          // '53744b5b3eabca020078060a',
          '5382e9e124b06a02005c09f2',
          '5382e9fd70f5d4020032160f',
          '5382ea1170f5d40200321610',
          '5382ea3470f5d40200321611',
        ],
      },
    },
  },
  {
    placeholder: 'Second Half Day',
    field: 'second_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'Second Half Day',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
    filter: {
      _id: {
        $in: [
          // '53744b5b3eabca020078060a',
          '5382e9e124b06a02005c09f2',
          '5382e9fd70f5d4020032160f',
          '5382ea1170f5d40200321610',
          '5382ea3470f5d40200321611',
        ],
      },
    },
  },
  {
    type: 'textArea',
    field: 'remark',
    placeholder: 'Remark',
    required: true,
  },
];

const selfleavelayoutfields = [
  {
    field: 'first_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'First Half',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
    filter: {
      _id: {
        $in: ['53744b5b3eabca020078060a', '53744b5b3eabca020078060b'],
      },
    },
  },
  {
    field: 'second_attendance_type_id',
    type: 'autoComplete',
    api: `/DailyAttendanceTypes`,
    label: 'Second Half',
    suggestionField: 'name',
    valueField: 'name',
    required: true,
    size: 6,
    filter: {
      _id: {
        $in: ['53744b5b3eabca020078060a', '53744b5b3eabca020078060b'],
      },
    },
  },
  {
    label: 'Leave Type',
    placeholder: 'Leave Type',
    field: 'leave_type_id',
    type: 'autoComplete',
    api: `/leavetypes`,
    suggestionField: 'name',
    valueField: 'name',
    size: 6,
  },
  {
    label: 'Reason',
    type: 'text',
    placeholder: 'Reason',
    field: 'remark',
  },
];

const selfAttendanceLayoutFields = [
  {
    label: 'Attendance Details',
    fields: [
      {
        field: 'first_attendance_type_id',
        type: 'autoComplete',
        api: `/DailyAttendanceTypes`,
        label: 'First Half',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
      },
      {
        field: 'second_attendance_type_id',
        type: 'autoComplete',
        api: `/DailyAttendanceTypes`,
        label: 'Second Half',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
      },
      {
        field: 'leave_type_id',
        type: 'autoComplete',
        api: `/LeaveTypes`,
        label: 'Leave Type',
        suggestionField: 'name',
        valueField: 'name',
        required: true,
        size: 6,
        visible: ({values}) => {
          if (
            values &&
            (values?.first_attendance_type_id?.name === 'Leave' ||
              values?.second_attendance_type_id?.name === 'Leave')
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        type: 'date',
        field: 'attendance_date',
        label: 'Attendance Date',
        placeholder: 'Date',
        size: 6,
      },
      {
        field: 'in_time',
        placeholder: 'In Time(hh:mm)',
        label: 'In Time',
        type: 'text',
        size: 6,
      },
      {
        field: 'out_time',
        placeholder: 'Out Time(hh:mm)',
        label: 'Out Time',
        type: 'text',
        size: 6,
      },
      {
        field: 'time_in_office',
        placeholder: ' Time in Office(min)',
        label: 'Time in Office',
        type: 'text',
        size: 6,
      },
      {
        field: 'break_time',
        placeholder: 'Break Time(min)',
        label: 'Break Time',
        type: 'text',
        size: 6,
      },
    ],
  },
];

const AttendanceForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyattendances',
    eventSourceId: 'allattendance',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/dailyattendances/${row?._id}`}
      fields={{
        first_attendance_type_id: {name: 1},
        second_attendance_type_id: {name: 1},
        remark: 1,
      }}
      eventSourceId={['allattendance']}
      {...props}
    />
  );
};

const AddAttendanceForm = props => {
  return (
    <AttendanceForm
      header="Add Attendance"
      defaultValues={{
        is_approved: true,
        status: 'approve',
        was_manual_update: true,
      }}
      computations={{
        status: [
          value => {
            const {is_approved} = value || {};
            if (is_approved) return 'approve';
            else return 'unapproved';
          },
          ['is_approved'],
        ],
        was_manual_update: [
          value => {
            if (value) {
              return true;
            }
          },
        ],
        leave_type_id: [
          value => {
            let {first_attendance_type_id, second_attendance_type_id} = value;
            if (
              (first_attendance_type_id?.name ||
                second_attendance_type_id?.name) === 'Leave'
            ) {
              return value?.leave_type_id;
            } else {
              return null;
            }
          },
        ],
      }}
      layoutFields={addAttendanceLayoutFields}
      {...props}
    />
  );
};
const AddSelfAttendanceForm = props => {
  let {user} = useAppStateContext();
  let {
    employee: {_id},
  } = user;
  return (
    <AttendanceForm
      header="Add Attendance"
      defaultValues={{
        is_approved: false,
        status: 'unapproved',
        employee: _id,
        was_manual_update: true,
      }}
      computations={{
        status: [
          value => {
            const {is_approved} = value || {};
            if (is_approved) return 'approve';
            else return 'unapproved';
          },
          ['is_approved'],
        ],
        leave_type_id: [
          value => {
            let {
              first_attendance_type_id,
              second_attendance_type_id,
              leave_type_id,
            } = value;
            if (
              first_attendance_type_id?.name === 'Leave' ||
              second_attendance_type_id?.name === 'Leave'
            ) {
              return leave_type_id;
            } else {
              return null;
            }
          },
        ],
      }}
      layoutFields={selfAttendanceLayoutFields}
      {...props}
    />
  );
};

const UpdateAttendanceForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  const {first_attendance_type_id, second_attendance_type_id, ...rest} = row;
  return (
    <AttendanceForm
      header="Update Attendance"
      // mode="edit"
      defaultValues={{
        ...rest,
        is_approved: false,
        status: 'unapproved',
        was_manual_update: true,
      }}
      layoutFields={updateAttendanceLayoutFields}
      {...props}
    />
  );
};

const UpdateAttendanceFormHR = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  const {first_attendance_type_id, second_attendance_type_id, ...rest} = row;
  return (
    <AttendanceForm
      header="Update Attendance"
      // mode="edit"
      defaultValues={{
        ...rest,
        is_approved: true,
        status: 'approve',
        was_manual_update: true,
        is_hr: true,
      }}
      layoutFields={[
        {
          placeholder: 'First Half Day',
          field: 'first_attendance_type_id',
          type: 'autoComplete',
          api: `/DailyAttendanceTypes`,
          label: 'First Half Day',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          // filter: {
          //   _id: {
          //     $in: [
          //       // '53744b5b3eabca020078060a',
          //       '5382e9e124b06a02005c09f2',
          //       '5382e9fd70f5d4020032160f',
          //       '5382ea1170f5d40200321610',
          //       '5382ea3470f5d40200321611',
          //     ],
          //   },
          // },
        },
        {
          placeholder: 'Second Half Day',
          field: 'second_attendance_type_id',
          type: 'autoComplete',
          api: `/DailyAttendanceTypes`,
          label: 'Second Half Day',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          // filter: {
          //   _id: {
          //     $in: [
          //       // '53744b5b3eabca020078060a',
          //       '5382e9e124b06a02005c09f2',
          //       '5382e9fd70f5d4020032160f',
          //       '5382ea1170f5d40200321610',
          //       '5382ea3470f5d40200321611',
          //     ],
          //   },
          // },
        },
        {
          type: 'autoComplete',
          field: 'leave_type_id',
          suggestionField: 'name',
          valueField: 'name',
          api: '/currentLeaveType',
          label: 'Leave Type',
          size: 6,
          filter: ({values}) => {
            let filter = {};
            if (values?.attendance_date) {
              filter['from_date'] = new Date(values?.attendance_date);
              filter['to_date'] = new Date(values?.attendance_date);
            }
            if (values?.employee) {
              filter['employee'] = values?.employee;
            }
            return filter;
          },
          visible: ({values}) => {
            if (
              values?.first_attendance_type_id?.name === 'Leave' ||
              values?.second_attendance_type_id?.name === 'Leave'
            ) {
              return true;
            }
          },
        },
        {
          type: 'text',
          field: 'purpose',
          label: 'Purpose',
          visible: ({values}) => {
            if (
              values.first_attendance_type_id?.name === 'EWD' ||
              values?.second_attendance_type_id?.name === 'EWD'
            ) {
              return true;
            }
          },
        },
        {
          type: 'textArea',
          field: 'remark',
          placeholder: 'Remark',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

const EWDForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyattendances',
    eventSourceId: 'allattendance',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/dailyattendances/${row?._id}`}
      fields={{
        first_attendance_type_id: {name: 1},
        second_attendance_type_id: {name: 1},
        remark: 1,
      }}
      eventSourceId={['allattendance']}
      {...props}
    />
  );
};

const UpdateEWDForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  let employeeFilter = row.employee._id;
  return (
    <EWDForm
      header="EWD Approval"
      // mode="edit"
      eventSourceId="teamAttendance"
      defaultValues={{
        ...row,
        is_approved: true,
        status: 'approve',
        was_manual_update: true,
      }}
      layoutFields={[
        {
          fields: [
            {
              field: 'purpose',
              type: 'autoComplete',
              placeholder: 'Purpose',
              options: ['OnProject', 'other'],
              required: true,
            },
            {
              field: 'project_id',
              type: 'autoComplete',
              api: '/employeeassignments/projects',
              suggestionField: 'project',
              valueField: 'project',
              required: true,
              label: 'Project',
              visible: ({values}) => {
                if (values.purpose === 'OnProject') {
                  return true;
                }
                return false;
              },
              filter: {
                employee: employeeFilter,
              },
            },
            {
              field: 'ewd_type',
              type: 'autoComplete',
              label: 'EWD Type',
              required: true,
              options: [
                'Leave Compensation',
                'Client Request/Additional Work',
                'Non Billable',
              ],
              visible: ({values}) => {
                if (values.purpose === 'OnProject') {
                  return true;
                }
                return false;
              },
            },
            {
              field: 'reason',
              type: 'textArea',
              label: 'Reason',
              placeholder: 'Type here',
              mandatory: true,
              minHeight: 90,
              visible: ({values}) => {
                if (values.purpose === 'other') {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const MultipleAttendanceForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyattendances',
    eventSourceId: 'allattendance',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      // api={`/dailyattendances/${row?._id}`}
      fields={{
        first_attendance_type_id: {name: 1},
        second_attendance_type_id: {name: 1},
        remark: 1,
      }}
      eventSourceId={['allattendance']}
      {...props}
    />
  );
};

const UpdateMultipleAttendanceForm = props => {
  return (
    <MultipleAttendanceForm
      header="Update Attendance"
      defaultValues={{
        is_approved: true,
        status: 'approve',
        was_manual_update: true,
        is_hr: true,
      }}
      layoutFields={updateHRAttendanceLayoutFields}
      {...props}
    />
  );
};

const SelfLeaveRequestForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyattendances',
    eventSourceId: 'allattendance',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      api={`/dailyattendances/${row?._id}`}
      fields={{
        duration_of_leave: 1,
        type_of_half_day: 1,
        leave_type_id: {name: 1},
        remark: 1,
      }}
      submitAction="Save"
      {...props}
    />
  );
};

const AddSelfLeaveRequestForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return (
    <SelfLeaveRequestForm
      header={'Add Leave Request'}
      defaultValues={{
        ...row,
        first_attendance_type_id: {
          _id: '53744b5b3eabca020078060b',
          name: 'Leave',
        },
        second_attendance_type_id: {
          _id: '53744b5b3eabca020078060b',
          name: 'Leave',
        },
        is_approved: false,
        status: 'unapproved',
        was_manual_update: true,
        __v: 1,
      }}
      layoutFields={selfleavelayoutfields}
      {...props}
    />
  );
};

export {
    AddAttendanceForm, AddSelfAttendanceForm, AddSelfLeaveRequestForm, UpdateAttendanceForm,
    UpdateAttendanceFormHR, UpdateEWDForm,
    UpdateMultipleAttendanceForm
};

