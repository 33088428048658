import {Col, Text} from '@unthinkable/react-core-components';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import React from 'react';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {GroupFilter} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import { IconButton } from '../../../components/button/IconButton';
import { useTheme } from '@unthinkable/react-theme';
import { useAppStateContext } from '../../../providers/AppState';

const getResolvedProductiveValue = value => {
  if (value === 'Yes') {
    return 'Productive';
  }
  if (value === 'No') {
    return 'Not Productive';
  }
  return value;
};

const ProductivitySurveyTable = props => {
  const {
    navigation,
    route: {params},
    tab,
  } = props;

  return (
    <Table
      api="/monthWiseProductivitySurveys"
      sort={{feedback_date: -1}}
      eventSourceId="productivitySurvey"
      fields={{
        feedback_date: 1,
        productive: 1,
        productive_points: 1,
        productive_suggestion: 1,
        employee: {
          name: 1,
          official_email_id: 1,
          color: 1,
        },
        rating: 1,
        rating_feedback: 1,
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <GroupContentItem
              value={moment(row.feedback_date).utc().format('MMMM YYYY')}
            />
          );
        },
        rightContent: ({row}) => {
          return (
            <RowWithSeparator gap={8} style={{alignItems: 'center'}}>
              <GroupContentItem value={'Total: ' + row?.total_count} />
              <GroupContentItem
                value={'Productive: ' + row?.productive_count}
              />
              <GroupContentItem value={'Awesome: ' + row?.awesome_count} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          render: ({value}) => <UserAvatarWithNameAndEmail {...value} />,
          width: 250,
        },
        {
          header: 'Department',
          field: 'employee.department.name',
          type: 'text',
          width: 180,
        },
        {
          header: 'Productivity',
          render: ({row}) => {
            const {productive_points, productive_suggestion, productive} = row;
            return (
              <Col style={{gap: 4, alignItems: 'center'}}>
                <TextRenderer value={getResolvedProductiveValue(productive)} />
                <RowWithSeparator
                  style={{
                    gap: 4,
                    alignItems: 'center',
                  }}>
                  {productive == 'Productive' ? (
                    productive_points?.map(item => (
                      <GroupContentItem value={item} title={item} />
                    ))
                  ) : (
                    <GroupContentItem
                      value={productive_suggestion}
                      title={productive_suggestion}
                    />
                  )}
                </RowWithSeparator>
              </Col>
            );
          },
          align: 'center',
        },
        {
          header: 'Experience',
          field: 'employee.experience',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>{row?.employee?.experience} yrs</Text>
            );
          },
          type: 'text',
          width: 100,
        },
        {
          header: 'Month',
          field: 'feedback_date',
          type: 'date',
          width: 120,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Reporting To',
          field: 'employee.reporting_to',
          type: 'userAvatar',
          width: 120,
          align: 'center',
        },
        // {
        //   header: 'Rating',
        //   render: ({row}) => {
        //     const {rating_feedback, rating} = row;
        //     return (
        //       <Col style={{gap: 4, alignItems: 'center'}}>
        //         <TextRenderer
        //           value={`Work Experience (${rating})`}
        //           title={`Work Experience (${rating})`}
        //         />
        //         <GroupContentItem
        //           value={rating_feedback}
        //           title={rating_feedback}
        //         />
        //       </Col>
        //     );
        //   },
        //   align: 'center',
        //   width: 250,
        // },
        {
          header: 'Action Taken',
          field: 'action_taken',
          visible: tab == 'closed',
        },
      ]}
      moreActions={[
        {
          title: "Manager's Remarks",
          onPress: ({row}) => {
            navigation.navigate('managers-remark-on-productivity', {
              ...params,
              productivity_survey: row,
              editable: false,
            });
          },
        },
        {
          title: 'Mark Closed',
          onPress: async ({row}) => {
            navigation.navigate('close-productivity-survey', {
              ...params,
              productivity_survey: row,
            });
          },
          visible: ({row}) => row?.status === 'Pending',
        },
      ]}
      {...props}
    />
  );
};

export const ProductivitySurveyTablePI = props => {
  const {filterValues, applyFilter} = useFilter({
    plainFilter: {feedback_date: getPreviousMonth()},
    filter: {
      feedback_date: {
        $gte: moment().utc().subtract(1, 'month').startOf('month').toDate(),
        $lte: moment().utc().subtract(1, 'month').endOf('month').toDate(),
      },
    },
  });
  const icons = useTheme('icons');
  const { download } = useAppStateContext();
  const tabs = {
    pending: {
      label: 'Open',
      api: '/productivitySurveys',
      filter: {
        status: 'Pending',
      },
      addOnFilter: filterValues.filter,
      eventSourceId: 'productivitySurvey',
      view: (
        <ProductivitySurveyTable
          filter={{
            status: 'Pending',
          }}
          addOnFilter={filterValues.filter}
          {...props}
        />
      ),
      actions : [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              type: 'multiAutoComplete',
              field: 'employee',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              placeholder: 'Select Employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              field: 'feedback_date',
              label: 'Feedback Date',
              options: [
                {label: 'Month', value: 'Month'},
                {
                  label: 'Year',
                  value: 'Year',
                },
                {
                  label: 'Custom',
                  value: 'Custom',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'productive',
              label: 'Productivity',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              placeholder: 'Select Productivity',
              options: [
                {label: 'Yes', value: 'Productive'},
                {label: 'No', value: 'Not Productive'},
                {
                  label: 'Sometimes Yes, Sometimes No',
                  value: 'Sometimes Productive',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'rating',
              label: 'Rating',
              placeholder: 'Select Rating',
              options: ['Awesome', 'Just Okay', 'Unsatisfied'],
            },
            {
              type: 'autoComplete',
              field: 'status',
              label: 'Status',
              placeholder: 'Select Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                {
                  label: 'Pending',
                  value: 'Pending',
                },
                {
                  label: 'Processed',
                  value: 'OK',
                },
              ],
            },
          ]}
        />,
          <IconButton
              icon={icons.Download}
              onPress={() => {
                  download({
                      uri: '/downloadProductivitySurvey',
                      props: {
                          addOnFilter: { ...filterValues.filter , status: 'Pending'},
                      },
                  });
              }}
          />,
      ]
    },
    closed: {
      label: 'Closed',
      api: '/productivitySurveys',
      filter: {
        status: 'OK',
      },
      addOnFilter: filterValues.filter,
      eventSourceId: 'productivitySurvey',
      view: (
        <ProductivitySurveyTable
          filter={{
            status: 'OK',
          }}
          addOnFilter={filterValues.filter}
          tab="closed"
          {...props}
        />
      ),
      actions : [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              type: 'multiAutoComplete',
              field: 'employee',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              placeholder: 'Select Employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              field: 'feedback_date',
              label: 'Feedback Date',
              options: [
                {label: 'Month', value: 'Month'},
                {
                  label: 'Year',
                  value: 'Year',
                },
                {
                  label: 'Custom',
                  value: 'Custom',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'productive',
              label: 'Productivity',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              placeholder: 'Select Productivity',
              options: [
                {label: 'Yes', value: 'Productive'},
                {label: 'No', value: 'Not Productive'},
                {
                  label: 'Sometimes Yes, Sometimes No',
                  value: 'Sometimes Productive',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'rating',
              label: 'Rating',
              placeholder: 'Select Rating',
              options: ['Awesome', 'Just Okay', 'Unsatisfied'],
            },
            {
              type: 'autoComplete',
              field: 'status',
              label: 'Status',
              placeholder: 'Select Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                {
                  label: 'Pending',
                  value: 'Pending',
                },
                {
                  label: 'Processed',
                  value: 'OK',
                },
              ],
            },
          ]}
        />,
          <IconButton
              icon={icons.Download}
              onPress={() => {
                  download({
                      uri: '/downloadProductivitySurvey',
                      props: {
                          addOnFilter: { ...filterValues.filter,status: 'OK' },
                      },
                  });
              }}
          />,
      ]
    },
    all: {
      label: 'All',
      api: '/productivitySurveys',
      addOnFilter: filterValues.filter,
      eventSourceId: 'productivitySurvey',
      view: (
        <ProductivitySurveyTable
          addOnFilter={filterValues.filter}
          tab="all"
          {...props}
        />
      ),
      actions : [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Employee',
              type: 'multiAutoComplete',
              field: 'employee',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              placeholder: 'Select Employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              field: 'feedback_date',
              label: 'Feedback Date',
              options: [
                {label: 'Month', value: 'Month'},
                {
                  label: 'Year',
                  value: 'Year',
                },
                {
                  label: 'Custom',
                  value: 'Custom',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'productive',
              label: 'Productivity',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              placeholder: 'Select Productivity',
              options: [
                {label: 'Yes', value: 'Productive'},
                {label: 'No', value: 'Not Productive'},
                {
                  label: 'Sometimes Yes, Sometimes No',
                  value: 'Sometimes Productive',
                },
              ],
            },
            {
              type: 'autoComplete',
              field: 'rating',
              label: 'Rating',
              placeholder: 'Select Rating',
              options: ['Awesome', 'Just Okay', 'Unsatisfied'],
            },
            {
              type: 'autoComplete',
              field: 'status',
              label: 'Status',
              placeholder: 'Select Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                {
                  label: 'Pending',
                  value: 'Pending',
                },
                {
                  label: 'Processed',
                  value: 'OK',
                },
              ],
            },
          ]}
        />,
          <IconButton
              icon={icons.Download}
              onPress={() => {
                  download({
                      uri: '/downloadProductivitySurvey',
                      props: {
                          addOnFilter: { ...filterValues.filter },
                      },
                  });
              }}
          />,
      ]
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
    />
  );
};
