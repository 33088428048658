import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import moment from 'moment';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

export const MisSortSummaryReport = props => {
  const {route: {params = {}} = {}} = props;
  let {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      ...params,
      period: getCurrentMonth(),
    },
  });

  const {icons} = useTheme();
  const {download} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title="Mis Report (Short Summary)"
            actions={[
              // <PeriodRangeFilter
              //   filterValues={filterValues}
              //   onChangeFilter={onChangeFilter}
              //   options={[]}
              //   skipQuarter
              // />,
              // <GroupFilter
              //   filterValues={filterValues}
              //   onChangeFilter={onChangeFilter}
              //   applyFilter={applyFilter}
              //   filters={[
              //     {
              //       label: 'Organization',
              //       type: 'multiAutoComplete',
              //       field: 'organization',
              //       api: '/organizations',
              //       suggestionField: 'name',
              //       valueField: 'name',
              //     },
              //     {
              //       label: 'Product',
              //       type: 'multiAutoComplete',
              //       field: 'product',
              //       api: '/products',
              //       suggestionField: 'name',
              //       valueField: 'name',
              //     },
              //   ]}
              // />,
              <TouchableOpacity
                onPress={async () => {
                  await download({
                    uri: '/exportExcelHelper',
                    props: {
                      props: {
                        fetchProps: {
                          api: '/misSortSummaryReport',
                          params: filterValues.params,
                          filter: filterValues.filter,
                          globalParams,
                        },
                        column: {
                          'Short Summary': 'title',
                          Current: 'current',
                          Previous: 'previous',
                          Difference: 'difference',
                        },
                        filename: `Mis Sort Summary Report(${moment(
                          filterValues.params.period.from,
                        ).format("MMM'YY")}vs${moment(
                          filterValues.params.period.from,
                        )
                          .subtract(1, 'months')
                          .format("MMM'YY")}})`,
                      },
                    },
                  });
                }}>
                <Image source={icons.Download} />
              </TouchableOpacity>,
            ]}
          />
        );
      }}
      params={filterValues.params}
      filter={filterValues.filter}
      api="/misSortSummaryReport"
      columns={[
        {
          header: 'Short Summary',
          field: 'title',
          type: 'text',
        },
        {
          header: 'Current',
          field: 'current',
          type: 'number',
        },
        {
          header: 'Previous',
          field: 'previous',
          type: 'number',
        },
        {
          header: 'Difference',
          field: 'difference',
          type: 'number',
        },
      ]}
    />
  );
};

export const ProfitMarginVarianceTable = props => {
  const {route: {params = {}} = {}} = props;
  let {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      ...params,
      period: getCurrentMonth(),
    },
  });

  const {icons} = useTheme();
  const {download} = useAppStateContext();
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title="Mis Report (Short Summary)"
          />
        );
      }}
      params={{...filterValues.params,fromDetail:true}}
      filter={filterValues.filter}
      api="/profit-margin-variance"
      columns={[
        {
          header: 'Short Summary',
          field: 'title',
          type: 'text',
        },
        {
          header: 'Current',
          field: 'current',
          type: 'number',
        },
        {
          header: 'Previous',
          field: 'previous',
          type: 'number',
        },
        {
          header: 'Difference',
          field: 'difference',
          type: 'number',
        },
      ]}
    />
  );
};
