import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import { Text, View } from '@unthinkable/react-core-components';

export const VendorBulkform16Table = props => {
  const {navigation} = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'vendorBulkform',
  });
  const {applyFilter, filterValues} = useFilter({});

  return (
    <Table
      eventSourceId={['vendorBulkform']}
      renderHeader={() => (
        <TableHeader
          title="Vendor Bulk form 16"
          actions={[
            <AddButton
              title="Upload Form 16"
              view="upload-vendor-form"
              // params={params}
            />,
            <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                field: 'organization',
                type: 'autoComplete',
                api: '/organizations',
                suggestionField: 'name',
                valueField: 'name',
                label: 'Organization',
              },
            ]}
          />,
          ]}
        />
        
      )}
      api={`/vendorform16document`}
      addOnFilter={{...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`detail-screen-view-form-16`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          minWidth: 300,
        },
        {
          header: 'Created On',
          field: 'date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
          width: 120,
        },
        {
          label: 'Total',
          size: 6,
          type: 'number',
          placeholder: 'Total',
          // field: 'document.length',
          render: ({row}) => {
            return <Text>{row?.document?.length || 0}</Text>;
          },
        },
        {
          header: 'Errors',
          field: 'pending_count',
          type: 'number',
          width: 150,
        },
        {
          header: 'Success',
          field: 'success_count',
          type: 'number',
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Import',
          confirm: {
            title: 'Import',
            message: 'Are you sure you want to Import?',
          },
          onPress: ({row}) => {
            invoke({
              uri: '/processVendorForm16Documents',
              props: {
                _id: row._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const VendorForm16Table = props => {
  const {navigation} = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'vendorForm16',
  });
  const {applyFilter, filterValues} = useFilter({});
  return (
    <Table
      eventSourceId={['vendorForm16']}
      addOnFilter={{...filterValues.filter}}
      renderHeader={() => (
        <TableHeader
          title="Vendor form 16"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  field: 'vendor',
                  api: '/vendors',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Vendor',
                },
                {
                  field: 'organization',
                  type: 'autoComplete',
                  api: '/organizations',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Organization',
                },
                {
                  field: 'financial_year',
                  type: 'autoComplete',
                  api: '/financialyears',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Financial Year',
                },
                {
                  type: 'autoComplete',
                  label: 'Quater',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  options: [
                    {
                      label: 'Q1',
                      value: 'Q1',
                    },
                    {
                      label: 'Q2',
                      value: 'Q2',
                    },
                    {
                      label: 'Q3',
                      value: 'Q3',
                    },
                    {
                      label: 'Q4',
                      value: 'Q4',
                    },
                  ],
                  placeholder: 'Select',
                  field: 'quarter',
                },
              ]}
            />,
            <AddButton title="Upload Form 16" view="upload-vendor-form-16" />,
          ]}
        />
      )}
      selection={{
        actions: [
          {
            title: 'Send Mail',
            onPress: ({selectedIds}) => {
              console.log("selectedIds>>>>>>>>>>>",selectedIds)
              invoke({
                uri: '/sendMailtoVendorForm16',
                props: {
                  selectedIds,
                },
              });
            },
          },
        ],
      }}
      api={`/vendoritr`}
      columns={[
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Vendor Name',
          field: 'vendor.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'File',
          field: 'document',
          type: 'file',
          widt: 200,
        },
        {
          header: 'Quarter',
          field: 'quarter',
          type: 'text',
          width: 200,
        },
        {
          header: 'Assessment Year',
          field: 'financial_year.name',
          type: 'text',
          width: 200,
        },
      ]}
    />
  );
};
