import React from 'react';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {EngGrossMarginReport} from './EngGrossMarginReport';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {TableHeader} from '../../../components/table/Headers';

export const EngGrossMarginTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {params: filterParams, filter} = filterValues;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    TandM: {
      label: 'T&M',
      view: (
        <EngGrossMarginReport
          filterValues={filterValues}
          searchValue={searchValue}
          api="/employee/engineeringReport"
          tab = "tm"
        />
      ),
      actions : [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter : {employee_status : {$in : ["Active","Inactive"]}}
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'resource_language',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportEngGrossMarginReport',
              props: {
                uri: '/employee/engineeringReport',
                parameters: {
                  params: {...filterParams},
                  addOnFilter : {...filter}
                },
              },
            });
          }}
        />,
      ]
      // params: {...filterValues.params},
      // api: '/employee/engineeringReport',
      // addOnFilter: {...filterValues?.filter},
      // limit: 5000,
    },
    Fixed: {
      label: 'Fixed',
      view: (
        <EngGrossMarginReport
          filterValues={filterValues}
          searchValue={searchValue}
          api="/employee/fixedEngineeringReport"
        />
      ),
      actions : [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'resource_language',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportEngGrossMarginReport',
              props: {
                uri: '/employee/engineeringReport',
                parameters: {
                  params: {...filterParams},
                  addOnFilter : {...filter}
                },
              },
            });
          }}
        />,
      ]
      // params: {...filterValues.params},
      // api: '/employee/fixedEngineeringReport',
      // addOnFilter: {...filterValues?.filter},
      // limit: 5000,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      headerComponent={<TableHeader title="Engineers gross margin report" />}
      {...props}
    />
  );
};
