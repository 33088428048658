import {
  ActivityIndicator,
  Text,
  View,
  Linking,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';
import moment from 'moment';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useAppStateContext} from '../../../providers/AppState';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useTheme} from '@unthinkable/react-theme';

const PROJECTANDCUSTOMER = {
  header: 'Customer',
  //   align: 'center',
  //   width: 150,
  // width: 300,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.customer?.name}
        items={[
          {
            value: row?.project?.project,
          },
        ]}
      />
    );
  },
};

const DOC = resourceUrl => ({
  header: 'File',
  align: 'center',
  width: 100,
  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.requirement_document && row?.requirement_document != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.requirement_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
  width: 100,
});

const RenderStatus = ({row, styles, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/additionalemailrequests/${row?._id}`,
                props: {
                  status: 'Approved',
                  approved_date: new Date(),
                  approver_status: 'IT',
                  approved_by_head_date: new Date(),
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Approve </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-additional-email-request', {
                row,
                rejected_by: 'Team Head',
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const AdditionalEmailRequesterTables = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'budget',
  });

  return (
    <Table
      eventSourceId={['budget']}
      api={`/additionalemailrequests/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="email"
      limit={500}
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-additional-email-request', {
            row,
            readOnly: true,
          });
        } else {
          navigation.navigate('edit-additional-email-request', {row});
        }
      }}
      columns={[
        PROJECTANDCUSTOMER,
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Email',
          field: 'email',
          width: 250,
          type: 'text',
        },
        // {
        //   header: 'Description',
        //   field: 'description',
        //   type: 'text',
        //   width: 300,
        // },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        tab === 'Requested' && {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 150,
        },

        tab === 'Requested' && {
          header: 'Pending by',
          field: 'approver_status',
          type: 'colorTag',
          minWidth: 200,
        },
        tab === 'Rejected' && {
          header: 'Rejected',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        tab === 'Rejected' && {
          header: 'Remark',
          field: 'reject_remark',
          type: 'text',
          width: 100,
        },
        {
          header: 'Head Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Approved') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_head_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Finance Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Approved') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_finance_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        tab === 'Inactive' && {
          header: 'Inactive on',
          field: 'inactive_date',
          type: 'date',
          width: 100,
        },
      ]}
      moreActions={[
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to Delete?',
        //     confirmText: 'Delete',
        //   },
        //   onPress: ({row}) => {
        //     deleteInvoke({
        //       uri: `/automaticpayments/${row._id}`,
        //       props: {
        //         ...row,
        //       },
        //     });
        //   },
        // },
        tab === 'Draft' && {
          title: 'Request Additional Email',
          confirm: {
            title: 'Request',
            message: 'Are you sure you want to make a request for this email?',
            confirmText: 'Request',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/additionalemailrequests/${row._id}`,
              props: {
                status: 'Requested',
                request_date: new Date(),
                approver_status: 'Team Head',
              },
            });
          },
        },
        tab === 'Approved' && {
          title: 'Request Inactivation',
          confirm: {
            title: 'Request',
            message: 'Are you sure you want to make an inactivation request?',
            confirmText: 'Request',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/additionalemailrequests/${row._id}`,
              props: {
                status: 'Issued',
                inactivation_request: true,
                inactivation_date: new Date(),
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/additionalemailrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const AdditionalEmailApproverTables = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const colors = useTheme('colors');

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'budget',
  });
  const {resourceUrl, user} = useAppStateContext();

  return (
    <Table
      eventSourceId={['budget']}
      api={`/additionalemailrequests/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="email"
      limit={500}
      onRowPress={({row}) => {
        navigation.navigate('edit-additional-email-request', {
          row,
          readOnly: true,
        });
      }}
      columns={[
        PROJECTANDCUSTOMER,
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Email',
          field: 'email',
          width: 250,
          type: 'text',
        },
        // {
        //   header: 'Description',
        //   field: 'description',
        //   type: 'text',
        //   width: 300,
        // },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requester',
          field: 'requester',
          type: 'userAvatar',
          width: 100,
          align: 'center',
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },

        {
          header: 'Action',
          visible: ({}) => {
            if (tab === 'Requested') return true;
          },
          align: 'center',

          render: ({row, styles}) => {
            return (
              <RenderStatus row={row} styles={styles} navigation={navigation} />
            );
          },
        },

        {
          header: 'Approved',
          field: 'approved_date',
          type: 'date',
          width: 100,
          visible: props => {
            let {row} = props;
            if (tab === 'Closed') return true;
          },
        },
        {
          header: 'Rejected',
          field: 'rejected_date',
          type: 'date',
          width: 100,
          visible: ({row}) => {
            if (tab === 'Closed') return true;
          },
        },

        {
          ...DOC(resourceUrl),
          visible: props => {
            let {row} = props;
            if (tab === 'Closed') return true;
          },
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          visible: props => {
            let {row} = props;
            if (tab === 'Closed') return true;
          },
        },

        {
          header: 'Status',
          visible: ({}) => {
            if (tab === 'Closed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (
              row?.status === 'Approved' ||
              row?.status == 'Inactive' ||
              row?.status == 'Issued'
            ) {
              color = '#34C759';
              status = 'Approved';
              return (
                <Text style={{...styles.rowText, color: color}}>{status}</Text>
              );
            } else if (
              row?.status === 'Rejected' &&
              row?.rejected_by === 'Team Head'
            ) {
              console.log('here');
              color = '#FF5247';
              status = 'Rejected';
              return (
                <>
                  <Text style={{...styles.rowText, color: color}}>
                    {status}
                  </Text>
                  <Text
                    style={{
                      ...styles.rowText,
                      fontSize: 13,
                      color: colors.NEUTRAL_LOW,
                    }}>
                    {row?.reject_remark}
                  </Text>
                </>
              );
            } else if (
              row?.status === 'Rejected' &&
              row?.rejected_by === 'IT'
            ) {
              color = '#34C759';
              status = 'Approved';
              return (
                <Text style={{...styles.rowText, color: color}}>{status}</Text>
              );
            }
          },
        },

        {
          header: 'Final Status',
          visible: ({}) => {
            if (tab === 'Closed') return true;
          },
          field: 'status',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/additionalemailrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const AdditionalEmailFinanceTables = props => {
  let {navigation, addOnFilter, tab, filterValues} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'budget',
  });
  const {resourceUrl, user} = useAppStateContext();

  return (
    <Table
      {...props}
      eventSourceId={['budget']}
      api={`/additionalemailrequests/details`}
      addOnFilter={addOnFilter}
      searchFields={['email']}
      limit={500}
      onRowPress={({row}) => {
        navigation.navigate('edit-additional-email-request-it', {row});
      }}
      columns={[
        PROJECTANDCUSTOMER,
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Email',
          field: 'email',
          width: 250,
          type: 'text',
        },
        // {
        //   header: 'Description',
        //   field: 'description',
        //   type: 'text',
        //   width: 300,
        // },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requester',
          field: 'requester',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        tab === 'Requested' && {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 150,
        },

        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },

        {
          ...DOC(resourceUrl),
          visible: props => {
            let {row} = props;
            if (
              tab === 'Approved' ||
              tab === 'StopTxn' ||
              tab === 'Inactive' ||
              tab == 'Overdue'
            )
              return true;
          },
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          visible: props => {
            let {row} = props;
            if (
              tab === 'Approved' ||
              tab === 'StopTxn' ||
              tab === 'Inactive' ||
              tab == 'Overdue'
            )
              return true;
          },
        },

        tab === 'Approved' && {
          header: 'Approved',
          field: 'approved_by_finance_date',
          type: 'date',
          width: 100,
        },
        tab === 'Rejected' && {
          header: 'Rejected',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        tab === 'Rejected' && {
          header: 'Remark',
          field: 'reject_remark',
          type: 'text',
          // width: 100,
        },
        tab === 'StopTxn' && {
          header: 'Inactive Request on',
          field: 'inactivation_date',
          type: 'date',
          width: 200,
        },
        tab === 'Inactive' && {
          header: 'Inactive on',
          field: 'inactive_date',
          type: 'date',
          width: 100,
        },
      ]}
      moreActions={[
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to Delete?',
        //     confirmText: 'Delete',
        //   },
        //   onPress: ({row}) => {
        //     deleteInvoke({
        //       uri: `/automaticpayments/${row._id}`,
        //       props: {
        //         ...row,
        //       },
        //     });
        //   },
        // },

        tab === 'Requested' && {
          title: 'Issue email',
          // confirm: {
          //   title: 'Issue',
          //   message: 'Are you sure you want to issue this email?',
          //   confirmText: 'Isue',
          // },
          onPress: ({row}) => {
            navigation.navigate('issue-additional-email-request-it', {
              row,
            });
          },
          // onPress: ({row}) => {
          //   invoke({
          //     uri: `/additionalemailrequests/${row._id}`,
          //     props: {
          //       status: 'Issued',
          //       approved_by_finance_date: new Date(),
          //     },
          //   });
          // },
        },
        tab === 'Requested' && {
          title: 'Reject Additional Email',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('reject-additional-email-request', {
              row,
              rejected_by: 'IT',
            });
          },
        },
        (tab === 'Overdue' || tab === 'Approved') && {
          title: 'Extend',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('extend-email-request', {
              row,
            });
          },
        },
        (tab === 'Overdue' || tab === 'Approved') && {
          title: 'Update Remarks',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('update-email-remark', {
              row,
            });
          },
        },
        (tab === 'StopTxn' || tab === 'Approved') && {
          title: 'Mark Email Inactive',
          confirm: {
            title: 'Mark Inactive',
            message: 'Are you sure you want to mark this email as inactive?',
            confirmText: 'Mark Inactive',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/additionalemailrequests/${row._id}`,
              props: {
                status: 'Inactive',
                inactive_date: new Date(),
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/additionalemailrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
