import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

export const SalaryComponentSetupTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salarycomponents`}
      renderHeader={() => (
        <TableHeader
          title="Salary Component Setup"
          actions={[
            <AddButton
              title="Add Salary Component"
              view="add-salary-component-setup"
              params={params}
            />,
          ]}
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component-setup`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          minWidth: 200,
          type: 'text',
        },
      ]}
    />
  );
};

export const AttendanceSalaryComponentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  params.component_calculation_base_id = {
    _id: '53d6189979df44bac9b42a7c',
    name: 'Attendance Based',
  };
  let addOnFilter = {
    type: 'e',
    status: 'a',
    component_calculation_base_id: {_id: '53d6189979df44bac9b42a7c'},
  };
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salaryComponent/detail`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component`, {
          row,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Attendance"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AddButton
                title="Add Salary Component"
                view="add-salary-component"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'Earning Type',
          field: 'component_type_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Calculation Base',
          field: 'component_calculation_base_id.name',
          width: 300,
        },
      ]}
    />
  );
};

export const PerformanceSalaryComponentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  params.component_calculation_base_id = {
    _id: '53d6189b79df44bac9b42a82',
    name: 'Performance Based',
  };
  let addOnFilter = {
    type: 'e',
    status: 'a',
    component_calculation_base_id: '53d6189b79df44bac9b42a82',
  };
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salaryComponent/detail`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component`, {
          row,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Performance"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AddButton
                title="Add Salary Component"
                view="add-salary-component"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'Earning Type',
          field: 'component_type_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Calculation Base',
          field: 'component_calculation_base_id.name',
          width: 300,
        },
      ]}
    />
  );
};

export const FixedSalaryComponentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  params.component_calculation_base_id = {
    _id: '53d6189d79df44bac9b42a8c',
    name: 'Fixed',
  };
  let addOnFilter = {
    type: 'e',
    status: 'a',
    component_calculation_base_id: '53d6189d79df44bac9b42a8c',
  };
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salaryComponent/detail`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="name"
      renderHeader={() => {
        return (
          <TableHeader
            title="Fixed"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AddButton
                title="Add Salary Component"
                view="add-salary-component"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'Earning Type',
          field: 'component_type_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Calculation Base',
          field: 'component_calculation_base_id.name',
          width: 300,
        },
      ]}
    />
  );
};

export const DeductionSalaryComponentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  params.type = 'deduction_type';
  let addOnFilter = {type: 'd', status: 'a'};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salaryComponent/detail`}
      addOnFilter={{...addOnFilter, ...filterValues.filter}}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component`, {
          row,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Deduction"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    field: 'deductable_account_id',
                    label: 'Deductable Account',
                    api: '/accounts',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    type: 'autoComplete',
                    field: 'component_calculation_base_id',
                    label: 'Calculation Base',
                    api: '/componentcalculationbasetypes',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                ]}
              />,
              <AddButton
                title="Add Salary Component"
                view="add-salary-component"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'Deductable Account',
          field: 'deductable_account_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Earning Type',
          field: 'component_type_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Calculation Base',
          field: 'component_calculation_base_id.name',
          type: 'text',
          width: 300,
        },
      ]}
    />
  );
};

export const AllSalaryComponentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  return (
    <Table
      eventSourceId={['salaryComponent']}
      api={`/salaryComponent/detail`}
      addOnFilter={{...filterValues.filter}}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-component`, {
          row,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="All"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    field: 'deductable_account_id',
                    label: 'Deductable Account',
                    api: '/accounts',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    type: 'autoComplete',
                    field: 'component_type_id',
                    label: 'Earning Type',
                    api: '/componenttypes',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    type: 'autoComplete',
                    field: 'payment_type_id',
                    label: 'Payment Type',
                    api: '/paymenttypes',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    type: 'autoComplete',
                    field: 'component_calculation_base_id',
                    label: 'Calculation Base',
                    api: '/componentcalculationbasetypes',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                ]}
              />,
              <AddButton
                title="Add Salary Component"
                view="add-salary-component"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'Deductable Account',
          field: 'deductable_account_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Earning Type',
          field: 'component_type_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Payment Type',
          field: 'payment_type_id.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Calculation Base',
          field: 'component_calculation_base_id.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Is Taxable',
          field: 'is_taxable',
          type: 'boolean',
          width: 200,
        },
      ]}
    />
  );
};
