import React from 'react';
import {TabView} from '../../../components/tab';
import {
  ActiveCandidate,
  MyCandidateClosedFeedbacks,
  MyCandidateFeedbacks,
} from './CandidateTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter} from '../../../components/filter/Filters';

export const MyCandidateFeedbackTabs = props => {
  const {user} = useAppStateContext();
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter();
  const addOnFilter = {...filterValues?.filter};
  const candidateFeedbackTabs = {
    open: {
      label: 'Pending',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateFeedbacks
          api="/candidates"
          filter={{
            $or: [
              {
                'first_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'first_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'second_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'second_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'third_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'third_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'fourth_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'fourth_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'hr_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'hr_round_interview_feedback.interviewer': user?.employee?._id,
              },
            ],
          }}
          addOnFilter={addOnFilter}
        />
      ),
      api: '/candidates',
      filter: {
        $or: [
          {
            'first_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'first_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'second_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'second_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'third_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'third_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'fourth_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'fourth_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'hr_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'hr_round_interview_feedback.interviewer': user?.employee?._id,
          },
        ],
      },
      addOnFilter,
    },
    closed: {
      label: 'Closed',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateClosedFeedbacks
          api="/candidates"
          filter={{
            $or: [
              {
                'first_round_interview_feedback.status': 'Completed',
                'first_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'second_round_interview_feedback.status': 'Completed',
                'second_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'third_round_interview_feedback.status': 'Completed',
                'third_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'fourth_round_interview_feedback.status': 'Completed',
                'fourth_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'hr_round_interview_feedback.status': 'Completed',
                'hr_round_interview_feedback.interviewer': user?.employee?._id,
              },
            ],
          }}
          addOnFilter={addOnFilter}
        />
      ),
      api: '/candidates',
      filter: {
        $or: [
          {
            'first_round_interview_feedback.status': 'Completed',
            'first_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'second_round_interview_feedback.status': 'Completed',
            'second_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'third_round_interview_feedback.status': 'Completed',
            'third_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'fourth_round_interview_feedback.status': 'Completed',
            'fourth_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'hr_round_interview_feedback.status': 'Completed',
            'hr_round_interview_feedback.interviewer': user?.employee?._id,
          },
        ],
      },
      addOnFilter,
    },
  };
  return (
    <TabView
      {...props}
      tabs={candidateFeedbackTabs}
      isLinkTab={false}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Job Opening',
              field: 'job_opening',
              api: '/jobOpenings',
              type: 'autoComplete',
              suggestionField: 'position_title.name',
              getDisplayValue: ({value}) => value?.position_title?.name,
              placeholder: 'Position',
              fields: {
                position_title: {
                  name: 1,
                },
              },
            },
            {
              label: 'Recruiter',
              field: 'job_opening.hiring_manager',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              type: 'autoComplete',
            },
          ]}
        />,
      ]}
    />
  );
};

export const AllCandidateFeedbackTabs = props => {
  const {user} = useAppStateContext();
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter();
  const addOnFilter = {...filterValues?.filter};
  const candidateFeedbackTabs = {
    open: {
      label: 'Pending',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateFeedbacks
          api="/candidates"
          filter={{
            $or: [
              {
                'first_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
              },
              {
                'second_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
              },
              {
                'third_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
              },
              {
                'fourth_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
              },
              {
                'hr_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
              },
            ],
          }}
          addOnFilter={addOnFilter}
          fromOrgAll={true}
        />
      ),
      api: '/candidates',
      filter: {
        $or: [
          {
            'first_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
          },
          {
            'second_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
          },
          {
            'third_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
          },
          {
            'fourth_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
          },
          {
            'hr_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
          },
        ],
      },
      addOnFilter,
    },
    closed: {
      label: 'Closed',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateClosedFeedbacks
          addOnFilter={addOnFilter}
          api="/candidates"
          filter={{
            $or: [
              {
                'first_round_interview_feedback.status': 'Completed',
              },
              {
                'second_round_interview_feedback.status': 'Completed',
              },
              {
                'third_round_interview_feedback.status': 'Completed',
              },
              {
                'fourth_round_interview_feedback.status': 'Completed',
              },
              {
                'hr_round_interview_feedback.status': 'Completed',
              },
            ],
          }}
        />
      ),
      api: '/candidates',
      filter: {
        $or: [
          {
            'first_round_interview_feedback.status': 'Completed',
          },
          {
            'second_round_interview_feedback.status': 'Completed',
          },
          {
            'third_round_interview_feedback.status': 'Completed',
          },
          {
            'fourth_round_interview_feedback.status': 'Completed',
          },
          {
            'hr_round_interview_feedback.status': 'Completed',
          },
        ],
      },
      addOnFilter,
    },
  };
  return (
    <TabView
      {...props}
      tabs={candidateFeedbackTabs}
      isLinkTab={false}
      // params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Job Opening',
              field: 'job_opening',
              api: '/jobOpenings',
              type: 'autoComplete',
              suggestionField: 'position_title.name',
              getDisplayValue: ({value}) => value?.position_title?.name,
              placeholder: 'Position',
              fields: {
                position_title: {
                  name: 1,
                },
              },
            },
            {
              label: 'Recruiter',
              field: 'job_opening.hiring_manager',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              type: 'autoComplete',
            },
          ]}
        />,
      ]}
    />
  );
};
