import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ResignReasons = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/resignreasons',
    eventSourceId: 'resignreasons',
  });
  return (
    <Form
      api={`/resignreasons/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Reason',
          type: 'text',
          field: 'reason',
        },
      ]}
      {...props}
    />
  );
};

export const AddResignReasons = props => {
  return <ResignReasons header="Add Resign Reason" {...props} />;
};

export const EditResignReasons = props => {
  return <ResignReasons mode="edit" header={'Update Resign Reason'} {...props} />;
};
