import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

export const DomainTable = (props) => {
  const {route: {params}} = props;
  const {row} = params || {};
  const website = row?._id;
  const navigation = useNavigation();

  return (
    <Table
      eventSourceId={["domain"]}
      api={`/domains`}
      fields={{
        domain: 1,
        is_domain: 1,
        parent: {
          domain: 1,
        },
        color: 1,
        index: 1,
        is_published: 1,
      }}
      filter={{
        website,
      }}
      sort={{index: 1}}
      renderHeader={() => (
        <TableHeader
          title="Domain"
          actions={[
            <AddButton
              title="Add Domain"
              view="add-domain"
              params={{
                website,
              }}
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-domain', { row, website });
      }}
      columns={[
        {
          type: 'text',
          field: 'domain',
          header: 'Domain',
        },
        {
          type: 'text',
          field: 'parent.domain',
          header: 'Parent',
        },
        {
          type: 'boolean',
          field: 'is_domain',
          header: 'Domain Module',
          align: 'center',
          width: 200,
        },
        {
          type: 'boolean',
          field: 'is_published',
          header: 'Publish',
          width: 100,
          align: 'center',
        },
        {
          type: 'text',
          field: 'color',
          header: 'Color',
          width: 120,
          align: 'center',
        },
        {
          type: 'number',
          field: 'index',
          header: 'Index'
        }
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-domain', { row, website });
          },
        },
      ]}
    />
  );
};
