import {
  // MenuItems as pmtMenuItems,
  // Screens as pmtScreens,
  ScreenMapping as pmtScreenMapping,
  addOnMenus as pmtAddOnMenus,
  addOnScreens as pmtAddOnScreens,
} from './pmt/ProjectManagementNavigation';
import {ScreenMapping as crmScreenMapping} from './customer/CustomerNavigation';
import {ScreenMapping as userScreenMapping} from './pmt/UserManagementNavigation';
import {
  MenuItems as employeeMenuItems,
  Screens as employeeScreens,
  ScreenMapping as employeeScreenMapping,
} from './employee/EmployeeManagementNavigation';
import {
  MenuItems as revenueMenuItems,
  Screens as revenueScreens,
  ScreenMapping as revenueScreenMapping,
} from './order/RevenueNavigator';
import {
  MenuItems as expenseMenuItems,
  Screens as expenseScreens,
  ScreenMapping as expenseScreenMapping,
} from './vendor/ExpenseNavigator';
import {
  MenuItems as attendanceMenuItems,
  Screens as attendanceScreens,
  ScreenMapping as attendanceScreenMapping,
} from './employee/AttendenceManagementNavigation';
import {
  MenuItems as payrollMenuItems,
  Screens as payrollScreens,
  ScreenMapping as payrollManagementScreenMapping,
} from './employee/PayrollManagementNavigation';
import {
  MenuItems as asssetMenuItems,
  Screens as assetScreens,
  ScreenMapping as assetScreensMapping,
} from './Expense/AssetManagementNavigation';
import {
  MenuItems as ticketMenuItems,
  Screens as ticketScreens,
} from './tickets/TicketManagementNavigation';
import {ScreenMapping as financeScreenMapping} from './order/views/FinanceManagement';
import {ScreenMapping as organizationScreenMapping} from './common/OrganizationManagement';
import {
  ScreenMapping as taskScreenMapping,
  addOnMenus as taskAddOnMenus,
  addOnScreens as taskAddOnScreens,
} from './common/TaskManagementNavigation';
import {ScreenMapping as helpScreenMapping} from './common/HelpManagementNavigation';
import {ScreenMapping as recruitmentScreenMapping} from './recruitment/RecruitmentManagement';
import {ScreenMapping as skillScreenMapping} from './skills/SkillManagementNavigation';
import {ScreenMapping as legalScreenMapping} from './common/LegalManagementNavigation';

// Akshat Garg - 2/08/24 - global screens
const screenMappingArr = [
  ...crmScreenMapping,
  ...userScreenMapping,
  ...organizationScreenMapping,
  ...attendanceScreenMapping,
  ...recruitmentScreenMapping,
  ...employeeScreenMapping,
  ...financeScreenMapping,
  ...skillScreenMapping,
  ...assetScreensMapping,
  ...payrollManagementScreenMapping,
  ...revenueScreenMapping,
  ...expenseScreenMapping,
  ...taskScreenMapping,
  ...pmtScreenMapping,
  ...helpScreenMapping,
];

const screenMap = {};
for (const screen of screenMappingArr) {
  const id = screen.id;
  if (screenMap[id]) {
    throw new Error(`Duplicate key found: ${id}`);
  }
  screenMap[id] = screen;
}

export const globalScreenMapping = screenMap;

export const moduleMenus = {
  pmt: {
    addOnMenus: pmtAddOnMenus,
    addOnScreen: pmtAddOnScreens,
    // menus: pmtMenuItems,
    // screens: pmtScreens,
  },
  user: {
    mapping: userScreenMapping,
  },
  crm: {
    mapping: crmScreenMapping,
  },
  revenuemanagement: {
    mapping: revenueScreenMapping,
    // menus: revenueMenuItems,
    // screens: revenueScreens,
  },
  expensemanagement: {
    mapping: expenseScreenMapping,
    // menus: expenseMenuItems,
    // screens: expenseScreens,
  },
  hrm: {
    // menus: employeeMenuItems,
    // screens: employeeScreens,
  },
  attendancemanagement: {
    // menus: attendanceMenuItems,
    // screens: attendanceScreens,
  },
  payrollmanagement: {
    mapping: payrollManagementScreenMapping,
    // menus: payrollMenuItems,
    // screens: payrollScreens,
  },
  asset: {
    mapping: assetScreensMapping,
    // menus: asssetMenuItems,
    // screens: assetScreens,
  },
  task: {
    addOnMenus: taskAddOnMenus,
    addOnScreen: taskAddOnScreens,
  },
  ticket: {
    menus: ticketMenuItems,
    screens: ticketScreens,
  },
  finance: {
    mapping: financeScreenMapping,
  },
  recruitment: {
    // menus: recruitmentMenuItems,
    // screens: recruitmentScreens,
  },
  skill: {
    mapping: skillScreenMapping,
    // menus: skillMenuItems,
    // screens: skillScreens,
  },
  organization: {
    mapping: organizationScreenMapping,
  },
  helpmanagement: {
    mapping: helpScreenMapping,
  },
  legalmanagement: {
    mapping: legalScreenMapping,
  },
};
