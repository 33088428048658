import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {Durations} from './components/Durations';
import {Text, View} from '@unthinkable/react-core-components';

const renderStatus = ({row = {}, styles = {}, field}) => {
  let value = row[field];
  let updateColor = {color: '#34C759'};
  let {rowText = {}} = styles;
  if (value == 'Unapproved') {
    updateColor['color'] = '#FF5247';
  }
  return (
    <View
      style={{
        padding: 4,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{value || '-'}</Text>
    </View>
  );
};

const assignMileStoneProject = ({navigation, fixedOrder}) => ({
  title: 'Assign Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate('milestone-assign-project', {
      row,
      fixedOrder,
    });
  },
  visible: ({row}) => {
    return row?.status == 'Approved';
  },
});

const assignResource = ({navigation, params, filterParams}) => ({
  title: 'Allocate Resource',
  onPress: props => {
    const {row} = props;
    navigation.navigate('milestone-allocated-resource', {
      row,
      filterParams,
    });
  },
  visible: ({row}) => {
    return row?.status == 'Approved';
  },
});

const approveStatus = ({invoke}) => ({
  title: 'Approve Status',
  confirm: {
    title: 'Approve Status',
    message: 'Are you sure you want to Aprove?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/approveOrdermilestones/${row?._id}`,
      props: {
        ...row,
      },
    });
  },
  visible: ({row}) => {
    return row?.status == 'Unapproved';
  },
});

const unApproveStatus = ({invoke}) => ({
  title: 'Unapprove Status',
  confirm: {
    title: 'Unapprove Status',
    message: 'Are you sure you want to Unapprove?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/ordermilestones/${row?._id}`,
      props: {
        status: 'Unapproved',
      },
    });
  },
  visible: ({row}) => {
    return row?.status == 'Approved';
  },
});

const navigateToAssignedResources = ({navigation, params}) => ({
  title: 'Requested Resources',
  onPress: props => {
    const {row} = props;
    navigation.navigate('fixed-order-assigned-resources-for-rm', {
      milestone: row,
    });
  },
});

const navigateToAssignedResourcesForMileStone = ({navigation, params}) => ({
  title: 'Allocated Resources',
  onPress: props => {
    const {row} = props;
    navigation.navigate('resource-allocations-list', {
      // ...params,
      mileStoneFilter: {
        order: row?.order?._id,
        milestone_id: row?._id,
      },
    });
  },
});

const MilestoneTable = props => {
  let {filterParams = {}, navigation = {}, route = {}} = props || {};
  const invoke = useInvoke({method: 'put', eventSourceId: 'ordermilestones'});
  let {params = {}} = route || {};
  const {row = {}} = params;

  return (
    <Table
      api={`/order-mileStones/${row?._id}`}
      eventSourceId="ordermilestones"
      filter={filterParams}
      columns={[
        // {
        //   field: 'order_milestone_no',
        //   header: 'Milestone No',
        //   width: 200,
        // },
        {
          field: 'order_milestone_no',
          header: 'Milestone No.',
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              fromDate: row?.start_date,
              toDate: row?.end_date,
            });
          },
        },
        {
          field: 'project_resource_working_data.approval_status',
          header: 'Approval Status',
        },
        {
          field: 'est_man_hour',
          header: 'Hrs',
          type: 'number',
          width: 200,
        },
        // {
        //   field: 'amount',
        //   header: 'Amount',
        //   type: 'number',
        //   width: 200,
        // },
        // {
        //   field: 'base_amount',
        //   header: 'Base Amount',
        //   type: 'number',
        //   width: 200,
        // },
        {
          header: 'Status',
          align: 'center',
          render: ({row, styles}) =>
            renderStatus({row, styles, field: 'status'}),
          width: 200,
        },
        {
          header: 'Updated End Date',
          field: 'update_end_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
        },
      ]}
      moreActions={() => [
        assignMileStoneProject({navigation, fixedOrder: params?.row}),
        !params?.row?.fromFixedOrderForRm &&
          assignResource({navigation, params, filterParams}),
        params?.row?.fromFixedOrderForRm &&
          navigateToAssignedResources({navigation, params}),
        approveStatus({invoke}),
        !params?.row?.fromFixedOrderForRm && unApproveStatus({invoke}),
        navigateToAssignedResourcesForMileStone({navigation, params}),
      ]}
    />
  );
};

export default MilestoneTable;
