import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';

export const CreditNoteTable = props => {
  const {navigation, addOnFilter, tab, approverView, accountsView} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['creditNoteAm'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    api: '/creditNote/am',
    addOnFilter: addOnFilter,
  };
  return (
    <Table
      eventSourceId={['creditNoteAm']}
      api={['/creditNote/am']}
      selection={{
        actions: [
          {
            title: 'Download',
            visible: () => {
              if (tab === 'Approved' && accountsView) {
                return true;
              }
            },
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      Date: 'date',
                      Amount: 'credit_note_amount',
                      Currency: 'currency.currency',
                      Reason: 'reason',
                      'Requested By': 'requested_by.name',
                      'Approved By': 'approved_by.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      onRowPress={props => {
        const {row} = props;
        if (tab === 'Draft') {
          navigation.navigate(`edit-credit-note-am`, {
            row,
          });
        } else {
          navigation.navigate(`edit-credit-note-am`, {
            row,
            readOnly: true,
          });
        }
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 150,
          colorField: randomColor,
        },
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 200,
          align: 'center',
        },
        {
          header: 'Invoice',
          field: 'invoice_against.invoice_number',
          type: 'text',
          width: 200,
        },
        {
          header: 'Amount',
          field: 'credit_note_amount',
          type: 'currency',
          width: 100,
          align: 'center',
        },
        {
          header: 'Currency',
          field: 'currency.currency',
          type: 'text',
          width: 100,
          align: 'center',
        },
        {
          header: 'Reason',
          field: 'reason',
          width: 250,
          align: 'center',
        },
        {
          header: 'Credit Note',
          field: 'invoice.invoice_number',
          type: 'text',
          width: 200,
          visible: () => {
            if (tab === 'Billed') {
              return true;
            }
          },
        },
        {
          header: 'Requested By',
          field: 'requested_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (approverView || accountsView) return true;
          },
        },
        {
          header: 'Approved By',
          field: 'approved_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
        {
          header: 'Approved On',
          field: 'approved_on',
          type: 'date',
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
        {
          header: 'Rejected By',
          field: 'approved_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (tab === 'Rejected') return true;
          },
        },
        {
          header: 'Rejected On',
          field: 'approved_on',
          type: 'date',
          width: 150,
          visible: () => {
            if (tab === 'Rejected') return true;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`edit-credit-note-am`, {
              row,
            });
          },
          visible: () => {
            if (tab === 'Approved' && accountsView) {
              return true;
            }
          },
        },
        {
          title: 'Send For Approval',
          confirm: {
            title: 'Send For Approval',
            message: 'Are you sure you want to send this request for approval?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/creditnoteams/${row._id}`,
              props: {
                credit_note_status: 'Pending',
              },
            });
          },
          visible: () => {
            if (tab === 'Draft') return true;
          },
        },
        {
          title: 'Approve',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to Approve this request?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/creditnoteams/${row._id}`,
              props: {
                credit_note_status: 'Approved',
                approved_by: user._id,
                approved_on: getZeroTimeDate(new Date()),
              },
            });
          },
          visible: () => {
            if (tab === 'Pending' && approverView) return true;
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('reject-credit-note-reason', {row});
          },
          visible: () => {
            if (tab === 'Pending' && approverView) return true;
          },
        },
        {
          title: 'Mark Billed',
          onPress: ({row}) => {
            navigation.navigate('mark-billed-credit-note', {row});
          },
          visible: () => {
            if (tab === 'Approved' && accountsView) return true;
          },
        },
        {
          title: 'Create Invoice',
          onPress: ({row}) => {
            const {credit_note_amount, customer, currency, reason} = row;
            const invoiceUpdates = {
              invoice_type: 'c',
              customer,
              credit_note_request: row?._id,
              currency,
              invoice_details: [
                {
                  amount: credit_note_amount * -1,
                  description: reason,
                },
              ],
            };
            navigation.navigate('create-revenue-invoice', {
              row: {
                ...invoiceUpdates,
              },
            });
          },
          visible: () => {
            if (tab === 'Approved' && accountsView) {
              return true;
            }
          },
        },
      ]}
    />
  );
};
