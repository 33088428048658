import React, {useEffect, useState} from 'react';
import NestedTable from '../../../../components/form/NestedTable';
import {Form} from '../../../../components/form/Form';
import {HEADER_CONTENT_TYPES} from '../ApiConfigurationForm';
import {
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {SubmitButton} from '@unthinkable/react-form';
import {Button} from '../../../../components/button/Button';
import {useInvoke} from '../../../../controllers/useInvoke';
import {
  Col,
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {Asterisk} from '@unthinkable/react-input';
import {OutlineButton} from '../../../../components/button/OutlineButton';
import {TextAreaRenderer} from '../../../../components/form-editors/Editors';
import {TextInput} from '@unthinkable/react-text-input';
import {CopyLink} from '../../../../components/copyLink/CopyLink';
import {IconButton} from '../../../../components/button/IconButton';
import {TableTextInputStyles} from '../../../../components/form-editors/text/theme';

const DetailColumn = ({data}) => {
  const {fonts, colors} = useTheme();
  data = data?.filter(doc => doc.desc !== undefined);
  return (
    <Col gap={8}>
      {data?.map(({inline, required, title, ...item}) =>
        inline ? (
          <Row gap={4} style={{alignItems: 'center'}}>
            <Text
              style={{color: colors.NEUTRAL_MEDIUM, ...fonts.CAPTION_LARGE}}>
              {title} :
            </Text>

            {item.type === 'code' ? (
              <pre>
                <code
                  title={item?.desc}
                  style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                  {item?.desc}
                </code>
              </pre>
            ) : (
              <Text
                title={item?.desc}
                style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                {item?.desc}
              </Text>
            )}
          </Row>
        ) : (
          <Col>
            <Text
              style={{color: colors.NEUTRAL_MEDIUM, ...fonts.CAPTION_LARGE}}>
              {title} :
            </Text>

            {item.type === 'code' ? (
              <pre>
                <code
                  title={item?.desc}
                  style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                  {item?.desc}
                </code>
              </pre>
            ) : (
              <Text
                title={item?.desc}
                style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                {item?.desc}
              </Text>
            )}
          </Col>
        ),
      )}
    </Col>
  );
};

const ResponseRender = ({row}) => {
  const {resp_type, response, status_desc} = row;

  return (
    <DetailColumn
      data={[
        {title: 'Content Type', desc: resp_type, inline: true},
        {title: 'Description', desc: status_desc},
        {title: 'Example Response', desc: response, type: 'code'},
      ]}
    />
  );
};

const RequestRender = ({row}) => {
  let {request_body_format, request_body} = row;

  if (typeof request_body === 'object') {
    request_body = JSON.stringify(request_body);
  }

  return (
    <DetailColumn
      data={[
        {title: 'Body format', desc: request_body_format},
        {title: 'Body', desc: request_body, type: 'code'},
      ]}
    />
  );
};

const ParameterRender = ({row}) => {
  const {type, parameter_type, parameters_desc} = row;

  return (
    <DetailColumn
      data={[
        {title: 'In', desc: parameter_type, inline: true},
        {title: 'Format', desc: type, inline: true},
        {title: 'Description', desc: parameters_desc},
      ]}
    />
  );
};

const NameRender = ({row}) => {
  const {name, required} = row;
  const {fonts, colors} = useTheme();

  return (
    <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
      {name}
      {required && <Asterisk>{'*'}</Asterisk>}
    </Text>
  );
};

const apiEndpointFields = ({params, navigation}) => {
  const {project} = params;
  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Name',
      type: 'text',
      field: 'controller',
      required: true,
    },
    path: {
      label: 'Path',
      type: 'text',
      field: 'uri',
      required: true,
      helperText: 'Note: the parameters should be  like : /user/{userId}',
      onChangeValue: (value, _, {setFieldValue, values}) => {
        const parameters = value.split('/').reduce(
          (acc, doc) => {
            if (doc[0] === '{' && doc[doc.length - 1] === '}') {
              acc.push({
                name: doc.slice(1, doc.length - 1),
                parameter_type: 'path',
                type: 'text',
                required: true,
              });
            }
            return acc;
          },
          values.parameters?.reduce((acc, doc) => {
            if (doc.parameter_type !== 'path') {
              acc.push(doc);
            }
            return acc;
          }, []) || [],
        );
        setFieldValue('parameters', parameters);
      },
      size: 9,
    },
    method: {
      label: 'Method',
      type: 'autoComplete',
      field: 'method',
      options: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
      required: true,
      size: 3,
    },
    version: {
      field: 'api_version',
      label: 'Version',
      type: 'text',
      size: 3,
    },
    reference_entities: {
      label: 'Reference Models',
      field: 'reference_entities',
      type: 'multiAutoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: props => {
        const {searchValue, onChange} = props;
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange && onChange(data),
        });
      },
    },
    dataEntity: ({readOnly} = {}) => ({
      label: 'Model',
      field: 'model_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: props => {
        const {searchValue, onChange} = props;
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange && onChange(data),
        });
      },
      size: 6,
      readOnly,
    }),
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    security: {
      label: 'Authentication',
      field: 'security_type',
      type: 'autoComplete',
      options: ['BasicAuth', 'BearerAuth', 'ApiKeyAuth'],
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'aiTextArea',
      field: 'desc',
      inputProps: {
        minRows: 10,
        maxRows: 20,
      },
    },
    complexity: {
      label: 'Complexity',
      type: 'autoComplete',
      field: 'complexity_id',
      api: '/projectcomplexities',
      suggestionField: 'complexity_type',
      secondarySuggestionField: 'point',
      valueField: 'complexity_type',
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      type: 'richText',
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
    folder_path: {
      field: 'folder_path_id',
      label: 'Folder Path',
      type: 'autoComplete',
      api: `/repositoryFolderPaths`,
      suggestionField: 'path',
      secondarySuggestionField: 'name',
      valueField: 'path',
      filter: {
        type: 'api',
        project_id: project?._id,
      },
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-repository-folder-path`, {
          ...params,
          repositoryType: 'Backend',
          defaultValues: {
            type: 'api',
            project_id: project?._id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      helperText: 'Select path on which file is placed',
    },
    file_name: {
      type: 'autoComplete',
      field: 'file_name',
      label: 'File Name',
      api: `/folderFiles`,
      suggestionField: 'name',
      valueField: 'name',
      filter: ({values}) => {
        return {
          folder_path_id: values?.folder_path_id,
        };
      },
      onCreate: ({searchValue, onChange, form: {values}}) => {
        navigation.navigate(`add-file-name`, {
          ...params,
          defaultValues: {
            folder_path_id: values?.folder_path_id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      visible: ({values}) => values?.folder_path_id,
    },
  };
};

let responseTabs = ({response}) => {
  let tabs = {
    body: {
      label: 'Body',
      layoutFields: [],
    },
    cookies: {
      label: 'Cookies',
      layoutFields: [],
    },
    headers: {
      label: 'Headers',
      layoutFields: [],
    },
  };

  let {data, receivedHeaders, cookies} = response || {};

  if (data) {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (Err) {
        console.log(Err.message);
      }
    } else {
      data = JSON.stringify(data, null, 2);
    }
  }

  if (receivedHeaders) {
    if (typeof receivedHeaders === 'string') {
      try {
        receivedHeaders = JSON.parse(receivedHeaders);
      } catch (Err) {
        console.log(Err.message);
      }
    } else {
      receivedHeaders = JSON.stringify(receivedHeaders, null, 2);
    }
  }

  if (cookies) {
    if (typeof cookies === 'string') {
      try {
        cookies = JSON.parse(cookies);
      } catch (Err) {
        console.log(Err.message);
      }
    } else {
      cookies = JSON.stringify(cookies, null, 2);
    }
  }

  if (data) {
    tabs.body.layoutFields = [
      {
        render: () => {
          return (
            <ScrollView>
              <TextAreaRenderer value={data} />
            </ScrollView>
          );
        },
      },
    ];
    tabs.body.actions = [
      <CopyLink
        title={'Copy Code'}
        message={'Code has been copied to clipboard.'}
        link={data}
      />,
    ];
  }
  if (receivedHeaders) {
    tabs.headers.layoutFields = [
      {
        render: () => {
          return (
            <ScrollView>
              <TextAreaRenderer value={receivedHeaders} />
            </ScrollView>
          );
        },
      },
    ];
  }
  if (cookies) {
    tabs.cookies.layoutFields = [
      {
        render: () => {
          return (
            <ScrollView>
              <TextAreaRenderer value={cookies} />
            </ScrollView>
          );
        },
      },
    ];
  }

  return tabs;
};

let ParamsValue = ({paramValues = [], setParamValues}) => {
  let {icons, colors, fonts} = useTheme();
  const styles = useStyles(TableTextInputStyles);

  const onChangeFieldValue = ({index, field, value}) => {
    setParamValues(prev => {
      const newParams = [...prev];
      newParams[index].field = field;
      newParams[index].value = value;
      return index === paramValues.length - 1
        ? [...newParams, {field: '', value: ''}]
        : newParams;
    });
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        maxHeight: 160,
        overflow: 'hidden',
      }}>
      <Row style={{alignItems: 'center', height: 41}}>
        <View style={{flex: 1, marginLeft: 15}}>
          <Text style={{...fonts.BODY1, color: colors.NEUTRAL_HIGH}}>Key</Text>
        </View>
        <View style={{flex: 1, marginLeft: 15}}>
          <Text style={{...fonts.BODY1, color: colors.NEUTRAL_HIGH}}>
            Value
          </Text>
        </View>
        <View
          style={{
            width: 33,
          }}
        />
      </Row>
      <ScrollView style={{flex: 1}}>
        {paramValues.map((param, index) => (
          <Row
            key={index}
            style={{
              alignItems: 'center',
              borderTopWidth: 1,
              borderColor: colors.OUTLINE,
            }}>
            <View style={{flex: 1, padding: 3}}>
              <TextInput
                readOnly={!!param?.autoGenerated}
                styles={styles}
                onChangeValue={value =>
                  onChangeFieldValue({index, field: value, value: param.value})
                }
                value={param.field}
                placeholder="Field"
              />
            </View>
            <View style={{flex: 1, padding: 3}}>
              <TextInput
                isFloatingLabel={true}
                styles={styles}
                required={!!param?.required}
                onChangeValue={value =>
                  onChangeFieldValue({index, value, field: param.field})
                }
                value={param.value}
                placeholder="Value"
              />
            </View>
            <TouchableOpacity
              style={{
                width: 33,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() =>
                setParamValues(prev => prev.filter((_, i) => i !== index))
              }>
              {index === paramValues?.length - 1 || param?.autoGenerated ? (
                void 0
              ) : (
                <Image source={icons?.TableDelete} />
              )}
            </TouchableOpacity>
          </Row>
        ))}
      </ScrollView>
    </View>
  );
};

export const ApiExecuteForm = ({route: {params}}) => {
  let {row} = params || {};

  let {parameters = [], requests = [], uri, method} = row;

  let {colors, fonts} = useTheme();
  const [response, updateResponse] = useState();
  let [loading, setLoading] = useState(false);

  let [pathValues, setPathValues] = useState([]);
  let [queryValues, setQueryValues] = useState([]);
  let [headerValues, setHeaderValues] = useState([]);
  let [cookieValues, setCookieValues] = useState([]);
  let request = requests[0];

  let requestTabs = {
    path: {
      label: 'Path Params',
      layoutFields: [
        {
          field: 'path',
          render: () => {
            return (
              <ParamsValue
                paramValues={pathValues}
                setParamValues={setPathValues}
              />
            );
          },
        },
      ],
    },
    query: {
      label: 'Query Params',
      layoutFields: [
        {
          field: 'query',
          render: () => {
            return (
              <ParamsValue
                paramValues={queryValues}
                setParamValues={setQueryValues}
              />
            );
          },
        },
      ],
    },
    header: {
      label: 'Headers',
      layoutFields: [
        {
          field: 'header',
          render: () => {
            return (
              <ParamsValue
                paramValues={headerValues}
                setParamValues={setHeaderValues}
              />
            );
          },
        },
      ],
    },
    cookie: {
      label: 'Cookies',
      layoutFields: [
        {
          field: 'cookie',
          render: () => {
            return (
              <ParamsValue
                paramValues={cookieValues}
                setParamValues={setCookieValues}
              />
            );
          },
        },
      ],
    },
    body: {
      label: 'Body',
      layoutFields: [
        {
          field: 'request_body',
          type:
            request?.request_body_type === 'application/json'
              ? 'json'
              : 'textArea',
          inputProps: {
            minRows: 5,
            maxRows: 7,
          },
        },
      ],
    },
  };

  let selectedReqTab = 'query';

  useEffect(() => {
    let pathValues = [];
    let queryValues = [];
    let headerValues = [];
    let cookieValues = [];

    parameters?.forEach(doc => {
      let {parameter_type, required = false} = doc;
      if (required === 'true' || required === 'True') {
        required = true;
      }
      if (parameter_type === 'path') {
        pathValues.push({
          field: doc?.name,
          value: '',
          autoGenerated: true,
          required,
        });
      }
      if (parameter_type === 'query') {
        queryValues.push({
          field: doc?.name,
          value: '',
          autoGenerated: true,
          required,
        });
      }
      if (parameter_type === 'header') {
        headerValues.push({
          field: doc?.name,
          value: '',
          autoGenerated: true,
          required,
        });
      }
      if (parameter_type === 'cookie') {
        cookieValues.push({
          field: doc?.name,
          value: '',
          autoGenerated: true,
          required,
        });
      }
    });
    setPathValues([...pathValues, {field: '', value: ''}]);
    setQueryValues([...queryValues, {field: '', value: ''}]);
    setHeaderValues([...headerValues, {field: '', value: ''}]);
    setCookieValues([...cookieValues, {field: '', value: ''}]);
  }, []);

  const invoke = useInvoke({
    method: 'post',
    close: false,
  });

  let defaultValues = requests[0] || {};

  defaultValues = {
    ...defaultValues,
    uri,
    method,
  };

  let responseTab = responseTabs({response});

  return (
    <Form
      header={'Execute API'}
      defaultValues={defaultValues}
      skipFooter
      layoutFields={[
        {
          field: 'method',
          label: 'Method',
          type: 'text',
          readOnly: true,
          size: 1,
        },
        {
          field: 'domain',
          label: 'Domain',
          type: 'text',
          required: true,
          size: 4,
        },
        {field: 'uri', label: 'Path', type: 'text', readOnly: true, size: 4},
        {
          size: 3,
          align: 'right',
          render: (_, {isValid, values, resetForm}) => {
            let disabled = !isValid;
            let updatedPathValues = [];
            let updatedQueryValues = [];
            let updatedHeaderValues = [];
            let updatedCookieValues = [];

            pathValues.forEach(value => {
              let {required, autoGenerated, ...rest} = value || {};
              if (required && !value?.value) {
                disabled = true;
              }
              updatedPathValues.push(rest);
            });
            queryValues.forEach(value => {
              let {required, autoGenerated, ...rest} = value || {};
              if (required && !value?.value) {
                disabled = true;
              }
              updatedQueryValues.push(rest);
            });
            headerValues.forEach(value => {
              let {required, autoGenerated, ...rest} = value || {};
              if (required && !value?.value) {
                disabled = true;
              }
              updatedHeaderValues.push(rest);
            });
            cookieValues.forEach(value => {
              let {required, autoGenerated, ...rest} = value || {};
              if (required && !value?.value) {
                disabled = true;
              }
              updatedCookieValues.push(rest);
            });
            let {domain, uri, method, ...rest} = values;
            return (
              <Row
                style={{justifyContent: 'flex-end', alignItems: 'center'}}
                gap={12}>
                <Button
                  loading={loading}
                  disabled={disabled}
                  text={'Execute'}
                  onPress={async () => {
                    setLoading(true);
                    const data = await invoke({
                      uri: '/executeApi',
                      props: {
                        _api: row,
                        domain,
                        variables: {
                          ...rest,
                          path: updatedPathValues,
                          query: updatedQueryValues,
                          header: updatedHeaderValues,
                          cookie: updatedCookieValues,
                        },
                      },
                    });
                    updateResponse(data);
                    setLoading(false);
                  }}
                />
                {response ? (
                  <OutlineButton
                    text={'Clear'}
                    onPress={() => {
                      resetForm();
                      updateResponse(null);
                    }}
                  />
                ) : (
                  void 0
                )}
              </Row>
            );
          },
        },
        {
          tabs: requestTabs,
          tabProps: {
            height: 200,
            selectedTab: selectedReqTab,
          },
        },
        {
          tabs: responseTab,
          tabProps: {
            height: 300,
            selectedTab: 'body',
            headerComponent: (
              <Text style={{...fonts.HEADING1, color: colors.NEUTRAL_HIGH}}>
                Response
              </Text>
            ),
            actions: [
              response?.statusCode ? (
                <Row style={{alignItems: 'center', gap: 4}}>
                  <Text
                    style={{
                      ...fonts.SIDE_NAVIGATION,
                      color: colors.NEUTRAL_MEDIUM,
                    }}>
                    Status:
                  </Text>
                  <Text
                    style={{
                      ...fonts.SIDE_NAVIGATION,
                      color: response?.is_error
                        ? colors.ERROR_HIGH
                        : colors.SUCCESS_HIGH,
                    }}>
                    {response?.statusCode}
                  </Text>
                </Row>
              ) : (
                void 0
              ),
            ],
          },
        },
      ]}
    />
  );
};

export const ApiEndpointForm = props => {
  const {
    navigation,
    route: {params},
    mode,
  } = props;

  const {row, project_id, module_id} = params;

  const fields = apiEndpointFields({params, navigation});

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        {
          ...fields.name,
          size: 9,
        },
        fields.method,
        fields.path,
        fields.version,
        fields.dataEntity({readOnly: mode}),
        fields.module({readOnly: mode}),
        fields.folder_path,
        fields.file_name,
        {
          collapsedFields: [
            fields.security,
            fields.owner,
            fields.reference_entities,
            fields.description,
            fields.complexity,
          ],
        },
      ],
    },
    request: {
      label: 'Request',
      layoutFields: [
        {
          label: 'Request Body',
          field: 'requests',
          nested: true,
          visible: ({values}) =>
            ['POST', 'PUT', 'PATCH'].includes(values?.method),
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                header={'Add Request'}
                editFormHeader={readOnly ? 'Request Detail' : 'Edit Request'}
                columnVerticalAlignment={'top'}
                modalProps={{
                  size: 'medium',
                }}
                fields={[
                  {
                    label: 'Content Type',
                    field: 'request_body_type',
                    type: 'autoComplete',
                    options: Object.keys(HEADER_CONTENT_TYPES),
                    onChangeValue: (value, _, {setFieldValue}) => {
                      setFieldValue(
                        'request_body_format',
                        HEADER_CONTENT_TYPES[value],
                      );
                      setFieldValue('request_body');
                    },
                    size: 6,
                  },
                  {
                    field: 'request_body_format',
                    label: 'Body format',
                    type: 'text',
                    readOnly: true,
                    size: 6,
                  },
                  {
                    field: 'request_body',
                    label: 'Body',
                    type: 'textArea',
                    inputProps: {
                      minRows: 14,
                      maxRows: 17,
                    },
                  },
                ]}
                columns={[
                  {
                    header: 'Content Type',
                    field: 'request_body_type',
                    type: 'text',
                    width: 150,
                  },
                  {
                    header: 'Detail',
                    render: RequestRender,
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'parameters',
          nested: true,
          label: 'Parameters',
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                header={'Add Parameter'}
                editFormHeader={
                  readOnly ? 'Parameter Detail' : 'Edit Parameter'
                }
                modalProps={{
                  size: 'medium',
                }}
                columnVerticalAlignment={'top'}
                fields={[
                  {
                    label: 'Name',
                    type: 'text',
                    field: 'name',
                    required: true,
                    size: 6,
                  },
                  {
                    label: 'In',
                    field: 'parameter_type',
                    type: 'autoComplete',
                    options: ['path', 'query', 'header', 'cookie'],
                    required: true,
                    onChangeValue: (value, _, {setFieldValue}) => {
                      if (value === 'path') {
                        setFieldValue('type', 'text');
                        setFieldValue('required', true);
                      } else {
                        setFieldValue('type');
                        setFieldValue('required', false);
                      }
                    },
                    size: 6,
                  },
                  {
                    field: 'type',
                    label: 'Format',
                    type: 'autoComplete',
                    options: ['text', 'object'],
                    size: 6,
                  },
                  {
                    field: 'required',
                    type: 'checkbox',
                    label: 'Required',
                    size: 6,
                  },
                  {
                    field: 'parameters_desc',
                    label: 'Description',
                    type: 'textArea',
                    inputProps: {
                      minRows: 2,
                      maxRows: 4,
                    },
                  },
                ]}
                columns={[
                  {
                    label: 'Name',
                    render: NameRender,
                    width: 150,
                  },
                  {
                    header: 'Detail',
                    render: ParameterRender,
                  },
                ]}
              />
            );
          },
        },
      ],
    },
    response: {
      label: 'Responses',
      layoutFields: [
        {
          field: 'responses',
          nested: true,
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                editFormHeader={readOnly ? 'Response Detail' : 'Edit Response'}
                header={'Add Response'}
                columnVerticalAlignment={'top'}
                fields={[
                  {
                    label: 'Code',
                    header: 'Code',
                    type: 'text',
                    field: 'status_code',
                    required: true,
                    size: 6,
                    onChangeValue: (value, _, {setFieldValue}) => {
                      setFieldValue('resp_type');
                    },
                  },
                  {
                    visible: ({values}) => {
                      const {status_code} = values;
                      return status_code?.length;
                    },
                    label: 'Content Type',
                    field: 'resp_type',
                    type: 'autoComplete',
                    options: ({values, _parentValues: {responses}}) => {
                      const headerNotToShow = responses?.reduce(
                        (acc, {status_code, resp_type}) => {
                          if (status_code === values?.status_code) {
                            acc[resp_type] = 1;
                          }
                          return acc;
                        },
                        {},
                      );

                      return Object.keys(HEADER_CONTENT_TYPES)?.filter(
                        key => !headerNotToShow?.[key],
                      );
                    },
                    onChangeValue: (value, _, {setFieldValue}) => {
                      setFieldValue('response');
                    },
                    size: 6,
                  },
                  {
                    label: 'Description',
                    header: 'Description',
                    field: 'status_desc',
                    type: 'text',
                  },

                  {
                    label: 'Response',
                    field: 'response',
                    type: 'textArea',
                    inputProps: {
                      minRows: 12,
                      maxRows: 15,
                    },
                    visible: ({values: {resp_type}}) =>
                      resp_type !== 'application/json',
                  },
                  {
                    label: 'Response',
                    field: 'response',
                    type: 'textArea',
                    inputProps: {
                      minRows: 12,
                      maxRows: 15,
                    },
                    visible: ({values: {resp_type}}) =>
                      resp_type === 'application/json',
                  },
                ]}
                columns={[
                  {
                    header: 'Code',
                    type: 'text',
                    field: 'status_code',
                    width: 60,
                  },
                  {
                    header: 'Detail',
                    render: ResponseRender,
                  },
                ]}
              />
            );
          },
        },
      ],
    },
    remarks: {
      visible: !!mode,
      label: 'Remarks',
      layoutFields: [fields.remarks],
    },
    // code: {
    //   visible: () => row?.folder_path_id && row?.file_name,
    //   label: 'Code',
    //   layoutFields: [
    //     {
    //       field: 'api_code',
    //       placeholder: 'Write your api code here...',
    //       type: 'aiCode',
    //       height: 500,
    //       params: ({model_id, _id: controller_id}) => {
    //         return {
    //           model_id: model_id?._id,
    //           project_id,
    //           module_id,
    //           controller_id,
    //         };
    //       },
    //       entityName: 'projectController',
    //     },
    //   ],
    // },
  };

  return <Form submitAction="Save" tabs={tabs} type="tab" {...props} />;
};

export const AddApiEndpointForm = props => {
  const {
    route: {params},
  } = props;

  const {
    project,
    module,
    feature,
    model,
    searchValue,
    afterSubmit,
    dataEntityFilter,
  } = params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    controller: searchValue,
    request_body_type: 'application/json',
    resp_type: 'application/json',
    request_body_format: HEADER_CONTENT_TYPES['application/json'],
    request_body: `{\n}`,
    response: `{\n}`,
    project_id: project?._id,
    module_id: module,
    model_id: model || dataEntityFilter?.model_id,
    feature_id: feature?._id,
    source: PROJECT_CONTROLLER_TYPE_ID,
  };

  return (
    <ApiEndpointForm
      data={initialValues}
      header="Add API"
      onSubmit={createAsset}
      beforeSubmit={requiredValidation}
      afterSubmit={afterSubmit}
      defaultValues={initialValues}
      {...props}
    />
  );
};

export const ApiEndpointDetailForm = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;

  const {row, feature, readOnly} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <ApiEndpointForm
      key={row?._id}
      isDualMode
      readOnly={readOnly || row?.aiGenerated}
      mode="edit"
      header={{
        title: 'Api Detail',
        secondaryTitle: row?.controller,
        actions: [
          <Button
            text={'Try it out'}
            onPress={() => {
              navigation.navigate('api-execute-form', {
                ...params,
              });
            }}
          />,
        ],
      }}
      onSubmit={updateHistory}
      submitAction="Save"
      data={row}
      beforeSubmit={assetBeforeSubmit({data: row})}
      {...props}
    />
  );
};

export const APIEndpointCodeEditorForm = props => {
  const {
    route: {params},
  } = props;

  const [loading, setLoading] = useState(false);
  const {row, project_id, module_id, readOnly, feature} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
    feature_id: feature?._id,
    row,
  });
  const postInvoke = useInvoke({
    method: 'post',
  });
  return (
    <Form
      data={row}
      mode="edit"
      header={{title: 'Api Code', secondaryTitle: row?.controller}}
      skipDefaultSave
      footer={({styles, handleSubmit, values, onClose}) => {
        return {
          actions: [
            <SubmitButton label="Save" styles={styles.secondaryButton} />,
            <Button
              loading={loading}
              text="Save and Commit"
              onPress={async () => {
                setLoading(true);
                const {message} = await postInvoke({
                  uri: '/commitGitFile',
                  props: {
                    ...params,
                    fileContent: values?.api_code,
                    folder_path_id: values?.folder_path_id?._id,
                    file_name: values?.file_name?.name,
                    feature_id: values?.feature_id?._id,
                  },
                });
                if (message === 'Success') {
                  handleSubmit?.();
                  onClose?.();
                  setLoading(false);
                }
              }}
            />,
          ],
        };
      }}
      readOnly={readOnly || row?.aiGenerated}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      layoutFields={[
        {
          field: 'api_code',
          placeholder: 'Write your api code here...',
          type: 'aiCode',
          height: 280,
          is_committable: false,
          params: ({model_id, _id: controller_id}) => {
            return {
              model_id: model_id?._id,
              project_id,
              module_id,
              controller_id,
            };
          },
          entityName: 'projectController',
        },
      ]}
      {...props}
    />
  );
};
