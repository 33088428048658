import React from 'react';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';

export const UnthinkableSandboxSectionTable = props => {

  const {route: {params}, navigation} = props;
  const {filter, url, type, website} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'section',
  });


  return (
    <Table
      eventSourceId={["section"]}
    api={`/sandboxsections`}
      fields={{
        type: 1,
        heading: 1,
        sub_heading: 1,
        description: 1,
        index: 1,
      }}
      sort={{index: 1}}
      filter={filter}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Add Section"
              view="add-section"
              params={{page:filter?.page, url, type, website}}
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-section', { row, page: filter?.page, url, website});
      }}
      columns={[
        {
          type: 'text',
          field: 'type',
          header: 'Type',
          width: 200,
        },
        {
          type: 'textArea',
          field: 'heading',
          header: 'Heading',
          width: 280,
        },
        {
          type: 'textArea',
          field: 'sub_heading',
          header: 'Sub Heading',
          width: 280,
        },
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
        {
          type: 'number',
          field: 'index',
          header: 'Index',
          width: 120,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-section', { row, page: filter?.page, url, website});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteInvoke({
              uri: `/sandboxsections/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};