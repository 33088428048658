import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

export const DepartmentOutputForm = props => {
  const {departmentOutputId} = props.route.params;

  const {user} = useAppStateContext();
  let {onSubmit} = useFormSubmit({
    uri: '/departmentoutputs',
    eventSourceId: 'DepartmentOutput',
  });
  const {params} = props.route || {};
  return (
    <Form
      api={`/departmentOutputs/${departmentOutputId}`}
      fields={{
        label: 1,
        filter: 1,
        action_view: 1,
        kpi: 1,
        aggregate: 1,
        group: 1,
        hide_in_output: 1,
        creation_date: 1,
        primary: 1,
        sequence: 1,
        department: {name: 1},
        model: {name: 1},
        on_press_url: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Label',
          field: 'label',
          type: 'text',
        },
        {
          label: 'KPI',
          field: 'kpi',
          type: 'text',
        },
        {
          label: 'Filter',
          field: 'filter',
          type: 'text',
        },
        {
          type: 'text',
          field: 'on_press_url',
          label: 'On Press URL',
        },
        {
          label: 'Group',
          field: 'group',
          type: 'text',
          visible: () => {
            if (user.email === 'sushil.nagvan@daffodilsw.com') {
              return true;
            }
          },
        },
        {
          label: 'Aggregate',
          field: 'aggregate',
          type: 'text',
          visible: () => {
            if (user.email === 'sushil.nagvan@daffodilsw.com') {
              return true;
            }
          },
        },

        // {
        //   label: 'Output Type',
        //   field: 'output_type',
        //   type: 'text',
        // },
        {
          label: 'Creation Date',
          field: 'creation_date',
          type: 'text',
        },
        {
          label: 'Primary',
          field: 'primary',
          type: 'switch',
        },
        {
          label: 'Hide in Output',
          field: 'hide_in_output',
          type: 'switch',
        },
        {
          label: 'Sequence',
          field: 'sequence',
          type: 'number',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Model',
          field: 'model',
          type: 'autoComplete',
          api: '/types',
          filter: {
            departments: params?.department?._id,
            is_show: true,
          },
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddDepartmentOutputForm = props => {
  return <DepartmentOutputForm header="Add Department Output" {...props} />;
};

export const UpdateDepartmentOutputForm = props => {
  return (
    <DepartmentOutputForm
      mode="edit"
      header="Update Department Output"
      {...props}
    />
  );
};
