import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const ProductForm = props => {
  const {
    route: {params},
  } = props;
  const {productId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/products',
    eventSourceId: 'Product',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/products/${productId}`}
      fields={{
        name: 1,
        description: 1,
        services: 1,
        differentiator: 1,
        status: 1,
        product_type: 1,
        managers: {name: 1},
        departments: {name: 1},
        members: {name: 1},
        organizations: {
          name: 1,
        },
        outreach_duration: 1,
        message_duration: 1,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
        {
          type: 'text',
          field: 'description',
          label: 'Description',
        },
        {
          type: 'text',
          field: 'services',
          label: 'Services',
        },
        {
          type: 'text',
          field: 'differentiator',
          label: 'Differentiator',
        },
        {
          type: 'text',
          field: 'status',
          label: 'Status',
        },
        {
          label: 'Product Type',
          field: 'product_type',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['technical', 'non-technical'],
        },
        {
          label: 'Department',
          field: 'departments',
          type: 'multiAutoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Managers',
          field: 'managers',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          label: 'Organizations',
          field: 'organizations',
          type: 'multiAutoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Members',
          field: 'members',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          type: 'number',
          field: 'outreach_duration',
          label: 'Outreach Duration',
        },
        {
          type: 'number',
          field: 'message_duration',
          label: 'Message Duration',
        },
      ]}
      {...props}
    />
  );
};

export const AddProductForm = props => {
  return <ProductForm header="Add Product" {...props} />;
};

export const UpdateProductForm = props => {
  return <ProductForm header="Update Product" mode="edit" {...props} />;
};
