import React, {forwardRef} from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  RichTextInput,
  RichTextRenderer as RichTextRender,
  RichText as RichTextComponent,
} from '@unthinkable/react-rich-text-input';
import {useColorMode, useStyles} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import './richText.css';
import {MentionSuggestionItemStyles, RichTextAreaInputStyles} from './theme';
import {View} from '@unthinkable/react-core-components';
import {MentionSuggestionItem} from './MentionSuggestionItem';

export const RichTextAreaInput = forwardRef(({mention, ...props}, ref) => {
  const styles = useStyles(RichTextAreaInputStyles);
  let {upload, resourceUrl} = useAppStateContext();
  const {colorMode} = useColorMode();
  const suggestionStyles = useStyles(MentionSuggestionItemStyles);
  let mentionRef = mention;
  const renderItem = (data, searchTerm) => {
    let mentionComponent = void 0;
    if (mention?.renderItemComponent) {
      if (typeof mention.renderItemComponent === 'function') {
        mentionComponent = mention.renderItemComponent({
          row: data,
          styles: suggestionStyles,
        });
      } else {
        const Component = mention.renderItemComponent;
        mentionComponent = <Component row={data} styles={suggestionStyles} />;
      }
    } else {
      mentionComponent = (
        <MentionSuggestionItem
          secondarySuggestionField={mention?.secondarySuggestionField}
          row={data}
          styles={suggestionStyles}
        />
      );
    }
    return ReactDOMServer.renderToString(mentionComponent);
  };

  if (mentionRef) {
    mentionRef = {
      renderItem,
      ...mentionRef,
    };
    delete mentionRef.renderItemComponent;
    delete mentionRef.secondarySuggestionField;
  }

  return (
    <RichTextInput
      className={colorMode === 'dark' ? 'dark-mode' : 'light-mode'}
      ref={ref}
      renderItem={renderItem}
      resourceUrl={resourceUrl}
      upload={upload}
      labelPosition={'top'}
      {...props}
      mention={mentionRef}
      styles={styles}
      additionalFonts={['Poppins-Regular', 'Poppins-Medium', 'Poppins-SemiBold', 'Poppins-Bold']} 
    />
  );
});

export const RichTextRenderer = props => {
  const {colorMode} = useColorMode();
  return (
    <RichTextRender
      {...props}
      className={colorMode === 'dark' ? 'dark-mode' : 'light-mode'}
    />
  );
};

export const RichText = props => {
  const {colorMode} = useColorMode();
  return (
    <View className={colorMode === 'dark' ? 'dark-mode' : 'light-mode'}>
      <RichTextComponent {...props} />
    </View>
  );
};
