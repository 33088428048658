import { Text, View } from '@unthinkable/react-core-components';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useInvoke } from '../../../controllers/useInvoke';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const NewAppraisalChipsOptions = [
    {
        value: "A+",
        label: "CE",
    },
    {
        value: "A",
        label: "OE",
    },
    {
        value: "B+",
        label: "ME",
    },
    {
        value: "B",
        label: "PE",
    },
    {
        value: "C",
        label: "DE",
    },
];


export const HRAppraisalForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    return (
        <Form
            api={`/hrAppraisalDetail/${row?._id}`}
            submitAction={'Save'}
            mode="edit"
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Employee Code',
                            label: 'Employee Code',
                            field: 'employee.employee_code',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Increment Type',
                            label: 'Increment Type',
                            field: 'employee.increment_type',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "from_date",
                            label: 'Increment From Date',
                            placeholder: 'From Date',
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "to_date",
                            label: 'IncrementTo Date',
                            placeholder: 'To Date',
                            readOnly: true,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    label: 'OVERALL RATING',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6
                        },
                        {
                            placeholder: 'Utilisation Percentage',
                            label: 'Utilisation Percentage',
                            field: 'utilisation',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            render: () => {
                                let {
                                    total_billed_hours = 0,
                                    total_available_hours = 0,
                                } = row || {};
                                let utilisation =
                                    (total_available_hours && total_billed_hours / total_available_hours) ||
                                    0;
                                utilisation *= 100;
                                let Format = value => (value && value.toFixed(2)) || 0;
                                total_billed_hours = Format(total_billed_hours || 0);
                                total_available_hours = Format(total_available_hours || 0);
                                utilisation = Format(utilisation || 0);
                                return (
                                    <>
                                        <Text style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: "#8E8E8E"
                                        }}>
                                            Utilisation
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: "#4B4B4B",
                                            }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'stretch',
                                                    minWidth: 20,
                                                    margin: 2,
                                                }}>
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_billed_hours && Number(total_billed_hours).toFixed(2)}
                                                </Text>
                                                <View style={{ height: 1, background: "#4B4B4B", }} />
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_available_hours}
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                }}>
                                                =
                                            </Text>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                    fontFamily: 'Quicksand-Regular',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                }}>
                                                {utilisation && Number(utilisation).toFixed(2)} %
                                            </Text>
                                        </View>
                                    </>
                                );
                            },
                        },
                        {
                            label: 'Manager Rating',
                            placeholder: 'Manager Rating',
                            field: "pm_overall_rating",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            type: 'radioGroup',
                            size: 6,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'rm_overall_score',
                            placeholder: "RM Feedback",
                            label: 'RM Feedback',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'mentorship_score',
                            placeholder: "Mentorship",
                            label: 'Mentorship',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            label: 'Innovation Score',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                    ],
                },
                {
                    label: 'Employee Self Assessment',
                    fields: [
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                            marginTop: "20px",
                                        }}> 1. Describe your significant accomplishments or contributions during this appraisal period.</Text>
                                );
                            }
                        },
                        {
                            type: 'textArea',
                            field: 'question_1_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 2. Have you successfully performed any new tasks or additional duties outside the scope of your regular responsibilities during the appraisal period? If so, please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_2_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 3. Innovation is one of our core values.Please share an instance where you have delivered an innovative solution at work during this appraisal period.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_3_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 4. Have you been deployed in any new technology or completed any certifications during the appraisal period?If yes,please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_4_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                    ],
                },
                // {
                //     label: 'Project Manager Feedback',
                //     visible: ({ values }) => {
                //         if (values?.department_type != "Non Technical" && values?.is_feedback_process_new) {
                //             return true
                //         }
                //     },
                //     fields: [
                //         {
                //             field: 'reporting_assessment_details',
                //             nested: true,
                //             readOnly: true,
                //             render: props => {
                //                 const {
                //                     form: { values = {} },
                //                 } = props;
                //                 return (
                //                     <NestedTable
                //                         {...props}
                //                         fields={[
                //                             {
                //                                 type: 'text',
                //                                 label: 'Quarter',
                //                                 header: 'Quarter',
                //                                 field: 'quater',
                //                                 width: 150
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Manager',
                //                                 header: 'Manager',
                //                                 field: 'reporting_to.name',
                //                                 width: 180
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Project',
                //                                 header: 'Project',
                //                                 field: 'project.project',
                //                                 width: 150
                //                             },
                //                             {
                //                                 field: 'overall_rating',
                //                                 label: 'Overall Rating',
                //                                 header: 'Overall Rating',
                //                                 width: 150,
                //                                 type: 'text'
                //                             },
                //                             {
                //                                 type: 'number',
                //                                 placeholder: 'Project Time Perc',
                //                                 field: 'project_time_perc',
                //                                 header: 'Project Time(DAYS)',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'cooprative',
                //                                 label: 'Cooperative',
                //                                 header: 'Cooperative',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'delivery_quality',
                //                                 label: 'Delivery Quality',
                //                                 header: 'Delivery Quality',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'punctuality_rating',
                //                                 label: 'Punctuality',
                //                                 header: 'Punctuality',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'following_processes_rating',
                //                                 label: 'Following Processes',
                //                                 header: 'Following Processes',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'communucations_rating',
                //                                 label: 'Communications',
                //                                 header: 'Communications',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'responsible_rating',
                //                                 label: 'Responsibility',
                //                                 header: 'Responsibility',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'given_training_rating',
                //                                 label: 'Given Training',
                //                                 header: 'Given Training',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'attitude',
                //                                 label: 'Attitude',
                //                                 header: 'Attitude',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'taken_training_rating',
                //                                 label: 'Taken Training',
                //                                 header: 'Taken Training',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'certifications_rating',
                //                                 label: 'Certifications',
                //                                 header: 'Certifications',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Comments',
                //                                 field: 'comments',
                //                                 header: 'Comments',
                //                             },
                //                         ]}
                //                     />
                //                 );
                //             },
                //         },
                //     ]
                // },
                {
                    label: 'Project Manager Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'reporting_assessment_details_new',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Quarter',
                                                header: 'Quarter',
                                                field: 'quater',
                                                width: 150
                                            },
                                            {
                                                type: 'text',
                                                label: 'Manager',
                                                header: 'Manager',
                                                field: 'reporting_to.name',
                                                width: 180
                                            },
                                            {
                                                type: 'text',
                                                label: 'Project',
                                                header: 'Project',
                                                field: 'project.project',
                                                width: 180
                                            },
                                            {
                                                field: 'overall_rating',
                                                label: 'Overall Rating',
                                                header: 'Overall Rating',
                                                width: 150,
                                                type: 'text',
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                type: 'number',
                                                placeholder: 'Project Time Perc',
                                                field: 'project_time_perc',
                                                header: 'Project Time(DAYS)',
                                                width: 150,
                                            },
                                            {
                                                field: 'delivery_quality',
                                                label: 'Delivery Quality',
                                                header: 'Delivery Quality',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'communucations_rating',
                                                label: 'Communications',
                                                header: 'Communications',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'punctuality_rating',
                                                label: 'Cultural & Behavioural',
                                                header: 'Cultural & Behavioural',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'attitude',
                                                label: 'Learning & Sharing',
                                                header: 'Learning & Sharing',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                type: 'text',
                                                label: 'Comments',
                                                field: 'comments',
                                                header: 'Comments',
                                            },
                                            //   {
                                            //     type : 'action',
                                            //     header:'Action',
                                            //     text : "View Detail",
                                            //     action: ({item}) => ({
                                            //       onPress : ({navigation}) => {
                                            //         navigation.navigate({ view: 'pm-edit-appraisal-requests', params: { item: item , isEditable : false} })
                                            //       }
                                            //     })

                                            //   }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Resource Management Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'resourceManagementDetails',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'number',
                                                label: 'Training Given',
                                                header: 'Training Given',
                                                field: 'rm_training_given_score',
                                                width: 150,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Certification',
                                                header: 'Certification',
                                                field: 'certification_score',
                                                width: 150,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Employee Rejection',
                                                header: 'Employee Rejection',
                                                field: 'number_of_rejection',
                                                width: 150,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Interview Rejection',
                                                header: 'Interview Rejection',
                                                field: 'number_of_interview_rejection',
                                                width: 150,
                                                visible: ({ values }) => {
                                                    const item = values;
                                                    if (item && item.employee && item.employee.employee_level && item.employee.employee_level.level && item.employee.employee_level.level < 4) {
                                                        return true
                                                    }
                                                }
                                            },
                                            {
                                                type: 'number',
                                                label: 'Mentorship',
                                                field: 'mentorship',
                                                header: 'Mentorship',
                                                width: 150,
                                            }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Processed Manually By HR',
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'processed_manually_by_hr',
                            label: 'Processed Manually',
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            size: 6,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'Processed Increment',
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'ifIncrement_asPer_aggrement',
                            options: [
                                { value: 'AsPerAggrement', label: 'As Per Aggrement' },
                                { value: 'OnHold', label: 'On Hold' },
                            ],
                            placeholder: 'Processed Increment',
                            label: 'Processed Increment',
                            required: true,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'OnHold',
                            type: 'date',
                            label: 'Hold Till',
                            field: 'hold_till',
                            size: 6,
                        },
                        {
                            type: 'date',
                            label: 'Exam Completion Date',
                            field: 'exam_completion_date',
                            size: 6,
                        },
                        {
                            type: 'number',
                            label: 'No Of Leaves',
                            field: 'no_of_leaves',
                            size: 6,
                        }
                    ],
                },
                {
                    label: 'People Manager Overall Rating',
                    visible: ({ values }) => values && values.department_type != "Non Technical",
                    fields: [
                        {
                            field: 'overall_grading_peopleManager',
                            placeholder: "Overall Grading",
                            required: true,
                            size: 6,
                            label: 'Overall Grading',
                            type: 'text'
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            required: true,
                            size: 6,
                            label: 'Innovation Score',
                            type: 'number'
                        },
                        {
                            type: 'textArea',
                            field: 'people_manager_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            required: true,
                        },
                        {
                            render: () => {
                                const { employee: { employee_level = {} } = {} } = row;
                                const { level } = employee_level;
                                let text = 5
                                if (level >= 4) {
                                    text = 10
                                }
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of ${text}`}
                                    </Text>
                                );
                            },
                        },
                        {
                            type: 'number',
                            field: 'appraisal_amount',
                            placeholder: 'Amount',
                            label: 'Amount',
                            size: 6,
                        },
                        {
                            type: 'text',
                            field: "peopleManager_biannual_amount",
                            label: "Biannual Amount",
                            size: 6,
                        },
                        {
                            type: 'textArea',
                            label: 'Remark',
                            // container:'topLabel',
                            field: 'people_manager_remark',
                            size: 6,
                        },
                        {
                            type: 'text',
                            field: "peopleManager_yearlyIncentive",
                            label: "Yearly Incentive",
                            size: 6,
                        }
                    ],
                },
                {
                    label: 'Amount',
                    fields: [
                        {
                            type: 'date',
                            field: 'effective_date',
                            placeholder: 'Effective Date',
                            label: 'Effective Date',
                            size: 6,
                        },
                        // {
                        //     type: "number",
                        //     field: "market_correction_min",
                        //     label: "Min Market Correction(Unit Head)",
                        //     placeholder: "Min Market Correction",
                        //     size: 6,
                        //     editable: false
                        // },
                        // {
                        //     type: "number",
                        //     field: "market_correction",
                        //     label: "Max Market Correction(Unit Head)",
                        //     placeholder: "Max Market Correction",
                        //     size: 6,
                        //     editable: false
                        // },
                        // {
                        //     type: 'number',
                        //     field: "unit_revised_amt_min", //'unit_revised_amount'
                        //     label: "Min Monthly Increment Amount(Unit Head)",
                        //     placeholder: "Min Monthly Increment Amount(Unit Head)",
                        //     size: 6,
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values?.employee?.increment_type == 'Annual') ||
                        //             values?.unitManager_incrementType == 'Annual'
                        //         );
                        //     },
                        // },
                        {
                            type: 'number',
                            field: "unit_revised_amt_max", //'unit_revised_amount'
                            label: "Monthly Increment Amount(Unit Head)",
                            placeholder: "Monthly Increment Amount(Unit Head)",
                            size: 6,
                            visible: ({ values }) => {
                                return (
                                    (values?.employee?.increment_type == 'Annual') ||
                                    values?.unitManager_incrementType == 'Annual'
                                );
                            },
                        },
                        // {
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values &&
                        //                 values.employee &&
                        //                 values.employee.increment_type &&
                        //                 (values.employee.increment_type == 'Annual'
                        //                     ? false
                        //                     : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                        //                         ? false
                        //                         : true)) ||
                        //             false
                        //         );
                        //     },
                        //     type: "number",
                        //     size: 6,
                        //     field: "unit_revised_amt_min", //'unit_revised_amount'
                        //     label: "First Six Months Min Amount(Unit Head)"
                        // },
                        {
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual'
                                            ? false
                                            : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                                                ? false
                                                : true)) ||
                                    false
                                );
                            },
                            type: "number",
                            size: 6,
                            field: "unit_revised_amt_max", //'unit_revised_amount'
                            label: "First Six Months Amount(Unit Head)"
                            // field:(props)=>props && props.unit_revised_amount ? props.unit_revised_amount:props.appraisal_amount
                        },
                        // {
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values &&
                        //                 values.employee &&
                        //                 values.employee.increment_type &&
                        //                 (values.employee.increment_type == 'Annual'
                        //                     ? false
                        //                     : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                        //                         ? false
                        //                         : true)) ||
                        //             false
                        //         );
                        //     },
                        //     type: "number",
                        //     size: 6,
                        //     field: "min_unit_biannual_amount",
                        //     placeholder: "BIANNUAL MIN AMOUNT",
                        //     label: "Next Six Months Min Amount(Unit Head)"
                        // },
                        {
                            type: 'number',
                            size: 6,
                            field: 'unit_biannual_amount',
                            placeholder: 'Next Six Months Amount(Unit Head)', //Bonus Amount
                            label: 'Next Six Months Amount(Unit Head)',
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual'
                                            ? false
                                            : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                                                ? false
                                                : true)) ||
                                    false
                                );
                            },
                        },
                        {
                            type: 'number',
                            field: 'unitManager_yearlyIncentive',
                            placeholder: 'Yearly Incentive(Unit Head)',
                            label: 'Yearly Incentive(Unit Head)',
                            size: 6,
                        },
                        // {
                        //     type: "number",
                        //     field: "min_unitManager_yearlyIncentive",
                        //     placeholder: "Min Yearly Incentive",
                        //     label: 'Min Yearly Incentive(Unit Head)',
                        //     size: 6,
                        // },
                        {
                            type: 'textArea',
                            label: 'Biannual Amount Remark(Unit Head)',
                            field: 'unitBiannual_amount_remark',
                            size: 6,
                        },
                        {
                            type: 'textArea',
                            label: 'Special Comments',
                            field: 'unit_special_comments',
                            size: 6,
                        },
                    ],
                },
                {
                    label: 'Designation',
                    visible: ({ values }) => values && values.promotion_recommendation,
                    fields: [
                        {
                            field: 'employee.designation.name',
                            label: 'Current Designations',
                            placeholder: 'Current Designations',
                            type: 'text',
                            size: 6,
                        },
                        {
                            field: 'unit_promotion_designation.name',
                            label: 'Designations',
                            placeholder: 'Current Designations',
                            type: 'text',
                            size: 6,
                        },
                    ],
                },
                {
                    label: 'Recommendations',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Final Unit Head Grading',
                            placeholder: 'Final Unit Head Grading',
                            field: "overall_grading_unitHeadFinal",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6
                        },
                        {
                            field: 'technology_by_unit_head',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                        {
                            field: 'forPromotion_unit_manager',
                            label: 'Promotion Recommendation',
                            placeholder: 'Promotion Recommendation',
                            type: 'text',
                            size: 6
                        },
                        {
                            field: 'increment_recommendation',
                            label: 'Increment Recommendation',
                            placeholder: 'Increment Recommendation',
                            type: 'text',
                            size: 6
                        },
                        {
                            field: 'offer_accepted_by_employee',
                            label: 'Offer Accepted By Employee',
                            placeholder: 'Offer Accepted By Employee',
                            type: 'text',
                            size: 6
                        },
                        {
                            label: 'Special Comments',
                            type: 'text',
                            field: 'unit_special_comments',
                            size: 6,
                        },
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const HRAppraisalDetail = props => {
    let {
        route: { params },
    } = props;
    let readOnly = true
    return (
        <HRAppraisalForm
            header={{
                title: 'Appraisal Details'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};

export const PHAppraisalForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    const { fetch, user } = useAppStateContext();
    let { onSubmit } = useFormSubmit({
        uri: '/appraisals',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/phappraisaldetail/${row?._id}`}
            submitAction={'Save'}
            mode="edit"
            onSubmit={onSubmit}
            beforeSubmit={({ data }) => {
                const {reporting_assessment_details , ...rest} = data;
                const { employee: approverEmployee = {} } = user || {};
                let localUpdates = {};
                if (row && row.new_effective_date) {
                    localUpdates.new_effective_date = data.new_effective_date;
                }
                if (data && data.new_effective_date) {
                    localUpdates['new_effective_date'] = data.new_effective_date;
                }
                if (data && data.unitManager_incrementType == 'Annual') {
                    localUpdates.new_effective_date = new Date(row && row.effective_date).setMonth(
                        new Date(row && row.effective_date).getMonth() + 12,
                    );
                }
                if (data && !data.forPromotion_unit_manager) {
                    localUpdates['unit_promotion_designation'] = null;
                }
                if (data && data.forPromotion_unit_manager) {
                    localUpdates['unit_promotion_designation'] = row && row.unit_promotion_designation;
                }
                if (data && !data.forPromotion_unit_manager) {
                    localUpdates.forPromotion_unit_manager =
                        data && !data.forPromotion_unit_manager ? false : data.forPromotion_unit_manager;
                }
                if (approverEmployee && approverEmployee._id) {
                    localUpdates["approved_by_unit_ph"] = { _id: approverEmployee && approverEmployee._id }
                }
                return {
                    data: {
                        ...rest,
                        unit_revised_amount: data && data.unit_revised_amount,
                        unit_appraisal_status: true,
                        offer_accepted_by_employee : true,
                        closed_date : getZeroTimeDate(new Date()),
                        ...localUpdates,
                    },
                };
            }}
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Employee Code',
                            label: 'Employee Code',
                            field: 'employee.employee_code',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Current Project',
                            placeholder: 'Current Project',
                            field: "current_project",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'text',
                            label: 'Experience',
                            placeholder: 'Experience',
                            field: "employee.experience",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Skills',
                            placeholder: 'Skills',
                            field: "employee_skill",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Increment Type',
                            label: 'Increment Type',
                            field: 'employee.increment_type',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            field: "employee.designation.name",
                            label: "Current Designations",
                            placeholder: "Current Designations",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'file',
                            field: 'employee_salary_component.offer_letter_copy_package',
                            label: 'Offer Letter Latest',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "from_date",
                            label: 'Increment From Date',
                            placeholder: 'From Date',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'date',
                            field: "to_date",
                            label: 'IncrementTo Date',
                            placeholder: 'To Date',
                            readOnly: true,
                            size: 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    label: 'OVERALL RATING',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6,
                        },
                        {
                            placeholder: 'Utilisation Percentage',
                            label: 'Utilisation Percentage',
                            field: 'utilisation',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            render: ({ }) => {
                                let {
                                    total_billed_hours = 0,
                                    total_available_hours = 0,
                                } = row || {};
                                let utilisation =
                                    (total_available_hours && total_billed_hours / total_available_hours) ||
                                    0;
                                utilisation *= 100;
                                let Format = value => (value && value.toFixed(2)) || 0;
                                total_billed_hours = Format(total_billed_hours || 0);
                                total_available_hours = Format(total_available_hours || 0);
                                utilisation = Format(utilisation || 0);
                                return (
                                    <>
                                        <Text style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: "#8E8E8E"
                                        }}>
                                            Utilisation
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: "#4B4B4B",
                                            }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'stretch',
                                                    minWidth: 20,
                                                    margin: 2,
                                                }}>
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_billed_hours && Number(total_billed_hours).toFixed(2)}
                                                </Text>
                                                <View style={{ height: 1, background: "#4B4B4B", }} />
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_available_hours}
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                }}>
                                                =
                                            </Text>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                    fontFamily: 'Quicksand-Regular',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                }}>
                                                {utilisation && Number(utilisation).toFixed(2)} %
                                            </Text>
                                        </View>
                                    </>
                                );
                            },
                        },
                        {
                            label: 'Manager Rating',
                            placeholder: 'Manager Rating',
                            field: "pm_overall_rating",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            type: 'radioGroup',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6,
                        },
                        {
                            field: 'rm_overall_score',
                            placeholder: "RM Feedback",
                            label: 'RM Feedback',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'mentorship_score',
                            placeholder: "Mentorship",
                            label: 'Mentorship',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            label: 'Innovation Score',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            type: 'number',
                            field: "delivered_hours",
                            label: 'Delivered Hours',
                            placeholder: 'Delivered Hours',
                            readOnly : true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                    ],
                },
                {
                    label: 'Employee Self Assessment',
                    fields: [
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                            marginTop: "20px",
                                        }}> 1. Describe your significant accomplishments or contributions during this appraisal period.</Text>
                                );
                            }
                        },
                        {
                            type: 'textArea',
                            field: 'question_1_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 2. Have you successfully performed any new tasks or additional duties outside the scope of your regular responsibilities during the appraisal period? If so, please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_2_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 3. Innovation is one of our core values.Please share an instance where you have delivered an innovative solution at work during this appraisal period.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_3_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 4. Have you been deployed in any new technology or completed any certifications during the appraisal period?If yes,please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_4_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            readOnly: true,
                        },
                    ],
                },
                // {
                //     label: 'Project Manager Feedback',
                //     visible: ({ values }) => {
                //         if (values?.department_type != "Non Technical" && values?.is_feedback_process_new) {
                //             return true
                //         }
                //     },
                //     fields: [
                //         {
                //             field: 'reporting_assessment_details',
                //             nested: true,
                //             readOnly: true,
                //             render: props => {
                //                 const {
                //                     form: { values = {} },
                //                 } = props;
                //                 return (
                //                     <NestedTable
                //                         {...props}
                //                         fields={[
                //                             {
                //                                 type: 'text',
                //                                 label: 'Quarter',
                //                                 header: 'Quarter',
                //                                 field: 'quater',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Manager',
                //                                 header: 'Manager',
                //                                 field: 'reporting_to.name',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Project',
                //                                 header: 'Project',
                //                                 field: 'project.project',
                //                             },
                //                             {
                //                                 field: 'overall_rating',
                //                                 label: 'Overall Rating',
                //                                 header: 'Overall Rating',
                //                                 width: 120,
                //                                 type: 'text'
                //                             },
                //                             {
                //                                 type: 'number',
                //                                 placeholder: 'Project Time Perc',
                //                                 field: 'project_time_perc',
                //                                 header: 'Project Time(DAYS)',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'cooprative',
                //                                 label: 'Cooperative',
                //                                 header: 'Cooperative',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'delivery_quality',
                //                                 label: 'Delivery Quality',
                //                                 header: 'Delivery Quality',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'punctuality_rating',
                //                                 label: 'Punctuality',
                //                                 header: 'Punctuality',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'following_processes_rating',
                //                                 label: 'Following Processes',
                //                                 header: 'Following Processes',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'communucations_rating',
                //                                 label: 'Communications',
                //                                 header: 'Communications',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'responsible_rating',
                //                                 label: 'Responsibility',
                //                                 header: 'Responsibility',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'given_training_rating',
                //                                 label: 'Given Training',
                //                                 header: 'Given Training',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'attitude',
                //                                 label: 'Attitude',
                //                                 header: 'Attitude',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'taken_training_rating',
                //                                 label: 'Taken Training',
                //                                 header: 'Taken Training',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'certifications_rating',
                //                                 label: 'Certifications',
                //                                 header: 'Certifications',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Comments',
                //                                 field: 'comments',
                //                                 header: 'Comments',
                //                             },
                //                         ]}
                //                     />
                //                 );
                //             },
                //         },
                //     ]
                // },
                {
                    label: 'Project Manager Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'reporting_assessment_details_new',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Quarter',
                                                header: 'Quarter',
                                                field: 'quater',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Manager',
                                                header: 'Manager',
                                                field: 'reporting_to.name',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Project',
                                                header: 'Project',
                                                field: 'project.project',
                                            },
                                            {
                                                field: 'overall_rating',
                                                label: 'Overall Rating',
                                                header: 'Overall Rating',
                                                width: 180,
                                                type: 'text',
                                            },
                                            {
                                                type: 'number',
                                                placeholder: 'Project Time Perc',
                                                field: 'project_time_perc',
                                                header: 'Project Time(DAYS)',
                                                width: 180,
                                            },
                                            {
                                                field: 'delivery_quality',
                                                label: 'Delivery Quality',
                                                header: 'Delivery Quality',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'communucations_rating',
                                                label: 'Communications',
                                                header: 'Communications',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'punctuality_rating',
                                                label: 'Cultural & Behavioural',
                                                header: 'Cultural & Behavioural',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'attitude',
                                                label: 'Learning & Sharing',
                                                header: 'Learning & Sharing',
                                                type: 'text',
                                                width: 120,
                                            },
                                            {
                                                type: 'text',
                                                label: 'Comments',
                                                field: 'comments',
                                                header: 'Comments',
                                            },
                                            //   {
                                            //     type : 'action',
                                            //     header:'Action',
                                            //     text : "View Detail",
                                            //     action: ({item}) => ({
                                            //       onPress : ({navigation}) => {
                                            //         navigation.navigate({ view: 'pm-edit-appraisal-requests', params: { item: item , isEditable : false} })
                                            //       }
                                            //     })

                                            //   }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Resource Management Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical" && values.rmFeedbackGeneratedNew) {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'resourceManagementDetails',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'number',
                                                label: 'Training Given',
                                                header: 'Training Given',
                                                field: 'rm_training_given_score',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Certification',
                                                header: 'Certification',
                                                field: 'certification_score',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Employee Rejection',
                                                header: 'Employee Rejection',
                                                field: 'number_of_rejection',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Interview Rejection',
                                                header: 'Interview Rejection',
                                                field: 'number_of_interview_rejection',
                                                editable: false,
                                                width: 180,
                                                visible: ({ values }) => {
                                                    const item = row;
                                                    if (item && item.employee && item.employee.employee_level && item.employee.employee_level.level && item.employee.employee_level.level < 4) {
                                                        return true
                                                    }
                                                }
                                            },
                                            {
                                                type: 'number',
                                                label: 'Mentorship',
                                                field: 'mentorship',
                                                header: 'Mentorship',
                                                editable: false
                                            }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Current Package',
                    visible: ({ values }) => values && values.department_type != "Non Technical",
                    fields: [
                        {
                            type: 'text',
                            field: 'ctc_amount',
                            label: 'Monthly CTC Amount',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'text',
                            field: 'yearly_employee_package',
                            label: 'CTC Amount',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "employee_salary_component.package_review_date",
                            label: 'Package Review Date',
                            placeholder: 'Package Review Date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "employee_salary_component.from_date",
                            label: 'From Date',
                            placeholder: 'From Date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "employee_salary_component.to_date",
                            label: 'To Date',
                            placeholder: 'To Date',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'People Manager Feedback',
                    visible: ({ values }) => values && values.increment_recommendation,
                    fields: [
                        {
                            field: 'overall_grading_peopleManager',
                            placeholder: "Overall Grading",
                            readOnly: true,
                            size: 6,
                            label: 'Overall Grading',
                            type: 'text',
                            readOnly: true,
                            visible: ({ values }) => values && values.department_type !== "Non Technical",
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            required: true,
                            size: 6,
                            label: 'Innovation Score',
                            type: 'number',
                            readOnly: true,
                            visible: ({ values }) => values && values.department_type !== "Non Technical",
                        },
                        {
                            type: 'textArea',
                            field: 'people_manager_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => values && values.department_type !== "Non Technical",
                        },
                        {
                            render: () => {
                                const { employee: { employee_level = {} } = {} } = row;
                                const { level } = employee_level;
                                let text = 5
                                if (level >= 4) {
                                    text = 10
                                }
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of ${text}`}
                                    </Text>
                                );
                            },
                            visible: ({ values }) => values && values.department_type !== "Non Technical",
                        },
                    ],
                },
                {
                    label: 'Processed Increment',
                    visible: ({ values }) => {
                        return values &&
                            values.employee &&
                            values.employee.agreement_end_date &&
                            values.effective_date &&
                            new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                            ? true
                            : false || false;
                    },
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'ifIncrement_asPer_aggrement',
                            options: [
                                { value: 'AsPerAggrement', label: 'As Per Aggrement' },
                                { value: 'OnHold', label: 'On Hold' },
                            ],
                            placeholder: 'Processed Increment',
                            label: 'Processed Increment',
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: ({ values }) => {
                                return values &&
                                    values.employee &&
                                    values.employee.agreement_end_date &&
                                    values.effective_date &&
                                    new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                    ? true
                                    : false || false;
                            },
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'OnHold',
                            type: 'date',
                            label: 'Hold Till',
                            field: 'hold_till',
                            size: 6,
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'AsPerAggrement',
                            type: 'text',
                            label: 'Package',
                            field: 'package_ifIncrement_asPer_aggrement',
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Exam Completion Date',
                            field: 'exam_completion_date',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'number',
                            label: 'No Of Leaves',
                            field: 'no_of_leaves',
                            readOnly: true,
                            size: 6,
                        }
                    ],
                },
                {
                    visible: ({ values }) => values && values.increment_recommendation,
                    fields: [
                        {
                            type: 'text',
                            label: 'Increment Type',
                            field: 'peopleManager_incr_type',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'text',
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                            type: 'text',
                            label: 'First Six Months Amount',
                            field: 'appraisal_amount',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                ((values.employee && values.employee.increment_type == 'Annual') ||
                                    (values.employee && !values.employee.increment_type) ||
                                    values.peopleManager_incrementType == 'Annual'),
                            type: 'text',
                            label: 'Increment Amount',
                            field: 'appraisal_amount',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'text',
                            label: 'Increment Remark',
                            field: 'people_manager_remark',
                            readOnly: true,
                            size: 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => values && values.increment_recommendation,
                    fields: [
                        {
                            type: 'date',
                            label: 'Next Effective Date',
                            field: 'peopleManager_new_effective_date',
                            readOnly: true,
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                        },
                        {
                            type: 'text',
                            label: 'Next Six Months Amount',
                            field: 'peopleManager_biannual_amount',
                            readOnly: true,
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                        },

                    ],
                },
                {
                    visible: ({ values }) => values && values.increment_recommendation,
                    fields: [
                        {
                            type: 'text',
                            label: 'Remark',
                            field: 'peopleManager_Biannual_remark',
                            readOnly: true,
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                        },
                        {
                            type: 'custom',
                            readOnly: true,
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                            render: ({ }) => {
                                return <div style={{ borderBottom: '2px solid #EAEAEA' }}></div>;
                            },
                        },
                    ],
                },
                {
                    visible: ({ values }) => values && values.increment_recommendation,
                    label: 'Promotion Proposed',
                    fields: [
                        {
                            type: 'text',
                            label: 'Current Designation',
                            field: 'employee.designation.name',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'text',
                            label: 'Proposed Designation',
                            field: 'promotion_designation.name',
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'If you want to change the increment cycle please select an appropriate option from below tab',
                    visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                    fields: [
                        {
                            field: "peopleManager_incrementType",
                            placeholder: "Proposed Increment Type",
                            label: "Propose Increment Type",
                            type: "text",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'autoComplete',
                            field: 'unitManager_incrementType',
                            options: ({ values }) => values && values.employee && values.employee.increment_type == "Annual" ? void 0 : ["Annual"],
                            placeholder: 'Proposed Increment Type',
                            label: 'Proposed Increment Type',
                            size: 6,

                        },
                    ],
                },
                {
                    label: 'Unit Head’s Proposal',
                    // visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                    fields: [
                        {
                            type: 'date',
                            field: 'effective_date',
                            placeholder: 'Effective Date',
                            label: 'Effective Date',
                            size: 6
                        },
                        {
                            label: 'Final Unit Head Grading',
                            placeholder: 'Final Unit Head Grading',
                            field: "overall_grading_unitHeadFinal",
                            required : true,
                            size: 6,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            type: 'radioGroup',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'technology_by_unit_head',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            required: true,
                        },
                    ],
                },
                {
                    fields: [
                        // {
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values &&
                        //                 values.employee &&
                        //                 values.employee.increment_type &&
                        //                 values.employee.increment_type == 'Annual') ||
                        //             values.unitManager_incrementType == 'Annual'
                        //         );
                        //     },
                        //     type: 'number',
                        //     label: 'Min Monthly Increment Amount',
                        //     field: 'unit_revised_amt_min',
                        //     readOnly: false,
                        //     size: 6
                        // },
                        {
                            visible: ({ values }) =>
                                (values && values.employee && values.employee.increment_type && values.employee.increment_type == 'Annual') ||
                                values.unitManager_incrementType == 'Annual',
                            type: 'number',
                            label: 'Monthly Increment Amount',
                            field: 'unit_revised_amt_max',
                            readOnly: false,
                            size: 6
                        },
                        // {
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values &&
                        //                 values.employee &&
                        //                 values.employee.increment_type &&
                        //                 (values.employee.increment_type == 'Annual'
                        //                     ? false
                        //                     : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                        //                         ? false
                        //                         : true)) ||
                        //             false
                        //         );
                        //     },
                        //     type: 'number',
                        //     field: 'unit_revised_amt_min',
                        //     label: 'First Six Months Min Amount',
                        //     readOnly: false,
                        //     size: 6
                        // },
                        {
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual'
                                            ? false
                                            : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                                                ? false
                                                : true)) ||
                                    false
                                );
                            },

                            type: 'number',
                            field: 'unit_revised_amt_max',
                            label: 'First Six Months Amount',
                            readOnly: false,
                            size: 6
                        },
                        // {
                        //     visible: ({ values }) => {
                        //         return (
                        //             (values &&
                        //                 values.employee &&
                        //                 values.employee.increment_type &&
                        //                 (values.employee.increment_type == 'Annual'
                        //                     ? false
                        //                     : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                        //                         ? false
                        //                         : true)) ||
                        //             false
                        //         );
                        //     },
                        //     type: 'number',
                        //     field: 'min_unit_biannual_amount',
                        //     placeholder: 'Amount',
                        //     label: 'Next Six Months Min Amount',
                        //     readOnly: false,
                        //     size: 6
                        // },
                        {
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual'
                                            ? false
                                            : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                                                ? false
                                                : true)) ||
                                    false
                                );
                            },
                            type: 'number',
                            field: 'unit_biannual_amount',//bonus_amount
                            placeholder: 'Amount',//Bonus Amount
                            label: 'Next Six Months Amount',
                            readOnly: false,
                            size: 6
                        },
                        // {
                        //     visible: ({ values }) =>
                        //         (values &&
                        //             values.employee &&
                        //             values.employee.increment_type &&
                        //             (values.employee.increment_type == 'Annual'
                        //                 ? false
                        //                 : values.employee.increment_type == 'Biannual' && values.unitManager_incrementType == 'Annual'
                        //                     ? false
                        //                     : true)) ||
                        //         false,
                        //     type: 'text',
                        //     field: 'unitBiannual_amount_remark',
                        //     placeholder: 'Remark',
                        //     label: 'Biannual Remark',
                        //     readOnly: false,
                        //     size: 6
                        // },
                        // {
                        //     type: 'number',
                        //     readOnly: false,
                        //     size: 6,
                        //     label: 'Min Market Correction',
                        //     field: 'market_correction_min',
                        // },
                        // {
                        //     type: 'number',
                        //     readOnly: false,
                        //     label: 'Max Market Correction',
                        //     field: 'market_correction',
                        //     size: 6
                        // },
                        // {
                        //     type: 'number',
                        //     field: 'min_unitManager_yearlyIncentive',
                        //     placeholder: 'Yearly Incentive Min',
                        //     label: 'Yearly Incentive Min',
                        //     readOnly: false,
                        //     size: 6
                        // },
                        {
                            type: 'number',
                            field: 'unitManager_yearlyIncentive',
                            placeholder: 'Yearly Incentive',
                            label: 'Yearly Incentive',
                            readOnly: false,
                            size: 6
                        },
                    ],
                },
                {
                    fields: [
                        {
                            type: 'text',
                            field: 'unit_special_comments',
                            label: 'Special Comments',
                            placeholder: 'Special Comments',
                            readOnly: false,
                        },
                        {
                            type: 'custom',
                            render: ({ }) => {
                                return <div style={{ borderBottom: '2px solid #EAEAEA' }}></div>;
                            },
                        },
                    ],
                },
                {
                    label: "Promotion Proposed by People Manager",
                    fields: [
                        {
                            type: 'text',
                            label: 'Current Designation',
                            field: 'employee.designation.name',
                            readOnly : true,
                            size: 6
                        },
                        {
                            type: 'text',
                            label: 'Proposed Designation',
                            field: 'promotion_designation.name',
                            readOnly : true,
                            size: 6
                        },
                    ],
                },
                {
                    label: "RFR Data",
                    fields: [
                        {
                            type: 'number',
                            label: 'Assigned',
                            field: 'rfr_assigned',
                            readOnly: true,
                            size: 6
                        },
                        {
                            type: 'number',
                            label: 'Won',
                            field: 'rfr_won',
                            readOnly: true,
                            size: 6
                        },
                        {
                            type: 'number',
                            label: 'Drop',
                            field: 'rfr_drop',
                            readOnly: true,
                            size: 6
                        },
                        {
                            type: 'number',
                            label: 'Reject',
                            field: 'rfr_reject',
                            readOnly: true,
                            size: 6
                        },
                        {
                            type: 'number',
                            label: 'Selection Percentage',
                            field: 'rfr_selection_percentage',
                            readOnly: true,
                            size: 6
                        },
                    ],
                },
                {
                    label: "RFR Owner Data",
                    fields: [
                        {
                            type: 'number',
                            label: 'Assigned',
                            field: 'rfr_owner_assigned',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Won',
                            field: 'rfr_owner_won',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Drop',
                            field: 'rfr_owner_drop',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Reject',
                            field: 'rfr_owner_reject',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'number',
                            label: 'Selection Percentage',
                            field: 'rfr_owner_selection_percentage',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Total RNR',
                            field: 'total_rnr',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Total Leave',
                            field: 'total_leave',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'number',
                            label: 'Trainee',
                            field: 'trainee_total_training',
                            size: 6,
                            readOnly: true
                        },
                        {
                            type: 'number',
                            label: 'Trainer',
                            field: 'trainer_total_training',
                            size: 6,
                            readOnly: true
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: 'Promotion Recommendation',
                            placeholder: 'Promotion Recommendation',
                            field: "forPromotion_unit_manager",
                            size: 6,
                            // required: true,
                            type: 'radioGroup',
                            options: [
                                { value: true, label: 'Accept & Finalise Designation' },
                                { value: false, label: 'Reject Promotion' },
                            ],
                            editorAsRenderer: true,
                        },
                    ],
                },
                {
                    columnsPerRow: 2,
                    fields: [
                        {
                            field: "employee.designation.name",
                            label: "Current Designations",
                            placeholder: "Current Designations",
                            size: 6,
                            type: 'text',
                            readOnly: true,
                            visible: ({ values }) => values && values.forPromotion_unit_manager,
                        },
                        {
                            field: 'unit_promotion_designation',
                            api: '/designations',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'New Designations',
                            size: 6,
                            readOnly: false,
                            visible: ({ values }) => values && values.forPromotion_unit_manager
                        },
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const PHAppraisalDetail = props => {
    let {
        route: { params },
    } = props;
    let readOnly = false
    return (
        <PHAppraisalForm
            header={{
                title: 'Appraisal Details'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};


export const PHFinalAppraisalForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    return (
        <Form
            api={`/phappraisaldetail/${row?._id}`}
            submitAction={'Save'}
            mode="edit"
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Employee Code',
                            label: 'Employee Code',
                            field: 'employee.employee_code',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Current Project',
                            placeholder: 'Current Project',
                            field: "current_project",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'text',
                            label: 'Experience',
                            placeholder: 'Experience',
                            field: "employee.experience",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Skills',
                            placeholder: 'Skills',
                            field: "employee_skill",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Increment Type',
                            label: 'Increment Type',
                            field: 'employee.increment_type',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            field: "employee.designation.name",
                            label: "Current Designations",
                            placeholder: "Current Designations",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'file',
                            field: 'employee_salary_component.offer_letter_copy_package',
                            label: 'Offer Letter Latest',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "from_date",
                            label: 'Increment From Date',
                            placeholder: 'From Date',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'date',
                            field: "to_date",
                            label: 'IncrementTo Date',
                            placeholder: 'To Date',
                            readOnly: true,
                            size: 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    label: 'OVERALL RATING',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6,
                        },
                        {
                            placeholder: 'Utilisation Percentage',
                            label: 'Utilisation Percentage',
                            field: 'utilisation',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            render: () => {
                                let {
                                    total_billed_hours = 0,
                                    total_available_hours = 0,
                                } = row || {};
                                let utilisation =
                                    (total_available_hours && total_billed_hours / total_available_hours) ||
                                    0;
                                utilisation *= 100;
                                let Format = value => (value && value.toFixed(2)) || 0;
                                total_billed_hours = Format(total_billed_hours || 0);
                                total_available_hours = Format(total_available_hours || 0);
                                utilisation = Format(utilisation || 0);
                                return (
                                    <>
                                        <Text style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: "#8E8E8E"
                                        }}>
                                            Utilisation
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: "#4B4B4B",
                                            }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'stretch',
                                                    minWidth: 20,
                                                    margin: 2,
                                                }}>
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_billed_hours && Number(total_billed_hours).toFixed(2)}
                                                </Text>
                                                <View style={{ height: 1, background: "#4B4B4B", }} />
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_available_hours}
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                }}>
                                                =
                                            </Text>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                    fontFamily: 'Quicksand-Regular',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                }}>
                                                {utilisation && Number(utilisation).toFixed(2)} %
                                            </Text>
                                        </View>
                                    </>
                                );
                            },
                        },
                        {
                            label: 'Manager Rating',
                            placeholder: 'Manager Rating',
                            field: "pm_overall_rating",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            type: 'radioGroup',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'rm_overall_score',
                            placeholder: "RM Feedback",
                            label: 'RM Feedback',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'mentorship_score',
                            placeholder: "Mentorship",
                            label: 'Mentorship',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            label: 'Innovation Score',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            type: 'number',
                            field: "delivered_hours",
                            label: 'Delivered Hours',
                            placeholder: 'Delivered Hours',
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                    ],
                },
                {
                    label: 'Employee Self Assessment',
                    fields: [
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                            marginTop: "20px",
                                        }}> 1. Describe your significant accomplishments or contributions during this appraisal period.</Text>
                                );
                            }
                        },
                        {
                            type: 'textArea',
                            field: 'question_1_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 2. Have you successfully performed any new tasks or additional duties outside the scope of your regular responsibilities during the appraisal period? If so, please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_2_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 3. Innovation is one of our core values.Please share an instance where you have delivered an innovative solution at work during this appraisal period.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_3_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 4. Have you been deployed in any new technology or completed any certifications during the appraisal period?If yes,please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_4_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                    ],
                },
                // {
                //     label: 'Project Manager Feedback',
                //     visible: ({ values }) => {
                //         if (values?.department_type != "Non Technical" && values?.is_feedback_process_new) {
                //             return true
                //         }
                //     },
                //     fields: [
                //         {
                //             field: 'reporting_assessment_details',
                //             nested: true,
                //             readOnly: true,
                //             render: props => {
                //                 const {
                //                     form: { values = {} },
                //                 } = props;
                //                 return (
                //                     <NestedTable
                //                         {...props}
                //                         fields={[
                //                             {
                //                                 type: 'text',
                //                                 label: 'Quarter',
                //                                 header: 'Quarter',
                //                                 field: 'quater',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Manager',
                //                                 header: 'Manager',
                //                                 field: 'reporting_to.name',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Project',
                //                                 header: 'Project',
                //                                 field: 'project.project',
                //                             },
                //                             {
                //                                 field: 'overall_rating',
                //                                 label: 'Overall Rating',
                //                                 header: 'Overall Rating',
                //                                 width: 120,
                //                                 type: 'text'
                //                             },
                //                             {
                //                                 type: 'number',
                //                                 placeholder: 'Project Time Perc',
                //                                 field: 'project_time_perc',
                //                                 header: 'Project Time(DAYS)',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'cooprative',
                //                                 label: 'Cooperative',
                //                                 header: 'Cooperative',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'delivery_quality',
                //                                 label: 'Delivery Quality',
                //                                 header: 'Delivery Quality',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'punctuality_rating',
                //                                 label: 'Punctuality',
                //                                 header: 'Punctuality',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'following_processes_rating',
                //                                 label: 'Following Processes',
                //                                 header: 'Following Processes',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'communucations_rating',
                //                                 label: 'Communications',
                //                                 header: 'Communications',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'responsible_rating',
                //                                 label: 'Responsibility',
                //                                 header: 'Responsibility',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'given_training_rating',
                //                                 label: 'Given Training',
                //                                 header: 'Given Training',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'attitude',
                //                                 label: 'Attitude',
                //                                 header: 'Attitude',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'taken_training_rating',
                //                                 label: 'Taken Training',
                //                                 header: 'Taken Training',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 field: 'certifications_rating',
                //                                 label: 'Certifications',
                //                                 header: 'Certifications',
                //                                 type: 'text',
                //                                 width: 120,
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Comments',
                //                                 field: 'comments',
                //                                 header: 'Comments',
                //                             },
                //                         ]}
                //                     />
                //                 );
                //             },
                //         },
                //     ]
                // },
                {
                    label: 'Project Manager Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'reporting_assessment_details_new',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Quarter',
                                                header: 'Quarter',
                                                field: 'quater',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Manager',
                                                header: 'Manager',
                                                field: 'reporting_to.name',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Project',
                                                header: 'Project',
                                                field: 'project.project',
                                            },
                                            {
                                                field: 'overall_rating',
                                                label: 'Overall Rating',
                                                header: 'Overall Rating',
                                                width: 180,
                                                type: 'text',
                                            },
                                            {
                                                type: 'number',
                                                placeholder: 'Project Time Perc',
                                                field: 'project_time_perc',
                                                header: 'Project Time(DAYS)',
                                                width: 150,
                                            },
                                            {
                                                field: 'delivery_quality',
                                                label: 'Delivery Quality',
                                                header: 'Delivery Quality',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'communucations_rating',
                                                label: 'Communications',
                                                header: 'Communications',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'punctuality_rating',
                                                label: 'Cultural & Behavioural',
                                                header: 'Cultural & Behavioural',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                field: 'attitude',
                                                label: 'Learning & Sharing',
                                                header: 'Learning & Sharing',
                                                type: 'text',
                                                width: 180,
                                            },
                                            {
                                                type: 'text',
                                                label: 'Comments',
                                                field: 'comments',
                                                header: 'Comments',
                                            },
                                            //   {
                                            //     type : 'action',
                                            //     header:'Action',
                                            //     text : "View Detail",
                                            //     action: ({item}) => ({
                                            //       onPress : ({navigation}) => {
                                            //         navigation.navigate({ view: 'pm-edit-appraisal-requests', params: { item: item , isEditable : false} })
                                            //       }
                                            //     })

                                            //   }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Resource Management Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical" && values.is_feedback_process_new && values.rmFeedbackGeneratedNew) {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'resourceManagementDetails',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'number',
                                                label: 'Training Given',
                                                header: 'Training Given',
                                                field: 'rm_training_given_score',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Certification',
                                                header: 'Certification',
                                                field: 'certification_score',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Employee Rejection',
                                                header: 'Employee Rejection',
                                                field: 'number_of_rejection',
                                                editable: false,
                                                width: 180,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Interview Rejection',
                                                header: 'Interview Rejection',
                                                field: 'number_of_interview_rejection',
                                                editable: false,
                                                width: 180,
                                                visible: ({ values }) => {
                                                    const item = row;
                                                    if (item && item.employee && item.employee.employee_level && item.employee.employee_level.level && item.employee.employee_level.level < 4) {
                                                        return true
                                                    }
                                                }
                                            },
                                            {
                                                type: 'number',
                                                label: 'Mentorship',
                                                field: 'mentorship',
                                                header: 'Mentorship',
                                                editable: false
                                            }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'Processed Manually By HR',
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'processed_manually_by_hr',
                            label: 'Processed Manually',
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            size: 6,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                        },
                    ]
                },
                {
                    label: 'Amount',
                    columnsPerRow: 2,
                    groups: [
                        {
                            columns: [
                                {
                                    field: 'overall_grading_peopleManager',
                                    placeholder: "Overall Grading",
                                    required: true,
                                    size: 6,
                                    label: 'Overall Grading',
                                    type: 'text'
                                },
                                {
                                    field: 'people_manager_innovation_score',
                                    placeholder: "Innovation Score",
                                    required: true,
                                    size: 6,
                                    label: 'Innovation Score',
                                    type: 'number'
                                },
                                {
                                    type: 'textArea',
                                    field: 'people_manager_comment',
                                    placeholder: "Comment",
                                    label: "Comments",
                                    size: 6,
                                    required: true,
                                },
                                {
                                    render: () => {
                                        const { employee: { employee_level = {} } = {} } = row;
                                        const { level } = employee_level;
                                        let text = 5
                                        if (level >= 4) {
                                            text = 10
                                        }
                                        return (
                                            <Text
                                                style={{
                                                    fontSize: 14,
                                                    color: ERROR_HIGH,
                                                }}>
                                                {`Out of ${text}`}
                                            </Text>
                                        );
                                    },
                                },
                                {
                                    type: 'text',
                                    field: 'employee.increment_type',
                                    placeholder: 'Increment Type',
                                    label: 'Increment Type',
                                    size: 6,
                                },
                                {
                                    type: 'date',
                                    field: 'effective_date',
                                    placeholder: 'EFFECTIVE DATE',
                                    label: 'EFFECTIVE DATE',
                                    size: 6,
                                },
                                // {
                                //     type: 'number',
                                //     label: 'Min Market Correction',
                                //     field: 'market_correction_min',
                                //     size: 6,
                                // },
                                // {
                                //     type: 'number',
                                //     field: 'market_correction',
                                //     label: 'Max Market Correction',
                                //     size: 6,
                                // },
                                // {
                                //     type: 'number',
                                //     field: 'unit_revised_amt_min',
                                //     label: 'Unit Manager Min Amount',
                                //     size: 6,
                                // },
                                {
                                    type: 'number',
                                    field: 'unit_revised_amt_max',
                                    label: 'Unit Manager Amount',
                                    size: 6,
                                },
                                {
                                    type: 'number',
                                    field: 'appraisal_amount',
                                    label: 'Manager Offered Amount',
                                    size: 6,
                                },
                                // {
                                //     type: 'number',
                                //     field: 'min_unitManager_yearlyIncentive',
                                //     placeholder: 'Min Yearly Incentive',
                                //     label: 'Min Yearly Incentive',
                                //     label: 'Min Yealy Incentive',
                                //     size: 6,
                                // },
                                {
                                    type: 'number',
                                    field: 'unitManager_yearlyIncentive',
                                    placeholder: 'Yearly Incentive',
                                    label: 'Yearly Incentive',
                                    placeholder: 'Yearly Incentive',
                                    size: 6,
                                },
                                // {
                                //     type: 'number',
                                //     field: 'min_unit_biannual_amount',
                                //     placeholder: 'Amount',
                                //     label: 'Min Biannual Amount',
                                //     size: 6,
                                // },
                                {
                                    type: 'number',
                                    field: 'unit_biannual_amount',//bonus_amount
                                    placeholder: 'Amount',//Bonus Amount
                                    label: 'Biannual Amount',
                                    size: 6,
                                },
                            ],
                        },
                    ],
                },
                {
                    visible: ({ values }) => values && values.promotion_recommendation,
                    label: 'Designation',
                    fields: [
                        {
                            field: 'unit_promotion_designation',
                            api: '/designations',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'New Designations',
                            size: 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        return values && values.employee && values.employee.agreement_end_date && values.effective_date && (new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()) ? true : false || false
                    },
                    label: 'Processed Increment',
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'ifIncrement_asPer_aggrement',
                            options: [
                                { value: 'AsPerAggrement', label: 'As Per Aggrement' },
                                { value: 'OnHold', label: 'On Hold' },
                            ],
                            placeholder: 'Processed Increment',
                            label: 'Processed Increment',
                            required: true,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'OnHold',
                            type: 'date',
                            label: 'Hold Till',
                            field: 'hold_till',
                            size: 6,
                        },
                    ]
                },
                {
                    label: 'RECOMMENDATION',
                    fields: [
                        {
                            label: 'Promotion Recommendation',
                            placeholder: 'Promotion Recommendation',
                            field: "forPromotion_unit_manager",
                            size: 6,
                            // required: true,
                            type: 'radioGroup',
                            options: [
                                { value: true, label: 'Accept & Finalise Designation' },
                                { value: false, label: 'Reject Promotion' },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            label: 'Increment Recommendation',
                            placeholder: 'Increment Recommendation',
                            field: "increment_recommendation",
                            size: 6,
                            required: true,
                            type: 'radioGroup',
                            options: [
                                { value: true, label: 'Increment' },
                                { value: false, label: 'No Increment' },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            type: 'radioGroup',
                            field: 'offer_accepted_by_employee',
                            options: [
                                { value: true, label: 'Offer Accepted By Employee' },
                                { value: false, label: 'Offer Not Accepted By Employee' },
                            ],
                            placeholder: 'Offer Accepted By Employee',
                            label: 'Offer Accepted By Employee',
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            size: 6,
                            editorAsRenderer : true
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: 'Special Comments',
                            type: 'text',
                            field: 'unit_special_comments',
                            size: 6,
                        },
                    ]
                }
            ]}
            {...props}
        />
    );
};


export const PHFinalAppraisalDetail = props => {
    let {
        route: { params },
    } = props;
    let readOnly = true
    return (
        <PHFinalAppraisalForm
            header={{
                title: 'Appraisal Details'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};

export const ManagerAppraisalForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { ERROR_HIGH } = useTheme('colors');
    let { onSubmit } = useFormSubmit({
        uri: '/appraisals',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/managerappraisaldetail/${row?._id}`}
            submitAction={'Save'}
            mode="edit"
            onSubmit={onSubmit}
            beforeSubmit={({ data }) => {
                const {reporting_assessment_details , ...rest} = data;
                if (!data) {
                    throw new Error('No Changes Found');
                }
                let localUpdates = { ...rest };
                if (row && data &&
                    row.employee &&
                    row.employee.agreement_end_date &&
                    row.effective_date &&
                    new Date(row.employee.agreement_end_date).getTime() > new Date(row.effective_date).getTime() &&
                    data.ifIncrement_asPer_aggrement !== "OnHold"
                ) {
                    localUpdates["ifIncrement_asPer_aggrement"] = "AsPerAggrement";
                    localUpdates['offer_accepted_by_employee'] = true;
                    localUpdates['closed_date'] = getZeroTimeDate(new Date());
                }
                if (data && data.peopleManager_incrementType == 'Annual') {
                    localUpdates.new_effective_date = new Date(row && row.effective_date).setMonth(
                        new Date(row && row.effective_date).getMonth() + 12,
                    );
                }
                if (data && data.hasOwnProperty('promotion_recommendation') && data.promotion_recommendation == null) {
                    localUpdates['promotion_designation'] = null;
                }
                if (data && data.hasOwnProperty('increment_recommendation') && data.increment_recommendation == null) {
                    localUpdates['appraisal_amount'] = null;
                    localUpdates['people_manager_remark'] = null;
                }
                if (row && !row.increment_recommendation) {
                    localUpdates.increment_recommendation =
                        data && !data.increment_recommendation ? false : data.increment_recommendation;
                }
                if (row && !row.promotion_recommendation) {
                    localUpdates['promotion_recommendation'] =
                        data && data.hasOwnProperty('promotion_recommendation') && !data.promotion_recommendation
                            ? false
                            : data.promotion_recommendation;
                }
                if (row && row.new_effective_date) {
                    localUpdates.peopleManager_new_effective_date = row.new_effective_date;
                }

                return { data: { ...localUpdates, people_manager_appraisal_status: true } };
            }}
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Employee Code',
                            label: 'Employee Code',
                            field: 'employee.employee_code',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Increment Type',
                            label: 'Increment Type',
                            field: 'employee.increment_type',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            field: "employee.designation.name",
                            label: "Current Designations",
                            placeholder: "Current Designations",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'file',
                            field: 'employee_salary_component.offer_letter_copy_package',
                            label: 'Offer Letter Latest',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "from_date",
                            label: 'Increment From Date',
                            placeholder: 'From Date',
                            readOnly: true,
                            size: 6,
                        },
                        {
                            type: 'date',
                            field: "to_date",
                            label: 'IncrementTo Date',
                            placeholder: 'To Date',
                            readOnly: true,
                            size: 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    label: 'OVERALL RATING',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                            size: 6,
                            readOnly : false,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) <= 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            placeholder: 'Utilisation Percentage',
                            label: 'Utilisation Percentage',
                            field: 'utilisation',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            render: () => {
                                let {
                                    total_billed_hours = 0,
                                    total_available_hours = 0,
                                } = row || {};
                                let utilisation =
                                    (total_available_hours && total_billed_hours / total_available_hours) ||
                                    0;
                                utilisation *= 100;
                                let Format = value => (value && value.toFixed(2)) || 0;
                                total_billed_hours = Format(total_billed_hours || 0);
                                total_available_hours = Format(total_available_hours || 0);
                                utilisation = Format(utilisation || 0);
                                return (
                                    <>
                                        <Text style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: "#8E8E8E"
                                        }}>
                                            Utilisation
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: "#4B4B4B",
                                            }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'stretch',
                                                    minWidth: 20,
                                                    margin: 2,
                                                }}>
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_billed_hours && Number(total_billed_hours).toFixed(2)}
                                                </Text>
                                                <View style={{ height: 1, background: "#4B4B4B", }} />
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_available_hours}
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                }}>
                                                =
                                            </Text>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                    fontFamily: 'Quicksand-Regular',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                }}>
                                                {utilisation && Number(utilisation).toFixed(2)} %
                                            </Text>
                                        </View>
                                    </>
                                );
                            },
                        },
                        {
                            type: 'radioGroup',
                            label: 'Manager Rating',
                            placeholder: 'Manager Rating',
                            field: "pm_overall_rating",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'rm_overall_score',
                            placeholder: "RM Feedback",
                            label: 'RM Feedback',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'mentorship_score',
                            placeholder: "Mentorship",
                            label: 'Mentorship',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            label: 'Innovation Score',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            type: 'number',
                            field: "delivered_hours",
                            label: 'Delivered Hours',
                            placeholder: 'Delivered Hours',
                            readOnly: true,
                            sze : 6,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                    ],
                },
                {
                    label: 'Employee Self Assessment',
                    fields: [
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                            marginTop: "20px",
                                        }}> 1. Describe your significant accomplishments or contributions during this appraisal period.</Text>
                                );
                            }
                        },
                        {
                            type: 'textArea',
                            field: 'question_1_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 2. Have you successfully performed any new tasks or additional duties outside the scope of your regular responsibilities during the appraisal period? If so, please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_2_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 3. Innovation is one of our core values.Please share an instance where you have delivered an innovative solution at work during this appraisal period.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_3_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 4. Have you been deployed in any new technology or completed any certifications during the appraisal period?If yes,please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_4_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            size: 6,
                            readOnly: true,
                        },
                    ],
                },
                {
                    label: 'Project Manager Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'reporting_assessment_details_new',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Quarter',
                                                header: 'Quarter',
                                                field: 'quater',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Manager',
                                                header: 'Manager',
                                                field: 'reporting_to.name',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Project',
                                                header: 'Project',
                                                field: 'project.project',
                                            },
                                            {
                                                field: 'overall_rating',
                                                label: 'Overall Rating',
                                                header: 'Overall Rating',
                                                width: 120,
                                                type: 'text',
                                            },
                                            {
                                                type: 'number',
                                                placeholder: 'Project Time Perc',
                                                field: 'project_time_perc',
                                                header: 'Project Time(DAYS)',
                                                width: 160,
                                            },
                                            {
                                                field: 'delivery_quality',
                                                label: 'Delivery Quality',
                                                header: 'Delivery Quality',
                                                type: 'text',
                                                width: 160,
                                            },
                                            {
                                                field: 'communucations_rating',
                                                label: 'Communications',
                                                header: 'Communications',
                                                type: 'text',
                                                width: 160,
                                            },
                                            {
                                                field: 'punctuality_rating',
                                                label: 'Cultural & Behavioural',
                                                header: 'Cultural & Behavioural',
                                                type: 'text',
                                                width: 160,
                                            },
                                            {
                                                field: 'attitude',
                                                label: 'Learning & Sharing',
                                                header: 'Learning & Sharing',
                                                type: 'text',
                                                width: 160,
                                            },
                                            {
                                                type: 'text',
                                                label: 'Comments',
                                                field: 'comments',
                                                header: 'Comments',
                                            },
                                            //   {
                                            //     type : 'action',
                                            //     header:'Action',
                                            //     text : "View Detail",
                                            //     action: ({item}) => ({
                                            //       onPress : ({navigation}) => {
                                            //         navigation.navigate({ view: 'pm-edit-appraisal-requests', params: { item: item , isEditable : false} })
                                            //       }
                                            //     })

                                            //   }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    style: { flex: 1, justifyContent: 'left' },
                    render: ({ data = {} }) => {
                        return (
                            <View>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*CE: Consistently Exceeds Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*OE : Occasionally Exceeds Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*ME : Meet Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*PE : Partially Meet Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*DE : Does not Meet Expectation </Text>
                            </View>
                        );
                    },
                },
                {
                    label: 'Resource Management Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical" && values.rmFeedbackGeneratedNew) {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'resourceManagementDetails',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'number',
                                                label: 'Training Given',
                                                header: 'Training Given',
                                                field: 'rm_training_given_score',
                                                editable: false,
                                                width: 200,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Certification',
                                                header: 'Certification',
                                                field: 'certification_score',
                                                editable: false,
                                                width: 200,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Employee Rejection',
                                                header: 'Employee Rejection',
                                                field: 'number_of_rejection',
                                                editable: false,
                                                width: 200,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Interview Rejection',
                                                header: 'Interview Rejection',
                                                field: 'number_of_interview_rejection',
                                                editable: false,
                                                width: 200,
                                                visible: ({ values }) => {
                                                    const item = row;
                                                    if (item && item.employee && item.employee.employee_level && item.employee.employee_level.level && item.employee.employee_level.level < 4) {
                                                        return true
                                                    }
                                                }
                                            },
                                            {
                                                type: 'number',
                                                label: 'Mentorship',
                                                field: 'mentorship',
                                                header: 'Mentorship',
                                                editable: false
                                            }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'People Manager Overall Rating',
                    visible: ({ values }) => values && !values.unit_appraisal_status && values.department_type != "Non Technical",
                    fields: [
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            required: true,
                            size: 6,
                            label: 'Innovation Score',
                            type: 'number',
                            readOnly: false,
                            visible: ({ values }) => values && !values.unit_appraisal_status,
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            required: true,
                            size: 6,
                            label: 'Innovation Score',
                            type: 'number',
                            visible: ({ values }) => values && values.unit_appraisal_status,
                            readOnly: true
                        },
                        {
                            type: 'textArea',
                            field: 'people_manager_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            // required: true,
                        },
                        {
                            render: () => {
                                const { employee: { employee_level = {} } = {} } = row;
                                const { level } = employee_level;
                                let text = 5
                                if (level >= 4) {
                                    text = 10
                                }
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of ${text}`}
                                    </Text>
                                );
                            },
                        },
                    ]
                },
                {
                    visible: ({ values }) => values  && values.department_type === "Non Technical",
                    label: 'People Manager Overall Grading',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'overall_grading_peopleManager',
                            placeholder: "Overall Grading",
                            required: true,
                            size: 6,
                            visible: ({ values }) => values && values.unit_appraisal_status,
                            readOnly: true,
                            label: 'Overall Grading',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            type: 'radioGroup',
                            field: 'overall_grading_peopleManager',
                            placeholder: "Overall Grading",
                            required: true,
                            size: 6,
                            visible: ({ values }) => values && !values.unit_appraisal_status,
                            // readOnly: true,
                            label: 'Overall Grading',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                    ]
                },
                {
                    label : "Unit Head Grading",
                    fields : [
                        {
                            label: 'Final Unit Head Grading',
                            placeholder: 'Final Unit Head Grading',
                            field: "overall_grading_unitHeadFinal",
                            readOnly : true,
                            size: 6,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            type: 'radioGroup',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'technology_by_unit_head',
                            api: '/skills',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Technology',
                            size: 6,
                            readOnly : true,
                        },
                    ]
                },
                {
                    visible: ({ values }) => {
                        return values &&
                            values.employee &&
                            values.employee.agreement_end_date &&
                            values.effective_date &&
                            new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                            ? true
                            : false || false;
                    },
                    label: 'Processed Increment',
                    fields: [
                        {
                            type: 'autoComplete',
                            field: 'ifIncrement_asPer_aggrement',
                            size: 6,
                            options: [
                                { value: 'AsPerAggrement', label: 'As Per Aggrement' },
                                { value: 'OnHold', label: 'On Hold' },
                            ],
                            placeholder: 'Processed Increment',
                            label: 'Processed Increment',
                            required: true,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            visible: ({ values }) => {
                                return values &&
                                    values.employee &&
                                    values.employee.agreement_end_date &&
                                    values.effective_date &&
                                    new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                            },
                            readOnly: false
                        },
                        {
                            type: 'autoComplete',
                            field: 'ifIncrement_asPer_aggrement',
                            size: 6,
                            options: [
                                { value: 'AsPerAggrement', label: 'As Per Aggrement' },
                                { value: 'OnHold', label: 'On Hold' },
                            ],
                            placeholder: 'Processed Increment',
                            label: 'Processed Increment',
                            required: true,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values &&
                                    values.employee &&
                                    values.employee.agreement_end_date &&
                                    values.effective_date &&
                                    new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()) {
                                    return false
                                }
                                else {
                                    return true
                                }

                            },
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'OnHold',
                            type: 'date',
                            label: 'Hold Till',
                            field: 'hold_till',
                            size: 6,
                        },
                        {
                            visible: ({ values }) =>
                                values && values.ifIncrement_asPer_aggrement && values.ifIncrement_asPer_aggrement === 'AsPerAggrement',
                            type: 'text',
                            label: 'Package',
                            field: 'package_ifIncrement_asPer_aggrement',
                            size: 6,
                        },
                        {
                            type: 'date',
                            label: 'Exam Completion Date',
                            field: 'exam_completion_date',
                            size: 6,
                        },
                        {
                            type: 'number',
                            label: 'No Of Leaves',
                            field: 'no_of_leaves',
                            size: 6,
                        }
                    ]
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.employee && values.employee.increment_type == 'Biannual';
                        }
                    },
                    label: 'Appraisal Type - Biannual',
                    fields: [
                        {
                            visible: ({ values }) => ((values && values.unit_appraisal_status)),
                            field: "peopleManager_incrementType",
                            placeholder: "Proposed Increment Type",
                            label: "Propose Increment Type",
                            type : 'text',
                            size: 6,
                            readOnly : true
                        },
                        {
                            visible: ({ values }) => values && !values.unit_appraisal_status,
                            field: "peopleManager_incrementType",
                            placeholder: "Proposed Increment Type",
                            label: "Propose Increment Type",
                            type : 'text',
                            size: 6,
                            readOnly : false
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && !values.unit_appraisal_status;
                        }
                    },
                    label: "People's Manager Recommendation",
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'increment_recommendation',
                            options: [
                                { value: true, label: 'Increment' },
                            ],
                            placeholder: 'Increment Recommendation',
                            label: 'Increment Recommendation',
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            editorAsRenderer: true,
                        },
                        {
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'Proposed Monthly Increment',
                            size: 6,
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                ((values.employee && values.employee.increment_type == 'Annual') ||
                                    (values.employee && !values.employee.increment_type) ||
                                    values.peopleManager_incrementType == 'Annual'),
                        },
                        {
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'First Six Months Amount',
                            size: 6,
                            visible: ({ values }) => {
                                return (
                                    (values &&
                                        values.increment_recommendation &&
                                        values.employee &&
                                        values.employee.increment_type &&
                                        (values.employee.increment_type == 'Annual' ? false : true) &&
                                        (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true)) ||
                                    false
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            label: 'Remark',
                            field: 'people_manager_remark',
                            size: 6,
                            visible: ({ values }) => values && values.increment_recommendation,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'old_overall_feedback',
                            placeholder: "Overall feedback",
                            size: 6,
                            label: 'Overall feedback',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    fields: [
                        {
                            type: 'text',
                            label: 'Increment Type',
                            field: 'old_incr_type',
                            size: 6,
                        },
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                            type: 'text',
                            label: 'First Six Months Amount',
                            field: 'old_incr_amt',
                            size : 6,
                        },
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                }
                            },
                            type: 'text',
                            label: 'Increment Amount',
                            field: 'old_incr_amt',
                            size : 6,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    fields: [
                        {
                            type: 'text',
                            size : 6,
                            label: 'Remark',
                            field: 'old_incr_remark',
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    fields: [
                        {
                            type: 'date',
                            label: 'Next Effective Date',
                            size : 6,
                            field: 'old_next_effective_date',
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                        },
                        {
                            type: 'text',
                            label: 'Next Six Months Amount',
                            size : 6,
                            field: 'old_biannual_amt',
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        return (
                            (values &&
                                values.increment_recommendation &&
                                values.employee &&
                                values.employee.increment_type &&
                                (values.employee.increment_type == 'Annual' ? false : true) &&
                                (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                    ? false
                                    : true)) ||
                            false
                        );
                    },
                    fields: [
                        {
                            type: 'date',
                            field: 'peopleManager_new_effective_date',
                            label: 'Next Effective Date',
                            size : 6,
                            readOnly: true
                        },
                    ]
                },
                {
                    fields: [
                        {
                            type: 'number',
                            field: 'peopleManager_biannual_amount',
                            label: 'Next Biannual Amount',
                            size : 6,
                            visible: ({ values }) =>
                                (values &&
                                    values.increment_recommendation &&
                                    values.employee &&
                                    values.employee.increment_type &&
                                    (values.employee.increment_type == 'Annual' ? false : true) &&
                                    (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                        ? false
                                        : true)) ||
                                false,
                        },
                        {
                            type: 'textArea',
                            field: 'peopleManager_Biannual_remark',
                            size : 6,
                            label: 'Remark',
                            visible: ({ values }) =>
                                (values &&
                                    values.increment_recommendation &&
                                    values.employee &&
                                    values.employee.increment_type &&
                                    (values.employee.increment_type == 'Annual' ? false : true) &&
                                    (values.employee.increment_type == 'Biannual' && values.peopleManager_incrementType == 'Annual'
                                        ? false
                                        : true)) ||
                                false,
                        },
                        {
                            type: 'radioGroup',
                            field: 'promotion_recommendation',
                            options: [
                                { value: true, label: 'Promotion' },
                            ],
                            placeholder: 'Promotion Recommendation',
                            label: 'Promotion Recommendation',
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            editorAsRenderer: true,
                        },
                        {
                            field: "employee.designation.name",
                            label: "Current Designations",
                            placeholder: "Current Designations",
                            type: 'text',
                            size : 6,
                            readOnly: true,
                            visible: ({ values }) => values && values.promotion_recommendation,
                        },
                        {
                            field: 'promotion_designation',
                            api: '/designations',
                            type: 'autoComplete',
                            valueField: 'name',
                            suggestionField: 'name',
                            label: 'Proposed Designations',
                            size: 6,
                            readOnly: false,
                            visible: ({ values }) => values && values.promotion_recommendation
                        },
                    ]
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    readOnly: true,
                    label: 'People Manager Feedback',
                    fields: [
                        {
                            type: 'radioGroup',
                            field: 'old_overall_feedback',
                            placeholder: "Overall feedback",
                            size: 6,
                            label: 'Overall feedback',
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                    ]
                },
                // {
                //     fields: [
                //         {
                //             type: 'text',
                //             label: 'Increment Type',
                //             field: 'old_incr_type',
                //         },
                //         {
                //             visible: ({ values }) => {
                //                 if (values.unit_appraisal_status) {
                //                     return (
                //                         (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 } else {
                //                     return (
                //                         (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 }
                //             },
                //             type: 'text',
                //             label: 'First Six Months Amount',
                //             field: 'old_incr_amt',
                //         },
                //         {
                //             visible: ({ values }) => {
                //                 if (values.unit_appraisal_status) {
                //                     return (
                //                         (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                //                             ? true
                //                             : false || false) ||
                //                         (values.employee && values.employee.increment_type == 'Annual') ||
                //                         (values.employee && !values.employee.increment_type)
                //                     );
                //                 } else {
                //                     return (
                //                         (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                //                             ? true
                //                             : false || false) ||
                //                         (values.employee && values.employee.increment_type == 'Annual') ||
                //                         (values.employee && !values.employee.increment_type)
                //                     );
                //                 }
                //             },
                //             type: 'text',
                //             label: 'Increment Amount',
                //             field: 'old_incr_amt',
                //         },
                //     ]
                // },
                // {
                //     fields: [
                //         {
                //             type: 'date',
                //             label: 'Next Effective Date',
                //             field: 'old_next_effective_date',
                //             size: 6,
                //             visible: ({ values }) => {
                //                 if (values.unit_appraisal_status) {
                //                     return (
                //                         (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 } else {
                //                     return (
                //                         (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 }
                //             }
                //         },
                //     ]
                // },
                // {
                //     visible: ({ values }) => {
                //         if (
                //             values &&
                //                 values.employee &&
                //                 values.employee.agreement_end_date &&
                //                 values.effective_date &&
                //                 new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                //                 ? true
                //                 : false || false
                //         ) {
                //             return false;
                //         } else {
                //             return values && values.unit_appraisal_status;
                //         }
                //     },
                //     fields: [
                //         {
                //             type: 'text',
                //             label: 'Remark',
                //             field: 'old_biannual_remark',
                //             visible: ({ values }) => {
                //                 if (values.unit_appraisal_status) {
                //                     return (
                //                         (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 } else {
                //                     return (
                //                         (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 }
                //             }
                //         },
                //         {
                //             type: 'text',
                //             label: 'Next Six Months Amount',
                //             field: 'old_biannual_amt',
                //             visible: ({ values }) => {
                //                 if (values.unit_appraisal_status) {
                //                     return (
                //                         (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 } else {
                //                     return (
                //                         (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                //                             ? false
                //                             : true || true) &&
                //                         values.employee &&
                //                         values.employee.increment_type == 'Biannual'
                //                     );
                //                 }
                //             },
                //         },
                //     ],
                // },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    label: "Promotion Proposed",
                    fields: [
                        {
                            type: 'text',
                            label: 'Current Designation',
                            field: 'old_designation',
                        },
                        {
                            type: 'text',
                            label: 'Proposed Designation',
                            field: 'old_propsed_designation',
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                    label: 'Unit Manager Proposal',
                    fields: [
                        // {
                        //     type: 'number',
                        //     label: ' Monthly Increment Amount',
                        //     field: 'unit_revised_amt_min',
                        //     visible: ({ values }) =>
                        //         (values && values.employee && values.employee.increment_type == 'Annual') ||
                        //         (values.employee && !values.employee.increment_type),
                        //     readOnly: true,
                        //     size: 6
                        // },
                        {
                            type: 'number',
                            label: 'Monthly Increment Amount',
                            field: 'unit_revised_amt_max',
                            visible: ({ values }) =>
                                (values && values.employee && values.employee.increment_type == 'Annual') ||
                                (values.employee && !values.employee.increment_type),
                            readOnly: true,
                            size: 6
                        },
                        // {
                        //     type: 'number',
                        //     field: 'unit_revised_amt_min',
                        //     readOnly: true,
                        //     label: 'First Six Months Min Amount',
                        //     size: 6,
                        //     visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                        // },
                        {
                            type: 'number',
                            field: 'unit_revised_amt_max',
                            label: 'First Six Months Amount',
                            visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                            readOnly: true,
                            size: 6
                        },

                        // {
                        //     type: 'number',
                        //     field: 'min_unit_biannual_amount',
                        //     placeholder: 'Amount',//Bonus Amount
                        //     label: 'Min Biannual Amount',
                        //     label: 'Next Six Months Min Amount',
                        //     visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                        //     readOnly: true,
                        //     size: 6
                        // },
                        {
                            type: 'number',
                            field: 'unit_biannual_amount',//bonus_amount
                            placeholder: 'Amount',//Bonus Amount
                            label: 'Biannual Amount',
                            label: 'Next Six Months Amount',
                            visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                            readOnly: true,
                            size: 6
                        },
                        // {
                        //     type: 'text',
                        //     field: 'unitBiannual_amount_remark',
                        //     placeholder: 'Remark',
                        //     label: 'Biannual Remark',
                        //     visible: ({ values }) => values && values.employee && values.employee.increment_type == 'Biannual',
                        //     readOnly: true,
                        //     size: 6
                        // },
                        {
                            type: 'text',
                            field: 'unitManager_incrementType',
                            label: 'Increment Type',
                            readOnly: true,
                            size: 6
                        },
                        // {
                        //     type: 'number',
                        //     readOnly: true,
                        //     label: 'Min Market Correction',
                        //     field: 'market_correction_min',
                        //     size: 6
                        // },
                        // {
                        //     type: 'number',
                        //     readOnly: true,
                        //     label: 'Max Market Correction',
                        //     field: 'market_correction',
                        //     size: 6
                        // },
                        // {
                        //     type: 'number',
                        //     field: 'min_unitManager_yearlyIncentive',
                        //     placeholder: 'Yearly Incentive Min',
                        //     label: 'Yearly Incentive Min',
                        //     label: 'Min Yearly Incentive Amount',
                        //     readOnly: true,
                        //     size: 6
                        // },
                        {
                            type: 'number',
                            field: 'unitManager_yearlyIncentive',
                            placeholder: 'Yearly Incentive',
                            label: 'Yearly Incentive',
                            label: 'Max Yearly Incentive Amount',
                            readOnly: true,
                            size: 6
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                    fields: [
                        {
                            type: 'textArea',
                            label: 'Special Remark',
                            field: 'unit_special_comments',
                            readOnly: true,
                        },
                    ]
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                    fields: [
                        {
                            label: 'Promotion Status',
                            type: 'text',
                            field: 'forPromotion_unit_manager',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            label: 'New Designation',
                            type: 'text',
                            field: 'unit_promotion_designation.name',
                            size: 6,
                            readOnly: true,
                        },
                    ]
                },
                {
                    // visible:({data})=>data && data.unit_appraisal_status,
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    label: 'People Manager (Revised)',
                    fields: [
                        {
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'Monthly Amount',
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                }
                            },
                        },
                        {
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'First Six Months Amount',
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                        },
                        {
                            type: 'textArea',
                            label: 'Remark',
                            field: 'people_manager_remark',
                            visible: ({ values }) => values && values.increment_recommendation,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (
                            values &&
                                values.employee &&
                                values.employee.agreement_end_date &&
                                values.effective_date &&
                                new Date(values.employee.agreement_end_date).getTime() > new Date(values.effective_date).getTime()
                                ? true
                                : false || false
                        ) {
                            return false;
                        } else {
                            return values && values.unit_appraisal_status;
                        }
                    },
                    fields: [
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                            type: 'number',
                            field: 'peopleManager_biannual_amount',
                            label: 'Next Six Months Amount',
                        },
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                            type: 'textArea',
                            field: 'peopleManager_Biannual_remark',
                            label: 'Remark'
                        },
                    ]
                },
            ]}
            {...props}
        />
    );
};


export const ManagerAppraisalDetail = props => {
    let {
        route: { params },
    } = props;
    const {tab} = params
    let readOnly = true;
    if(tab === "Awaiting Response" || tab === "TeamPending"){
        readOnly = false;
    }
    return (
        <ManagerAppraisalForm
            header={{
                title: 'Appraisal Details'
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};

export const ChangePeopleManagerForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={
                { employee_people_manager: { name: 1 } }
            }
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    field: 'employee_people_manager',
                    api: '/employeeSuggestions',
                    type: 'autoComplete',
                    label: 'Select People Manager',
                    searchField: 'name',
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    required: true,
                    size: 4,
                },
            ]}
            {...props}
        />
    );
};

export const ChangePeopleManager = props => {
    return <ChangePeopleManagerForm header="Update People Manager" {...props} />;
};


export const ExtendAppraisalForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });

    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            defaultValues={{
                ifIncrement_asPer_aggrement: 'Extend',
            }}
            // fields={
            //     { employee_people_manager: { name: 1 } }
            // }
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type : 'date',
                    field : "extended_till",
                    label : "Extended Till"
                }
            ]}
            {...props}
        />
    );
};

export const ExtendAppraisal = props => {
    return <ExtendAppraisalForm header="Update People Manager" {...props} />;
};

export const ChangePracticeHeadForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={
                { practice_head: { name: 1 } }
            }
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    field: 'practice_head',
                    api: '/employeeSuggestions',
                    type: 'autoComplete',
                    label: 'Select Practice Head',
                    searchField: 'name',
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    required: true,
                    size: 4,
                },
            ]}
            {...props}
        />
    );
};

export const ChangePracticeHead = props => {
    return <ChangePracticeHeadForm header="Update Practice Head" {...props} />;
};

export const EmployeeAppraisalAcceptedForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={
                {
                    employee_people_manager: { name: 1 },
                    increment_recommendation: 1,
                    unitManager_incrementType: 1,
                    employee: {
                        increment_type: 1
                    },
                    unit_revised_amt_min: 1,
                    unit_revised_amt_max: 1,
                    min_unit_biannual_amount: 1,
                    unit_biannual_amount: 1,
                    market_correction_min: 1,
                    market_correction: 1,
                    unitManager_yearlyIncentive: 1,
                    min_unitManager_yearlyIncentive: 1,
                    peopleManager_biannual_amount: 1,
                    appraisal_amount: 1,
                    people_manager_market_correction: 1,
                    peopleManager_yearlyIncentive: 1
                }
            }
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/hrappraisalemails',
                    props: { appraisalDetail: row, updates: data },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    lable: "Unit Manager Proposal",
                    fields: [
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Annual' ||
                                    (values.employee && values.employee.increment_type == 'Annual') ||
                                    (values.employee && !values.employee.increment_type)),
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'unit_revised_amt_min',
                            label: 'Min Monthly Proposed Amount',
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Annual' ||
                                    (values.employee && values.employee.increment_type == 'Annual') ||
                                    (values.employee && !values.employee.increment_type)),
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'unit_revised_amt_max',
                            label: 'Max Monthly Proposed Amount',
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Biannual' ||
                                    (values.employee && values.employee.increment_type == 'Biannual')),
                            type: 'number',
                            editable: false,
                            size: 6,
                            field: 'unit_revised_amt_min',
                            label: 'Min First Six Months Amount',
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Biannual' ||
                                    (values.employee && values.employee.increment_type == 'Biannual')),
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'unit_revised_amt_max',
                            label: 'Max First Six Months Amount',
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Biannual' ||
                                    (values.employee && values.employee.increment_type == 'Biannual')),
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'min_unit_biannual_amount',
                            label: 'Min Next Six Months Amount',
                        },
                        {
                            visible: ({ values }) =>
                                values &&
                                values.increment_recommendation &&
                                (values.unitManager_incrementType == 'Biannual' ||
                                    (values.employee && values.employee.increment_type == 'Biannual')),
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'unit_biannual_amount',
                            label: 'Max Next Six Months Amount',
                        },
                        {
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            label: 'Min Market Correction',
                            field: 'market_correction_min',
                        },
                        {
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            label: 'Max Market Correction',
                            field: 'market_correction',
                        },
                        {
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'min_unitManager_yearlyIncentive',
                            label: 'Min Yearly Incentive',
                        },
                        {
                            type: 'number',
                            readOnly: true,
                            size: 6,
                            field: 'unitManager_yearlyIncentive',
                            label: 'Max Yearly Incentive ',
                        },
                    ]
                },
                {
                    fields: [
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? true
                                            : false || false) ||
                                        (values.employee && values.employee.increment_type == 'Annual') ||
                                        (values.employee && !values.employee.increment_type)
                                    );
                                }
                            },
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'Monthly Proposed Amount',
                        },
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                            type: 'number',
                            field: 'appraisal_amount',
                            label: 'First Six Months Amount',
                        },
                        {
                            visible: ({ values }) => {
                                if (values.unit_appraisal_status) {
                                    return (
                                        (values.unitManager_incrementType && values.unitManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                } else {
                                    return (
                                        (values.peopleManager_incrementType && values.peopleManager_incrementType == 'Annual'
                                            ? false
                                            : true || true) &&
                                        values.employee &&
                                        values.employee.increment_type == 'Biannual'
                                    );
                                }
                            },
                            type: 'number',
                            field: 'peopleManager_biannual_amount',
                            label: 'Next Six Months Amount',
                        },
                        {
                            type: 'number',
                            label: 'Market Correction',
                            field: 'people_manager_market_correction',
                        },
                        {
                            type: 'number',
                            field: 'peopleManager_yearlyIncentive',
                            label: 'Yearly Incentive',
                        },
                    ],
                }
            ]}
            {...props}
        />
    );
};

export const EmployeeAppraisalAccepted = props => {
    return <EmployeeAppraisalAcceptedForm header="Update People Manager" {...props} />;
};


export const ProcessAppraisalManuallyForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                unit_special_comments: 1
            }}
            mode='edit'
            onSubmit={onSubmit}
            beforeSubmit={({ data }) => {
                let { offer_accepted_by_employee } = row || {};
                const update = {};
                if (offer_accepted_by_employee) {
                    update["processed_manually_by_hr"] = true
                }
                else {
                    update["processed_manually_by_hr"] = true
                    update["offer_accepted_by_employee"] = true
                }
                return {
                    data: {
                        ...update,
                        ...data,
                    }
                };
            }}
            submitAction="Save"
            defaultValues={{
                employee_people_manager: row?.employee_people_manager,
            }}
            layoutFields={[
                {
                    field: "unit_special_comments",
                    type: "textArea",
                    placeholder: "Comments",
                    label: "Comments",
                    minHeight: 90,
                    maxHeight: 90
                },
            ]}
            {...props}
        />
    );
};

export const ProcessAppraisalManually = props => {
    return <ProcessAppraisalManuallyForm header="Process Manually" {...props} />;
};


export const CloseAppraisalManuallyForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                increment_letter_latest_from_date: 1,
                increment_letter_latest_to_date: 1,
                increment_letter_latest: 1,
                unit_special_comments: 1
            }}
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/closeappraisalrequestmanually',
                    props: { appraisalDetail: row, updates: data },
                });
            }}
            beforeSubmit={({ data }) => {
                let { offer_accepted_by_employee } = row || {};
                const update = {};
                if (offer_accepted_by_employee) {
                    update["processed_manually_by_hr"] = true
                    update["hrPayroll_closed_status"] = true
                }
                else {
                    update["processed_manually_by_hr"] = true
                    update["hrPayroll_closed_status"] = true
                    update["offer_accepted_by_employee"] = true
                }
                return {
                    data: {
                        ...update,
                        ...data,
                        closed_date: getZeroTimeDate(new Date()),
                        status:"Closed",
                        hrPayroll_closed_status: true,
                    }
                };
            }}
            submitAction="Save"
            defaultValues={{
                employee_people_manager: row?.employee_people_manager,
            }}
            layoutFields={[
                {
                    type: 'date',
                    field: 'increment_letter_latest_from_date',
                    placeholder: 'From Date',
                    label: 'From Date',
                    required: true,
                },
                {
                    type: 'date',
                    field: 'increment_letter_latest_to_date',
                    placeholder: 'To Date',
                    label: 'To Date',
                    required: true,
                },
                {
                    type: 'file',
                    label: 'Increment Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'increment_letter_latest',
                    required: true,
                    options: { bucketName: 'manaze' },
                },
                {
                    field: "unit_special_comments",
                    type: "textArea",
                    placeholder: "Comments",
                    label: "Comments",
                }
            ]}
            {...props}
        />
    );
};

export const CloseAppraisalManually = props => {
    return <CloseAppraisalManuallyForm header="Close Manually" {...props} />;
};

export const CloseAppraisalRequestForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                increment_letter_latest: 1
            }}
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/closeappraisalrequest',
                    props: { appraisalDetail: row, updates: data },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'file',
                    label: 'Increment Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'increment_letter_latest',
                    required: true,
                    options: { bucketName: 'manaze' },
                },

            ]}
            {...props}
        />
    );
};

export const closeAppraisalRequest = props => {
    return <CloseAppraisalRequestForm header="Close Appraisal" {...props} />;
};

export const ResendAppraisalLetterForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                increment_letter_latest: 1
            }}
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/resendappraisalletter',
                    props: { appraisalDetail: row, updates: data },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'file',
                    label: 'Increment Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'increment_letter_latest',
                    required: true,
                    // options: { bucketName: 'manaze' },
                },
            ]}
            {...props}
        />
    );
};

export const ResendAppraisalLetter = props => {
    return <ResendAppraisalLetterForm header="Resend Letter" {...props} />;
};


export const SendAppraisalLetterForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                increment_letter_latest_from_date: 1,
                increment_letter_latest_to_date: 1,
                increment_letter_latest: 1
            }}
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/hrappraisalemails',
                    props: { appraisalDetail: row, updates: data, extraData: 'HrPayroll_Call' },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'date',
                    field: 'increment_letter_latest_from_date',
                    placeholder: 'From Date',
                    label: 'From Date',
                    required: true,
                },
                {
                    type: 'date',
                    field: 'increment_letter_latest_to_date',
                    placeholder: 'To Date',
                    label: 'To Date',
                    required: true,
                },
                {
                    type: 'file',
                    label: 'Increment Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'increment_letter_latest',
                    required: true,
                    options: { bucketName: 'manaze' },
                },
            ]}
            {...props}
        />
    );
};

export const SendAppraisalLetter = props => {
    return <SendAppraisalLetterForm header="Send Appraisal Letter" {...props} />;
};


export const UploadHrLetterForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                current_offer_letter: 1,
            }}
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'file',
                    label: 'Current Offer Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'current_offer_letter',
                    required: true,
                    options: { bucketName: 'manaze' },
                },
            ]}
            {...props}
        />
    );
};

export const HRUploadLetter = props => {
    return <UploadHrLetterForm header="Send Appraisal Letter" {...props} />;
};


export const AddRemarkForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                remark_closed_appraisal: 1,
            }}
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'text',
                    field: 'remark_closed_appraisal',
                    placeholder: 'Add Remark',
                    label: 'Add Remark',
                    required: true,
                },
            ]}
            {...props}
        />
    );
};

export const AddRemark = props => {
    return <AddRemarkForm header="Add Remark" {...props} />;
};

export const AddAttachmentForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                attachment: 1,
            }}
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'file',
                    label: 'Attachment',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'attachment',
                    required: true,
                    options: { bucketName: 'manaze' },
                },
            ]}
            {...props}
        />
    );
};

export const AddAppraisalAttachment = props => {
    return <AddAttachmentForm header="Add Attachment" {...props} />;
};

export const ChangeCloseDateForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: `/appraisals`,
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                closed_date: 1,
            }}
            mode='edit'
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'date',
                    field: 'closed_date',
                    placeholder: 'Close date',
                    label: 'Close date',
                    required: true,
                },
            ]}
            {...props}
        />
    );
};

export const ChangeCloseDate = props => {
    return <ChangeCloseDateForm header="Update Close Date" {...props} />;
};

export const EditIncementDetailForm = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'Appraisal',
    });
    return (
        <Form
            api={`/getAppraisalDetails/${row?._id}`}
            fields={{
                increment_letter_latest_from_date: 1,
                increment_letter_latest_to_date : 1,
                increment_letter_latest : 1
            }}
            mode='edit'
            onSubmit={data => {
                invoke({
                    uri: '/editincrementdetail',
                    props: { appraisalDetail: row, updates: data },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'date',
                    field: 'increment_letter_latest_from_date',
                    placeholder: 'From Date',
                    label: 'From Date',
                    required: true,
                },
                {
                    type: 'date',
                    field: 'increment_letter_latest_to_date',
                    placeholder: 'To Date',
                    label: 'To Date',
                    required: true,
                },
                {
                    type: 'file',
                    label: 'Increment Letter',
                    placeholder: 'Drag and drop Increment Letter here',
                    field: 'increment_letter_latest',
                    required: true,
                    options: { bucketName: 'manaze' },
                },
            ]}
            {...props}
        />
    );
};

export const EditIncementDetail = props => {
    return <EditIncementDetailForm header="Edit Increment Datails" {...props} />;
};


export const DownloadAnnualAppraisalLetter = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let appraisalId = row?._id;
    const { user, download } = useAppStateContext();

    return (
        <Form
            {...props}
            onSubmit={data => {
                download({
                    uri: `/downloadAnnualAppraisalPackage`,
                    props: {
                        appraisalId,
                        ...data,
                    },
                });
            }}
            submitAction={'Download'}
            layoutFields={[
                {
                    label: 'On Letter Head',
                    placeholder: 'On Letter Head',
                    field: 'on_letter_head',
                    type: 'checkbox',
                },
            ]}
        />
    );
};

export const DownloadAnnualAppraisalLetterForm = props => {
    let {
        route: { params },
    } = props;
    const { row } = params;
    return (
        <DownloadAnnualAppraisalLetter
            header={{
                title: 'Download Letter',
            }}
            {...props}
        />
    );
};


export const DownloadBiAnnualAppraisalLetter = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let appraisalId = row?._id;
    const { user, download } = useAppStateContext();

    return (
        <Form
            {...props}
            onSubmit={data => {
                download({
                    uri: `/downloadBianualAppraisalPackage`,
                    props: {
                        appraisalId,
                        ...data,
                    },
                });
            }}
            submitAction={'Download'}
            layoutFields={[
                {
                    label: 'On Letter Head',
                    placeholder: 'On Letter Head',
                    field: 'on_letter_head',
                    type: 'checkbox',
                },
            ]}
        />
    );
};

export const DownloadBiAnnualAppraisalLetterForm = props => {
    let {
        route: { params },
    } = props;
    const { row } = params;
    return (
        <DownloadBiAnnualAppraisalLetter
            header={{
                title: 'Download Letter',
            }}
            {...props}
        />
    );
};

export const AddToApprasialForm = props => {
    const {route: {params} = {}} = props;
  
    const {row, key, ...rest} = params;
  
    const invoke = useInvoke({
      method: 'post',
    });
    return (
      <Form
        onSubmit={data => {
          invoke({
            uri: '/invoke',
            props: {data, from_employee: true,...rest},
          });
        }}
        submitAction="Save"
        layoutFields={[
          {
            type: 'date',
            field: 'effective_date',
            label: 'Effective Date',
            size: 6,
          },
        ]}
        {...props}
      />
    );
  };
  
  export const AddEmployeeToApprasial = props => {
    const {route = {}} = props;
    return (
      <AddToApprasialForm
        header="Add To Appaisal"
        {...props}
      />
    );
  };

export const SetExceptionalShift = props => {
    const { route: { params } = {} } = props;

    const { row, key, ...rest } = params;

    const invoke = useInvoke({
        method: 'post',
    });
    return (
        <Form
            onSubmit={data => {
                invoke({
                    uri: '/invoke',
                    props: { data, ...rest },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'radioGroup',
                    field: 'is_exceptional_shift',
                    required: true,
                    options: [
                        { value: true, label: 'Exceptional' },
                        { value: false, label: 'Not Exceptional' },
                    ],
                },
            ]}
            {...props}
        />
    );
};

export const SetExceptionalShiftForm = props => {
    const { route = {} } = props;
    return (
        <SetExceptionalShift
            header="Set Exceptional Shift"
            {...props}
        />
    );
};