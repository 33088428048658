import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssignTechPerson = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  const {opportunity} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        ...opportunity,
        status: 'Active',
        rfq_owner_assign_date: getZeroTimeDate(new Date()),
        sub_stage: 'New',
        sub_stage_date: new Date(),
        new_date: new Date(),
      }}
      onSubmit={onSubmit}
      header={'Assign'}
      submitAction={'Update'}
      layoutFields={[
        {
          placeholder: 'Solution Consultant',
          label: 'Solution Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Primary Consultant',
          label: 'Primary Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // size: 6,
        },
        {
          label: 'Pre Sales',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          placeholder: 'Practice',
          label: 'Practice',
          field: 'practice',
          type: 'multiAutoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const AssignTechPersonOnActive = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });

  return (
    <Form
      {...props}
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Assign Technical Consultant'}
      submitAction={'Assign'}
      layoutFields={[
        {
          placeholder: 'Technical Consultant',
          label: 'Technical Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Solution Consultant',
          label: 'Solution Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // size: 6,
        },
        {
          placeholder: 'Business Analyst',
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const AssignOwnerOnUnassignedRFQ = props => {
  const params = props.route.params;
  const {opportunity} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={props => {
        onSubmit(props);
      }}
      defaultValues={{
        _id: opportunity?._id,
        owner: opportunity?.owner,
      }}
      header={'Update Owner'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Owner',
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const AssignPreSalesOnUnAssignedRFQ = props => {
  const params = props.route.params;
  const {opportunity} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={props => {
        onSubmit(props);
      }}
      defaultValues={{
        _id: opportunity?._id,
        rfq_owner: opportunity?.rfq_owner,
        rfq_owner_assign_date: getZeroTimeDate(new Date()),
        // sub_stage: "New",
        practice: opportunity?.practice,
      }}
      header={'Assign Buisness Consultant'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Buisness Consultant',
          label: 'Buisness Consultant',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Practice',
          field: 'practice',
          type: 'multiAutoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const AssignBAOnUnAssignedRFQ = props => {
  const params = props.route.params;
  const {opportunity} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={props => {
        onSubmit(props);
      }}
      defaultValues={{
        _id: opportunity?._id,
        buisness_analyst: opportunity?.buisness_analyst,
        buisness_analyst_assign_date: getZeroTimeDate(new Date()),
      }}
      header={'Assign Business Analyst'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Business Analyst',
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const AssignSolutionConsultants = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  const {opportunity} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        ...opportunity,
        primary_owner: opportunity?.primary_owner,
        tech_person: opportunity?.tech_person,
        buisness_analyst: opportunity?.buisness_analyst,
        ux_consultant: opportunity?.ux_consultant,
        buisness_analyst_assign_date: getZeroTimeDate(new Date()),
        rfq_owner: opportunity?.rfq_owner,
        rfq_owner_assign_date: getZeroTimeDate(new Date()),
        // sub_stage: "New",
        practice: opportunity?.practice,
        status: 'Active',
        sub_stage_date: new Date(),
        sub_stage: 'New',
        new_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      header={'Assign Consultants'}
      submitAction={'Assign'}
      layoutFields={[
        {
          placeholder: 'Techniacal Consultant',
          label: 'Techniacal Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Solution Consultant',
          label: 'Solution Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // size: 6,
        },
        {
          placeholder: 'Business Analyst',
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          placeholder: 'Buisness Consultant',
          label: 'Buisness Consultant',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          placeholder: 'Ux Consultant',
          label: 'Ux Consultant',
          field: 'ux_consultant',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // size: 6,
        },
        {
          label: 'Practice',
          field: 'practice',
          type: 'multiAutoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const UpdatePracticeOnActiveRFQ = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Update Practice'}
      submitAction={'Update'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Practice',
          label: 'Practice',
          field: 'practice',
          type: 'multiAutoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateSolutionConsultant = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Update Solution Consultant'}
      submitAction={'Update'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Solution Consultant',
          label: 'Solution Consultant',
          field: 'solution_consultant',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const UpdatePreSales = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Update Solution Consultant'}
      submitAction={'Update'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Pre Sales',
          label: 'Pre Sales',
          field: 'rfq_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const UpdateOwner = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Update Solution Consultant'}
      submitAction={'Update'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Owner',
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};
