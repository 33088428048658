import {useTheme} from '@unthinkable/react-theme';
import {AddButton} from '../../../components/button/AddButton';
import {Button} from '../../../components/button/Button';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {
  RecieptAdvanceTable,
  RecieptAdvanceTableAm,
  RecieptAllTable,
  RecieptAllTableAm,
} from './RecieptTable';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const RecieptTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter(
    {},
  );
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let filterParams = {};

  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }
  let {filter} = filterValues;
  const groupBy = useGroupBy({
    options: [
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'AM',
        field: 'account_owner_id',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const tabs = {
    receipt_all: {
      label: 'All',
      view: (
        <RecieptAllTable
          {...props}
          filterParams={filterParams}
          searchValue={searchValue}
          filterValues={filterValues}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'receipt',
      api: '/receipt/all',
      addOnFilter: {...filterValues.filter},
      limit: 2000,
      search: searchValue,
      searchFields: ['receipt_number'],
      actions: [
        <Button
          text="Receipt Report"
          onPress={() => {
            download({
              uri: `/exportreceiptsreport`,
              props: {
                addOnFilter: {...filter},
              },
            });
          }}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportreceipts',
              props: {
                addOnFilter: {...filter},
              },
            });
          }}
        />,
      ],
    },

    advance: {
      label: 'Advance',
      view: (
        <RecieptAdvanceTable
          {...props}
          filterParams={filterParams}
          searchValue={searchValue}
          filterValues={filterValues}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'receipt',
      api: '/receipt/advance',
      addOnFilter: {...filterValues.filter},
      search: searchValue,
      searchFields: ['receipt_number'],
      actions: [
        <Button
          text="Receipt Report"
          onPress={() => {
            download({
              uri: `/exportreceiptsreport`,
              props: {
                addOnFilter: {receipt_type: 'a', ...filter},
              },
            });
          }}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportreceipts',
              props: {
                addOnFilter: {receipt_type: 'a', ...filter},
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Receipts Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'receipt_type',
              label: 'Receipt Type',
              options: [
                {
                  label: 'Advance',
                  value: 'a',
                },
                {
                  label: 'Revenue',
                  value: 'r',
                },
              ],
              placeholder: 'Select',
              valueField: 'value',
              suggestionField: 'label',
              keyField: 'label',
            },
            {
              type: 'date',
              field: 'receipts_date',
              label: 'Receipt Date',
              placeholder: 'Receipt Date',
            },
            {
              label: 'AM',
              field: 'account_owner_id',
              type: 'autoComplete',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              valueField: 'name',
              suggestionField: 'name',
              placeholder: 'select',
            },
            {
              type: 'autoComplete',
              label: 'Payment mode',
              field: 'payment_mode',
              options: ['Cash', 'Cheque', 'RTGS/NEFT', 'Transfer Wise'],
              placeholder: 'select',
            },
            {
              type:"autoComplete",
              label:"Category",
              field:"category",
              api:"/categories",
              placeholder:"Select",
              suggestionField:"name",
              valueField:"name"
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'multiAutoComplete',
              label: 'Department',
              api: '/activeDepartmentSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'department',
            },
            {
              type: 'multiAutoComplete',
              label: 'Bank',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'fund_id',
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <AddButton
          title="Receipt"
          view="add-revenue-receipt"
          params={params}
        />,
      ]}
    />
  );
};

export const RecieptTabAm = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter(
    {},
  );
  let filterParams = {};

  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          // title: 'name',
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();

  const tabs = {
    receipt_all: {
      label: 'All',
      view: (
        <RecieptAllTableAm
          {...props}
          filterParams={filterParams}
          searchValue={searchValue}
          filterValues={filterValues}
          groupBy={groupBy}
        />
      ),
      filter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
      eventSourceId: 'receipt',
      api: '/receipt/all',
      limit: 2000,
      search: searchValue,
      searchFields: ['receipt_number'],
    },

    advance: {
      label: 'Advance',
      view: (
        <RecieptAdvanceTableAm
          {...props}
          filterParams={filterParams}
          searchValue={searchValue}
          filterValues={filterValues}
          groupBy={groupBy}
        />
      ),
      filter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
      eventSourceId: 'receipt',
      api: '/receipt/advance',
      search: searchValue,
      searchFields: ['receipt_number'],
    },
    // fd: {
    //   label: 'FD',
    //   view: (
    //     <RecieptTable
    //       filterParams={filterParams}
    //       addOnFilter={{
    //         receipt_type: 'o',
    //       }}
    //       searchValue={searchValue}
    //       {...props}
    //     />
    //   ),
    //   addOnFilter: {
    //     receipt_type: 'o',
    //   },
    //   eventSourceId: 'receipt',
    //   api: '/receipts',
    //   search: searchValue,
    //   searchFields: ['receipt_number'],
    // },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Receipts Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'receipt_type',
              label: 'Receipt Type',
              options: [
                {
                  label: 'Advance',
                  value: 'a',
                },
                {
                  label: 'Revenue',
                  value: 'r',
                },
              ],
              placeholder: 'Select',
              valueField: 'value',
              suggestionField: 'label',
              keyField: 'label',
            },
            {
              type: 'date',
              field: 'receipts_date',
              label: 'Receipt Date',
              placeholder: 'Receipt Date',
            },
            {
              type: 'autoComplete',
              label: 'Payment mode',
              field: 'payment_mode',
              options: ['Cash', 'Cheque', 'RTGS/NEFT', 'Transfer Wise'],
              placeholder: 'select',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <AddButton
          title="Receipt"
          view="add-revenue-receipt"
          params={params}
        />,
      ]}
    />
  );
};
