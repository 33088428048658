import React from 'react';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {Tag} from '../../../components/chip/Chip';
import {AddButton} from '../../../components/button/AddButton';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

const EventTaskAssignmentTable = props => {
  const {
    navigation,
    route: {params},
    isCompleted,
    searchValue,
  } = props;
  const {parent_model} = params;

  let filter = {
    parent_model: parent_model?._id,
    parent_model_relation: 'Event',
  };

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MasterTaskAssignment',
    close: false,
  });

  return (
    <Table
      filter={filter}
      eventSourceId={['TaskActions', 'MasterTaskAssignment']}
      api={`/mastertaskassignments`}
      searchFields="task"
      search={searchValue}
      sort={{_id: -1}}
      fields={{
        task: 1,
        thread: {
          name: 1,
        },
        team: {
          name: 1,
          color: 1,
        },
        start_date: 1,
        est_hours: 1,
        acceptance_criteria: 1,
        reviewer: {
          name: 1,
          color: 1,
        },
        owner_wise_estimates: {
          owner: {
            name: 1,
          },
          est_hours: 1,
        },
        primary_source_id: {
          name: 1,
        },
        status: 1,
        assignee_mode: 1,
        auto_assignee: 1,
        review_not_required: 1,
        event_on: 1,
        event_field: 1,
        event_field_value: 1,
        output_model: {
          label: 1,
          name: 1,
          color: 1,
        },
        output_model_id: 1,
        output_model_view: 1,
        parent_model: {name: 1, label: 1, color: 1},
        due_days: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-event-task-assignment`, {
          ...params,
          row,
          parent_model: row?.parent_model,
        });
      }}
      columns={[
        {
          header: 'Tasks',
          type: 'text',
          render: ({row}) => {
            let {task, start_date} = row;
            start_date = start_date
              ? moment(start_date).format('DD MMM YY')
              : void 0;
            let value = start_date ? `Start Date: ${start_date}` : void 0;
            return (
              <PrimaryColumnCard
                numberOfLines={2}
                primaryTitle={task}
                items={[
                  {
                    value: value,
                  },
                ]}
              />
            );
          },
        },
        {
          type: 'textArea',
          field: 'thread.name',
          header: 'Project',
          width: 250,
          align: 'left',
        },
        {
          type: 'colorTag',
          field: 'output_model.label',
          colorField: 'output_model.color',
          header: 'Output',
          width: 250,
          align: 'center',
        },
        {
          header: 'Assignee Mode',
          type: 'text',
          render: ({row}) => {
            let {assignee_mode} = row;

            let assigneeMode =
              assignee_mode &&
              (assignee_mode === 'Common'
                ? {value: 'Open Task', color: 'Accent1'}
                : {value: 'Assigned Task', color: 'Accent3'});

            if (!assigneeMode) {
              return null;
            }

            return (
              <Tag value={assigneeMode.value} color={assigneeMode.color} />
            );
          },
          width: 200,
          align: 'center',
        },
        {
          header: 'Assignees',
          field: 'team',
          type: 'userAvatarGroup',
          width: 150,
          align: 'center',
        },
        {
          header: 'Reviewers',
          field: 'reviewer',
          type: 'userAvatarGroup',
          width: 150,
          align: 'center',
        },
        {
          header: 'Checklist',
          render: CountCellRender,
          isNestedObject: true,
          count_field: 'acceptance_criteria.length',
          onPress: isCompleted
            ? void 0
            : ({row}) => {
                navigation.navigate('assignment-checklist-form', {
                  ...params,
                  row,
                });
              },
          align: 'center',
          width: 80,
        },
      ]}
      moreActions={[
        {
          title: 'Mark Archive',
          confirm: {
            title: 'Mark Archive',
            message: 'Task of this assignment will not be created',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/mastertaskassignments/${row?._id}`,
              props: {
                status: 'Completed',
              },
            });
          },
          visible: ({row}) =>
            row?.status == 'Active' || row?.status == 'InActive',
        },
        {
          title: 'Mark Active',
          onPress: ({row}) => {
            invoke({
              uri: `/mastertaskassignments/${row?._id}`,
              props: {
                status: 'Active',
              },
            });
          },
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          visible: ({row}) => row?.status == 'Completed',
        },
      ]}
      {...props}
    />
  );
};

export const EventTaskAssignmentTabs = props => {
  const {
    route: {params},
  } = props;
  const {filter, onChangeFilter, searchValue} = useFilter();
  const {parent_model} = params;
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'Active',
          api: '/mastertaskassignments',
          search: searchValue,
          searchFields: 'task',
          filter: {
            ...filter,
            status: 'Active',
            parent_model: parent_model?._id,
            parent_model_relation: 'Event',
          },
          view: (
            <EventTaskAssignmentTable
              addOnFilter={{...filter, status: 'Active'}}
              searchValue={searchValue}
            />
          ),
          actions: [
            <AddButton
              {...props}
              title={'Assignment'}
              view="add-event-task-assignment"
              params={{...params, parent_model}}
            />,
          ],
        },
        completed: {
          label: 'Archived ',
          api: '/mastertaskassignments',
          search: searchValue,
          searchFields: 'task',
          filter: {
            ...filter,
            status: 'Completed',
            parent_model: parent_model?._id,
            parent_model_relation: 'Event',
          },
          view: (
            <EventTaskAssignmentTable
              isCompleted
              addOnFilter={{...filter, status: 'Completed'}}
              searchValue={searchValue}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
