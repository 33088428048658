import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const TypesForm = props => {
  const {
    route: {params},
  } = props;
  const {typeId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/types',
    eventSourceId: 'Type',
  });
  return (
    <Form
      api={`/types/${typeId}`}
      fields={{
        name: 1,
        label: 1,
        display_field: 1,
        list_view: 1,
        add_view: 1,
        is_show: 1,
        departments: {name: 1},
        task_assignment_type: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
        {
          type: 'text',
          field: 'label',
          label: 'Label',
        },
        {
          type: 'text',
          field: 'display_field',
          label: 'Display Field',
        },
        {
          type: 'text',
          field: 'list_view',
          label: 'List View',
        },
        {
          type: 'text',
          field: 'add_view',
          label: 'Form View',
        },
        {
          type: 'switch',
          field: 'is_show',
          label: 'Show',
        },
        {
          label: 'Department',
          field: 'departments',
          type: 'multiAutoComplete',
          api: '/activeDepartmentSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Task Assignment Type',
          field: 'task_assignment_type',
          type: 'autoComplete',
          options: ['Product', 'Project'],
        },
      ]}
      {...props}
    />
  );
};

export const AddTypesForm = props => {
  return <TypesForm header="Add Entity" {...props} />;
};

export const UpdateTypesForm = props => {
  return <TypesForm mode="edit" header="Update Entity" {...props} />;
};
