import React from 'react';
import {SHORT_NAMES} from '../../common/constants/SourceConstants';
import {Tag} from '../../../components/chip/Chip';
import { useViewTheme } from '@unthinkable/react-theme';
import { RowWithSeparator } from '@unthinkable/react-layout';

const TaskSourceRender = ({sourceField, sourceIdField, row, onChipPress}) => {
  const { colors } = useViewTheme();
  let sourceValue = row[sourceField];
  let sourceValueName = sourceValue?.name || '';
  let sourceValueColor = sourceValue?.color || '';
  let sourceValueId = sourceValue?._id || '';

  const sourceConstantValue = SHORT_NAMES[sourceValueId] || sourceValueName;

  let sourceIdValue = row[sourceIdField];
  sourceIdValue = sourceIdValue?.name || '';

  if (!sourceConstantValue && !sourceIdValue) {
    return null;
  }
  let textValue = '';
  if (sourceIdValue) {
    textValue += `${sourceIdValue}`;
  }
  textValue += ` (${sourceConstantValue})`;

  return (
    <>
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <Tag value={textValue} color={sourceValueColor} onPress={onChipPress} />
        {row?.is_rework ? (
          <Tag
            value={`Rework`}
            color={colors.ACCENT2_LOW}
            textColor={colors.ACCENT2_HIGH}
          />
        ) : null}
      </RowWithSeparator>
    </>
  );
};

export default TaskSourceRender;
