import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { InProcessProjectFeedbackTable, NotRelevantProjectFeedbackTable, PendingProjectFeedbackTable, ProcessedProjectFeedbackTable } from './TeamProjectFeedbackTable';

const TeamProjectFeedbackTab = props => {
    let { practice_head, reporting_to, user = {}, route: { params = {} } = {} , ...rest} = props || {};
    const { applyFilter, filterValues, searchValue, onChangeFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    const addOnFilter = {};
    if (practice_head) {
        addOnFilter["practice_head"] = practice_head
    }
    if (reporting_to) {
        addOnFilter["reporting_to"] = reporting_to
    }
    let tabs = {};
    tabs['PendingProjectFeedback'] = {
      label: 'Pending Submission',
      eventSourceId: 'PendingProjectFeedback',
      view: (
        <PendingProjectFeedbackTable
          searchValue={searchValue}
          filterValues={filterValues}
          addOnFilter={{
            ...filterValues.filter,
            ...addOnFilter,
            is_not_relevent: {$in: [null, false]},
            is_processed: {$in: [null, false]},
            is_project_manager_feedback_given: {$in: [null, false]},
            $and: [
              {rm_feedback_generated: {$in: [null, false]}},
              {rm_feedback_generated_new: {$in: [null, false]}},
            ],
            to_be_approved: {$in: [null, false]},
          }}
          user={user}
          {...rest}
        />
      ),
      addOnFilter: {
        ...filter,
        ...addOnFilter,
        is_not_relevent: {$in: [null, false]},
        is_processed: {$in: [null, false]},
        is_project_manager_feedback_given: {$in: [null, false]},
        $and: [
            {rm_feedback_generated: {$in: [null, false]}},
            {rm_feedback_generated_new: {$in: [null, false]}},
          ],
        to_be_approved: {$in: [null, false]},
      },
      api: '/employeeprojectfeedback',
    };

    tabs['InProcessProjectFeedback'] = {
        label: 'Pending Approval',
        eventSourceId: 'InProcessProjectFeedback',
        view: <InProcessProjectFeedbackTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                ...addOnFilter,
                is_project_manager_feedback_given: { $in: [null, false] },
                $and: [
                    {rm_feedback_generated: {$in: [null, false]}},
                    {rm_feedback_generated_new: {$in: [null, false]}},
                  ],
                to_be_approved: true,
                is_processed: { $in: [null, false] },
            }}
            user={user}
            {...rest} />,
        addOnFilter: {
            ...filter,
            ...addOnFilter,
            is_project_manager_feedback_given: { $in: [null, false] },
            $and: [
                {rm_feedback_generated: {$in: [null, false]}},
                {rm_feedback_generated_new: {$in: [null, false]}},
              ],
            to_be_approved: true,
            is_processed: { $in: [null, false] },
        },
        api: '/employeeprojectfeedback',
    }

    tabs['ProcessedProjectFeedback'] = {
      label: 'Closed',
      eventSourceId: 'ProcessedProjectFeedback',
      view: (
        <ProcessedProjectFeedbackTable
          searchValue={searchValue}
          filterValues={filterValues}
          addOnFilter={{
            ...filterValues.filter,
            ...addOnFilter,
            $or: [
              {is_project_manager_feedback_given: true},
              {is_processed: true},
            ],
            $and: [
              {rm_feedback_generated: {$in: [null, false]}},
              {rm_feedback_generated_new: {$in: [null, false]}},
            ],
          }}
          user={user}
          {...rest}
        />
      ),
      addOnFilter: {
        ...filter,
        ...addOnFilter,
        $or: [{is_project_manager_feedback_given: true}, {is_processed: true}],
        $and: [
          {rm_feedback_generated: {$in: [null, false]}},
          {rm_feedback_generated_new: {$in: [null, false]}},
        ],
      },
      api: '/employeeprojectfeedback',
    };

    tabs['NotRelevant'] = {
        label: 'Not Relevant',
        eventSourceId: 'PendingProjectFeedback',
        view: <NotRelevantProjectFeedbackTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                ...addOnFilter,
                is_not_relevent: true,
            }}
            user={user}
            {...rest}
        />,
        addOnFilter: {
            ...filterValues.filter,
            ...addOnFilter,
            is_not_relevent: true,
        },
        api: '/employeeprojectfeedback',
    }

    return <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
            <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                    {
                        type: 'multiAutoComplete',
                        label: 'Employee',
                        api: '/employeeSuggestions',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        secondarySuggestionField: 'official_email_id',
                        valueField: 'name',
                        field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
                    },
                    {
                        type: 'autoComplete',
                        label: 'Financial Year',
                        api: '/FinancialYears',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'financial_year',
                    },
                ]}
            />,
        ]}
    />;
};

export const ProjectFeedbackTab = props => {
    let { user = {}, route: { params = {} } = {} , ...rest} = props || {};
    const { filterValues, searchValue} = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    const addOnFilter = {};
    const {assesment_id} = params
    if (assesment_id) {
        addOnFilter["assesment_id"] = assesment_id
    }
    let tabs = {};
    tabs['ProjectFeedback'] = {
        label: 'Project Feedback',
        eventSourceId: 'ProjectFeedback',
        view: <ProcessedProjectFeedbackTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                ...addOnFilter,
                is_not_relevent: { $in: [null, false] },
                $and: [
                    {rm_feedback_generated: {$in: [null, false]}},
                    {rm_feedback_generated_new: {$in: [null, false]}},
                  ],
            }}
            user={user}
            {...rest}
        />,
        addOnFilter:{
            ...filterValues.filter,
            ...addOnFilter,
            is_not_relevent: { $in: [null, false] },
            $and: [
                {rm_feedback_generated: {$in: [null, false]}},
                {rm_feedback_generated_new: {$in: [null, false]}},
              ],
        },
        api: '/employeeprojectfeedback',
    }

    return <TabView
        tabs={tabs}
        // params={params}
        {...rest}
    />;
};




export default TeamProjectFeedbackTab;
