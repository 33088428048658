import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';

const RenderPromotionRecommendationActions = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'Promotion',
    close: false,
  });
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            invoke({
              uri: `/promotiondetails/${row._id}`,
              props: {
                promotion_status: 'accepted_by_reporting_to',
                promotion_closed_by_reporting_to: new Date(),
                pending_by_ph: true,
              },
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            Approve
          </Text>
        </TouchableOpacity>
        <LineSeparator />

        <TouchableOpacity
          onPress={() => {
            navigation.navigate('reject-promotion-recommendation', {
              row,
              forReject: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
              overflow: 'hidden',
            }}>
            Reject
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

const RenderPromotionRecommendationActionsPH = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'Promotion',
    close: false,
  });
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            invoke({
              uri: `/promotiondetails/${row._id}`,
              props: {
                promotion_status: 'accepted_by_ph',
                promotion_closed_by_ph: new Date(),
              },
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            Approve
          </Text>
        </TouchableOpacity>
        <LineSeparator />

        <TouchableOpacity
          onPress={() => {
            navigation.navigate('reject-promotion-recommendation-ph', {
              row,
              forReject: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
              overflow: 'hidden',
            }}>
            Reject
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

export const PendingPromotionsManager = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/team-pending'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 150,
          type: 'date',
          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderPromotionRecommendationActions
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
    />
  );
};

export const ClosedPromotionsManager = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/team-closed'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 150,
          type: 'date',
          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },

        // {
        //   header: 'Accepted on',
        //   width: 120,
        //   field: 'promotion_closed_by_reporting_to',
        //   formatOptions: {format: 'DD MMM YY'},
        //   //   render: RenderDueDate,
        // },

        {
          header: 'Status',
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (
              row?.promotion_status === 'accepted_by_reporting_to' ||
              row?.promotion_status === 'accepted_by_ph'
            ) {
              color = '#34C759';
              status = 'Approved';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Approved'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_reporting_to).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else if (row?.status === 'rejected_by_reporting_to') {
              color = '#FF5247';
              status = 'Rejected';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_reporting_to).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: 'Remark',
          type: 'text',
          field: 'rejection_remark',
        },
      ]}
    />
  );
};

export const PendingPromotionsPH = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/ph-pending'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 120,
          type: 'date',

          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Accepted by Manager',
          width: 150,
          type: 'date',

          field: 'promotion_closed_by_reporting_to',
          formatOptions: {format: 'DD MMM YY'},
        },

        {
          header: 'Project Reportee',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
        },
        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderPromotionRecommendationActionsPH
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
    />
  );
};

export const ClosedPromotionByPH = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['Probation']}
      searchFields={['name']}
      api={'/promotion-recommendation/ph-closed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 150,
          type: 'date',
          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Manager date',
          width: 120,
          type: 'date',
          field: 'promotion_closed_by_reporting_to',
          formatOptions: {format: 'DD MMM YY'},
        },

        {
          header: 'Project Reportee',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 150,
        },
        {
          header: 'Status',
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (row?.promotion_status === 'accepted_by_ph') {
              color = '#34C759';
              status = 'Approved';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Approved'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_ph).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else if (row?.status === 'rejected_by_ph') {
              color = '#FF5247';
              status = 'Rejected';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_ph).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: 'Remark',
          type: 'text',
          field: 'rejection_remark',
        },
      ]}
    />
  );
};

export const PendingPromotionsHr = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/hr-pending'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion date',
          width: 150,
          type: 'date',
          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Project Reportee',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
        },
        {
          header: 'Practice Head',
          field: 'practice_head',
          type: 'userAvatar',
          align: 'center',
          width: 200,
        },
      ]}
    />
  );
};

export const PendingByPhPromotionsHr = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/hr-pending-by-ph'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 120,
          type: 'date',

          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Accepted by Manager',
          width: 150,
          type: 'date',

          field: 'promotion_closed_by_reporting_to',
          formatOptions: {format: 'DD MMM YY'},
        },

        {
          header: 'Project Reportee',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
        },
        {
          header: 'Practice Head',
          field: 'practice_head',
          type: 'userAvatar',
          align: 'center',
          width: 200,
        },
      ]}
    />
  );
};

export const ClosedPromotionHR = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {promoteEmployee, promoteEmployeeFromRecommendation} =
    employeeController(props);
  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['Probation']}
      searchFields={['name']}
      api={'/promotion-recommendation/hr-closed-promotions'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },
        {
          header: 'Eligible Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },

        {
          header: 'Promotion due',
          width: 150,
          type: 'date',

          field: 'promotion_due_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Manager Date',
          width: 150,
          type: 'date',
          field: 'promotion_closed_by_reporting_to',
          formatOptions: {format: 'DD MMM YY'},
        },

        {
          header: 'Project Reportee',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 120,
        },
        {
          header: 'Practice Head',
          field: 'practice_head',
          type: 'userAvatar',
          align: 'center',
          width: 120,
        },
        {
          header: 'Status',
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (row?.promotion_status === 'accepted_by_ph') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Approved'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_ph).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else if (row?.status === 'rejected_by_ph') {
              color = '#FF5247';
              status = 'Rejected';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_ph).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            } else if (row?.status === 'rejected_by_reporting_to') {
              color = '#FF5247';
              status = 'Rejected';
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.promotion_closed_by_reporting_to).format(
                      'DD MMM, YY',
                    )}`}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: 'Remark',
          type: 'text',
          field: 'rejection_remark',
        },
      ]}
      moreActions={() => [
        {
          title: 'Promote employee',
          onPress: promoteEmployeeFromRecommendation,
          visible: ({row}) => {
            if (row?.promotion_status === 'accepted_by_ph') {
              return true;
            } else {
              return false;
            }
          },
        },
      ]}
    />
  );
};

export const HRFinalPromotedEmployees = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/hr-promoted-employees'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },

        {
          header: 'Promotion date',
          width: 150,
          type: 'date',
          field: 'new_promotion_from_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Closed date',
          width: 150,
          type: 'date',
          field: 'promotion_closed_on',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
      ]}
    />
  );
};

export const SelfPromotionView = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['Promotion']}
      searchFields={['name']}
      api={'/promotion-recommendation/self-promotion'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Current Designation',
          type: 'text',
          field: 'designation.name',
          //   width: 200,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'oldDesignation.name',
          //   width: 200,
        },

        {
          header: 'Promotion date',
          width: 150,
          type: 'date',
          field: 'new_promotion_from_date',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
        {
          header: 'Closed date',
          width: 150,
          type: 'date',
          field: 'promotion_closed_on',
          formatOptions: {format: 'DD MMM YY'},
          //   render: RenderDueDate,
        },
      ]}
    />
  );
};
