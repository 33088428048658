import React from 'react';
import {InlineForm} from '../../../../components/form/Form';
import {Table} from '../../../../components/table/Table';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID} from '../../../common/constants/SourceConstants';
import {ThirdPartyIntegrationDetailForm} from './ThirdPartyIntegrationForm';
import {SplitScreen} from '../../../../components/SplitScreen';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {
  ThirdPartyIntegrationTable,
  thirdPartyIntergrationExportParams,
} from './ThirdPartyIntegrationScreen';
import {TabView} from '../../../../components/tab';
import {TableHeader} from '../../../../components/table/Headers';
import {useFilter} from '../../../../controllers/useFilter';
import {SearchFilter} from '../../../../components/filter/Filters';
import {GroupBy} from '../../../../components/table/GroupBy';

const ThirdPartyIntegrationInlineForm = props => {
  const {
    route: {params = {}},
    navigation,
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'ThirdPartyIntegration',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params;

  const defaultValues = {
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Name',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}/suggestions`,
          suggestionField: 'name',
          valueField: 'name',
          searchFields: ['name'],
          required: true,
          filter: {_id: {$nin: sourceIds}},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-third-party-integration`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

export const FeaturesThirdPartyIntegrationTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    status,
    selectedId,
  } = props;

  const {feature} = params;

  const EVENT_SOURCE_ID = [
    'Feature',
    'Task',
    'ThirdPartyIntegration',
    'MapUserstory',
  ];

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}/asset-data`,
  };

  const {
    assetDetail,
    tasks,
    removeFromFeature,
    updateArtifactStatus,
    versions,
    changeModule,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/projectThirdPartyIntegrations',
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}
          assetInlineForm={<ThirdPartyIntegrationInlineForm {...props} />}
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={thirdPartyIntergrationExportParams({
            ...tableProps,
            fromFeature: true,
          })}
          {...headerProps}
        />
      )}
      eventSourceId={EVENT_SOURCE_ID}
      selection
      onRowPress={assetDetail}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.tasks({onPress: tasks}),
        !selectedId && assetColumns.est_hrs,
        !selectedId && assetColumns.assignees,
        !selectedId && assetColumns.complexity,
        !selectedId && assetColumns.artifact_status,
      ]}
      moreActions={[
        versions,
        changeModule,
        updateArtifactStatus,
        removeFromFeature,
      ]}
      {...tableProps}
    />
  );
};

const FeatureThirdPartyIntegrationScreen = props => {
  return (
    <SplitScreen splitScreen={<ThirdPartyIntegrationDetailForm {...props} />}>
      <FeaturesThirdPartyIntegrationTable {...props} status={'released'} />
    </SplitScreen>
  );
};

export const AllThirdPartyIntegrationScreen = props => {
  const {
    route: {params},
    api,
    groupBy,
  } = props;

  const {project, feature, module} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField =
    assetMappings[PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}/all-data`,
    addOnFilter: filterValues.filter,
    search: searchValue,
    searchFields: sourceField,
    filter: {
      module_id: module?._id,
    },
    params: {
      show_in_development: !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={thirdPartyIntergrationExportParams({
              ...tableProps,
              fromFeature: true,
            })}
          />,
          <AssetAddButton
            title={'Third Party Integration'}
            view={'add-third-party-integration'}
            params={params}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ThirdPartyIntegrationDetailForm {...props} />}>
      <ThirdPartyIntegrationTable
        {...props}
        renderHeader={renderHeader}
        groupBy={groupBy}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureThirdPartyIntegrationTab = props => {
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureThirdPartyIntegrationScreen {...props} />,
        },
        all: {
          label: 'All',
          view: <AllThirdPartyIntegrationScreen {...props} />,
        },
      }}
    />
  );
};
