import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter, PeriodRangeMonthWiseBreakupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { UserAvatarWithNameAndEmployeeCode } from '../../reimbursement/components/ReimbursementColumnRenders';

export const SalaryReviewReport = props => {
    let { navigation } = props;
    const { filterValues, onChangeFilter,applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { params: filterParams, filter } = filterValues;
    const { period } = filterParams;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    return (
        <Table
            {...props}
            api={'/salaryreviewreport'}
            params={{...filterParams , ...filter}}
            variant={'bordered'}
            limit={2000}
            renderHeader={() => (
                <TableHeader
                    title="Salary Review"
                    actions={[
                        <PeriodRangeMonthWiseBreakupFilter
                            onChangeFilter={onChangeFilter}
                            filterValues={filterValues}
                        />,
                        <GroupFilter
                            filterValues={filterValues}
                            applyFilter={applyFilter}
                            filters={[
                                {
                                    type: 'multiAutoComplete',
                                    label: 'Employee',
                                    api: '/employeeSuggestions',
                                    suggestionField: 'name',
                                    secondarySuggestionField: 'official_email_id',
                                    valueField: 'name',
                                    titleField: 'name',
                                    field: 'employee',
                                    searchFields: ['name', 'official_email_id', 'employee_code'],
                                    filter : {$in : ["Active", "Inactive"]}
                                },
                                {
                                    type: 'multiAutoComplete',
                                    label: 'Organization',
                                    api: '/organizations',
                                    placeholder: 'Select',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    field: 'organization',
                                },
                            ]}
                        />,
                        <IconButton
                            icon={Download}
                            onPress={() => {
                                download({
                                    uri: '/downloadsalaryreviewreport',
                                    props: {
                                        ...filterParams,
                                        ...filter
                                    },
                                });
                            }}
                        />,
                    ]}
                />
            )}
            columns={[
                {
                    header: 'Employee',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameAndEmployeeCode
                                name={row?.name}
                                color={row?.color}
                                employee_code={row?.employee_code}
                            />
                        );
                    },
                    width: 200,
                },
                {
                    header: 'Fixed CTC',
                    align: 'right',
                    type: 'currency',
                    field: 'employee_salary_component.fixed_salary',
                    minWidth: 130,
                },
                {
                    header: 'Variable',
                    type: 'currency',
                    align: 'right',
                    field: 'employee_salary_component.incentive_salary',
                    minWidth: 130,
                },
                {
                    header: 'Package Start Date',
                    type: 'date',
                    align: 'right',
                    field: 'employee_salary_component.from_date',
                    minWidth: 150,
                },
                {
                    header: 'Current Month',
                    children: [
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'currentMonthFixedSalary',
                            align: 'right',
                            header: 'Fixed',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'currentMonthIncentiveSalary',
                            align: 'right',
                            header: 'Incentive',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'currentMonthBonusSalary',
                            align: 'right',
                            header: 'Bonus',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'currentMonthTotal',
                            align: 'right',
                            header: 'Total',
                            width: 150,
                        },
                        {
                            field: 'currentMonthTotalSalaryDays',
                            align: 'right',
                            header: 'Salary Days',
                            width: 150,
                        },
                    ],
                },
                {
                    header: 'Previous Month',
                    children: [
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'previousMonthFixedSalary',
                            align: 'right',
                            header: 'Fixed',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'previousMonthIncentiveSalary',
                            align: 'right',
                            header: 'Incentive',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'previousMonthBonusSalary',
                            align: 'right',
                            header: 'Bonus',
                            width: 150,
                        },
                        {
                            aggregate: true,
                            type: 'currency',
                            field: 'previousMonthTotal',
                            align: 'right',
                            header: 'Total',
                            width: 150,
                        },
                        {
                            field: 'previousMonthTotalSalaryDays',
                            align: 'right',
                            header: 'Salary Days',
                            width: 150,
                        },
                    ],
                },
                {
                    aggregate: true,
                    type: 'currency',
                    field: 'arrearFixedSalary',
                    header: 'Fixed Arrear',
                    align: 'right',
                    width: 150,
                },
                {
                    aggregate: true,
                    type: 'currency',
                    field: 'arrearIncentiveSalary',
                    header: 'Incentive Arrear',
                    align: 'right',
                    width: 150,
                },
            ]}
        />
    );
};
