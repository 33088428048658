import React from 'react';

import {getPreviousMonth} from '@unthinkable/react-filters';

import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {ReleasedFeaturesTable, WipFeaturesTable} from './FeaturesTable';
import {FeatureStatus} from '../constants/FeatureStages';
import {ProjectStoryPointTable} from './ProjectStoryPointTable';

export const ProjectReportTab = props => {
  const {route: {params} = {}, navigation, addOnFilter = {}} = props;
  const {project, milestone, module, projectlibrary_id} = params;

  const {searchValue, onChangeFilter, filterValues} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });

  let {filter, params: {sub_status, ...filterParams} = {}} = filterValues || {};

  const countFilter = {
    project_id: project?._id,
    milestone_id: milestone?._id,
    module_id: module?._id,
    library_id: projectlibrary_id,
    ...filter,
    ...addOnFilter,
  };

  filter = {
    ...filter,
    ...addOnFilter,
  };

  const countProps = {
    search: searchValue,
    searchFields: ['feature', 'code'],
    params: filterParams,
    eventSourceId: 'Feature',
  };

  const tabs = {
    released: {
      label: FeatureStatus.released.label,
      view: (
        <ReleasedFeaturesTable
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: FeatureStatus.released.value,
            library_id: projectlibrary_id,
          }}
          extraParams={{...filterParams}}
        />
      ),
      ...countProps,
      api: `/projects/${project?._id}/features/released-report`,
      filter: {
        ...countFilter,
      },
      params: {
        ...filterParams,
      },
    },

    wip: {
      label: 'WIP',
      view: (
        <WipFeaturesTable
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.released.value,
            library_id: projectlibrary_id,
          }}
          extraParams={{...filterParams}}
        />
      ),
      ...countProps,
      api: `/projects/${project?._id}/features/wip`,
      filter: {
        ...countFilter,
      },
      params: filterParams,
    },

    storyPoint: {
      label: 'Story Point',
      view: (
        <ProjectStoryPointTable
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.released.value,
            library_id: projectlibrary_id,
          }}
          extraParams={{...filterParams}}
        />
      ),
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={() => {
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            asParam
          />,
        ];
      }}
    />
  );
};
