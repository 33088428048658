import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';

export const MarkEmployeeInactive = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const defaultValues = {
    _id: row?._id,
    resign_date: row?.resign_date_new,
    relieving_date: row?.expected_relieving_date,
    employee_status: 'Inactive',
  };
  return (
    <Form
      defaultValues={{...defaultValues}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Resign Date',
              label: 'Resign Date',
              field: 'resign_date',
              type: 'date',
              required: true,
            },
            {
              placeholder: 'Relieving Date',
              label: 'Relieving Date',
              field: 'relieving_date',
              type: 'date',
              required: true,
            },
            {
              placeholder: 'Remarks',
              label: 'Remarks',
              field: 'remarks',
              type: 'text',
            },
            {
              label: 'Exit Status',
              placeholder: 'Exit Status',
              field: 'exit_status',
              type: 'autoComplete',
              options: [
                'Inactive',
                'Abscond',
                'Shortcoming',
                'Terminate',
                'Service Agreement breach',
                'Early exit',
              ],
            },
            {
              label: 'SA Breach Status',
              placeholder: 'SA Breach Status',
              field: 'sa_breach_status',
              type: 'autoComplete',
              size: 3,
              options: ['Legal SA Breach', 'HR SA Breach'],
              visible: ({values}) => {
                if (
                  values &&
                  values.exit_status &&
                  values.exit_status == 'Service Agreement breach'
                ) {
                  return true;
                }
              },
            },
            {
              placeholder: 'Attachment',
              label: 'Attachment',
              field: 'exit_attachment',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          fields: [
            {
              placeholder: 'Is Rehireable',
              label: 'Is Rehireable',
              field: 'is_rehireable',
              type: 'checkbox',
              size: 6,
            },
            {
              placeholder: 'Is Voluntary',
              label: 'Is Voluntary',
              field: 'is_voluntary_exit',
              type: 'checkbox',
              size: 6,
            },
            {
              placeholder: 'Is Alumni',
              label: 'Is Alumni',
              field: 'is_alumni',
              type: 'checkbox',
              size: 6,
            },
            {
              placeholder: 'Manual Exit',
              label: 'Manual Exit',
              field: 'is_manual_exit',
              type: 'checkbox',
              size: 6,
            },
            {
              placeholder: 'Is Transfer Case',
              label: 'Is Transfer Case',
              field: 'is_transfer_case',
              type: 'checkbox',
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const MarkInactive = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkEmployeeInactive
      header={{
        title: 'Mark Employee Inactive',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeRelievingDate = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'EmployeeAssignment',
    // throwError: true,
  });
  return (
    <Form
      onSubmit={data => {
        invoke({
          uri: '/updaterelievingdate',
          props: {data: {employeeId: row?._id, ...data}},
        });
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Relieving Date',
              label: 'Relieving Date',
              field: 'expected_relieving_date',
              type: 'date',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateEmployeeRelievingDateInactiveEmployee = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const defaultValues = {
    _id: row?._id,
    relieving_date: row?.relieving_date,
  };
  return (
    <Form
      defaultValues={{...defaultValues}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Relieving Date',
              label: 'Relieving Date',
              field: 'relieving_date',
              type: 'date',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateExitTypeForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'ResignationActive',
    ...props,
  });
  return (
    <Form
      submitAction={'Save'}
      defaultValues={{
        exit_type: row?.assigned_to?.exit_type,
        _id: row?._id,
        // ...row,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              type: 'radioGroup',
              field: 'exit_type',
              options: [
                {
                  label: 'Voluntary',
                  value: 'Voluntary',
                },
                {
                  label: 'Involuntary',
                  value: 'Involuntary',
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateExitStatusForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'ResignationActive',
    ...props,
  });
  return (
    <Form
      submitAction={'Save'}
      defaultValues={{
        exit_type: row?.exit_type,
        desireable_status: row?.desireable_status,
        _id: row?._id,
        // ...row,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              type: 'radioGroup',
              field: 'exit_type',
              label: 'Exit type',
              options: [
                {
                  label: 'Voluntary',
                  value: 'Voluntary',
                },
                {
                  label: 'Involuntary',
                  value: 'Involuntary',
                },
              ],
            },
            {
              type: 'radioGroup',
              field: 'desireable_status',
              label: 'Desirable stauts',
              options: [
                {label: 'Desirable', value: 'Desirable'},
                {label: 'Regrettable', value: 'Regrettable'},
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateDesirableStatusForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'ResignationActive',
    ...props,
  });
  return (
    <Form
      submitAction={'Save'}
      defaultValues={{
        desireable_status: row?.assigned_to?.desireable_status,
        _id: row?._id,
        // ...row,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              type: 'radioGroup',
              field: 'desireable_status',
              options: [
                {label: 'Desirable', value: 'Desirable'},
                {label: 'Regrettable', value: 'Regrettable'},
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateRelievingDate = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateEmployeeRelievingDate
      header={{
        title: 'Update Relieving Date',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateRelievingDateInactive = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateEmployeeRelievingDateInactiveEmployee
      header={{
        title: 'Update Relieving Date',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateExittype = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateExitTypeForm
      header={{
        title: 'Mark voluntary/involuntary',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateExitStatus = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateExitStatusForm
      header={{
        title: 'Update',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateResignReasonForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'ResignationActive',
    ...props,
  });
  return (
    <Form
      submitAction={'Save'}
      defaultValues={{
        resign_reason: row?.assigned_to?.resign_reason,
        _id: row?._id,
        // ...row,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              label: 'Resign reason',
              field: 'resign_reason',
              type: 'autoComplete',
              api: '/resignreasons',
              suggestionField: 'reason',
              valueField: 'reason',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const ResignationMeetingRemark = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'ResignationActive',
    ...props,
  });
  console.log("row>>>>",row)
  return (
    <Form
      submitAction={'Save'}
      defaultValues={{
        resign_meeting_remark: row?.resign_meeting_remark,
        _id: row?._id,
        // ...row,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              label: 'Remark',
              field: 'resign_meeting_remark',
              type: 'text',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateResignReason = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateResignReasonForm
      header={{
        title: 'Update resign reason',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const AddResignationMeetingRemark = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ResignationMeetingRemark
      header={{
        title: 'Update Remark',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateDesirableStatus = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateDesirableStatusForm
      header={{
        title: 'Update desirable status',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};
