import {
  ActiveCandidateTabs,
  ClosedCandidateTabs,
  DraftCandidateTabs,
} from '../recruitment/views/AllCandidateTabs';
import {JobOpeningTab} from '../recruitment/views/JobOpeningsTab';
import {
  ManPowerRequisitionTab,
  MrfApproverTab,
} from '../recruitment/views/ManPowerRequisitionTab';
import {
  AllCandidateFeedbackTabs,
  MyCandidateFeedbackTabs,
} from '../recruitment/views/MyCandidateFeedbacks';
import {MyReferralTabs} from '../recruitment/views/MyReferralTabs';
import {ReferralTabs} from '../recruitment/views/RefferalTabs';
import {RecruiterwiseCandidateReport} from './views/JobOpeningPeriodWiseReport';

export const ScreenMapping = [
  {
    id: 'requisitionrequester',
    view: ({user}) => {
      return <ManPowerRequisitionTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'requisitionapprover',
    view: props => {
      return <MrfApproverTab {...props} />;
    },
  },
  {
    id: 'position',
    view: props => {
      return <JobOpeningTab {...props} />;
    },
  },
  {
    id: 'feedback',
    view: props => {
      return <MyCandidateFeedbackTabs {...props} />;
    },
  },
  {
    id: 'allfeedback',
    view: props => {
      return <AllCandidateFeedbackTabs {...props} />;
    },
  },
  {
    id: 'activecandidates',
    view: props => {
      return <ActiveCandidateTabs {...props} />;
    },
  },
  {
    id: 'draftcandidates',
    view: props => {
      return <DraftCandidateTabs {...props} />;
    },
  },
  {
    id: 'closedcandidates',
    view: props => {
      return <ClosedCandidateTabs {...props} />;
    },
  },
  {
    id: 'referralcandidates',
    view: props => {
      return <ReferralTabs {...props} />;
    },
  },
  {
    id: 'refercandidates',
    view: props => {
      return <MyReferralTabs {...props} />;
    },
  },
  {
    id: 'recruiterwisecandidates',
    view: props => {
      return <RecruiterwiseCandidateReport {...props} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'talentrequisitions',
//     views: [
//       {
//         id: 'requisitionrequester',
//         label: 'Requisition Requester',
//         view: ({user}) => {
//           return <ManPowerRequisitionTab user={user} fromMyView={true} />;
//         },
//       },
//       {
//         id: 'requisitionapprover',
//         label: 'Requisition Approver',
//         view: props => {
//           return <MrfApproverTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'openings',
//     views: [
//       {
//         id: 'positions',
//         label: 'Positions',
//         view: props => {
//           return <JobOpeningTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'candidatesfeedback',
//     views: [
//       {
//         id: 'feedback',
//         label: 'Candidate Feedback',
//         view: props => {
//           return <MyCandidateFeedbackTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'candidates',
//     views: [
//       {
//         id: 'activecandidates',
//         label: 'Active',
//         view: props => {
//           return <ActiveCandidateTabs {...props} />;
//         },
//       },
//       {
//         id: 'closedcandidates',
//         label: 'Closed',
//         view: props => {
//           return <ClosedCandidateTabs {...props} />;
//         },
//       },
//       {
//         id: 'referralcandidates',
//         label: 'Referral',
//         view: props => {
//           return <ReferralTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'refers',
//     label: 'Refer',
//     views: [
//       {
//         id: 'refercandidates',
//         label: 'Refer',
//         view: props => {
//           return <MyReferralTabs {...props} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'openings',
//     label: 'Positions',
//   },
//   {
//     id: 'candidates',
//     label: 'Candidates',
//   },
//   {
//     id: 'candidatesfeedback',
//     label: 'Candidate Feedback',
//   },
//   {
//     id: 'talentrequisitions',
//     label: 'Talent Requisition',
//   },
//   {
//     id: 'refers',
//     label: 'Refer Candidates',
//   },
// ];
