import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {
  ActiveCandidate,
  ClosedCandidate,
  DraftCandidate,
  OnHoldCandidate,
} from './CandidateTable';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const JobOpeningCandidateTab = props => {
  let {route: {params} = {}} = props;
  const {job_details = {}, selectedTab} = params || {};
  const addOnFilter = {job_opening: job_details?._id};
  const {searchValue, onChangeFilter} = useFilter();
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    active: {
      label: 'Active',
      view: <ActiveCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Sortlisted', 'Interview', 'Offered', 'Offer Accepted']},
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
      actions: [
        <AddButton
          disabled={false}
          title=" Candidate"
          view="add-candidate"
          params={params}
        />,
        <IconButton
          icon={Download}
          title="Download"
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  ...props,
                  fetchProps: {
                    api: `/candidates`,
                    filter: {
                      status: {
                        $in: [
                          'Sortlisted',
                          'Interview',
                          'Offered',
                          'Offer Accepted',
                        ],
                      },
                      job_opening: job_details?._id,
                    },
                  },
                  column: {
                    Name: 'name',
                    Email: 'email',
                    Mobile: 'mobile',
                    'Added On': 'candidate_created_on',
                    Experience: 'experience.experience',
                    'Portfolio Link': 'portfolio_link',
                    Status: 'status',
                    'Current Round': 'current_round',
                  },
                  filename: job_details?.position_title?.name + '(Active).xlsx',
                },
              },
            });
          }}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: <ClosedCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Offer Rejected', 'Joined', 'Rejected', 'Dropped']},
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
      actions: [
        <IconButton
          icon={Download}
          title="Download"
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  ...props,
                  fetchProps: {
                    api: `/candidates`,
                    filter: {
                      status: {
                        $in: [
                          'Offer Rejected',
                          'Joined',
                          'Rejected',
                          'Dropped',
                        ],
                      },
                      job_opening: job_details?._id,
                    },
                  },
                  column: {
                    Name: 'name',
                    Email: 'email',
                    Mobile: 'mobile',
                    'Added On': 'candidate_created_on',
                    Experience: 'experience.experience',
                    'Portfolio Link': 'portfolio_link',
                    Status: 'status',
                    'Current Round': 'current_round',
                  },
                  filename: job_details?.position_title?.name + '(Closed).xlsx',
                },
              },
            });
          }}
        />,
      ],
    },
    joined: {
      label: 'Hold',
      view: <OnHoldCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: 'On Hold',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
      actions: [
        <IconButton
          icon={Download}
          title="Download"
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  ...props,
                  fetchProps: {
                    api: `/candidates`,
                    filter: {
                      status: 'On Hold',
                      job_opening: job_details?._id,
                    },
                  },
                  column: {
                    Name: 'name',
                    Email: 'email',
                    Mobile: 'mobile',
                    'Added On': 'candidate_created_on',
                    Experience: 'experience.experience',
                    'Portfolio Link': 'portfolio_link',
                    Status: 'status',
                    'Current Round': 'current_round',
                  },
                  filename:
                    job_details?.position_title?.name + '(On Hold).xlsx',
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      search={searchValue}
      searchFields={['name', 'email']}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Candidate"
        />,
      ]}
      selectedTab={selectedTab}
    />
  );
};

export const JobOpeningDraftCandidates = props => {
  let {route: {params} = {}} = props;
  const {job_details = {}, selectedTab} = params || {};
  const addOnFilter = {job_opening: job_details?._id};
  const {searchValue, onChangeFilter} = useFilter();
  const tabs = {
    active: {
      label: 'Draft',
      view: <DraftCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Draft']},
        candidate_source: 'Website',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
      actions: [
        <AddButton
          disabled={false}
          title=" Candidate"
          view="add-candidate"
          params={params}
        />,
      ],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      search={searchValue}
      searchFields={['name', 'email']}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Candidate"
        />,
      ]}
      selectedTab={selectedTab}
    />
  );
};
