import {useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab/TabView';
import {CreditNoteTable} from './CreditNoteTable';
import {useAppStateContext} from '../../../providers/AppState';

export const CreditNoteTab = props => {
  let {route: {params} = {}} = props;
  const {user} = useAppStateContext();
  const tabs = {
    Draft: {
      label: 'Draft',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Open', requested_by: user._id}}
          tab="Draft"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Open',
        requested_by: user._id,
      },
      eventSourceId: 'creditNoteAm',
      actions: [
        <AddButton
          title="Add Credit Note"
          view="add-credit-note-am"
          params={params}
        />,
      ],
    },
    Pending: {
      label: 'Pending',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Pending', requested_by: user._id}}
          tab="Pending"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Pending',
        requested_by: user._id,
      },
      eventSourceId: 'creditNoteAm',
    },
    Approved: {
      label: 'Approved',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Approved', requested_by: user._id}}
          tab="Approved"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Approved',
        requested_by: user._id,
      },
      eventSourceId: 'creditNoteAm',
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Reject', requested_by: user._id}}
          tab="Rejected"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Reject',
        requested_by: user._id,
      },
      eventSourceId: 'creditNoteAm',
    },
    Billed: {
      label: 'Billed',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Billed', requested_by: user._id}}
          tab="Billed"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Billed',
        requested_by: user._id,
      },
      eventSourceId: 'creditNoteAm',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CreditNoteApproverTab = props => {
  let {route: {params} = {}} = props;
  const tabs = {
    Pending: {
      label: 'Pending',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Pending'}}
          approverView={true}
          tab="Pending"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Pending',
      },
      eventSourceId: 'creditNoteAm',
    },
    Approved: {
      label: 'Approved',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Approved'}}
          approverView={true}
          tab="Approved"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Approved',
      },
      eventSourceId: 'creditNoteAm',
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Reject'}}
          approverView={true}
          tab="Rejected"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Reject',
      },
      eventSourceId: 'creditNoteAm',
    },
    Billed: {
      label: 'Billed',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Billed'}}
          approverView={true}
          tab="Billed"
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Billed',
      },
      eventSourceId: 'creditNoteAm',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CreditNoteAccountsTab = props => {
  let {navigation} = props;
  const {filterValues} = useFilter({});
  const tabs = {
    Pending: {
      label: 'Pending',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Approved'}}
          tab="Approved"
          accountsView={true}
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Approved',
      },
      eventSourceId: 'creditNoteAm',
    },
    billed: {
      label: 'Billed',
      view: (
        <CreditNoteTable
          addOnFilter={{credit_note_status: 'Billed'}}
          tab="Billed"
          accountsView={true}
          {...props}
        />
      ),
      api: '/creditNote/am',
      addOnFilter: {
        credit_note_status: 'Billed',
      },
      eventSourceId: 'creditNoteAm',
    },
  };
  return <TabView tabs={tabs} navigation={navigation} {...props} />;
};
