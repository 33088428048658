import React from 'react';
import { Table } from '../../../components/table/Table';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { AddButton } from '../../../components/button/AddButton';
import { TableHeader } from '../../../components/table/Headers';
import { AmountRender } from './components/AmountRender';
import { Button } from '../../../components/button/Button';
import { Text } from '@unthinkable/react-core-components';

const AgeingTable = props => {
  let { navigation = {}, route = {} } = props || {};

  let { params = {} } = route || {};

  const filterProps = useFilter();

  const { searchValue, filterValues } = filterProps;
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Ageing"
          actions={[
            <AddButton title="Add" view="create-ageing-data" params={params} />,
            <Button
              text="Import Ageing Data"
              onPress={() => {
                navigation.navigate('import-list', {
                  source: 'ageingData',
                  model: 'InvoiceReceiptHistory',
                });
              }}
            />,
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  type: 'autoComplete',
                  field: 'customer',
                  label: 'Customer',
                  api: '/customers',
                  valueField: 'name',
                  suggestionField: 'name',
                },
                {
                  label: 'Date',
                  field: 'date',
                  type: 'date'
                }
              ]}
            />,
          ]}
        />
      )}
      api={`/invoicereceipthistorydata`}
      limit={100000}
      addOnFilter={filterValues.filter}
      params={params}
      eventSourceId="Ageing"
      onRowPress={props => {
        const { row } = props;
        navigation.navigate(`edit-ageing-data`, {
          row,
        });
      }}
      columns={[
        {
          type: 'userAvatarChip',
          field: 'customer',
          header: 'Customer',
        },
        {
          type: 'date',
          field: 'date',
          header: "Date"
        },
        {
          type: 'text',
          field: 'type',
          header: "Type"
        },
        {
          type: 'text',
          render: ({ row, styles }) => {
            if (row?.credit_invoice_id) {
              return <Text style={{fontSize: 14, ...styles}}>{row?.credit_invoice_id?.invoice_number}</Text>;
            } else if (row?.invoice_id) {
              return <Text style={{fontSize: 14,...styles}}>{row?.invoice_id?.invoice_number}</Text>;
            } else if (row?.receipt_id) {
              return <Text style={{fontSize: 14, ...styles}}>{row?.receipt_id?.receipt_number}</Text>;
            } else if (row?.number) {
              return <Text style={{fontSize: 14 , ...styles}}>{row?.number}</Text>;
            }
          },
          header: "Invoice"
        },
        {
          header: "Amount",
          width: 90,
          render: ({ row, styles }) => {
            if (row?.base_invoice_pending_amount) {
              return <AmountRender
                row={row}
                styles={styles}
                amount={row?.base_invoice_pending_amount || 0}
              />;
            } else if (row?.base_invoice_receipt_amount) {
              return <AmountRender
                row={row}
                styles={styles}
                amount={row?.base_invoice_receipt_amount || 0}
              />;
            }
          },
        },
      ]}

    />
  );
};

export default AgeingTable;