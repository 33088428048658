import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const GoalForm = props => {
  let {
    navigation,
    route: {params},
    mode,
  } = props;
  const {row, thread = {}, myThreadGoals} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/threadgoals',
    eventSourceId: 'threadGoal',
    ...props,
  });
  const {user} = useAppStateContext();

  return (
    <Form
      api={`/threadgoals/${row?._id}`}
      fields={{
        goal: 1,
        project: {project: 1},
        owner: {name: 1},
        thread: {name: 1},
        members: {name: 1},
        kpi: {
          kpi: 1,
        },
        keyperformanceindicator: {
          name: 1,
        },
      }}
      beforeSubmit={({data}) => {
        if (mode) {
          return data;
        } else {
          return {
            data: {
              ...data,
              status: 'Plan',
              thread: !data?.thread ? thread?._id : data.thread,
              owner: user?._id,
            },
          };
        }
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Goal'}}
      layoutFields={[
        {
          label: 'Goal',
          type: 'text',
          field: 'goal',
          required: true,
        },
        {
          label: 'KPI',
          type: 'autoComplete',
          field: 'keyperformanceindicator',
          api: '/keyperformanceindicators',
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            type: 'variance',
          },
        },
        myThreadGoals
          ? {
              label: 'Project',
              field: 'thread',
              type: 'autoComplete',
              api: `/threads`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
            }
          : void 0,
        {
          label: 'Memebers',
          field: 'members',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditGoalForm = props => {
  return <GoalForm header={{title: 'Edit Goal'}} mode="edit" {...props} />;
};
