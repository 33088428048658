import React from 'react';
import {Form} from '../../../../components/form/Form';
import {FormErrorConfirmWrapper} from '../../../../components/form/FormWrapper';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';

const ChangeAllocatedResourceForm = props => {
  const {
    route: {params},
  } = props;
  const {allocated_data = {}, updated_data, type} = params;
  let uri = '/changeResource';
  if (type == 'nonbillable') uri = '/changeWorkloadResource';
  let {onSubmit} = useFormSubmit({
    uri,
    eventSourceId: 'allocatedResource',
  });
  let incentiveOptions = [];
  let incentiveModelDefaultValue = {};
  if (
    allocated_data?.project_id?.new_project_type == 'staffaugmentation' ||
    allocated_data?.project_id?.new_project_type == 'managedteam'
  ) {
    incentiveOptions = [
      {
        label: 'Reporting To',
        value: 'Reporting To',
      },
      {
        label: 'Sharing With PM',
        value: 'Sharing With PM',
      },
      {
        label: 'Pay To Skill Manager',
        value: 'Pay To Skill Manager',
      },
      {label: 'No Mentorship', value: 'No Mentorship'},
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Reporting To',
    };
  }
  if (
    allocated_data?.project_id?.new_project_type == 'sdlc' ||
    allocated_data?.project_id?.new_project_type == 'sdlcplus'
  ) {
    incentiveOptions = [
      {
        label: 'Pay To Module Lead',
        value: 'Pay To Module Lead',
      },
      {
        label: 'Sharing With Skill Manager',
        value: 'Sharing With Skill Manager',
      },
      {
        label: 'Pay To Skill Manager',
        value: 'Pay To Skill Manager Sdlc',
      },
      {label: 'No Mentorship', value: 'No Mentorship'},
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Pay To Module Lead',
    };
  }

  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        submitAction={'Save'}
        header="Change Resource Allocation"
        defaultValues={{
          ...allocated_data,
          updated_data,
          ...incentiveModelDefaultValue,
        }}
        layoutFields={[
          {
            label: 'Resource',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
            onChangeValue: (value, e, {setFieldValue}) => {
              setFieldValue('front_facing_resource', {
                _id: value?._id,
                name: value?.name,
              });
            },
          },
          {
            label: 'Front Facing Resource',
            field: 'front_facing_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            label: 'Start Date',
            field: 'from_date',
            type: 'date',
            required: true,
            size: 4,
          },
          {
            label: 'End Date',
            field: 'to_date',
            type: 'date',
            required: true,
            size: 4,
          },
          {
            label: 'Hours',
            field: 'hours',
            type: 'number',
            required: true,
            size: 4,
          },
          {
            label: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/orderWiseProject`,
            filter: {_id: allocated_data?.order?._id},
            suggestionField: 'project',
            valueField: 'project',
            required: true,
            size: 4,
          },
          {
            label: 'Project Skill',
            field: 'tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
          },
          {
            label: 'Resource Skill',
            field: 'resource_tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
          },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 6,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            required: true,
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            size: 6,
          },
          {
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 4,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 4,
                  },
                  {
                    label: 'Level 3 Owner',
                    placeholder: 'Level 3 Owner',
                    field: 'level_three_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                ],
              },
              {
                defaultHidden: true,
                label: 'Incentive Mode',
                fields: [
                  {
                    label: 'Incentive Mode',
                    type: 'radioGroup',
                    field: 'incentive_mode',
                    options: incentiveOptions,
                    required: true,
                  },
                  {
                    label: 'Incentive Shared With',
                    placeholder: 'Incentive Shared With',
                    field: 'incentive_shared_with',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    visible: ({values = {}}) =>
                      values?.incentive_mode === 'Sharing With PM' ||
                      values?.incentive_mode === 'Pay To Skill Manager Sdlc' ||
                      values?.incentive_mode === 'Pay To Skill Manager' ||
                      values?.incentive_mode === 'Sharing With Skill Manager',
                    required: true,
                  },
                  {
                    type: 'switch',
                    label: 'Is Self/Front Facing Not Allow',
                    field: 'self_front_facing_not_allow',
                    size: 6,
                  },
                  //   {
                  //     type: 'switch',
                  //     label: 'Consider Half Variable Amount',
                  //     field: 'consider_half_variable_amount',
                  //     size:6,
                  //     visible: ({values = {}}) =>{
                  //       return values?.project_id?.new_project_type==='managedteam'
                  //     }
                  //   },
                ],
              },
            ],
          },
        ]}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};

export default ChangeAllocatedResourceForm;
