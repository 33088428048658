import React from 'react';

import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {Button} from '../../../components/button/Button';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  NoDuesAccountsPendingList,
  NoDuesAccountsProcessedList,
  NoDuesAdminITList,
  NoDuesAdminPendingList,
  NoDuesAdminProcessedList,
  NoDuesHRPendingList,
  NoDuesHRProcessedList,
  NoDuesHrClosedList,
  NoDuesHrHoldList,
  NoDuesHrOverdueList,
  NoDuesHrPendingList,
  NoDuesITProcessedList,
  NoDuesTeamPendingList,
  NoDuesTeamProcessedList,
} from './NoduesLists';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const NoDuesHrTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.pending = {
    label: 'Pending',
    api: '/no-dues/pending',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      $and: [
        {
          status: {
            $ne: true,
          },
        },
        {is_cancelled: {$ne: true}},
        {on_hold: {$ne: true}},
      ],
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHrPendingList
        key="pending"
        tab="pending"
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  tabs.closed = {
    label: 'Closed',
    api: '/no-dues/pending-closed',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      $or: [{status: true}, {is_cancelled: true}],
      $nor: [{on_hold: true}, {status: true, fnf_status: 'absoconding'}],
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHrClosedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.onHold = {
    label: 'Hold',
    api: '/no-dues/pending-closed',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      $or: [{fnf_status: 'absconding'}, {on_hold: true}],
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHrHoldList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.overdue = {
    label: 'Overdue',
    api: '/no-dues/overude',
    params: {
      ...params,
    },
    filter: {
      $and: [
        {
          status: {
            $ne: true,
          },
        },
        {is_cancelled: {$ne: true}},
        {on_hold: {$ne: true}},
      ],
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHrOverdueList
        key="overdue"
        tab="overdue"
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
    />
  );
};

export const NoDuesTeamTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let tabs = {};
  tabs.active = {
    label: 'Pending',
    api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      reportingTo_DuesCleaared: {$ne: true},
      is_transfer_case: {$in: [null, false]},
      is_cancelled: {$ne: true},
    },
    limit: 5000,
    eventSourceId: 'NoduesTeam',
    view: (
      <NoDuesTeamPendingList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
  };
  tabs.closed = {
    label: 'Cleared',
    // api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'NoduesTeam',
    view: (
      <NoDuesTeamProcessedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
    />
  );
};

export const NoDuesAccountsTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Pending',
    api: '/no-dues/pending',
    params: {
      ...params,
    },
    filter: {
      Accounts_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesAccounts',
    view: (
      <NoDuesAccountsPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.closed = {
    label: 'Cleared',
    // api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'NoduesAccounts',
    view: (
      <NoDuesAccountsProcessedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/no-dues/accounts/processed/export`,
            props: {
              ...params,
              ...filterParams,
              ...filter,
            },
          });
        }}
      />,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
    />
  );
};

export const NoDuesHRTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Pending',
    api: '/no-dues/pending',
    params: {
      ...params,
    },
    filter: {
      hr_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      on_hold: {$ne: true},
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHRPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.closed = {
    label: 'Cleared',
    // api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <NoDuesHRProcessedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/no-dues/hr/processed/export`,
            props: {
              ...params,
              ...filterParams,
              ...filter,
            },
          });
        }}
      />,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: ({values}) => {
                return {
                  employee_status: {$in: ['Active', 'Inactive']},
                };
              },
            },
          ]}
        />,
      ]}
    />
  );
};

export const NoDuesAdminTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Pending',
    api: '/no-dues/pending',
    params: {
      ...params,
    },
    filter: {
      admin_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesAdmin',
    view: (
      <NoDuesAdminPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.closed = {
    label: 'Cleared',
    // api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'NoduesAdmin',
    view: (
      <NoDuesAdminProcessedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/no-dues/admin/processed/export`,
            props: {
              ...params,
              ...filterParams,
              ...filter,
            },
          });
        }}
      />,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
    />
  );
};

export const NoDuesITNetworkTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {download} = useAppStateContext();
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Pending',
    api: '/no-dues/pending',
    params: {
      ...params,
    },
    filter: {
      ITNetworking_DuesCleaared: {$ne: true},
      is_cancelled: {$ne: true},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesIt',
    view: (
      <NoDuesAdminITList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  tabs.closed = {
    label: 'Cleared',
    // api: '/no-dues/team',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'NoduesIt',
    view: (
      <NoDuesITProcessedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/no-dues/it/processed/export`,
            props: {
              ...params,
              ...filterParams,
              ...filter,
            },
          });
        }}
      />,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              secondarySuggestionField: 'official_email_id',
            },
          ]}
        />,
      ]}
    />
  );
};
