import React from 'react';
import {RecursiveTable, Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {Row} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';
import {RecursiveRender} from '@unthinkable/react-table';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

const RecurringTableHOC = ({source, ...props}) => {
  const {params} = props;
  const {navigation, filterParams} = params;
  return (
    <RecursiveTable
      {...props}
      recursiveKey="_children"
      columns={[
        {
          render: RecursiveRender,
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Amount',
          field: source === 'expense' ? 'dr_amount' : 'cr_amount',
          value: ({row}) =>
            source === 'expense'
              ? Math.abs(row.dr_amount || 0)
              : row.cr_amount || 0,
          type: 'currency',
        },
      ]}
      moreActions={
        source === 'expense'
          ? [
              {
                title: 'Voucher',
                onPress: ({row}) => {
                  navigation.navigate(`voucherlineitems`, {
                    lineItemFilter: {
                      account: row?.account_id,
                      ...filterParams,
                    },
                  });
                },
              },
            ]
          : []
      }
    />
  );
};

const PandLTable = props => {
  const {filterParams, searchValue, params} = props;
  const {data, loading} = useFetchData({
    api: '/pnlbalance',
    addOnFilter: filterParams,
    params,
    eventSourceId: 'GST',
  });
  return (
    <Row style={{gap: 8, overflow: 'hidden', flex: 1}}>
      <RecurringTableHOC
        data={data?.revenuedata}
        loading={loading}
        source="revenue"
        params={props}
      />
      <RecurringTableHOC
        data={data?.expensedata}
        loading={loading}
        source="expense"
        params={props}
      />
    </Row>
  );
};

export const PandLTabs = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {},
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    all: {
      label: 'Profit & Loss',
      view: (
        <PandLTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'profitloss',
      addOnFilter: filter,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpnlbalance',
              props: {
                addOnFilter: {...filter},
                params: {...params, ...filterValues.params},
                globalParams: globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={{...params, ...filterValues.params}}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Currency',
              placeholder: 'Select',
              options: [
                {label: 'INR', value: 'INR'},
                {label: 'Other', value: 'Other'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'currency',
            },
            {
              type: 'autoComplete',
              label: 'Is Internal',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'is_internal',
            },
            // {
            //   type: 'date',
            //   label: 'Date',
            //   field: 'period',
            //   // rangeCalendar:true
            //   asParam: true,
            // },
            // {
            //   type: 'multiAutoComplete',
            //   label: 'Organization',
            //   api: '/organizations',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'organization',
            // },
            // {
            //   type: 'autoComplete',
            //   label: 'Product',
            //   api: '/products',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'product',
            // },
          ]}
        />,
      ]}
    />
  );
};
