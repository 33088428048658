import React from 'react';
import {Image, Row, TouchableOpacity} from '@unthinkable/react-core-components';
import {extractEditorContent} from '@unthinkable/react-rich-text-input';
import {Commenter, DateTime} from './CommentComponents';
import {CommentCard} from './CommentCard';
import {useInvoke} from '../../controllers/useInvoke';
import {useAppStateContext} from '../../providers/AppState';
import {MoreActions} from '../moreAction/MoreAction';
import {useFormSubmit} from '../../controllers/useSubmitForm';

const base64ToFile = (base64, filename = 'untitled') => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: 'image/png'});

  const file = new File([blob], filename, {
    type: blob.type,
    lastModified: Date.now(),
  });

  return file;
};

export const useComment = ({
  mentionApi,
  comment_source,
  comment_source_id,
  view,
  eventSourceId = [],
  mention,
  placeholder = 'Add a comment, use @ to mention a person...',
  maxHeight,
}) => {
  const {fetch, user, upload, resourceUrl} = useAppStateContext();
  if (!Array.isArray(eventSourceId)) {
    eventSourceId = [eventSourceId];
  }
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['Comment', ...eventSourceId],
    uri: '/projectcomments',
  });

  const defaultValues = {
    comment_by: user._id,
    source: comment_source,
    source_id: comment_source_id,
    view: view,
  };

  const beforeSubmit = async ({data}) => {
    let {mentions = [], ...rest} = extractEditorContent(data.comment);
    mentions = mentions?.map(mention => ({
      _id: mention.id,
    }));

    const {comment} = data;

    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = comment;

    let imgTags = tempDiv.querySelectorAll('img') || [];
    for (const imgTag of imgTags) {
      {
        if (imgTag.src.startsWith('data:image/')) {
          const base64Image = imgTag.src;
          const file = base64ToFile(base64Image);

          const result = await upload(file);
          const url = resourceUrl(result);

          imgTag.src = url;
        }
      }
    }

    let updatedComment = tempDiv.innerHTML;

    return {
      data: {
        ...data,
        comment: updatedComment,
        mentions,
      },
    };
  };
  const componentProps = {};
  if (maxHeight) {
    componentProps.maxHeight = maxHeight;
  }
  if (mention !== false) {
    componentProps.mention =
      mention || mentionApi
        ? {
            source: async (searchTerm, renderList) => {
              let result = await fetch({
                uri: mentionApi,
                props: {
                  search: searchTerm,
                  searchFields: ['name'],
                  limit: 50,
                },
              });
              let data = result?.data || [];
              data = data.map(user => {
                return {
                  id: user._id,
                  value: user.name,
                  email: user.email,
                };
              });
              renderList(data);
            },
            secondarySuggestionField: 'email',
          }
        : {};
  }
  return {
    field: 'comment',
    placeholder,
    onSubmit,
    defaultValues,
    beforeSubmit,
    componentProps,
  };
};

const CommentCardHeader = props => {
  const {row, setIsFocused, ReplyIcon, handleOnReply, level} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['Bug', 'Feature', 'Issue', 'Task', 'Comment'],
  });
  const {user} = useAppStateContext();
  return (
    <Row gap={6} style={{alignItems: 'center'}}>
      <Commenter {...props} />
      <DateTime row={row} />
      {level === 0 ? (
        <TouchableOpacity onPress={handleOnReply} title="Reply">
          <Image source={ReplyIcon} />
        </TouchableOpacity>
      ) : (
        void 0
      )}
      {user?._id === row?.comment_by?._id ? (
        <MoreActions
          actions={[
            {title: 'Edit', onPress: () => setIsFocused(true)},
            {
              title: 'Delete',
              variant: 'error',
              confirm: {
                title: 'Delete',
                message: 'Are you sure you want to Delete?',
                confirmText: 'Delete',
              },
              onPress: props => {
                deleteInvoke({
                  uri: `/projectcomments/${row?._id}`,
                  props: {},
                });
              },
            },
          ]}
        />
      ) : (
        void 0
      )}
    </Row>
  );
};

export const CommentRow = props => {
  const commentProps = useComment(props);
  return (
    <CommentCard
      {...props}
      {...commentProps}
      header={CommentCardHeader}
      actionContainerStyle={{paddingTop: 8, paddingBottom: 8}}
      value={props?.row}
    />
  );
};
